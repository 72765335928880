import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { AppService } from '../../servicios/app.service';
import { EmailsService } from '../../servicios/emails.service';
import { ClienteService } from '../../servicios/cliente.service';
import { MatDialog } from '@angular/material/dialog';
import { DestinatariosmasivoComponent } from '../destinatariosmasivo/destinatariosmasivo.component';
import { DestinatariospeticionComponent } from '../destinatariospeticion/destinatariospeticion.component';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition, MatSnackBarConfig } from '@angular/material/snack-bar'; 
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete';
import * as Editor from '../../ck5editor/ckeditor.js';
import { MatTableDataSource } from '@angular/material/table';
import * as moment from 'moment';

@Component({
  selector: 'mailmasivoclientes',
  templateUrl: './mailmasivoclientes.component.html',
  styleUrls: ['./mailmasivoclientes.component.css'],
  providers: [EmailsService, AppService, ClienteService]
})
export class MailmasivoclientesComponent implements OnInit {
  public identity;
  public filesToUload;
  public load;
  public mensajenuevo;
  public Editor = Editor;
  public abonados;
  public abonadosg3;
  public abonadosplataforma;
  public destinatarios;
  public peticionespendientes = new MatTableDataSource<any>();
  public verpendientes = false;
  public columns = ['Fecha', 'pendientes', 'Eliminar'];
  public peticioncargada = false;
  public peticionactual;
  public procesoenviado = false;
  public textoprocesoenvio = '';
  public tarifas;

  @ViewChild('auto') matAutocomplete: MatAutocomplete;

  constructor(
    private _emailsService: EmailsService,
    public ventanaDestinatarios: MatDialog,
    private _snackBar: MatSnackBar,
    private _appservice: AppService,
    private _clienteservice: ClienteService
  ) {
    this.identity = this._appservice.getIdentity();
  }


  ngOnInit(): void {
    this._emailsService.getpeticiones().subscribe(
      response=>{
        console.log(response);
        if(response && response.peticiones && response.peticiones.length >=1){
          this.peticionespendientes.data = response.peticiones.map((peticion)=>{
            let pet = {
              Fecha: moment(peticion.actualizado).format('DD/MM/YYYY HH:mm'),
              pendientes: peticion.destinatariospendientes.length,
              id: peticion._id
            }
            return pet; 
          });
          this.verpendientes = true;
        }
        this.crearmensajenuevo();
        this.getabonadosg3();
        this.getclientesplataforma();
      }
    )
  }


  aviso(texto, subtexto) {
    this._snackBar.open(texto,subtexto,{
      duration: 4000,
      horizontalPosition: "center",
      verticalPosition: "top",
    });
  }

  getabonadosg3(){
    var query = {"datosAbonado.Estado": 'A'}
    this._clienteservice.getcecos(query).subscribe(
      response=>{
        if(response && response.cecos){
          var hash = {};
          this.abonadosg3 = response.cecos
          .filter((centro)=>{
            let abinicio = centro.Abonado.slice(0,1);
            if(abinicio == '1' || abinicio == '2'){
              return false;
            }else{
              return true;
            }
          })
          .map((ceco)=>{
            if(ceco.datosAbonado){
              ceco['Nombre'] = ceco.datosAbonado.RazonSocial;
            }else{
              ceco['Nombre'] = ceco.Abonado;
            }
            ceco['Numero'] = ceco.Abonado;
            ceco['Email'] = ceco.Email;
            ceco['Tarifa'] = ceco.Tarifa;
            return ceco;
          }).filter(o => hash[o.Numero] ? false : hash[o.Numero] = true)
          .filter(o => hash[o.Email] ? false : hash[o.Email] = true);

          this.tarifas = response.cecos.map((cec)=>{
            return cec.Tarifa;
          })
          .filter(o => hash[o] ? false : hash[o] = true)
          .filter(a => a? true : false)
          .sort(function(a,b){
            return a > b;
          });  
        }
      }
    )
  }

  getclientesplataforma(){
    this._clienteservice.getclientes().subscribe(
      response=>{
        if(response && response.clientes){
          this.abonadosplataforma = response.clientes.map((cliente)=>{
            cliente['Nombre'] = cliente.nombre;
            cliente['Numero'] = cliente.numero;
            cliente['Email'] = cliente.emailsIncidencias;
            return cliente;
          });
        }
      }
    )
  }

  crearmensajenuevo(){
    var firma = this.identity.options.firmamail ? this.identity.options.firmamail : '';
    this.mensajenuevo = {
      message: {
        subject: '',
        body: {
          contentType: "HTML",
          content: '<br><br>' + firma
        },
        toRecipients: [],
        sender:{
          emailAddress:{
                address:"02601@grupomrw.com",
                name:"Mensasur Getafe"
            }
        }
      }
    }
  }
  

  fileChangeEvent(fileInput: any){
    if(!this.mensajenuevo.message['attachments']){
      this.mensajenuevo.message['attachments'] = new Array();
    }  
      var archivos = <Array<File>>fileInput.target.files;
      for(let file of archivos){
          readFileAsync(file).then((data)=>{return data}).then((a:any)=>{
              let partes = a.split(";");
              let content = partes[1].substring(7);
              let tipo = partes[0].split(':');
              var archivo = {
                'contentType': tipo[1],
                'name': file.name,
                'contentBytes': content
              };
              this.mensajenuevo.message.attachments.push(archivo);
          }).catch(err=>{
            console.log(err);
          });  
       }
  
      function readFileAsync(file) {
        return new Promise((resolve, reject) => {
          let reader = new FileReader();
          reader.onload = () => {
            resolve(reader.result);
          };
          reader.onerror = reject;
          reader.readAsDataURL(file);
        })
      }
  }  
  
  eliminarAdjunto(indice){
    this.mensajenuevo.message.attachments.splice(indice, 1);
  }

  eliminapeticion(peticionid){
    this._emailsService.eliminapeticion(peticionid).subscribe(
      response=>{
        if(response && response.peticion){
          let peticiones = this.peticionespendientes.data;
          peticiones.splice(peticiones.findIndex(a=>a.id == peticionid),1);
          this.peticionespendientes.data = peticiones;
        }
      }
    )
  }

  abrepeticion(peticion){
    this.peticioncargada = true;
    this.verpendientes = false;
    this._emailsService.getpeticion(peticion).subscribe(
      response=>{
        this.mensajenuevo = response.peticion.mensaje;
        this.peticionactual = response.peticion;
      }
    )
  }

  abredestinatariospeticion(){
    var destinatariosabierto = this.ventanaDestinatarios.open(DestinatariospeticionComponent, {
      width: '85%',
      height: '85%',
      data: this.peticionactual
    });
    destinatariosabierto.afterClosed().subscribe(result =>{
      if(result){
        this.destinatarios = result;
      }  
    });
 }

  abredestinatarios(){
    this.abonados = {
      g3: this.abonadosg3,
      plataforma: this.abonadosplataforma,
      tarifas: this.tarifas
    }
    var destinatariosabierto = this.ventanaDestinatarios.open(DestinatariosmasivoComponent, {
      width: '85%',
      height: '85%',
      data: this.abonados
    });
    destinatariosabierto.afterClosed().subscribe(result =>{
      if(result){
        this.destinatarios = result;
      }  
    });
 }

 enviarmensaje(){
   this.procesoenviado = true;
    this._emailsService.mailmasivo(this.mensajenuevo, this.destinatarios).subscribe(
      response=>{
        if(response && response.mailmasivoid){
          var contador = 0;
          this.procesadestinatario(response.mailmasivoid,this.destinatarios[0],contador);
        }
      }
    )    
  }

  procesadestinatario(masivoid, destinatario, contador){
    if(destinatario && destinatario.length >=3){
      this.textoprocesoenvio = moment().format('HH:mm:ss') + ' enviando a: ' + destinatario + '\n' + this.textoprocesoenvio;
      this._emailsService.continuamailmasivo(masivoid,destinatario).subscribe(
        response=>{
          if(response.response == 202){
            this.textoprocesoenvio = moment().format('HH:mm:ss') + '  ' + response.response + '  ' + destinatario + ' OK\n' + this.textoprocesoenvio;
          }else{
            this.textoprocesoenvio = moment().format('HH:mm:ss') + '  ' + response.response + '  ' + destinatario + ' ERROR\n' + this.textoprocesoenvio;
          }
          if(contador >= this.destinatarios.length -1){
            this._emailsService.getpeticion(masivoid).subscribe(
              response=>{
                if(response && response.peticion){
                  if((response.peticion.errores && response.peticion.errores.length >=1) || (response.peticion.destinatariospendientes.errores && response.peticion.destinatariospendientes.length >=1)){
                    alert('se han producido errores en el envio de emails masivos');
                  }else{
                    alert('Proceso Masivo Completado');
                    this._emailsService.eliminapeticion(masivoid).subscribe(response=>{});
                  }
                  this.destinatarios = undefined;
                }
              }
            )
          }else{
            contador++
            this.procesadestinatario(masivoid,this.destinatarios[contador],contador);
          }
        },
        error=>{
          console.log(<any>error);
        }
      )
    }else{
      this.textoprocesoenvio = moment().format('HH:mm:ss') + ' no existe direccion de email: ' + this.destinatarios[contador].Numero + '\n' + this.textoprocesoenvio;
      contador++
      this.procesadestinatario(masivoid,this.destinatarios[contador],contador);
    }  
  }


}
