import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs/Observable";
import { GLOBAL } from "./global";

@Injectable()
export class ConfiguracionService {
  public url: string;

  constructor(public _http: HttpClient) {
    this.url = GLOBAL.url;
  }

  getarchivosimagen(): Observable<any> {
    let headers = new HttpHeaders().set("Content-Type", "application/json");
    return this._http.get(this.url + "archivosimagenesportada", {
      headers: headers,
    });
  }

  getimagenportada(imagen): Observable<any> {
    let headers = new HttpHeaders().set("Content-Type", "application/json");
    return this._http.get(this.url + "imagenportada/" + imagen, {
      responseType: "blob",
      headers: headers,
    });
  }
}
