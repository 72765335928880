import { Component, OnInit, Inject } from '@angular/core';
import { VehiculoService } from '../../servicios/vehiculos.service';
import { Tarjeta } from '../../models/tarjeta';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition, MatSnackBarConfig } from '@angular/material/snack-bar';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ClienteService } from '../../servicios/cliente.service';

@Component({
  selector: 'app-tarjeta',
  templateUrl: './tarjeta.component.html',
  styleUrls: ['./tarjeta.component.css'],
  providers: [VehiculoService, ClienteService]
})
export class TarjetaComponent implements OnInit {
  public vehiculos;
  public mensajeros;
  constructor(
    public dialogoTarjeta: MatDialogRef<TarjetaComponent>,
    private _snackBar: MatSnackBar,
    private _vehiculoService: VehiculoService,
    private _clienteService: ClienteService,
    @Inject(MAT_DIALOG_DATA) public data: Tarjeta
  ) { 
    var query = {fechabaja:{$in:[null, '']}};
    this._vehiculoService.getvehiculos(query).subscribe(
      response=>{
        if(response.vehiculos) this.vehiculos = response.vehiculos;  
      },
      error=>{
        console.log(<any>error);
      }
    );
  }

  ngOnInit(): void {
    this._clienteService.getmensajeros().subscribe(
      response=>{
        this.mensajeros = response.mensajeros.sort(function(a, b){
          return a['numero'] > b['numero'];
        });
      },
      error=>{
        console.log(<any>error);
      }
    )    
  }

  aviso(texto, subtexto) {
    this._snackBar.open(texto,subtexto,{
      duration: 5000,
      horizontalPosition: "center",
      verticalPosition: "top",
    });
  }

  closeDialog() {
    this.dialogoTarjeta.close(true);
  }

  guardartarjeta(){
    this._vehiculoService.guardartarjeta(this.data).subscribe(
        response=>{
            if(response.status == 200){
              this.aviso(response.message, 'Ok');
              this.closeDialog();
            }
          },
        error=>{
          this.aviso(error.error.message, 'error');
          console.log(<any>error);
        }  
      )
  }

  eliminartarjeta(){
    if(this.data._id){
      this._vehiculoService.borratarjeta(this.data._id).subscribe(
        response=>{
            if(response.tarjetaremoved){
              this.aviso('Tarjeta eliminada','Ok');
              this.closeDialog();
            }  
          },
        error=>{
          console.log(<any>error);
        }  
      );
    }else{
      this.closeDialog();
    }  
  }

}
