import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ClienteService } from '../../servicios/cliente.service';
import { AppService } from '../../servicios/app.service';
import { FichajeService } from '../../servicios/fichaje.service';

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  providers: [ClienteService, AppService, FichajeService]
 
})
export class LoginComponent implements OnInit {
	public title:String;
	public identity;
	public status: String;
	public token;
  public mensajestatus;
  public anterior;
 
  constructor(
    private _router: Router,
    private _route: ActivatedRoute,
    private _clienteService: ClienteService,
    private _appservice: AppService,
    private _fichajeService: FichajeService
  ){
  	this.title = 'Identificate';
    this.identity = {};
  }

  ngOnInit(){
    this._route.params.subscribe(
      params=>{
        if(params && params.anterior){
          this.anterior = params.anterior;
          console.log(this.anterior);
        }
  	});   
  }

  onSubmit(form){
  	this._clienteService.signUp(form.form.value).subscribe(
      response =>{
        if(!response.token){
      		this.status = 'error';
      	}else{
          this.status = 'success';
          this.token = response.token;
          sessionStorage.setItem('token', this.token);
          this.identity = response.cliente;
          sessionStorage.setItem('identity', JSON.stringify(this.identity));
          this._appservice.refreshTokenOffice();
          if(response.cliente.role != 'cliente'){
            if(this.identity.options && this.identity.options['g3']) this._appservice.refreshg3();
            this._fichajeService.getfichajeusr().subscribe(
              response=>{
                if(response && response.ficha){
                  this._fichajeService.setficha(response.ficha);
                }
              },
              error=>{
                console.log('el usuario aun no ha fichado hoy');
              }
            )
          }else{
            sessionStorage.setItem('ceco', JSON.stringify(response.ceco));
          }
          if(this.anterior){
            this.enrutar(this.anterior);
          }else{
            this.enrutar('inicio');
          }  
        }
      },
      error =>{
        this.mensajestatus = error.error.message;
        this.status = 'error';
      });
  }

  enrutar(ruta){
    this._router.navigate([ruta]);
  }


}
