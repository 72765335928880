<div class="barraprincipalincidencias">
	<!--<div class="cajaherramientasincidencias">
		<div class="herramientaincidenciaspequena" (click)="categoriaseleccionada('modificados_totales'); getmodificados()" [class.selectcategory]="category == 'modificados_totales'">
			<mat-icon style="font-size:12pt">storage</mat-icon> Totales <div class="avisoatrasadas" *ngIf="avisomodificados >= 1"><strong>({{avisomodificados}})</strong></div>
		</div>
		<div class="herramientaincidenciaspequena" (click)="categoriaseleccionada('modificados_recogida'); modrecogida()" [class.selectcategory]="category == 'modificados_recogida'">
			<mat-icon style="font-size:12pt">transit_enterexit</mat-icon> Recogida <div class="avisoatrasadas" *ngIf="avisomodificados_recogida >= 1"><strong>({{avisomodificados_recogida}})</strong></div>
		</div>
		<div class="herramientaincidenciaspequena" (click)="categoriaseleccionada('modificados_entrega'); modentrega()" [class.selectcategory]="category == 'modificados_entrega'">
			<mat-icon style="font-size:12pt">rv_hookup</mat-icon> Entrega <div class="avisoatrasadas" *ngIf="avisomodificados_entrega >= 1"><strong>({{avisomodificados_entrega}})</strong></div>
		</div>
		<div class="herramientaincidenciaspequena" (click)="categoriaseleccionada('modificados_otros'); modotros()" [class.selectcategory]="category == 'modificados_otros'">
			<mat-icon style="font-size:12pt">multiple_stop</mat-icon> Otros <div class="avisoatrasadas" *ngIf="avisomodificados_otros >= 1"><strong>({{avisomodificados_otros}})</strong></div>
		</div>
		Envios Modificados
	</div>-->
	<div class="cajaherramientasincidencias">
		<div class="herramientaincidenciaspequena" (click)="categoriaseleccionada('recibidos_totales');getrecibidos()" [class.selectcategory]="category == 'recibidos_totales'">
			<mat-icon style="font-size:12pt">content_paste</mat-icon> Pendientes Hoy
		</div>
		<div class="herramientaincidenciaspequena" (click)="categoriaseleccionada('recibidos_manana');getmanana()" [class.selectcategory]="category == 'recibidos_manana'">
			<mat-icon style="font-size:12pt">pending_actions</mat-icon> Pendientes Mañana
		</div>
		<div class="herramientaincidenciaspequena">
			
		</div>
		<div class="herramientaincidenciaspequena">
			
		</div>		
		Envios Recibidos
	</div>
	<div class="cajaherramientasincidencias">
		<div *ngIf="identity.role == 'Administrador' || identity.role == 'operador-admin' || identity.role == 'operador-plus'" class="herramientaincidenciaspequena" (click)="categoriaseleccionada('fichajes_mensajero');section=null" [class.selectcategory]="category == 'fichajes_mensajero'">
			<mat-icon style="font-size:12pt">rv_hookup</mat-icon> Fichajes Mensajeros
		</div>
		<div *ngIf="identity.role == 'Administrador' || identity.role == 'operador-admin'" class="herramientaincidenciaspequena" (click)="categoriaseleccionada('fichajes_usuarios');section=null" [class.selectcategory]="category == 'fichajes_usuarios'">
			<mat-icon style="font-size:12pt">person_pin</mat-icon> Fichajes Usuarios
		</div>
		<div class="herramientaincidenciaspequena">
			
		</div>
		<div class="herramientaincidenciaspequena">
			
		</div>		
		Fichajes
	</div>
</div>
<div *ngIf="section == 'modificados'" class="contenido">
	<modificados [incidencias]="modificados"></modificados>
</div>
<div *ngIf="section == 'recibidos'" class="contenido">
	<enviosrecibidos [envios]="envios" [totales]="totales" [load]="load" (totalesenvios)="toggletot($event)"></enviosrecibidos>
</div>
<div *ngIf="category == 'fichajes_mensajero'" class="contenido">
	<fichajemensajeros></fichajemensajeros>
</div>
<div *ngIf="category == 'fichajes_usuarios'" class="contenido">
	<fichajeusuarios></fichajeusuarios>
</div>
