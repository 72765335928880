<div class="barraprincipalincidencias" *ngIf="!email && !veremails">
	<div class="cajaherramientasincidencias overfondo" style="font-size:10pt;cursor:pointer;padding:5px;" (click)="guardaincidencia()">
		<mat-icon style="font-size:25pt">save</mat-icon><br>
		Guardar
	</div>
	<div class="cajaherramientasincidenciaslarga" style="font-size:10pt;padding:5px;position:relative;">
		<mat-form-field>
			<mat-label>Seleccionar Ubicacion</mat-label>
			<mat-select name="selubicacion" (selectionChange)="ubicar()" style="width: 100%;margin-bottom:15px;" [(ngModel)]="ubicacionseleccionada">
				<mat-option [value]="item" *ngFor="let item of listaubicaciones">{{item}}</mat-option>
			</mat-select>
		</mat-form-field>
		<div style="width:100%;text-align:center;position:absolute;bottom:10px;"><strong style="font-size:14pt;">{{data.NumAlbaran}}</strong></div>
	</div>
	<div class="cajaherramientasincidencias overfondo" *ngIf="abonadomail" style="cursor:pointer;padding: 5px;" (click)="muestramail('mailcliente')">
		<mat-icon style="font-size:25pt">forward_to_inbox</mat-icon><br>
		Comunicar Cliente
	</div>
	<div class="cajaherramientasdireccionesmail" style="font-size:10pt;padding: 5px;">
		<div *ngIf="destinatarionuevo.length >= 1" class="fondooscuro" (click)="muestramail('mailalvuelo')" style="height:50%;margin-right:5px;cursor:pointer;"><mat-icon>forward_to_inbox</mat-icon></div>
		<mat-form-field class="agregardestinatarios">
			<mat-label>Destinatarios</mat-label>
			<mat-chip-list #chipList aria-label="destinatarios selection" style="font-size: 8pt;">
				<mat-chip
					*ngFor="let destinatario of destinatarionuevo"
					[removable] = "true"
					(removed)="remove(destinatario)">
					{{destinatario}}
					<mat-icon matChipRemove>cancel</mat-icon>
				</mat-chip>
				<input
				placeholder="Nuevo Destinatario..."
				#destinatariosinput
				[formControl]="myControl"
				[matAutocomplete]="auto"
				[matChipInputFor]="chipList"
				[matChipInputSeparatorKeyCodes]="separatorKeysCodes"
				(matChipInputTokenEnd)="add($event)">
			</mat-chip-list>
			<mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
				<mat-option *ngFor="let option of filteredOptions | async" [value]="option">
					<div style="width:100%;padding:1px;font-size:10pt;display:inline-flex">
						<span>{{option}}</span>
						<span class="spacercorto"></span>
						<button mat-button mat-icon-button aria-label="Clear" #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="eliminardireccion" (mouseover)="direccionaleiminar = option; menuTrigger.openMenu()">
							<mat-icon>close</mat-icon>
						</button>
					</div>
					<mat-menu #eliminardireccion="matMenu">
						<button mat-menu-item style="padding:5px;" (click)="borraopcion(direccionaleiminar)">
								Eliminar: {{direccionaleiminar}}
						</button>
					</mat-menu>
				</mat-option>
			</mat-autocomplete>
		</mat-form-field>
	</div>
	<div class="cajaherramientasincidenciaslarga" style="padding:5px; width:10%;font-size:8pt;">
		<div class="herramientaincidenciaspequena2" style="font-size:10pt;" (click)="muestramail('mailagenciadestino')">
			<mat-icon>mark_email_unread</mat-icon> Agencia Destino
		</div>
		<div class="herramientaincidenciaspequena2" style="font-size:10pt;" (click)="muestramail('mailagenciaorigen')">
			<mat-icon>mark_email_unread</mat-icon> Agencia Origen
		</div>
	</div>
	<div class="cajaherramientasincidenciaslarga" style="padding: 5px; width: 10%">
		<div class="herramientaincidenciaspequena2" style="font-size:10pt;" (click)="intranet(data.NumAlbaran)">
			<mat-icon>language</mat-icon> Intranet
		</div>
		<div *ngIf="dirExpediente" class="herramientaincidenciaspequena2" style="font-size:10pt;" (click)="expediente()">
			<mat-icon>folder_open</mat-icon> Expediente
		</div>
		
	</div>
	<div class="cajaherramientasincidencias overfondo" style="cursor:pointer;padding: 5px;" (click)="cancelar()">
		<mat-icon style="font-size:25pt">close</mat-icon><br>
		Cancelar
	</div>
</div>

<div *ngIf="!email && !veremails" class="contenedor">
	<div class="datosenvio">
		<div class="remitente">
			Fecha Recogida: <strong>{{parsefechahms(data.FechaFinalizacionRecogida)}}</strong><br>
			Remitente: <strong>{{data.Remitente}}</strong><br>
			Direccion: <strong>{{data.DireccionRemitente}}</strong><br>
			Codigo postal: <strong>{{data.CpRemitente}}</strong><br>
			Poblacion: <strong>{{data.PoblacionRemitente}}</strong><br>
			telefono: <strong>{{data.TelefonoRemitente}}</strong><br>
			Km Recogida: <span [class.kmup]="data.KmRemitente > 15"><strong>{{data.KmRemitente}}</strong></span><br>
			Observaciones Recogida: <strong>{{data.ObservacionesRecogida}}</strong><br>
			Contacto: <strong>{{data.ContactoRecogida}}</strong>
		</div>
		<div class="remitente">
			Destinatario: <strong>{{data.Destinatario}}</strong><br>
			Direccion: <strong>{{data.DireccionDestinatario}}</strong><br>
			Codigo postal: <strong>{{data.CpDestinatario}}</strong><br>
			Poblacion: <strong>{{data.PoblacionDestinatario}}</strong><br>
			telefono: <strong>{{data.TelefonoDestinatario}}</strong><br>
			Km Entrega: <span [class.kmup]="data.KmDestinatario > 15"><strong>{{data.KmDestinatario}}</strong></span><br>
			Observaciones Entrega: <strong>{{data.ObservacionesEntrega}}</strong>
		</div>
		<div class="remitente">
			Fecha Envio: <strong>{{parsefecha(data.FechaEnvio)}}</strong><br>
			Cliente: <strong>{{data.CodigoAbonado}}; {{data.NombreCliente}}</strong><br>
			Agencias: <strong>{{data.FranquiciaOrigen}}  </strong><mat-icon>arrow_right_alt</mat-icon><strong> {{data.FranquiciaDestino}}  </strong><mat-icon style="font-size:smaller">euro_symbol</mat-icon><strong>  {{data.FranquiciaClienteFacturacion}} </strong><br>
			Bultos: <strong>{{data.TotalBultos}}</strong> Servicio: <strong>{{data.Servicio}}  {{humanizafrecuencia(data.Frecuencia)}}</strong><br>
			<span *ngIf="data.RangoHorarioRecogidaManana || data.RangoHorarioRecogidaTarde"> Horario Recogida: <strong style="font-size: 10pt">{{data.RangoHorarioRecogidaManana}}<br>
			{{data.RangoHorarioRecogidaTarde}}</strong></span><br>
			Referencia: <strong>{{data.ReferenciaCliente}}</strong><br>
			Retorno: <span class="overfondo" *ngIf="data.NumeroEnvioRetorno" (dblclick)="bucaryabrir(data.NumeroEnvioRetorno)"><b>{{data.NumeroEnvioRetorno}}</b></span><br>
			Precursor: <span class="overfondo" *ngIf="data.NumeroAlbaranPrecursor" (dblclick)="bucaryabrir(data.NumeroAlbaranPrecursor)"><b>{{data.NumeroAlbaranPrecursor}}</b></span> 
		</div>
	</div>
	<div class="datosincidencia">
		<div class="remitente">
			Estado: <strong>{{data.Estado}}</strong><br>
			Anomalia: <strong>{{data.Tipo}}</strong><br>
			Motivo: <strong>{{data.AnomaliaObservaciones}}</strong>
		</div>
		<div class="comentarios">
			<mat-form-field class="coments">
			  <mat-label>Notas</mat-label>
			  <textarea matInput [(ngModel)]="data.notas" rows="5" placeholder="Notas"></textarea>
			</mat-form-field>
			<mat-form-field class="coments">
			  <mat-label>Albaran retorno/dev/reexp</mat-label>
			  <input matInput [(ngModel)]="data.albaranrd" placeholder="Albaran retorno/dev/reexp">
			</mat-form-field>
				<select name="seltiponota" [(ngModel)]="tiponota">
					<option [value]="item" *ngFor="let item of listatiponota">{{item}}</option>
				</select>  <button mat-raised-button style="margin-left: 10px;" *ngIf="tiponota && notaexpediente" (click)="notag3()"><mat-icon>send</mat-icon></button>
			<mat-form-field class="coments">
			  <mat-label>Nota expediente</mat-label>
			  <textarea matInput [(ngModel)]="notaexpediente" rows="5" placeholder="Nota Expediente"></textarea>
			</mat-form-field>
			
		</div>
	</div>
	
		
	
	<div class="datosincidencia">
		<div *ngIf="cantidadimagenes != 0" class="coments" style="position:relative;">
			<div *ngIf="cantidadimagenes >= 1 && numimagen >= 1" style="position:absolute; left:3px; top:145px;cursor:pointer;" (click)="anteriorimg()"><mat-icon class="fa-arrow-circle-left" style="font-size:25px">arrow_left</mat-icon>
		</div>
			<div *ngIf="cantidadimagenes >= 1 && numimagen < cantidadimagenes -1" style="position:absolute; right:3px; top:145px;cursor:pointer;" (click)="siguienteimg()"><mat-icon class="fa-arrow-circle-left" style="font-size:25px">arrow_right</mat-icon>
		</div>
			<img style="width:100%;height:300px;" (dblclick)="abreimagenes(data)" [src]="imagenactual"/>
		</div>
		<div *ngIf="cantidadimagenes == 0" style="width:100%;height:300px;position:relative;">
			<div style="position:absolute;top:145px;right:40%">No hay Imagenes...</div>
		</div>
		<div class="btnplantillas">
			 <button mat-raised-button *ngIf="nuevaplantilla.texto == null && !editplantilla" (click)="newplantilla()" matTooltip="Nueva Plantilla"><mat-icon>description</mat-icon></button>
			 <button mat-raised-button *ngIf="plantillaseleccionada && !editplantilla" (click)="editaplantilla()" matTooltip="Editar Plantilla"><mat-icon>drive_file_rename_outline</mat-icon></button>
			 <button mat-raised-button *ngIf="editplantilla" (click)="updateplantilla()" matTooltip="Actualizar Plantilla"><mat-icon>save</mat-icon></button>
			 <button mat-raised-button *ngIf="editplantilla" (click)="canceleditplantilla()" matTooltip="Cancelar Edicion Plantilla"><mat-icon>cancel_presentation</mat-icon></button>
			 <button mat-raised-button *ngIf="plantillaseleccionada && !editplantilla" (click)="eliminaPlantilla(plantillaseleccionada)" matTooltip="Eliminar Plantilla"><mat-icon>cancel_presentation</mat-icon></button>
			 <button mat-raised-button *ngIf="nuevaplantilla.texto != null" (click)="saveplantilla()" matTooltip="Guardar Plantilla"><mat-icon>save</mat-icon></button>
			 <button mat-raised-button *ngIf="nuevaplantilla.texto != null && !editplantilla" (click)="cancelplantilla()" matTooltip="Cancelar"><mat-icon>cancel_presentation</mat-icon></button>
		</div>
		<div class="nplantilla" *ngIf="nuevaplantilla.texto != null">
			<mat-form-field class="message">
			  <mat-label>Nueva Plantilla</mat-label>
			  <textarea matInput [(ngModel)]="nuevaplantilla.texto" rows="7" placeholder="Texto plantilla"></textarea>
			</mat-form-field>
		</div>
		<div class="nplantilla" *ngIf="editplantilla != null">
			<mat-form-field class="message">
			  <mat-label>Editar Plantilla</mat-label>
			  <textarea matInput [(ngModel)]="editplantilla.texto" rows="7" placeholder="Texto plantilla"></textarea>
			</mat-form-field>
		</div>
		<ul style="max-height:170px; overflow-y: scroll;">
			<li class="overfondo" style="list-style: none; padding: 2px; margin-bottom: 3px;border-bottom: 0.5px solid black" *ngFor="let plantilla of plantillas" (dblclick)="pasartextonota(plantilla.texto)" (click)="selecplantilla(plantilla)" [class.sinseleccionar]="plantillaseleccionada != plantilla" [class.seleccionada]="plantillaseleccionada == plantilla">
				{{plantilla.texto}}
			</li>
		</ul>	
	</div>
	<div style="overflow-y: scroll;max-width:30%;max-height: 500px;">
		<mat-list *ngFor="let email of listaemails; let indice = index">	
			<mat-card class="cardover" (dblclick)="abrirmensaje(email)">
				<mat-card-header>
					<mat-card-title style="font-size:8pt">
						<span *ngIf="email.from.emailAddress.address">De: {{email.from.emailAddress.address}}</span><span *ngIf="email.sender.emailAddress.address && !email.from.emailAddress.address">De: {{email.sender.emailAddress.address}}</span><span class="spacercorto"></span><span style="margin-left:5px;">{{email.sentDateTime | date:"dd/MM/yyyy HH:mm:ss"}}</span><br><span>Para: {{email.toRecipients[0].emailAddress.address}}</span><span class="spacercorto"></span><span style="margin-left:5px;">{{email.receivedDateTime | date:"dd/MM/yyyy HH:mm:ss"}}</span>
					</mat-card-title>
					<mat-card-subtitle style="font-size:8pt;color:black"><b>{{email.subject}}</b></mat-card-subtitle>
				</mat-card-header>	
				<mat-card-content style="font-size:7pt;padding:2px;">{{email.bodyPreview}}
				</mat-card-content>		
			</mat-card>
			<mat-divider></mat-divider>
		</mat-list>
	</div>

</div>



<div class="barraprincipalincidencias" *ngIf="email && !veremails">
	<div class="cajaherramientasincidencias overfondo" style="padding:5px;cursor:pointer;" (click)="enviarEmail()">
		<mat-icon style="font-size:28pt">send</mat-icon><br>
		Enviar
	</div>
	<div class="cajaherramientasincidenciaslarga" style="padding: 5px;">
		<div class="herramientaincidenciaspequena" style="cursor:pointer;" (click)="incluyeincidencia()">
			<mat-icon style="font-size:10pt">transit_enterexit</mat-icon>
			Incidencia Entrega
		</div>
		<div class="herramientaincidenciaspequena" style="font-size: 12px;" (click)="incluyeincidenciaorigen()">
			<mat-icon style="font-size:10pt">transit_enterexit</mat-icon>
			Incidencia Recogida
		</div>
		<div class="herramientaincidenciaspequena" (click)="incluyealbaran()">
			<mat-icon style="font-size:10pt">transit_enterexit</mat-icon>
			Incluir Albaran
		</div>
		<div class="herramientaincidenciaspequena" (click)="incluyeref()">
			<mat-icon style="font-size:10pt">transit_enterexit</mat-icon>
			Incluir Referencia
		</div>
		
	</div>
	<div class="cajaherramientasincidencias overfondo" style="padding: 5px;">
		<label for="file-upload" class="subir">
		    <mat-icon style="font-size:30pt">drive_folder_upload</mat-icon><br>
		    Adjuntar..
		</label>
		<input id="file-upload" multiple="true" (change)="fileChangeEvent($event)" type="file" style='display: none;'/>
	</div>
	<div *ngIf="cantidadimagenes != 0" class="cajaherramientasincidencias overfondo" (click)="adjuntarimgexpediente = 'true'" style="cursor:pointer;padding: 5px;">
		<mat-icon style="font-size:30pt">cloud_upload</mat-icon><br>
		    <div style="font-size:9pt;">Adjuntar imagen expediente</div>
	</div>
	<div *ngIf="!adjuntarimgexpediente" class="cajaherramientasincidencias overfondo" style="cursor:pointer;padding: 5px;" (click)="cancelamail()">
		<mat-icon style="font-size:30pt">cancel</mat-icon><br>
		Cancelar
	</div>
	<div *ngIf="adjuntarimgexpediente" class="cajaherramientasincidencias overfondo" style="cursor:pointer;padding: 5px;" (click)="adjuntarimgexpediente = undefined">
		<mat-icon style="font-size:30pt">cancel</mat-icon><br>
		Cancelar
	</div>
</div>
<div *ngIf="email && !veremails" class="mail">
	<div class="contienemensaje">
		<div *ngIf="mensajenuevo" style="width:100%;padding:0px;margin:0px;border:none;">
			<mat-card *ngIf="mensajenuevo.message.attachments" style="width:100%;padding:0px;">
				<mat-chip-list style="width:100%;"> 
					<mat-chip *ngFor="let archivo of mensajenuevo.message.attachments; let indice = index" style="font-size:8pt" [removable]='true' (removed)="eliminarAdjunto(indice)">{{archivo.name}}<mat-icon matChipRemove>cancel</mat-icon></mat-chip>
				</mat-chip-list>
			</mat-card>
			<mat-chip-list style="width:100%">
				<mat-chip *ngFor="let dir of mensajenuevo.message.toRecipients">
					{{dir.emailAddress.address}}
				</mat-chip>	
			</mat-chip-list>
			<mat-card style="width:100%;padding:0px;font-size:10pt;text-align:center;">
				<mat-form-field class="coments" appearance="outline" style="text-align:center;">
				<mat-label>Asunto</mat-label>
				  <input matInput [(ngModel)]="asuntomail" placeholder="Asunto">
				</mat-form-field>
			</mat-card>
		</div>
		<div *ngIf="!adjuntarimgexpediente">
			<ckeditor [editor]="Editor" [config]="{ 
				toolbar: {
					items: [
						'heading',
						'|',
						'undo',
						'redo',
						'|',
						'bold',
						'italic',
						'fontColor',
						'fontSize',
						'fontFamily',
						'|',
						'alignment',
						'bulletedList',
						'numberedList',
						'highlight',
						'|',
						'imageUpload',
						'insertTable',
						'|',
						'horizontalLine',
					]
				},
				language: 'es',
				image: {
					styles: [
						'full', 'side', 'alignLeft', 'alignCenter', 'alignRight'
					],
					toolbar: [
						'imageStyle:full',
						'imageStyle:side',
						'|',
						'imageStyle:alignLeft',
						'imageStyle:alignCenter',
						'imageStyle:alignRight',
						'|',
						'ImageResize',
						'imageTextAlternative',
					]
				},
				table: {
					contentToolbar: [
						'tableColumn',
						'tableRow',
						'mergeTableCells',
						'tableCellProperties',
						'tableProperties'
					]
				}
				}" [(ngModel)]="mensajenuevo.message.body.content">
			</ckeditor>
		</div>
		<div *ngIf="cantidadimagenes != 0 && adjuntarimgexpediente" class="coments" style="position:relative;">
			<div *ngIf="cantidadimagenes >= 1 && numimagen >= 1" style="position:absolute; left:3px; top:145px;" (click)="anteriorimg()"><mat-icon class="fa-arrow-circle-left" style="font-size:25px">arrow_left</mat-icon>
		</div>
			<div *ngIf="cantidadimagenes >= 1 && numimagen < cantidadimagenes -1" style="position:absolute; right:3px; top:145px;" (click)="siguienteimg()"><mat-icon class="fa-arrow-circle-left" style="font-size:25px">arrow_right</mat-icon>
		</div>
			<img style="width:100%;height:300px;" (dblclick)="adjuntarimagenexpediente(numimagen); adjuntarimgexpediente = undefined" [src]="imagenactual"/>
		</div>
	</div>		
	<div class="plantillas">
		<div class="btnplantillas">
			 <button mat-raised-button *ngIf="nuevaplantilla.texto == null && !editplantilla" (click)="newplantilla()" matTooltip="Nueva Plantilla"><mat-icon>description</mat-icon></button>
			 <button mat-raised-button *ngIf="plantillaseleccionada && !editplantilla" (click)="editaplantilla()" matTooltip="Editar Plantilla"><mat-icon>drive_file_rename_outline</mat-icon></button>
			 <button mat-raised-button *ngIf="editplantilla" (click)="updateplantilla()" matTooltip="Actualizar Plantilla"><mat-icon>save</mat-icon></button>
			 <button mat-raised-button *ngIf="editplantilla" (click)="canceleditplantilla()" matTooltip="Cancelar Edicion Plantilla"><mat-icon>cancel_presentation</mat-icon></button>
			 <button mat-raised-button *ngIf="plantillaseleccionada && !editplantilla" (click)="eliminaPlantilla(plantillaseleccionada)" matTooltip="Eliminar Plantilla"><mat-icon>cancel_presentation</mat-icon></button>
			 <button mat-raised-button *ngIf="nuevaplantilla.texto != null" (click)="saveplantilla()" matTooltip="Guardar Plantilla"><mat-icon>save</mat-icon></button>
			 <button mat-raised-button *ngIf="nuevaplantilla.texto != null && !editplantilla" (click)="cancelplantilla()" matTooltip="Cancelar"><mat-icon>cancel_presentation</mat-icon></button>
		</div>
		<div class="nplantilla" *ngIf="nuevaplantilla.texto != null">
			<mat-form-field class="message">
			  <mat-label>Nueva Plantilla</mat-label>
			  <textarea matInput [(ngModel)]="nuevaplantilla.texto" rows="7" placeholder="Texto plantilla"></textarea>
			</mat-form-field>
		</div>
		<div class="nplantilla" *ngIf="editplantilla != null">
			<mat-form-field class="message">
			  <mat-label>Editar Plantilla</mat-label>
			  <textarea matInput [(ngModel)]="editplantilla.texto" rows="7" placeholder="Texto plantilla"></textarea>
			</mat-form-field>
		</div>
		<ul>
			<li class="overfondo" style="list-style: none; padding: 2px; margin-bottom: 3px; border-bottom: 0.5px solid black" *ngFor="let plantilla of plantillas" (dblclick)="pasartextoplantilla(plantilla.texto)" (click)="selecplantilla(plantilla)" [class.sinseleccionar]="plantillaseleccionada != plantilla" [class.seleccionada]="plantillaseleccionada == plantilla">
				{{plantilla.texto}}
			</li>
		</ul>	
	</div>
	
</div>


<div class="barraprincipalincidencias" *ngIf="veremails && !email">
	<div class="cajaherramientasincidencias overfondo" style="padding: 5px;cursor:pointer;" (click)="contestaexpediente()">
		<mat-icon style="font-size:25pt">drive_file_move</mat-icon><br>
		Contestar<br>Expediente
	</div>
	<div class="cajaherramientasincidencias overfondo" *ngIf="abonadomail && veremails && !email" style="cursor:pointer;padding: 5px;" (click)="muestramail('mailcliente'); noveremails();">
		<mat-icon style="font-size:25pt">forward_to_inbox</mat-icon><br>
		Comunicar Cliente
	</div>
	<div class="cajaherramientasdireccionesmail" *ngIf="!abonadomail && veremails && !email" style="padding: 5px;">
		<div *ngIf="destinatarionuevo.length >= 1" class="fondooscuro" (click)="muestramail('mailalvuelo'); noveremails()" style="height:50%;margin-right:5px;cursor:pointer;"><mat-icon style="font-size:25pt">forward_to_inbox</mat-icon></div>
		<mat-form-field class="agregardestinatarios">
			<mat-label>Destinatarios</mat-label>
			<mat-chip-list #chipList aria-label="destinatarios selection">
				<mat-chip
					*ngFor="let destinatario of destinatarionuevo"
					[removable] = "true"
					(removed)="remove(destinatario)">
					{{destinatario}}
					<mat-icon matChipRemove>cancel</mat-icon>
				</mat-chip>
				<input
				placeholder="Nuevo Destinatario..."
				#destinatariosinput
				[formControl]="myControl"
				[matAutocomplete]="auto"
				[matChipInputFor]="chipList"
				[matChipInputSeparatorKeyCodes]="separatorKeysCodes"
				(matChipInputTokenEnd)="add($event)">
			</mat-chip-list>
			<mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
				<mat-option *ngFor="let option of filteredOptions | async" [value]="option">
					<div style="width:100%;padding:1px;font-size:10pt;display:inline-flex">
						<span>{{option}}</span>
						<span class="spacercorto"></span>
						<button mat-button mat-icon-button aria-label="Clear" #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="eliminardireccion" (mouseover)="direccionaleiminar = option; menuTrigger.openMenu()">
							<mat-icon>close</mat-icon>
						</button>
					</div>
					<mat-menu #eliminardireccion="matMenu">
						<button mat-menu-item style="padding:5px;" (click)="borraopcion(direccionaleiminar)">
								Eliminar: {{direccionaleiminar}}
						</button>
					</mat-menu>
				</mat-option>
			</mat-autocomplete>
		</mat-form-field>
	</div>	 
	<div class="cajaherramientasincidenciaslarga" style="padding: 5px; width: 23%">
		<div class="herramientaincidenciaspequena2" (click)="muestramail('mailagenciadestino'); noveremails()">
			<mat-icon>mark_email_unread</mat-icon> Agencia Destino
		</div>
		<div class="herramientaincidenciaspequena2" (click)="muestramail('mailagenciaorigen'); noveremails()">
			<mat-icon>mark_email_unread</mat-icon> Agencia Origen
		</div>
		
	</div>
	<div *ngIf="!contestarexpediente" class="cajaherramientasincidencias overfondo" style="cursor:pointer;padding: 5px;" (click)="cancelavistaemails()">
		<mat-icon style="font-size:25pt">cancel</mat-icon><br>
		Cancelar
	</div>
	<div *ngIf="contestarexpediente" class="cajaherramientasincidencias overfondo" style="cursor:pointer;padding: 5px;" (click)="cancelacontestaexpediente()">
		<mat-icon style="font-size:25pt">cancel</mat-icon><br>
		Cancelar
	</div>
</div>
<div *ngIf="veremails && !email" class="mail">
	<div *ngIf="contestarexpediente" style="display:inline-flex;">
		<div>
				<select name="seltiponota2" [(ngModel)]="tiponota">
					<option [value]="item" *ngFor="let item of listatiponota">{{item}}</option>
				</select>  <button mat-raised-button style="margin-left: 10px;" *ngIf="tiponota && notaexpediente" (click)="notag3()" matTooltip="Enviar nota"><mat-icon>read_more</mat-icon></button>
			<mat-form-field class="coments">
			  <mat-label>Nota expediente</mat-label>
			  <textarea matInput [(ngModel)]="notaexpediente" rows="5" placeholder="Nota Expediente"></textarea>
			</mat-form-field>
		</div>
		<div>
			<div class="btnplantillas">
			 <button mat-raised-button *ngIf="nuevaplantilla.texto == null && !editplantilla" (click)="newplantilla()" matTooltip="Nueva Plantilla"><mat-icon>description</mat-icon></button>
			 <button mat-raised-button *ngIf="plantillaseleccionada && !editplantilla" (click)="editaplantilla()" matTooltip="Editar Plantilla"><mat-icon>drive_file_rename_outline</mat-icon></button>
			 <button mat-raised-button *ngIf="editplantilla" (click)="updateplantilla()" matTooltip="Actualizar Plantilla"><mat-icon>save</mat-icon></button>
			 <button mat-raised-button *ngIf="editplantilla" (click)="canceleditplantilla()" matTooltip="Cancelar Edicion Plantilla"><mat-icon>cancel_presentation</mat-icon></button>
			 <button mat-raised-button *ngIf="plantillaseleccionada && !editplantilla" (click)="eliminaPlantilla(plantillaseleccionada)" matTooltip="Eliminar Plantilla"><mat-icon>cancel_presentation</mat-icon></button>
			 <button mat-raised-button *ngIf="nuevaplantilla.texto != null" (click)="saveplantilla()" matTooltip="Guardar Plantilla"><mat-icon>save</mat-icon></button>
			 <button mat-raised-button *ngIf="nuevaplantilla.texto != null && !editplantilla" (click)="cancelplantilla()" matTooltip="Cancelar"><mat-icon>cancel_presentation</mat-icon></button>
		</div>
		<div class="nplantilla" *ngIf="nuevaplantilla.texto != null">
			<mat-form-field class="message">
			  <mat-label>Nueva Plantilla</mat-label>
			  <textarea matInput [(ngModel)]="nuevaplantilla.texto" rows="7" placeholder="Texto plantilla"></textarea>
			</mat-form-field>
		</div>
		<div class="nplantilla" *ngIf="editplantilla != null">
			<mat-form-field class="message">
			  <mat-label>Editar Plantilla</mat-label>
			  <textarea matInput [(ngModel)]="editplantilla.texto" rows="7" placeholder="Texto plantilla"></textarea>
			</mat-form-field>
		</div>
		<ul style="max-height:170px; overflow-y: scroll;">
			<li class="overfondo" style="list-style: none; padding: 2px; margin-bottom: 3px; border-bottom: 0.5px solid black" *ngFor="let plantilla of plantillas" (dblclick)="pasartextonota(plantilla.texto)" (click)="selecplantilla(plantilla)" [class.sinseleccionar]="plantillaseleccionada != plantilla" [class.seleccionada]="plantillaseleccionada == plantilla">
				{{plantilla.texto}}
			</li>
		</ul>	
		</div>	
	</div>
	<div style="width:100%;padding:3px;font-size:10pt;">
		<div style="width:100%;padding:0px;margin:0px;border:none;">
			<mat-card *ngIf="emailseleccionado.adjuntos" style="width:100%;padding:0px;">
				<mat-chip-list style="width:100%;"> 
					<mat-chip *ngFor="let archivo of emailseleccionado.adjuntos; let indice = index" (click)="getadjunto(archivo)" style="font-size:8pt">{{archivo.name}}</mat-chip>
				</mat-chip-list>
			</mat-card>
			<mat-card style="width:100%;padding:2px;font-size:10pt;text-align:center;">
				De: {{emailseleccionado.from.emailAddress.address}}<span style="margin-left:5px;">{{emailseleccionado.sentDateTime | date:"dd/MM/yyyy HH:mm:ss"}}</span><br>Para: {{emailseleccionado.toRecipients[0].emailAddress.address}}<span style="margin-left:5px;">{{emailseleccionado.receivedDateTime | date:"dd/MM/yyyy HH:mm:ss"}}</span>
			</mat-card>
			<mat-card style="width:100%;padding:1px;font-size:10pt;text-align:center;">
				{{emailseleccionado.subject}}
			</mat-card>
		</div>
		<div [innerHtml]="emailseleccionado.body.content" style="overflow-y:scroll;max-height:500px;padding:10px;">
		</div>
	</div>
	<div style="overflow-y:scroll;max-height: 650px;">
		<mat-list *ngFor="let email of listaemails; let indice = index">	
			<mat-card class="cardover" [class.seleccionada]="emailseleccionado.id == email.id" (click)="abrirmensaje(email)">
				<mat-card-header>
					<mat-card-title style="width:100%;font-size:8pt">
						<div style="width:100%;display:inline-flex;">
							<div style="width:100%;">
								<span>De: {{email.from.emailAddress.address}}</span>
								<span style="margin-left:5px;">{{email.sentDateTime | date:"dd/MM/yyyy HH:mm:ss"}}</span>
								<br>
								<span>Para: {{email.toRecipients[0].emailAddress.address}}</span>
								<span style="margin-left:5px;">{{email.receivedDateTime | date:"dd/MM/yyyy HH:mm:ss"}}</span>
							</div>
						</div>
					</mat-card-title>
					<span class="spacercorto"></span>
					<div style="width:15%;margin-left:50px;display:inline-flex;">
						<button mat-icon-button class="overoverfondo" matTooltip="Linkar Albaran" (click)="mostrarcuadroalbaran()">
							<mat-icon>add_link</mat-icon>
						</button>
						<button mat-icon-button class="overoverfondo" matTooltip="Descargar Email" (click)="exportar(email)">
							<mat-icon>file_download</mat-icon>
						</button>
						<div class="cuadroalb" *ngIf="cuadroalbaran == true && emailseleccionado.id == email.id">
							<mat-form-field>
								<mat-label>Albaran a Linkar</mat-label>
								<input matInput [(ngModel)]="alb" placeholder="Albaran...">
							</mat-form-field>
							<div style="display:inline-flex;">
								<button mat-button *ngIf="alb" (click)="linkaremail(email, alb)">Linkar</button>
							</div>
						</div>
					</div>
					<mat-card-subtitle style="font-size:8pt;color:black">
						<b>{{email.subject}}</b>
					</mat-card-subtitle>
				</mat-card-header>	
				<mat-card-content style="font-size:7pt;padding:2px;">{{email.bodyPreview}}
				</mat-card-content>		
			</mat-card>
			<mat-divider></mat-divider>
		</mat-list>
	</div>			
</div>

