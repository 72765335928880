<div style="width:100%;height:100%">
	<div style="display:inline-flex;width:100%;border-bottom: 0.5px solid black;">
		<div (click)="guardartarjeta()"  matTooltip="Guardar" class="botonbarraherramientas fondooscuro">
			<span class="material-icons">save</span>
		</div>
		<div (click)="eliminartarjeta()"  matTooltip="Eliminar Tarjeta" class="botonbarraherramientas fondooscuro" style="margin-left:30px;">
			<span class="material-icons">highlight_off</span>
		</div>
		<span class="spacer"></span>
		<div (click)="closeDialog()"  matTooltip="Salir" class="botonbarraherramientas fondooscuro">
			<span class="material-icons">backspace</span>
		</div>
	</div>
	<div class="contenidodatos">
        <mat-form-field class="datovehiculo">
            <mat-label>Nombre</mat-label>
            <input matInput type="text" [(ngModel)]="data.nombre">
            <button mat-button *ngIf="data.nombre" matSuffix mat-icon-button aria-label="Clear" (click)="data.nombre=''">
                <mat-icon>close</mat-icon>
            </button>
        </mat-form-field>
        <mat-form-field class="datovehiculo">
            <mat-label>Matricula</mat-label>
            <input matInput type="text" [(ngModel)]="data.matricula" (ngModelChange)="data.matricula = $event" [matAutocomplete]="auto">
            <button mat-button *ngIf="data.matricula" matSuffix mat-icon-button aria-label="Clear" (click)="data.matricula=''">
                <mat-icon>close</mat-icon>
            </button>
            <mat-autocomplete #auto="matAutocomplete">
                <mat-option *ngFor="let vehiculo of vehiculos" [value]="vehiculo.matricula">
                    {{vehiculo.matricula}}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>
        <mat-form-field class="datovehiculo">
            <mat-label>Numero</mat-label>
            <input matInput type="text" [(ngModel)]="data.numero">
            <button mat-button *ngIf="data.numero" matSuffix mat-icon-button aria-label="Clear" (click)="data.numero=''">
            <mat-icon>close</mat-icon>
            </button>
        </mat-form-field>
        <mat-form-field class="datovehiculo">
            <mat-label>Caducidad</mat-label>
            <input matInput type="text" [(ngModel)]="data.caducidad">
            <button mat-button *ngIf="data.caducidad" matSuffix mat-icon-button aria-label="Clear" (click)="data.caducidad=''">
                <mat-icon>close</mat-icon>
            </button>
        </mat-form-field>
        <mat-form-field class="datovehiculo">
            <mat-label>Mensajero</mat-label>
            <input matInput type="text" [matAutocomplete]="auto4" [(ngModel)]="data.mensajero">
            <button mat-button *ngIf="data.mensajero" matSuffix mat-icon-button aria-label="Clear" (click)="data.mensajero=''">
                <mat-icon>close</mat-icon>
            </button>
            <mat-autocomplete #auto4="matAutocomplete">
                <mat-option *ngFor="let mensajero of mensajeros" [value]="mensajero.numero + '-' + mensajero.nombre">
                    {{mensajero.numero}}-{{mensajero.nombre}}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>
        <mat-form-field class="datovehiculo" style="width:90%">
            <mat-label>Notas</mat-label>
            <textarea  matInput rows="5" [(ngModel)]="data.notas"></textarea>
        </mat-form-field>
    </div>  
</div>        
