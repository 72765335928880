<div class="contenedorppal" *ngIf='identity'>
		<mat-card *ngIf="identity.role == 'Administrador'" style="width:20%;text-align:center;">
			<mat-card-header style="display:block;width:100%;text-align:center;">
			    	<div style="width:100%;text-align:center;">Configuracion para el Perfil:</div>
			    	<div style="width:100%;display:inline-block;">
			    		<select *ngIf="listaperfiles" style="width:80%;" name="selperfil" (change)="cargaperfil($event.target.value)">
							<option [value]="item" *ngFor="let item of listaperfiles">{{item}}</option>
						</select>
			    		<button mat-mini-fab color="warn" href="#victorModal" data-toggle="modal" style="margin-left:8px;">
			    			<mat-icon>delete</mat-icon>
				        </button>
			    	</div>
			  </mat-card-header>
			  <mat-card-content>

				<p>Abonados excluidos del perfil</p>
					<div class="cuadroab">
						<div *ngIf="perfil" class="listaabonados">
							<ul style="list-style: none;">
								<li (dblclick)="eliminaabonado(abonado)" *ngFor="let abonado of perfil.abonadosnoincluir">{{abonado}}</li>
							</ul>
						</div>
						<div class="operacionesab">
							<div class="ab">	
								<p>Abonado:</p>
								<input type="text" name="abno" class="cuadroabonado" [(ngModel)]="abincluir"><span class="botonab" matTooltip="Dejar de ver" (click)="agregaabonado(abincluir)"><mat-icon>person_remove</mat-icon></span><span class="botonab" matTooltip="ver" (click)="eliminaabonado(abincluir)"><mat-icon>person_add_alt</mat-icon></span>
							</div>	
							<div class="rango">
								<p>Rango:</p>
								<p>desde</p><input type="text" name="abini" class="cuadroabonado" [(ngModel)]="abini">
								<p>hasta</p><input type="text" name="abini" class="cuadroabonado" [(ngModel)]="abfin"><br>
								<span class="botonab1" matTooltip="Dejar de ver" (click)="agregaabloque(abini, abfin)"><mat-icon>person_remove</mat-icon></span><span class="botonab2" matTooltip="ver" (click)="eliminaabloque(abini, abfin)"><mat-icon>person_add_alt</mat-icon></span>
							</div>	
						</div>
					</div>
			  </mat-card-content>
		</mat-card>
		<div style="width:20%;margin-left:5px;">
			<mat-card *ngIf="identity.role == 'Administrador'" style="text-align:center;">
				<p>Nuevo Perfil</p>
				<input mat-input type="text" name="perfilnuevo" class="cuadroabonado" [(ngModel)]="newperfil"/><span class="botonab" (click)="nuevoperfil(newperfil)"><mat-icon>person_add_alt</mat-icon></span>
			</mat-card>
			<mat-card style="width:100%;text-align:center;">
				<p style="width:100%;text-align:center;">Datos G3</p>
					<mat-form-field appearance="outline" style="width:100%;text-align:center;">
					  <mat-label style="color:black;">Usuario</mat-label>
					  <input matInput type="text" name="usuario" class="cuadroabonado" [(ngModel)]="identity.options.g3.usuario" style="padding:1px;color:black;" (keyup.enter)="grabaroc()">
					</mat-form-field>
					<mat-form-field appearance="outline" style="width:100%;text-align:center;">
					  <mat-label style="color:black;">Password</mat-label>
					  <input matInput type="text" name="pass" class="cuadroabonado" [(ngModel)]="identity.options.g3.password" style="padding:1px;color:black;" (keyup.enter)="grabaroc()">
					</mat-form-field>
			</mat-card>
		</div>
		<div style="width:20%;margin-left:5px;">
			<mat-card style="display:block;width:100%;" *ngIf="identity.role == 'Administrador'">
				<button mat-raised-button color="primary" style="margin-top: 8px;" (click)="codigo()">
					Activar Acceso Office365
				</button>
			</mat-card>
		</div>	

</div>		


<div id="victorModal" class="modal fade centramodal">
    <div class="modal-dialog centrado">
        <div class="modal-content centrado">
            <div class="modal-header">
                <h4 class="modal-title">¿Estás seguro?</h4>
            </div>
            <div class="modal-body">
                <p>¿Seguro que quieres borrar este elemento?</p>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-default" data-dismiss="modal">Cerrar</button>
                <button type="button" class="btn btn-danger" (click)="borraperfil()" data-dismiss="modal">Eliminar</button>
            </div>
        </div>
    </div>
</div>		