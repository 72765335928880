<div style="width:100%">
	<div style="width:100%;display:inline-flex;">
		<div class="fondooscuro" style="cursor:pointer;" (click)="guardar()"><mat-icon class="example-tab-icon">save</mat-icon><span style="margin-left:15px;">Guardar</span></div>
		<div class="fondooscuro" style="margin-left:500px;cursor:pointer;" (click)="closeDialog()"><mat-icon class="example-tab-icon">backspace</mat-icon><span style="margin-left:15px;">Cancelar</span></div>
	</div>
	<div style="width:100%">
		<div style="display:inline-flex">
			<mat-form-field appearance="outline" style="text-align:center;font-size:12pt">
			  <mat-label style="color:black;" (click)="picker.open()"><b>Fecha Deuda</b></mat-label>
				<input matInput [matDatepicker]="picker" [formControl]="date" (dateChange)="setfechadeuda()">
				<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
  				<mat-datepicker #picker></mat-datepicker>
			</mat-form-field>
			<mat-form-field *ngIf="!data.mensajero" appearance="outline" style="text-align:center;font-size:12pt">
			  <mat-label style="color:black;"><b>Mensajero</b></mat-label>
				<input matInput [(ngModel)]="data.mensajero">
			</mat-form-field>
			<mat-form-field appearance="outline" style="width:300px;text-align:center;font-size:12pt">
			  <mat-label style="color:black;"><b>Motivo</b></mat-label>
				<input matInput [(ngModel)]="data.motivo">
			</mat-form-field>
			<mat-form-field appearance="outline" style="text-align:center;font-size:12pt">
			  <mat-label style="color:black;"><b>Importe</b></mat-label>
				<input matInput type="number" [(ngModel)]="data.importe">
			</mat-form-field>
		</div>
	</div>
</div>
