import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ClienteService } from '../../servicios/cliente.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-gestionusuarios',
  templateUrl: './gestionusuarios.component.html',
  styleUrls: ['./gestionusuarios.component.css'],
  providers: [ClienteService]
})
export class GestionusuariosComponent implements OnInit {

	public usuarios = new MatTableDataSource<any>();
  public mensajeros = new MatTableDataSource<any>();
  public columns = [
    {
      columnDef: 'nombre',
      header: 'nombre'
    },
    {
      columnDef: 'role',
      header: 'role'
    },
    {
      columnDef: 'numero',
      header: 'numero'
    },
    {
      columnDef: 'emailsIncidencias',
      header: 'emailsIncidencias'
    }
  ];
  public columns2 = [
    {
      columnDef: 'numero',
      header: 'numero'
    },
    {
      columnDef: 'nombre',
      header: 'nombre'
    },
    {
      columnDef: 'direccion',
      header: 'direccion'
    },
    {
      columnDef: 'telefono',
      header: 'telefono'
    }
  ];
  public displayedColumns = this.columns.map(c => c.columnDef);
  public displayedColumns2 = this.columns2.map(c => c.columnDef);
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('sBSort') sBSort: MatSort;
  constructor(
  	private _router: Router,
    private _clienteService: ClienteService
  ) {

  	this._clienteService.getusuarios().subscribe(
  		response=>{
  			this.usuarios.data = response.usuarios;
  			},
  		error=>{
  			console.log(<any>error);
  	});
    this._clienteService.getmensajeros().subscribe(
      response=>{
        this.mensajeros.data = response.mensajeros;
        },
      error=>{
        console.log(<any>error);
    });

   }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.usuarios.sort = this.sort;
    this.mensajeros.sort = this.sBSort;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.usuarios.filter = filterValue.trim().toLowerCase();
    this.mensajeros.filter = filterValue.trim().toLowerCase();
  }

  abrecliente(id){
    this._router.navigate(['cliente/' + id]);
  }

  enrutar(ruta){
    this._router.navigate([ruta]);
  }

}
