import { OnInit, Component, ViewChild} from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { ActivatedRoute } from '@angular/router';
import { ClienteService } from '../../servicios/cliente.service';
import { IncidenciasService } from '../../servicios/incidencias.service';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import * as moment from 'moment';
import { Incidencia } from '../../models/incidencia';
import { MatTableDataSource } from '@angular/material/table';
import { MatTable } from '@angular/material/table';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition, MatSnackBarConfig } from '@angular/material/snack-bar';
import { AppService } from '../../servicios/app.service';

@Component({
  selector: 'cobromensajero',
  templateUrl: './cobromensajero.component.html',
  styleUrls: ['./cobromensajero.component.css'],
  providers: [ClienteService, IncidenciasService, AppService]
})

export class CobromensajeroComponent implements OnInit{
    public mensajero;
    public identity;
    public totalcolumns;
    public listasfiltros;
    public filtros;
    public dataSource;
    public selection;
    public seleccion;
    public direcciones;
    public category;
    public cobros;
    public gG3;
    public totalimporte;
    public importeReembolsos;
    public importeopen;
    public openr;
    public opene;
    public adelantos;
    public totalimporteseleccion;
    public importeReembolsosseleccion;
    public importeopenseleccion;
    public openrseleccion;
    public openeseleccion;
    public adelantosseleccion;
    public listaoa;
    public tipocobro;
    public queryoptions;
    public load;
    @ViewChild(MatTable) myTable!: MatTable<any>;
  constructor(
  		private _route: ActivatedRoute,
	    private _clienteService: ClienteService,
      private _incidenciasService: IncidenciasService,
      private _snackBar: MatSnackBar,
      private dateAdapter: DateAdapter<Date>,
      private _appservice: AppService
  ){
    this.identity = this._appservice.getIdentity();
    this.load = true;
    this.queryoptions = {paginacion:'no'};
    this.listaoa = ['000000', '', null];
    this.dataSource = new MatTableDataSource<Incidencia>();
    this.totalcolumns = ['select','NumAlbaran','Tipo','Anomalia','gestionado','AnomaliaMotivo','AnomaliaObservaciones','CodigoAbonado','CodigoFranquicia','ContactoEntrega','ContactoRecogida','CpDestinatario','CpRemitente','Destinatario','DireccionDestinatario','DireccionRemitente','Estado','Fase','FechaEntrega','FechaEnvio','EntregarAPartirDe','FechaRecogida','FechaFinalizacionRecogida','FechaConcertacion','HoraMaximaEntrega','HoraMaximaRecogida','IdFiscal','AR','ImporteAR','Kilos','KmDestinatario','KmRemitente','Motivo','NombreCliente','NombreSolicitante','ObservacionesEntrega','ObservacionesRecogida','PoblacionDestinatario','PoblacionRemitente','ProvinciaDestinatario','ProvinciaRemitente','RangoHorarioEntregaManana','RangoHorarioEntregaTarde','RangoHorarioRecogidaManana','RangoHorarioRecogidaTarde','Remitente','Servicio','TelefonoDestinatario','TelefonoRemitente','TipoComision','TipoEntrega','TipoGeografico','TipoMercancia','TipoRecogida','TotalBultos','TipoCobro','FranquiciaDestino','FranquiciaOrigen','FranquiciaTercera','FranquiciaClienteFacturacion','CodigoCupon','NumeroAlbaranPrecursor','NumeroEnvioRetorno','TipoGestion','TieneRetorno','ConfirmacionInmediata','EntregaSabado','TipoRetorno','Usuario','UsuarioModificacion','ReferenciaCliente','EsInternacional','GeneradoPor','Aviso','TotalMedidas','Operacion','Frecuencia','Contador','NifRemitente','NifDestinatario','MensajeroEntrega','MensajeroRecogida','PuntoRecogida','PuntoEntrega','PuntoAnomalia','PuntoEstado','PuntoConcertacion','TipoPuntoConcertacion','albaranrd','notas','fechacobrado','usuariocobro','Importe'];
    this.listasfiltros = {
        listamotivo:[
          {Motivo:'', valor:''},
          {Motivo:'OT0700', valor: 'Clasificado en plataforma'},
          {Motivo:'OE0062', valor: 'Depositado en Franquicia de destino'},
          {Motivo:'OE0600', valor: 'Mensajero de entrega asignado'},
          {Motivo:'OR0600', valor: 'Mensajero de recogida asignado'},
          {Motivo:'OE0500', valor: 'Recibido en franquicia de destino'},
          {Motivo:'OR0200', valor: 'Recogida finalizada'},
          {Motivo:'OS0200', valor: 'Recogida pendiente'},
          {Motivo:'OE0100', valor: 'Tránsito interno de entrega'}
          ],
        listagestionados:['','Pendiente Cliente','Pendiente Agencia','Para Entregar','Recoge en agencia','Entregado','Cancelado','Especial/vigilar'],
        listaestado:['EntregaPendiente','EnvioCancelado','RecogidaFinalizada','RecogidaPendiente','TransitoInternoEntrega','EntregaCancelada','EntregaFinalizada'],
        listanomostrar:['EnvioCancelado','EntregaCancelada']
     }
     this.filtros = {
      filtroprincipal: ''
      };
     this.selection = new SelectionModel<Incidencia>(true, []);
     this.importeReembolsosseleccion = 0;
     this.importeopenseleccion = 0;
     this.totalimporteseleccion = 0;
     this.openrseleccion = 0;
     this.openeseleccion = 0;
     this.adelantosseleccion = 0;
     this.dateAdapter.setLocale('es');
     this.dateAdapter.getFirstDayOfWeek = () => { return 1; };
     this._route.params.subscribe(
      params=>{
        this._clienteService.getcliente(params.numero).subscribe(
          response=>{
            this.mensajero = response.cliente;
            this.getcobros();
          });
      });    
  }

  ngOnInit(): void {
  }

  aviso(texto, subtexto) {
    this._snackBar.open(texto,subtexto,{
      duration: 4000,
      horizontalPosition: "center",
      verticalPosition: "top",
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  isAllSelected() {
    if(this.dataSource.filteredData){
      const numSelected = this.selection.selected.length;
      const numRows = this.dataSource.filteredData.length;
      return numSelected === numRows;
    }else{
      const numSelected = this.selection.selected.length;
      const numRows = this.dataSource.data.length;
      return numSelected === numRows;
    }  
  }

  /** Selects all rows if they are not all selected; otherwise clear selection.*/ 
  masterToggle() {
    if(this.dataSource.filteredData){
        this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.filteredData.forEach(row => this.selection.select(row));
    }else{  
      this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.data.forEach(row => this.selection.select(row));
    }
    this.mapearseleccion();    
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: Incidencia): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    this.mapearseleccion();
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.identity.options['ordencamposreembolsos']['totales'], event.previousIndex, event.currentIndex);
    this.actualizacliente();
  }

  actualizacliente(){
    sessionStorage.setItem('identity', JSON.stringify(this.identity));
    this._clienteService.saveCliente(this.identity);
  }

  actualizamensajero(){
    this._clienteService.updateCliente(this.mensajero).subscribe(
      response=>{
            if(response.message == 'usuario actualizado') this.aviso('mensajero cobrado','ok');
        },
      error=>{
        console.log(<any>error);
      }  
    );
  }

  selectipocobro(tipo){
    if(this.tipocobro != tipo) this.mapearcobros();
    switch(tipo){
      case 'reembolsos':
        this.dataSource.data = this.dataSource.data.filter(dato=> dato.AR == 1).filter(data=>data.FranquiciaDestino == '02601');
        this.tipocobro = tipo;
        break;
      case 'adelantos':
        this.dataSource.data = this.dataSource.data.filter(dato=> dato.AR == 0).filter(data=>data.FranquiciaOrigen == '02601');
        this.tipocobro = tipo;
        break;
      case 'orecogidos':
        this.dataSource.data = this.dataSource.data.filter(dato=> dato.TipoCobro === 0).filter(data=>data.FranquiciaOrigen == '02601');
        this.tipocobro = tipo;
        break;
      case 'oentregados':
        this.dataSource.data = this.dataSource.data.filter(dato=> dato.TipoCobro === 1).filter(data=>data.FranquiciaDestino == '02601');
        this.tipocobro = tipo;
        break;
      case 'todos':
        this.mapearcobros();
        this.tipocobro = undefined;
        break;
      default: 
        this.mapearcobros();
        this.tipocobro = undefined;         
    }
  }

   onselectcolumnastotales(value){
    this.identity.options.ordencamposreembolsos.totales = value;
    this.actualizacliente();
  }

  onDateFechaenvio(){

   var fechainicial = moment(this.filtros['FechaEnvioinicial']).set({hour:0,minute:0,second:0,millisecond:0});
   if(this.filtros.FechaEnviofinal == null){
    var fechafinal = moment(this.filtros['FechaEnvioinicial']).set({hour:22,minute:0,second:0,millisecond:0});
   }else{
     var fechafinal = moment(this.filtros['FechaEnviofinal']).set({hour:22,minute:0,second:0,millisecond:0});
   }
   var query = {$and:[{FechaEnvio:{$gte:fechainicial}},{FechaEnvio:{$lte:fechafinal}}],MensajeroEntrega:this.mensajero.numero,FranquiciaDestino:'02601',$or:[{ImporteAR: {$gt: 1}}, {$and:[{CodigoAbonado:{$in:['000000','']}},{TipoCobro: 1}]}], cobrado:{$ne:true}};
   //this.cobrosactualesentrega(query);
  }

  onDateFecharecogida(){
    var fechainicial = moment(this.filtros['FechaRecogidainicial']).set({hour:0,minute:0,second:0,millisecond:0});
   if(this.filtros.FechaRecogidafinal == null){
    var fechafinal = moment(this.filtros['FechaRecogidainicial']).set({hour:22,minute:0,second:0,millisecond:0});
   }else{
    var fechafinal = moment(this.filtros['FechaRecogidafinal']).set({hour:22,minute:0,second:0,millisecond:0});
   }
   var query = {MensajeroRecogida:this.mensajero.numero};// {$and:[{FechaRecogida:{$gte:fechainicial}},{FechaRecogida:{$lte:fechafinal}}],MensajeroRecogida:this.mensajero.numero,FranquiciaOrigen:'02601'};//,cobrado:{$ne:true}};//$or:[{ImporteAR: {$gt: 1}}, {$and:[{CodigoAbonado:{$in:['000000','']}},{TipoCobro: 1}]}]};
   //this.cobrosactualesentrega(query);
  }

  onDateFecharecogidafinalizada(){
   var fechainicial = moment(this.filtros['FechaRecogidafinalizadainicial']).set({hour:0,minute:0,second:0,millisecond:0});
   if(this.filtros.FechaRecogidafinalizadafinal == null){
    var fechafinal = moment(this.filtros['FechaRecogidafinalizadainicial']).set({hour:22,minute:0,second:0,millisecond:0});
   }else{
    var fechafinal = moment(this.filtros['FechaRecogidafinalizadafinal']).set({hour:22,minute:0,second:0,millisecond:0});
   }
   var query = {MensajeroRecogida:this.mensajero.numero};// {$and:[{FechaFinalizacionRecogida:{$gte:fechainicial}},{FechaFinalizacionRecogida:{$lte:fechafinal}}],FranquiciaOrigen:'02601',MensajeroRecogida:this.mensajero.numero};
   
   //this.cobrosactualesentrega(query);
  }

  onDateFechaentrega(){
    var fechainicial = moment(this.filtros['FechaEntregainicial']).set({hour:0,minute:0,second:0,millisecond:0});
   if(this.filtros.FechaEntregafinal == null){
    var fechafinal = moment(this.filtros['FechaEntregainicial']).set({hour:22,minute:0,second:0,millisecond:0});
   }else{
    var fechafinal = moment(this.filtros['FechaEntregafinal']).set({hour:22,minute:0,second:0,millisecond:0});
   }
    var query = {$and:[{FechaEntrega:{$gte:fechainicial}},{FechaEntrega:{$lte:fechafinal}}],MensajeroEntrega:this.mensajero.numero,FranquiciaDestino:'02601',$or:[{ImporteAR: {$gt: 1}}, {$and:[{CodigoAbonado:{$in:['000000','']}},{TipoCobro: 1}]}]};
    //this.cobrosactualesentrega(query);
  }

  onDateFecharcobro(){
    this.load = false;
    var fecha = moment(this.filtros['Fechacobro']).format('DD/MM/YYYY');
    var query = {$or:[{MensajeroEntrega:this.mensajero.numero},{MensajeroRecogida:this.mensajero.numero}],fechacobrado:fecha,cobrado:true};
    this.buscarcobros(query);
  }

  mapearcobros(){
    this.load = false;
    this.importeReembolsos = 0;
    this.importeopen = 0;
    this.totalimporte = 0;
    this.openr = 0;
    this.opene = 0;
    this.adelantos = 0;
    this.dataSource.data = [];
    this.dataSource.data = this.cobros.map((inc, index)=>{
      if(!inc.Total){
        inc['Total'] = 0;
      }
      if(inc.AR == 0 && inc.FranquiciaOrigen == '02601'){
        this.getadelantomapeo(inc.Id, index);
        if(inc.FranquiciaClienteFacturacion == '02601'){
          if(inc.Total <= 0){
            this.getvaloracionmapeo(inc.Id, inc.TipoCobro, index);
          }else{
            this.importeopen = this.importeopen + inc.Total;
            this.openr = this.openr + inc.Total;
            this.totalimporte = this.totalimporte + inc.Total;
          }
        }
      }
      if(inc.AR == 0 && inc.FranquiciaDestino == '02601'){
        this.importeReembolsos = this.importeReembolsos + inc.ImporteAR;
        this.totalimporte = this.totalimporte + inc.ImporteAR;
        if(inc.FranquiciaClienteFacturacion == '02601'){
          if(inc.Total <= 0){
            this.getvaloracionmapeo(inc.Id, inc.TipoCobro, index);
          }else{
            this.importeopen = this.importeopen + inc.Total;
            this.opene = this.opene + inc.Total;
            this.totalimporte = this.totalimporte + inc.Total;
          }
        }
      }

      if(inc.AR == 1 && inc.FranquiciaDestino == '02601'){
        this.importeReembolsos = this.importeReembolsos + inc.ImporteAR;
        this.totalimporte = this.totalimporte + inc.ImporteAR;
        if(inc.FranquiciaClienteFacturacion == '02601'){
          if(inc.Total <= 0){
            this.getvaloracionmapeo(inc.Id, inc.TipoCobro, index);
          }else{
            this.importeopen = this.importeopen + inc.Total;
            this.opene = this.opene + inc.Total;
            this.totalimporte = this.totalimporte + inc.Total;
          }
        }
      }
      if(inc.AR == -1 && inc.Total <= 0){  
        this.getvaloracionmapeo(inc.Id, inc.TipoCobro, index);
      }
      if(inc.AR == -1 && inc.Total >= 1){
        this.importeopen = this.importeopen + inc.Total;
        if(inc.TipoCobro == 0) this.openr = this.openr + inc.Total;
        if(inc.TipoCobro == 1) this.opene = this.opene + inc.Total;
        this.totalimporte = this.totalimporte + inc.Total;
      }  
      inc['position'] = index;
      return inc;
    });
    this.load = true;
  }

  mapearseleccion(){
    this.load = false;
    if(this.selection.selected.length >= 1){
      this.importeReembolsosseleccion = 0;
      this.importeopenseleccion = 0;
      this.totalimporteseleccion = 0;
      this.openrseleccion = 0;
      this.openeseleccion = 0;
      this.adelantosseleccion = 0;
      this.selection.selected.map((inc)=>{
        if(inc.AR == 0 && inc.FranquiciaDestino == '02601'){
          this.totalimporteseleccion = this.totalimporteseleccion + inc.ImporteAR;
          this.importeReembolsosseleccion = this.importeReembolsosseleccion + inc.ImporteAR;
          if(inc.Total && inc.Total >= 1){
            this.totalimporteseleccion = this.totalimporteseleccion + inc.Total;
            this.openeseleccion = this.openeseleccion + inc.Total;
          }
        }
        if(inc.AR == 0 && inc.FranquiciaOrigen == '02601'){
          this.adelantosseleccion = this.adelantosseleccion + inc.Total;
          this.totalimporteseleccion = this.totalimporteseleccion + inc.Total;
        }    
        if(inc.AR == 1){
          this.totalimporteseleccion = this.totalimporteseleccion + inc.ImporteAR;
          this.importeReembolsosseleccion = this.importeReembolsosseleccion + inc.ImporteAR;
        }
        if(inc.TipoCobro == 0 && inc.AR != 0){
          this.importeopenseleccion = this.importeopenseleccion + inc.Total;
          this.totalimporteseleccion = this.totalimporteseleccion + inc.Total;
          this.openrseleccion = this.openrseleccion + inc.Total;
        } 
        if(inc.TipoCobro == 1 && inc.AR != 0){
          this.importeopenseleccion = this.importeopenseleccion + inc.Total;
          this.openeseleccion = this.openeseleccion + inc.Total;
          this.totalimporteseleccion = this.totalimporteseleccion + inc.Total;
        }
      });
    }
    this.load = true;    
  }

  limpiarfiltros(){
    this.dataSource.filter = '';
    this.filtros = {filtroprincipal: ''};
  }  

  getcobros(){
    this.load = false;
    this.limpiarfiltros();
    var fechafinal = moment().set({hour:23,minute:0,second:0,millisecond:0}).toISOString();
    var params = {
    cobrado:{$ne:true},
    Estado:{$in:['EntregaFinalizada', 'RecogidaFinalizada']},
    $or:[
      {$and:[
        {FechaEntrega:{$lte:fechafinal}},
        {MensajeroEntrega:this.mensajero.numero},
        {FranquiciaDestino:'02601'},
        {$or:[
          {ImporteAR: {$gt: 1}},
          {$and:[
            {CodigoAbonado:{$in:this.listaoa}},
            {TipoCobro: 1}
          ]}
        ]}
      ]},
      {$and:[
        {FechaFinalizacionRecogida:{$lte:fechafinal}},
        {MensajeroRecogida:this.mensajero.numero},
        {FranquiciaOrigen:'02601'},
        {$or:[
          {AR:0},
          {$and:[
            {CodigoAbonado:{$in:this.listaoa}},
            {TipoCobro: 0}
          ]}
        ]}
      ]}
    ]};    
    this._incidenciasService.getincidencias(params, this.queryoptions).subscribe(
      response =>{
        if(response.incidencias && response.incidencias.length >= 1){
          this.cobros = response.incidencias;
          this.mapearcobros();
        }else{
          this.cobros = [];
          this.totalimporte = 0;
          this.mapearcobros();
        } 
        this.load = true; 
      },
      error =>{
        console.log(<any>error);
      });
  }

  getvaloracionmapeo(incId, incTipoCobro, index){
    this._incidenciasService.getvaloracion(incId).subscribe(
        response=>{
            if(response.datos){
              this.importeopen = this.importeopen + response.datos.Totales.Total;
              if(incTipoCobro == 0) this.openr = this.openr + response.datos.Totales.Total;
              if(incTipoCobro == 1) this.opene = this.opene + response.datos.Totales.Total;
              this.totalimporte = this.totalimporte + response.datos.Totales.Total;
              this.dataSource.data[index].Total = response.datos.Totales.Total;
              this.myTable.renderRows(); 
            }else{
              this.dataSource.data[index].Total = 0;
            }  
          },
        error=>{
          console.log(<any>error);
      });
  }

  getadelantomapeo(incId, index){
    this._incidenciasService.getcomision(incId).subscribe(
      response=>{
        this.adelantos = this.adelantos + response.datos;
        this.totalimporte = this.totalimporte - response.datos;
        this.dataSource.data[index].Total = this.dataSource.data[index].Total >=1? this.dataSource.data[index].Total - response.datos : 0 - response.datos;
        this.myTable.renderRows(); 
      },
      error=>{
        console.log(error);
        console.log(<any>error);
      }
    );
  }

  buscarcobros(busqueda){
    this.load = false;
    this.queryoptions = {pagination:false}
    this._incidenciasService.getincidencias(busqueda, this.queryoptions).subscribe(
      response =>{
        if(response.incidencias && response.incidencias.length >= 1){
          this.cobros = response.incidencias;
          this.mapearbusqueda(this.cobros);
        }else{
          this.cobros = [];
          this.mapearbusqueda(this.cobros);
        } 
        this.load = true; 
      },
      error =>{
        console.log(<any>error);
      });
  }

  mapearbusqueda(cobros){
      this.load = false;
      this.importeReembolsos = 0;
      this.importeopen = 0;
      this.totalimporte = 0;
      this.openr = 0;
      this.opene = 0;
      this.adelantos = 0;
      this.dataSource.data = [];
      this.dataSource.data = cobros.map((inc)=>{
        if(!inc.Total){
          inc['Total'] = 0;
        }
        if(inc.AR == 0 && inc.FranquiciaOrigen == '02601'){
          this.adelantos = this.adelantos + inc.Total;
          this.totalimporte = this.totalimporte + inc.Total;
        }
        if(inc.AR == 0 && inc.FranquiciaDestino == '02601'){
          this.totalimporte = this.totalimporte + inc.ImporteAR;
          this.importeReembolsos = this.importeReembolsos + inc.ImporteAR;
        }  
        if(inc.AR == 1 && inc.FranquiciaDestino == '02601'){
          this.totalimporte = this.totalimporte + inc.ImporteAR;
          this.importeReembolsos = this.importeReembolsos + inc.ImporteAR;
        }
        if(inc.TipoCobro == 0 && inc.AR != 0){
          this.importeopen = this.importeopen + inc.Total;
          this.totalimporte = this.totalimporte + inc.Total;
          this.openr = this.openr + inc.Total;
        } 
        if(inc.TipoCobro == 1 && inc.AR != 0){
          this.importeopen = this.importeopen + inc.Total;
          this.opene = this.opene + inc.Total;
          this.totalimporte = this.totalimporte + inc.Total;
        }
        return inc;
      });
      this.load = true;
  }

  seleccionar(row){
    this.seleccion = row;
    //this.selecionra = this.tipora(row.AR);
  }

  tipora(tipo){
    if(tipo == 1) return '(R)';
    if(tipo == 0) return '(A)';
  }

  categoriaseleccionada(categoria){
    this.category = categoria;
  }

  onselectfiltro(filtro, valor){
     if(valor == ''){
      this.filtros[filtro] = undefined;
      this.eliminafiltro(filtro);
    }
  }

  eliminafiltro(filtro){
  } 

  cobrar(){
    this.load = false;
    var cantidad = 0;
    var cobrados = this.dataSource.data;
    this.selection.selected.forEach((cobro) =>{
      cobro.cobrado = true;
      cobro.fechacobrado = moment().format('DD/MM/YYYY');
      cobro.usuariocobro = this.identity.nombre;
      cobrados.splice(cobrados.findIndex(cb=>cb.NumAlbaran == cobro.NumAlbaran), 1);
      this._incidenciasService.guardarincidencia(cobro).subscribe(
        response =>{
          },
         error =>{
          console.log(<any>error);
         } 
      );
      if(cantidad >= this.selection.selected.length -1){
        this.selection.clear();
        this.mensajero['pendiente'] = moment().format('DD/MM/YYYY');
        if(cobrados && cobrados.length >=1){
          this.mapearcobrar(cobrados);
        }else{
          this.dataSource.data = [];
          this.mapearcobrar(this.dataSource.data);
        }
        this.load = true;
        this.actualizamensajero();
      }else{
        cantidad++
      }  
    });
  }

  mapearcobrar(albaranes){
    this.load = false;
    this.importeReembolsos = 0;
    this.importeopen = 0;
    this.totalimporte = 0;
    this.openr = 0;
    this.opene = 0;
    this.adelantos = 0;
    this.dataSource.data = [];
    this.dataSource.data = albaranes.map((inc, index)=>{
      if(!inc.Total){
        inc['Total'] = 0;
      }
      if(inc.AR == 0 && inc.FranquiciaOrigen == '02601'){
        this.getadelantomapeo(inc.Id, index);
      }
      /*if(inc.AR == 0 && inc.Total >=1 && inc.FranquiciaOrigen == '02601'){
        inc.ImporteAR = inc.Total;
        this.adelantos = this.adelantos + inc.Total;
        this.totalimporte = this.totalimporte - inc.Total;
      }*/
      if(inc.AR == 0 && inc.FranquiciaDestino == '02601'){
        this.importeReembolsos = this.importeReembolsos + inc.ImporteAR;
        this.totalimporte = this.totalimporte + inc.ImporteAR;
      }
      if(inc.AR == 1 && inc.FranquiciaDestino == '02601'){
        this.importeReembolsos = this.importeReembolsos + inc.ImporteAR;
        this.totalimporte = this.totalimporte + inc.ImporteAR;
      }
      if(inc.AR == -1 && inc.Total <= 0){  
        this.getvaloracionmapeo(inc.Id, inc.TipoCobro, index);
      }
      if(inc.AR == -1 && inc.Total >= 1){
        this.importeopen = this.importeopen + inc.Total;
        if(inc.TipoCobro == 0) this.openr = this.openr + inc.Total;
        if(inc.TipoCobro == 1) this.opene = this.opene + inc.Total;
        this.totalimporte = this.totalimporte + inc.Total;
      }  
      inc['position'] = index;
      return inc;
    });
    this.load = true;
  }

  desmarcar(){
    this.load = false;
    var cantidad = 0;
    var cobrados = this.dataSource.data;
    this.selection.selected.forEach(cobro =>{
      cobro.cobrado = false;
      cobro.fechacobrado = moment().format('DD/MM/YYYY');
      cobro.usuariocobro = this.identity.nombre;
      cobrados.splice(cobrados.findIndex(cb=>cb.NumAlbaran == cobro.NumAlbaran), 1);
      this._incidenciasService.guardarincidencia(cobro).subscribe(
        response =>{
          },
         error =>{
          console.log(<any>error);
         } 
      );
      if(cantidad >= this.selection.selected.length -1){
        this.selection.clear();
        this.mensajero['pendiente'] = moment().format('DD/MM/YYYY');
        if(cobrados && cobrados.length >=1){
          this.mapearbusqueda(cobrados);
        }else{
          this.dataSource.data = [];
          this.mapearbusqueda(this.dataSource.data);
        }
        this.load = true;
        this.actualizamensajero();
      }else{
        cantidad++
      }  
    });
  }
}



