import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ClienteService } from '../../servicios/cliente.service';
import { saveAs } from "file-saver";
import { UploadService } from '../../servicios/upload.service';
import { AppService } from '../../servicios/app.service';

@Component({
  selector: 'app-misarchivos',
  templateUrl: './misarchivos.component.html',
  styleUrls: ['./misarchivos.component.css'],
  providers: [ClienteService, UploadService, AppService]
})

export class MisarchivosComponent implements OnInit {

	public title: string;
	public cliente;
	public archivos;
  public identity;
  public filesToUload: Array<File>;
  public status: string;
  public archivoaborrar;
  public enlaces;
  public displayedColumns;

  constructor(
  	private _route: ActivatedRoute,
    private _clienteService: ClienteService,
    private _uploadService: UploadService,
    private _appservice: AppService
  ) {
    this.identity = this._appservice.getIdentity();
    if(this.identity.role == 'Administrador'){
      this.displayedColumns = ['archivo', 'Eliminar', 'CreaEnlace'];
    }else{
      this.displayedColumns = ['archivo'];
    }
    this._route.params.subscribe(params=>{
      if(params.id){
        var clienteid = params.id;
      }else{
        var clienteid = this.identity._id;
      }        
      this._clienteService.getcliente(clienteid).subscribe(
        response =>{
          this.cliente = response.cliente;
          this.title = 'Archivos Abonado: '+ response.cliente.nombre;
          this._clienteService.getArchivos(this.cliente._id, this.cliente.numero).subscribe(
            response =>{
              this.archivos = response;
              this.enlaces = {};
                if(this.cliente.nuevoarchivo == this.identity.role){
                    this.cliente.nuevoarchivo = "";
                }
              this.archivos.forEach((archivo)=>{
                this.enlaces[archivo] = '';
              });
              this._clienteService.updateCliente(this.identity);
            },
            error =>{
              console.log(error);
            } 
          );
        },
        error=>{
          console.log(error);
        }
      );            
      },
      error =>{
        console.log(error);
      }
    );
    
  }

  ngOnInit() {
    
  }

  descargaArchivo(archivo){
    var ruta = './archivosclientes/' + this.cliente.numero + '/';
    this._clienteService.descargaFactura(archivo, this.cliente._id, ruta).subscribe(
      response =>{
          saveAs(response, archivo);
        },
      error => {
        console.log(<any>error);
      }  
     );


  }


fileChangeEvent(fileInput: any){
  this.filesToUload = <Array<File>>fileInput.target.files;
}
  
subir(){
  this._uploadService.makeFilesRequest(this.cliente.numero, this.filesToUload).subscribe(
    response=>{
        this.status = "subido";
        this._clienteService.getArchivos(this.cliente._id, this.cliente.numero).subscribe(
          response =>{
            this.archivos = response;
            this.enlaces = {};
            if(this.identity.role != 'Administrador'){
              this.cliente.nuevoarchivo = 'Administrador';
            }else{
              this.cliente.nuevoarchivo = this.cliente.role;
            }
            this.archivos.forEach((archivo)=>{
              this.enlaces[archivo] = '';
            });
            this._clienteService.updateCliente(this.cliente);
          },
          error =>{
                    console.log(error);
          } 
        );        
      },
     error=>{
      console.log(<any>error);
     }
  );    
    

}


enviaaviso(identificacion){
  /* var rol = 'Administrador';
   var name = 'configuracion';
  this._configuracionService.verconfig(name, this.token, rol).subscribe(
    response=>{
      var config = response.config;
      var enlace = "http://www.mensasur.es";
      if(identificacion.role == 'Administrador'){
        this.email = this.cliente.emailsIncidencias;
        var mensaje = '<div><p>esta recibiendo este email porque se ha subido un archivo a su cuenta en la plataforma Mensasur Getafe</p> <p>siga el siguiente enlace para acceder: <br> <a href='+ enlace+'>'+enlace+'</a></p></div>'
      }else{
        this.email = config.mailnotificaciones;    
        var abonado = identificacion.numero;
        var nombreabonado = identificacion.nombre;
        var mensaje = '<div><p>esta recibiendo este email porque el cliente: ' + abonado +' - ' + nombreabonado + ' <br> ha subido un archivo a la plataforma Mensasur Getafe</p> siga el siguiente enlace para acceder: <a href='+ enlace+'>'+enlace+'</a></div>'
      }
      
      var asunto = 'Archivo subido a Mensasur Getafe';
      this._clienteService.avisomail(identificacion._id, this.email, mensaje, asunto).subscribe(
        response=>{
            console.log(response);
          },
        error =>{
          console.log(<any>error);
        }  
      );
    },
    error=>{
      console.log(<any>error);
  }); */ 
}

setarchivoaborrar(archivo){
  this.archivoaborrar = archivo;
}

borraarchivo(archivo){
  var ruta = './archivosclientes/' + this.cliente.numero + '/';
  this._clienteService.borrararchivo(archivo, this.cliente.numero, ruta).subscribe(
      response =>{
          this._clienteService.getArchivos(this.cliente._id, this.cliente.numero).subscribe(
            response =>{
              this.archivos = response;
            },
            error =>{
              console.log(<any>error);
            }  
          );              
          console.log(response.message);
        },
      error =>{
          console.log(<any>error);
      }  
  );

  this.archivoaborrar = "";
  
}

creaenlace(archivo){
  let enlace = {
    ruta: './archivosclientes/' + this.cliente.numero + '/' + archivo,
		nombre: archivo,
		url: 'https://www.mensasur.es/descarga'
  }
  this._clienteService.creaenlace(enlace).subscribe(
    response=>{
      this.enlaces[archivo] = response.enlace;
      if(!this.displayedColumns.includes('Enlace')){
        this.displayedColumns.push('Enlace');
        this.displayedColumns.push('Password');
      }  
    }
  )
}


}
