export class Gasto{
	constructor(
		public _id: string,
        public fecha: Date,
        public tarjeta: string,
        public matricula: string,
        public importe: number,
        public tienda: string,
        public mensajero: string,
        public imagenes: [{}],
        public notas: string
	){}
}	