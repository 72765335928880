import { Component, OnInit, Inject } from '@angular/core';
import { IncidenciasService } from '../../servicios/incidencias.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
  selector: 'app-imagenesexpediente',
  templateUrl: './imagenesexpediente.component.html',
  styleUrls: ['./imagenesexpediente.component.css'],
  providers: [IncidenciasService]
})
export class ImagenesexpedienteComponent implements OnInit {
	public dirExpediente;
	public imagenes;
	public imagenactual;
	public numimagen: number;
	public cantidadimagenes: number;
  public gG3;

  constructor(
  	private _incidenciasservice: IncidenciasService,
  	public ventanaImagenes: MatDialogRef<ImagenesexpedienteComponent>,
  	@Inject(MAT_DIALOG_DATA) public data
  	) {
      this.gG3 = sessionStorage.getItem('galletag3');
     }

  ngOnInit(): void {
  	this.dirExpediente = 'https://www2-vpc.mrw.es/gestion3/Operativo/Popups/ExpedienteEnvio2?Arguments=Id:' + this.data.Id + '_NumAlbaran:' + this.data.NumAlbaran + '_Estado:' + this.data.Estado + '_Fase:' + this.data.Fase + '_FranquiciaOrigDest:undefined_tipoExpediente:_FechaEnvio=' + this.data.FechaEnvio;
      this._incidenciasservice.imgExp(this.data.NumAlbaran, this.dirExpediente).subscribe(
        response =>{
            if(response.datos){
              this.imagenes = new Array();
              var tmp = response.datos;
              this.cantidadimagenes = tmp.length;
              this.numimagen = 0;
              this.imagenactual = 'data:image/jpg;base64,' + tmp[this.numimagen].ImageBase64;
              tmp.forEach((imagen)=>{
                this.imagenes.push('data:image/jpg;base64,' + imagen.ImageBase64);
              });
            }
        },
         error =>{
          console.log(<any>error);
         } 
      );
  }

  cancelar() {
    this.ventanaImagenes.close();
  }

  anteriorimg(){
    this.numimagen = this.numimagen -1;
    this.imagenactual = this.imagenes[this.numimagen];
    
  }

  siguienteimg(){
    this.numimagen = this.numimagen +1;
    this.imagenactual = this.imagenes[this.numimagen];
    
  }

}
