import { Component, OnInit, ViewChild } from '@angular/core';
import { ConfiguracionService } from '../../servicios/configuracion.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ClienteService } from '../../servicios/cliente.service';
import { AppService } from '../../servicios/app.service';
import { saveAs } from "file-saver";
import { Router } from '@angular/router';
import * as xlsx from 'xlsx';
import { isThisTypeNode } from 'typescript';

@Component({
  selector: 'inicio',
  templateUrl: './inicio.component.html',
  styleUrls: ['./inicio.component.css'],
  providers: [ConfiguracionService, ClienteService]
})
export class InicioComponent implements OnInit {
  public title: string;
  public mailcomercial: string;
  public mailadmin: string;
  public mailclientes: string;
  public admin: string;
  public comercial: string;
  public clientes: string;
  public slides;
  public cantidadimagenes: number;
  public identity;
  public datosmensajeros;
  public vermensajeros = false;
  public aactual = new Date().getFullYear();
  @ViewChild('myCarousel', {static: false}) myCarousel;

  constructor(
      private _configuracionService: ConfiguracionService,
      private _sanitizer: DomSanitizer,
      private _clienteService: ClienteService,
      private _router: Router,
      private _appservice: AppService
    ) {
  	this.title = 'Bienvenido a Mensasur Getafe S.L.';
  	this.mailcomercial = 'comercial.02601@grupomrw.com';
    this.mailadmin = 'administracion.02601@grupomrw.com';
    this.mailclientes = 'clientes.02601@grupomrw.com';
    this.admin = 'Administracion';
    this.comercial = 'comercial';
    this.clientes = 'clientes';
    this.slides = new Array();
    this.cantidadimagenes = 0;
    this.datosmensajeros = {};
    this.identity = this._appservice.getIdentity();
   }

  ngOnInit() {
    this.setyear();
    this._configuracionService.getarchivosimagen().subscribe(
      response=>{
          var imagenes = response;
          this.cantidadimagenes = imagenes.length;
          imagenes.forEach(img=>{
            this.agregaimagen(img);
          });
          if(this.identity)this.verexcel();
        },
      error=>{
        console.log(<any>error);
      }  
    );
    this._appservice.comprobartokens();
  }

  enrutar(ruta){
    this._router.navigate([ruta]);
  }

  setyear(){
    let mes = new Date().getMonth();
    let ano = new Date().getFullYear();
    if(mes == 11){
      this.aactual = ano + 1;
    }else{
      this.aactual = ano;
    }
  }

  vermensajerostoggle(){
    this.vermensajeros = !this.vermensajeros;
  }

  agregaimagen(imagen){
    this._configuracionService.getimagenportada(imagen).subscribe(
      response=>{
          var uri = URL.createObjectURL(response);
          var tmp = this._sanitizer.bypassSecurityTrustUrl(uri);
          var img = {path: tmp}
          this.slides.push(img);
         },
      error=>{
        console.log(<any>error);
      }  
    );
  }

  eventoscarrusel(evento){
    if(evento.name == "transitionend" && this.myCarousel.slideCounter == this.myCarousel.cellLength -1){
        setTimeout(()=>{
           this.myCarousel.select(0);
        }, this.myCarousel.autoplayInterval)
    } 
    if(evento.name == "click"){
      this.myCarousel.next();
    }  
  }

  descargageneral(archivo){
    let ruta = 'archivosclientes/general/';
    this._clienteService.descargaFactura(archivo, this.identity._id, ruta).subscribe(
      response =>{
          if(response.message){
            alert('no existe el archivo');
          }else{
            saveAs(response, archivo);
          }  
        },
      error => {
        console.log(<any>error);
      }  
     );
  }

  verexcel(){
    let ruta = 'archivosclientes/general/';
    let archivo = 'mensajeros.xlsx';
    this._clienteService.descargaFactura(archivo, this.identity._id, ruta).subscribe(
      response =>{
          this.datosmensajeros['filas'] = [];
          let workBook = null;
          let worksheet = null;
          const reader = new FileReader();
          reader.onload = (event) => {
            const data = reader.result;
            workBook = xlsx.read(data, { type: 'binary' });
            var first_sheet_name = workBook.SheetNames[0];
            worksheet = workBook.Sheets[first_sheet_name];
            var datosmsg = xlsx.utils.sheet_to_json(worksheet,{raw:true});
            this.datosmensajeros['cabeceras'] = Object.keys(datosmsg[0]);
            for(let i = 0;i<datosmsg.length;i++){
              let json = Object.keys(datosmsg[i]);
              var fila = [];
              var contador = 0;
              for(let j of json){
                fila.push(datosmsg[i][j]);
                if(contador == json.length -1){
                  this.datosmensajeros.filas.push(fila);
                }else{
                  contador++
                }
              }
            }
          }
          reader.readAsBinaryString(response);  
        },
      error => {
        console.log(<any>error);
      }  
    );
  }
}