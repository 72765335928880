import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ClienteService } from '../../servicios/cliente.service';
import { Cliente } from '../../models/cliente';
import { Office365Service } from '../../servicios/office365.service';
import { AppService} from '../../servicios/app.service';
import * as Editor from '../../ck5editor/ckeditor.js';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition, MatSnackBarConfig } from '@angular/material/snack-bar'; 

@Component({
  selector: 'datoscliente',
  templateUrl: './datoscliente.component.html',
  styleUrls: ['./datoscliente.component.css'],
  providers: [Office365Service, ClienteService, AppService]
})
export class DatosclienteComponent implements OnInit {
	
	public title: string;
  public identity;
	public status: string;
  public role: string;
  public id: string;
  public email;
  public ocultaboton = false;
  public listaroles;
  public carpetasmail;
  public tokenoffice;
  public dirmailraiz;
  public dircarpetasmail;
  public Editor = Editor;
  @Input('cliente') cliente:Cliente = null;
  @Input('abonado') abonado = null;

  constructor(
  	private _route: ActivatedRoute,
  	private _router: Router,
    private _clienteService: ClienteService,
    private _office365Service: Office365Service,
    private _appservice: AppService,
    public _snackBar: MatSnackBar
  ) {
    this.identity = this._appservice.getIdentity();
    this.carpetasmail = new Array();
    this.dirmailraiz = "https://graph.microsoft.com/v1.0/me/mailFolders?$top=100";
    this.dircarpetasmail = "https://graph.microsoft.com/v1.0/me/mailFolders?$top=100";
    this.listaroles = ['Administrador', 'operador-admin', 'operador-incidencias', 'operador', 'operador-plus', 'Mensajero'];
   }

  ngOnInit(): void {
    if(!this.cliente){
      this._route.params.subscribe(
        params=>{
          if(params && params.id){
            var idcliente = params.id;
            this._clienteService.getcliente(idcliente).subscribe(
              response =>{
                this.cliente = response.cliente;
                this.title = "Datos del cliente: " + this.cliente.numero;
                if(this.cliente.role == 'cliente' && this.identity.role != 'cliente'){
                  this.getabonado();
                }
                if(this.cliente.role != 'cliente'){
                  this.getcarpetasmail();
                }
              },
              error => {
                console.log(<any>error);
              }  
            );
          }else{
            if(this.cliente.role == 'cliente' && this.identity.role == 'Administrador'){
              this.getabonado();
            }
            this.ocultaboton = true;
          }
        }
      );
    }  
  }

  onSubmit(form){
  	this.guardarcliente();
   	this.status = "success";
    this.email = "administracion.02601@grupomrw.com";
    var mensaje = "<div><p>esta recibiendo este email porque el cliente: " + this.cliente.numero + " - " + this.cliente.nombre +",</p><p>ha modificado sus datos en la plataforma de Mensasur Getafe</p><div>";
    var asunto = 'Datos de cliente modificados en web Mensasur Getafe'
    this._clienteService.avisomail(this.cliente._id, this.email, mensaje, asunto).subscribe(
      response =>{
          console.log(response);
        },
      error =>{
        console.log(<any>error);
      }  
    );
  }


  verarchivoscliente(){
    this._router.navigate(['archivos/' + this.cliente._id]);
  }

  verfacturascliente(){
    this._router.navigate(['carpetas/' + this.cliente._id + '/' + this.cliente.numero]);
  }

  borracliente(id){
    this._clienteService.borracliente(id).subscribe(
      response =>{
          if(this.cliente.role == 'cliente'){
            this._router.navigate(['clientes']);
           }else{
            this._router.navigate(['gestionusuarios']);
           } 
          
        },
       error =>{
        console.log(<any>error);
       } 
    );
  }

  aviso(texto, subtexto) {
    this._snackBar.open(texto,subtexto,{
      duration: 4000,
      horizontalPosition: "center",
      verticalPosition: "top",
    });
}

  guardarcliente(){
    this._clienteService.saveCliente(this.cliente);
    this.aviso('Datos del Usuario Actualizados', 'ok');
  }

  actualizaabonado(){
    this._clienteService.actualizaabonado(this.abonado).subscribe(
      response=>{
          if(response.abonadoupdated){
            this.aviso('abonado actualizado', 'ok');
          }
        },
      error=>{
        console.log(<any>error);
      }  
    )
  }

  checkcarpeta(check, carpeta){
    if(check == true){
      this.cliente.options.carpetasmail.push(carpeta);
      this.guardarcliente();
    }else{
      if(this.cliente.options.carpetasmail.includes(carpeta) == true){
        var indice:number = this.cliente.options.carpetasmail.indexOf(this.cliente.options.carpetasmail.find(fldr => fldr == carpeta));
        this.cliente.options.carpetasmail.splice(indice, 1);
        this.guardarcliente();
      } 
    }    
  }

  limpiarcarpetas(){
    this.cliente.options.carpetasmail.splice(0, this.cliente.options.carpetasmail.length);
    this.cliente.options.carpetasinteraccion.splice(0, this.cliente.options.carpetasinteraccion.length);
    this.carpetasmail = new Array();
    this.guardarcliente();
  }


  checkcarpetainter(check, carpeta){
    if(check == true){
      this.cliente.options.carpetasinteraccion.push(carpeta);
      this.guardarcliente();
    }else{
      if(this.cliente.options.carpetasinteraccion.includes(carpeta) == true){
        var indice:number = this.cliente.options.carpetasinteraccion.indexOf(this.cliente.options.carpetasinteraccion.find(fldr => fldr == carpeta));
        this.cliente.options.carpetasinteraccion.splice(indice, 1);
        this.guardarcliente();
      } 
    }    
  }

  getcarpetasmail(){
    this._office365Service.solicitaremails(this.dircarpetasmail).subscribe(
      response =>{
        var next = response['@odata.nextLink'];
        var carpetas = response.value;
        carpetas.forEach(carpeta=>{
          if(this.cliente.options.carpetasmail.includes(carpeta.id) == true){
            carpeta['check'] = true;
          }else{
            carpeta['check'] = false;
          };
          if(this.cliente.options.carpetasinteraccion.includes(carpeta.id) == true){
            carpeta['checkinter'] = true;
          }else{
            carpeta['checkinter'] = false;
          };
          this.carpetasmail.push(carpeta);
        });
        if(next){ 
          this.dircarpetasmail = next;
          this.getcarpetasmail();
        }else{
          this.dircarpetasmail = this.dirmailraiz;
        } 
    },
    error =>{
      console.log(<any>error);
    });

  }

  enrutar(ruta): void{
    this._router.navigate([ruta]);
  }

  getabonado(){
    let query = {Abonado: this.cliente.numero}
    this._clienteService.getcecos(query).subscribe(
      response=>{
        if(response.cecos && response.cecos.length >= 1){
          this.abonado = response.cecos[0];
        }
      }
    );      
  }

  abreabonadog3(){
    let dir = 'https://www2-vpc.mrw.es/gestion3/Comercial/Clientes/EdicionCliente?Arguments=' + this.abonado.datosAbonado.Id + '_clienteEdit'
    window.open(dir);
  }
 
}
