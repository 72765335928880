<div style="width:100%;height:100%">
	<div style="display:inline-flex;width:100%;border-bottom: 0.5px solid black;">
		<div (click)="guardarvehiculo()"  matTooltip="Guardar" class="botonbarraherramientas fondooscuro">
			<span class="material-icons">save</span>
		</div>
		<label matTooltip="Agregar Archivo" for="file-upload" class="botonbarraherramientas fondooscuro">
			<span class="material-icons">attach_file</span>
		</label>
		<input id="file-upload" multiple="true" (change)="fileChangeEvent($event)" type="file" style='display: none;'/>
		<span class="spacer"></span>
		<div (click)="closeDialog()"  matTooltip="Salir" class="botonbarraherramientas fondooscuro">
			<span class="material-icons">backspace</span>
		</div>
	</div>
	<div class="contenidodatos">
		<div class="fila">
			<div class="datosvehiculo">
				<mat-form-field class="datovehiculo">
					<mat-label>Marca</mat-label>
					<input matInput type="text" [(ngModel)]="data.marca">
					<button mat-button *ngIf="data.marca" matSuffix mat-icon-button aria-label="Clear" (click)="data.marca=''">
						<mat-icon>close</mat-icon>
					</button>
				</mat-form-field>
				<mat-form-field class="datovehiculo">
					<mat-label>Modelo</mat-label>
					<input matInput type="text" [(ngModel)]="data.modelo">
					<button mat-button *ngIf="data.modelo" matSuffix mat-icon-button aria-label="Clear" (click)="data.modelo=''">
						<mat-icon>close</mat-icon>
					</button>
				</mat-form-field>
				<mat-form-field class="datovehiculo">
					<mat-label>Matricula</mat-label>
					<input matInput type="text" [(ngModel)]="data.matricula" (ngModelChange)="data.matricula = $event.toUpperCase()">
					<button mat-button *ngIf="data.matricula" matSuffix mat-icon-button aria-label="Clear" (click)="data.matricula=''">
					<mat-icon>close</mat-icon>
					</button>
				</mat-form-field>
				<mat-form-field class="datovehiculo">
					<mat-label>Fecha de Alta</mat-label>
					<input matInput type="text" [(ngModel)]="data.fechaalta">
					<button mat-button *ngIf="data.fechaalta" matSuffix mat-icon-button aria-label="Clear" (click)="data.fechaalta=''">
						<mat-icon>close</mat-icon>
					</button>
				</mat-form-field>
				<mat-form-field class="datovehiculo" style="width:90%">
					<mat-label>Notas</mat-label>
					<textarea  matInput rows="5" [(ngModel)]="data.notas"></textarea>
				</mat-form-field>
				<mat-form-field *ngIf="this.data.fechabaja" class="datovehiculo">
					<mat-label>Fecha de Baja</mat-label>
					<input matInput type="text" [(ngModel)]="data.fechabaja">
				</mat-form-field>
				<div *ngIf="!this.data.fechabaja" (click)="baja()"  matTooltip="Dar de Baja" class="fondooscuro" style="width:50px;cursor:pointer">
					<span class="material-icons md-48">do_not_disturb_off</span>
				</div>
				<div *ngIf="this.data.fechabaja" (click)="alta()"  matTooltip="Dar de Alta" class="fondooscuro" style="width:50px;cursor:pointer">
					<span class="material-icons md-48">bus_alert</span>
				</div>
			</div>
			<div class="datosimagenes">
				<mat-tab-group>
					<mat-tab>
						<ng-template mat-tab-label>
						  <span class="material-icons" style="margin-right:5px;">paid</span>Gastos
						</ng-template>
						  <div style="display:inline-flex;width:100%;border-bottom: 0.5px solid black;">
							  <div matTooltip="Nuevo Gasto" class="botonbarraherramientas fondooscuro" (click)="nuevogasto()" style="height:30px;margin-top:10px;">
								  <span class="material-icons" style="vertical-align:middle;">add_shopping_cart</span>
							  </div>
							  <div class="botonbarraherramientas" style="margin-left:100px;">
								  <mat-form-field style="text-align:center;font-size:10pt">
									  <mat-label style="color:black;" (click)="picker.open()"><b>Rango Fechas</b></mat-label>
									  <mat-date-range-input [rangePicker]="picker" (click)="picker.open()">
									  <input matStartDate placeholder="Fecha Inicio" [(ngModel)]="fechainicio">
									  <input matEndDate placeholder="Fecha fin" [(ngModel)]="fechafin" (dateChange)="onDaterangofechas()">
									  </mat-date-range-input>
									  <button mat-button *ngIf="fechainicio" matSuffix mat-icon-button aria-label="Clear" (click)="fechainicio=null;fechafin=null;gastosactuales()">
									  <mat-icon>close</mat-icon>
									  </button>
									  <mat-date-range-picker #picker></mat-date-range-picker>
								  </mat-form-field>
							  </div>
						  </div>
						<ul style="list-style:none;margin-top:30px;">
							<li *ngFor="let gasto of gastos" class="fondooscuro" style="cursor:pointer" (dblclick)="abregasto(gasto)">
							  <span *ngIf="gasto.tarjeta" class="material-icons" style="margin-right:5px;">credit_score</span> {{gasto.fecha | date:'dd/MM/yyyy'}}   {{gasto.matricula}}   {{gasto.tienda}}   {{gasto.tarjeta}}    {{gasto.mensajero}}   {{gasto.importe}}€  {{gasto.notas}}
							</li>
						</ul>
						<div style="margin-left: 60px;">
							importe total sin tarjeta: {{importesintarjeta.toFixed(2)}}€      importe total con tarjeta: {{importecontarjeta.toFixed(2)}}€      total gastos: {{importetotal.toFixed(2)}}€
						</div>
					</mat-tab>
					<mat-tab>
					  <ng-template mat-tab-label>
						<span class="material-icons" style="margin-right:5px;">collections</span>Imagenes
					  </ng-template>
						<div *ngIf="cantidadimagenes != 0" class="coments" style="position:relative;" (mouseover)="imagehover = imagenactual" (mouseleave)="imagehover = undefined">
							<div *ngIf="cantidadimagenes >= 1 && numimagen >= 1" style="position:absolute; left:3px; top:145px;" (click)="anteriorimg()"><mat-icon style="font-size:25pt">arrow_back</mat-icon>
							</div>
							<div *ngIf="cantidadimagenes >= 1 && numimagen < cantidadimagenes -1" style="position:absolute; right:3px; top:145px;" (click)="siguienteimg()"><mat-icon style="font-size:25pt">arrow_forward</mat-icon>
							</div>
							<div [ngClass]="{'menuvisible': imagehover, 'menuoculto': !imagehover}" style="width:100%;display:inline-flex;background:rgba(201, 196, 196, 0.623); position:absolute;top:40px;">
								<div mat-icon-button matTooltip="Descargar" class="fondooscuro" (click)="getarchivo(imagenactual)" style="margin-left:40px;cursor:pointer;">
									<span class="material-icons md-48">download</span>
								</div>
								<span class="spacer"></span>
								<div mat-icon-button matTooltip="Eliminar" class="fondooscuro" (click)="eliminaimagen(imagenactual)" style="margin-right:40px;cursor:pointer;">
									<span class="material-icons md-48">highlight_off</span>
								</div>	
							</div>
							<img style="width:100%;height:500px;" [src]="imagenactual.contentBytes"/>
						</div>
						<div *ngIf="cantidadimagenes == 0" style="width:100%;height:500px;position:relative;">
							<div style="position:absolute;top:145px;right:40%">No hay Imagenes...</div>
						</div>
					</mat-tab>
			  
					<mat-tab>
					  <ng-template mat-tab-label>
						<span class="material-icons" style="margin-right:5px;">folder</span>Archivos
					  </ng-template>
					  <ul style="list-style:none;margin-top:30px;">
						  <li *ngFor="let archivo of archivos" class="fondooscuro" style="width:100%;display:inline-flex;cursor:pointer;">
							<div (click)="getarchivo(archivo)"><span class="material-icons" style="margin-right:5px;">description</span>{{archivo.name}}</div><span class="spacer"></span><div><span class="material-icons fondoclaro" style="cursor:pointer;" (click)="eliminaimagen(archivo)">delete_forever</span></div>
						  </li>
					  </ul>
					</mat-tab>
					<mat-tab>
						<ng-template mat-tab-label>
							<span class="material-icons" style="margin-right:5px;">checklist</span>Revisiones
						</ng-template>
						<ul *ngIf="data.revisiones" style="list-style:none;margin-top:30px;">
							<li *ngFor="let revision of data.revisiones; let indice = index">
								<div style="width:100%;display:inline-flex"><div (dblclick)="edicionrevision = indice;editarevision(revision);">{{revision.fecha}}  -  {{revision.datos}}</div><span class="spacer"></span><div (click)="eliminarevision(indice)" class="fondooscuro" style="cursor:pointer;"><span class="material-icons">delete_forever</span></div></div>
							</li>
						</ul>
						<div style="display:inline-flex;width:100%">
							<mat-form-field class="datovehiculo">
								<mat-label>Fecha</mat-label>
								<input matInput type="text" [(ngModel)]="nuevarevision.fecha">
								<button mat-button *ngIf="nuevarevision.fecha" matSuffix mat-icon-button aria-label="Clear" (click)="nuevarevision.fecha=''">
									<mat-icon>close</mat-icon>
								</button>
							</mat-form-field>
							<mat-form-field class="datovehiculo" style="width:70%">
								<mat-label>Reparaciones/Revisado</mat-label>
								<input matInput type="text" [(ngModel)]="nuevarevision.datos">
								<button mat-button *ngIf="nuevarevision.datos" matSuffix mat-icon-button aria-label="Clear" (click)="nuevarevision.datos=''">
									<mat-icon>close</mat-icon>
								</button>
							</mat-form-field>
							<div *ngIf="edicionrevision != 0 && !edicionrevision" (click)="guardarnuevarevision()" matTooltip="Guardar nueva" class="fondooscuro" style="width:30px;cursor:pointer;margin-top:5px;height:30px;">
								<span class="material-icons">save</span>
							</div>
							<div *ngIf="edicionrevision || edicionrevision == 0" (click)="guardarevision()" matTooltip="Guardar editada" class="fondooscuro" style="width:30px;cursor:pointer;margin-top:5px;height:30px;">
								<span class="material-icons">save</span>
							</div>
						</div>	
					</mat-tab>
					<mat-tab>
						<ng-template mat-tab-label>
							<span class="material-icons" style="margin-right:5px;">grading</span>Itv
						</ng-template>
						<ul *ngIf="data.itvs" style="list-style:none;margin-top:30px;">
							<li *ngFor="let itv of data.itvs; let indice = index">
								<div style="width:100%;display:inline-flex"><div (dblclick)="edicionitv = indice;editaitv(itv);">{{itv.fechaitvpasada}}  -  {{itv.fechaproximaitv}}  -  {{itv.notas}}</div><span class="spacer"></span><div (click)="eliminaitv(indice)" class="fondooscuro" style="cursor:pointer;"><span class="material-icons">delete_forever</span></div></div>
							</li>
						</ul>
						<div style="display:inline-flex;width:100%">
							<mat-form-field class="datovehiculo">
								<mat-label>Fecha ITV pasada</mat-label>
								<input matInput type="text" [(ngModel)]="nuevaitv.fechaitvpasada">
								<button mat-button *ngIf="nuevaitv.fechaitvpasada" matSuffix mat-icon-button aria-label="Clear" (click)="nuevaitv.fechaitvpasada=''">
									<mat-icon>close</mat-icon>
								</button>
							</mat-form-field>
							<mat-form-field class="datovehiculo">
								<mat-label>Fecha caducidad ITV</mat-label>
								<input matInput type="text" [(ngModel)]="nuevaitv.fechaproximaitv">
								<button mat-button *ngIf="nuevaitv.fechaproximaitv" matSuffix mat-icon-button aria-label="Clear" (click)="nuevaitv.fechaproximaitv=''">
									<mat-icon>close</mat-icon>
								</button>
							</mat-form-field>
							<mat-form-field class="datovehiculo" style="width:60%">
								<mat-label>Notas</mat-label>
								<input matInput type="text" [(ngModel)]="nuevaitv.notas">
								<button mat-button *ngIf="nuevaitv.notas" matSuffix mat-icon-button aria-label="Clear" (click)="nuevaitv.notas=''">
									<mat-icon>close</mat-icon>
								</button>
							</mat-form-field>
							<div *ngIf="edicionitv != 0 && !edicionitv" (click)="guardarnuevaitv()" matTooltip="Guardar" class="fondooscuro" style="width:30px;cursor:pointer;margin-top:5px;height:30px;">
								<span class="material-icons">save</span>
							</div>
							<div *ngIf="edicionitv || edicionitv == 0" (click)="guardaitv()" matTooltip="Guardar" class="fondooscuro" style="width:30px;cursor:pointer;margin-top:5px;height:30px;">
								<span class="material-icons">save</span>
							</div>
						</div>	
					</mat-tab>
				</mat-tab-group>
			</div>
		</div>	
	</div>	
</div>