
<div class="contenedor">
    <span class="spacer"></span>
        <button mat-fab color="primary" (click)="antdia()">
            <mat-icon>arrow_back</mat-icon>
        </button>    
    <span class="spacer"></span>
    <mat-form-field>
        <input matInput [matDatepicker]="picker" value="value | date: 'dd/mm/yyyy'" [formControl]="date" (dateChange)="selectfecha($event)">
        <mat-datepicker #picker></mat-datepicker>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    </mat-form-field>     
    <span class="spacer"></span>
    <button mat-fab color="primary" (click)="sigdia()">
        <mat-icon>arrow_forward</mat-icon>
    </button>
    <span class="spacer"></span>
</div>
<div class="usuarios">
    <table mat-table *ngIf="fichajes && mensajeros && dataSource" [dataSource]="dataSource" matSort class="tabla">
        <ng-container matColumnDef="Fichado">
            <th mat-header-cell *matHeaderCellDef="Fichado" mat-sort-header>
                Fichado
            </th>
            <td mat-cell *matCellDef="let row">
                <img *ngIf="fichajes[row._id].estado" src="../assets/Iconos/trafficlight-green_40427.png" width="15">
                <img *ngIf="!fichajes[row._id].estado" src="../assets/Iconos/trafficlight-red_40428.png" width="15">
            </td>
        </ng-container>
        <ng-container *ngFor="let column of columns" [matColumnDef]="column.columnDef">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              {{column.header}}
            </th>
            <td mat-cell *matCellDef="let element">
              {{element[column.columnDef]}}
            </td>
        </ng-container>
        <ng-container matColumnDef="eman">
            <th mat-header-cell *matHeaderCellDef="eman" mat-sort-header>
                Entrada Mañana
            </th>
            <td mat-cell *matCellDef="let row" (click)="openDialog(row._id, 'horaentrada', 'fechahoraentrada')">
                {{fichajes[row._id].horaentrada}}
            </td>
        </ng-container>
        <ng-container matColumnDef="salman">
            <th mat-header-cell *matHeaderCellDef="salman" mat-sort-header>
                Salida Mañana
            </th>
            <td mat-cell *matCellDef="let row" (click)="openDialog(row._id, 'horasalida', 'fechahorasalida')">
                {{fichajes[row._id].horasalida}}
            </td>
        </ng-container>
        <ng-container matColumnDef="entar">
            <th mat-header-cell *matHeaderCellDef="entar" mat-sort-header>
                Entrada Tarde
            </th>
            <td mat-cell *matCellDef="let row" (click)="openDialog(row._id, 'horaentrada2', 'fechahoraentrada2')">
                {{fichajes[row._id].horaentrada2}}
            </td>
        </ng-container>
        <ng-container matColumnDef="saltar">
            <th mat-header-cell *matHeaderCellDef="saltar" mat-sort-header>
                Salida Tarde
            </th>
            <td mat-cell *matCellDef="let row" (click)="openDialog(row._id, 'horasalida2', 'fechahorasalida2')">
                {{fichajes[row._id].horasalida2}}
            </td>
        </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="celdas"></tr>
    </table>
</div>
