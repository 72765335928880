<div class="opciones" (click)="enrutar('nuevousuario')">
	<mat-icon>person_add</mat-icon><span> Nuevo Usuario</span>
</div>
<div class="contenedor">
	<div class="usuarios">
		<table mat-table *ngIf="usuarios" [dataSource]="usuarios" matSort class="tabla">
			<ng-container matColumnDef="disclaimer">
				<td mat-header-cell *matHeaderCellDef colspan="4" class="titulo">
				  Usuarios
				</td>
			</ng-container>
			<ng-container *ngFor="let column of columns" [matColumnDef]="column.columnDef">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>
				  {{column.header}}
				</th>
				<td mat-cell *matCellDef="let element">
				  {{element[column.columnDef]}}
				</td>
			  </ng-container>
			  <tr mat-header-row *matHeaderRowDef="['disclaimer']; sticky:true"></tr>
			  <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></tr>
			  <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="celdas" (dblclick)="abrecliente(row._id)"></tr>
		</table>
	</div>
	<div class="usuarios">
		<table mat-table *ngIf="mensajeros" [dataSource]="mensajeros" #sBSort="matSort" matSort class="tabla">
			<ng-container matColumnDef="disclaimer2">
				<td mat-header-cell *matHeaderCellDef colspan="4" class="titulo">
				  Mensajeros
				</td>
			</ng-container>
			<ng-container *ngFor="let column of columns2" [matColumnDef]="column.columnDef">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>
				  {{column.header}}
				</th>
				<td mat-cell *matCellDef="let element">
				  {{element[column.columnDef]}}
				</td>
			  </ng-container>
			  <tr mat-header-row *matHeaderRowDef="['disclaimer2']; sticky:true"></tr>
			  <tr mat-header-row *matHeaderRowDef="displayedColumns2; sticky:true"></tr>
			  <tr mat-row *matRowDef="let row; columns: displayedColumns2;" class="celdas" (dblclick)="abrecliente(row._id)"></tr>
		</table>
	</div>
</div>