<div style="width:100%;margin:50px;padding:50px;text-align: center;">
    <div *ngIf="!mensajeerror">
        <div>
            Introduzca contraseña para descargar el archivo: {{enlace.nombre}}
        </div><br>
        <mat-form-field style="width:40%;">
            <mat-label>Password</mat-label>
            <input matInput type="password" [(ngModel)]="password">
            <button *ngIf="password" matSuffix mat-icon-button aria-label="Clear" (click)="password=null">
                <mat-icon>close</mat-icon>
            </button>
            <mat-hint *ngIf="passincorrecta" style="color:red">La contraseña no es correcta</mat-hint>
        </mat-form-field><br><br>
        <button mat-raised-button matTooltip="Descargar" [disabled]="!password || mensajeerror" (click)="comprobar()">
            <mat-icon>download</mat-icon>
        </button>
    </div>
    <p *ngIf="mensajeerror">
        {{mensajeerror}}
    </p>
</div>