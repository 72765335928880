import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { VehiculoService } from '../../servicios/vehiculos.service';
import { Vehiculo } from '../../models/vehiculo';
import { GastoComponent } from '../gasto/gasto.component';
import { Gasto } from '../../models/gasto';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition, MatSnackBarConfig } from '@angular/material/snack-bar';
import { ActivatedRoute, Params } from '@angular/router';
import { saveAs } from "file-saver";
import * as moment from 'moment';

@Component({
  selector: 'app-vehiculo',
  templateUrl: './vehiculo.component.html',
  styleUrls: ['./vehiculo.component.css'],
  providers: [VehiculoService]
})
export class VehiculoComponent implements OnInit {
    public imagenseleccionada;
    public imagenes;
    public cantidadimagenes;
    public archivos;
    public numimagen;
    public imagenactual;
    public imagehover;
    public todoslosgastos;
    public gastos;
    public importetotal = 0;
    public importesintarjeta = 0;
    public importecontarjeta = 0;
    public queryactual;
    public fechainicio;
    public fechafin;
    public nuevarevision;
    public nuevaitv;
    public edicionrevision;
    public edicionitv;
    public totalrevisiones;
    public totalitvs;
  constructor(
  	public dialogoVehiculo: MatDialogRef<VehiculoComponent>,
    public ventanaGasto: MatDialog,
    private _snackBar: MatSnackBar,
    private _route: ActivatedRoute,
    private _vehiculoService: VehiculoService,
    @Inject(MAT_DIALOG_DATA) public data: Vehiculo
  ) {
      this.imagehover = undefined;
      if(this.data.imagenes){
        this.mostrarimagenes();
      }else{
        this.cantidadimagenes = 0;
      }
      var query = {matricula:this.data.matricula};
      var mesactual = moment().month() +1;
      this.getgastos(query, mesactual);
  }

  ngOnInit(): void {
    this.setnuevarevision();
    this.setnuevaitv();
  }

  aviso(texto, subtexto) {
    this._snackBar.open(texto,subtexto,{
      duration: 5000,
      horizontalPosition: "center",
      verticalPosition: "top",
    });
  }

  mostrarimagenes(){
      this.archivos = new Array();
      this.imagenes = this.data.imagenes.filter(image=>{
        if(image.tipo.slice(0,5) == 'image'){
          return true;
        }else{
          this.archivos.push(image);
          return false;
        }  
      });
      this.cantidadimagenes = this.imagenes.length;
      this.numimagen = 0;
      this.imagenactual = this.imagenes[this.numimagen];
  }

  closeDialog() {
    this.dialogoVehiculo.close(true);
  }

  fileChangeEvent(fileInput: any){
    var archivos = <Array<File>>fileInput.target.files;
    for(let file of archivos){
        readFileAsync(file).then((data)=>{return data}).then((a)=>{
            var archivo = {
            'name': file.name,
            'tipo': file.type,
            'contentBytes': a
            };
            if(!this.data.imagenes){
              this.data.imagenes = [archivo];
            }else{
              this.data.imagenes.push(archivo);
            }
            this.mostrarimagenes();        
        }).catch(err=>{
          console.log(err);
        });
    }      

   
      function readFileAsync(file) {
        return new Promise((resolve, reject) => {
          let reader = new FileReader();
          reader.onload = () => {
            resolve(reader.result);
          };
          reader.onerror = reject;
          reader.readAsDataURL(file);
        });
      }
  } 

  guardarvehiculo(){
    if(this.data.matricula){
      this._vehiculoService.guardarvehiculo(this.data).subscribe(
        response=>{
            if(response.status == 200){
              this.aviso(response.message, 'Ok');
              this.closeDialog();
            }
          },
        error=>{
          this.aviso(error.error.message, 'error');
          console.log(<any>error);
        }  
      )
    }else{
      this.aviso('falta introducir matricula', 'Ok');
    }  
  }

  baja(){
    var fecha = moment().format('DD/MM/YYYY').split('/');
    this.data.fechabaja = fecha[0] + '/' + fecha[1] + '/' + fecha[2];
    this.guardarvehiculo();
  }

  alta(){
    var dato = {$unset:{fechabaja:''}}
    this._vehiculoService.updatevehiculo(dato, this.data._id).subscribe(
      response=>{
        this.aviso(response.message, 'ok');
      },
      error=>{
        console.log(error);
      }
    );
  }

  eliminarvehiculo(){
    if(this.data._id){
      this._vehiculoService.borravehiculo(this.data._id).subscribe(
        response=>{
            if(response.vehiculoremoved){
              this.aviso('Vehiculo eliminado','Ok');
              this.closeDialog();
            }  
          },
        error=>{
          console.log(<any>error);
        }  
      );
    }else{
      this.closeDialog();
    }  
  }

  eliminaimagen(imagen){
    this.data.imagenes.splice(this.data.imagenes.indexOf(imagen), 1);
    this._vehiculoService.guardarvehiculo(this.data).subscribe(
        response=>{
          if(response.status == 200) this.aviso('Imagen Eliminada', 'Ok');
          if(this.data.imagenes){
            this.mostrarimagenes();
          }else{
            this.cantidadimagenes = 0;
          }
        }    
    )      
  }

  anteriorimg(){
    this.numimagen = this.numimagen -1;
    this.imagenactual = this.imagenes[this.numimagen];
  }

  siguienteimg(){
    this.numimagen = this.numimagen +1;
    this.imagenactual = this.imagenes[this.numimagen];
  }

  getarchivo(archivo){
      saveAs(archivo.contentBytes, archivo.name);
  }

  getgastos(query, mes){
    this.importetotal = 0;
    this.importesintarjeta = 0;
    this.importecontarjeta = 0;
    this._vehiculoService.getgastos(query).subscribe(
      response=>{
        if(response.gastos){
          this.todoslosgastos = response.gastos;
          this.gastos = this.todoslosgastos.filter(gasto=>{
            if(moment(gasto.fecha).month() + 1 == mes){
              this.importetotal = this.importetotal + gasto.importe;
              if(gasto.tarjeta){
                this.importecontarjeta = this.importecontarjeta + gasto.importe;
              }else{
                this.importesintarjeta = this.importesintarjeta + gasto.importe;
              }
              return true;
            }else{
              return false;
            }
          });
        }  
      },
      error=>{
        console.log(<any>error);
      }
    )
  }

  abregasto(gasto){
    var gastoabierto = this.ventanaGasto.open(GastoComponent, {
      width: '85%',
      height: '85%',
      data: gasto
    });
    
    gastoabierto.afterClosed().subscribe(result =>{
      if(result == true){
        var query = {matricula:this.data.matricula};
        var mesactual = moment().month() +1;
        this.getgastos(query, mesactual);
      }
    });

  }

  nuevogasto(){
    this.abregasto(new Gasto('',null,'',this.data.matricula,0,'', '', null,''));
  }

  gastosactuales(){
    this._vehiculoService.getgastos(this.queryactual).subscribe(
      response=>{
        if(response.gastos){
          this.todoslosgastos = response.gastos;
          this.gastos = this.todoslosgastos.filter(gasto=>{
            this.importetotal = this.importetotal + gasto.importe;
            if(gasto.tarjeta){
              this.importecontarjeta = this.importecontarjeta + gasto.importe;
            }else{
              this.importesintarjeta = this.importesintarjeta + gasto.importe;
            }
            return gasto;
          });
        }  
      },
      error=>{
        console.log(<any>error);
      }
    );
  }

  onDaterangofechas(){
    var fechainicial = moment(this.fechainicio).set({hour:4,minute:0,second:0,millisecond:0}).toISOString(true);
    if(!this.fechafin){
     var fechafinal = moment(this.fechafin).set({hour:21,minute:0,second:0,millisecond:0}).toISOString(true);
    }else{
      var fechafinal = moment(this.fechafin).set({hour:23,minute:0,second:0,millisecond:0}).toISOString(true);
    }
    var query = {$and:[{fecha:{$gte:fechainicial}},{fecha:{$lte:fechafinal}}], matricula:this.data.matricula};
    this.queryactual = query;
    this.gastosactuales();
  }

  setnuevaitv(){
    this.nuevaitv = {
      fechaitvpasada:'',
			fechaproximaitv: '',
			notas: ''
    }
  }

  setnuevarevision(){
    this.nuevarevision = {
      fecha: '',
      datos: ''
    }
  }

  guardarnuevaitv(){
    if(this.data.itvs){
      this.data.itvs.push(this.nuevaitv);
    }else{
      this.data.itvs = [this.nuevaitv];
    }
    this.setnuevaitv();
  }

  guardarnuevarevision(){
    if(this.data.revisiones){
      this.data.revisiones.push(this.nuevarevision);
    }else{
      this.data.revisiones = [this.nuevarevision];
    }
    this.setnuevarevision();
  }

  guardarevision(){
    this.data.revisiones[this.edicionrevision] = this.nuevarevision;
    this.totalrevisiones = this.data.revisiones;
    this._vehiculoService.guardarvehiculo(this.data).subscribe(
      response=>{
        this.edicionrevision = undefined;
        this.setnuevarevision();
        this.data.revisiones = this.totalrevisiones;
      },
      error=>{
        console.log(<any>error)
      });
  }

  eliminarevision(index){
    this.data.revisiones.splice(index, 1);
    this.totalrevisiones = this.data.revisiones;
    this._vehiculoService.guardarvehiculo(this.data).subscribe(
      response=>{
        this.edicionrevision = undefined;
        this.setnuevarevision();
        this.data.revisiones = this.totalrevisiones;
      },
      error=>{
        console.log(<any>error)
      });
  }

  eliminaitv(index){
    this.data.itvs.splice(index, 1);
    this.totalitvs = this.data.itvs;
    this._vehiculoService.guardarvehiculo(this.data).subscribe(
      response=>{
        this.edicionitv = undefined;
        this.setnuevaitv();
        this.data.itvs = this.totalitvs;
      },
      error=>{
        console.log(<any>error)
      });
  }

  editarevision(revision){
     this.nuevarevision = {
      fecha: revision.fecha,
      datos: revision.datos
    }
  }

  guardaitv(){
    this.data.itvs[this.edicionitv] = this.nuevaitv;
    this.totalitvs = this.data.itvs;
    this._vehiculoService.guardarvehiculo(this.data).subscribe(
      response=>{
        this.edicionitv = undefined;
        this.setnuevaitv();
        this.data.itvs = this.totalitvs;
      },
      error=>{
        console.log(<any>error)
      });
  }

  editaitv(itv){
    this.nuevaitv = {
      fechaitvpasada:itv.fechaitvpasada,
			fechaproximaitv: itv.fechaproximaitv,
			notas: itv.notas
    }
  }

}
