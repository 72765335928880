import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs/Observable";
import { GLOBAL } from "./global";

@Injectable()
export class PlantillaService {
  public url: string;

  constructor(public _http: HttpClient) {
    this.url = GLOBAL.urlplantilla;
  }

  nuevaplantilla(plantilla): Observable<any> {
    let params = JSON.stringify(plantilla);
    let headers = new HttpHeaders().set("Content-Type", "application/json");
    return this._http.post(this.url + "guardarplantilla", params, {
      headers: headers,
    });
  }

  getplantilla(plantillaid): Observable<any> {
    let tmp = { id: plantillaid };
    let params = JSON.stringify(tmp);
    let headers = new HttpHeaders().set("Content-Type", "application/json");
    return this._http.post(this.url + "getplantilla", params, {
      headers: headers,
    });
  }

  getplantillas(usuario, tipo): Observable<any> {
    let tmp = { usuario: usuario, tipo: tipo };
    let params = JSON.stringify(tmp);
    let headers = new HttpHeaders().set("Content-Type", "application/json");
    return this._http.post(this.url + "plantillas", params, {
      headers: headers,
    });
  }

  actualizarplantilla(plantilla): Observable<any> {
    let params = JSON.stringify(plantilla);
    let headers = new HttpHeaders().set("Content-Type", "application/json");
    return this._http.post(this.url + "actualizar", params, {
      headers: headers,
    });
  }
  eliminarplantilla(plantillaId): Observable<any> {
    let tmp = { plantilla: plantillaId };
    let params = JSON.stringify(tmp);
    let headers = new HttpHeaders().set("Content-Type", "application/json");
    return this._http.post(this.url + "eliminar", params, { headers: headers });
  }
}
