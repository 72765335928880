import { Injectable } from "@angular/core";
import { Observable } from "rxjs/Observable";
import { GLOBAL } from "./global";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
  MatSnackBarConfig,
} from "@angular/material/snack-bar";

@Injectable()
export class AppService {
  public identity: string;
  public token: string;
  public tokenoffice: string;
  public galletag3: string;
  public authorization: string;
  public url: string;
  public urlseguimiento: string;
  public urloffice: string;
  public dirmailraiz: string;
  public dircarpetas: string;

  constructor(public _http: HttpClient, public _snackBar: MatSnackBar) {
    this.url = GLOBAL.url;
    this.urlseguimiento = GLOBAL.urlseguimiento;
    this.urloffice = GLOBAL.urloffice;
    this.dirmailraiz = "https://graph.microsoft.com/v1.0/me/mailFolders";
    this.dircarpetas =
      "https://graph.microsoft.com/v1.0/me/mailFolders?$top=100";
  }

  aviso(texto, subtexto) {
    this._snackBar.open(texto, subtexto, {
      duration: 4000,
      horizontalPosition: "center",
      verticalPosition: "top",
    });
  }

  getaccesonocliente(): Observable<any> {
    let headers = new HttpHeaders().set("Content-Type", "application/json");
    return this._http.get(this.urlseguimiento + "acceso", { headers: headers });
  }

  getIdentity() {
    let identity = JSON.parse(sessionStorage.getItem("identity"));
    if (identity) {
      this.identity = identity;
    } else {
      this.identity = null;
    }
    return this.identity;
  }

  getusrid() {
    let usrid = localStorage.getItem("usrid");
    if (usrid) {
      return usrid;
    } else {
      return null;
    }
  }

  getToken() {
    let token = sessionStorage.getItem("token");
    if (token) {
      this.token = token;
    } else {
      this.token = null;
    }
    return this.token;
  }

  getTokenOffice() {
    let token = sessionStorage.getItem("tokenoffice");
    if (token) {
      this.tokenoffice = token;
    } else {
      this.tokenoffice = null;
    }
    return this.tokenoffice;
  }

  getgalletag3() {
    let galletag3 = sessionStorage.getItem("galletag3");
    if (galletag3) {
      this.galletag3 = galletag3;
    } else {
      this.galletag3 = null;
    }
    return this.galletag3;
  }

  getgauthG3() {
    let authorization = sessionStorage.getItem("authorization");
    if (authorization) {
      this.authorization = authorization;
    } else {
      this.authorization = null;
    }
    return this.authorization;
  }

  refreshg3() {
    let datosg3 = this.getIdentity();
    console.log("entrando en refreshg3");
    if (datosg3) {
      sessionStorage.setItem("galletag3", "");
      sessionStorage.setItem("authorization", "");
      let headers = new HttpHeaders()
        .set("Content-Type", "application/json")
        .set("authorization", this.getToken());
      var params = {
        usuario: datosg3["options"]["g3"]["usuario"],
        pass: datosg3["options"]["g3"]["password"],
      };
      var cuerpo = JSON.stringify(params);
      this._http
        .post(this.url + "getgalletag3", cuerpo, { headers: headers })
        .subscribe(
          (response) => {
            let rest = JSON.parse(response["cookie"]);
            sessionStorage.setItem("galletag3", rest["galletag3"]);
            sessionStorage.setItem("authorization", rest["authorization"]);
          },
          (error) => {
            this.aviso(
              `error general de conexion a central MRW
                    por favor, intentelo de nuevo mas tarde`,
              "ok"
            );
          }
        );
    }
  }

  refreshg3cliente() {
    let datosg3 = this.getIdentity();
    if (datosg3) {
      let headers = new HttpHeaders()
        .set("Content-Type", "application/json")
        .set("authorization", this.getToken());
      var params = {
        usuario: "0260100019",
        pass: "mensasur2601",
      };
      var cuerpo = JSON.stringify(params);
      return this._http.post(this.url + "getgalletag3", cuerpo, {
        headers: headers,
      });
    }
  }

  refreshTokenOffice() {
    let token = this.getToken();
    if (token) {
      let headers = new HttpHeaders()
        .set("Content-Type", "application/json")
        .set("authorization", token);
      this._http
        .get(this.urloffice + "refreshtoken", { headers: headers })
        .subscribe(
          (response) => {
            console.log("renovado tokenoffice");
            sessionStorage.setItem(
              "tokenoffice",
              response["ots"]["tokendeacceso"]
            );
          },
          (error) => {
            console.log(error);
          }
        );
    }
  }

  comprobartokens() {
    console.log("comprobando tokens...");
    let identity = this.getIdentity();
    let to = this.getTokenOffice();
    let g3 = this.getgalletag3(); // Quitar G3
    if (!to) this.refreshTokenOffice();
    if (!g3) this.refreshg3(); // Quitar G3
    if (identity && identity["role"] != "cliente" && to) {
      this.solicitacarpetas(this.dircarpetas).subscribe(
        (response) => {
          if (response.status != 200) {
            console.log("refrescando token status != 200");
            this.refreshTokenOffice();
          } else {
            console.log("token Office correcto!");
          }
        },
        (error) => {
          this.refreshTokenOffice();
          console.log("refrescando token error");
          console.log(error);
        }
      );
    }
    this.pruebag3().subscribe(
      (response) => {
        if (response.message) {
          console.log("refrescando g3 status != 200");
          this.refreshg3();
        } else {
          console.log("galleta G3 correcta!");
        }
      },
      (error) => {
        console.log("refrescando g3 error");
        this.refreshg3();
      }
    );
  }

  solicitacarpetas(url): Observable<any> {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("Authorization", this.getTokenOffice());
    return this._http.get(url, { headers: headers, observe: "response" });
  }

  solicitamails(url): Observable<any> {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("Authorization", this.getTokenOffice());
    return this._http.get(url, { headers: headers });
  }

  pruebag3(): Observable<any> {
    let params = JSON.stringify({
      cookie: {
        authorization: this.getgauthG3(),
        galletag3: this.getgalletag3(),
      },
    });
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("authorization", this.getToken());
    return this._http.post(this.url + "pruebag3", params, { headers: headers });
  }
}
