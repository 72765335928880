<h2 class="centrado">{{title}}</h2>
<div *ngIf="identity && identity.role == 'Administrador' && clienteid" class="divenlace">
	<a class="enlace" [routerLink]="['../../../cliente/' + clienteid]"><mat-icon style="font-size:20px;">arrow_back</mat-icon><br>Volver</a>	
</div>

<div class="listacarpetas">
	<ul>
		<li *ngFor="let carpeta of carpetas" class="carpetas centrado">
			<a [routerLink]="['/facturas/' + carpeta + '/' + clienteid]" class="enlace">
				<div style="display:inline-flex">
					<div><mat-icon style="font-size:35pt;margin-right:20px;">drive_file_move</mat-icon></div>
					<div>{{carpeta}}</div>
				</div>	
			</a>
		</li>
	</ul>
	
</div>

						
						