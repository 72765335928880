<div *ngIf="data.estado == true" style="text-align: center;">
    <p>
        fichar para salir a las {{hora}}
    </p>
    <button mat-button (click)="cancelar()">Cancelar</button>
    <button mat-button (click)="ficharsalida()" cdkFocusInitial>Fichar Salida</button>
</div>

<div *ngIf="data.estado == 'sin datos' || data.estado == false" style="text-align: center;">
    <p>
        fichar entrada a las {{hora}}
    </p>
    <button mat-button (click)="cancelar()">Cancelar</button>
    <button mat-button (click)="ficharentrada()" cdkFocusInitial>Fichar Entrada</button>
</div>