<div class="body">
<div class="content"> <img alt="MRW Logo" src="../../../assets/Iconos/logo_trans.png"><span>  Franquicia 02601</span></div>
<form #loginForm="ngForm" (ngSubmit)="onSubmit(loginForm)">
    <div style="margin-top: 100px; width: 440px; margin-left: 89px;">
        <fieldset style="border:0;">
            
            <div style="float: left; width: 263px;">
                <div style="float:left;">
                    <div class="userTitle">
                        <label for="UserName">Usuario: </label>
                    </div>
                    <div class="inptPass">
                        <input id="UserName" name="UserName" [(ngModel)]="identity.numero" type="text" value="">
                        
                    </div>
                </div>
                <div style="float:left;margin-top:-3px">
                    <div class="passTitle">
                        <label for="Password">Password: </label>
                    </div>
                    <div class="inptPass">
                        <input id="Password" name="Password" [(ngModel)]="identity.password" type="password">
                        
                    </div>
                    
                </div>
               
            </div>
            <div class="botonLogin" style="float:left;margin-top:-2px;cursor:pointer;">
                <input type="submit" id="btnSubmitForm" value="" style="margin-top: 28px; margin-left: 20px; border-top-color: currentColor; border-right-color: currentColor; border-bottom-color: currentColor; border-left-color: currentColor; border-top-width: 0px; border-right-width: 0px; border-bottom-width: 0px; border-left-width: 0px; border-top-style: none; border-right-style: none; border-bottom-style: none; border-left-style: none; background-color: transparent; width: 40px; height: 41px;">
                
            </div>
            
        </fieldset>

        <br>
        
    </div>
</form>
<div class="alert alert-danger avisoerror" *ngIf="status && status == 'error'">{{mensajestatus}}</div>
<div class="menu">
<a [routerLink]="['../olvidada']">Olvidaste la contraseña?</a> &nbsp;
</div>

</div>

