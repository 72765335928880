import { OnInit, Component, ViewChild, AfterViewInit, Input, Output, EventEmitter } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { ClienteService } from '../../servicios/cliente.service';
import { Cliente } from '../../models/cliente';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import * as moment from 'moment';
import { IncidenciasService } from '../../servicios/incidencias.service';
import { Incidencia } from '../../models/incidencia';
import { MatTableDataSource } from '@angular/material/table';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { FormControl } from '@angular/forms';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition, MatSnackBarConfig } from '@angular/material/snack-bar'; 
import { AppService } from '../../servicios/app.service';

@Component({
  selector: 'direccionesmensajero',
  templateUrl: './direccionesmensajero.component.html',
  styleUrls: ['./direccionesmensajero.component.css'],
  providers: [ClienteService, AppService, IncidenciasService]
})
export class DireccionesmensajeroComponent implements OnInit {
    public dataSource = new MatTableDataSource<Incidencia>();
    public load = true;
    public dirreales;
    public mensajero;
    public identity;
    public totalcolumns;
    public listasfiltros;
    public filtros;
    public selection;
    public seleccion;
    public fechahoy;
    public category;
    public totaldirecciones;
    public queryoptions;
    public tdir = true;
    public operativainicial = new FormControl();
    public operativafinal = new FormControl();
    @ViewChild(MatSort, {static: false}) set content(sort: MatSort){this.dataSource.sort = sort;}
    @ViewChild(MatPaginator, {static: false}) set matPaginator(mp: MatPaginator) {this.dataSource.paginator = mp;}
  constructor(
  		private _route: ActivatedRoute,
	  	private _router: Router,
	    private _clienteService: ClienteService,
      private _incidenciasService: IncidenciasService,
      private _snackBar: MatSnackBar,
      private dateAdapter: DateAdapter<Date>,
      private _appservice: AppService
  ) {
    this.identity = this._appservice.getIdentity();
    this.queryoptions = {pagination:false};
    this.totalcolumns = ['select','NumAlbaran','Tipo','Anomalia','gestionado','AnomaliaMotivo','AnomaliaObservaciones','CodigoAbonado','CodigoFranquicia','ContactoEntrega','ContactoRecogida','CpDestinatario','CpRemitente','Destinatario','DireccionDestinatario','DireccionRemitente','Estado','Fase','FechaEntrega','FechaEnvio','EntregarAPartirDe','FechaRecogida','FechaFinalizacionRecogida','FechaConcertacion','HoraMaximaEntrega','HoraMaximaRecogida','IdFiscal','AR','ImporteAR','Kilos','KmDestinatario','KmRemitente','Motivo','NombreCliente','NombreSolicitante','ObservacionesEntrega','ObservacionesRecogida','PoblacionDestinatario','PoblacionRemitente','ProvinciaDestinatario','ProvinciaRemitente','RangoHorarioEntregaManana','RangoHorarioEntregaTarde','RangoHorarioRecogidaManana','RangoHorarioRecogidaTarde','Remitente','Servicio','TelefonoDestinatario','TelefonoRemitente','TipoComision','TipoEntrega','TipoGeografico','TipoMercancia','TipoRecogida','TotalBultos','TipoCobro','FranquiciaDestino','FranquiciaOrigen','FranquiciaTercera','FranquiciaClienteFacturacion','CodigoCupon','NumeroAlbaranPrecursor','NumeroEnvioRetorno','TipoGestion','TieneRetorno','ConfirmacionInmediata','EntregaSabado','TipoRetorno','Usuario','UsuarioModificacion','ReferenciaCliente','EsInternacional','GeneradoPor','Aviso','TotalMedidas','Operacion','Frecuencia','Contador','NifRemitente','NifDestinatario','MensajeroEntrega','MensajeroRecogida','PuntoRecogida','PuntoEntrega','PuntoAnomalia','PuntoEstado','PuntoConcertacion','TipoPuntoConcertacion','albaranrd','notas','fechacobrado','usuariocobro'];
    this.listasfiltros = {
        listamotivo:[
          {Motivo:'', valor:''},
          {Motivo:'OT0700', valor: 'Clasificado en plataforma'},
          {Motivo:'OE0062', valor: 'Depositado en Franquicia de destino'},
          {Motivo:'OE0600', valor: 'Mensajero de entrega asignado'},
          {Motivo:'OR0600', valor: 'Mensajero de recogida asignado'},
          {Motivo:'OE0500', valor: 'Recibido en franquicia de destino'},
          {Motivo:'OR0200', valor: 'Recogida finalizada'},
          {Motivo:'OS0200', valor: 'Recogida pendiente'},
          {Motivo:'OE0100', valor: 'Tránsito interno de entrega'}
          ],
        listagestionados:['','Pendiente Cliente','Pendiente Agencia','Para Entregar','Recoge en agencia','Entregado','Cancelado','Especial/vigilar'],
        listaestado:['EntregaPendiente','EnvioCancelado','RecogidaFinalizada','RecogidaPendiente','TransitoInternoEntrega','EntregaCancelada','EntregaFinalizada'],
        listanomostrar:['EnvioCancelado','EntregaCancelada']
     }
     this.filtros = {
      filtroprincipal: ''
      };
     this.selection = new SelectionModel<Incidencia>(true, []);
     this.dateAdapter.setLocale('es');
     this.dateAdapter.getFirstDayOfWeek = () => { return 1; };
     this.fechahoy = moment(new Date());
  }

  ngOnInit(): void {
    this._route.params.subscribe(params=>{
      this._clienteService.getcliente(params.numero).subscribe(
        response=>{
          this.mensajero = response.cliente;
          this.getdirecciones();
        }
      );
    });      
  }

  ngAfterViewInit(){
     this.matPaginator.page.subscribe((event) => console.log(event));
     this.dataSource.sort = this.content;
  }

  aviso(texto, subtexto) {
    this._snackBar.open(texto,subtexto,{
      duration: 4000,
      horizontalPosition: "center",
      verticalPosition: "top",
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  isAllSelected() {
    if(this.dataSource.filteredData){
      const numSelected = this.selection.selected.length;
      const numRows = this.dataSource.filteredData.length;
      return numSelected === numRows;
    }else{
      const numSelected = this.selection.selected.length;
      const numRows = this.dataSource.data.length;
      return numSelected === numRows;
    }  
  }


  masterToggle() {
    if(this.dataSource.filteredData){
        this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.filteredData.forEach(row => this.selection.select(row));
    }else{  
      this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.data.forEach(row => this.selection.select(row));
    }
  }


  checkboxLabel(row?: Incidencia): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.identity.options['ordencamposreembolsos']['direcciones'], event.previousIndex, event.currentIndex);
    this.actualizacliente();
  }

  actualizacliente(){
    sessionStorage.setItem('identity', JSON.stringify(this.identity));
    this._clienteService.saveCliente(this.identity);
  }

  onselectcolumnastotales(value){
    this.identity.options.ordencamposreembolsos.direcciones = value;
    this.actualizacliente();
  }

  onDateFechaenvio(){
   var fechainicial = moment(this.filtros['FechaEnvioinicial']).format('DD/MM/YYYY');
   if(this.filtros.FechaEnviofinal == null){
    var fechafinal = moment(this.filtros['FechaEnvioinicial']).format('DD/MM/YYYY');
   }else{
     var fechafinal = moment(this.filtros['FechaEnviofinal']).format('DD/MM/YYYY');
   }
   var query = {$and:[{FechaEnvio:{$gte:fechainicial}},{FechaEnvio:{$lte:fechafinal}}],MensajeroEntrega:this.mensajero.numero,FranquiciaDestino:'02601',$or:[{ImporteAR: {$gt: 1}}, {$and:[{CodigoAbonado:{$in:['000000','']}},{TipoCobro: 1}]}], cobrado:{$ne:true}};
   this.busqueda(query);
  }

  onDateFecharecogida(){
    var fechainicial = moment(this.filtros['FechaRecogidainicial']).format('DD/MM/YYYY');
   if(this.filtros.FechaRecogidafinal == null){
    var fechafinal = moment(this.filtros['FechaRecogidainicial']).format('DD/MM/YYYY');
   }else{
    var fechafinal = moment(this.filtros['FechaRecogidafinal']).format('DD/MM/YYYY');
   }
   //var query = {MensajeroRecogida:this.mensajero.numero};// {$and:[{FechaRecogida:{$gte:fechainicial}},{FechaRecogida:{$lte:fechafinal}}],MensajeroRecogida:this.mensajero.numero,FranquiciaOrigen:'02601'};//,cobrado:{$ne:true}};//$or:[{ImporteAR: {$gt: 1}}, {$and:[{CodigoAbonado:{$in:['000000','']}},{TipoCobro: 1}]}]};
   //this.cobrosactualesentrega(query);
  }

  onDateFecharecogidafinalizada(){
   var fechainicial = moment(this.filtros['FechaRecogidafinalizadainicial']).format('DD/MM/YYYY HH:mm:ss');
   if(this.filtros.FechaRecogidafinalizadafinal == null){
    var fechafinal = moment(this.filtros['FechaRecogidafinalizadainicial']).format('DD/MM/YYYY HH:mm:ss');
   }else{
    var fechafinal = moment(this.filtros['FechaRecogidafinalizadafinal']).format('DD/MM/YYYY HH:mm:ss');
   }
   //var query = {MensajeroRecogida:this.mensajero.numero};// {$and:[{FechaFinalizacionRecogida:{$gte:fechainicial}},{FechaFinalizacionRecogida:{$lte:fechafinal}}],FranquiciaOrigen:'02601',MensajeroRecogida:this.mensajero.numero};
   //console.log(query);
   //this.cobrosactualesentrega(query);
  }

  onDateFechaentrega(){
    var fechainicial = moment(this.filtros['FechaEntregainicial']).format('DD/MM/YYYY HH:mm:ss');
   if(this.filtros.FechaEntregafinal == null){
    var fechafinal = moment(this.filtros['FechaEntregainicial']).set({hour:23,minute:59,second:59}).format('DD/MM/YYYY HH:mm:ss');
   }else{
    var fechafinal = moment(this.filtros['FechaEntregafinal']).set({hour:23,minute:59,second:59}).format('DD/MM/YYYY HH:mm:ss');
   }
    //var query = {$and:[{FechaEntrega:{$gte:fechainicial}},{FechaEntrega:{$lte:fechafinal}}],MensajeroEntrega:this.mensajero.numero,FranquiciaDestino:'02601',$or:[{ImporteAR: {$gt: 1}}, {$and:[{CodigoAbonado:{$in:['000000','']}},{TipoCobro: 1}]}], cobrado:{$ne:true}};
    //this.cobrosactualesentrega(query);
  }

  onDateFechaoperativa(){
    if(this.operativafinal.value){
      var fechainicio = moment(this.operativainicial.value).set({hour:3,minute:0,second:0});
      var fechafin = moment(this.operativafinal.value).set({hour:21,minute:59,second:59});
      var params = {
        $or:[
          {$and:[{FechaEntrega:{$gt:fechainicio}},{FechaEntrega:{$lt:fechafin}}, {MensajeroEntrega:this.mensajero.numero},{Estado:'EntregaFinalizada'}]},
          {$and:[{FechaFinalizacionRecogida:{$gt:fechainicio}},{FechaFinalizacionRecogida:{$lt:fechafin}}, {MensajeroRecogida:this.mensajero.numero}]}
        ]};
      this.busqueda(params);
    }  
  }

  getdirecciones(){
    this.load = false;
    var fechainicio = moment().set({hour:3,minute:0,second:0,millisecond:0});
    var fechafin = moment().set({hour:21,minute:59,second:0,millisecond:0});
    var params = {
      $or:[
        {$and:[{FechaEntrega:{$gt:fechainicio}},{FechaEntrega:{$lt:fechafin}}, {MensajeroEntrega:this.mensajero.numero},{Estado:'EntregaFinalizada'}]},
        {$and:[{FechaFinalizacionRecogida:{$gt:fechainicio}},{FechaFinalizacionRecogida:{$lt:fechafin}}, {MensajeroRecogida:this.mensajero.numero}]}
      ]};
    this._incidenciasService.getincidencias(params, this.queryoptions).subscribe(
      response =>{
        if(response.incidencias){
          this.totaldirecciones = response.incidencias;
          this.dataSource.data = this.totaldirecciones.map((direccion, index)=>{
            direccion['position'] = index;
            return direccion;
          });
          this.dirreales = this.getdireccionesreales(this.totaldirecciones);
        } 
        this.load = true; 
      },
      error =>{
        console.log(<any>error);
      }); 
  }

  getdireccionesreales(direcciones){
    var hash = {};
    return direcciones.filter((direccion)=>{
      if(direccion.FranquiciaDestino == '02601'){
        let fecha = moment(direccion.FechaEntrega).format('DD_MM_YYYY').toString();
        let hora = moment(direccion.FechaEntrega).format('HH_mm').toString();
        let dato = fecha + 'R_' + direccion.Destinatario;
        let dato2 = fecha + '_' + hora;
        if(hash[dato] || hash[dato2]){
          return false;
        }else{
          hash[dato] = true;
          hash[dato2] = true;
          return true;
        }
      }else if(direccion.FranquiciaOrigen == '02601'){
        let fecha = moment(direccion.FechaFinalizacionRecogida).format('DD_MM_YYYY').toString();
        let hora = moment(direccion.FechaFinalizacionRecogida).format('HH_mm').toString();
        let dato = fecha + 'D_' + direccion.Remitente;
        let dato2 = fecha + '_' + hora;
        if(hash[dato] || hash[dato2]){
          return false;
        }else{
          hash[dato] = true;
          hash[dato2] = true;
          return true;
        }
      } 
    }) 
  }

  busqueda(query){
    this.load = false;
    this._incidenciasService.getincidencias(query, this.queryoptions).subscribe(
      response =>{
        if(response.incidencias){
          this.totaldirecciones = response.incidencias;
          this.dataSource.data = this.totaldirecciones.map((direccion, index)=>{
            direccion['position'] = index;
            return direccion;
          });
          this.dirreales = this.getdireccionesreales(this.totaldirecciones);
        } 
        this.load = true; 
      },
      error =>{
        console.log(<any>error);
      }); 
  }

  limpiaoperativa(){
    this.operativainicial.setValue(null);
    this.operativafinal.setValue(null);
  }


  limpiarfiltros(){
    this.dataSource.filter = '';
    this.filtros = {filtroprincipal: ''};
  }  

  toggledirecciones(){
    this.tdir = !this.tdir;
    if(this.tdir){
      this.dataSource.data = this.totaldirecciones.map((direccion, index)=>{
        direccion['position'] = index;
        return direccion;
      });
    }else{
      this.dataSource.data = this.dirreales.map((direccion, index)=>{
        direccion['position'] = index;
        return direccion;
      });
    }
  }

  seleccionar(row){
    this.seleccion = row;
    //this.selecionra = this.tipora(row.AR);
  }

  tipora(tipo){
    if(tipo == 1) return '(R)';
    if(tipo == 0) return '(A)';
  }

  categoriaseleccionada(categoria){
    this.category = categoria;
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }  
  }

  onselectfiltro(filtro, valor){
     if(valor == ''){
      this.filtros[filtro] = undefined;
      this.eliminafiltro(filtro);
    }
  }

  eliminafiltro(filtro){
  } 

  enrutar(ruta){
    this._router.navigate([ruta]);
  }
}
