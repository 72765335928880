<h2 class="centrado">{{title}}</h2>

<form #cambiaForm="ngForm" (ngSubmit)="onSubmit(cambiaForm)">
	<div class="formulario" >
				<p *ngIf="status != 'success'">
					<label>Nueva Contraseña</label>
					<input type="password" name="password" #password="ngModel" [(ngModel)]="pasword" class="form-control" required />
				</p>
				<p *ngIf="status != 'success'">
					<label>Repetir Contraseña</label>
					<input type="password" name="password2" #password2="ngModel" [(ngModel)]="pasword2" class="form-control" required />
				</p>
		<div class="alert alert-danger aviso" *ngIf="status == 'error'"> 
						las contraseñas no coinciden
		</div>
		<div class="alert alert-danger aviso" *ngIf="status == 'error2'">{{mensajeerror}}</div>
		<div class="alert alert-success aviso" *ngIf="status == 'success'"> 
						las contraseñas son correctas haga click <a [routerLink]="['../../login']">Aqui</a> para iniciar sesion
		</div>			
		<div class="centrado">
		<input type="submit" value="Cambiar Contraseña" class="btn btn-success centrado" [disabled]="!cambiaForm.form.valid" *ngIf="status != 'success'"/>
		</div>
	</div>
</form>