<div class="contenido">
    <div class="filtros">
        <div class="cajaherramientasincidencias overfondo" style="cursor:pointer;padding: 5px;" (click)="guardar()">
            <mat-icon style="font-size:25pt">save</mat-icon><br>
            Guardar
        </div>
        <span class="spacercorto"></span>
        <div class="cajaherramientasincidencias overfondo" style="cursor:pointer;padding: 5px;" (click)="getdestinatariospendientes()">
            <mat-icon style="font-size:25pt">person_pin</mat-icon><br>
            Pendientes
        </div>
        <div class="cajaherramientasincidencias overfondo" style="cursor:pointer;padding: 5px;" (click)="getdestinatarioserrores()">
            <mat-icon style="font-size:25pt">no_accounts</mat-icon><br>
            Errores
        </div>
        <div class="cajaherramientasincidencias overfondo" style="cursor:pointer;padding: 5px;" (click)="getdestinatariostotales()">
            <mat-icon style="font-size:25pt">account_circle</mat-icon><br>
            Totales
        </div>
        <span class="spacercorto"></span>
        <div class="cajaherramientasincidencias overfondo" style="cursor:pointer;padding: 5px;" (click)="cerrar()">
            <mat-icon style="font-size:25pt">close</mat-icon><br>
            Cancelar
        </div>
    </div>
    <div class="listadestinatarios">
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 demo-table tabla">
            <ng-container *ngFor="let column of columns" [matColumnDef]="column">
              <th mat-header-cell *matHeaderCellDef>
                {{column}}
              </th>
              <td mat-cell *matCellDef="let element">
                {{element}}
              </td>
            </ng-container>
            <ng-container matColumnDef="Eliminar">
                <th mat-header-cell *matHeaderCellDef="Eliminar">
                    Eliminar
                </th>
                <td mat-cell *matCellDef="let row">
                    <button mat-button mat-icon-button aria-label="Clear" (click)="eliminadestinatario(row)">
                        <mat-icon>close</mat-icon>
                    </button>
                </td>
            </ng-container>
            <ng-container matColumnDef="disclaimer">
                <td mat-footer-cell *matFooterCellDef colspan="3">
                  {{dataSource.data.length}} Clientes Seleccionados
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="columns; sticky:true"></tr>
            <tr mat-row *matRowDef="let row; columns: columns;"></tr>
            <tr mat-footer-row *matFooterRowDef="['disclaimer'];sticky:true"></tr>
          </table>          
    </div>
</div>
