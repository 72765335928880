import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, OnDestroy, DoCheck } from '@angular/core';
import * as moment from 'moment';
import { AppService } from '../../servicios/app.service';
import { ChatService } from 'src/app/servicios/chat.service';
import { PushNotificationsService } from 'src/app/servicios/notificaciones.service';
import * as Editor from '../../ck5editor/ckeditor.js';

@Component({
  selector: 'mensajeria',
  templateUrl: './mensajeria.component.html',
  styleUrls: ['./mensajeria.component.css'],
  providers:[AppService, ChatService, PushNotificationsService]
})
export class MensajeriaComponent implements OnInit, OnDestroy, DoCheck{
    public identity;
    public destino;
    public textomensaje = '';
    public usuarios = [];
    public mensajeschat = [];
    public usuariosjson = {};
    public conectado = false;
    public urgente = false;
    public Editor = Editor;

    @Input() mensajesnuevos = [];
    @Input() abierto = false;
    @Input() config = false;

    @Output() nuevosmensajes: EventEmitter<any> = new EventEmitter();
    @Output() abrenotificacion: EventEmitter<any> = new EventEmitter();
    

    @ViewChild('msglist') msglist: ElementRef;
    

  constructor(
    private _appservice: AppService,
    private _chatService: ChatService,
    private _notificationService: PushNotificationsService
  ){
    
  }
  
  ngOnInit() {
    if(this.identity && !this.conectado && this.identity.role != 'cliente'){
      this.conectado = true;
      this._chatService.recon.subscribe(
        res=>{
          this.iniciar();
        }
      )
    }
  }

  ngDoCheck(){
    this.identity = this._appservice.getIdentity();
    if(this.identity && !this.conectado && this.identity.role != 'cliente'){
      this.conectado = true;
      this._chatService.recon.subscribe(
        res=>{
          this.iniciar();
        }
      )
    }
  }

  ngOnDestroy(){
    console.log('mensajeria destruido');
  }

  iniciareditor(editor){
    editor.editing.view.document.on('keydown', (e, data)=>{
      if(data.keyCode == 13){
        data.preventDefault();
        e.stop();
        this.enviamensaje();
      }
    }, { priority : "highest" } );
  }

  iniciar(){
    this.identity = this._appservice.getIdentity();
    this.conectado = true;
    if(this.identity.role != 'cliente'){
      this._chatService.emitEvent('reguser', {
        usuario: this.identity._id,
        alias: this.identity.nombre,
        horaconexion: moment().toISOString(),
        tipo: this.identity.role
      });

      this._chatService.outEven.subscribe(
        res=>{
          if(res.msg.usuario != this.identity._id){
            if(this.abierto && this.destino && this.destino.usuario == res.msg.usuario){
              this.getmensajeschat(this.destino);
            }

            if(this.abierto && this.destino && this.destino.usuario != res.msg.usuario){
              this.agregamensajenotificacion(res.msg);
            }

            if(this.abierto && !this.destino && res.msg.todos){
              this.getmensajestodos();
            }
            
            if(!this.abierto){
              this.agregamensajenotificacion(res.msg);

              var temporal = document.createElement("div");
              temporal.innerHTML = res.msg.mensaje;
              var texto = temporal.textContent || temporal.innerText || "";

              let notif = [{
                title: 'mensaje de: ' + this.aliasusuario(res.msg.usuario),
                alertContent: texto,
                data: res.msg
              }];
              this._notificationService.generateNotification(notif);
              if(res.msg.urgente){
                var ventana = window.open("");
                let mensaje = `tienes un mensaje urgente de ${this.usuariosjson[res.msg.usuario].alias}`;
                ventana.alert(mensaje);
                ventana.close();
              }
            }
          }
      });

      this._notificationService.notificaciones.subscribe(
        response=>{
          if(response && response.event && response.event.type == 'click'){
            var mensaje = response.event.target.data;
            if(mensaje.todos){
              this.seleccionausuariotodos();
            }else{
              let usuario = this.usuariosjson[mensaje.usuario];
              this.seleccionausuario(usuario);
              this.abrenotificacion.emit(mensaje);
            }
          }
        }
      );
      
      this._chatService.users.subscribe(res=>{
        this.usuarios = res;
        this.usuarios.forEach((usuario)=>{
          this.usuariosjson[usuario.usuario] = usuario; 
        });
      });

      this._chatService.getmensajesnuevos().subscribe(
        response=>{
          if(response.mensajes){
            let mensajes = response.mensajes.filter(men=>men.usuario != this.identity._id).map((msg)=>{
              let mens = msg;
              mens.alias = this.aliasusuario(mens.usuario);
              return mens;
            });;
            this.nuevosmensajes.emit(mensajes);
          }
        }
      )
      this._notificationService.requestPermission();
    }   
  }
  
  seleccionausuario(usuario){
    this.mensajeschat = [];
    this.destino = usuario;
    this.getmensajeschat(usuario);
    this._chatService.marcarchatleido(usuario).subscribe();
    this.limpianotificacionesusuario(usuario.usuario);
  }

  seleccionausuariotodos(){
    this.mensajeschat = [];
    this.destino = null;
    this.getmensajestodos();
    this._chatService.marcarchattodosleido().subscribe();
    this.limpianotificacionestodos();
  }

  agregamensajenotificacion(mensaje){
    let mensajesnuevos = this.mensajesnuevos.concat([mensaje]).map((msg)=>{
      let mens = msg;
      mens.alias = this.usuariosjson[mens.usuario].alias;
      return mens;
    });
    this.nuevosmensajes.emit(mensajesnuevos);
  }

  abrirnotificacion(mensaje){
    if(mensaje.todos){
      this.seleccionausuariotodos();
    }else{
      let destinatario = this.usuariosjson[mensaje.usuario];
      this.seleccionausuario(destinatario);
    }
  }

  limpianotificacionesusuario(usuario){
    let mensajesnuevos = this.mensajesnuevos.filter(msg=>msg.usuario != usuario);
    this.nuevosmensajes.emit(mensajesnuevos);
  }

  limpianotificacionestodos(){
    let mensajesnuevos = this.mensajesnuevos.filter(msg=>msg.todos != true);
    this.nuevosmensajes.emit(mensajesnuevos);
  }

  mensajesnuevosusuario(usuario){
    let cantidad = this.mensajesnuevos.filter((msg)=>{
      if(msg.usuario == usuario && !msg.todos){
        return true;
      }else{
        return false;
      }
    });
    return cantidad.length;
  }

  mensajesnuevostodos(){
    let cantidad = this.mensajesnuevos.filter(msg=>msg.todos);
    return cantidad.length;
  }


  eliminausuario(usuario){
    this._chatService.emitEvent('deleteuser', usuario);
  }

  enviamensaje(){
    var mensaje = {
      usuario: this.identity._id,
      mensaje: this.textomensaje,
      fechahora: moment().toISOString()
    }
    if(this.urgente){
      mensaje['urgente'] = true;
    }
    if(!this.destino){
      this._chatService.emitEvent('mensajeatodos', mensaje);
    }else{
      mensaje['destinatario'] = this.destino.usuario;
      this._chatService.emitEvent('enviamensaje', mensaje);
    }
    this.mensajeschat.push(mensaje);
    this.scrollfinal();
    this.urgente = false;
    this.textomensaje = null;   
  }

  aliasusuario(usuarioid){
    let user = this.usuariosjson[usuarioid];
    if(user){
      return user.alias;
    }else{
      return 'Yo'
    }
  }

  scrollfinal = () => {
    setTimeout(()=>{
      try {
        this.msglist.nativeElement.scrollTop = this.msglist.nativeElement.scrollHeight + 100;
      } catch (err) {
        console.log(err);
      }
    }, 1000)
  }

  getmensajeschat(usuario){
    this._chatService.getmensajeschat(usuario).subscribe(
      response=>{
        if(response.mensajes){
          this.mensajeschat = response.mensajes;
          this.scrollfinal();
        }
      }
    )
  }

  getmensajestodos(){
    this._chatService.getmensajestodos().subscribe(
      response=>{
        if(response.mensajes){
          this.mensajeschat = response.mensajes;
          this.scrollfinal();
        }
      }
    )
  }

}