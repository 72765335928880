import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ClienteService } from '../../servicios/cliente.service';
import { AppService } from '../../servicios/app.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-clientes',
  templateUrl: './clientes.component.html',
  styleUrls: ['./clientes.component.css'],
  providers: [ClienteService, AppService]
})
export class ClientesComponent implements OnInit {
	public title: String;
	public clientes;
	public identity;
  public clienteabuscar: string;
  public seleccion;
  public ClientesSeleccionados;
  public listacliamostrar;
  public filtro;
  public abonado = null;
  public dataSource = new MatTableDataSource<any>();
  public columns = [
    {
      nombre: 'numero',
      header: 'numero'
    },
    {
      nombre: 'nombre',
      header: 'nombre'
    }
  ] 
  public displayedColumns = this.columns.map(c=> c.header);
  @ViewChild(MatSort) sort: MatSort;

  constructor(
  	private _router: Router,
    private _clienteService: ClienteService,
    private _appservice: AppService
  ) {
  	this.identity = this._appservice.getIdentity();
    this.ClientesSeleccionados = new Array();
   }

  ngOnInit(): void {
  	this.todoslosclientes();
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  todoslosclientes(){
    this._clienteService.getclientes().subscribe(
      response =>{
        this.dataSource.data = response.clientes.sort((a,b)=>{
            return parseInt(a.numero) > parseInt(b.numero);
          });
        this.clientes = this.dataSource.data;  
        },
      error =>{
        console.log(<any>error);
      } 
    );
  }

  selectcliente(cliente){
    this.abonado = null;
    this.seleccion = cliente;
    this.getabonado();
  }

  abrecliente(id){
    this._router.navigate(['cliente/' + id]);
  }

  getabonado(){
    let query = {Abonado: this.seleccion.numero}
    this._clienteService.getcecos(query).subscribe(
      response=>{
        if(response.cecos && response.cecos.length >= 1){
          this.abonado = response.cecos[0];
          console.log(this.abonado);
        }
      }
    );      
  }
}
