import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition, MatSnackBarConfig } from '@angular/material/snack-bar'; 
import { ClienteService } from '../../servicios/cliente.service';
import { MatTableDataSource } from '@angular/material/table';
import { CobromensajeroComponent } from '../cobromensajero/cobromensajero.component';
import { DireccionesmensajeroComponent } from '../direccionesmensajero/direccionesmensajero.component';
import { DeudasComponent } from '../deudas/deudas.component';
import * as moment from 'moment';

@Component({
  selector: 'app-mensajero',
  templateUrl: './mensajero.component.html',
  styleUrls: ['./mensajero.component.css'],
  providers: [ClienteService]
})
export class MensajeroComponent implements OnInit {
	public mensajero;
  public estadocobrado;
  public Fechahoy
  public load;

  constructor(
  	  private _route: ActivatedRoute,
	    private _router: Router,
	    private _clienteService: ClienteService,
      private _snackBar: MatSnackBar
  ) {
    
  }

  ngOnInit(): void {
    this.load = true;
    this.Fechahoy = moment().format('DD/MM/YYYY');
  	this._route.params.subscribe(params=>{this._clienteService.getcliente(params.numero).subscribe(
  		response=>{
  			this.mensajero = response.cliente;
        if(this.mensajero['pendiente'] == this.Fechahoy){
          this.estadocobrado = true;
        }else{
          this.estadocobrado = false;
        }
      })  
  	});   
  }

  enrutar(ruta){
    this._router.navigate([ruta]);
  }

  aviso(texto){
    alert(texto);
  }

  checkcobrado(check){
    if(check == true) this.marcacobrado();
    if(check == false) this.marcanocobrado();
  }

  marcacobrado(){
    this.mensajero['pendiente'] = this.Fechahoy;
    this.estadocobrado = true;
    this.actualizacliente();  
  }

  marcanocobrado(){
    var fechahoy = moment().add(-1, 'days').format('DD/MM/YYYY');
    this.mensajero['pendiente'] = fechahoy;
    this.estadocobrado = false;
    this.actualizacliente();  
  }

  actualizacliente(){
    this._clienteService.updateCliente(this.mensajero).subscribe(
      response=>{
        
      }
    );
  }
}
