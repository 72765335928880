<div class="contenidoincidencias">
		<table mat-table *ngIf="dataSource && identity" [dataSource]="dataSource" matSort cdkDropList cdkDropListOrientation="horizontal" (cdkDropListDropped)="drop($event)" class="tabla">
			<ng-container *ngFor="let column of identity.options.ordencamposreembolsos.mensajeros" [matColumnDef]="column">
			<th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
				{{column}}
			</th>
			<td mat-cell *matCellDef="let element">
				{{element[column]}}
			</td>
			</ng-container>
			<ng-container matColumnDef="pendiente">
			<th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
				Cobrado?
			</th>
			<td mat-cell *matCellDef="let element"><b *ngIf="element.pendiente == 'cobrado'">{{element.pendiente}}</b></td>
			</ng-container>
			<ng-container matColumnDef="importe">
			<th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
				Reembolsos
			</th>
			<td mat-cell *matCellDef="let element">{{totales[element.numero] | currency: 'EUR'}}</td>
			</ng-container>
			<ng-container matColumnDef="Deudas">
			<th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
				<mat-card style="width:100%;font-size:10pt;height:15pt;padding:2px;margin:0px;">
					Deudas
				</mat-card>
			</th>
			<td mat-cell *matCellDef="let element"> {{totaldeudas[element.numero] | currency: 'EUR'}} </td>
			</ng-container>
			<tr mat-header-row *matHeaderRowDef="identity.options.ordencamposreembolsos.mensajeros; sticky:true"></tr>
			<tr mat-row (click)="seleccionar(row)" (dblclick)="enrutar('mensajero/' + row._id)" class="celdas" [class.seleccionada]="seleccion == row" *matRowDef="let row; columns: identity.options.ordencamposreembolsos.mensajeros;">
		<!--<ng-container matColumnDef="nombre">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
			    <mat-form-field appearance="outline" style="text-align:center;max-width:110px;font-size:7pt">
				  <mat-label style="color:black;"><b>Nombre</b></mat-label>
				  <input matInput type="text" [(ngModel)]="filtros['nombre']" style="padding:1px;color:black;">
				  <button mat-button *ngIf="filtros['nombre']" matSuffix mat-icon-button aria-label="Clear" (click)="filtros['nombre']=''">
				    <mat-icon>close</mat-icon>
				  </button>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.nombre}} </td>
		  </ng-container>
		  <ng-container matColumnDef="telefono">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-card style="width:100%;font-size:10pt;height:15pt;padding:2px;margin:0px;">
					   telefono
				</mat-card>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.telefono}} </td>
		  </ng-container>

		  <ng-container matColumnDef="direccion">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-card style="width:100%;font-size:10pt;height:15pt;padding:2px;margin:0px;">
					   direccion
				</mat-card>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.direccion}} </td>
		  </ng-container>
		  <ng-container matColumnDef="poblacion">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-card style="width:100%;;font-size:10pt;height:15pt;padding:2px;margin:0px;">
					   poblacion
				</mat-card>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.poblacion}} </td>
		  </ng-container>
		  <ng-container matColumnDef="cp">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-card style="width:100%;font-size:10pt;height:15pt;padding:2px;margin:0px;">
					   cp
				</mat-card>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.cp}} </td>
		  </ng-container>
		  <ng-container matColumnDef="numero">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field appearance="outline" style="text-align:center;max-width:80px;font-size:7pt">
				  <mat-label style="color:black;"><b>Numero</b></mat-label>
				  <input matInput type="text" [(ngModel)]="filtros['numero']" style="padding:1px;color:black;">
				  <button mat-button *ngIf="filtros['numero']" matSuffix mat-icon-button aria-label="Clear" (click)="filtros['numero']=''">
				    <mat-icon>close</mat-icon>
				  </button>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.numero}} </td>
		  </ng-container>
		  <ng-container matColumnDef="emailsIncidencias">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-card style="width:100%;font-size:10pt;height:15pt;padding:2px;margin:0px;">
					   email
				</mat-card>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.emailsIncidencias}} </td>
		  </ng-container>
		  <ng-container matColumnDef="pendiente">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-card style="width:100%;font-size:10pt;height:15pt;padding:2px;margin:0px;">
					Cobrado?
				</mat-card>
		    </th>
		    <td mat-cell *matCellDef="let element"><b *ngIf="element.pendiente == 'cobrado'">{{element.pendiente}}</b></td>
		  </ng-container>
		  <ng-container matColumnDef="importe">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-card style="width:100%;font-size:10pt;height:15pt;padding:2px;margin:0px;">
					Reembolsos
				</mat-card>
		    </th>
		    <td mat-cell *matCellDef="let element">{{totales[element.numero] | currency: 'EUR'}}</td>
		  </ng-container>
		  <ng-container matColumnDef="observaciones">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-card style="width:100%;font-size:10pt;height:15pt;padding:2px;margin:0px;">
					   observaciones
				</mat-card>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.observaciones}} </td>
		  </ng-container>
		  <ng-container matColumnDef="Deudas">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-card style="width:100%;font-size:10pt;height:15pt;padding:2px;margin:0px;">
					   Deudas
				</mat-card>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{totaldeudas[element.numero] | currency: 'EUR'}} </td>
		  </ng-container>
		  <tr mat-header-row *matHeaderRowDef="identity.options.ordencamposreembolsos.mensajeros; sticky:true"></tr>
		  <tr mat-row (click)="seleccionar(row)" (dblclick)="enrutar('mensajero/' + row._id)" class="celdas" [class.seleccionada]="seleccion == row" *matRowDef="let row; columns: identity.options.ordencamposreembolsos.mensajeros;">
		  </tr>-->
		</table>
	</div>
	<div style="display:inline-flex;align-items:center;">
		<mat-form-field *ngIf="identity" style="width:35px;margin-left:20px;margin-right:50px;">
			<mat-select [(ngModel)]="identity.options.ordencamposreembolsos.mensajeros" (selectionChange)="onselectcolumnas($event.value)" multiple>
			<mat-select-trigger class="fondooscuro">
				<mat-icon>view_list</mat-icon>
			</mat-select-trigger>
			<mat-option *ngFor="let campo of totalcolumns" [value]="campo">{{campo}}</mat-option>
			</mat-select>
		</mat-form-field>
		<mat-form-field *ngIf="identity" style="width:40px;margin-left:20px;margin-right:50px;">
			<mat-select [(ngModel)]="identity.options.muestramensajeros" (selectionChange)="onselectcmuestramensajeros($event.value)" multiple>
			<mat-select-trigger class="fondooscuro">
				<mat-icon>person_add_disabled</mat-icon>
			</mat-select-trigger>
			<mat-option *ngFor="let mensaca of mensajeros" [value]="mensaca.numero">{{mensaca.numero}}  {{mensaca.nombre}}</mat-option>
			</mat-select>
		</mat-form-field>
	</div>
	