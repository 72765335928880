import { Component, OnInit } from '@angular/core';
import { VehiculoService } from '../../servicios/vehiculos.service';
import { Gasto } from '../../models/gasto';
import { GastoComponent } from '../gasto/gasto.component';
import { ClienteService } from '../../servicios/cliente.service';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition, MatSnackBarConfig } from '@angular/material/snack-bar';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import * as moment from 'moment';

@Component({
  selector: 'gastos',
  templateUrl: './gastos.component.html',
  styleUrls: ['./gastos.component.css'],
  providers: [ClienteService, VehiculoService]
})
export class GastosComponent implements OnInit {
    public gastos;
    public queryactual;
    public fechainicio;
    public fechafin;
    public mes;
    public meses;
    public importesintarjeta;
    public importecontarjeta;
    public importetotal;
    public tipogastos;
    public tarjetas;
    public tarjetaseleccionada;
    public mensajeros;
    public gpm;
    public vehiculos;
    public gpv;
    public seleccion;
    public displayedColumns;

  constructor(
    private _snackBar: MatSnackBar,
    private dateAdapter: DateAdapter<Date>,
    private _vehiculoService: VehiculoService,
    private _clienteService: ClienteService,
    public ventanaGasto: MatDialog
  ) {
    this.dateAdapter.setLocale('es');
    this.dateAdapter.getFirstDayOfWeek = () => { return 1; };
    this.tarjetaseleccionada = undefined;
    this.displayedColumns = ['fecha', 'matricula', 'tarjeta','mensajero','importe','notas'];
    this.gastosmesactual();
    this.meses = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
    this._vehiculoService.gettarjetas({}).subscribe(
      response=>{
        if(response.tarjetas) this.tarjetas = response.tarjetas;
      },
      error=>{
        console.log(<any>error);
      }
    );
    this._clienteService.getmensajeros().subscribe(
      response=>{
        this.mensajeros = response.mensajeros.sort(function(a, b){
          return a['numero'] > b['numero']? 1 : -1;
        });
      },
      error=>{
        console.log(<any>error);
      }
    );
    this._vehiculoService.getvehiculos({}).subscribe(
      response=>{
        if(response.vehiculos) this.vehiculos = response.vehiculos;
      },
      error=>{
        console.log(<any>error);
      }
    );   
  }

  ngOnInit(): void {
    this.tipogastos = 'todos';
  }

  aviso(texto, subtexto) {
    this._snackBar.open(texto,subtexto,{
      duration: 5000,
      horizontalPosition: "center",
      verticalPosition: "top",
    });
  }

  nuevogasto(){
    this.abregasto(new Gasto('',null,'','',0,'','',null,''));
  }

  abregasto(gasto){
    var gastoabierto = this.ventanaGasto.open(GastoComponent, {
      width: '85%',
      height: '85%',
      data: gasto
    });
    
    gastoabierto.afterClosed().subscribe(result =>{
      if(result == true) this.gastosactuales();
    });

  }

  seleccionar(row){
    this.seleccion = row;
  }

  gastosmesactual(){
    let fechaactual = moment().set({hour:21,minute:0,second:0,millisecond:0}).toISOString(true);
    let dia = moment(fechaactual).add(-1, 'days').date();
    let fechaanterior = moment(fechaactual).add(-dia, 'days').set({hour:4,minute:0,second:0,millisecond:0}).toISOString(true);
    let query = {$and:[{fecha:{$gte:fechaanterior}},{fecha:{$lte:fechaactual}}]};
    this.queryactual = query;
    this._vehiculoService.getgastos(query).subscribe(
      response=>{
        if(response.gastos){
          this.gastos = response.gastos;
          this.importesintarjeta = 0;
          this.importecontarjeta = 0;
          this.importetotal = 0;
          this.gastos.forEach(element => {
            this.importetotal = this.importetotal + element.importe;
            if(element.tarjeta){
              this.importecontarjeta = this.importecontarjeta + element.importe;
            }else{
              this.importesintarjeta = this.importesintarjeta + element.importe;
            }  
          });
        }  
      },
      error=>{
        console.log(<any>error);
      }
    );
  }

  toggletipogasto(tipo){
    if(tipo == 'tarjeta') this.queryactual['tarjeta'] = {$nin:[null, '']};
    if(tipo == 'sintarjeta') this.queryactual['tarjeta'] = {$in:[null, '']};
    if(tipo == 'todos') delete this.queryactual['tarjeta'];
    this.gastosactuales();
  }

  seleccionatarjeta(tarjeta){
    this.queryactual['tarjeta'] = tarjeta;
    this.tarjetaseleccionada = tarjeta;
    this.gastosactuales();
  }

  cancelaselecciontarjeta(){
    this.tarjetaseleccionada = undefined;
    this.toggletipogasto('todos');
  }

  gastosactuales(){
    this._vehiculoService.getgastos(this.queryactual).subscribe(
      response=>{
        if(response.gastos){
          this.gastos = response.gastos;
          this.importesintarjeta = 0;
          this.importecontarjeta = 0;
          this.importetotal = 0;
          this.gastos.forEach(element => {
            this.importetotal = this.importetotal + element.importe;
            if(element.tarjeta){
              this.importecontarjeta = this.importecontarjeta + element.importe;
            }else{
              this.importesintarjeta = this.importesintarjeta + element.importe;
            }  
          });
        }  
      },
      error=>{
        console.log(<any>error);
      }
    );
  }

  gastospormensajero(mensajero){
    this.queryactual['mensajero'] = mensajero;
    this.gastosactuales();
  }

  cancelagastospormensajero(){
    delete this.queryactual['mensajero'];
    this.gpm = '';
    this.gastosactuales();
  }

  gastosporvehiculo(matricula){
    this.queryactual['matricula'] = matricula;
    this.gastosactuales();
  }

  cancelagastosporvehiculo(){
    delete this.queryactual['matricula'];
    this.gpv = '';
    this.gastosactuales();
  }

  onDaterangofechas(){
    var fechainicial = moment(this.fechainicio).set({hour:4,minute:0,second:0,millisecond:0}).toISOString(true);
    if(!this.fechafin){
     var fechafinal = moment(this.fechafin).set({hour:21,minute:0,second:0,millisecond:0}).toISOString(true);
    }else{
      var fechafinal = moment(this.fechafin).set({hour:23,minute:0,second:0,millisecond:0}).toISOString(true);
    }
    var query = {$and:[{fecha:{$gte:fechainicial}},{fecha:{$lte:fechafinal}}]};
    this.queryactual = query;
    this.gastosactuales();
  }

}
