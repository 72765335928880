export class Vehiculo{
	constructor(
		public _id: string,
		public propietario: string,
		public fechaalta: string,
		public fechabaja: string,
		public marca: string,
		public modelo: string,
		public matricula: string,
		public imagenes: [
			{
				name: string,
				tipo: string,
				contentBytes: any
			}
		],
		public seguro: string,
		public poliza: string,
		public notas: string,
		public itvs: [{
			fechaitvpasada: string,
			fechaproximaitv: string,
			notas: string
		}],
		public revisiones:[{}]
	){}
}	