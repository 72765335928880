<h2 class="centrado">{{title}}</h2>

<div *ngIf="identity && identity.role == 'Administrador' && cliente" class="divenlace">
	<a class="enlace" [routerLink]="['../../../cliente/' + cliente._id]"><mat-icon style="font-size:25pt">arrow_back</mat-icon><br>Volver</a>	
</div>

<div *ngIf="identity && identity.role == 'cliente' && cliente" class="divenlace">
	<a class="enlace" [routerLink]="['../../../carpetas']"><mat-icon style="font-size:25pt">arrow_back</mat-icon><br>Volver</a>	
</div>

<div class="contenidodatos">
	<table mat-table [dataSource]="facturas">
		<ng-container matColumnDef="factura">
			<th mat-header-cell *matHeaderCellDef class="casilla">Factura</th>
			<td mat-cell *matCellDef="let element" class="casilla">
				<mat-icon style="font-size:28px;margin-right:5px;">cloud_download</mat-icon> {{element.factura}}
			</td>
		</ng-container>
		<tr mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></tr>
		<tr mat-row *matRowDef="let row; columns: displayedColumns;" class="celdas" (click)='descargaFactura(row.factura)'></tr>
	</table>
</div>
