import { Component, ViewChild, OnInit, AfterViewInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { AppService } from '../../servicios/app.service';
import { ClienteService } from '../../servicios/cliente.service';
import { IncidenciasService } from '../../servicios/incidencias.service';
import { Incidencia } from '../../models/incidencia';
import { MatDialog } from '@angular/material/dialog';
import { AlbclienteComponent } from '../albcliente/albcliente.component';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'anomaliasclientes',
  templateUrl: './anomaliasclientes.component.html',
  styleUrls: ['./anomaliasclientes.component.css'],
  providers: [ClienteService, IncidenciasService, AppService]
})

export class AnomaliasclientesComponent implements OnInit, AfterViewInit{
  public dataSource = new MatTableDataSource<Incidencia>()
  public selection = new SelectionModel<Incidencia>(true, []);
  public filtro;
  public identity;
  public load = true;
  public category;
  public estadosnoincluir;
  public envios;
  public totalcolumns;
  public seleccion;
  public gestionable;
  public listagestionables;
  public urlseguimientomrw;
  public seguimiento = false;
  @ViewChild(MatSort, {static: false}) set content(sort: MatSort){this.dataSource.sort = sort;}

  constructor(
    private _clienteService: ClienteService,
    private _incidenciasservice: IncidenciasService,
    private _appservice: AppService,
    private _sanitizer: DomSanitizer,
    public ventanaAlbaran: MatDialog
  ){
    /*listaestado:['EntregaPendiente','EnvioCancelado','RecogidaFinalizada','RecogidaPendiente','TransitoInternoEntrega','EntregaCancelada','EntregaFinalizada']
     */
    this.identity = this._appservice.getIdentity();
    this.estadosnoincluir = ['EntregaFinalizada', 'EnvioCancelado', 'EntregaCancelada'];
    this.listagestionables = ['INotificarDestinatarioDesconocido']
    this.totalcolumns = ['select','FechaRecogida','Albaran','Estado','Referencia','Destinatario','DireccionDestinatario','PoblacionDestinatario','CpDest','Motivo','Kilos','Bultos'];
    this.selection = new SelectionModel<Incidencia>(true, []);
  }

  ngOnInit(): void {
    this.getanomalias();
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.content;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  isAllSelected() {
    if(this.dataSource.filteredData){
      const numSelected = this.selection.selected.length;
      const numRows = this.dataSource.filteredData.length;
      return numSelected === numRows;
    }else{
      const numSelected = this.selection.selected.length;
      const numRows = this.dataSource.data.length;
      return numSelected === numRows;
    }  
  }

 // Selects all rows if they are not all selected; otherwise clear selection.
  masterToggle() {
    if(this.dataSource.filteredData){
        this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.filteredData.forEach(row => this.selection.select(row));
    }else{  
      this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.data.forEach(row => this.selection.select(row));
    }    
  }

  // The label for the checkbox on the passed row 
  checkboxLabel(row?: Incidencia): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  seleccionar(row){
    this.seleccion = row;
    //if(this.listagestionables.includes(row.Tipo)){
     // this.gestionable = true;
    /*}else{*/
      this.gestionable = false;
    //}
    this.urlseguimientomrw = undefined;
  }

  getanomalias(){
    this.load = false;
    this.category = 'totales';
    var query = {
       Anomalia: true,
       Estado:{$nin:this.estadosnoincluir},
       FranquiciaClienteFacturacion: '02601' 
    };   
    this._incidenciasservice.getanomaliascliente(query).subscribe(
        response =>{
        if(response.incidencias){
​​          this.dataSource.data = response.incidencias.map((inc, index)=>{
            inc['position'] = index;
            return inc
          });
          this.load = true;
        }else{
          this.load = true;
        }
      },
      error =>{
        console.log(<any>error);
        this.load = true;
      } 
    );
  }

  humanizemotivo(motivo){
    var motivohumanizado:string;
    switch(motivo){
      case 'OT0700':
        motivohumanizado = 'Clasificado en plataforma';
        break;
      case 'OE0062': 
        motivohumanizado = 'Depositado en Franquicia de destino';
        break;
      case 'OE0600':
        motivohumanizado = 'Mensajero de entrega asignado';
        break;  
      case 'OR0600':
        motivohumanizado = 'Mensajero de recogida asignado';
        break; 
      case 'OE0500':
        motivohumanizado = 'Recibido en franquicia de destino';
        break;
      case 'OR0200':
        motivohumanizado = 'Recogida finalizada';
        break;
      case 'OS0200':
        motivohumanizado = 'Recogida pendiente';
        break;
      case 'OE0100':
        motivohumanizado = 'Tránsito interno de entrega';
        break;
      case 'OR0402':
        motivohumanizado = 'Envio borrado';
        break;
      case 'OR0201':
        motivohumanizado = 'Recogida finalizada en franquicia';
        break;
      case 'OE0702':
        motivohumanizado = 'Reexpedir Envio';
        break;
      case 'OE0401':
        motivohumanizado = 'Devolver al remitente';
        break;
      case 'OE1103':
        motivohumanizado = 'Cliente Ordena destruir el envio';
        break; 
      case 'OR0800':
        motivohumanizado = 'Salida de franquicia origen a plataforma';
        break;  
      default:
        motivohumanizado = motivo;    
    }
    return motivohumanizado;
  }

  gestionanomalia(incidencia){
    var albaranabierto = this.ventanaAlbaran.open(AlbclienteComponent, {
      width: '85%',
      height: '85%',
      data: incidencia
    });
 }

 verseguimiento(){
  this.seguimiento = true;
  var uri = 'https://www.mrw.es/seguimiento_envios/MRW_resultados_consultas.asp?modo=nacional&envio=' + this.seleccion.NumAlbaran;
  this.urlseguimientomrw = this._sanitizer.bypassSecurityTrustResourceUrl(uri);
}

}
