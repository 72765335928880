import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as moment from 'moment';
import { FichajeService } from '../../servicios/fichaje.service';
import { AppService } from '../../servicios/app.service';

@Component({
  selector: 'dialogfichaje',
  templateUrl: './dialogfichaje.component.html',
  styleUrls: ['./dialogfichaje.component.css'],
  providers:[FichajeService, AppService]
})
export class DialogfichajeComponent implements OnInit {
  public hora = moment().format('HH:mm');
  public usuario;
  constructor(
    public dialogRef: MatDialogRef<DialogfichajeComponent>,
    private _appservice: AppService,
    private _fichajeService: FichajeService,
    @Inject(MAT_DIALOG_DATA) public data
  ){
    this.usuario = this.data.usuario;
  }

  cancelar(){
      this.dialogRef.close('cancelado fichaje');
  }

  ficharentrada(){
    if(this.data.estado == 'sin datos'){
      this.data.fecha = moment().format('DD/MM/YYYY');
      this.data.estado = 'nuevo'
    }
    if(this.data.horaentrada){
      this.data.horaentrada2 = moment().format('HH:mm:ss');
      this.data.fechahoraentrada2 = moment().toISOString();
      var horaficha = this.data.horaentrada2;
    }else{
      this.data.horaentrada = moment().format('HH:mm:ss');
      this.data.fechahoraentrada = moment().toISOString();
      var horaficha = this.data.horaentrada;
    }

    if(this.data.estado == 'nuevo'){
      this.data.estado = true;
      this.guardafichaje('entrada fichada a las: ' + horaficha);
    }else{
      this.data.estado = true;
      this.updatefichaje(this.data, 'entrada fichada a las: ' + horaficha);
    }
  }

  ficharsalida(){
    if(this.data.horasalida){
      this.data.horasalida2 = moment().format('HH:mm:ss');
      this.data.fechahorasalida2 = moment().toISOString();
      var horaficha = this.data.horasalida2;
    }else{
      this.data.horasalida = moment().format('HH:mm:ss');
      this.data.fechahorasalida = moment().toISOString();
      var horaficha = this.data.horasalida;
    }
    this.data.estado = false;
    this.updatefichaje(this.data, 'entrada fichada a las: ' + horaficha);
  }

  ngOnInit(): void {
  }

  guardafichaje(mensaje){
    let ident = this._appservice.getIdentity();
    let usrid = this._appservice.getusrid();
    if(ident){
      localStorage.setItem('usrid', ident['_id']);
    }else if(usrid && usrid != null){
      localStorage.setItem('usrid', usrid);
    }
    
    if(usrid && usrid != null){
      this.data['usrid'] = usrid;
      this._fichajeService.ficharqr(this.data).subscribe(
        response=>{
          if(response){
            this._fichajeService.setficha(response.ficha);
            this.dialogRef.close(mensaje);
          }
        }
      )     
    }else if(ident && ident['_id']){
      this._fichajeService.fichar(this.data).subscribe(
        response=>{
          if(response){
            this._fichajeService.setficha(response.ficha);
            this.dialogRef.close(mensaje);
          }
        }
      )     
    }
  }

  updatefichaje(datos, mensaje){
    let id = datos._id;
    delete datos._id;
    var usrid = this._appservice.getusrid();
    if(usrid){
      this._fichajeService.updatefichajeqr(datos, id).subscribe(
        response=>{
          if(response){
            this._fichajeService.setficha(response.fichajeUpdated);
            this.dialogRef.close(mensaje);
          }
        }
      )
    }else{
      this._fichajeService.updatefichaje(datos, id).subscribe(
        response=>{
          if(response){
            this._fichajeService.setficha(response.fichajeUpdated);
            this.dialogRef.close(mensaje);
          }
        }
      )
    }  
  }
}
