<div class="contenido">
    <div class="filtros">
        <div class="cajaherramientasincidencias overfondo" style="cursor:pointer;padding: 5px;" (click)="guardar()">
            <mat-icon style="font-size:25pt">save</mat-icon><br>
            Guardar
        </div>
        <span class="spacercorto"></span>
        <div class="botonesfiltros">
            <div>
                <mat-slide-toggle [(ngModel)]="filtros.clientesg3" (change)="checkincluirg3($event.checked)" color="primary">
                    <span>Incluir Clientes G3</span>
                </mat-slide-toggle>
            </div>
            <div>
                <mat-slide-toggle [(ngModel)]="filtros.solog3" (change)="checksolog3($event.checked)" color="primary">
                    <span>Solo Clientes G3</span>
                </mat-slide-toggle>
            </div>		
        </div>
        <div class="botonesfiltros">
            <div>
                <mat-slide-toggle [(ngModel)]="filtros.personalizar" (change)="checkpersonalizar($event.checked)" color="primary">
                    <span>Personalizar</span>
                </mat-slide-toggle>
            </div>
            <div>
                <mat-form-field *ngIf="filtros.solog3" style="width:400px;">
                    <mat-label>Tarifa</mat-label>
                    <input matInput type="text" [matAutocomplete]="auto5" [formControl]="myControl">
                    <button mat-button *ngIf="myControl.value" matSuffix mat-icon-button aria-label="Clear" (click)="todastarifas()">
                        <mat-icon>close</mat-icon>
                    </button>
                    <mat-autocomplete #auto5="matAutocomplete" (optionSelected)="filtratarifa($event.option.value)">
                        <mat-option *ngFor="let tarifa of filteredOptions | async" [value]="tarifa">
                            {{tarifa}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>		
        </div>
        <span class="spacercorto"></span>
        <div class="cajaherramientasincidencias overfondo" style="cursor:pointer;padding: 5px;" (click)="limpiarlista()">
            <mat-icon style="font-size:25pt">layers_clear</mat-icon><br>
            Limpiar Lista
        </div>
        <div class="cajaherramientasincidencias overfondo" style="cursor:pointer;padding: 5px;" (click)="cerrar()">
            <mat-icon style="font-size:25pt">close</mat-icon><br>
            Cancelar
        </div>
    </div>
    <mat-form-field *ngIf="filtros.personalizar" style="width:100%;text-align:center;font-size:12pt;font-weight:700;margin-bottom:0">
        <mat-label>Email</mat-label>
          <input matInput [(ngModel)]="destinatario" (keyup.enter)="agregadestinatario()" placeholder="Email a incluir..">
          <button mat-button matSuffix mat-icon-button aria-label="Clear" (click)="destinatario = undefined">
            <mat-icon>close</mat-icon>
          </button>
          <mat-hint>Escribe el email para agregar a la lista y pulsa "Enter"</mat-hint>
    </mat-form-field>
    <div class="listadestinatarios">
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 demo-table tabla">
            <ng-container *ngFor="let column of columns" [matColumnDef]="column">
              <th mat-header-cell *matHeaderCellDef>
                {{column}}
              </th>
              <td mat-cell *matCellDef="let element">
                {{element[column]}}
              </td>
            </ng-container>
            <ng-container matColumnDef="Eliminar">
                <th mat-header-cell *matHeaderCellDef="Eliminar">
                    Eliminar
                </th>
                <td mat-cell *matCellDef="let row">
                    <button mat-button mat-icon-button aria-label="Clear" (click)="eliminadestinatario(row.Email)">
                        <mat-icon>close</mat-icon>
                    </button>
                </td>
            </ng-container>
            <ng-container matColumnDef="disclaimer">
                <td mat-footer-cell *matFooterCellDef colspan="3">
                  {{dataSource.data.length}} Clientes Seleccionados
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="columns; sticky:true"></tr>
            <tr mat-row *matRowDef="let row; columns: columns;"></tr>
            <tr mat-footer-row *matFooterRowDef="['disclaimer'];sticky:true"></tr>
          </table>          
    </div>
</div>