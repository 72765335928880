<div class="barraprincipalincidencias">
	<div class="cajaherramientasincidenciasdoble">
		<div class="herramientaincidenciaspequena" (click)="getconcertadas()" [class.selectcategory]="category == 'totales'">
		<mat-icon style="font-size:12pt">pending_actions</mat-icon> Concertadas Dia Siguiente
		</div>
		<div class="herramientaincidenciaspequena" (click)="categoriaseleccionada('depositados'); getdepositados()" [class.selectcategory]="category == 'depositados'">
			<mat-icon style="font-size:12pt">business</mat-icon> Depositados en Agencia 
		</div>
		<div class="herramientaincidenciaspequena" (click)="categoriaseleccionada('pendientes'); getpendientes()" [class.selectcategory]="category == 'pendientes'">
			<mat-icon style="font-size:12pt">local_printshop</mat-icon> Pendientes de Imprimir 
		</div>
		<div class="herramientaincidenciaspequena" (click)="categoriaseleccionada('impresas'); getimpresas()" [class.selectcategory]="category == 'impresas'">
			<mat-icon style="font-size:12pt">print_disabled</mat-icon> Impresas 
		</div>
	</div>
	<div *ngIf="selection.selected.length >= 1 && category != 'depositados'" class="cajaherramientasincidencias" style="padding:7px;">
		<div class="overfondo" style="padding:5px;font-size:10pt;cursor:pointer;" (click)="imprimiretiquetas(selection.selected)">
			<mat-icon style="font-size:30pt">local_printshop</mat-icon><br>
			Imprimir<br>Etiquetas Seleccionadas
		</div>
	</div>
	<div class="cajaherramientasincidenciasdoble">
			<mat-form-field appearance="outline" style="text-align:center;width:100%;">
				  <mat-label style="color:black;"><b>Buscar Albaran</b></mat-label>
				  <input matInput type="text" [(ngModel)]="filtros['NumAlbarang3']" style="padding:1px;color:black;"
				  (keyup.enter)="buscarAlbaran()" (ngModelChange)="filtros['NumAlbarang3'] = $event.toUpperCase()">
				  <button mat-button *ngIf="filtros['NumAlbarang3']" matSuffix mat-icon-button aria-label="Clear" (click)="filtros['NumAlbarang3']=''; getconcertadas()">
				    <mat-icon>close</mat-icon>
				  </button>
			</mat-form-field>
			<mat-form-field appearance="outline" style="width:100%;font-size:9pt;">
			  <mat-label style="font-size:11pt;color:black;">Buscar texto</mat-label>
			  <input matInput (keyup)="applyFilter($event)" placeholder="Buscar.." [(ngModel)]="filtros['filtroprincipal']">
			   <button mat-button *ngIf="filtros['filtroprincipal']" matSuffix mat-icon-button aria-label="Clear" (click)="dataSource.filter = ''; filtros['filtroprincipal']=''">
				<mat-icon>close</mat-icon>
			   </button>
			</mat-form-field>
	</div>
	<div class="cajaherramientasincidenciasdoble" style="padding:5px;" (click)="proximodialibre()">
		<div style="width:100%;font-size:10pt;"><b>Proximo dia libre:<br>{{fechalibre}}</b></div>
		<div *ngIf="identity && identity.role == 'Administrador' || identity && identity.role =='operador-plus'">
			<mat-form-field appearance="outline" style="text-align:center;width:100%;font-size:8pt">
			  <mat-label style="color:black;" (click)="picker.open()"><b>Cerrar Dia</b></mat-label>
				<input matInput [matDatepicker]="picker" (click)="picker.open()" [(ngModel)]="cerrardia">
			  <button mat-button *ngIf="cerrardia" matSuffix mat-icon-button aria-label="Clear" (click)='cerrardia=undefined'>
			    <mat-icon>close</mat-icon>
			  </button>
			  <mat-datepicker #picker></mat-datepicker>
			</mat-form-field>
			<button mat-raised-button style="font-size:8pt" *ngIf="cerrardia" (click)="cierradia()">
				Cerrar Dia
			</button>
			<button mat-raised-button style="font-size:8pt" *ngIf="cerrardia" (click)="abredia()">
				Abrir Dia
			</button>
			
		</div>
	</div>
	<div class="cajaherramientasincidenciasdoble">
		<div class="herramientaincidenciaspequena" *ngIf="albarananterior" style="text-align:center;" (dblclick)="abrealbaran(albarananterior)"><strong>{{albarananterior.NumAlbaran}}</strong></div>
		<div class="herramientaincidenciaspequena" (click)="categoriaseleccionada('imensajero'); getimpresasmensajero()" [class.selectcategory]="category == 'imensajero'">
			<mat-icon style="font-size:12pt">contact_page</mat-icon> Impresas Mensajero
		</div>
		<div class="herramientaincidenciaspequena" (click)="categoriaseleccionada('phoy');getpendienteshoy()" [class.selectcategory]="category == 'phoy'">
			<mat-icon *ngIf="concertadaspendienteshoy >= 1" style="font-size:12pt">notification_important</mat-icon><mat-icon *ngIf="concertadaspendienteshoy == 0" style="font-size:12pt">notifications_none</mat-icon><span [matBadge]="concertadaspendienteshoy" [matBadgeOverlap]="false" matBadgeSize="medium" [matBadgeHidden]="concertadaspendienteshoy == 0">Pendientes Hoy</span>
		</div>
		<div class="herramientaincidenciaspequena" (click)="categoriaseleccionada('ptot');getpendientestot()" [class.selectcategory]="category == 'ptot'">
			<mat-icon *ngIf="anterioressinimprimir.length >= 1" style="font-size:12pt">notification_important</mat-icon><mat-icon *ngIf="anterioressinimprimir.length == 0" style="font-size:12pt">notifications_none</mat-icon><span [matBadge]="anterioressinimprimir.length" [matBadgeOverlap]="false" matBadgeSize="medium" [matBadgeHidden]="anterioressinimprimir.length == 0">Totales Sin Imprimir</span>
		</div>
	</div>		
	<div *ngIf="selection.selected.length >= 1" class="cajaherramientasincidencias" style="padding:7px;">
		<div class="overfondo" style="padding:5px;font-size:10pt;cursor:pointer;" (click)="imprimirmensajeros(selection.selected)">
			<mat-icon style="font-size:30pt">local_printshop</mat-icon><br>
			Etiquetas<br>Mensajero
		</div>
	</div>
	<div *ngIf="selection.selected.length >= 1" class="cajaherramientasincidencias" style="padding:7px;">
		<mat-form-field style="width:100%">
			<mat-label>Ubicar</mat-label>
			<input type="text" matInput [formControl]="controlUbicaciones" [matAutocomplete]="auto" (keyUp.enter)="ubicar(selection.selected, controlUbicaciones.value)">
			<button mat-button matSuffix mat-icon-button aria-label="Clear" (click)="controlUbicaciones.setValue('')">
				<mat-icon>close</mat-icon>
			</button>
			<mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="ubicar(selection.selected, controlUbicaciones.value)">
				<mat-option *ngFor="let option of filteredUbicaciones | async" [value]="option">
					{{option}}
				</mat-option>
			</mat-autocomplete>
		</mat-form-field>
	</div>

</div>
<div class="contenidoincidencias">
	<mat-progress-bar *ngIf="!load" mode="indeterminate"></mat-progress-bar>
		<table mat-table *ngIf="dataSource && identity" [dataSource]="dataSource" matSort cdkDropList cdkDropListOrientation="horizontal" (cdkDropListDropped)="drop($event)">
		  <ng-container matColumnDef="select" class="cabeceras">
		    <th mat-header-cell cdkDrag *matHeaderCellDef class="cabeceras">
		      <mat-checkbox color="primary" (change)="$event ? masterToggle() : null"
		                    [checked]="selection.hasValue() && isAllSelected()"
		                    [indeterminate]="selection.hasValue() && !isAllSelected()"
		                    [aria-label]="checkboxLabel()">
		      </mat-checkbox>
		    </th>
		    <td mat-cell *matCellDef="let row">
		      <mat-checkbox color="primary" (click)="$event.stopPropagation()"
		                    (change)="$event ? selection.toggle(row) : null"
		                    [checked]="selection.isSelected(row)"
		                    [aria-label]="checkboxLabel(row)">
		      </mat-checkbox>
		    </td>
		  </ng-container>
		  <ng-container matColumnDef="NumAlbaran">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
			    <mat-form-field style="text-align:center;max-width:110px;font-size:7pt">
				  <mat-label style="color:black;"><b>Albaran</b></mat-label>
				  <input matInput type="text" [(ngModel)]="filtros['NumAlbaran']" style="padding:1px;color:black;"
				  (keyup.enter)="buscarconcertada()">
				  <button mat-button *ngIf="filtros['NumAlbaran']" matSuffix mat-icon-button aria-label="Clear" (click)="filtros['NumAlbaran']=''; getconcertadas()">
				    <mat-icon>close</mat-icon>
				  </button>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.NumAlbaran}} </td>
		  </ng-container>

		  <!-- Symbol Column -->
		  <ng-container matColumnDef="Tipo">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>Tipo</b></mat-label>
					<input matInput type="text" [(ngModel)]="filtros['Tipo']" style="padding:1px;color:black;"
					(keyup.enter)="onselectfiltro('Tipo', filtros['Tipo'])">
					<button mat-button *ngIf="filtros['Tipo']" matSuffix mat-icon-button aria-label="Clear" (click)="filtros['Tipo']='';eliminafiltro('Tipo');">
					  <mat-icon>close</mat-icon>
					</button>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.Tipo}} </td>
		  </ng-container>

		  <ng-container matColumnDef="Anomalia">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>Anomalia</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.Anomalia}} </td>
		  </ng-container>
		  <ng-container matColumnDef="AnomaliaMotivo">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>Anomalia Motivo</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element" style="max-width:200px;"> {{element.AnomaliaMotivo}} </td>
		  </ng-container>
		  <ng-container matColumnDef="AnomaliaObservaciones">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>Anomalia Observaciones</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element" style="max-width:200px;"> {{element.AnomaliaObservaciones}} </td>
		  </ng-container>
		  <ng-container matColumnDef="CodigoAbonado">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
				  <mat-label style="color:black;"><b>Abonado</b></mat-label>
				  <input matInput type="text" [(ngModel)]="filtros['CodigoAbonado']" style="padding:1px;color:black;"
				  (keyup.enter)="onselectfiltro('CodigoAbonado', filtros['CodigoAbonado'])">
				  <button mat-button *ngIf="filtros['CodigoAbonado']" matSuffix mat-icon-button aria-label="Clear" (click)="filtros['CodigoAbonado']=''; eliminafiltro('CodigoAbonado')">
				    <mat-icon>close</mat-icon>
				  </button>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.CodigoAbonado}} </td>
		  </ng-container>
		  <ng-container matColumnDef="CodigoFranquicia">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>Codigo Franquicia</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.CodigoFranquicia}} </td>
		  </ng-container>
		  <ng-container matColumnDef="ContactoEntrega">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>Contacto Entrega</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.ContactoEntrega}} </td>
		  </ng-container>
		  <ng-container matColumnDef="ContactoRecogida">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>Contacto Recogida</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.ContactoRecogida}} </td>
		  </ng-container>
		  <ng-container matColumnDef="CpDestinatario">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>Cp Destinatario</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.CpDestinatario}} </td>
		  </ng-container>
		  <ng-container matColumnDef="CpRemitente">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>Cp Remitente</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.CpRemitente}} </td>
		  </ng-container>
		  <ng-container matColumnDef="Destinatario">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>Destinatario</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.Destinatario}} </td>
		  </ng-container>
		  <ng-container matColumnDef="DireccionDestinatario">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>Direccion Destinatario</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.DireccionDestinatario}} </td>
		  </ng-container>
		  <ng-container matColumnDef="DireccionRemitente">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>Direccion Remitente</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.DireccionRemitente}} </td>
		  </ng-container>
		  <ng-container matColumnDef="Estado">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;width:100%;font-size:8pt">
				  <mat-label style="font-size:11pt;color:black;">Estado</mat-label>
				  <mat-select [(value)]="filtros['Estado']" (selectionChange)="onselectfiltro('Estado', $event.value)">
				    <mat-option [value]="estado" *ngFor="let estado of listasfiltros.listaestado">{{estado}}</mat-option>
				  </mat-select>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.Estado}} </td>
		  </ng-container>
		  <ng-container matColumnDef="Fase">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>Fase</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.Fase}} </td>
		  </ng-container>
		  <ng-container matColumnDef="FechaEntrega">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:120px;font-size:8pt">
				  <mat-label style="color:black;" (click)="picker3.open()"><b>Fecha Entrega</b></mat-label>
				  <mat-date-range-input [rangePicker]="picker3" (click)="picker3.open()">
					<input matStartDate placeholder="Fecha Inicio" [(ngModel)]="filtros['FechaEntregainicial']">
					<input matEndDate placeholder="Fecha fin" [(ngModel)]="filtros['FechaEntregafinal']" (dateChange)="onDateFechaentrega()">
				  </mat-date-range-input>
				  <button mat-button *ngIf="filtros['FechaEntregainicial']" matSuffix mat-icon-button aria-label="Clear" (click)="filtros['FechaEntregainicial']=''; filtros['FechaEntregafinal']=''; getconcertadas();">
				    <mat-icon>close</mat-icon>
				  </button>
				  <mat-date-range-picker #picker3></mat-date-range-picker>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.FechaEntrega | date:'dd/MM/yyyy HH:mm:ss'}} </td>
		  </ng-container>
		  <ng-container matColumnDef="FechaEnvio">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:110px;font-size:8pt">
				  <mat-label style="color:black;" (click)="picker.open()"><b>Fecha</b></mat-label>
				  <mat-date-range-input [rangePicker]="picker" (click)="picker.open()">
					<input matStartDate placeholder="Fecha Inicio" [(ngModel)]="filtros['FechaEnvioinicial']">
					<input matEndDate placeholder="Fecha fin" [(ngModel)]="filtros['FechaEnviofinal']" (dateChange)="onDateFechaenvio()">
				  </mat-date-range-input>
				  <button mat-button *ngIf="filtros['FechaEnvioinicial']" matSuffix mat-icon-button aria-label="Clear" (click)="filtros['FechaEnvioinicial']=''; filtros['FechaEnviofinal']=''; getconcertadas();">
				    <mat-icon>close</mat-icon>
				  </button>
				  <mat-date-range-picker #picker></mat-date-range-picker>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.FechaEnvio | date:'dd/MM/yyyy'}} </td>
		  </ng-container>
		  <ng-container matColumnDef="EntregarAPartirDe">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>EntregarAPartirDe</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.EntregarAPartirDe}} </td>
		  </ng-container>
		  <ng-container matColumnDef="FechaRecogida">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:120px;font-size:8pt">
				  <mat-label style="color:black;" (click)="picker2.open()"><b>Fecha Recogida</b></mat-label>
				  <mat-date-range-input [rangePicker]="picker2" (click)="picker2.open()">
					<input matStartDate placeholder="Fecha Inicio" [(ngModel)]="filtros['FechaRecogidainicial']">
					<input matEndDate placeholder="Fecha fin" [(ngModel)]="filtros['FechaRecogidafinal']" (dateChange)="onDateFecharecogida()">
				  </mat-date-range-input>
				  <button mat-button *ngIf="filtros['FechaRecogidainicial']" matSuffix mat-icon-button aria-label="Clear" (click)="filtros['FechaRecogidainicial']=''; filtros['FechaRecogidafinal']=''; getconcertadas();">
				    <mat-icon>close</mat-icon>
				  </button>
				  <mat-date-range-picker #picker2></mat-date-range-picker>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.FechaRecogida | date:'dd/MM/yyyy'}} </td>
		  </ng-container>
		  <ng-container matColumnDef="FechaFinalizacionRecogida">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:120px;font-size:8pt">
				  <mat-label style="color:black;" (click)="picker4.open()"><b>Recogida Finalizada</b></mat-label>
				  <mat-date-range-input [rangePicker]="picker4" (click)="picker4.open()">
					<input matStartDate placeholder="Fecha Inicio" [(ngModel)]="filtros['FechaRecogidafinalizadainicial']">
					<input matEndDate placeholder="Fecha fin" [(ngModel)]="filtros['FechaRecogidafinalizadafinal']" (dateChange)="onDateFecharecogidafinalizada()">
				  </mat-date-range-input>
				  <button mat-button *ngIf="filtros['FechaRecogidafinalizadainicial']" matSuffix mat-icon-button aria-label="Clear" (click)="filtros['FechaRecogidafinalizadainicial']=''; filtros['FechaRecogidafinalizadafinal']=''; getconcertadas();">
				    <mat-icon>close</mat-icon>
				  </button>
				  <mat-date-range-picker #picker4></mat-date-range-picker>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.FechaFinalizacionRecogida | date:'dd/MM/yyyy HH:mm:ss'}} </td>
		  </ng-container>
		  <ng-container matColumnDef="FechaConcertacion">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:120px;font-size:8pt">
				  <mat-label style="color:black;" (click)="picker5.open()"><b>ConcertadaG3</b></mat-label>
				  <mat-date-range-input [rangePicker]="picker5" (click)="picker5.open()">
					<input matStartDate placeholder="Fecha Inicio" [(ngModel)]="filtros['FechaConcertacioninicial']">
					<input matEndDate placeholder="Fecha fin" [(ngModel)]="filtros['FechaConcertacionfinal']" (dateChange)="onDateFechaConcertacion()">
				  </mat-date-range-input>
				  <button mat-button *ngIf="filtros['FechaConcertacioninicial']" matSuffix mat-icon-button aria-label="Clear" (click)="filtros['FechaConcertacioninicial']=''; filtros['FechaConcertacionfinal']=''; getconcertadas();">
				    <mat-icon>close</mat-icon>
				  </button>
				  <mat-date-range-picker #picker5></mat-date-range-picker>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.FechaConcertacion | date:'dd/MM/yyyy'}} </td>
		  </ng-container>
		  <ng-container matColumnDef="fecharegistro">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:120px;font-size:8pt">
				  <mat-label style="color:black;" (click)="picker6.open()"><b>fecharegistro</b></mat-label>
				  <mat-date-range-input [rangePicker]="picker6" (click)="picker6.open()">
					<input matStartDate placeholder="Fecha Inicio" [(ngModel)]="filtros['Fecharegistroinicial']">
					<input matEndDate placeholder="Fecha fin" [(ngModel)]="filtros['Fecharegistrofinal']" (dateChange)="onDateFecharegistro()">
				  </mat-date-range-input>
				  <button mat-button *ngIf="filtros['Fecharegistroinicial']" matSuffix mat-icon-button aria-label="Clear" (click)="filtros['Fecharegistroinicial']=''; filtros['Fecharegistrofinal']=''; getconcertadas();">
				    <mat-icon>close</mat-icon>
				  </button>
				  <mat-date-range-picker #picker6></mat-date-range-picker>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.fecharegistro}} </td>
		  </ng-container>
		  <ng-container matColumnDef="HoraMaximaEntrega">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>Hora Maxima Entrega</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.HoraMaximaEntrega}} </td>
		  </ng-container>
		  <ng-container matColumnDef="HoraMaximaRecogida">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header> 
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>Hora Maxima Recogida</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.HoraMaximaRecogida}} </td>
		  </ng-container>
		  <ng-container matColumnDef="IdFiscal">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>IdFiscal</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.IdFiscal}} </td>
		  </ng-container>
		  <ng-container matColumnDef="AR">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>AR</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.AR}} </td>
		  </ng-container>
		  <ng-container matColumnDef="ImporteAR">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>ImporteAR</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.ImporteAR}} </td>
		  </ng-container>
		  <ng-container matColumnDef="Kilos">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>Kilos</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.Kilos}} </td>
		  </ng-container>
		  <ng-container matColumnDef="KmDestinatario">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>KmDestinatario</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.KmDestinatario}} </td>
		  </ng-container>
		  <ng-container matColumnDef="KmRemitente">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>KmRemitente</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.KmRemitente}} </td>
		  </ng-container>
		  <ng-container matColumnDef="Motivo">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;width:100%;font-size:8pt">
				  <mat-label style="font-size:11pt;color:black;">Motivo</mat-label>
				  <mat-select [(value)]="filtros['Motivo']" (selectionChange)="onselectfiltro('Motivo', $event.value)">
				    <mat-option [value]="motivo" *ngFor="let motivo of listasfiltros.listamotivo">{{motivo.valor}}</mat-option>
				  </mat-select>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{humanizemotivo(element.Motivo)}} </td>
		  </ng-container>
		  <ng-container matColumnDef="NombreCliente">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>Nombre Cliente</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.NombreCliente}} </td>
		  </ng-container>
		  <ng-container matColumnDef="NombreSolicitante">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>Nombre Solicitante</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.NombreSolicitante}} </td>
		  </ng-container>
		  <ng-container matColumnDef="ObservacionesEntrega">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>Observaciones Entrega</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element" style="max-width:200px;"> {{element.ObservacionesEntrega}} </td>
		  </ng-container>
		  <ng-container matColumnDef="ObservacionesRecogida">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>Observaciones Recogida</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.ObservacionesRecogida}} </td>
		  </ng-container>
		  <ng-container matColumnDef="PoblacionDestinatario">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>Poblacion Destinatario</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.PoblacionDestinatario}} </td>
		  </ng-container>
		  <ng-container matColumnDef="PoblacionRemitente">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>Poblacion Remitente</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.PoblacionRemitente}} </td>
		  </ng-container>
		  <ng-container matColumnDef="ProvinciaDestinatario">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>Provincia Destinatario</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.ProvinciaDestinatario}} </td>
		  </ng-container>
		  <ng-container matColumnDef="ProvinciaRemitente">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>Provincia Remitente</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.ProvinciaRemitente}} </td>
		  </ng-container>
		  <ng-container matColumnDef="RangoHorarioEntregaManana">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>Rango Horario Entrega Manana</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.RangoHorarioEntregaManana}} </td>
		  </ng-container>
		  <ng-container matColumnDef="RangoHorarioEntregaTarde">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>Rango Horario Entrega Tarde</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.RangoHorarioEntregaTarde}} </td>
		  </ng-container>
		  <ng-container matColumnDef="RangoHorarioRecogidaManana">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>Rango Horario Recogida Manana</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.RangoHorarioRecogidaManana}} </td>
		  </ng-container>
		  <ng-container matColumnDef="RangoHorarioRecogidaTarde">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>Rango Horario Recogida Tarde</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.RangoHorarioRecogidaTarde}} </td>
		  </ng-container>
		  <ng-container matColumnDef="Remitente">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>Remitente</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.Remitente}} </td>
		  </ng-container>
		  <ng-container matColumnDef="Servicio">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>Servicio</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.Servicio}} </td>
		  </ng-container>
		  <ng-container matColumnDef="TelefonoDestinatario">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>Telefono Destinatario</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.TelefonoDestinatario}} </td>
		  </ng-container>
		  <ng-container matColumnDef="TelefonoRemitente">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>Telefono Remitente</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.TelefonoRemitente}} </td>
		  </ng-container>
		  <ng-container matColumnDef="TipoComision">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>Tipo Comision</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.TipoComision}} </td>
		  </ng-container>
		  <ng-container matColumnDef="TipoEntrega">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>Tipo Entrega</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.TipoEntrega}} </td>
		  </ng-container>
		  <ng-container matColumnDef="TipoGeografico">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>Tipo Geografico</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.TipoGeografico}} </td>
		  </ng-container>
		  <ng-container matColumnDef="TipoMercancia">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>Tipo Mercancia</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.TipoMercancia}} </td>
		  </ng-container>
		  <ng-container matColumnDef="TipoRecogida">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>Tipo Recogida</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.TipoRecogida}} </td>
		  </ng-container>
		  <ng-container matColumnDef="TotalBultos">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>Total Bultos</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.TotalBultos}} </td>
		  </ng-container>
		  <ng-container matColumnDef="TipoCobro">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>Tipo Cobro</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.TipoCobro}} </td>
		  </ng-container>
		  <ng-container matColumnDef="FranquiciaDestino">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>Franquicia Destino</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.FranquiciaDestino}} </td>
		  </ng-container>
		  <ng-container matColumnDef="FranquiciaOrigen">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>Franquicia Origen</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.FranquiciaOrigen}} </td>
		  </ng-container>
		  <ng-container matColumnDef="FranquiciaTercera">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>Franquicia Tercera</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.FranquiciaTercera}} </td>
		  </ng-container>
		  <ng-container matColumnDef="FranquiciaClienteFacturacion">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>Franquicia Cliente Facturacion</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.FranquiciaClienteFacturacion}} </td>
		  </ng-container>
		  <ng-container matColumnDef="CodigoCupon">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>Codigo Cupon</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.CodigoCupon}} </td>
		  </ng-container>
		  <ng-container matColumnDef="NumeroAlbaranPrecursor">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>Numero Albaran Precursor</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.NumeroAlbaranPrecursor}} </td>
		  </ng-container>
		  <ng-container matColumnDef="NumeroEnvioRetorno">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>Numero Envio Retorno</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.NumeroEnvioRetorno}} </td>
		  </ng-container>
		  <ng-container matColumnDef="TipoGestion">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>Tipo Gestion</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.TipoGestion}} </td>
		  </ng-container>
		  <ng-container matColumnDef="TieneRetorno">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>Tiene Retorno</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.TieneRetorno}} </td>
		  </ng-container>
		  <ng-container matColumnDef="ConfirmacionInmediata">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>Confirmacion Inmediata</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.ConfirmacionInmediata}} </td>
		  </ng-container>
		  <ng-container matColumnDef="EntregaSabado">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>Entrega Sabado</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.EntregaSabado}} </td>
		  </ng-container>
		  <ng-container matColumnDef="TipoRetorno">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>Tipo Retorno</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.TipoRetorno}} </td>
		  </ng-container>
		  <ng-container matColumnDef="Usuario">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>Usuario</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.Usuario}} </td>
		  </ng-container>
		  <ng-container matColumnDef="UsuarioModificacion">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>Usuario Modificacion</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.UsuarioModificacion}} </td>
		  </ng-container>
		  <ng-container matColumnDef="ReferenciaCliente">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>Referencia Cliente</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.ReferenciaCliente}} </td>
		  </ng-container>
		  <ng-container matColumnDef="EsInternacional">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>EsInternacional</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.EsInternacional}} </td>
		  </ng-container>
		  <ng-container matColumnDef="GeneradoPor">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>Generado Por</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.GeneradoPor}} </td>
		  </ng-container>
		  <ng-container matColumnDef="Aviso">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>Aviso</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.Aviso}} </td>
		  </ng-container>
		  <ng-container matColumnDef="TotalMedidas">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>Total Medidas</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.TotalMedidas}} </td>
		  </ng-container>
		  <ng-container matColumnDef="Operacion">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>Operacion</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.Operacion}} </td>
		  </ng-container>
		  <ng-container matColumnDef="Frecuencia">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>Frecuencia</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.Frecuencia}} </td>
		  </ng-container>
		  <ng-container matColumnDef="Contador">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>Contador</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.Contador}} </td>
		  </ng-container>
		  <ng-container matColumnDef="NifRemitente">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>NifRemitente</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.NifRemitente}} </td>
		  </ng-container>
		  <ng-container matColumnDef="NifDestinatario">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>NifDestinatario</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.NifDestinatario}} </td>
		  </ng-container>
		  <ng-container matColumnDef="MensajeroEntrega">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>MensajeroEntrega</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.MensajeroEntrega}} </td>
		  </ng-container>
		  <ng-container matColumnDef="MensajeroRecogida">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>MensajeroRecogida</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.MensajeroRecogida}} </td>
		  </ng-container>
		  <ng-container matColumnDef="PuntoRecogida">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>PuntoRecogida</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.PuntoRecogida}} </td>
		  </ng-container>
		  <ng-container matColumnDef="PuntoEntrega">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>PuntoEntrega</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.PuntoEntrega}} </td>
		  </ng-container>
		  <ng-container matColumnDef="PuntoAnomalia">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>PuntoAnomalia</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.PuntoAnomalia}} </td>
		  </ng-container>
		  <ng-container matColumnDef="PuntoEstado">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>PuntoEstado</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.PuntoEstado}} </td>
		  </ng-container>
		  <ng-container matColumnDef="PuntoConcertacion">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>PuntoConcertacion</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.PuntoConcertacion}} </td>
		  </ng-container>
		  <ng-container matColumnDef="TipoPuntoConcertacion">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>Tipo Punto Concertacion</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.TipoPuntoConcertacion}} </td>
		  </ng-container>
		  <ng-container matColumnDef="usuario">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>Usuario Oficina</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.usuario}} </td>
		  </ng-container>
		  <ng-container matColumnDef="fechaconcertada">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;width:100%;font-size:10pt">
				  <mat-label style="color:black;" (click)="picker7.open()"><b>Fecha Entrega concertada</b></mat-label>
					<input matInput [matDatepicker]="picker7" (click)="picker7.open()" placeholder="fechaconcertada" [(ngModel)]="filtros['FechaEntregaConcertada']" (dateChange)="onDateFechaentregaconcertada()">
				  <button mat-button *ngIf="filtros['FechaEntregaConcertada']" matSuffix mat-icon-button aria-label="Clear" (click)="filtros['FechaEntregaConcertada']=''; getconcertadas()"> 
				    <mat-icon>close</mat-icon>
				  </button>
				  <mat-datepicker #picker7></mat-datepicker>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.fechaconcertada}} </td>
		  </ng-container>
		  <ng-container matColumnDef="texto">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>Texto</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.texto}} </td>
		  </ng-container>
		  <ng-container matColumnDef="ObservacionesEstado">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>Ubicacion</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.ObservacionesEstado}} </td>
		  </ng-container>
		  <ng-container matColumnDef="horarioconcertado">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>Horario Concertada</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.horarioconcertado}} </td>
		  </ng-container>
		  <ng-container matColumnDef="impresa">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>Impresa</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.impresa}} </td>
		  </ng-container>
		  <ng-container matColumnDef="usuarioimpresion">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>Usuario Impresion</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.usuarioimpresion}} </td>
		  </ng-container>
		  <ng-container matColumnDef="obconcertada">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>Observaciones Concertacion</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.obconcertada}} </td>
		  </ng-container>
		  <ng-container matColumnDef="fechaimpresion">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:120px;font-size:8pt">
				  <mat-label style="color:black;" (click)="picker8.open()"><b>fechaimpresion</b></mat-label>
				  <mat-date-range-input [rangePicker]="picker8" (click)="picker8.open()">
					<input matStartDate placeholder="Fecha Inicio" [(ngModel)]="filtros['fechaimpresioninicial']">
					<input matEndDate placeholder="Fecha fin" [(ngModel)]="filtros['fechaimpresionfinal']" (dateChange)="onDateFechaimpresion()">
				  </mat-date-range-input>
				  <button mat-button *ngIf="filtros['fechaimpresioninicial']" matSuffix mat-icon-button aria-label="Clear" (click)="filtros['fechaimpresioninicial']=''; filtros['fechaimpresionfinal']=''; getconcertadas();">
				    <mat-icon>close</mat-icon>
				  </button>
				  <mat-date-range-picker #picker8></mat-date-range-picker>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.fechaimpresion}} </td>
		  </ng-container>

		  <tr mat-header-row *matHeaderRowDef="identity.options.ordencamposconcertadas; sticky:true"></tr>
		  <tr mat-row (dblclick)="abrealbaran(row)" (click)="seleccionar(row)" class="celdas" [class.seleccionada]="seleccion == row" *matRowDef="let row; columns: identity.options.ordencamposconcertadas;">
		  </tr>
		</table>
	</div>
	<div style="display:inline-flex;align-items:center;">
		<span style="font-size:10pt;margin-left:15px;color:black;">Seleccionadas: {{selection.selected.length}}</span>
		<mat-form-field *ngIf="identity" style="width:35px;margin-left:20px;margin-right:50px;">
			<mat-select [(ngModel)]="identity.options.ordencamposconcertadas" (selectionChange)="onselectcolumnas($event.value)" multiple>
			<mat-select-trigger class="fondooscuro">
				<mat-icon>view_list</mat-icon>
			</mat-select-trigger>
			<mat-option *ngFor="let campo of totalcolumns" [value]="campo">{{campo}}</mat-option>
			</mat-select>
		</mat-form-field>
		<mat-paginator style="color:black;" showFirstLastButtons [length]="concertadas? concertadas.length : 0" [pageSize]="identity.options.limit" [pageSizeOptions]="[5, 10, 25, 50, 100]">
		</mat-paginator>
	</div>
	<div class="pieincidencias" *ngIf='concertadas'>
	<div class="datosincidencia">
		<div class="datodentroincidencia" *ngIf="seleccion">
			<ul style="list-style: none">
				<li>KM.Recogida: <span [class.kmup]="seleccion.KmRemitente > 15">{{seleccion.KmRemitente}}</span></li>
				<li>KM.Entrega: <span [class.kmup]="seleccion.KmDestinatario > 15">{{seleccion.KmDestinatario}}</span></li>
				<li>Referencia: {{seleccion.ReferenciaCliente}}</li>
				<li>Bultos: {{seleccion.TotalBultos}}</li>				
				<li>Generado: {{seleccion.GeneradoPor}} {{seleccion.FechaEnvio}}</li>
				<li>Usuario: {{seleccion.Usuario}}</li>
				<li *ngIf="seleccion.UsuarioModificacion">Modificado: {{seleccion.UsuarioModificacion}}</li>
			</ul>	
		</div>
	</div>
	<div class="datosincidencia">
		<div class="datodentroincidencia" *ngIf="seleccion">
			<ul style="list-style: none">
				<li *ngIf="seleccion.NumeroEnvioRetorno">Retorno: {{seleccion.NumeroEnvioRetorno}}</li>
				<li *ngIf="seleccion.NumeroAlbaranPrecursor">Precursor: {{seleccion.NumeroAlbaranPrecursor}}</li>
				<li>Servicio: {{seleccion.Servicio}}</li>
				<li *ngIf="seleccion.ImporteAR">Adelanto/Reembolso: {{selecionra}} {{seleccion.ImporteAR}}€</li>
				<li>Kilos: {{seleccion.Kilos}}</li>
				<li>Bultos: {{seleccion.TotalBultos}}</li>
				<li>Medidas: {{seleccion.TotalMedidas}}</li>
				<li *ngIf="seleccion.FechaConcertacion">Concertada: {{seleccion.FechaConcertacion | date:'dd/MM/yyyy'}}</li>
			</ul>	
		</div>
	</div>
	<div class="datosexpediente">
		<div class="datodentroincidencia" *ngIf="seleccion">
			<table mat-table [dataSource]="listaexpediente">
				 <ng-container matColumnDef="Estado">
				    <th mat-header-cell *matHeaderCellDef  style="color:black;text-align:center;padding:1px;"> Estado </th>
				    <td mat-cell *matCellDef="let element"> {{element.Estado}} </td>
				  </ng-container>
				  <ng-container matColumnDef="FechaEntrada">
				    <th mat-header-cell *matHeaderCellDef style="color:black;text-align:center;padding:1px;"> FechaEntrada </th>
				    <td mat-cell *matCellDef="let element"> {{element.FechaEntrada}} </td>
				  </ng-container>
				  <ng-container matColumnDef="HoraEntrada">
				    <th mat-header-cell *matHeaderCellDef style="color:black;text-align:center;padding:1px;"> HoraEntrada </th>
				    <td mat-cell *matCellDef="let element"> {{element.HoraEntrada}} </td>
				  </ng-container>
				  <ng-container matColumnDef="Descripcion">
				    <th mat-header-cell *matHeaderCellDef style="color:black;text-align:center;padding:1px;"> Descripcion </th>
				    <td mat-cell *matCellDef="let element"> {{element.Descripcion}} </td>
				  </ng-container>
				  <ng-container matColumnDef="Detalle">
				    <th mat-header-cell *matHeaderCellDef style="color:black;text-align:center;padding:1px;"> Detalle </th>
				    <td mat-cell *matCellDef="let element"> {{element.Detalle}} </td>
				  </ng-container>
				  <ng-container matColumnDef="Usuario">
				    <th mat-header-cell *matHeaderCellDef style="color:black;text-align:center;padding:1px;"> Usuario </th>
				    <td mat-cell *matCellDef="let element"> {{element.Usuario}} </td>
				  </ng-container>	
				 <tr mat-header-row *matHeaderRowDef="columnasexpediente; sticky:true" style="padding:1px;"></tr>
  				 <tr mat-row *matRowDef="let fila; columns: columnasexpediente;" class="fondooscuro" [class.seleccionada]="seleccionexp == fila.Id" (dblclick)="detalleexpediente(fila)"></tr>
			</table>
		</div>
	</div>
	<div class="datosincidencia" style="width:25%">
		<p style="text-align: center;">Origen</p>
		<div class="datodentroincidencia" *ngIf="seleccion">
			<ul style="list-style: none">
				<li>Agencia: {{seleccion.FranquiciaOrigen}}</li>
				<li>Nombre: {{seleccion.Remitente}}</li>
				<li>Direccion: {{seleccion.DireccionRemitente}}</li>
				<li>Poblacion: {{seleccion.PoblacionRemitente}}</li>
				<li>CP: {{seleccion.CpRemitente}}</li>
				<li>Telefono: {{seleccion.TelefonoRemitente}}</li>
			</ul>	
		</div>
	</div>
	<div class="datosincidencia">
		<p style="text-align: center;">Destino</p>
		<div class="datodentroincidencia" *ngIf="seleccion">
			<ul style="list-style: none">
				<li>Agencia: {{seleccion.FranquiciaDestino}}</li>
				<li>Nombre: {{seleccion.Destinatario}}</li>
				<li>Direccion: {{seleccion.DireccionDestinatario}}</li>
				<li>Poblacion: {{seleccion.PoblacionDestinatario}}</li>
				<li>CP: {{seleccion.CpDestinatario}}</li>
				<li>Telefono: {{seleccion.TelefonoDestinatario}}</li>
			</ul>	
		</div>
	</div>
</div>
