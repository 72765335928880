import { OnInit, Component, ViewChild, AfterViewInit} from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { ClienteService } from '../../servicios/cliente.service';
import { DeudasService } from '../../servicios/deudas.service';
import { MatTableDataSource } from '@angular/material/table';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition, MatSnackBarConfig } from '@angular/material/snack-bar'; 
import { Cliente } from '../../models/cliente';
import * as moment from 'moment';

@Component({
  selector: 'app-gestiondeudas',
  templateUrl: './gestiondeudas.component.html',
  styleUrls: ['./gestiondeudas.component.css'],
  providers: [ClienteService, DeudasService]
})
export class GestiondeudasComponent implements OnInit, AfterViewInit{

  public identity;
  public seleccion;
  public mensajeros;
  public category;
  public dataSource;
  public totalcolumns;
  public filtros;
  public deudas;
  public totalimporte;
  private paginator: MatPaginator;
  private sort: MatSort;
  @ViewChild(MatSort, {static: false}) set content(sort: MatSort){this.sort = sort;}
  @ViewChild(MatPaginator, {static: false}) set matPaginator(mp: MatPaginator) {this.paginator = mp;}
  
  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _clienteService: ClienteService,
    private _deudasService: DeudasService,
    private _snackBar: MatSnackBar

    ) {
    this.filtros = {};
    this.totalimporte = 0;
    this.totalcolumns = ['nombre','numero','importe'];
    this.identity = JSON.parse(sessionStorage.getItem('identity'));
    this.dataSource = new MatTableDataSource<Cliente>();

    this._clienteService.getmensajeros().subscribe(
        response=>{
          this.mensajeros = response.mensajeros;
          this.getdeudaspendientes();
         },
      error =>{
        console.log(<any>error);
    });      

  }

  ngOnInit(): void {
  }


  ngAfterViewInit(){
     this.dataSource.paginator = this.paginator
     this.dataSource.sort = this.sort;
  }

  aviso(texto, subtexto) {
    this._snackBar.open(texto,subtexto,{
      duration: 4000,
      horizontalPosition: "center",
      verticalPosition: "top",
    });
  }

  actualizacliente(){
    sessionStorage.setItem('identity', JSON.stringify(this.identity));
    this._clienteService.saveCliente(this.identity);
  }

  seleccionar(row){
    this.seleccion = row;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
 
  categoriaseleccionada(categoria){
    this.category = categoria;
    if(this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }  
  }

  enrutar(ruta){
    this._router.navigate([ruta]);
  }

  getdeudaspendientes(){
    var query = {$or:[{cobrada:false},{cobrada:{$exists:false}}]};
    this._deudasService.getdeudas(query).subscribe(
      response=>{
          if(response.deudas){
            this.deudas = response.deudas;
            this.dataSource.data = [];
            this.dataSource.data = this.mensajeros.map((mensajero, index)=>{
              mensajero['totalimporte'] = 0;
              this.deudas.filter(deuda => deuda.mensajero == mensajero.numero).map((debe)=>{
                mensajero.totalimporte = mensajero.totalimporte + debe.importe;
                this.totalimporte = this.totalimporte + debe.importe;
              });  
              return mensajero;
            });
          }  
        },
      error=>{
        console.log(<any>error);
      }  
    );
  }



}