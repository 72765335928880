<div class="contenidoincidencias">
	<div class="tablafacturas">
        <div class="barraprincipalincidencias">
            <div class="cajaherramientasincidencias">
                <button mat-button mat-icon-button matTooltip="Clientes Activos" (click)="getclientesactivos()">
                    <mat-icon>account_circle</mat-icon>
                </button>
                <div style="font-size:8pt;">
                    Activos
                </div>
            </div>
            <div class="cajaherramientasincidencias">
                <button mat-button mat-icon-button matTooltip="Clientes Suspendidos" (click)="getclientesSuspendidos()">
                    <mat-icon>no_accounts</mat-icon>
                </button>
                <div style="font-size:8pt;">
                    Suspendidos
                </div>
            </div>
            <div class="cajaherramientasincidencias">
                <button mat-button mat-icon-button matTooltip="Todos Los Clientes" (click)="todoslosclientes()">
                    <mat-icon>supervised_user_circle</mat-icon>
                </button>
                <div style="font-size:8pt;">
                    Todos
                </div>
            </div>       
            <div class="cajaherramientasincidencias" style="margin-left: 30px;">
                <mat-form-field appearance="outline">
                    <mat-label style="font-size:11pt;color:black;">Buscar</mat-label>
                    <input matInput (keyup)="applyFilter($event)" placeholder="buscar.." [(ngModel)]="filtro">
                    <button mat-button *ngIf="filtro" matSuffix mat-icon-button aria-label="Clear" (click)="dataSource.filter = ''; filtro=''">
                        <mat-icon>close</mat-icon>
                    </button>
                </mat-form-field>
            </div>
        </div>    
		<div class="facturas">
            <mat-progress-bar *ngIf="!load" mode="indeterminate"></mat-progress-bar>
			<table mat-table *ngIf="dataSource && identity" [dataSource]="dataSource" matSort style="width:100%">
                <ng-container *ngFor="let column of columns" [matColumnDef]="column.columnDef">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                      {{column.header}}
                    </th>
                    <td mat-cell *matCellDef="let element">
                      {{element[column.columnDef]}} <span *ngIf="element[column.columnDef] == 'Totalano'"> €</span>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="disclaimer">
                      <td mat-footer-cell *matFooterCellDef colspan="3">
                        {{dataSource.data.length}} Clientes
                      </td>
                  </ng-container>
                  <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="seleccionar(row)" (dblclick)="abreabonadog3(row.datosAbonado.Id)" class="celdas" [class.seleccionada]="clienteseleccionado.Abonado == row.Abonado"></tr>
                  <tr mat-footer-row *matFooterRowDef="['disclaimer'];sticky:true"></tr>
			</table>
		</div>
	</div>
	<div *ngIf="clienteseleccionado" class="datosabonado">
        <div class="infoabonado">
            <datoscliente [cliente]="clientplataforma" [abonado]="clienteseleccionado"></datoscliente>
            <!--<mat-tab-group>
                <mat-tab>
                <ng-template mat-tab-label>
                    <mat-icon class="example-tab-icon">account_circle</mat-icon>
                    Abonado
                </ng-template>
                    <div class="lineaabonado">
                        <mat-form-field style="width:10%;font-size:9pt;color:black;">
                            <mat-label>Abonado</mat-label>
                            <input style="color:black;" matInput [value]="clienteseleccionado.Abonado" disabled>
                        </mat-form-field>
                        <mat-form-field style="width:60%;font-size:9pt;color:black;">
                            <mat-label>Nombre</mat-label>
                            <input style="color:black;" matInput [value]="clienteseleccionado.Descripcion" disabled>
                        </mat-form-field>
                        <mat-form-field style="width:15%;font-size:9pt;color:black;margin-left:10px;">
                            <mat-label>Nif</mat-label>
                            <input style="color:black;" matInput [value]="clienteseleccionado.datosAbonado.IdFiscal" disabled>
                        </mat-form-field>
                    </div>
                    <div class="lineaabonado">
                        <mat-form-field style="width:45%;font-size:12pt;color:black;">
                            <mat-label>Email</mat-label>
                            <input style="color:black;" matInput [(ngModel)]="clienteseleccionado['emailfacturacion']">
                            <button *ngIf="emailclienteseleccionado != clienteseleccionado['emailfacturacion']" mat-icon-button matSuffix>
                                <mat-icon>save</mat-icon>
                            </button>
                        </mat-form-field>
                        <mat-form-field style="width:15%;font-size:12pt;color:black;margin-left:10px;">
                            <mat-label>Telefono</mat-label>
                            <input style="color:black;" matInput [value]="clienteseleccionado.datosAbonado.Telefono" disabled>
                        </mat-form-field>
                        <mat-form-field style="width:20%;font-size:12pt;color:black;margin-left:10px;">
                            <mat-label>Contacto</mat-label>
                            <input style="color:black;" matInput [(ngModel)]="clienteseleccionado['contactofacturacion']">
                            <button *ngIf="contactoclienteseleccionado != clienteseleccionado['contactofacturacion']" mat-icon-button matSuffix>
                                <mat-icon>save</mat-icon>
                            </button>
                        </mat-form-field>
                    </div>
                    <div class="lineaabonado">
                        <mat-form-field style="max-height:100px;width:100%;font-size:9pt;">
                            <mat-label>Notas Privadas Abonado</mat-label>
                            <textarea matInput [value]="clienteseleccionado.datosAbonadoEdit.InformacionPrivada" rows="3"></textarea>
                        </mat-form-field>
                        <mat-form-field style="max-height:100px;width:100%;font-size:9pt;">
                            <mat-label>Notas Abonado</mat-label>
                            <textarea matInput [(ngModel)]="clienteseleccionado['Notas']" rows="3" placeholder="Notas"></textarea>
                        </mat-form-field>
                        <button mat-button *ngIf="clienteseleccionado['Notas']" mat-icon-button>
                        <mat-icon>save</mat-icon>
                        </button>
                    </div>
                    <div class="lineaabonado">
                        <ul style="list-style:none;" *ngFor="let direccion of clienteseleccionado.direcciones">
                            <li *ngIf="direccion.Predeterminada">
                                {{direccion.Direccion}}
                            </li>
                        </ul>
                    </div>
                </mat-tab>
            
                <mat-tab>
                    <ng-template mat-tab-label>
                      <mat-icon style="padding-right:8px;">contact_mail</mat-icon>
                    Datos Cliente
                  </ng-template>
                  <div *ngIf="clientplataforma" style="position:relative;">
                      <h2 class="centrado">Datos del cliente: {{clientplataforma.numero}}</h2>
                      <div style="position:absolute;top:5px;right:5px;">
                           <button class="btn btn-success" (click)="enrutar('cambiar-password/' + clientplataforma._id)">Cambiar Contraseña</button>
                      </div>
                      <form #editForm="ngForm" (ngSubmit)="onSubmit(editForm)">
                          <div class="formulario">
                              
                              <div class="datosprincipales">
                                  <p>
                                      <label>Nombre del Usuario</label>
                                      <input type="text" name="Nombre" #nombre="ngModel" [(ngModel)]="clientplataforma.nombre" class="form-control" required />
          
                                  </p>
                                  <p>
                                      <label>Direccion</label>
                                      <input type="text" name="direccion" #direccion="ngModel" [(ngModel)]="clientplataforma.direccion" class="form-control" required />
                                  </p>
                                  <p>
                                      <label>Codigo Postal</label>
                                      <input type="text" name="cp" #cp="ngModel" [(ngModel)]="clientplataforma.cp" class="form-control" required />
                                  </p>
                                  <p>
                                      <label>Poblacion</label>
                                      <input type="text" name="poblacion" #poblacion="ngModel" [(ngModel)]="clientplataforma.poblacion" class="form-control" required />
                                  </p>
                              </div>
                              <div class="datoscontacto">	
                                  <p>
                                      <label>Nif</label>
                                      <input type="text" name="nif" #poblacion="ngModel" [(ngModel)]="clientplataforma.nif" class="form-control"/>
                                  </p>
                                  <p>
                                      <label>Telefono</label>
                                      <input type="text" name="telefono" #telefono="ngModel" [(ngModel)]="clientplataforma.telefono" class="form-control" required />
                                  </p>
                                  <p>
                                      <label>Email</label>
                                      <input type="text" name="emailsIncidencias" #emailsIncidencias="ngModel" [(ngModel)]="clientplataforma.emailsIncidencias" class="form-control" required pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"/>
                                  </p>
                                  <p *ngIf="clientplataforma && identity.role == 'Administrador' && clientplataforma.role !='cliente'">
                                      <label>Rol</label>
                                      <select name="rol" [(ngModel)]="clientplataforma.role" class="form-control" required>
                                          <option [value]="item" *ngFor="let item of listaroles">{{item}}</option>
                                      </select>
                                  </p>
                              </div>	
                              <div class="clearfix"></div>
                              <div class="alert alert-danger centrado" *ngIf="status == 'error'">
                                  error al actualizar los datos
                              </div>
                              <div class="alert alert-success centrado" *ngIf="status == 'success'">
                                  datos modificados correctamente
                              </div>		
                              <div class="obser" *ngIf="identity.role == 'Administrador'">
                                  <div class="butverarchivos">
                                      <input class="btn btn-info" value="ver archivos del cliente" (click)="verarchivoscliente()">
                                  </div>
                                  <div class="butverfacturas">
                                      <input class="btn btn-info" value="ver facturas del cliente" (click)="verfacturascliente()">
                                  </div>
                                  <div class="borracliente">
                                      <input class="btn btn-danger" type ="button" value="borrar cliente" href="#victorModal" data-toggle="modal"/>
                                  </div>
                                  <div class="clearfix"></div>
                                  <div class="cuadroobservaciones">
                                      <label>observaciones</label>
                                      <input type="text" name="observaciones" #observaciones="ngModel" [(ngModel)]="cliente.observaciones" class="form-control" />
                                  </div>
                                  
                              </div>
                                                  
          
                          <div class="centrado">
                          <input type="submit" value="Enviar Datos Modificados" class="btn btn-success" [disabled]="!editForm.form.valid" />
                          </div>
                      </div>
                      </form>
                      
                  </div>
                </mat-tab>
                <mat-tab>
                <ng-template mat-tab-label>
                    <mat-icon class="example-tab-icon">thumb_up</mat-icon>
                    Datos Facturacion
                </ng-template>
            
                Content 3
                </mat-tab>
            </mat-tab-group>
        </div>-->
        <div class="barrafacturascliente">
            <mat-tab-group>
                <mat-tab>
                    <ng-template mat-tab-label>
                        <mat-icon class="example-tab-icon">monetization_on</mat-icon>
                        Importes
                    </ng-template>
                    <div *ngIf="clienteseleccionado && facturasclienteseleccionado" class="tblfacturascliente">
                        <ul *ngFor="let ano of facturasclienteseleccionado.anos" style="list-style: none;">
                            <li>
                                <ul class="horizontallist">
                                    <li style="width:50px;">
                                        {{ano}}
                                    </li>
                                    <li>
                                        <div>
                                            Enero         
                                        </div>
                                        <div [ngClass]="importemes('01', ano) > importemes('01', ano-1) ? 'mayor' : 'menor'">
                                            {{(importemes('01',ano)).toFixed(2)}} €
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            Febrero         
                                        </div>
                                        <div [ngClass]="importemes('02', ano) > importemes('02', ano-1) ? 'mayor' : 'menor'">
                                            {{(importemes('02',ano)).toFixed(2)}} €
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            Marzo         
                                        </div>
                                        <div [ngClass]="importemes('03', ano) > importemes('03', ano-1) ? 'mayor' : 'menor'">
                                            {{(importemes('03',ano)).toFixed(2)}} €
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            Abril         
                                        </div>
                                        <div [ngClass]="importemes('04', ano) > importemes('04', ano-1) ? 'mayor' : 'menor'">
                                            {{(importemes('04',ano)).toFixed(2)}} €
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            Mayo         
                                        </div>
                                        <div [ngClass]="importemes('05', ano) > importemes('05', ano-1) ? 'mayor' : 'menor'">
                                            {{(importemes('05',ano)).toFixed(2)}} €
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            Junio         
                                        </div>
                                        <div [ngClass]="importemes('06', ano) > importemes('06', ano-1) ? 'mayor' : 'menor'">
                                            {{(importemes('06',ano)).toFixed(2)}} €
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            Julio         
                                        </div>
                                        <div [ngClass]="importemes('07', ano) > importemes('07', ano-1) ? 'mayor' : 'menor'">
                                            {{(importemes('07',ano)).toFixed(2)}} €
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            Agosto         
                                        </div>
                                        <div [ngClass]="importemes('08', ano) > importemes('08', ano-1) ? 'mayor' : 'menor'">
                                            {{(importemes('08',ano)).toFixed(2)}} €
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            Septiembre         
                                        </div>
                                        <div [ngClass]="importemes('09', ano) > importemes('09', ano-1) ? 'mayor' : 'menor'">
                                            {{(importemes('09',ano)).toFixed(2)}} €
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            Octubre         
                                        </div>
                                        <div [ngClass]="importemes('10', ano) > importemes('10', ano-1) ? 'mayor' : 'menor'">
                                            {{(importemes('10',ano)).toFixed(2)}} €
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            Noviembre         
                                        </div>
                                        <div [ngClass]="importemes('11', ano) > importemes('11', ano-1) ? 'mayor' : 'menor'">
                                            {{(importemes('11',ano)).toFixed(2)}} €
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            Diciembre         
                                        </div>
                                        <div [ngClass]="importemes('12', ano) > importemes('12', ano-1) ? 'mayor' : 'menor'">
                                            {{(importemes('12',ano)).toFixed(2)}} €
                                        </div>
                                    </li>
                                    <li style="width:120px;">
                                        <div>
                                            Total         
                                        </div>
                                        <div [ngClass]="importeano(ano) > importeano(ano-1) ? 'mayor' : 'menor'">
                                            {{(importeano(ano)).toFixed(2)}} €
                                        </div>
                                    </li>
                                </ul>
                            </li>
                        </ul>                         
                    </div> 
                </mat-tab>
                <mat-tab>
                    <ng-template mat-tab-label>
                        <mat-icon class="example-tab-icon">local_shipping</mat-icon>
                        Envios
                    </ng-template>
                    <mat-progress-bar *ngIf="!load2" mode="indeterminate"></mat-progress-bar>
                    <div *ngIf="clienteseleccionado && enviosclienteseleccionado" class="barrafacturascliente">
                        <ul *ngFor="let ano of enviosclienteseleccionado.anos" style="list-style: none;">
                            <li>
                                <ul class="horizontallist">
                                    <li style="width:50px;">
                                        {{ano}}
                                    </li>
                                    <li>
                                        <div>
                                            Enero         
                                        </div>
                                        <div [ngClass]="enviosmes('01', ano) > enviosmes('01', ano-1) ? 'mayor' : 'menor'">
                                            {{enviosmes('01',ano)}}
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            Febrero         
                                        </div>
                                        <div [ngClass]="enviosmes('02', ano) > enviosmes('02', ano-1) ? 'mayor' : 'menor'">
                                            {{enviosmes('02',ano)}}
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            Marzo         
                                        </div>
                                        <div [ngClass]="enviosmes('03', ano) > enviosmes('03', ano-1) ? 'mayor' : 'menor'">
                                            {{enviosmes('03',ano)}}
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            Abril         
                                        </div>
                                        <div [ngClass]="enviosmes('04', ano) > enviosmes('04', ano-1) ? 'mayor' : 'menor'">
                                            {{enviosmes('04',ano)}}
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            Mayo         
                                        </div>
                                        <div [ngClass]="enviosmes('05', ano) > enviosmes('05', ano-1) ? 'mayor' : 'menor'">
                                            {{enviosmes('05',ano)}}
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            Junio         
                                        </div>
                                        <div [ngClass]="enviosmes('06', ano) > enviosmes('06', ano-1) ? 'mayor' : 'menor'">
                                            {{enviosmes('06',ano)}}
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            Julio         
                                        </div>
                                        <div [ngClass]="enviosmes('07', ano) > enviosmes('07', ano-1) ? 'mayor' : 'menor'">
                                            {{enviosmes('07',ano)}}
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            Agosto         
                                        </div>
                                        <div [ngClass]="enviosmes('08', ano) > enviosmes('08', ano-1) ? 'mayor' : 'menor'">
                                            {{enviosmes('08',ano)}}
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            Septiembre         
                                        </div>
                                        <div [ngClass]="enviosmes('09', ano) > enviosmes('09', ano-1) ? 'mayor' : 'menor'">
                                            {{enviosmes('09',ano)}}
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            Octubre         
                                        </div>
                                        <div [ngClass]="enviosmes('10', ano) > enviosmes('10', ano-1) ? 'mayor' : 'menor'">
                                            {{enviosmes('10',ano)}}
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            Noviembre         
                                        </div>
                                        <div [ngClass]="enviosmes('11', ano) > enviosmes('11', ano-1) ? 'mayor' : 'menor'">
                                            {{enviosmes('11',ano)}}
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            Diciembre         
                                        </div>
                                        <div [ngClass]="enviosmes('12', ano) > enviosmes('12', ano-1) ? 'mayor' : 'menor'">
                                            {{enviosmes('12',ano)}}
                                        </div>
                                    </li>
                                    <li style="width:120px;">
                                        <div>
                                            Total         
                                        </div>
                                        <div [ngClass]="importeano(ano) > importeano(ano-1) ? 'mayor' : 'menor'">
                                            {{enviosano(ano)}}
                                        </div>
                                    </li>
                                </ul>
                            </li>
                        </ul>                         
                    </div> 
                </mat-tab>
            </mat-tab-group>    
        </div>     
</div>	