export class Linea{
    constructor(
        public nombre: string,
        public direccion: string,
        public poblacion: string,
        public cp: string,
        public provincia: string,
        public telefono: string,
        public contacto: string,
        public referencia: string,
        public observaciones: string,
        public cobro: string,
        public sabado: string,
        public servicio:string
    ){}
}	