<button *ngIf="cliente.role == 'cliente' && !ocultaboton && identity.role != 'cliente' && identity.role != 'comercial'" mat-icon-button (click)="enrutar('admintab/Clientes')">
	<mat-icon>keyboard_return</mat-icon>
</button>
<button *ngIf="cliente.role != 'cliente' && !ocultaboton && identity.role != 'cliente' && identity.role != 'comercial'" mat-icon-button (click)="enrutar('gestionusuarios')">
	<mat-icon>keyboard_return</mat-icon>
</button>
<button *ngIf="abonado && cliente.role == 'cliente' && !ocultaboton && identity.role != 'cliente' && identity.role != 'comercial'" mat-icon-button matTooltip="ver en G3" (click)="abreabonadog3()">
	<mat-icon>person_pin</mat-icon>
</button>
<div *ngIf="identity && identity.role != 'cliente'" style="position:relative;">
	<mat-tab-group style="width:100%">
		<mat-tab *ngIf="identity.role == 'Administrador' && abonado">
			<ng-template mat-tab-label>
				<mat-icon style="padding-right:8px;">contact_mail</mat-icon>
			  Datos G3
			</ng-template>
			<div style="width:100%;padding:10px;">
				<div class="lineaabonado">
					<mat-form-field style="width:10%;font-size:9pt;color:black;">
						<mat-label>Abonado</mat-label>
						<input style="color:black;" matInput [value]="abonado.Abonado" disabled>
					</mat-form-field>
					<mat-form-field style="width:60%;font-size:9pt;color:black;">
						<mat-label>Nombre</mat-label>
						<input style="color:black;" matInput [value]="abonado.Descripcion" disabled>
					</mat-form-field>
					<mat-form-field style="width:15%;font-size:9pt;color:black;margin-left:10px;">
						<mat-label>Nif</mat-label>
						<input style="color:black;" matInput [value]="abonado.datosAbonado.IdFiscal" disabled>
					</mat-form-field>
				</div>
				<div class="lineaabonado">
					<mat-form-field style="width:45%;font-size:12pt;color:black;">
						<mat-label>Email</mat-label>
						<input style="color:black;" matInput [(ngModel)]="abonado['emailfacturacion']">
					</mat-form-field>
					<mat-form-field style="width:15%;font-size:12pt;color:black;margin-left:10px;">
						<mat-label>Telefono</mat-label>
						<input style="color:black;" matInput [value]="abonado.datosAbonado.Telefono" disabled>
					</mat-form-field>
					<mat-form-field style="width:20%;font-size:12pt;color:black;margin-left:10px;">
						<mat-label>Contacto</mat-label>
						<input style="color:black;" matInput [(ngModel)]="abonado['contactofacturacion']">
					</mat-form-field>
				</div>
				<div class="lineaabonado">
					<mat-form-field style="max-height:100px;width:100%;font-size:9pt;">
						<mat-label>Notas Privadas Abonado</mat-label>
						<textarea matInput [value]="abonado.datosAbonadoEdit.InformacionPrivada" rows="3"></textarea>
					</mat-form-field>
					<mat-form-field style="max-height:100px;width:100%;font-size:9pt;">
						<mat-label>Notas Abonado</mat-label>
						<textarea matInput [(ngModel)]="abonado['Notas']" rows="3" placeholder="Notas"></textarea>
					</mat-form-field>
					<button mat-button *ngIf="abonado['Notas']" mat-icon-button (click)="actualizaabonado()">
					<mat-icon>save</mat-icon>
					</button>
				</div>
				<div class="lineaabonado">
					<ul style="list-style:none;" *ngFor="let direccion of abonado.direcciones">
						<li *ngIf="direccion.Predeterminada">
							{{direccion.Direccion}}
						</li>
					</ul>
				</div>
			</div>	
		  </mat-tab>
	  <mat-tab>
	  	<ng-template mat-tab-label>
			<mat-icon style="padding-right:8px;">contact_mail</mat-icon>
	      Datos Cliente
	    </ng-template>
	    <div *ngIf="cliente" style="position:relative;">
	    	<h2 class="centrado">Datos del cliente: {{cliente.numero}}</h2>
	    	<div style="position:absolute;top:5px;right:5px;">
			 	<button class="btn btn-success" (click)="enrutar('cambiar-password/' + cliente._id)">Cambiar Contraseña</button>
			</div>
			<form #editForm="ngForm" (ngSubmit)="onSubmit(editForm)">
				<div class="formulario">
					
					<div class="datosprincipales">
						<p>
							<label>Nombre del Usuario</label>
							<input type="text" name="Nombre" #nombre="ngModel" [(ngModel)]="cliente.nombre" class="form-control" required />

						</p>
						<p>
							<label>Direccion</label>
							<input type="text" name="direccion" #direccion="ngModel" [(ngModel)]="cliente.direccion" class="form-control" required />
						</p>
						<p>
							<label>Codigo Postal</label>
							<input type="text" name="cp" #cp="ngModel" [(ngModel)]="cliente.cp" class="form-control" required />
						</p>
						<p>
							<label>Poblacion</label>
							<input type="text" name="poblacion" #poblacion="ngModel" [(ngModel)]="cliente.poblacion" class="form-control" required />
						</p>
					</div>
					<div class="datoscontacto">	
						<p>
							<label>Nif</label>
							<input type="text" name="nif" #poblacion="ngModel" [(ngModel)]="cliente.nif" class="form-control"/>
						</p>
						<p>
							<label>Telefono</label>
							<input type="text" name="telefono" #telefono="ngModel" [(ngModel)]="cliente.telefono" class="form-control" required />
						</p>
						<p>
							<label>Email</label>
							<input type="text" name="emailsIncidencias" #emailsIncidencias="ngModel" [(ngModel)]="cliente.emailsIncidencias" class="form-control" required/>
						</p>
						<p *ngIf="cliente && identity.role == 'Administrador' && cliente.role !='cliente'">
							<label>Rol</label>
							<select name="rol" [(ngModel)]="cliente.role" class="form-control" required>
								<option [value]="item" *ngFor="let item of listaroles">{{item}}</option>
							</select>
						</p>
					</div>	
					<div class="clearfix"></div>
					<div class="alert alert-danger centrado" *ngIf="status == 'error'">
						error al actualizar los datos
					</div>
					<div class="alert alert-success centrado" *ngIf="status == 'success'">
						datos modificados correctamente
					</div>		
					<div class="obser" *ngIf="identity.role == 'Administrador'">
						<div class="butverarchivos">
							<input class="btn btn-info" value="ver archivos del cliente" (click)="verarchivoscliente()">
						</div>
						<div class="butverfacturas">
							<input class="btn btn-info" value="ver facturas del cliente" (click)="verfacturascliente()">
						</div>
						<div class="borracliente">
							<input class="btn btn-danger" type ="button" value="borrar cliente" href="#victorModal" data-toggle="modal"/>
						</div>
						<div class="clearfix"></div>
						<div class="cuadroobservaciones">
							<label>observaciones</label>
							<input type="text" name="observaciones" #observaciones="ngModel" [(ngModel)]="cliente.observaciones" class="form-control" />
						</div>
						
					</div>
										

				<div class="centrado">
				<input type="submit" value="Enviar Datos Modificados" class="btn btn-success" [disabled]="!editForm.form.valid" />
				</div>
			</div>
			</form>
			
		</div>
	  </mat-tab>
	  <mat-tab *ngIf="identity.role == 'Administrador' && cliente && cliente.role != 'cliente' && cliente.role != 'mensajero'">
	  	<ng-template mat-tab-label>
			<mat-icon style="padding-right:8px;">mark_email_read</mat-icon>
	      Configuracion email Usuario
	    </ng-template>
	    <div style="width:100%;display:inline-flex;">
		    <div style="width:20%;margin-top:8px;">
			    <div style="text-align:center;">
					    	Carpetas a mostrar
				</div>
			 	<div class="carpetasEmail" style="height:450px;width:100%;overflow-y:scroll;">
			 			
						<mat-list *ngFor="let folder of carpetasmail">
							<mat-checkbox labelPosition="after" color="primary" [checked]="folder.check" (change)="checkcarpeta($event.checked, folder.id)">
								{{folder.displayName}}
							</mat-checkbox>
						</mat-list>	
				</div>
			</div>
			<div style="width:20%;margin-top:8px;">
			    <div style="text-align:center;">
					    	Carpetas interactuar
				</div>
			 	<div class="carpetasEmail" style="height:450px;width:100%;overflow-y:scroll;">
			 			
						<mat-list *ngFor="let carpet of carpetasmail">
							<mat-checkbox labelPosition="after" color="primary" [checked]="carpet.checkinter" (change)="checkcarpetainter($event.checked, carpet.id)">
								{{carpet.displayName}}
							</mat-checkbox>
						</mat-list>	
				</div>
			</div>
			<div style="width:20%;margin-top:8px;">
				<button mat-raised-button color="primary" (click)="limpiarcarpetas()">Limpiar Carpetas</button>
			</div>		
	 	</div>
	  </mat-tab>
	  <mat-tab *ngIf="identity.role != 'cliente' && cliente && cliente.role != 'cliente' && cliente.role != 'mensajero'">
		<ng-template mat-tab-label>
			<span class="material-icons" style="margin-right:5px;">history_edu</span>
			Firma Email
		</ng-template>
		<div class="contieneeditor">
			<ckeditor [editor]="Editor" [config]="{ 
				toolbar: {
					items: [
						'heading',
						'|',
						'undo',
						'redo',
						'|',
						'bold',
						'italic',
						'fontColor',
						'fontSize',
						'fontFamily',
						'|',
						'alignment',
						'bulletedList',
						'numberedList',
						'highlight',
						'|',
						'imageUpload',
						'insertTable',
						'|',
						'horizontalLine',
					]
				},
				language: 'es',
				image: {
					styles: [
						'full', 'side', 'alignLeft', 'alignCenter', 'alignRight'
					],
					toolbar: [
						'imageStyle:full',
						'imageStyle:side',
						'|',
						'imageStyle:alignLeft',
						'imageStyle:alignCenter',
						'imageStyle:alignRight',
						'|',
						'ImageResize',
						'imageTextAlternative',
					]
				},
				table: {
					contentToolbar: [
						'tableColumn',
						'tableRow',
						'mergeTableCells',
						'tableCellProperties',
						'tableProperties'
					]
				}
			}" [(ngModel)]="cliente.options.firmamail">
			</ckeditor>
		</div>
		<button mat-raised-button color="primary" (click)="guardarcliente()" style="margin-left:45%">Guardar</button>
	  </mat-tab>
	</mat-tab-group>
</div>


<div *ngIf="identity && identity.role == 'cliente'" style="position:relative;">
		<h2 class="centrado">{{title}}</h2>
		<div style="position:absolute;top:5px;right:5px;">
	 		<a [routerLink]="['../../cambiar-password/' + cliente._id]" class="btn btn-success">Cambiar Contraseña</a>	
		</div>
	<form #editForm="ngForm" (ngSubmit)="onSubmit(editForm)">
		<div class="formulario">
			
			<div class="datosprincipales">
				<p>
					<label>Nombre del Abonado</label>
					<input type="text" name="Nombre" #nombre="ngModel" [(ngModel)]="cliente.nombre" class="form-control" required />

				</p>
				<p>
					<label>Direccion</label>
					<input type="text" name="direccion" #direccion="ngModel" [(ngModel)]="cliente.direccion" class="form-control" required />
				</p>
				<p>
					<label>Codigo Postal</label>
					<input type="text" name="cp" #cp="ngModel" [(ngModel)]="cliente.cp" class="form-control" required />
				</p>
				<p>
					<label>Poblacion</label>
					<input type="text" name="poblacion" #poblacion="ngModel" [(ngModel)]="cliente.poblacion" class="form-control" required />
				</p>
			</div>
			<div class="datoscontacto">	
				<p>
					<label>Telefono</label>
					<input type="text" name="telefono" #telefono="ngModel" [(ngModel)]="cliente.telefono" class="form-control" required />
				</p>
				<p>
					<label>Contacto</label>
					<input type="text" name="contacto" #contacto="ngModel" [(ngModel)]="cliente.contacto" class="form-control" required />
				</p>
				<p>
					<label>Email para recibir facturas</label>
					<input type="text" name="emailFacturas" #emailFacturas="ngModel" [(ngModel)]="cliente.emailFacturas" class="form-control" required pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"/>
				</p>
				<p>
					<label>Email para recibir incidencias/notificaciones</label>
					<input type="text" name="emailsIncidencias" #emailsIncidencias="ngModel" [(ngModel)]="cliente.emailsIncidencias" class="form-control" required pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"/>
				</p>
			</div>	
			<div class="clearfix"></div>
					<div class="alert alert-danger centrado" *ngIf="status == 'error'">
						error al actualizar los datos
					</div>
					<div class="alert alert-success centrado" *ngIf="status == 'success'">
						datos modificados correctamente, debe volver a iniciar sesion para que los cambios surtan efecto
					</div>		
		<div class="centrado">
		<input type="submit" value="Enviar Datos Modificados" class="btn btn-success" [disabled]="!editForm.form.valid" />
		</div>
	</div>
	</form>
	
</div>


<div id="victorModal" class="modal fade centramodal">
    <div class="modal-dialog centrado">
        <div class="modal-content centrado">
            <div class="modal-header">
                <h4 class="modal-title">¿Estás seguro?</h4>
            </div>
            <div class="modal-body">
                <p>¿Seguro que quieres borrar este elemento?</p>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-default" data-dismiss="modal">Cerrar</button>
                <button type="button" class="btn btn-danger" (click)="borracliente(cliente._id)" data-dismiss="modal">Eliminar</button>
            </div>
        </div>
    </div>
</div>		