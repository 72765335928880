import { OnInit, Component, ViewChild, AfterViewInit} from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { VehiculoService } from '../../servicios/vehiculos.service';
import { Vehiculo } from '../../models/vehiculo';
import { MatTableDataSource } from '@angular/material/table';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { FormControl } from '@angular/forms';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition, MatSnackBarConfig } from '@angular/material/snack-bar'; 
import * as moment from 'moment';
import { MatDialog } from '@angular/material/dialog';
import { VehiculoComponent } from '../vehiculo/vehiculo.component';
import { GastosComponent } from '../gastos/gastos.component';
import { MatListModule } from '@angular/material/list'; 

@Component({
  selector: 'app-vehiculos',
  templateUrl: './vehiculos.component.html',
  styleUrls: ['./vehiculos.component.css'],
  providers: [VehiculoService]
})
export class VehiculosComponent implements OnInit {
  public seleccion;
  public mensajeros;
  public category;
  public dataSource;
  public totalcolumns;
  public filtros;
  public vista;
   @ViewChild(MatSort, {static: false}) set content(sort: MatSort){this.dataSource.sort = sort;}
   @ViewChild(MatPaginator, {static: false}) set matPaginator(mp: MatPaginator) {this.dataSource.paginator = mp;}

  constructor(
    private _router: Router,
    private _vehiculoService: VehiculoService,
    private _snackBar: MatSnackBar,
    private ventanaNuevovehiculo: MatDialog,
  ) {
  	 this.filtros = {};
     this.vista = 'vehiculos';
     this.totalcolumns = ['matricula','marca','modelo','fechaalta', 'notas'];
     this.dataSource = new MatTableDataSource<Vehiculo>();
  }

  ngOnInit(): void {
    this.getvehiculos();
  }

  ngAfterViewInit(){
     this.matPaginator.page.subscribe((event) => console.log(event));
     this.dataSource.sort = this.content;
  }

  aviso(texto, subtexto) {
    this._snackBar.open(texto,subtexto,{
      duration: 4000,
      horizontalPosition: "center",
      verticalPosition: "top",
    });
  }

  seleccionar(row){
    this.seleccion = row;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
 
  enrutar(ruta){
    this._router.navigate([ruta]);
  }

  togglevista(vista){
    this.vista = vista;
  }

  getvehiculos(){
  	var query = {fechabaja:{$in:[null, '']}};
  	this._vehiculoService.getvehiculos(query).subscribe(
  		response=>{
  			this.dataSource.data = response.vehiculos;
  			},
  		error=>{
  			console.log(<any>error);
  		}	
  	);
  }

  getvehiculosbaja(){
  	var query = {fechabaja:{$nin:[null, '']}};
  	this._vehiculoService.getvehiculos(query).subscribe(
  		response=>{
  			this.dataSource.data = response.vehiculos;
  			},
  		error=>{
  			console.log(<any>error);
  		}	
  	);
  }

  abrenuevovehiculo(){
    var datos = new Vehiculo("","",null,null,"","","",null,"","","",null,null);
    var newvehiculo = this.ventanaNuevovehiculo.open(VehiculoComponent, {
      width: '80%',
      height: '90%',
      data: datos
    });
    newvehiculo.afterClosed().subscribe(result =>{
      if(result == true) this.getvehiculos();
    });  
  }

  abrevehiculo(vehiculo){
    var oldvehiculo = this.ventanaNuevovehiculo.open(VehiculoComponent, {
      width: '80%',
      height: '90%',
      data: vehiculo
    }); 
  }

}
