import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Cliente } from '../../models/cliente';
import { ClienteService } from '../../servicios/cliente.service';

@Component({
  selector: 'passolvidada',
  templateUrl: './passolvidada.component.html',
  styleUrls: ['./passolvidada.component.css'],
  providers: [ClienteService]
})
export class PassolvidadaComponent implements OnInit {
	public title:String;
	public cliente:Cliente;
	public status: String;
	public numero: String;
	public mail: String;
  public mensaje: String;
	
  constructor(
  	private _route: ActivatedRoute,
  	private _router: Router,
    private _clienteService: ClienteService
  ) { 
  	this.title = 'Solicitar Enlace para Cambiar la Contraseña';
    this.status = 'normal';
  }

  ngOnInit(): void {
  }

   onSubmit(){
    this._clienteService.enviamail(this.numero, this.mail).subscribe(
      response =>{
         if(response.message){
           this.status = 'success';
           this.mensaje = response.message;
         }else{
           this.status = 'error';
         }
       },
     error =>{
       console.log(error);
       //this.mensaje = error.error.message;       
     });
   }


}
