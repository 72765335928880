import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs/Observable";
import { AppService } from "./app.service";
import { GLOBAL } from "./global";
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
  MatSnackBarConfig,
} from "@angular/material/snack-bar";

@Injectable()
export class ComparativaService {
  public url: string;

  constructor(public _http: HttpClient, public _appservice: AppService) {
    this.url = GLOBAL.urlcomparativas;
  }

  getentregas(query): Observable<any> {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("authorization", this._appservice.getToken());
    var cuerpo = { query: query };
    var params = JSON.stringify(cuerpo);

    return this._http.post(this.url + "listaentregas", params, {
      headers: headers,
    });
  }

  getfacturados(data): Observable<any> {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("authorization", this._appservice.getToken());
    var params = JSON.stringify(data);
    return this._http.post(this.url + "facturados", params, {
      headers: headers,
    });
  }
}
