import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs/Observable";
import { GLOBAL } from "./global";
import { AppService } from "./app.service";

@Injectable()
export class FichajeService {
  public url: string;

  constructor(public _http: HttpClient, private _appservice: AppService) {
    this.url = GLOBAL.urlfichaje;
  }

  fichar(datos): Observable<any> {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("authorization", this._appservice.getToken());
    let params = { fichaje: datos };
    let cuerpo = JSON.stringify(params);
    return this._http.post(this.url + "fichar", cuerpo, { headers: headers });
  }

  ficharqr(datos): Observable<any> {
    let headers = new HttpHeaders().set("Content-Type", "application/json");
    let params = { fichaje: datos };
    let cuerpo = JSON.stringify(params);
    return this._http.post(this.url + "ficharqr", cuerpo, { headers: headers });
  }

  fichajemensajero(datos): Observable<any> {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("authorization", this._appservice.getToken());
    let params = { fichaje: datos };
    let cuerpo = JSON.stringify(params);
    return this._http.post(this.url + "fichajemensajero", cuerpo, {
      headers: headers,
    });
  }

  updatefichaje(datos, fichajeid): Observable<any> {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("authorization", this._appservice.getToken());
    let params = { fichaje: datos };
    let cuerpo = JSON.stringify(params);
    return this._http.post(this.url + "update/" + fichajeid, cuerpo, {
      headers: headers,
    });
  }

  updatefichajeqr(datos, fichajeid): Observable<any> {
    let headers = new HttpHeaders().set("Content-Type", "application/json");
    let params = { fichaje: datos };
    let cuerpo = JSON.stringify(params);
    return this._http.post(this.url + "updateqr/" + fichajeid, cuerpo, {
      headers: headers,
    });
  }

  getfichajes(query): Observable<any> {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("authorization", this._appservice.getToken());
    let params = { query: query };
    let cuerpo = JSON.stringify(params);
    return this._http.post(this.url + "fichajes", cuerpo, { headers: headers });
  }

  getfichajespop(query): Observable<any> {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("authorization", this._appservice.getToken());
    let params = { query: query };
    let cuerpo = JSON.stringify(params);
    return this._http.post(this.url + "fichajespop", cuerpo, {
      headers: headers,
    });
  }

  getfichaje(fichajeid): Observable<any> {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("authorization", this._appservice.getToken());
    return this._http.get(this.url + "fichaje/" + fichajeid, {
      headers: headers,
    });
  }

  getfichajeusr(): Observable<any> {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("authorization", this._appservice.getToken());
    return this._http.get(this.url + "fichajeusr", { headers: headers });
  }

  getfichajeusrqr(usrid): Observable<any> {
    let headers = new HttpHeaders().set("Content-Type", "application/json");
    let cuerpo = JSON.stringify({ usrid: usrid });
    return this._http.post(this.url + "fichajeusrqr", cuerpo, {
      headers: headers,
    });
  }

  setficha(ficha) {
    let fichaje = JSON.stringify(ficha);
    sessionStorage.setItem("fichaje", fichaje);
  }

  deleteficha() {
    sessionStorage.removeItem("fichaje");
  }

  getficha() {
    let ficha = JSON.parse(sessionStorage.getItem("fichaje"));
    if (ficha) {
      return ficha;
    } else {
      return null;
    }
  }
}
