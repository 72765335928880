import { Component, OnInit, ViewChild } from '@angular/core';
import { ClienteService } from '../../servicios/cliente.service';
import { AppService } from '../../servicios/app.service';
import * as moment from 'moment';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { IncidenciasService } from '../../servicios/incidencias.service';

@Component({
  selector: 'ventas',
  templateUrl: './ventas.component.html',
  styleUrls: ['./ventas.component.css'],
  providers: [ClienteService, IncidenciasService, AppService]
})
export class VentasComponent implements OnInit {
  public emailclienteseleccionado;
  public contactoclienteseleccionado;
  public totalclientes;
  public totalenvioscliente;
  public enviosclienteseleccionado;
  public clientes;
  public clientesactivos;
  public clientessuspendidos;
  public identity;
  public facturastotales;
  public clienteseleccionado;
  public facturasclienteseleccionado;
  public facturasclienteano;
  public facturasclienteanoant;
  public clientesplataforma;
  public clientplataforma;
  public load = true;
  public load2 = true;
  public filtro;
  public anoactual = moment().format('YYYY');
  public dataSource = new MatTableDataSource<any>();
  public columns = [
    {
      columnDef: 'Abonado',
      header: 'Abonado'
    },
    {
      columnDef: 'Nombre',
      header: 'Nombre'
    },
    {
      columnDef: 'Totalano',
      header: 'Total año ' + this.anoactual
    }
  ];
  public columnasfacturas = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre','Diciembre'];
  public displayedColumns = this.columns.map(c => c.columnDef);
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private _clienteService: ClienteService,
    private _incidenciasservice: IncidenciasService,
    private _appservice: AppService
  ) {
    this.identity = this._appservice.getIdentity();
  }

  ngOnInit(): void {
    this.getclientesplataforma();
    this.getFacturas();
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  getFacturas(){
    this.load = false;
    var query = {}
  	this._clienteService.getfacturasg3(query).subscribe(
  		response=>{
        this.facturastotales = response.facturas.filter(factura=>{
          let abinicio = factura.Abonado.slice(0,1);
          if(abinicio == '1' || abinicio == '2'){
            return false;
          }else{
            return true;
          }  
        });
        this.getcecos();
  		},
  		error=>{
  			console.log(<any>error);	
  		}	
  	)
  }

  importeanoactual(abonado){
    return parseFloat(this.facturastotales.filter((factura)=>{
      let fecha = moment(factura.FechaParticion).format('YYYY');
      if(factura.Abonado == abonado && fecha == this.anoactual){
        return true;
      }else{
        return false;
      }
    }).reduce(function(accumulator, item){
      const toNumber = parseFloat(item.ImporteFactura);
      return accumulator + toNumber;
    },0));
  }

  facturascliente(abonado){
    return this.facturastotales.filter((factura)=>{
      if(factura.Abonado == abonado){
        return true;
      }else{
        return false;
      }
    });
  }

  getcecos(){
    this._clienteService.getcecos({}).subscribe(
      response=>{
        if(response.cecos){
          var hash = {};
          this.totalclientes = response.cecos;
          this.clientes = response.cecos.filter(ceco=>{
            let abinicio = ceco.Abonado.slice(0,1);
            if(abinicio == '1' || abinicio == '2'){
              return false;
            }else{
              return true;
            }  
          }).filter((ab)=>{
            if(hash[ab.Abonado]){
              return false;
            }else{
              hash[ab.Abonado] = true;
              return true;
            }
          }).map((abonado)=>{
            if(abonado.datosAbonado){
              abonado['Nombre'] = abonado.datosAbonado.RazonSocial;
            }else{
              abonado['Nombre'] = abonado.Descripcion;
            }  
            abonado['Totalano'] = (this.importeanoactual(abonado.Abonado)).toFixed(2);
            return abonado;
          }).sort(function(a,b){
            let origen = parseInt(a.Abonado);
            let destino = parseInt(b.Abonado);
            return origen > destino;
          });
          this.clientesactivos = this.clientes.filter((cli)=>{
            if(cli.datosAbonado && cli.datosAbonado.Estado == 'A'){
              return true;
            }else{
              return false;
            }
          });
          this.clientessuspendidos = this.clientes.filter((cli)=>{
            if(cli.datosAbonado && cli.datosAbonado.Estado != 'A'){
              return true;
            }else{
              return false;
            }
          });
          this.dataSource.data = this.clientesactivos;
          this.clienteseleccionado = this.clientes[0];
          this.load = true;
        }  
      }
    )
  }

  getclientesactivos(){
    this.dataSource.data = this.clientesactivos;
  }

  getclientesSuspendidos(){
    this.dataSource.data = this.clientessuspendidos;
  }

  todoslosclientes(){
    this.dataSource.data = this.clientes;
  }

  getclientesplataforma(){
    this._clienteService.getclientes().subscribe(
      response=>{
        this.clientesplataforma = response.clientes;
      }
    )
  }

  importemes(mes, a){
    let ano = a.toString();
    if(this.facturasclienteseleccionado[ano] && this.facturasclienteseleccionado[ano][mes]){
      return this.facturasclienteseleccionado[ano][mes]
    }else{
      return 0.00;
    }
  }

  importeano(a){
    let ano = a.toString();
    if(this.facturasclienteseleccionado[ano] && this.facturasclienteseleccionado[ano].total){
      return this.facturasclienteseleccionado[ano].total
    }else{
      return 0.00;
    }
  }

  crearfacturascliente(abonado){
    var factcliente = this.facturascliente(abonado);
    this.facturasclienteseleccionado = {};
    this.facturasclienteseleccionado['anos'] = new Array();
    factcliente.forEach((fact)=>{
      let fecha = moment(fact.FechaParticion);
      let ano = moment(fecha).format('YYYY');
      let mes = moment(fecha).format('MM');
      if(this.facturasclienteseleccionado[ano]){
        if(this.facturasclienteseleccionado[ano][mes]){
          this.facturasclienteseleccionado[ano][mes] = this.facturasclienteseleccionado[ano][mes] + fact.ImporteFactura;
          this.facturasclienteseleccionado[ano]['total'] = this.facturasclienteseleccionado[ano]['total'] + fact.ImporteFactura
        }else{
          this.facturasclienteseleccionado[ano][mes] = fact.ImporteFactura;
          this.facturasclienteseleccionado[ano]['total'] = fact.ImporteFactura + this.facturasclienteseleccionado[ano]['total']
        }
      }else{
        this.facturasclienteseleccionado.anos.push(ano);
        this.facturasclienteseleccionado.anos.sort((a,b)=>{
          return parseInt(a) < parseInt(b);
        });
        this.facturasclienteseleccionado[ano] = {};
        this.facturasclienteseleccionado[ano][mes] = fact.ImporteFactura;
        this.facturasclienteseleccionado[ano]['total'] = fact.ImporteFactura;
      }
    });
  }

  seleccionar(row){
    this.clienteseleccionado = this.clientes.find(cliente=>cliente.Abonado == row.Abonado);
    this.clientplataforma = this.clientesplataforma.find(cli=>cli.numero == row.Abonado);
    this.crearfacturascliente(this.clienteseleccionado.Abonado);
    this.getenvioscliente(this.clienteseleccionado.Abonado);
  }

  verano(ano){
    console.log(ano);
  }

  getenvioscliente(abonado){
    this.load2 = false;
    var query = {
       CodigoAbonado:abonado,
       FranquiciaClienteFacturacion: '02601' 
    };
    var queryoptions = {paginacion: 'no'};
    this._incidenciasservice.getincidencias(query, queryoptions).subscribe(
      response =>{
        if(response.incidencias){
  ​​        this.totalenvioscliente = response.incidencias
          this.crearenvioscliente();
        }
      },
      error =>{
        console.log(<any>error);
      } 
    );
  }

  crearenvioscliente(){
    this.enviosclienteseleccionado = {};
    this.enviosclienteseleccionado['anos'] = new Array();
    this.totalenvioscliente.forEach((envio)=>{
      var fecha = null;
      if(envio.FechaEnvio && typeof(envio.FechaEnvio) == 'string' && envio.FechaEnvio.length >=2){
        fecha = moment(envio.FechaEnvio);
      }else if(envio.FechaRecogida && typeof(envio.FechaRecogida) == 'string' && envio.FechaRecogida.length >=2){
        fecha = moment(envio.FechaRecogida);
      }
      if(fecha){  
        let ano = moment(fecha).format('YYYY');
        let mes = moment(fecha).format('MM');
        if(this.enviosclienteseleccionado[ano]){
          if(this.enviosclienteseleccionado[ano][mes]){
            this.enviosclienteseleccionado[ano][mes] = this.enviosclienteseleccionado[ano][mes] + 1;
            this.enviosclienteseleccionado[ano]['total'] = this.enviosclienteseleccionado[ano]['total'] + 1
          }else{
            this.enviosclienteseleccionado[ano][mes] = + 1;
            this.enviosclienteseleccionado[ano]['total'] = this.enviosclienteseleccionado[ano]['total'] + 1
          }
        }else{
          this.enviosclienteseleccionado.anos.push(ano);
          this.enviosclienteseleccionado.anos.sort((a,b)=>{
            return parseInt(a) < parseInt(b);
          });
          this.enviosclienteseleccionado[ano] = {};
          this.enviosclienteseleccionado[ano][mes] = 1;
          this.enviosclienteseleccionado[ano]['total'] = 1;
        }
      }else{
        console.log(envio.NumAlbaran);
      }  
    });
    this.load2 = true;
  }

  enviosmes(mes, a){
    let ano = a.toString();
    if(this.enviosclienteseleccionado[ano] && this.enviosclienteseleccionado[ano][mes]){
      return this.enviosclienteseleccionado[ano][mes]
    }else{
      return 0;
    }
  }

  enviosano(a){
    let ano = a.toString();
    if(this.enviosclienteseleccionado[ano] && this.enviosclienteseleccionado[ano].total){
      return this.enviosclienteseleccionado[ano].total
    }else{
      return 0;
    }
  }

  abreabonadog3(abonado){
    let dir = 'https://www2-vpc.mrw.es/gestion3/Comercial/Clientes/EdicionCliente?Arguments=' + abonado + '_clienteEdit'
    window.open(dir);
  }

}
