import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs/Observable";
import { GLOBAL } from "./global";
import { AppService } from "./app.service";

@Injectable()
export class CallG3Service {
  public url: string;

  constructor(public _http: HttpClient, public _appservice: AppService) {
    this.url = GLOBAL.urlcallg3;
  }

  poblacionporcp(cp): Observable<any> {
    let cookie = {
      galletag3: this._appservice.getgalletag3(),
      authorization: this._appservice.getgauthG3(),
    };
    let cuerpo = { cp: cp, cookies: cookie };
    let params = JSON.stringify(cuerpo);
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("authorization", this._appservice.getToken());
    return this._http.post(this.url + "poblacionporcp", params, {
      headers: headers,
    });
  }

  poblacionsugerida(poblacion): Observable<any> {
    let cookie = {
      galletag3: this._appservice.getgalletag3(),
      authorization: this._appservice.getgauthG3(),
    };
    let cuerpo = { poblacion: poblacion, cookies: cookie };
    let params = JSON.stringify(cuerpo);
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("authorization", this._appservice.getToken());
    return this._http.post(this.url + "poblacionsugerida", params, {
      headers: headers,
    });
  }

  cppoblacion(poblacion, provincia): Observable<any> {
    let cookie = {
      galletag3: this._appservice.getgalletag3(),
      authorization: this._appservice.getgauthG3(),
    };
    let cuerpo = {
      poblacion: poblacion,
      provincia: provincia,
      cookies: cookie,
    };
    let params = JSON.stringify(cuerpo);
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("authorization", this._appservice.getToken());
    return this._http.post(this.url + "codigospostalespoblacion", params, {
      headers: headers,
    });
  }

  tienecallejero(poblacion): Observable<any> {
    let cookie = {
      galletag3: this._appservice.getgalletag3(),
      authorization: this._appservice.getgauthG3(),
    };
    let cuerpo = { poblacion: poblacion, cookies: cookie };
    let params = JSON.stringify(cuerpo);
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("authorization", this._appservice.getToken());
    return this._http.post(this.url + "tienecallejero", params, {
      headers: headers,
    });
  }

  callesugerida(poblacion, calle): Observable<any> {
    let cookie = {
      galletag3: this._appservice.getgalletag3(),
      authorization: this._appservice.getgauthG3(),
    };
    let cuerpo = { poblacion: poblacion, calle: calle, cookies: cookie };
    let params = JSON.stringify(cuerpo);
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("authorization", this._appservice.getToken());
    return this._http.post(this.url + "callesugerida", params, {
      headers: headers,
    });
  }

  humanizatipovia(tipo): String {
    var tipos = {
      AB: "ARRABAL",
      AC: "ACERA",
      AD: "ALDA",
      AE: "ACCESO",
      AI: "PATIO",
      AL: "ALAMEDA",
      AN: "ANTIGUO",
      AO: "ARCO",
      AP: "APARTAMENTO",
      AR: "ARA",
      AS: "CASAS",
      AT: "ALTO",
      AU: "AUTOVIA",
      AV: "AVENIDA",
      AY: "ARROYO",
      AZ: "PLAZUELA",
      BA: "BARRIADA",
      BB: "RCANTOS",
      BJ: "BAJADA",
      BL: "BLOQUE",
      BO: "BARRIO",
      BR: "BARRANCO",
      BU: "BULEVAR",
      CA: "CALLEJA",
      CB: "CIRCUNVALACION",
      CC: "CAMPO",
      CD: "CUADRA",
      CE: "CARRERA",
      CG: "COLGIO",
      CH: "CHALET",
      CI: "CARRIL",
      CJ: "CALLEJON",
      CL: "CALLE",
      CM: "CAMINO",
      CN: "CONJUNTO",
      CÑ: "CAÑADA",
      CO: "CORTIJO",
      CP: "CAMPING",
      CQ: "ACEQUIA",
      CR: "CARRETERA",
      CS: "CASERIO",
      CT: "CINTURON",
      CU: "CUESTA",
      CV: "CAMINO VIEJO",
      CZ: "CALZADA",
      DA: "RINCONADA",
      DC: "CORRDOR",
      DE: "DHESA",
      DS: "DISEMINADO",
      EA: "ESCALAS",
      EC: "ESTACION",
      ED: "DIFICIO",
      EJ: "CALLEJUELA",
      EM: "EXTRAMUROS",
      EN: "ENTRADA",
      EO: "CERRO",
      ER: "EXTRARRADIO",
      ES: "ESCALINATA",
      ET: "ESTRADA",
      EX: "EXPLANADA",
      FC: "FERROCARRIL",
      FN: "FINCA",
      FU: "FUENTE",
      GL: "GLORIETA",
      GR: "GRUPO",
      GV: "GRAN VIA",
      HO: "HORTA",
      HT: "HUERTA",
      IN: "RCINTO",
      IS: "ISLA",
      IU: "CIUDADELA",
      JP: "PARAJE",
      JR: "JARDINES",
      KO: "COOPERATIVA",
      LA: "PLACETA",
      LD: "LADO",
      LG: "LUGAR",
      LL: "PASILLO",
      LM: "LOMA",
      LN: "LLANO",
      LQ: "ALQUERIA",
      MA: "MALCON",
      MC: "MERCADO",
      ML: "MUELLE",
      MN: "MUNICIPIO",
      MO: "MONTAÑA",
      MR: "MONASTERIO",
      EMS: "MASIA",
      MT: "MONTE",
      MU: "MURO",
      MZ: "MANZANA",
      NC: "NUCLEO",
      ND: "NUDO",
      NI: "COSTANILLA",
      NL: "CANAL",
      NO: "PANTANO",
      NR: "ANDADOR",
      NT: "CANTON",
      OA: "CORRAL",
      OE: "COSTERA",
      OL: "COLONIA",
      ON: "CONVENTO",
      OS: "POSADA",
      OT: "POSTIGO",
      PA: "PARCELA",
      PB: "POBLADO",
      PC: "PARTICULAR",
      PD: "PARTIDA",
      PE: "PLAZOLETA",
      PG: "POLIGONO",
      PI: "PASADIZO",
      PJ: "PASAJE",
      PL: "PARALELA",
      PM: "PASEO MARITIMO",
      PN: "PARCELACION",
      PO: "PORTAL",
      PP: "PORTA",
      PQ: "PARROQUIA",
      PR: "PROLONGACION",
      PS: "PASEO",
      PT: "PUENTE",
      PU: "PUERTA",
      PY: "PLAYA",
      PZ: "PLAZA",
      QE: "PARQUE",
      QT: "QUINTA",
      RA: "RIBERA",
      RB: "RAMBLA",
      RC: "RINCON",
      RD: "RONDA",
      RE: "REPLACETA",
      RI: "RIO",
      RL: "RAVAL",
      RM: "RAMAL",
      RN: "RONDIN",
      RO: "ROTONDA",
      RP: "RAMPA",
      RR: "RIERA",
      RS: "RESIDENCIAL",
      RT: "RAMBLETA",
      RU: "CRUCE",
      SA: "SALON",
      SB: "SUBIDA",
      SC: "SCTOR",
      SD: "SENDA",
      SE: "SCCION",
      SL: "SOLAR",
      SN: "SENDERO",
      SP: "PASO",
      SR: "ESCALERA",
      ST: "SANTUARIO",
      TA: "COSTA",
      TE: "TRASERA",
      TI: "PORTICO",
      TJ: "ATAJO",
      TL: "TUNEL",
      TN: "TERRENOS",
      TO: "TORRENTE",
      TP: "AUTOPISTA",
      TR: "TRAVESIA",
      TS: "TRANSITO",
      TV: "TRANSVERSAL",
      UE: "PUBLO",
      UR: "URBANIZACION",
      UT: "PUERTO",
      VA: "VIAL",
      VC: "CUEVAS",
      VD: "VIADUCTO",
      VI: "VIA",
      VL: "VILLA",
      VP: "VIA PUBLICA",
      VR: "VERDA",
      VV: "VIVIENDAS",
      ZN: "ZONA",
    };
    return tipos[tipo];
  }
}
