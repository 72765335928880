<div class="barraherramientas">
	<div *ngIf="dataSource && dataSource.data" style="margin-left:30px;">
		<mat-form-field appearance="outline" style="text-align:center;font-size:12pt">
			<mat-label style="color:black;"><b>Direcciones</b></mat-label>
			<input matInput type="text" [(ngModel)]="dataSource.data.length" style="padding:3px;color:black;" disabled="true">
		</mat-form-field>	
	</div>
	<div *ngIf="dirreales" style="margin-left:30px;">
		<mat-form-field appearance="outline" style="text-align:center;font-size:12pt">
			<mat-label style="color:black;"><b>Direcciones reales</b></mat-label>
			<input matInput type="text" [value]="dirreales.length" style="padding:3px;color:black;" disabled="true">
		</mat-form-field>	
	</div>
	<div style="margin-left:30px;">
		<mat-form-field appearance="outline" style="text-align:center;font-size:12pt">
			<mat-label style="color:black;" (click)="pickeroperativa.open()"><b>Fecha Operativa</b></mat-label>
			<mat-date-range-input [rangePicker]="pickeroperativa" (click)="pickeroperativa.open()">
			  <input matStartDate placeholder="Fecha Inicio" [formControl]="operativainicial">
			  <input matEndDate placeholder="Fecha fin" [formControl]="operativafinal" (dateChange)="onDateFechaoperativa()">
			</mat-date-range-input>
			<button mat-button *ngIf="operativainicial.value" matSuffix mat-icon-button aria-label="Clear" (click)="limpiaoperativa()">
			  <mat-icon>close</mat-icon>
			</button>
			<mat-date-range-picker #pickeroperativa></mat-date-range-picker>
		</mat-form-field>
	</div>
	<div style="margin-left:30px;">
		<mat-form-field appearance="outline" style="text-align:center;font-size:12pt">
			<mat-label style="font-size:11pt;color:black;">Buscar Texto</mat-label>
			<input matInput (keyup)="applyFilter($event)" placeholder="Texto a buscar.." [(ngModel)]="filtros['filtroprincipal']">
				<button mat-button *ngIf="filtros['filtroprincipal']" matSuffix mat-icon-button aria-label="Clear" (click)="dataSource.filter = ''; filtros['filtroprincipal']=''">
				<mat-icon>close</mat-icon>
				</button>
		</mat-form-field>
	</div>
	<div style="margin-left:30px;">
		<button mat-button mat-icon-button aria-label="Clear" (click)="toggledirecciones()" style="margin-left:20%">
			<mat-icon>switch_left</mat-icon>
		</button><br>
		<span style="font-size:8px;">Cambiar Direcciones</span>
	</div>
</div>

<div class="contenidoincidencias">
	<mat-progress-bar *ngIf="!load" mode="indeterminate"></mat-progress-bar>
		<table mat-table *ngIf="dataSource && identity" [dataSource]="dataSource" matSort cdkDropList cdkDropListOrientation="horizontal" (cdkDropListDropped)="drop($event)">
		  <ng-container matColumnDef="select" class="cabeceras">
		    <th mat-header-cell cdkDrag *matHeaderCellDef class="cabeceras">
		      <mat-checkbox color="primary" (change)="$event ? masterToggle() : null"
		                    [checked]="selection.hasValue() && isAllSelected()"
		                    [indeterminate]="selection.hasValue() && !isAllSelected()"
		                    [aria-label]="checkboxLabel()">
		      </mat-checkbox>
		    </th>
		    <td mat-cell *matCellDef="let row">
		      <mat-checkbox color="primary" (click)="$event.stopPropagation()"
		                    (change)="$event ? selection.toggle(row) : null"
		                    [checked]="selection.isSelected(row)"
		                    [aria-label]="checkboxLabel(row)">
		      </mat-checkbox>
		    </td>
		  </ng-container>
		  <ng-container matColumnDef="NumAlbaran">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
			    <mat-form-field appearance="outline" style="text-align:center;max-width:110px;font-size:7pt">
				  <mat-label style="color:black;"><b>Albaran</b></mat-label>
				  <input matInput type="text" [(ngModel)]="filtros['NumAlbaran']" style="padding:1px;color:black;">
				  <button mat-button *ngIf="filtros['NumAlbaran']" matSuffix mat-icon-button aria-label="Clear" (click)="filtros['NumAlbaran']='';">
				    <mat-icon>close</mat-icon>
				  </button>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.NumAlbaran}} </td>
		  </ng-container>


		  <ng-container matColumnDef="Tipo">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-card style="width:100%;font-size:10pt;height:15pt;padding:2px;margin:0px;">
					   Tipo
				</mat-card>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.Tipo}} </td>
		  </ng-container>

		  <ng-container matColumnDef="Anomalia">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-card style="width:100%;font-size:10pt;height:15pt;padding:2px;margin:0px;">
					   Anomalia
				</mat-card>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.Anomalia}} </td>
		  </ng-container>
		  <ng-container matColumnDef="AnomaliaMotivo">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-card style="width:100%;;font-size:10pt;height:15pt;padding:2px;margin:0px;">
					   AnomaliaMotivo
				</mat-card>
		    </th>
		    <td mat-cell *matCellDef="let element" style="max-width:200px;"> {{element.AnomaliaMotivo}} </td>
		  </ng-container>
		  <ng-container matColumnDef="AnomaliaObservaciones">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-card style="width:100%;font-size:10pt;height:15pt;padding:2px;margin:0px;">
					   AnomaliaObservaciones
				</mat-card>
		    </th>
		    <td mat-cell *matCellDef="let element" style="max-width:200px;"> {{element.AnomaliaObservaciones}} </td>
		  </ng-container>
		  <ng-container matColumnDef="CodigoAbonado">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field appearance="outline" style="text-align:center;max-width:80px;font-size:7pt">
				  <mat-label style="color:black;"><b>Abonado</b></mat-label>
				  <input matInput type="text" [(ngModel)]="filtros['CodigoAbonado']" style="padding:1px;color:black;"
				  (keyup.enter)="onselectfiltro('CodigoAbonado', filtros['CodigoAbonado'])">
				  <button mat-button *ngIf="filtros['CodigoAbonado']" matSuffix mat-icon-button aria-label="Clear" (click)="filtros['CodigoAbonado']=''; eliminafiltro('CodigoAbonado')">
				    <mat-icon>close</mat-icon>
				  </button>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.CodigoAbonado}} </td>
		  </ng-container>
		  <ng-container matColumnDef="CodigoFranquicia">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-card style="width:100%;font-size:10pt;height:15pt;padding:2px;margin:0px;">
					   CodigoFranquicia
				</mat-card>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.CodigoFranquicia}} </td>
		  </ng-container>
		  <ng-container matColumnDef="ContactoEntrega">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-card style="width:100%;font-size:10pt;height:15pt;padding:2px;margin:0px;">
					   ContactoEntrega
				</mat-card>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.ContactoEntrega}} </td>
		  </ng-container>
		  <ng-container matColumnDef="ContactoRecogida">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-card style="width:100%;font-size:10pt;height:15pt;padding:2px;margin:0px;">
					   ContactoRecogida
				</mat-card>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.ContactoRecogida}} </td>
		  </ng-container>
		  <ng-container matColumnDef="CpDestinatario">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-card style="width:100%;font-size:10pt;height:15pt;padding:2px;margin:0px;">
					   CpDestinatario
				</mat-card>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.CpDestinatario}} </td>
		  </ng-container>
		  <ng-container matColumnDef="CpRemitente">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-card style="width:100%;font-size:10pt;height:15pt;padding:2px;margin:0px;">
					   CpRemitente
				</mat-card>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.CpRemitente}} </td>
		  </ng-container>
		  <ng-container matColumnDef="Destinatario">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-card style="width:100%;font-size:10pt;height:15pt;padding:2px;margin:0px;">
					   Destinatario
				</mat-card>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.Destinatario}} </td>
		  </ng-container>
		  <ng-container matColumnDef="DireccionDestinatario">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-card style="width:100%;font-size:10pt;height:15pt;padding:2px;margin:0px;">
					   DireccionDestinatario
				</mat-card>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.DireccionDestinatario}} </td>
		  </ng-container>
		  <ng-container matColumnDef="DireccionRemitente">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-card style="width:100%;font-size:10pt;height:15pt;padding:2px;margin:0px;">
					   DireccionRemitente
				</mat-card>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.DireccionRemitente}} </td>
		  </ng-container>
		  <ng-container matColumnDef="Estado">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field>
		    		<mat-label style="font-size:11pt;color:black;">Estado</mat-label>
					<mat-select [(ngModel)]="this.identity.incidenciasdefinitivas" multiple>
						<mat-option *ngFor="let campo of listasfiltros.listaestado" [value]="campo">{{campo}}</mat-option>
					</mat-select>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.Estado}} </td>
		  </ng-container>
		  <ng-container matColumnDef="Fase">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-card style="width:100%;font-size:10pt;height:15pt;padding:2px;margin:0px;">
					   Fase
				</mat-card>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.Fase}} </td>
		  </ng-container>
		  <ng-container matColumnDef="FechaEntrega">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field appearance="outline" style="text-align:center;max-width:120px;font-size:8pt">
				  <mat-label style="color:black;" (click)="picker3.open()"><b>Fecha Entrega</b></mat-label>
				  <mat-date-range-input [rangePicker]="picker3" (click)="picker3.open()">
					<input matStartDate placeholder="Fecha Inicio" [(ngModel)]="filtros['FechaEntregainicial']">
					<input matEndDate placeholder="Fecha fin" [(ngModel)]="filtros['FechaEntregafinal']" (dateChange)="onDateFechaentrega()">
				  </mat-date-range-input>
				  <button mat-button *ngIf="filtros['FechaEntregainicial']" matSuffix mat-icon-button aria-label="Clear" (click)="filtros['FechaEntregainicial']=''; filtros['FechaEntregafinal']='';">
				    <mat-icon>close</mat-icon>
				  </button>
				  <mat-date-range-picker #picker3></mat-date-range-picker>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.FechaEntrega | date:'dd/MM/yyyy HH:mm:ss'}} </td>
		  </ng-container>
		  <ng-container matColumnDef="FechaEnvio">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field appearance="outline" style="text-align:center;max-width:110px;font-size:8pt">
				  <mat-label style="color:black;" (click)="picker.open()"><b>Fecha Operativa</b></mat-label>
				  <mat-date-range-input [rangePicker]="picker" (click)="picker.open()">
					<input matStartDate placeholder="Fecha Inicio" [(ngModel)]="filtros['Fechaoperativainicial']">
					<input matEndDate placeholder="Fecha fin" [(ngModel)]="filtros['Fechaoperativafinal']">
				  </mat-date-range-input>
				  <button mat-button *ngIf="filtros['Fechaoperativainicial']" matSuffix mat-icon-button aria-label="Clear" (click)="getdirecciones()">
				    <mat-icon>close</mat-icon>
				  </button>
				  <mat-date-range-picker #picker></mat-date-range-picker>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.FechaEnvio | date:'dd/MM/yyyy'}} </td>
		  </ng-container>
		  <ng-container matColumnDef="EntregarAPartirDe">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-card style="width:100%;font-size:10pt;height:15pt;padding:2px;margin:0px;">
					   EntregarAPartirDe
				</mat-card>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.EntregarAPartirDe}} </td>
		  </ng-container>
		  <ng-container matColumnDef="FechaRecogida">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field appearance="outline" style="text-align:center;max-width:120px;font-size:8pt">
				  <mat-label style="color:black;" (click)="picker2.open()"><b>Fecha Recogida</b></mat-label>
				  <mat-date-range-input [rangePicker]="picker2" (click)="picker2.open()">
					<input matStartDate placeholder="Fecha Inicio" [(ngModel)]="filtros['FechaRecogidainicial']">
					<input matEndDate placeholder="Fecha fin" [(ngModel)]="filtros['FechaRecogidafinal']" (dateChange)="onDateFecharecogida()">
				  </mat-date-range-input>
				  <button mat-button *ngIf="filtros['FechaRecogidainicial']" matSuffix mat-icon-button aria-label="Clear" (click)="filtros['FechaRecogidainicial']=''; filtros['FechaRecogidafinal']='';">
				    <mat-icon>close</mat-icon>
				  </button>
				  <mat-date-range-picker #picker2></mat-date-range-picker>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.FechaRecogida | date:'dd/MM/yyyy'}} </td>
		  </ng-container>
		  <ng-container matColumnDef="FechaFinalizacionRecogida">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field appearance="outline" style="text-align:center;max-width:120px;font-size:8pt">
				  <mat-label style="color:black;" (click)="picker4.open()"><b>Recogida Finalizada</b></mat-label>
				  <mat-date-range-input [rangePicker]="picker4" (click)="picker4.open()">
					<input matStartDate placeholder="Fecha Inicio" [(ngModel)]="filtros['FechaRecogidafinalizadainicial']">
					<input matEndDate placeholder="Fecha fin" [(ngModel)]="filtros['FechaRecogidafinalizadafinal']" (dateChange)="onDateFecharecogidafinalizada()">
				  </mat-date-range-input>
				  <button mat-button *ngIf="filtros['FechaRecogidafinalizadainicial']" matSuffix mat-icon-button aria-label="Clear" (click)="filtros['FechaRecogidafinalizadainicial']=''; filtros['FechaRecogidafinalizadafinal']='';">
				    <mat-icon>close</mat-icon>
				  </button>
				  <mat-date-range-picker #picker4></mat-date-range-picker>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.FechaFinalizacionRecogida | date:'dd/MM/yyyy HH:mm:ss'}} </td>
		  </ng-container>
		  <ng-container matColumnDef="FechaConcertacion">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-card style="width:100%;font-size:10pt;height:15pt;padding:2px;margin:0px;">
					Concertada
			 </mat-card>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.FechaConcertacion | date:'dd/MM/yyyy'}} </td>
		  </ng-container>
		  <ng-container matColumnDef="HoraMaximaEntrega">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-card style="width:100%;font-size:10pt;height:15pt;padding:2px;margin:0px;">
					   HoraMaximaEntrega
				</mat-card>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.HoraMaximaEntrega}} </td>
		  </ng-container>
		  <ng-container matColumnDef="HoraMaximaRecogida">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header> 
		    	<mat-card style="width:100%;font-size:10pt;height:15pt;padding:2px;margin:0px;">
					   HoraMaximaRecogida
				</mat-card>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.HoraMaximaRecogida}} </td>
		  </ng-container>
		  <ng-container matColumnDef="IdFiscal">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-card style="width:100%;font-size:10pt;height:15pt;padding:2px;margin:0px;">
					   IdFiscal
				</mat-card>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.IdFiscal}} </td>
		  </ng-container>
		  <ng-container matColumnDef="AR">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-card style="width:100%;font-size:10pt;height:15pt;padding:2px;margin:0px;">
					   AR
				</mat-card>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.AR}} </td>
		  </ng-container>
		  <ng-container matColumnDef="ImporteAR">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-card style="width:100%;font-size:10pt;height:15pt;padding:2px;margin:0px;">
					   ImporteAR
				</mat-card>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.ImporteAR}} </td>
		  </ng-container>
		  <ng-container matColumnDef="Kilos">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-card style="width:100%;font-size:10pt;height:15pt;padding:2px;margin:0px;">
					   Kilos
				</mat-card>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.Kilos}} </td>
		  </ng-container>
		  <ng-container matColumnDef="KmDestinatario">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-card style="width:100%;font-size:10pt;height:15pt;padding:2px;margin:0px;">
					   KmDestinatario
				</mat-card>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.KmDestinatario}} </td>
		  </ng-container>
		  <ng-container matColumnDef="KmRemitente">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-card style="width:100%;font-size:10pt;height:15pt;padding:2px;margin:0px;">
					   KmRemitente
				</mat-card>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.KmRemitente}} </td>
		  </ng-container>
		  <ng-container matColumnDef="Motivo">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field appearance="outline" style="text-align:center;width:100%;font-size:8pt">
				  <mat-label style="font-size:11pt;color:black;">Motivo</mat-label>
				  <mat-select [(value)]="filtros['Motivo']" (selectionChange)="onselectfiltro('Motivo', $event.value)">
				    <mat-option [value]="motivo" *ngFor="let motivo of listasfiltros.listamotivo">{{motivo.valor}}</mat-option>
				  </mat-select>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.Motivo}} </td>
		  </ng-container>
		  <ng-container matColumnDef="NombreCliente">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-card style="width:100%;font-size:10pt;height:15pt;padding:2px;margin:0px;">
					   NombreCliente
				</mat-card>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.NombreCliente}} </td>
		  </ng-container>
		  <ng-container matColumnDef="NombreSolicitante">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-card style="width:100%;font-size:10pt;height:15pt;padding:2px;margin:0px;">
					   NombreSolicitante
				</mat-card>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.NombreSolicitante}} </td>
		  </ng-container>
		  <ng-container matColumnDef="ObservacionesEntrega">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-card style="width:100%;font-size:10pt;height:15pt;padding:2px;margin:0px;">
					   ObservacionesEntrega
				</mat-card>
		    </th>
		    <td mat-cell *matCellDef="let element" style="max-width:200px;"> {{element.ObservacionesEntrega}} </td>
		  </ng-container>
		  <ng-container matColumnDef="ObservacionesRecogida">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-card style="width:100%;font-size:10pt;height:15pt;padding:2px;margin:0px;">
					   ObservacionesRecogida
				</mat-card>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.ObservacionesRecogida}} </td>
		  </ng-container>
		  <ng-container matColumnDef="PoblacionDestinatario">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-card style="width:100%;font-size:10pt;height:15pt;padding:2px;margin:0px;">
					   PoblacionDestinatario
				</mat-card>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.PoblacionDestinatario}} </td>
		  </ng-container>
		  <ng-container matColumnDef="PoblacionRemitente">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-card style="width:100%;font-size:10pt;height:15pt;padding:2px;margin:0px;">
					   PoblacionRemitente
				</mat-card>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.PoblacionRemitente}} </td>
		  </ng-container>
		  <ng-container matColumnDef="ProvinciaDestinatario">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-card style="width:100%;font-size:10pt;height:15pt;padding:2px;margin:0px;">
					   ProvinciaDestinatario
				</mat-card>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.ProvinciaDestinatario}} </td>
		  </ng-container>
		  <ng-container matColumnDef="ProvinciaRemitente">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-card style="width:100%;font-size:10pt;height:15pt;padding:2px;margin:0px;">
					   ProvinciaRemitente
				</mat-card>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.ProvinciaRemitente}} </td>
		  </ng-container>
		  <ng-container matColumnDef="RangoHorarioEntregaManana">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-card style="width:100%;font-size:10pt;height:15pt;padding:2px;margin:0px;">
					   RangoHorarioEntregaManana
				</mat-card>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.RangoHorarioEntregaManana}} </td>
		  </ng-container>
		  <ng-container matColumnDef="RangoHorarioEntregaTarde">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-card style="width:100%;font-size:10pt;height:15pt;padding:2px;margin:0px;">
					   RangoHorarioEntregaTarde
				</mat-card>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.RangoHorarioEntregaTarde}} </td>
		  </ng-container>
		  <ng-container matColumnDef="RangoHorarioRecogidaManana">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-card style="width:100%;font-size:10pt;height:15pt;padding:2px;margin:0px;">
					   RangoHorarioRecogidaManana
				</mat-card>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.RangoHorarioRecogidaManana}} </td>
		  </ng-container>
		  <ng-container matColumnDef="RangoHorarioRecogidaTarde">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-card style="width:100%;font-size:10pt;height:15pt;padding:2px;margin:0px;">
					   RangoHorarioRecogidaTarde
				</mat-card>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.RangoHorarioRecogidaTarde}} </td>
		  </ng-container>
		  <ng-container matColumnDef="Remitente">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-card style="width:100%;font-size:10pt;height:15pt;padding:2px;margin:0px;">
					   Remitente
				</mat-card>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.Remitente}} </td>
		  </ng-container>
		  <ng-container matColumnDef="Servicio">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-card style="width:100%;font-size:10pt;height:15pt;padding:2px;margin:0px;">
					   Servicio
				</mat-card>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.Servicio}} </td>
		  </ng-container>
		  <ng-container matColumnDef="TelefonoDestinatario">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-card style="width:100%;font-size:10pt;height:15pt;padding:2px;margin:0px;">
					   TelefonoDestinatario
				</mat-card>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.TelefonoDestinatario}} </td>
		  </ng-container>
		  <ng-container matColumnDef="TelefonoRemitente">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-card style="width:100%;font-size:10pt;height:15pt;padding:2px;margin:0px;">
					   TelefonoRemitente
				</mat-card>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.TelefonoRemitente}} </td>
		  </ng-container>
		  <ng-container matColumnDef="TipoComision">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-card style="width:100%;font-size:10pt;height:15pt;padding:2px;margin:0px;">
					   TipoComision
				</mat-card>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.TipoComision}} </td>
		  </ng-container>
		  <ng-container matColumnDef="TipoEntrega">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-card style="width:100%;font-size:10pt;height:15pt;padding:2px;margin:0px;">
					   TipoEntrega
				</mat-card>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.TipoEntrega}} </td>
		  </ng-container>
		  <ng-container matColumnDef="TipoGeografico">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-card style="width:100%;font-size:10pt;height:15pt;padding:2px;margin:0px;">
					   TipoGeografico
				</mat-card>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.TipoGeografico}} </td>
		  </ng-container>
		  <ng-container matColumnDef="TipoMercancia">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-card style="width:100%;font-size:10pt;height:15pt;padding:2px;margin:0px;">
					   TipoMercancia
				</mat-card>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.TipoMercancia}} </td>
		  </ng-container>
		  <ng-container matColumnDef="TipoRecogida">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-card style="width:100%;font-size:10pt;height:15pt;padding:2px;margin:0px;">
					   TipoRecogida
				</mat-card>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.TipoRecogida}} </td>
		  </ng-container>
		  <ng-container matColumnDef="TotalBultos">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-card style="width:100%;font-size:10pt;height:15pt;padding:2px;margin:0px;">
					   TotalBultos
				</mat-card>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.TotalBultos}} </td>
		  </ng-container>
		  <ng-container matColumnDef="TipoCobro">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-card style="width:100%;font-size:10pt;height:15pt;padding:2px;margin:0px;">
					   TipoCobro
				</mat-card>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.TipoCobro}} </td>
		  </ng-container>
		  <ng-container matColumnDef="FranquiciaDestino">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-card style="width:100%;font-size:10pt;height:15pt;padding:2px;margin:0px;">
					   FranquiciaDestino
				</mat-card>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.FranquiciaDestino}} </td>
		  </ng-container>
		  <ng-container matColumnDef="FranquiciaOrigen">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-card style="width:100%;font-size:10pt;height:15pt;padding:2px;margin:0px;">
					   FranquiciaOrigen
				</mat-card>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.FranquiciaOrigen}} </td>
		  </ng-container>
		  <ng-container matColumnDef="FranquiciaTercera">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-card style="width:100%;font-size:10pt;height:15pt;padding:2px;margin:0px;">
					   FranquiciaTercera
				</mat-card>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.FranquiciaTercera}} </td>
		  </ng-container>
		  <ng-container matColumnDef="FranquiciaClienteFacturacion">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-card style="width:100%;font-size:10pt;height:15pt;padding:2px;margin:0px;">
					   FranquiciaClienteFacturacion
				</mat-card>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.FranquiciaClienteFacturacion}} </td>
		  </ng-container>
		  <ng-container matColumnDef="CodigoCupon">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-card style="width:100%;font-size:10pt;height:15pt;padding:2px;margin:0px;">
					   CodigoCupon
				</mat-card>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.CodigoCupon}} </td>
		  </ng-container>
		  <ng-container matColumnDef="NumeroAlbaranPrecursor">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-card style="width:100%;font-size:10pt;height:15pt;padding:2px;margin:0px;">
					   NumeroAlbaranPrecursor
				</mat-card>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.NumeroAlbaranPrecursor}} </td>
		  </ng-container>
		  <ng-container matColumnDef="NumeroEnvioRetorno">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-card style="width:100%;font-size:10pt;height:15pt;padding:2px;margin:0px;">
					   NumeroEnvioRetorno
				</mat-card>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.NumeroEnvioRetorno}} </td>
		  </ng-container>
		  <ng-container matColumnDef="TipoGestion">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-card style="width:100%;font-size:10pt;height:15pt;padding:2px;margin:0px;">
					   TipoGestion
				</mat-card>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.TipoGestion}} </td>
		  </ng-container>
		  <ng-container matColumnDef="TieneRetorno">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-card style="width:100%;font-size:10pt;height:15pt;padding:2px;margin:0px;">
					   TieneRetorno
				</mat-card>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.TieneRetorno}} </td>
		  </ng-container>
		  <ng-container matColumnDef="ConfirmacionInmediata">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-card style="width:100%;font-size:10pt;height:15pt;padding:2px;margin:0px;">
					   ConfirmacionInmediata
				</mat-card>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.ConfirmacionInmediata}} </td>
		  </ng-container>
		  <ng-container matColumnDef="EntregaSabado">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-card style="width:100%;font-size:10pt;height:15pt;padding:2px;margin:0px;">
					   EntregaSabado
				</mat-card>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.EntregaSabado}} </td>
		  </ng-container>
		  <ng-container matColumnDef="TipoRetorno">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-card style="width:100%;font-size:10pt;height:15pt;padding:2px;margin:0px;">
					   TipoRetorno
				</mat-card>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.TipoRetorno}} </td>
		  </ng-container>
		  <ng-container matColumnDef="Usuario">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-card style="width:100%;font-size:10pt;height:15pt;padding:2px;margin:0px;">
					   Usuario
				</mat-card>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.Usuario}} </td>
		  </ng-container>
		  <ng-container matColumnDef="UsuarioModificacion">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-card style="width:100%;font-size:10pt;height:15pt;padding:2px;margin:0px;">
					   UsuarioModificacion
				</mat-card>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.UsuarioModificacion}} </td>
		  </ng-container>
		  <ng-container matColumnDef="ReferenciaCliente">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-card style="width:100%;font-size:10pt;height:15pt;padding:2px;margin:0px;">
					   ReferenciaCliente
				</mat-card>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.ReferenciaCliente}} </td>
		  </ng-container>
		  <ng-container matColumnDef="EsInternacional">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-card style="width:100%;font-size:10pt;height:15pt;padding:2px;margin:0px;">
					   EsInternacional
				</mat-card>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.EsInternacional}} </td>
		  </ng-container>
		  <ng-container matColumnDef="GeneradoPor">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-card style="width:100%;font-size:10pt;height:15pt;padding:2px;margin:0px;">
					   GeneradoPor
				</mat-card>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.GeneradoPor}} </td>
		  </ng-container>
		  <ng-container matColumnDef="Aviso">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-card style="width:100%;font-size:10pt;height:15pt;padding:2px;margin:0px;">
					   Aviso
				</mat-card>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.Aviso}} </td>
		  </ng-container>
		  <ng-container matColumnDef="TotalMedidas">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-card style="width:100%;font-size:10pt;height:15pt;padding:2px;margin:0px;">
					   TotalMedidas
				</mat-card>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.TotalMedidas}} </td>
		  </ng-container>
		  <ng-container matColumnDef="Operacion">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-card style="width:100%;font-size:10pt;height:15pt;padding:2px;margin:0px;">
					   Operacion
				</mat-card>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.Operacion}} </td>
		  </ng-container>
		  <ng-container matColumnDef="Frecuencia">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-card style="width:100%;font-size:10pt;height:15pt;padding:2px;margin:0px;">
					   Frecuencia
				</mat-card>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.Frecuencia}} </td>
		  </ng-container>
		  <ng-container matColumnDef="Contador">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-card style="width:100%;font-size:10pt;height:15pt;padding:2px;margin:0px;">
					   Contador
				</mat-card>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.Contador}} </td>
		  </ng-container>
		  <ng-container matColumnDef="NifRemitente">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-card style="width:100%;font-size:10pt;height:15pt;padding:2px;margin:0px;">
					   NifRemitente
				</mat-card>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.NifRemitente}} </td>
		  </ng-container>
		  <ng-container matColumnDef="NifDestinatario">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-card style="width:100%;font-size:10pt;height:15pt;padding:2px;margin:0px;">
					   NifDestinatario
				</mat-card>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.NifDestinatario}} </td>
		  </ng-container>
		  <ng-container matColumnDef="MensajeroEntrega">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-card style="width:100%;font-size:10pt;height:15pt;padding:2px;margin:0px;">
					   MensajeroEntrega
				</mat-card>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.MensajeroEntrega}} </td>
		  </ng-container>
		  <ng-container matColumnDef="MensajeroRecogida">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-card style="width:100%;font-size:10pt;height:15pt;padding:2px;margin:0px;">
					   MensajeroRecogida
				</mat-card>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.MensajeroRecogida}} </td>
		  </ng-container>
		  <ng-container matColumnDef="PuntoRecogida">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-card style="width:100%;font-size:10pt;height:15pt;padding:2px;margin:0px;">
					   PuntoRecogida
				</mat-card>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.PuntoRecogida}} </td>
		  </ng-container>
		  <ng-container matColumnDef="PuntoEntrega">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-card style="width:100%;font-size:10pt;height:15pt;padding:2px;margin:0px;">
					   PuntoEntrega
				</mat-card>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.PuntoEntrega}} </td>
		  </ng-container>
		  <ng-container matColumnDef="PuntoAnomalia">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-card style="width:100%;font-size:10pt;height:15pt;padding:2px;margin:0px;">
					   PuntoAnomalia
				</mat-card>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.PuntoAnomalia}} </td>
		  </ng-container>
		  <ng-container matColumnDef="PuntoEstado">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-card style="width:100%;font-size:10pt;height:15pt;padding:2px;margin:0px;">
					   PuntoEstado
				</mat-card>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.PuntoEstado}} </td>
		  </ng-container>
		  <ng-container matColumnDef="PuntoConcertacion">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-card style="width:100%;font-size:10pt;height:15pt;padding:2px;margin:0px;">
					   PuntoConcertacion
				</mat-card>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.PuntoConcertacion}} </td>
		  </ng-container>
		  <ng-container matColumnDef="TipoPuntoConcertacion">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-card style="width:100%;font-size:10pt;height:15pt;padding:2px;margin:0px;">
					   TipoPuntoConcertacion
				</mat-card>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.TipoPuntoConcertacion}} </td>
		  </ng-container>
		  <ng-container matColumnDef="albaranrd">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-card style="width:100%;font-size:10pt;height:15pt;padding:2px;margin:0px;">
					   Albaran reex/dev/ret
				</mat-card>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.albaranrd}} </td>
		  </ng-container>
		  <ng-container matColumnDef="fechacobrado">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-card style="width:100%;font-size:10pt;height:15pt;padding:2px;margin:0px;">
					Fecha Cobro
			 </mat-card>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.fechacobrado}} </td>
		  </ng-container>
		  <ng-container matColumnDef="usuariocobro">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-card style="width:100%;font-size:10pt;height:15pt;padding:2px;margin:0px;">
					   Usuario Cobro
				</mat-card>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.usuariocobro}} </td>
		  </ng-container>
		  <ng-container matColumnDef="gestionado">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field appearance="outline" style="text-align:center;width:100%;font-size:8pt">
				  <mat-label style="font-size:11pt;color:black;">Gestionado</mat-label>
				  <mat-select [(value)]="filtros['gestionado']" (selectionChange)="onselectfiltro('gestionado', $event.value)">
				    <mat-option [value]="gest" *ngFor="let gest of listasfiltros.listagestionados">{{gest}}</mat-option>
				  </mat-select>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.gestionado}} </td>
		  </ng-container>
		  <ng-container matColumnDef="notas">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-card style="width:100%;font-size:10pt;height:15pt;padding:2px;margin:0px;">
					   Notas
				</mat-card>
		    </th>
		    <td mat-cell *matCellDef="let element"style="max-width:200px;"> {{element.notas}} </td>
		  </ng-container>
	
		  <tr mat-header-row *matHeaderRowDef="identity.options.ordencamposreembolsos.direcciones; sticky:true"></tr>
		  <tr mat-row (click)="seleccionar(row)" class="celdas" [class.seleccionada]="seleccion == row" *matRowDef="let row; columns: identity.options.ordencamposreembolsos.direcciones;">
		  </tr>
		</table>
	</div>
	<div style="display:inline-flex;align-items:center;">
		<span style="font-size:10pt;margin-left:15px;color:black;">Seleccionadas: {{selection.selected.length}}</span>
		<mat-form-field *ngIf="identity" style="width:35px;margin-left:20px;margin-right:50px;">
			<mat-select [(ngModel)]="identity.options.ordencamposreembolsos.direcciones" (selectionChange)="onselectcolumnastotales($event.value)" multiple>
			<mat-select-trigger class="fondooscuro">
				<mat-icon>view_list</mat-icon>
			</mat-select-trigger>
			<mat-option *ngFor="let campo of totalcolumns" [value]="campo">{{campo}}</mat-option>
			</mat-select>
		</mat-form-field>
		<mat-paginator *ngIf="dataSource.data" style="color:black;" showFirstLastButtons [length]="dataSource.data.length" [pageSize]="50" [pageSizeOptions]="[5, 10, 25, 50, 100]">
		</mat-paginator>
	</div>
