<div class="msgnew" *ngIf="mensajenuevo && !verpendientes">
    <div class="botonesnuevo">
        <div>
            <button mat-raised-button style="margin-right:20px;cursor:pointer;" matTooltip="Enviar mensaje" (click)="enviarmensaje()" [disabled]="!destinatarios || destinatarios.length <=0">
                Enviar
            </button>
        </div>
        <div>
            <button mat-raised-button style="margin-right:20px;cursor:pointer;" matTooltip="Ver Peticiones Pendientes" (click)="verpendientes = true" [disabled]="!peticionespendientes || peticionespendientes.data.length <=0">
                Masivos Pendientes
            </button>
        </div>
        <span class="spacer"></span>
        <div *ngIf="!peticioncargada">
            <button mat-raised-button style="margin-right:20px;cursor:pointer;" matTooltip="Agregar Destinatarios" (click)="abredestinatarios()" [disabled]="!abonadosg3 || !abonadosplataforma">
                Destinatarios
            </button>
        </div>
        <div *ngIf="peticioncargada">
            <button mat-raised-button style="margin-right:20px;cursor:pointer;" matTooltip="Agregar Destinatarios" (click)="abredestinatariospeticion()">
                Destinatarios
            </button>
        </div>   
        <div style="margin-left: 10px;">
            <button mat-raised-button>
                <label for="file-upload" style="cursor:pointer;">
                    <mat-icon>folder_open</mat-icon>Adjuntar..
                </label>
            </button>
            <input id="file-upload" multiple="true" (change)="fileChangeEvent($event)" type="file" style='display: none;'/>
        </div>
    </div>
    <div class="cabeceranuevo">
        <mat-form-field style="width:100%;text-align:center;font-size:12pt;font-weight:700;margin-bottom:0">
            <mat-label>Asunto</mat-label>
              <input matInput [(ngModel)]="mensajenuevo.message.subject" placeholder="Asunto">
        </mat-form-field>
    </div>
    <div *ngIf="mensajenuevo.message.attachments && mensajenuevo.message.attachments.length >=1" style="width:100%;display:inline-flex;padding-bottom:5px;border-bottom: 1px solid rgba(0, 0, 0, 0.158);">
        <div style="margin-right:20px;">
            Adjuntos
        </div>
        <mat-chip-list #chipadjuntos style="width:100%;"> 
            <mat-chip *ngFor="let archivo of mensajenuevo.message.attachments; let indice = index" [removable]='true' (removed)="eliminarAdjunto(indice)">{{archivo.name}}<mat-icon matChipRemove>cancel</mat-icon></mat-chip>
        </mat-chip-list>
    </div>
    <div class="contieneeditor">
        <ckeditor [editor]="Editor" [config]="{ 
            toolbar: {
                items: [
                    'heading',
                    '|',
                    'undo',
                    'redo',
                    '|',
                    'bold',
                    'italic',
                    'fontColor',
                    'fontSize',
                    'fontFamily',
                    '|',
                    'alignment',
                    'bulletedList',
                    'numberedList',
                    'highlight',
                    '|',
                    'imageUpload',
                    'insertTable',
                    '|',
                    'horizontalLine',
                ]
            },
            language: 'es',
            image: {
                styles: [
                    'full', 'side', 'alignLeft', 'alignCenter', 'alignRight'
                ],
                toolbar: [
                    'imageStyle:full',
                    'imageStyle:side',
                    '|',
                    'imageStyle:alignLeft',
                    'imageStyle:alignCenter',
                    'imageStyle:alignRight',
                    '|',
                    'ImageResize',
                    'imageTextAlternative',
                ]
            },
            table: {
                contentToolbar: [
                    'tableColumn',
                    'tableRow',
                    'mergeTableCells',
                    'tableCellProperties',
                    'tableProperties'
                ]
            }
         }" [(ngModel)]="mensajenuevo.message.body.content">
        </ckeditor>
    </div>
</div>

<div class="msgnew" *ngIf="verpendientes && peticionespendientes">
    <div class="botonesnuevo">
        <h2>Tienes peticiones Pendientes..</h2>
    </div>
    <div class="botonesnuevo">
        <div>
            <button mat-raised-button style="margin-right:20px;cursor:pointer;" matTooltip="volver" (click)="verpendientes = false">
                Volver
            </button>
        </div>
    </div>
    <div class="listadestinatarios">
        <table mat-table [dataSource]="peticionespendientes" class="mat-elevation-z8 demo-table tabla">
            <ng-container *ngFor="let column of columns" [matColumnDef]="column">
              <th mat-header-cell *matHeaderCellDef>
                {{column}}
              </th>
              <td mat-cell *matCellDef="let element">
                {{element[column]}}
              </td>
            </ng-container>
            <ng-container matColumnDef="Eliminar">
                <th mat-header-cell *matHeaderCellDef="Eliminar">
                    Eliminar
                </th>
                <td mat-cell *matCellDef="let row">
                    <button mat-button mat-icon-button aria-label="Clear" (click)="eliminapeticion(row.id)">
                        <mat-icon>close</mat-icon>
                    </button>
                </td>
            </ng-container>
            <ng-container matColumnDef="disclaimer">
                <td mat-footer-cell *matFooterCellDef colspan="3">
                  {{peticionespendientes.data.length}} Peticiones
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="columns; sticky:true"></tr>
            <tr mat-row *matRowDef="let row; columns: columns;" (dblclick)="abrepeticion(row.id)"></tr>
            <tr mat-footer-row *matFooterRowDef="['disclaimer'];sticky:true"></tr>
          </table>          
    </div>
</div>
<div *ngIf="procesoenviado" style="position:absolute;top:100px;right:10px;width:70%;display:inline-flex">
    <mat-form-field appearance="fill" style="width:100%;">
        <mat-label>proceso envio</mat-label>
        <textarea matInput [value]="textoprocesoenvio" rows="30"></textarea>
    </mat-form-field>
    <button mat-button mat-icon-button aria-label="Clear" (click)="procesoenviado = false">
        <mat-icon>close</mat-icon>
    </button>
</div>