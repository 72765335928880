import { Component, OnInit, Input } from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import { ModificadoService } from '../../servicios/modificados.service';

@Component({
  selector: 'modificados',
  templateUrl: './modificados.component.html',
  styleUrls: ['./modificados.component.css'],
  providers: [ModificadoService],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class ModificadosComponent implements OnInit {
  @Input() incidencias = new Array<any>();
  public expandedElement:any = null;
  public columns = [
    { nombre: 'FechaEnvio',
      mostrar: 'Fecha'},
    { nombre: 'NumAlbaran',
      mostrar: 'Albaran'},
    { nombre: 'Remitente',
      mostrar: 'Remitente'},    
    { nombre: 'DireccionRemitente',
      mostrar: 'Dir Recogida'},
    { nombre: 'Destinatario',
      mostrar: 'Destinatario'},
    { nombre: 'DireccionDestinatario',
      mostrar: 'Dir Destinatario'},
    { nombre: 'Servicio',
      mostrar: 'Servicio'}
  ];

  public displayedColumns = this.columns.map(colum=>colum.nombre);

  constructor(
    private _modificadoService: ModificadoService
  ) { }

  ngOnInit(): void {

  }

  getcolumnas(){

  }

  borramodificado(envio){
    this._modificadoService.borrar(envio).subscribe(
      response=>{
        console.log(response);
      }
    )
  }

  logear(row){
    console.log(this.expandedElement);
    console.log(row);
  }

}
