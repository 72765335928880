<div class="contenidoincidencias">
	<div class="tablafacturas">
		<div class="facturas">
            <mat-progress-bar *ngIf="!load" mode="indeterminate"></mat-progress-bar>
			<table mat-table *ngIf="dataSource && identity" [dataSource]="dataSource" matSort>
                <ng-container *ngFor="let column of columns" [matColumnDef]="column.columnDef">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                      {{column.header}}
                    </th>
                    <td mat-cell *matCellDef="let element">
                      {{element[column.columnDef]}} <span *ngIf="element[column.columnDef] == 'Totalano'"> €</span>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="disclaimer">
                      <td mat-footer-cell *matFooterCellDef colspan="3">
                        {{dataSource.data.length}} Clientes         {{(importetotal()).toFixed(2)}} € ({{((importetotal())/10).toFixed(2)}} €)
                      </td>
                  </ng-container>
                  <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="seleccionar(row)" (dblclick)="abreabonadog3(row.datosAbonado.Id)" class="celdas" [class.seleccionada]="clienteseleccionado.Abonado == row.Abonado"></tr>
                  <tr mat-footer-row *matFooterRowDef="['disclaimer'];sticky:true"></tr>
			</table>
		</div>
	</div>
	<div *ngIf="clienteseleccionado" class="datosabonado">
        <div class="infoabonado">
            <div class="lineaabonado">
                <mat-form-field style="width:10%;font-size:9pt;color:black;">
                    <mat-label>Abonado</mat-label>
                    <input style="color:black;" matInput [value]="clienteseleccionado.Abonado" disabled>
                </mat-form-field>
                <mat-form-field style="width:60%;font-size:9pt;color:black;">
                    <mat-label>Nombre</mat-label>
                    <input style="color:black;" matInput [value]="clienteseleccionado.Descripcion" disabled>
                </mat-form-field>
                <mat-form-field style="width:15%;font-size:9pt;color:black;margin-left:10px;">
                    <mat-label>Nif</mat-label>
                    <input style="color:black;" matInput [value]="clienteseleccionado.datosAbonado.IdFiscal" disabled>
                </mat-form-field>
            </div>
            <div class="lineaabonado">
                <mat-form-field style="width:45%;font-size:12pt;color:black;">
                    <mat-label>Email</mat-label>
                    <input style="color:black;" matInput [value]="clienteseleccionado.datosAbonado.Email" disabled>
                </mat-form-field>
                <mat-form-field style="width:15%;font-size:12pt;color:black;margin-left:10px;">
                    <mat-label>Telefono</mat-label>
                    <input style="color:black;" matInput [value]="clienteseleccionado.datosAbonado.Telefono" disabled>
                </mat-form-field>
                <mat-form-field style="width:20%;font-size:12pt;color:black;margin-left:10px;">
                    <mat-label>Contacto</mat-label>
                    <input style="color:black;" matInput [value]="clienteseleccionado.datosAbonado.Contacto" disabled>
                </mat-form-field>
            </div>
            <div class="lineaabonado">
                <mat-form-field style="max-height:100px;width:100%;font-size:9pt;">
                    <mat-label>Notas Privadas Abonado</mat-label>
                    <textarea matInput [value]="clienteseleccionado.datosAbonadoEdit.InformacionPrivada" rows="3"></textarea>
                </mat-form-field>
            </div>
            <div class="lineaabonado">
                <ul style="list-style:none;" *ngFor="let direccion of clienteseleccionado.direcciones">
                    <li *ngIf="direccion.Predeterminada">
                        {{direccion.Direccion}}
                    </li>
                </ul>
            </div>
        </div>
        <div *ngIf="clienteseleccionado && facturasclienteseleccionado" class="barrafacturascliente">
            <ul *ngFor="let ano of facturasclienteseleccionado.anos;let index" style="list-style: none;">
                <li>
                    <ul class="horizontallist">
                        <li style="width:50px;">
                            {{ano}}
                        </li>
                        <li>
                            <div>
                                Enero         
                            </div>
                            <div [ngClass]="importemes('01', ano) > importemes('01', ano-1) ? 'mayor' : 'menor'">
                                {{(importemes('01',ano)).toFixed(2)}} €
                            </div>
                        </li>
                        <li>
                            <div>
                                Febrero         
                            </div>
                            <div [ngClass]="importemes('02', ano) > importemes('02', ano-1) ? 'mayor' : 'menor'">
                                {{(importemes('02',ano)).toFixed(2)}} €
                            </div>
                        </li>
                        <li>
                            <div>
                                Marzo         
                            </div>
                            <div [ngClass]="importemes('03', ano) > importemes('03', ano-1) ? 'mayor' : 'menor'">
                                {{(importemes('03',ano)).toFixed(2)}} €
                            </div>
                        </li>
                        <li>
                            <div>
                                Abril         
                            </div>
                            <div [ngClass]="importemes('04', ano) > importemes('04', ano-1) ? 'mayor' : 'menor'">
                                {{(importemes('04',ano)).toFixed(2)}} €
                            </div>
                        </li>
                        <li>
                            <div>
                                Mayo         
                            </div>
                            <div [ngClass]="importemes('05', ano) > importemes('05', ano-1) ? 'mayor' : 'menor'">
                                {{(importemes('05',ano)).toFixed(2)}} €
                            </div>
                        </li>
                        <li>
                            <div>
                                Junio         
                            </div>
                            <div [ngClass]="importemes('06', ano) > importemes('06', ano-1) ? 'mayor' : 'menor'">
                                {{(importemes('06',ano)).toFixed(2)}} €
                            </div>
                        </li>
                        <li>
                            <div>
                                Julio         
                            </div>
                            <div [ngClass]="importemes('07', ano) > importemes('07', ano-1) ? 'mayor' : 'menor'">
                                {{(importemes('07',ano)).toFixed(2)}} €
                            </div>
                        </li>
                        <li>
                            <div>
                                Agosto         
                            </div>
                            <div [ngClass]="importemes('08', ano) > importemes('08', ano-1) ? 'mayor' : 'menor'">
                                {{(importemes('08',ano)).toFixed(2)}} €
                            </div>
                        </li>
                        <li>
                            <div>
                                Septiembre         
                            </div>
                            <div [ngClass]="importemes('09', ano) > importemes('09', ano-1) ? 'mayor' : 'menor'">
                                {{(importemes('09',ano)).toFixed(2)}} €
                            </div>
                        </li>
                        <li>
                            <div>
                                Octubre         
                            </div>
                            <div [ngClass]="importemes('10', ano) > importemes('10', ano-1) ? 'mayor' : 'menor'">
                                {{(importemes('10',ano)).toFixed(2)}} €
                            </div>
                        </li>
                        <li>
                            <div>
                                Noviembre         
                            </div>
                            <div [ngClass]="importemes('11', ano) > importemes('11', ano-1) ? 'mayor' : 'menor'">
                                {{(importemes('11',ano)).toFixed(2)}} €
                            </div>
                        </li>
                        <li>
                            <div>
                                Diciembre         
                            </div>
                            <div [ngClass]="importemes('12', ano) > importemes('12', ano-1) ? 'mayor' : 'menor'">
                                {{(importemes('12',ano)).toFixed(2)}} €
                            </div>
                        </li>
                        <li style="width:120px;">
                            <div>
                                Total         
                            </div>
                            <div [ngClass]="importeano(ano) > importeano(ano-1) ? 'mayor' : 'menor'">
                                {{(importeano(ano)).toFixed(2)}} €
                            </div>
                        </li>
                    </ul>
                </li>
            </ul>                         
        </div>  
</div>	
