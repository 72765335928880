import { Component, OnInit, ViewChild } from '@angular/core';
import { ModificadoService } from '../../servicios/modificados.service';
import { IncidenciasService } from '../../servicios/incidencias.service';
import { Incidencia } from '../../models/incidencia';
import { MatTableDataSource } from '@angular/material/table';
import * as moment from 'moment';
import { AppService } from '../../servicios/app.service';

@Component({
  selector: 'app-gestionenvios',
  templateUrl: './gestionenvios.component.html',
  styleUrls: ['./gestionenvios.component.css'],
  providers: [ModificadoService, IncidenciasService, AppService]
})
export class GestionenviosComponent implements OnInit {
  public identity;
  public modificados;
  public modificadostotales;
  public avisomodificados_recogida;
  public avisomodificados_entrega;
  public avisomodificados_otros;
  public avisomodificados;
  public category = 'modificados_totales';
  public section;
  public tipo;
  public load = true;
  public totales = {
    totalkilos: 0,
    totalbultos: 0,
    totalenvios: 0
  }
  public envios = new MatTableDataSource<Incidencia>();
  public enviostotales;
  public estadosno = ['EnvioCancelado','RecogidaPendiente','EntregaCancelada'];
  public diahoy;

  constructor(
    private _modificadoService: ModificadoService,
    private _incidenciaService: IncidenciasService,
    private _appservice: AppService
  ) {
    this.identity = this._appservice.getIdentity();
  }

  ngOnInit(): void {
    this.getsection();
    this.getmodificados();
    this.diahoy = moment().day();
  }

  getmodificados(){
    this._modificadoService.getmodificados({}).subscribe(
      response=>{
        if(response.albaranes && response.albaranes.length >=1){
          this.modificadostotales = response.albaranes;
          this.modificados = response.albaranes;
        }else{
          this.modificados = [];
        }
      }
    )
  }

  getsection(){
    this.section = this.category.split('_')[0];
  }

  categoriaseleccionada(categoria){
    this.category = categoria;
    this.getsection();
  }

  modrecogida(){
    this.modificados = this.modificadostotales.filter(modif=>modif.tipo == 'recogida')
  }

  modentrega(){
    this.modificados = this.modificadostotales.filter(modif=>modif.tipo == 'entrega')
  }

  modotros(){
    this.modificados = this.modificadostotales.filter(modif=>modif.tipo == 'otros')
  }

  toggletot(event){
    if(event){
      this.totalesrecibidos();
    }else{
      this.mapenvios();
    }
  }

  getrecibidos(){
    this.load = false;
    //domingo = 0
    var fechahoy = moment().set({hour:6,minute:1,second:0,millisecond:0}).add(-1, 'days').toISOString()
    if(this.diahoy == 0){
      fechahoy = moment().set({hour:6,minute:1,second:0,millisecond:0}).add(-2, 'days').toISOString();
    }
    if(this.diahoy == 1){
      fechahoy = moment().set({hour:6,minute:1,second:0,millisecond:0}).add(-3, 'days').toISOString();
    }    
		var fechamanana = moment().set({hour:22,minute:1,second:0,millisecond:0}).add(-1, 'days').toISOString();
    
    var queryoptions = {
      paginacion : 'no'
    }
		var query = {
      FranquiciaDestino:'02601',
      $and:[{FechaRecogida:{$gt:fechahoy}}, {FechaRecogida:{$lt:fechamanana}}],
			AnomaliaMotivo: {$nin: ['AE0700', 'AE0699']},
      Estado:{$nin:this.estadosno}
		};
    this._incidenciaService.getincidencias(query, queryoptions).subscribe(
      response=>{
        this.enviostotales = response.incidencias.map((envio)=>{
          envio.FechaRecogida = moment(envio.FechaRecogida).format('DD/MM/YYYY');
          return envio;
        });
        this.load = true;
        this.mapenvios();
      }
    )
  }

  mapenvios(){
    this.totales = {
      totalkilos: 0,
      totalbultos: 0,
      totalenvios: 0
    }
    this.envios.data = this.enviostotales.filter((envio)=>{
      if(envio.Estado == 'TransitoInternoEntrega'){
        return true;
      }else{
        return false;
      }
    });
    this.totales.totalenvios = this.envios.data.length;
    this.envios.data.forEach((envio)=>{
      this.totales.totalbultos = this.totales.totalbultos + envio.TotalBultos;
      this.totales.totalkilos = this.totales.totalkilos + envio.Kilos;
    });
  }

  totalesrecibidos(){
    this.totales = {
      totalkilos: 0,
      totalbultos: 0,
      totalenvios: 0
    }
    this.envios.data = this.enviostotales;
    this.totales.totalenvios = this.envios.data.length;
    this.envios.data.forEach((envio)=>{
      this.totales.totalbultos = this.totales.totalbultos + envio.TotalBultos;
      this.totales.totalkilos = this.totales.totalkilos + envio.Kilos;
    });
  }

  getmanana(){
    this.load = false;
    var fechahoy= moment().set({hour:6,minute:1,second:0,millisecond:0}).toISOString();
		var fechamanana = moment().set({hour:22,minute:1,second:0,millisecond:0}).toISOString();
    if(this.diahoy == 5){
      fechamanana = moment().set({hour:22,minute:1,second:0,millisecond:0}).add(2, 'days').toISOString();
    }
    if(this.diahoy == 6){
      fechamanana = moment().set({hour:22,minute:1,second:0,millisecond:0}).add(1, 'days').toISOString();
    }     
    var queryoptions = {
      paginacion : 'no'
    }
		var query = {
      FranquiciaDestino:'02601',
      $and:[{FechaRecogida:{$gt:fechahoy}}, {FechaRecogida:{$lt:fechamanana}}],
			//AnomaliaMotivo: {$nin: ['AE0700', 'AE0699']},
      Estado:{$nin:this.estadosno}
		};
    this._incidenciaService.getincidencias(query, queryoptions).subscribe(
      response=>{
        this.enviostotales = response.incidencias.map((envio)=>{
          envio.FechaRecogida = moment(envio.FechaRecogida).format('DD/MM/YYYY');
          return envio;
        });
        this.load = true;
        this.totales = {
          totalkilos: 0,
          totalbultos: 0,
          totalenvios: 0
        }
        this.envios.data = this.enviostotales;
        this.totales.totalenvios = this.envios.data.length;
        this.envios.data.forEach((envio)=>{
          this.totales.totalbultos = this.totales.totalbultos + envio.TotalBultos;
          this.totales.totalkilos = this.totales.totalkilos + envio.Kilos;
        });
      }
    )
  }
  
}
