<div class="barraseleccion">
  <div>
    <mat-form-field style="margin-left:50px;width:250px;font-size:10pt;">
      <mat-label><b>Buscar</b></mat-label>
      <input matInput (keyup)="applyFilter($event)" placeholder="buscar texto, albaran, referencia.." [(ngModel)]="filtro" style="padding:1px;color:black;">
      <button mat-button *ngIf="filtro" matSuffix mat-icon-button aria-label="Clear" (click)="dataSource.filter = ''; filtro=''">
          <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
  </div>  
  <div *ngIf="seleccion && selection.selected.length <= 0" class="botonbarra" style="height:30px;">
      {{seleccion.NumAlbaran}}
  </div>
  <div *ngIf="urlseguimientomrw && seguimiento" class="botonbarra" style="height:30px;">
    <mat-icon class="material-icons-outlined" matTooltip="Volver" (click)="seguimiento = false">keyboard_return</mat-icon>
  </div>
  <div *ngIf="seleccion" class="botonbarra" style="height:30px;">
    <mat-icon class="material-icons-outlined" matTooltip="Seguimiento" (click)="verseguimiento()">travel_explore</mat-icon>
  </div>
  <div *ngIf="seleccion && selection.selected.length <= 0 && gestionable" class="botonbarra" style="height:30px;">
      <mat-icon class="material-icons-outlined" matTooltip="Gestionar Anomalia" (click)="gestionanomalia(seleccion)">announcement</mat-icon>
  </div>
</div>

<div class="contenidoincidencias">
	<mat-progress-bar style="width:100%" *ngIf="!load" mode="indeterminate"></mat-progress-bar>
    <div *ngIf="urlseguimientomrw && seguimiento">
      <iframe [src]="urlseguimientomrw" width=100% height=500 frameborder=1 scrolling=auto></iframe>
    </div>
    <table *ngIf="load && !seguimiento" mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">
        <ng-container matColumnDef="select" class="cabeceras">
		    <th mat-header-cell cdkDrag *matHeaderCellDef class="cabeceras">
		      <mat-checkbox color="primary" (change)="$event ? masterToggle() : null"
		                    [checked]="selection.hasValue() && isAllSelected()"
		                    [indeterminate]="selection.hasValue() && !isAllSelected()"
		                    [aria-label]="checkboxLabel()">
		      </mat-checkbox>
		    </th>
		    <td mat-cell *matCellDef="let row">
		      <mat-checkbox color="primary" (click)="$event.stopPropagation()"
		                    (change)="$event ? selection.toggle(row) : null"
		                    [checked]="selection.isSelected(row)"
		                    [aria-label]="checkboxLabel(row)">
		      </mat-checkbox>
		    </td>
		  </ng-container>

        <ng-container matColumnDef="Albaran">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Albaran </th>
          <td mat-cell *matCellDef="let row"> {{row.NumAlbaran}} </td>
        </ng-container>
    
        <ng-container matColumnDef="AnomaliaMotivo">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Motivo Anomalia </th>
          <td mat-cell *matCellDef="let row"> {{humanizemotivo(row.AnomaliaMotivo)}} </td>
        </ng-container>
     
        <ng-container matColumnDef="AnomaliaObservaciones">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Observaciones Anomalia </th>
          <td mat-cell *matCellDef="let row"> {{row.AnomaliaObservaciones}} </td>
        </ng-container>
  
        <ng-container matColumnDef="CpDest">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Cp Destino </th>
          <td mat-cell *matCellDef="let row"> {{row.CpDestinatario}} </td>
        </ng-container>

        <ng-container matColumnDef="Estado">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Estado </th>
            <td mat-cell *matCellDef="let row"> {{row.Estado}} </td>
        </ng-container>

        <ng-container matColumnDef="Destinatario">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Destinatario </th>
            <td mat-cell *matCellDef="let row"> {{row.Destinatario}} </td>
        </ng-container>

        <ng-container matColumnDef="DireccionDestinatario">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Direccion Destinatario </th>
            <td mat-cell *matCellDef="let row"> {{row.DireccionDestinatario}} </td>
        </ng-container>

        <ng-container matColumnDef="PoblacionDestinatario">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Poblacion Destino </th>
            <td mat-cell *matCellDef="let row"> {{row.PoblacionDestinatario}} </td>
        </ng-container>

        <ng-container matColumnDef="Motivo">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Motivo </th>
            <td mat-cell *matCellDef="let row"> {{humanizemotivo(row.Motivo)}} </td>
        </ng-container>

        <ng-container matColumnDef="FechaRecogida">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha </th>
            <td mat-cell *matCellDef="let row"> {{row.FechaRecogida | date:'dd/MM/yyyy'}} </td>
        </ng-container>

        <ng-container matColumnDef="Referencia">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Referencia </th>
            <td mat-cell *matCellDef="let row"> {{row.ReferenciaCliente}} </td>
        </ng-container>

        <ng-container matColumnDef="Kilos">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Kilos </th>
            <td mat-cell *matCellDef="let row"> {{row.Kilos}} </td>
        </ng-container>

        <ng-container matColumnDef="Bultos">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Bultos </th>
            <td mat-cell *matCellDef="let row"> {{row.TotalBultos}} </td>
        </ng-container>
    
        <tr mat-header-row *matHeaderRowDef="totalcolumns; sticky:true"></tr>
        <tr mat-row *matRowDef="let row; columns: totalcolumns;" (click)="seleccionar(row)" class="celdas" [class.seleccionada]="seleccion && seleccion.NumAlbaran == row.NumAlbaran"></tr>
    
        <!-- Row shown when there is no matching data. 
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
        </tr>-->
    </table>
</div>