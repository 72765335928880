<div style="position:relative;width:100%">
	<div class="barraprincipalincidencias">
		<div class="cajaherramientasincidencias fondooscuro" *ngIf="admin == 'si'" style="width:10%;padding-top:20px;text-align:center;align-content:center;cursor:pointer;" (click)="enrutar('admintab/Gestiondeudas')">
			<mat-icon>arrow_back</mat-icon><br>
			Volver
		</div>
		<div class="cajaherramientasincidencias fondooscuro" style="width:10%;padding-top:20px;text-align:center;align-content:center;cursor:pointer;" (click)="abrenuevadeuda()">
			<mat-icon style="font-size:25pt">request_quote</mat-icon><br>
			Nueva Deuda
		</div>
		<div *ngIf="selection.selected.length >= 1 && !filtros['Fechacobro'] && !filtros['Fechadeudas']" class="cajaherramientasincidencias" style="width:10%;padding-left:10px;text-align:center;align-content:center;font-size:20px;">
			<span style="padding:4px;margin-bottom:2px;">Total a Cobrar</span><br><br>
			<span style="padding:4px;border:0.2px solid black;font-size:30px;"><b>{{totalimporteseleccion.toFixed(2)}}</b> <mat-icon style="margin-left:4px;font-size:smaller">euro_symbol</mat-icon></span>
		</div>
		<div *ngIf="selection.selected.length >= 1 && !filtros['Fechacobro'] && !filtros['Fechadeudas']" style="width:5%;border-right: 0.5px solid rgba(59,90,130,0.30);padding-bottom:2px;padding-left:5px;padding-right:5px;" >
			<div class="fondooscuro" style="width:100%;margin-top:20px;cursor:pointer;text-align:center;align-content:center;" (click)="cobrar()">
				<mat-icon style="font-size:25pt">point_of_sale</mat-icon><br>Cobrar
			</div>
		</div>
		<div *ngIf="selection.selected.length >= 1 && filtros['Fechacobro']" class="cajaherramientasincidencias" style="width:10%;padding-left:10px;text-align:center;align-content:center;font-size:20px;">
			<span style="padding:4px;margin-bottom:2px;">Total a Desmarcar</span><br><br>
			<span style="padding:4px;border:0.2px solid black;font-size:30px;"><b>{{totalimporteseleccion.toFixed(2)}}</b> <mat-icon style="margin-left:4px;font-size:smaller">euro_symbol</mat-icon></span>
		</div>
		<div *ngIf="selection.selected.length >= 1 && filtros['Fechacobro']" style="width:5%;border-right: 0.5px solid rgba(59,90,130,0.30);padding-bottom:2px;padding-left:5px;padding-right:5px;" >
			<div class="fondooscuro" style="width:100%;margin-top:20px;cursor:pointer;text-align:center;align-content:center;" (click)="desmarcar()">
				<mat-icon style="font-size:25pt">point_of_sale</mat-icon><br>Desmarcar
			</div>
		</div>
		<div *ngIf="selection.selected.length >= 1 && filtros['Fechadeudas']" class="cajaherramientasincidencias" style="width:10%;padding-left:10px;text-align:center;align-content:center;font-size:20px;">
			<span style="padding:4px;margin-bottom:2px;">Total a Desmarcar</span><br><br>
			<span style="padding:4px;border:0.2px solid black;font-size:30px;"><b>{{totalimporteseleccion.toFixed(2)}}</b> <mat-icon style="margin-left:4px;font-size:smaller">euro_symbol</mat-icon></span>
		</div>
		<div *ngIf="selection.selected.length >= 1 && filtros['Fechadeudas']" style="width:5%;border-right: 0.5px solid rgba(59,90,130,0.30);padding-bottom:2px;padding-left:5px;padding-right:5px;" >
			<div class="fondooscuro" style="width:100%;margin-top:20px;cursor:pointer;text-align:center;align-content:center;" (click)="desmarcar()">
				<mat-icon style="font-size:25pt">point_of_sale</mat-icon><br>Desmarcar
			</div>
		</div>
		<div *ngIf="totalimporte" class="cajaherramientasincidencias" style="width:10%;padding-left:10px;text-align:center;align-content:center;font-size:20px;">
			<span *ngIf="!filtros['Fechacobro'] && !filtros['Fechadeudas']" style="padding:4px;margin-bottom:2px;">Total Debe</span>
			<span *ngIf="filtros['Fechadeudas']" style="padding:4px;margin-bottom:2px;">Total Dia</span>
			<span *ngIf="filtros['Fechacobro']" style="padding:4px;margin-bottom:2px;">Total Pagado</span><br><br>
			<span style="padding:4px;border:0.2px solid black;font-size:30px;"><b>{{totalimporte.toFixed(2)}}</b> <mat-icon style="margin-left:4px;font-size:smaller">euro_symbol</mat-icon></span>
		</div>
		<div *ngIf="mensajero && filtros['Fechacobro']" class="cajaherramientasincidenciasdoble">
			<div style="width:100%;"><b>
				<span style="width:100%;font-size:15pt;">Fecha Cobro</span><br>
				<span style="width:100%;font-size:15pt;">{{filtros.Fechacobro | date:'dd/MM/yyyy'}}</span>
			</b></div>	
		</div>
		<div *ngIf="mensajero && filtros['Fechadeudas']" class="cajaherramientasincidenciasdoble">
			<div style="width:100%;"><b>
				<span style="width:100%;font-size:15pt;">Fecha Deudas</span><br>
				<span style="width:100%;font-size:15pt;">{{filtros.Fechadeudas | date:'dd/MM/yyyy'}}</span>
			</b></div>	
		</div>
	</div>
	<div class="contenidoincidencias">
		<table mat-table *ngIf="dataSource && mensajero" [dataSource]="dataSource" matSort>
		  <ng-container matColumnDef="select" class="cabeceras">
		    <th mat-header-cell *matHeaderCellDef class="cabeceras">
		      <mat-checkbox color="primary" (change)="$event ? masterToggle() : null"
		                    [checked]="selection.hasValue() && isAllSelected()"
		                    [indeterminate]="selection.hasValue() && !isAllSelected()"
		                    [aria-label]="checkboxLabel()">
		      </mat-checkbox>
		    </th>
		    <td mat-cell *matCellDef="let row">
		      <mat-checkbox color="primary" (click)="$event.stopPropagation()"
		                    (change)="$event ? selection.toggle(row) : null"
		                    [checked]="selection.isSelected(row)"
		                    [aria-label]="checkboxLabel(row)">
		      </mat-checkbox>
		    </td>
		  </ng-container>
		  <ng-container matColumnDef="fechadeuda">
		    <th mat-header-cell *matHeaderCellDef mat-sort-header>
			    <mat-form-field appearance="outline" style="text-align:center;width:100%;font-size:8pt">
				  <mat-label style="color:black;" (click)="picker9.open()"><b>Fecha Deuda</b></mat-label>
					<input matInput [matDatepicker]="picker9" (click)="picker9.open()" [(ngModel)]="filtros['Fechadeudas']" (dateChange)="ondatefechadeuda(filtros['Fechadeudas'])">
				  <button mat-button *ngIf="filtros['Fechadeudas']" matSuffix mat-icon-button aria-label="Clear" (click)="filtros['Fechadeudas']=undefined;getdeudaspendientes()">
				    <mat-icon>close</mat-icon>
				  </button>
				  <mat-datepicker #picker9></mat-datepicker>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.fechadeuda}} </td>
		  </ng-container>
		  <ng-container matColumnDef="importe">
		    <th mat-header-cell *matHeaderCellDef mat-sort-header>
		    	<mat-card style="width:100%;font-size:10pt;height:15pt;padding:2px;margin:0px;">
					   Importe
				</mat-card>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.importe}} </td>
		  </ng-container>

		  <ng-container matColumnDef="motivo">
		    <th mat-header-cell *matHeaderCellDef mat-sort-header>
		    	<mat-card style="width:100%;font-size:10pt;height:15pt;padding:2px;margin:0px;">
					   Motivo
				</mat-card>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.motivo}} </td>
		  </ng-container>
		  <ng-container matColumnDef="fechacobro">
		    <th mat-header-cell *matHeaderCellDef mat-sort-header>
			    <mat-form-field appearance="outline" style="text-align:center;width:100%;font-size:8pt">
				  <mat-label style="color:black;" (click)="picker.open()"><b>Fecha Cobro</b></mat-label>
					<input matInput [matDatepicker]="picker" (click)="picker.open()" [(ngModel)]="filtros['Fechacobro']" (dateChange)="ondatefechacobro(filtros['Fechacobro'])">
				  <button mat-button *ngIf="filtros['Fechacobro']" matSuffix mat-icon-button aria-label="Clear" (click)="filtros['Fechacobro']=undefined;getdeudaspendientes()">
				    <mat-icon>close</mat-icon>
				  </button>
				  <mat-datepicker #picker></mat-datepicker>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.fechacobro}} </td>
		  </ng-container>
		 
		  <tr mat-header-row *matHeaderRowDef="totalcolumns; sticky:true"></tr>
		  <tr mat-row class="celdas" *matRowDef="let row; columns: totalcolumns;" (dblclick)="abredeuda(row)">
		  </tr>
		</table>
	</div>
	<div style="display:inline-flex;align-items:center;">
		<span style="font-size:10pt;margin-left:15px;color:black;">Seleccionadas: {{selection.selected.length}}</span>
		<mat-paginator *ngIf='deudas' style="color:black;" showFirstLastButtons [length]="deudas.length" [pageSize]="50" [pageSizeOptions]="[5, 10, 25, 50, 100]">
		</mat-paginator>
	</div>
</div>
