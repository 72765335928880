import { Component, OnInit } from '@angular/core';
import { ActivatedRoute} from '@angular/router';
import { ClienteService } from '../../servicios/cliente.service';

@Component({
  selector: 'restablecerpass',
  templateUrl: './restablecerpass.component.html',
  styleUrls: ['./restablecerpass.component.css'],
  providers: [ClienteService]
})
export class RestablecerpassComponent implements OnInit {
	public title: String;
	public id: String;
  public pasword: String;
  public pasword2: String;
  public status: String;
  public mensajeerror: String;

  constructor(
  	private _route: ActivatedRoute,
    private _clienteService: ClienteService
  ) { 
  	  this.title = "Cambiar Contraseña Plataforma Mensasur Getafe S.L."
      this.pasword = "";
      this.pasword2 = "";
      this.status = "ok";
      this._route.params.subscribe(params=>{this.id = params.id;});  
  }

  ngOnInit(): void {

  }

  onSubmit(form){
    if(this.pasword != this.pasword2){
      this.status = 'error';
    }else{
      var identity = JSON.parse(sessionStorage.getItem('identity'));
      if(identity){
        this._clienteService.updatePasswordlogged(this.pasword, this.id).subscribe(
          response =>{
              if(response.message == 'contraseña actualizada'){
                this.status = "success";
                form.reset();
              }
            },
          error =>{
            this.status = "error2";
            this.mensajeerror = error.error.message;
          } 
        );
      }else{
        this._clienteService.updatePassword(this.pasword, this.id).subscribe(
          response =>{
              if(response.message == 'contraseña actualizada'){
                this.status = "success";
                form.reset();
              }
            },
          error =>{
            this.status = "error2";
            this.mensajeerror = error.error.message;
          } 
        );
      }  
    }
  }

}
