<div class="barraprincipalincidencias">
	<div class="cajaherramientasincidencias" style="width:5%;border-right: 0.5px solid rgba(59,90,130,0.30);padding:10px;">
		<button *ngIf="!verclientes" mat-button mat-icon-button matTooltip="Ver Clientes" (click)="vistatoggle()">
			<mat-icon>people_alt</mat-icon>
		</button>
		<div *ngIf="!verclientes" style="font-size:8pt;">
			Ver Clientes
		</div>
		<button *ngIf="verclientes" mat-button mat-icon-button matTooltip="Ver Facturas" (click)="vistatoggle()">
			<mat-icon>file_copy</mat-icon>
		</button>
		<div *ngIf="verclientes" style="font-size:8pt;">
			Ver Facturas
		</div>
	</div>
	<div *ngIf="selection.selected.length >= 1" class="cajaherramientasincidencias" style="width:12%;padding-left:10px;text-align:center;align-content:center;font-size:15px;">
		<div>
			<span style="padding:4px;margin-bottom:2px;">Total a Cobrar</span><br>
			<span style="padding:4px;border:0.2px solid black;font-size:30px;"><b>{{importeseleccion.toFixed(2)}}</b> <mat-icon style="margin-left:4px;font-size:smaller">euro_symbol</mat-icon></span>
		</div>
	</div>
	<div *ngIf="selection.selected.length >= 1" class="cajaherramientasincidencias" style="width:5%;border-right: 0.5px solid rgba(59,90,130,0.30);padding-bottom:2px;padding-left:5px;padding-right:5px;" >
		<div class="fondooscuro" style="width:100%;margin-top:20px;cursor:pointer;text-align:center;align-content:center;" (click)="cobrar()">
			<mat-icon style="font-size:25px;">point_of_sale</mat-icon><br>Cobrar
		</div>
	</div>
	<div *ngIf="totalpendiente" class="cajaherramientasincidencias">
		<mat-form-field appearance="outline" style="text-align:center;width:100%;font-size:20px;padding:0px">
			<mat-label style="color:black;"><b>Total Pendiente</b></mat-label>
			<input matInput type="text" [value]="totalpendiente.toFixed(2)" style="padding:2px;color:black;" disabled = "true">
			<span class="material-icons" matSuffix>euro_symbol</span>
		</mat-form-field>
	</div>
	<div class="cajaherramientasincidencias">
		<mat-form-field appearance="outline" style="text-align:center;width:100%;">
			<mat-label style="color:black;"><b>Buscar Factura</b></mat-label>
			<input matInput type="text" [(ngModel)]="filtros['NumFactura']" style="padding:1px;color:black;" (ngModelChange)="filtros['NumFactura'] = $event.toUpperCase()" (keyup.enter)="buscarFactura()">
			<button mat-button *ngIf="filtros['NumFactura']" matSuffix mat-icon-button aria-label="Clear" (click)="filtros['NumFactura']=''; getFacturas()">
				<mat-icon>close</mat-icon>
			</button>
		</mat-form-field>
	</div>
	<div class="cajaherramientasincidencias">
		<mat-form-field appearance="outline" style="text-align:center;width:100%;">
			<mat-label style="color:black;"><b>Buscar Abonado</b></mat-label>
			<input matInput type="text" [(ngModel)]="filtros['abonado']" style="padding:1px;color:black;" (ngModelChange)="filtros['abonado'] = $event.toUpperCase()" (keyup.enter)="buscarabonado($event.target.value)">
			<button mat-button *ngIf="filtros['abonado']" matSuffix mat-icon-button aria-label="Clear" (click)="filtros['abonado']=''; getFacturas()">
				<mat-icon>close</mat-icon>
			</button>
		</mat-form-field>
	</div>
	<div class="cajaherramientasincidencias">
		<mat-form-field appearance="outline" style="text-align:center;width:100%;">
		  <mat-label style="font-size:11pt;color:black;">Buscar Texto</mat-label>
		  <input matInput (keyup)="applyFilter($event)" placeholder="Texto a buscar.." [(ngModel)]="filtros['filtroprincipal']">
		   <button mat-button *ngIf="filtros['filtroprincipal']" matSuffix mat-icon-button aria-label="Clear" (click)="dataSource.filter = ''; filtros['filtroprincipal']=''">
			<mat-icon>close</mat-icon>
		   </button>
		</mat-form-field>
	</div>
	<button *ngIf="clienteseleccionado" mat-button mat-icon-button matTooltip="Cliente Anterior" (click)="clienteanterior()">
		<mat-icon>arrow_back</mat-icon>
	</button>
	<button *ngIf="clienteseleccionado" mat-button mat-icon-button matTooltip="Siguiente Cliente" (click)="siguientecliente()">
		<mat-icon>arrow_forward</mat-icon>
	</button>
</div>
<div class="contenidoincidencias">
	<div class="tablafacturas">
		<div *ngIf="!verclientes" class="facturas">
			<table mat-table *ngIf="dataSource && identity" [dataSource]="dataSource" matSort cdkDropList cdkDropListOrientation="horizontal" (cdkDropListDropped)="drop($event)">
			<ng-container matColumnDef="select" class="cabeceras">
				<th mat-header-cell cdkDrag *matHeaderCellDef class="cabeceras">
				<mat-checkbox color="primary" (change)="$event ? masterToggle() : null"
								[checked]="selection.hasValue() && isAllSelected()"
								[indeterminate]="selection.hasValue() && !isAllSelected()"
								[aria-label]="checkboxLabel()">
				</mat-checkbox>
				</th>
				<td mat-cell *matCellDef="let row">
				<mat-checkbox color="primary" (click)="$event.stopPropagation()"
								(change)="$event ? selection.toggle(row) : null"
								[checked]="selection.isSelected(row)"
								[aria-label]="checkboxLabel(row)">
				</mat-checkbox>
				</td>
			</ng-container>
			<ng-container matColumnDef="NumeroFactura">
				<th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
					<mat-card style="width:100%;font-size:10pt;height:15pt;padding:2px;margin:0px;">
						Numero Factura
					</mat-card>
				</th>
				<td mat-cell *matCellDef="let element"> {{element.NumeroFactura}} </td>
			</ng-container>
			<ng-container matColumnDef="FechaFactura">
				<th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
					<mat-card style="width:100%;font-size:10pt;height:15pt;padding:2px;margin:0px;">
						Fecha Factura
					</mat-card>
				</th>
				<td mat-cell *matCellDef="let element"> {{parsefecha(element.FechaParticion)}} </td>
			</ng-container>
			<ng-container matColumnDef="FechaVencimiento">
				<th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
					<mat-card style="width:100%;font-size:10pt;height:15pt;padding:2px;margin:0px;">
						Fecha Vencimiento
					</mat-card>
				</th>
				<td mat-cell *matCellDef="let element"> {{parsefecha(element.FechaVencimiento)}} </td>
			</ng-container>
			<ng-container matColumnDef="Nif">
				<th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
					<mat-card style="width:100%;font-size:10pt;height:15pt;padding:2px;margin:0px;">
						Nif
					</mat-card>
				</th>
				<td mat-cell *matCellDef="let element"> {{element.Nif}} </td>
			</ng-container>
			<ng-container matColumnDef="RazonSocial">
				<th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
					<mat-card style="width:100%;;font-size:10pt;height:15pt;padding:2px;margin:0px;">
						Razon Social
					</mat-card>
				</th>
				<td mat-cell *matCellDef="let element" style="max-width:200px;"> {{element.RazonSocial}} </td>
			</ng-container>
			<ng-container matColumnDef="Departamento">
				<th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
					<mat-card style="width:100%;font-size:10pt;height:15pt;padding:2px;margin:0px;">
						Departamento
					</mat-card>
				</th>
				<td mat-cell *matCellDef="let element" style="max-width:200px;"> {{element.Departamento}} </td>
			</ng-container>
			<ng-container matColumnDef="Abonado">
				<th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
					<mat-form-field appearance="outline" style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>Abonado</b></mat-label>
					<input matInput type="text" [(ngModel)]="filtros['Abonado']" style="padding:1px;color:black;"
					(keyup.enter)="onselectfiltro('Abonado', filtros['Abonado'])">
					<button mat-button *ngIf="filtros['Abonado']" matSuffix mat-icon-button aria-label="Clear" (click)="filtros['Abonado']=''; eliminafiltro('Abonado')">
						<mat-icon>close</mat-icon>
					</button>
					</mat-form-field>
				</th>
				<td mat-cell *matCellDef="let element"> {{element.Abonado}} </td>
			</ng-container>
			<ng-container matColumnDef="GrupoImpositivoCliente">
				<th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
					<mat-card style="width:100%;font-size:10pt;height:15pt;padding:2px;margin:0px;">
						Grupo Impositivo Cliente
					</mat-card>
				</th>
				<td mat-cell *matCellDef="let element"> {{element.GrupoImpositivoCliente}} </td>
			</ng-container>
			<ng-container matColumnDef="ImporteBaseImponible">
				<th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
					<mat-card style="width:100%;font-size:10pt;height:15pt;padding:2px;margin:0px;">
						ImporteBaseImponible
					</mat-card>
				</th>
				<td mat-cell *matCellDef="let element"> {{element.ImporteBaseImponible}} <mat-icon style="margin-left:4px;font-size:smaller">euro_symbol</mat-icon></td>
			</ng-container>
			<ng-container matColumnDef="ImporteExento">
				<th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
					<mat-card style="width:100%;font-size:10pt;height:15pt;padding:2px;margin:0px;">
						ImporteExento
					</mat-card>
				</th>
				<td mat-cell *matCellDef="let element"> {{element.ImporteExento}} <mat-icon style="margin-left:4px;font-size:smaller">euro_symbol</mat-icon></td>
			</ng-container>
			<ng-container matColumnDef="ImporteDescuentoCliente">
				<th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
					<mat-card style="width:100%;font-size:10pt;height:15pt;padding:2px;margin:0px;">
						ImporteDescuentoCliente
					</mat-card>
				</th>
				<td mat-cell *matCellDef="let element"> {{element.ImporteDescuentoCliente}} <mat-icon style="margin-left:4px;font-size:smaller">euro_symbol</mat-icon></td>
			</ng-container>
			<ng-container matColumnDef="ImporteRecargoCliente">
				<th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
					<mat-card style="width:100%;font-size:10pt;height:15pt;padding:2px;margin:0px;">
						ImporteRecargoCliente
					</mat-card>
				</th>
				<td mat-cell *matCellDef="let element"> {{element.ImporteRecargoCliente}} <mat-icon style="margin-left:4px;font-size:smaller">euro_symbol</mat-icon></td>
			</ng-container>
			<ng-container matColumnDef="ImporteImpuestos">
				<th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
					<mat-card style="width:100%;font-size:10pt;height:15pt;padding:2px;margin:0px;">
						ImporteImpuestos
					</mat-card>
				</th>
				<td mat-cell *matCellDef="let element"> {{element.ImporteImpuestos}} <mat-icon style="margin-left:4px;font-size:smaller">euro_symbol</mat-icon></td>
			</ng-container>
			<ng-container matColumnDef="ImporteFactura">
				<th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
					<mat-card style="width:100%;font-size:10pt;height:15pt;padding:2px;margin:0px;">
						ImporteFactura
					</mat-card>
				</th>
				<td mat-cell *matCellDef="let element"> {{element.ImporteFactura}} <mat-icon style="margin-left:4px;font-size:smaller">euro_symbol</mat-icon></td>
			</ng-container>
			<ng-container matColumnDef="Anulada">
				<th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
					<mat-card style="width:100%;font-size:10pt;height:15pt;padding:2px;margin:0px;">
						Anulada
					</mat-card>
				</th>
				<td mat-cell *matCellDef="let element"> {{element.Anulada}} </td>
			</ng-container>
			<ng-container matColumnDef="ImporteVentas">
				<th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
					<mat-card style="width:100%;font-size:10pt;height:15pt;padding:2px;margin:0px;">
						ImporteVentas
					</mat-card>
				</th>
				<td mat-cell *matCellDef="let element"> {{element.ImporteVentas}} <mat-icon style="margin-left:4px;font-size:smaller">euro_symbol</mat-icon></td>
			</ng-container>
			<ng-container matColumnDef="Abono">
				<th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
					<mat-card style="width:100%;font-size:10pt;height:15pt;padding:2px;margin:0px;">
						Abono
					</mat-card>
				</th>
				<td mat-cell *matCellDef="let element"> {{element.Abono}} </td>
			</ng-container>
			<ng-container matColumnDef="IdTipoFactura">
				<th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
					<mat-card style="width:100%;font-size:10pt;height:15pt;padding:2px;margin:0px;">
						IdTipoFactura
					</mat-card>
				</th>
				<td mat-cell *matCellDef="let element"> {{element.IdTipoFactura}} </td>
			</ng-container>
			<ng-container matColumnDef="NumeroFacturaRectificada">
				<th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header> 
					<mat-card style="width:100%;font-size:10pt;height:15pt;padding:2px;margin:0px;">
						NumeroFacturaRectificada
					</mat-card>
				</th>
				<td mat-cell *matCellDef="let element"> {{element.NumeroFacturaRectificada}} </td>
			</ng-container>
			<ng-container matColumnDef="Franquicia">
				<th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
					<mat-card style="width:100%;font-size:10pt;height:15pt;padding:2px;margin:0px;">
						Franquicia
					</mat-card>
				</th>
				<td mat-cell *matCellDef="let element"> {{element.Franquicia}} </td>
			</ng-container>
			<ng-container matColumnDef="ImporteDescuento">
				<th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
					<mat-card style="width:100%;font-size:10pt;height:15pt;padding:2px;margin:0px;">
						ImporteDescuento
					</mat-card>
				</th>
				<td mat-cell *matCellDef="let element"> {{element.ImporteDescuento}} <mat-icon style="margin-left:4px;font-size:smaller">euro_symbol</mat-icon></td>
			</ng-container>
			<ng-container matColumnDef="MotivoRectificativa">
				<th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
					<mat-card style="width:100%;font-size:10pt;height:15pt;padding:2px;margin:0px;">
						MotivoRectificativa
					</mat-card>
				</th>
				<td mat-cell *matCellDef="let element"> {{element.MotivoRectificativa}} </td>
			</ng-container>
			<ng-container matColumnDef="EsProforma">
				<th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
					<mat-card style="width:100%;font-size:10pt;height:15pt;padding:2px;margin:0px;">
						EsProforma
					</mat-card>
				</th>
				<td mat-cell *matCellDef="let element"> {{element.EsProforma}} </td>
			</ng-container>
			<ng-container matColumnDef="EsPortugal">
				<th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
					<mat-card style="width:100%;font-size:10pt;height:15pt;padding:2px;margin:0px;">
						EsPortugal
					</mat-card>
				</th>
				<td mat-cell *matCellDef="let element"> {{element.EsPortugal}} </td>
			</ng-container>
			<ng-container matColumnDef="Impreso">
				<th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
					<mat-card style="width:100%;font-size:10pt;height:15pt;padding:2px;margin:0px;">
						Impreso
					</mat-card>
				</th>
				<td mat-cell *matCellDef="let element"> {{element.Impreso}} </td>
			</ng-container>
			<ng-container matColumnDef="Cobrada">
				<th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
					<mat-card style="width:100%;font-size:10pt;height:15pt;padding:2px;margin:0px;">
						Cobrada
					</mat-card>
				</th>
				<td mat-cell *matCellDef="let element"> {{element.Cobrada}} </td>
			</ng-container>
			<ng-container matColumnDef="Devuelta">
				<th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
					<mat-card style="width:100%;font-size:10pt;height:15pt;padding:2px;margin:0px;">
						Devuelta
					</mat-card>
				</th>
				<td mat-cell *matCellDef="let element"> {{element.Devuelta}} </td>
			</ng-container>

			<tr mat-header-row *matHeaderRowDef="identity.options.ordencamposfacturas; sticky:true"></tr>
			<tr mat-row (click)="seleccionar(row)" class="celdas" [class.seleccionada]="seleccion == row" *matRowDef="let row; columns: identity.options.ordencamposfacturas;">
			</tr>
			</table>
		</div>
		<div *ngIf="!verclientes" style="display:inline-flex;align-items:center;">
			<span style="font-size:10pt;margin-left:15px;color:black;">Seleccionadas: {{selection.selected.length}}</span>
			<mat-form-field *ngIf="identity" style="width:35px;margin-left:20px;margin-right:50px;">
				<mat-select [(ngModel)]="identity.options.ordencamposfacturas" (selectionChange)="onselectcolumnas($event.value)" multiple>
				<mat-select-trigger class="fondooscuro">
					<mat-icon>view_list</mat-icon>
				</mat-select-trigger>
				<mat-option *ngFor="let campo of totalcolumns" [value]="campo">{{campo}}</mat-option>
				</mat-select>
			</mat-form-field>
		</div>
		<div *ngIf="verclientes" class="facturas">
			<ul style="list-style:none;">
				<li *ngFor="let cliente of clientesactivos" class="fondooscuro" style="cursor:pointer;" (click)="seleccionar(cliente)">
					{{cliente.Abonado}} {{cliente.Descripcion}} {{cliente.AvisoAlbaran}} 
				</li>
			</ul>
		</div>
	</div>
	<div *ngIf="clienteseleccionado" class="datosabonado">
		<div class="infoabonado">
			<div class="lineaabonado">
				<mat-form-field style="width:10%;font-size:9pt;color:black;">
					<mat-label>Abonado</mat-label>
					<input style="color:black;" matInput [value]="clienteseleccionado.Abonado" disabled>
				</mat-form-field>
				<mat-form-field style="width:60%;font-size:9pt;color:black;">
					<mat-label>Nombre</mat-label>
					<input style="color:black;" matInput [value]="clienteseleccionado.Descripcion" disabled>
				</mat-form-field>
				<mat-form-field style="width:15%;font-size:9pt;color:black;margin-left:10px;">
					<mat-label>Nif</mat-label>
					<input style="color:black;" matInput [value]="clienteseleccionado.datosAbonado.IdFiscal" disabled>
				</mat-form-field>
			</div>
			<div class="lineaabonado">
				<mat-form-field style="width:45%;font-size:12pt;color:black;">
					<mat-label>Email</mat-label>
					<input style="color:black;" matInput [(ngModel)]="clienteseleccionado['emailfacturacion']">
					<button *ngIf="emailclienteseleccionado != clienteseleccionado['emailfacturacion']" mat-icon-button matSuffix (click)="actualizaabonado()">
						<mat-icon>save</mat-icon>
					</button>
				</mat-form-field>
				<mat-form-field style="width:15%;font-size:12pt;color:black;margin-left:10px;">
					<mat-label>Telefono</mat-label>
					<input style="color:black;" matInput [value]="clienteseleccionado.datosAbonado.Telefono" disabled>
				</mat-form-field>
				<mat-form-field style="width:20%;font-size:12pt;color:black;margin-left:10px;">
					<mat-label>Contacto</mat-label>
					<input style="color:black;" matInput [(ngModel)]="clienteseleccionado['contactofacturacion']">
					<button *ngIf="contactoclienteseleccionado != clienteseleccionado['contactofacturacion']" mat-icon-button matSuffix (click)="actualizaabonado()">
						<mat-icon>save</mat-icon>
					</button>
				</mat-form-field>
			</div>
			<div class="lineaabonado">
				<mat-form-field style="max-height:100px;width:100%;font-size:9pt;">
					<mat-label>Notas Abonado</mat-label>
					<textarea matInput [(ngModel)]="clienteseleccionado['Notas']" rows="3" placeholder="Notas"></textarea>
				</mat-form-field>
				<button mat-button *ngIf="clienteseleccionado['Notas']" mat-icon-button (click)="actualizaabonado()">
				  <mat-icon>save</mat-icon>
				</button>
			</div>
			<div class="lineaabonado">
				<ul style="list-style:none;" *ngFor="let direccion of clienteseleccionado.direcciones">
					<li *ngIf="direccion.Predeterminada">
						{{direccion.Direccion}}
					</li>
				</ul>
			</div>
		</div>
		<div class="barrafacturascliente">
			<mat-checkbox class="example-margin"
			  [checked]="facturasseleccionadasclienteseleccionado.length == facturasabonado.length && facturasabonado.length >=1"
			  [indeterminate]="facturasseleccionadasclienteseleccionado.length != facturasabonado.length && facturasseleccionadasclienteseleccionado.length >=1"
			  (change)="facturascstoggle()">
			</mat-checkbox>
			<div *ngIf="facturasseleccionadasclienteseleccionado.length >= 1 && !desmarcarfacturas" style="margin-left:5px;">
				<div style="font-size:9pt;">Total a Cobrar</div>
				<div style="padding:1px;border:0.2px solid black;font-size:9pt;"><b>{{importefscs.toFixed(2)}}</b> <mat-icon style="margin-left:4px;font-size:smaller">euro_symbol</mat-icon></div>
			</div>
			<div *ngIf="facturasseleccionadasclienteseleccionado.length >= 1 && desmarcarfacturas" style="margin-left:5px;">
				<div style="font-size:9pt;">Total Desmarcar</div>
				<div style="padding:1px;border:0.2px solid black;font-size:9pt;"><b>{{importefscs.toFixed(2)}}</b> <mat-icon style="margin-left:4px;font-size:smaller">euro_symbol</mat-icon></div>
			</div>
			<button *ngIf="facturasseleccionadasclienteseleccionado.length >= 1 && !desmarcarfacturas" mat-button mat-icon-button matTooltip="Cobrar" (click)="cobrarfcs()">
				<mat-icon>point_of_sale</mat-icon>
			</button>
			<button *ngIf="facturasseleccionadasclienteseleccionado.length >= 1 && desmarcarfacturas" mat-button mat-icon-button matTooltip="Desmarcar" (click)="desmarcarfcs()">
				<mat-icon>point_of_sale</mat-icon>
			</button>
			<div *ngIf="!desmarcarfacturas" style="margin-left:5px;margin-right:20px;">
				<div style="font-size:9pt;">Total Pendiente</div>
				<div style="padding:1px;border:0.2px solid black;font-size:9pt;"><b>{{pendientecliente.toFixed(2)}}</b> <mat-icon style="margin-left:4px;font-size:smaller">euro_symbol</mat-icon></div>
			</div>
			<button *ngIf="!desmarcarfacturas" mat-button mat-icon-button matTooltip="Desmarcar Facturas" (click)="facturascobradascliente()">
				<mat-icon>request_quote</mat-icon>
			</button>
			<button *ngIf="desmarcarfacturas" mat-button mat-icon-button matTooltip="Facturas Pendientes" (click)="facturascliente(clienteseleccionado.Abonado)">
				<mat-icon>request_quote</mat-icon>
			</button>
			<a style="text-decoration:none;color:black;" [href]="botoncorreo">
				<button mat-button mat-icon-button matTooltip="Enviar por Email">
					<mat-icon>forward_to_inbox</mat-icon>
				</button>
			</a>	
		</div>
		<div class="facturaspendientes">
			<ul style="list-style:none;">
				<li *ngFor="let factura of facturasabonado">
					<mat-checkbox (change)="seleccionafacturaclienteseleccionado($event, factura)" [checked]="facturasseleccionadasclienteseleccionado.includes(factura)? true : false">
						{{parsefecha(factura.FechaParticion)}} {{factura.NumeroFactura}} {{factura.ImporteFactura}}<mat-icon style="margin-left:4px;font-size:smaller">euro_symbol</mat-icon>
					</mat-checkbox>
				</li>
			</ul>
		</div>
	</div>
</div>	

	