<div style="width:100%">
    <mat-tab-group>
        <mat-tab>
          <ng-template mat-tab-label>
              <mat-icon class="example-tab-icon" style="margin-right:4px;">
                download
              </mat-icon> Descarga Excel
          </ng-template>
          <div style="width:100%;padding:7px;">
            <div class="fondooscuro boton" style="cursor:pointer;font-size:12pt;" (click)="descargageneral('plantilla_solicitud_envios.xlsx')">
                <span class="material-icons md-36" style="margin-right:7px;">cloud_download</span> plantilla excel
            </div>
            <div style="margin:10px;">
                <p>
                    Descargar la plantilla y enviarla rellena con los datos de los envios a: <a [href]="botoncorreo">02601@grupomrw.com</a> con el asunto: {{identity.numero}}_ENTREGAS. 
                </p>
                <p>
                    es importante que los datos dentro del excel esten en formato texto y los codigos postales tengan los 5 digitos (los 4 primeros en caso de portugal).
                </p>
                <p>
                    los envios se generaran con servicio normal (urgente 19), y solo podran ser cobro en origen. para solicitar recogidas en otra direccion deberan usar el APC.
                    consultenos en <a [href]="botoncorreo2">02601@grupomrw.com</a> para prestaciones complementarias y otros servicios, en caso de necesitarlo se podria incluir en la propia plantilla.
                </p>
                <p>
                    cuando se procese el archivo el sistema enviara un email informando del resultado.
                </p>
            </div>
          </div>
        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon class="example-tab-icon" style="margin-right:4px;">
                    drive_file_rename_outline
                </mat-icon> Generar excel Online
            </ng-template>
            <div *ngIf="grup && !pendientes" class="barraseleccion">
                <span class="spacer"></span>
                <div>
                    Archivos de excel online solicitados anteriormente
                </div>
                <span class="spacer"></span>
            </div>
            <div *ngIf="pendientes" class="barraseleccion">
                <span class="spacer"></span>
                <div>
                    Solicitudes Guardadas
                </div>
                <span class="spacer"></span>
            </div>
            <div *ngIf="!grup && !pendientes" class="barraseleccion">
                <span class="spacer"></span>
                <div>
                    Direccion De Entrega
                </div>
                <span class="spacer"></span>
            </div>
            <div *ngIf="grup" class="listagrupos">
                <table mat-table [dataSource]="grupos" class="tabla">
                    <ng-container matColumnDef="FECHA">
                        <th mat-header-cell *matHeaderCellDef>
                          Fecha
                        </th>
                        <td mat-cell *matCellDef="let element">
                          {{element.actualizado | date:'dd/MM/yyyy HH:mm:ss'}}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="ENVIOS">
                        <th mat-header-cell *matHeaderCellDef="ENVIOS">
                            Envios
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{element.envios.length}}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="Eliminar">
                        <th mat-header-cell *matHeaderCellDef="Eliminar">
                            Eliminar
                        </th>
                        <td mat-cell *matCellDef="let row">
                            <button *ngIf="pendientes" mat-icon-button aria-label="Clear" (click)="eliminasolicitud(row)">
                                <mat-icon>close</mat-icon>
                            </button>
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="columnsgrupos; sticky:true"></tr>
                    <tr mat-row *matRowDef="let row; columns: columnsgrupos;" class="celdas" [class.seleccionada]="gruposeleccionado == row" (click)="lineasgrupo(row)"></tr>
                </table>
            </div>
            <div *ngIf="!grup" style="width:100%">
                <div *ngIf="departamentos && !departamento" style="display:inline;width:100%;padding:10px;">
                    <mat-form-field style="margin-right:50px;" class="campo">
                        <mat-label><b>Departamento</b></mat-label>
                        <mat-select name="departamentos" [(ngModel)]="departamento">
                            <mat-option [value]="item" *ngFor="let item of departamentos">{{item.CodigoCentro}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div *ngIf="!departamentos || (departamentos && departamento)" style="display:inline;width:100%;padding:10px;">
                    <mat-form-field style="margin-right:50px;" class="campo" required>
                        <mat-label><b>Nombre</b></mat-label>
                        <input matInput type="text" [(ngModel)]="linea.nombre" style="padding:1px;color:black;">
                        <button mat-button *ngIf="linea.nombre" matSuffix mat-icon-button aria-label="Clear" (click)="linea.nombre =''">
                            <mat-icon>close</mat-icon>
                        </button>
                    </mat-form-field>
                    <mat-form-field style="margin-right:50px;" class="campo" required>
                        <mat-label><b>Direccion</b></mat-label>
                        <input matInput type="text" [(ngModel)]="linea.direccion" style="padding:1px;color:black;">
                        <button mat-button *ngIf="linea.direccion" matSuffix mat-icon-button aria-label="Clear" (click)="linea.direccion =''">
                            <mat-icon>close</mat-icon>
                        </button>
                    </mat-form-field>
                    <mat-form-field style="margin-right:50px;" class="campo" required>
                        <mat-label><b>Poblacion</b></mat-label>
                        <input matInput type="text" [(ngModel)]="linea.poblacion" style="padding:1px;color:black;">
                        <button mat-button *ngIf="linea.poblacion" matSuffix mat-icon-button aria-label="Clear" (click)="linea.poblacion =''">
                            <mat-icon>close</mat-icon>
                        </button>
                    </mat-form-field>
                    <mat-form-field style="margin-right:50px;" class="campo" required>
                        <mat-label><b>Codigo Postal</b></mat-label>
                        <input matInput type="text" [(ngModel)]="linea.cp" style="padding:1px;color:black;">
                        <button mat-button *ngIf="linea.cp" matSuffix mat-icon-button aria-label="Clear" (click)="linea.cp =''">
                            <mat-icon>close</mat-icon>
                        </button>
                    </mat-form-field>
                    <mat-form-field style="margin-right:50px;" class="campo">
                        <mat-label><b>Telefono</b></mat-label>
                        <input matInput type="text" [(ngModel)]="linea.telefono" style="padding:1px;color:black;">
                        <button mat-button *ngIf="linea.telefono" matSuffix mat-icon-button aria-label="Clear" (click)="linea.telefono =''">
                            <mat-icon>close</mat-icon>
                        </button>
                    </mat-form-field>
                    <mat-form-field style="margin-right:50px;" class="campo">
                        <mat-label><b>Contacto</b></mat-label>
                        <input matInput type="text" [(ngModel)]="linea.contacto" style="padding:1px;color:black;">
                        <button mat-button *ngIf="linea.contacto" matSuffix mat-icon-button aria-label="Clear" (click)="linea.contacto =''">
                            <mat-icon>close</mat-icon>
                        </button>
                    </mat-form-field>
                    <mat-form-field style="margin-right:50px;" class="campo">
                        <mat-label><b>Referencia</b></mat-label>
                        <input matInput type="text" [(ngModel)]="linea.referencia" style="padding:1px;color:black;">
                        <button mat-button *ngIf="linea.referencia" matSuffix mat-icon-button aria-label="Clear" (click)="linea.referencia =''">
                            <mat-icon>close</mat-icon>
                        </button>
                    </mat-form-field>
                    <mat-form-field style="margin-right:50px;" class="campo">
                        <mat-label><b>Observaciones</b></mat-label>
                        <input matInput type="text" [(ngModel)]="linea.observaciones" style="padding:1px;color:black;">
                        <button mat-button *ngIf="linea.observaciones" matSuffix mat-icon-button aria-label="Clear" (click)="linea.observaciones =''">
                            <mat-icon>close</mat-icon>
                        </button>
                    </mat-form-field>
                    <mat-form-field *ngIf="servicios.length >=1" style="margin-right:50px;" class="campo">
                        <mat-label><b>Servicio</b></mat-label>
                        <mat-select name="servicio" [(ngModel)]="linea.servicio">
                            <mat-option [value]="item.valor" *ngFor="let item of servicios">{{item.mostrar}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field style="margin-right:50px;" class="campo">
                        <mat-label><b>Cobro</b></mat-label>
                        <mat-select name="cobro" [(ngModel)]="linea.cobro">
                            <mat-option [value]="item.valor" *ngFor="let item of cobros">{{item.mostrar}}</mat-option>
                        </mat-select>
                    </mat-form-field>  
                    <button mat-button matTooltip="Guardar direccion" (click)="guardalinea()">
                        <mat-icon>
                            save
                        </mat-icon>
                    </button>
                </div>
            </div>    
            <div *ngIf="!departamentos || (departamentos && departamento)" class="barraseleccion">
                <button *ngIf="!grup" mat-icon-button [disabled]="lineas.data.length <= 0" class="botonbarra" (click)="solicitaenvios()">
                    <mat-icon class="material-icons-outlined" matTooltip="Enviar solicitud de direcciones">send</mat-icon>
                </button>
                <button *ngIf="grup" mat-icon-button [disabled]="!gruposeleccionado" class="botonbarra" (click)="cargasolicitud()">
                    <mat-icon class="material-icons-outlined" matTooltip="Cargar solicitud de direcciones">published_with_changes</mat-icon>
                </button>
                <button *ngIf="grup && gruposeleccionado && gruposeleccionado.errores && gruposeleccionado.errores.length >= 1 && !errores" mat-icon-button class="botonbarra" (click)="erroresgrupo()">
                    <mat-icon class="material-icons-outlined" matTooltip="Errores solicitud de direcciones">report_problem</mat-icon>
                </button>
                <button *ngIf="grup && gruposeleccionado && gruposeleccionado.errores && gruposeleccionado.errores.length >= 1 && errores" mat-icon-button class="botonbarra" (click)="volveralineasgrupo()">
                    <mat-icon class="material-icons-outlined" matTooltip="Volver a lista envios">switch_left</mat-icon>
                </button>
                <button *ngIf="!grup && !pendientes" mat-icon-button [disabled]="grupos.data.length <= 0" class="botonbarra" (click)="togglegroup()">
                    <mat-icon class="material-icons-outlined" matTooltip="Excel online solicitados anteriormente">pending_actions</mat-icon>
                </button>
                <button *ngIf="grup && !pendientes" mat-icon-button class="botonbarra" (click)="togglegroup()">
                    <mat-icon class="material-icons-outlined" matTooltip="Volver a solicitud online">switch_left</mat-icon>
                </button>
                <button *ngIf="!pendientes && !grup" mat-icon-button [disabled]="!grupend || grupend.data.length <=0" class="botonbarra" (click)="togglependientes()">
                    <mat-icon class="material-icons-outlined" matTooltip="ver solicitudes guardadas">folder_special</mat-icon>
                </button>
                <button *ngIf="pendientes" mat-icon-button class="botonbarra" (click)="togglependientes()">
                    <mat-icon class="material-icons-outlined" matTooltip="volver">switch_left</mat-icon>
                </button>
                <button *ngIf="!pendientes && !grup && !gruposeleccionado" mat-icon-button class="botonbarra" [disabled]="!lineas || lineas.data.length <=0" (click)="savegrupo()">
                    <mat-icon class="material-icons-outlined" matTooltip="Guardar lista de envios">save</mat-icon>
                </button>
                <button *ngIf="!pendientes && !grup && gruposeleccionado" mat-icon-button class="botonbarra" [disabled]="!lineas || lineas.data.length <=0" (click)="actualizagrupo()">
                    <mat-icon class="material-icons-outlined" matTooltip="Guardar lista de envios">save</mat-icon>
                </button>
            </div>
            <div class="listalineas">
                <table mat-table *ngIf="lineas" [dataSource]="lineas" class="tabla">
                    <ng-container *ngFor="let column of columns; let index" [matColumnDef]="column">
                        <th mat-header-cell *matHeaderCellDef>
                          {{column}}
                        </th>
                        <td mat-cell *matCellDef="let element">
                          {{element[column.toLowerCase()]}}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="Eliminar">
                        <th mat-header-cell *matHeaderCellDef="Eliminar">
                            Eliminar
                        </th>
                        <td mat-cell *matCellDef="let row">
                            <button *ngIf="!grup" mat-icon-button aria-label="Clear" (click)="eliminalinea(row)">
                                <mat-icon>close</mat-icon>
                            </button>
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="columns; sticky:true"></tr>
                    <tr mat-row *matRowDef="let row; columns: columns;" class="celdas"></tr>
                </table>
            </div>
        </mat-tab>    
    </mat-tab-group>    
</div>