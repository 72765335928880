import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ClienteService } from '../../servicios/cliente.service';
import { saveAs } from "file-saver";

@Component({
  selector: 'facturas',
  templateUrl: './facturas.component.html',
  styleUrls: ['./facturas.component.css'],
  providers: [ClienteService]
})
export class FacturasComponent implements OnInit {
	public title:String;
	public identity;
  public carpeta;
	public facturas;
  public cliente;
  public displayedColumns = ['factura'];
  constructor(
  	private _route: ActivatedRoute,
    private _clienteService: ClienteService
  ) {

    this.identity = JSON.parse(sessionStorage.getItem('identity'));
    this._route.params.subscribe(params=>{
      if(params.id){
        let id = params.id;
        this._clienteService.getcliente(id).subscribe(
          response =>{
              this.cliente = response.cliente;
              this.carpeta = params.carpeta;
              this.title = 'Facturas Abonado: '+ this.cliente.nombre + ' de ' + this.carpeta;
              this.getFacturas(this.carpeta);
            },
          error => {
            console.log(<any>error);
          }  
        );
      }  
    });  
  }

  ngOnInit() {
      
  }

  getFacturas(carpeta){
    this._clienteService.getFacturas(this.cliente._id, this.cliente.numero, carpeta).subscribe(
      response =>{
        this.facturas = response.map((factura)=>{
          let fact = {
            factura: factura,
            orden: this.obtenermes(factura)
          }
          return fact;
        }).sort(function(a, b){
          return a['orden'] > b['orden']? 1 :-1;
        });
        console.log(this.facturas);
        
        },
      error =>{
        console.log(error);
      } 
    );

  }

  obtenermes(archivo){
    let mes = archivo.split('-');
    let vuelta;
    switch(mes[0]){
      case 'Enero':
        vuelta = 1;
        break;
      case 'Febrero':
        vuelta = 2;
        break;
      case 'Marzo':
        vuelta = 3;
        break;
      case 'Abril':
        vuelta = 4;
        break;
      case 'Mayo':
        vuelta = 5;
        break;
      case 'Junio':
        vuelta = 6;
        break;
      case 'Julio':
        vuelta = 7;
        break;
      case 'Agosto':
        vuelta = 8;
        break;
      case 'Septiembre':
        vuelta = 9;
        break;
      case 'Octubre':
        vuelta = 10;
        break;
      case 'Noviembre':
        vuelta = 11;
        break;
      case 'Diciembre':
        vuelta = 12;
        break;
      default:
        vuelta = 1;
        break;  
    }
    return vuelta;
  }

  descargaFactura(archivo){
    let ruta = 'facturasclientes/' + this.cliente.numero + '/' + this.carpeta + '/';
    this._clienteService.descargaFactura(archivo, this.cliente._id, ruta).subscribe(
      response =>{
          saveAs(response, archivo);
        },
      error => {
        console.log(<any>error);
      }  
     );
  }

 
}
