import { OnInit, Component, ViewChild, AfterViewInit} from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { ClienteService } from '../../servicios/cliente.service';
import { DeudasService } from '../../servicios/deudas.service';
import { Deuda } from '../../models/deuda';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import * as moment from 'moment';
import { MatTableDataSource } from '@angular/material/table';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition, MatSnackBarConfig } from '@angular/material/snack-bar'; 
import { NuevadeudaComponent } from '../nuevadeuda/nuevadeuda.component';

@Component({
  selector: 'deudas',
  templateUrl: './deudas.component.html',
  styleUrls: ['./deudas.component.css'],
  providers: [DeudasService,ClienteService]
})
export class DeudasComponent implements OnInit {
	  public mensajero;
    public cliente;
    public identity;
    public totalcolumns;
    public dataSource;
    public selection;
    public nuevadeuda;
    public totalimporte;
    public totalimporteseleccion;
    public filtros;
    public deudas;
    public admin;
    private paginator: MatPaginator;
    private sort: MatSort;
    @ViewChild(MatSort, {static: false}) set content(sort: MatSort){this.sort = sort;}
    @ViewChild(MatPaginator, {static: false}) set matPaginator(mp: MatPaginator) {this.paginator = mp;}
    
  constructor(
  	  private _route: ActivatedRoute,
	    private _router: Router,
	    private _clienteService: ClienteService,
      private _deudasService: DeudasService,
      private _snackBar: MatSnackBar,
      private ventanaNuevadeuda: MatDialog,
      private dateAdapter: DateAdapter<Date>
  ) {
  	this._route.params.subscribe(params=>{
      if(params.admin) this.admin = params.admin;
      this._clienteService.getcliente(params.numero).subscribe(
        response=>{
          this.mensajero = response.cliente;
          this.getdeudaspendientes()
        }
      );
    });    
    this.dataSource = new MatTableDataSource<Deuda>();
    this.totalcolumns = ['select','fechadeuda','importe','motivo','fechacobro'];
    this.selection = new SelectionModel<Deuda>(true, []);
    this.dateAdapter.setLocale('es');
    this.dateAdapter.getFirstDayOfWeek = () => { return 1; };
    this.identity = JSON.parse(sessionStorage.getItem('identity'));
    this.totalimporteseleccion = 0;
    this._clienteService.getcliente(this.identity._id).subscribe(
        response=>{
          this.cliente = response.cliente;
        },
      error =>{
        console.log(<any>error);
      });
    this.filtros = {
      filtroprincipal: ''
      };   
  }

  ngOnInit(): void {
  }


  ngAfterViewInit(){
     this.dataSource.paginator = this.paginator
     this.dataSource.sort = this.sort;
  }

  limpiarfiltros(){
    this.dataSource.filter = '';
    this.filtros = {filtroprincipal: ''};
  }  

  aviso(texto, subtexto) {
    this._snackBar.open(texto,subtexto,{
      duration: 4000,
      horizontalPosition: "center",
      verticalPosition: "top",
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  isAllSelected() {
    if(this.dataSource.filteredData){
      const numSelected = this.selection.selected.length;
      const numRows = this.dataSource.filteredData.length;
      return numSelected === numRows;
    }else{
      const numSelected = this.selection.selected.length;
      const numRows = this.dataSource.data.length;
      return numSelected === numRows;
    }  
  }

  /** Selects all rows if they are not all selected; otherwise clear selection.*/ 
  masterToggle() {
    if(this.dataSource.filteredData){
        this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.filteredData.forEach(row => this.selection.select(row));
    }else{  
      this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.data.forEach(row => this.selection.select(row));
    }
    this.mapearseleccion();    
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: Deuda): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    this.mapearseleccion();
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  ondatefechadeuda(data){
    var fecha = moment(data).format('DD/MM/YYYY');
    var query = {fechadeuda:fecha,mensajero:this.mensajero.numero};
    this.getdeudas(query);
  }

  ondatefechacobro(data){
    var fecha = moment(data).format('DD/MM/YYYY');
    var query = {fechacobro:fecha,mensajero:this.mensajero.numero};
    this.getdeudas(query);
  }

  getdeudaspendientes(){
    this.selection.clear();
    this.limpiarfiltros();
    var query = {mensajero:this.mensajero.numero, $or:[{cobrada:false},{cobrada:{$exists:false}}]};
    this._deudasService.getdeudas(query).subscribe(
      response=>{
          if(response.deudas){
            this.deudas = response.deudas;
            this.mapearpendientes();
          }  
        },
      error=>{
        console.log(<any>error);
      }  
    );
  }

  mapearpendientes(){
    this.dataSource.data = [];
    this.totalimporte = 0;
    this.dataSource.data = this.deudas.map((deuda)=>{
      this.totalimporte = this.totalimporte + deuda.importe;
      return deuda;
    });
  }

  mapearseleccion(){
    this.totalimporteseleccion = 0;
    if(this.selection.selected.length >= 1){
      this.selection.selected.map((deuda)=>{
        this.totalimporteseleccion = this.totalimporteseleccion + deuda.importe;
        return deuda;
      });  
    }    
  }

  getdeudas(query){
    this._deudasService.getdeudas(query).subscribe(
      response=>{
          if(response.deudas){
            this.deudas = response.deudas;
            this.mapearpendientes();
          }else{
            this.deudas = [];
            this.mapearpendientes();
          }  
        },
      error=>{
        console.log(<any>error);
      }  
    );
  }

  cobrar(){
    var cantidad = 0;
    this.selection.selected.forEach(deuda =>{
      deuda.cobrada = true;
      deuda.fechacobro = moment().format('DD/MM/YYYY');
      this._deudasService.guardardeuda(deuda).subscribe(
        response =>{
            if(response){
              cantidad++
              if(cantidad == this.selection.selected.length){
                this.selection.clear();
                this.getdeudaspendientes();
              }  
            }
          },
         error =>{
          console.log(<any>error);
         } 
      );
    });
  }

  desmarcar(){
    var cantidad = 0;
    this.selection.selected.forEach(deuda =>{
      deuda.cobrada = false;
      deuda.fechacobro = '';
      this._deudasService.guardardeuda(deuda).subscribe(
        response =>{
            if(response){
              cantidad++
              if(cantidad == this.selection.selected.length){
                this.selection.clear();
                this.getdeudaspendientes();
              }  
            }
          },
         error =>{
          console.log(<any>error);
         } 
      );
    });
  }

  abrenuevadeuda(){
    var datos = new Deuda("", this.mensajero.numero,"",0,"",false,"",0);
    var newdeuda = this.ventanaNuevadeuda.open(NuevadeudaComponent, {
      width: '60%',
      height: '30%',
      data: datos
    });
    newdeuda.afterClosed().subscribe(result =>{
      if(result == true) this.getdeudaspendientes();
    });  
  }

  abredeuda(deuda){
    var olddeuda = this.ventanaNuevadeuda.open(NuevadeudaComponent, {
      width: '60%',
      height: '30%',
      data: deuda
    });
    olddeuda.afterClosed().subscribe(result =>{
      if(result == true) this.getdeudaspendientes();
    });  
  }

  enrutar(ruta){
    this._router.navigate([ruta]);
  }

}
