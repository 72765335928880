import { Injectable } from "@angular/core";
import { Observable } from "rxjs/Observable";
import { GLOBAL } from "./global";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { AppService } from "./app.service";

@Injectable()
export class DireccionesService {
  public identity: string;
  public url: string;

  constructor(public _http: HttpClient, public _appservice: AppService) {
    this.url = GLOBAL.urldirecciones;
  }

  savedir(direccion) {
    let cuerpo = {
      direccion: direccion,
    };
    let params = JSON.stringify(cuerpo);
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("authorization", this._appservice.getToken());
    return this._http.post(this.url + "savedir", params, { headers: headers });
  }

  getdirecciones(query) {
    let cuerpo = {
      query: query,
    };
    let params = JSON.stringify(cuerpo);
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("authorization", this._appservice.getToken());
    return this._http.post(this.url + "getdirecciones", params, {
      headers: headers,
    });
  }

  borradireccion(dirid) {
    let cuerpo = {
      idDireccion: dirid,
    };
    let params = JSON.stringify(cuerpo);
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("authorization", this._appservice.getToken());
    return this._http.post(this.url + "borradir", params, { headers: headers });
  }

  updatedireccion(direccion) {
    let cuerpo = {
      direccion: direccion,
    };
    let params = JSON.stringify(cuerpo);
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("authorization", this._appservice.getToken());
    return this._http.post(this.url + "updatedir", params, {
      headers: headers,
    });
  }
}
