
<h4 class="centrado">{{title}}</h4>

<div class="contenido">
	<div *ngIf="editables && editables.galletag3" class="contienesubir">	
		<div style="display:block">
			<label style="margin-right: 10px;" for="btnsel">Seleccionar archivo</label>
			<input [(ngModel)]="contenidoinput" name="btnsel" type="file" multiple="false" (change)="fileChangeEvent($event)"/>
		</div>
		<div style="margin-left: 20px;">	
			<input class="btn btn-success" value="Procesar Archivo" type="submit" (click)="subir()"/>
		</div>
	</div>
	<div *ngIf="(editables && !editables.galletag3) || !editables">
		Conectando a MRW...
	</div>
	<div *ngIf="procesando">
		{{procesando}}
	</div>
	<envioseditablescliente #EnviosEditablesclienteComponent (procesando)="mensajeprocesando($event)" (actualizargrupo)="actualizagrupo($event)" (tipo)="tipoenvio($event)"></envioseditablescliente>
</div>





