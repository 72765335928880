import { Component, OnInit } from '@angular/core';
import { ActivatedRoute} from '@angular/router';
import { ClienteService } from '../../servicios/cliente.service';
import { AppService } from '../../servicios/app.service';

@Component({
  selector: 'carpetas',
  templateUrl: './carpetas.component.html',
  styleUrls: ['./carpetas.component.css'],
  providers: [ClienteService, AppService]
})
export class CarpetasComponent implements OnInit {
	public title:String;
	public identity;
	public carpetas;
  public clienteid;
  public clientenumero;

  constructor(
  	private _route: ActivatedRoute,
    private _clienteService: ClienteService,
    private _appservice: AppService
  ) {
  	this.identity = this._appservice.getIdentity();
    this._route.params.subscribe(params=>{
      if(params.id && params.numero){
        this.clienteid = params.id;
        this.clientenumero = params.numero;
      }else{
        this.clienteid = this.identity._id;
        this.clientenumero = this.identity.numero;
      }
      this._clienteService.getFacturas(this.clienteid, this.clientenumero).subscribe(
        response =>{
          this.carpetas = response;
          this.title = 'Facturas Abonado: '+ this.clientenumero;
        },
        error =>{
          console.log(error);
        } 
      );  
    });
  }
  ngOnInit() {
  }
}