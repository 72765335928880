import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ClienteService } from '../../servicios/cliente.service';
import { Cliente } from '../../models/cliente';

@Component({
  selector: 'app-regusuarios',
  templateUrl: './regusuarios.component.html',
  styleUrls: ['./regusuarios.component.css'],
  providers: [ClienteService]
})
export class RegusuariosComponent implements OnInit {

	public title:String;
	public cliente;
	public status: String;
	public pass: String;
  public adminrol: String;
  public listarol;


  constructor(
  	private _router: Router,
    private _clienteService: ClienteService

  ) {
  		this.title = 'registro de usuarios, palataforma Mensasur Getafe';
  		this.cliente = new Cliente("","","","","","","","","","","","","","","","","",[""],{page:1,limit:50, sort:{fechaenvio: 'asc'}, carpetasmail:[""], carpetasinteraccion:[""], ordencamposincidencias:["select"], ordencamposconcertadas:["select"],ordencamposfacturas:["select"],ordencamposreembolsos:{mensajeros:null,totales:["select"],direcciones:["select"]}, g3:{usuario:'0260100019', password:'mensasur2601'}, firmamail:''});
    }

  ngOnInit(): void {
      this.listarol = ['Administrador', 'operador-admin', 'operador-incidencias', 'operador', 'operador-plus', 'Mensajero', 'comercial'];
  }

  onSubmit(form){
    
      	this._clienteService.registro(this.cliente).subscribe(
          response =>{
            if(response.message && response.message == 'usuario guardado con exito'){
              this.status = 'success';
              form.reset();
            }else{
              if(response.message == 'el cliente ya existe'){
                this.status = 'existe'
              }else{
                this.status = 'error';
              }
            }
          },
          error =>{
            console.log(<any>error);
        });
  }

   enrutar(ruta){
    this._router.navigate([ruta]);
  }

}