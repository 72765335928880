import { Component, OnInit, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { ClienteService } from '../../servicios/cliente.service';
import { AppService } from '../../servicios/app.service';
import { Linea } from '../../models/lineaenvio';
import { saveAs } from "file-saver";
import { MatSelect } from '@angular/material/select';

@Component({
  selector: 'enviosexcel',
  templateUrl: './enviosexcel.component.html',
  styleUrls: ['./enviosexcel.component.css'],
  providers: [ClienteService]
})
export class EnviosexcelComponent implements OnInit {
  public identity;
  public ceco;
  public botoncorreo;
  public botoncorreo2;
  public lineascreadas;
  public lineas = new MatTableDataSource<Linea>();
  public linea = new Linea("","","",""," ","","","","","1","N","");
  public columns = ['NOMBRE', 'DIRECCION', 'POBLACION', 'CP', 'TELEFONO', 'CONTACTO', 'REFERENCIA', 'OBSERVACIONES', 'Eliminar'];
  public grupos = new MatTableDataSource<any>();
  public columnsgrupos = ['FECHA', 'ENVIOS', 'Eliminar'];
  public grup = false;
  public gruposeleccionado;
  public errores = false;
  public servicios;
  public grupend = new MatTableDataSource<any>();
  public pendientes = false;
  public serviciopredeterminado;
  public departamentos = undefined;
  public departamento = undefined;
  public mapaservicios = {
    U19E: 'URGENTE 19EXP',
    U19: 'URGENTE 19',
    U830: 'URGENTE 8:30',
    U10: 'URGENTE 10',
​​    ​U12: 'URGENTE 12',
​​    U14: 'URGENTE 14',
    U10E: 'URGENTE 10EXP',
    U14E: 'URGENTE 14EXP',
    U19PT: 'URGENTE 19 PORTUGAL',
​    UBAG14: 'BAG 14',
​​​    UBAG19: 'BAG 19',
​​​    UMARITIMO: 'MARITIMO',
    U830E: 'URGENTE 8:30EXP',
    ECOM: 'ECOMMERCE',​​​
  }
  public cobros = [
    {
      mostrar: 'Origen',
      valor: '1'
    },
    {
      mostrar: 'Destino',
      valor: '2'
    }  
  ]

  @ViewChild(MatSelect) select: MatSelect;
  
  constructor(
    private _clienteService: ClienteService,
    private _appservice: AppService
  ) { 
    this.identity = this._appservice.getIdentity();
    this.botoncorreo = `mailto:02601@grupomrw.com?subject=${this.identity.numero}_ENTREGAS`;
    this.botoncorreo2 = `mailto:02601@grupomrw.com?subject=${this.identity.numero}_Consulta envios Excel`;
    this.ceco = JSON.parse(sessionStorage.getItem('ceco'));
    this.getdepartamentos();
    if(this.ceco.servicios.length >= 1){   
      if(this.ceco.servicios[0].CodigosServicio.length >= 1){
        this.servicios =  this.ceco.servicios[0].CodigosServicio.map((serv)=>{
          if(this.mapaservicios[serv]){
            var servmost = this.mapaservicios[serv];
          }else{
            var servmost = 'no';
          }
          let lineaservicio = {
            mostrar: servmost,
            valor: serv
          }
          return lineaservicio;
       }).filter((srv)=>{
         if(srv.mostrar == 'no'){
           return false;
         }else{
           return true;
         }
       });
      }  
    }
  }

  ngOnInit(): void {
    this.getgrupos();
  }

  ngAfterViewInit(){
    if(this.ceco.servicios[0].Enviado){
      this.serviciopredeterminado = this.ceco.servicios[0].Enviado;
      this.linea.servicio = this.serviciopredeterminado;
      this.select.value = this.mapaservicios[this.serviciopredeterminado];
    }else{
      this.linea.servicio = 'U19';
      this.select.value = 'URGENTE 19';
    }  
  }

  getdepartamentos(){
    let query = {
      Abonado: this.ceco.Abonado
    }
    this._clienteService.getcecos(query).subscribe(
      res=>{
        if(res.cecos && res.cecos.length > 1){
          this.departamentos = res.cecos;
          console.log(this.departamentos);
        }
      }
    )
  }

  descargageneral(archivo){
    let ruta = 'archivosclientes/general/';
    this._clienteService.descargaFactura(archivo, this.identity._id, ruta).subscribe(
      response =>{
          if(response.message){
            alert('no existe el archivo');
          }else{
            saveAs(response, archivo);
          }  
        },
      error => {
        console.log(<any>error);
      }  
     );
  }

  guardalinea(){
    if(this.linea.nombre && this.linea.direccion && this.linea.cp && this.linea.poblacion){
      var lines = this.lineas.data;
      lines.push(this.linea);
      this.lineas.data = lines;
      let srvpred = this.serviciopredeterminado? this.serviciopredeterminado : '';
      this.linea = new Linea("","","",""," ","","","","","1","N",srvpred);
    }else{
      alert('faltan campos obligatorios por rellenar')
    }
  }  

  eliminalinea(row){
    var lines = this.lineas.data;
    lines.splice(lines.findIndex((li)=>{
      return li.nombre == row.nombre && li.direccion == row.direccion;
    }), 1);
    this.lineas.data = lines;
  }

  eliminasolicitud(row){
    var grupos = this.grupos.data;
    grupos.splice(grupos.findIndex((li)=>{
      return li._id == row._id;
    }), 1);
    this.grupos.data = grupos;
    this._clienteService.borragrupo(row._id).subscribe(
      response=>{
        this.getgrupospendientes();
        this.lineas = new MatTableDataSource<Linea>();
        this.gruposeleccionado = undefined;
        if(response && response['message']){
          alert(response['message']);
        }
      }
    )
  }

  solicitaenvios(){
    if(this.departamento){
      var dep = this.departamento.CodigoCentro.replace(this.ceco.Abonado, '');
    }else{
      var dep = null;
    }
    console.log(dep);
    this._clienteService.solicitarenvios(this.lineas.data,this.identity.numero, dep).subscribe(
      response=>{
        if(response && response['message'] && response['message'] == 'lineas procesadas'){
          this.lineas = new MatTableDataSource<Linea>();
          this.grupos = new MatTableDataSource<any>();
          this.grupend = new MatTableDataSource<any>();
          this.getgrupos();
          alert('solicitud enviada correctamente');
        }else{
          alert(`ocurrio un error en la solicitud,
          por favor intentelo de nuevo pasados unos minutos`);
        }
      }
    )
  }

  getgrupos(){
    this._clienteService.getgrupos().subscribe(
      response=>{
        if(response && response['grupos'] && response['grupos'].length >=1){
          this.grupos.data = response['grupos'].sort(function(a,b){
            return a.actualizado < b.actualizado;
          });
        }
        this.getgrupospendientes();
      }
    )
  }

  getgrupospendientes(){
    this._clienteService.getgrupospendientes().subscribe(
      response=>{
        if(response && response['grupos'] && response['grupos'].length >=1){
          this.grupend.data = response['grupos'].sort(function(a,b){
            return a.actualizado < b.actualizado;
          });
        }
      }
    )
  }

  togglegroup(){
    this.grup = !this.grup;
    if(this.grup){
      this.lineascreadas = this.lineas.data;
      this.lineas = new MatTableDataSource<Linea>(); 
    }else{
      this.lineas.data = this.lineascreadas;
      this.gruposeleccionado = undefined;
    }
  }

  togglependientes(){
    this.pendientes = !this.pendientes;
    if(this.pendientes){
      this.grup = true;
      this.grupos.data = this.grupend.data;
      this.lineascreadas = this.lineas.data;
      this.lineas = new MatTableDataSource<Linea>();
    }else{
      this.grup = false;
      this.grupos = new MatTableDataSource<any>();
      this.grupend = new MatTableDataSource<any>();
      this.getgrupos();
      this.gruposeleccionado = undefined;
      this.lineas.data = this.lineascreadas;
    }
  }

  lineasgrupo(grupo){
    this.lineas.data = grupo.envios;
    this.gruposeleccionado = grupo;
  }

  erroresgrupo(){
    this.errores = true;
    this.lineas.data = this.gruposeleccionado.errores;
  }

  volveralineasgrupo(){
    this.errores = false;
    this.lineas.data = this.gruposeleccionado.envios;
  }

  cargasolicitud(){
    this.grup = false;
    this.pendientes = false;
    this.lineas.data = this.gruposeleccionado.envios;
    this.grupos = new MatTableDataSource<any>();
    this.grupend = new MatTableDataSource<any>();
    this.getgrupos();
  }

  savegrupo(){
    let grupo = {
      lineas: this.lineas.data,
      abonado: this.identity.numero,
      pendiente: true
    }
    this._clienteService.savegrupo(grupo).subscribe(
      response=>{
        alert('lista guardada');
        this.getgrupos();
      }
    )
  }

  actualizagrupo(){
    let grupo = {
      lineas: this.lineas.data,
      abonado: this.identity.numero,
      pendiente: this.gruposeleccionado.pendiente
    }
    this._clienteService.actualizagrupo(grupo, this.gruposeleccionado._id).subscribe(
      response=>{
        this.gruposeleccionado = undefined;
        this.getgrupos();
        alert(response['message']);
      }
    )
  }

}