<p>
    fichar dia: {{data.fecha}} a las: {{horas + ':' + minutos + ':' + segundos}}
</p>
<div style="display:inline-flex;width:100%;padding-left:20%;">
    <div class="centrar">
        <div class="centrar">
            <button mat-raised-button (click)="mas('horas')">+</button>
        </div>   
        <div class="centrar">
            <mat-form-field class="intime">
                <input matInput [(ngModel)]="horas">
            </mat-form-field>
        </div>
        <div class="centrar">    
            <button mat-raised-button (click)="menos('horas')">-</button>
        </div>    
    </div>
    <div class="centrar">
        <div class="centrar">
            <button mat-raised-button (click)="mas('minutos')">+</button>
        </div>   
        <div class="centrar">
            <mat-form-field class="intime">
                <input matInput [(ngModel)]="minutos">
            </mat-form-field>
        </div>
        <div class="centrar">    
            <button mat-raised-button (click)="menos('minutos')">-</button>
        </div>    
    </div>  
    <div class="centrar">
        <div class="centrar">
            <button mat-raised-button (click)="mas('segundos')">+</button>
        </div>   
        <div class="centrar">
            <mat-form-field class="intime">
                <input matInput [(ngModel)]="segundos">
            </mat-form-field>
        </div>
        <div class="centrar">    
            <button mat-raised-button (click)="menos('segundos')">-</button>
        </div>    
    </div>      
</div>
<div class="centrar" style="display:inline-flex;width:100%;margin-top:15px;">
    <div>
        <button mat-raised-button color="warn" (click)="cancelar()">Cancelar</button>
    </div>    
    <span class="spacer"></span>
    <div>
        <button mat-raised-button color="primary" (click)="fichar()" cdkFocusInitial>Fichar</button>
    </div>    
</div>    
