import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs/Observable";
import { GLOBAL } from "./global";
import { AppService } from "./app.service";

@Injectable()
export class VehiculoService {
  public url: string;

  constructor(public _http: HttpClient, public _appservice: AppService) {
    this.url = GLOBAL.urlvehiculo;
  }

  getvehiculos(query): Observable<any> {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("authorization", this._appservice.getToken());
    var tmp = { query: query };
    var params = JSON.stringify(tmp);
    return this._http.post(this.url + "getvehiculos", params, {
      headers: headers,
    });
  }

  guardarvehiculo(vehiculo): Observable<any> {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("authorization", this._appservice.getToken());
    var tmp = { vehiculo: vehiculo };
    var params = JSON.stringify(tmp);
    return this._http.post(this.url + "guardarvehiculo", params, {
      headers: headers,
    });
  }

  borravehiculo(vehiculo): Observable<any> {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("authorization", this._appservice.getToken());
    return this._http.delete(this.url + "borrarvehiculo/" + vehiculo, {
      headers: headers,
    });
  }

  getvehiculo(vehiculo): Observable<any> {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("authorization", this._appservice.getToken());
    return this._http.get(this.url + "getvehiculo/" + vehiculo, {
      headers: headers,
    });
  }

  updatevehiculo(vehiculo, Id): Observable<any> {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("authorization", this._appservice.getToken());
    var tmp = { vehiculo: vehiculo };
    var params = JSON.stringify(tmp);
    return this._http.post(this.url + "updatevehiculo/" + Id, params, {
      headers: headers,
    });
  }

  gettarjetas(query): Observable<any> {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("authorization", this._appservice.getToken());
    var tmp = { query: query };
    var params = JSON.stringify(tmp);
    return this._http.post(this.url + "gettarjetas", params, {
      headers: headers,
    });
  }

  guardartarjeta(tarjeta): Observable<any> {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("authorization", this._appservice.getToken());
    var tmp = { tarjeta: tarjeta };
    var params = JSON.stringify(tmp);
    return this._http.post(this.url + "guardartarjeta", params, {
      headers: headers,
    });
  }

  borratarjeta(tarjetaid): Observable<any> {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("authorization", this._appservice.getToken());
    return this._http.delete(this.url + "borrartarjeta/" + tarjetaid, {
      headers: headers,
    });
  }

  gettarjeta(tarjetaid): Observable<any> {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("authorization", this._appservice.getToken());
    return this._http.get(this.url + "gettarjeta/" + tarjetaid, {
      headers: headers,
    });
  }

  updatetarjeta(tarjeta, Id): Observable<any> {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("authorization", this._appservice.getToken());
    var tmp = { tarjeta: tarjeta };
    var params = JSON.stringify(tmp);
    return this._http.post(this.url + "updatetarjeta/" + Id, params, {
      headers: headers,
    });
  }

  getgastos(query): Observable<any> {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("authorization", this._appservice.getToken());
    var tmp = { query: query };
    var params = JSON.stringify(tmp);
    return this._http.post(this.url + "getgastos", params, {
      headers: headers,
    });
  }

  guardargasto(gasto): Observable<any> {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("authorization", this._appservice.getToken());
    var tmp = { gasto: gasto };
    var params = JSON.stringify(tmp);
    return this._http.post(this.url + "guardargasto", params, {
      headers: headers,
    });
  }

  borragasto(gastoid): Observable<any> {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("authorization", this._appservice.getToken());
    return this._http.delete(this.url + "borrargasto/" + gastoid, {
      headers: headers,
    });
  }

  getgasto(gastoid): Observable<any> {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("authorization", this._appservice.getToken());
    return this._http.get(this.url + "getgasto/" + gastoid, {
      headers: headers,
    });
  }

  updategasto(gasto, Id): Observable<any> {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("authorization", this._appservice.getToken());
    var tmp = { gasto: gasto };
    var params = JSON.stringify(tmp);
    return this._http.post(this.url + "updategasto/" + Id, params, {
      headers: headers,
    });
  }
}
