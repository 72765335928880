<div style="width:100%;height:100%">
	<div style="display:inline-flex;width:100%;border-bottom: 0.5px solid black;">
		<div matTooltip="Nueva Tarjeta" class="botonbarraherramientas fondooscuro" (click)="nuevatarjeta()" style="height:30px;margin-top:10px;">
			<span class="material-icons">price_change</span>
		</div>
	</div>
	<div *ngIf="tarjetas" class="contenidodatos">
		<table mat-table [dataSource]="tarjetas">
			<ng-container matColumnDef="Tarjeta">
				<th mat-header-cell *matHeaderCellDef>Tarjeta </th>
				<td mat-cell *matCellDef="let element"> {{element.matricula}}   {{element.nombre}}  {{element.caducidad}}  {{element.numero}}   {{element.notas}}  </td>
			</ng-container>
			<ng-container matColumnDef="Mensajero">
				<th mat-header-cell *matHeaderCellDef>Mensajero </th>
				<td mat-cell *matCellDef="let element">
					<mat-form-field class="datovehiculo">
						<input matInput type="text" [matAutocomplete]="auto4" [(ngModel)]="element.mensajero" (ngModelChange)="guardartarjeta(element)">
						<button mat-button *ngIf="element.mensajero" matSuffix mat-icon-button aria-label="Clear" (click)="element.mensajero='';guardartarjeta(element)">
							<mat-icon>close</mat-icon>
						</button>
						<mat-autocomplete #auto4="matAutocomplete">
							<mat-option *ngFor="let mensajero of mensajeros" [value]="mensajero.numero + '-' + mensajero.nombre" >
								{{mensajero.numero}}-{{mensajero.nombre}}
							</mat-option>
						</mat-autocomplete>
					</mat-form-field>
				</td>
			</ng-container>
			<ng-container matColumnDef="Total">
				<th mat-header-cell *matHeaderCellDef>Total Gastado </th>
				<td mat-cell *matCellDef="let element">
					<div *ngIf="gastos[element.nombre + '-' + element.matricula]">
						{{gastos[element.nombre + '-' + element.matricula].toFixed(2)}} €
					</div>
					<div *ngIf="!gastos[element.nombre + '-' + element.matricula]">
						0 €
					</div>
				</td>
			</ng-container>
			<ng-container matColumnDef="Total">
				<th mat-header-cell *matHeaderCellDef>Notas</th>
				<td mat-cell *matCellDef="let element">
					<mat-form-field>
						<textarea  matInput rows="2" [(ngModel)]="element.notas" (blur)="guardartarjeta(element)"></textarea>
					</mat-form-field>
				</td>
			</ng-container>
			<tr mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></tr>
			<tr mat-row *matRowDef="let row; columns: displayedColumns;" (dblclick)="abretarjeta(row)"></tr>
		</table>
    </div>
</div>
