import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Incidencia } from '../../models/incidencia';
import { ClienteService } from '../../servicios/cliente.service';
import { IncidenciasService } from '../../servicios/incidencias.service';
import { PlantillaService } from '../../servicios/plantilla.service';
import { EmailsService } from '../../servicios/emails.service';
import { ImagenesexpedienteComponent } from '../imagenesexpediente/imagenesexpediente.component';
import { Office365Service } from '../../servicios/office365.service';
import { saveAs } from "file-saver";
import * as moment from 'moment';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition, MatSnackBarConfig } from '@angular/material/snack-bar';
import { FormControl } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { AppService } from '../../servicios/app.service';
import * as Editor from '../../ck5editor/ckeditor';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete';

@Component({
  selector: 'app-albaran',
  templateUrl: './albaran.component.html',
  styleUrls: ['./albaran.component.css'],
  providers: [ClienteService, IncidenciasService, PlantillaService, EmailsService, AppService, Office365Service]
})
export class AlbaranComponent implements OnInit{
    public isErrorState(control: FormControl | null){
    return !!(control && control.invalid && (control.dirty || control.touched));
    }
    public matcher = new ErrorStateMatcher();
    public identity;
	  public albaranrd: string;
    public gestionado: string;
    public notas: string;
    public listaubicaciones;
    public ubicacionseleccionada;
    public email;
    public emaildestino;
    public mensaje;
    public plantillas;
    public nuevaplantilla;
    public editplantilla;
    public plantillaseleccionada;
    public abonadomail;
    public asuntomail;
    public albG3;
    public dirExpediente;
    public notaexpediente;
    public tiponota;
    public listatiponota;
    public imagenes;
    public imagenactual;
    public numimagen: number;
    public cantidadimagenes: number;
    public listaemails;
    public tokenoffice;
    public dirmensajes;
    public veremails;
    public emailseleccionado;
    public adjuntarimgexpediente;
    public contestarexpediente;
    public contactos;
    public myControl = new FormControl('',[]);
    public filteredOptions: Observable<any[]>;
    public alb;
    public cuadroalbaran;
    public htmlanterior;
    public listaexpediente;
    public columnasexpediente;
    public Editor = Editor;
    public mensajenuevo;
    public destinatarionuevo = new Array();
    public separatorKeysCodes: number[] = [ENTER, COMMA];
    public direccionaleiminar;
    public direcciones;

    @ViewChild('destinatariosinput') destinatariosinput: ElementRef<HTMLInputElement>;
    @ViewChild('auto') matAutocomplete: MatAutocomplete;

  constructor(
    private _clienteService: ClienteService,
    private _office365Service: Office365Service,
    private _incidenciasservice: IncidenciasService,
    private _plantillasservice: PlantillaService,
    private _emailsService: EmailsService,
  	public dialogoAlbaran: MatDialogRef<AlbaranComponent>,
    public ventanaImagenes: MatDialog,
    private _snackBar: MatSnackBar,
    private _appservice: AppService,
    @Inject(MAT_DIALOG_DATA) public data: Incidencia
  	) {
      this.identity = this._appservice.getIdentity();
      this.columnasexpediente = ['Estado','FechaEntrada','HoraEntrada','Descripcion','Detalle','Usuario'];
      this.dirmensajes = "https://graph.microsoft.com/v1.0/me/messages";
      this.email = undefined;
      this.veremails = undefined;
      this.listaubicaciones = new Array();
      this.listaubicaciones = ['','Pendiente Cliente', 'Pendiente Agencia', 'Para Entregar', 'Recoge en agencia', 'Entregado', 'Cancelado', 'Especial/vigilar','Devuelto/Reexpedido','No Recibido', 'Recogida aplazada']; 
      this.ubicacionseleccionada = this.data.gestionado;
      this.gestionado = this.data.gestionado;
      this.albaranrd = this.data.albaranrd;
      this.notas = this.data.notas;
      this.numimagen = 0;
      this.cuadroalbaran = false;
      this.editplantilla = undefined;
      this.nuevaplantilla = {};
      this.nuevaplantilla.texto = null;
      this.listatiponota = ['Pública Notificada', 'Pública','Privada'];
      this.tiponota = 'Pública Notificada';
      this.dirExpediente = 'https://www2-vpc.mrw.es/gestion3/Operativo/Popups/ExpedienteEnvio2?Arguments=' + this.data.Id + ';' + this.data.NumAlbaran + ';' + this.data.Estado + ';' + this.data.Fase + ';;;' + this.data.FechaEnvio + ';' + this.data.FranquiciaOrigen + ';' + this.data.FranquiciaDestino;
      this.getplantillas('general');
      this.getemails(this.data.NumAlbaran);
      this._emailsService.getlista(this.identity._id).subscribe(
        response=>{
          if(response.lista){
            this.contactos = response.lista;
            this.filteredOptions = this.myControl.valueChanges
              .pipe(
              startWith(''),
              map(name => name ? this.filtro(name) : this.contactos.slice())
              );
          } 
        },
        error=>{
          console.log(<any>error);
        }
      );
      this._clienteService.getemail(this.data.CodigoAbonado).subscribe(
        response=>{
          if(response && !response.message){
            this.abonadomail = response.email;
          }else{
            this.getemailg3(); 
          }
        },
        error=>{
          if(error.message){
            /*this.aviso(`el cliente no esta dado de alta en la plataforma mensasur.es
            por favor, comunicar al abonado para que ingrese sus datos en www.mensasur.es/registro`, 'error')*/
            this.getemailg3(); 
          }  
        }  
      );
      this.albarang3(this.data.NumAlbaran);
    }


  ngOnInit(): void {
    this.getimgexp();
  }

  filtro(name: string){
    const filterValue = name.toLowerCase();
   return this.contactos.filter(option => option.toLowerCase().indexOf(filterValue) === 0);
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.destinatariosinput.nativeElement.value = '';
    this.myControl.setValue(null);
    if(event.option.value.includes(';')){
      let direcciones = event.option.value.split(';');
      this.direcciones = event.option.value;
      direcciones.forEach(direccion=>{
        this.destinatarionuevo.push(direccion);
      });
    }else{
      this.destinatarionuevo.push(event.option.value);
    }  
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    if ((value || '').trim()) {
      if(value.includes(';')){
        let direcciones = value.split(';');
        this.direcciones = value;
        direcciones.forEach(direccion=>{
          this.destinatarionuevo.push(direccion.trim());
        });
      }else{
        this.destinatarionuevo.push(value.trim());
      }  
    }
    if (input) {
      input.value = '';
    }
    this.myControl.setValue(null);
  }

  remove(destinatario: string): void {
    const index = this.destinatarionuevo.indexOf(destinatario);
    if (index >= 0) {
      this.destinatarionuevo.splice(index, 1);
    }
  }

  getemailg3(){
    var query = {Abonado:this.data.CodigoAbonado};
    this._clienteService.getcecos(query).subscribe(
      response=>{
        if(response.cecos.length >=1){
          this.abonadomail = response.cecos[0].Email;
        }
      }
    )
  }

  getimgexp(){
    this._incidenciasservice.imgExp(this.data.NumAlbaran, this.dirExpediente).subscribe(
      response =>{
          if(response.resp){
            this.imagenes = new Array();
            var tmp = response.resp;
            this.cantidadimagenes = tmp.length;
            tmp.forEach((imagen)=>{
              this.imagenes.push('data:image/jpg;base64,' + imagen.ImageBase64);
            });
            this.numimagen = 0;
            if(tmp.length >=1) this.imagenactual = 'data:image/jpg;base64,' + tmp[this.numimagen].ImageBase64;
          }
      },
       error =>{
        console.log(<any>error);
       } 
    );
  }

  parsefecha(fecha){
    if(fecha){
      return moment(fecha).format('DD/MM/YYYY');
    }else{
      return '';
    }
  }

  parsefechahms(fecha){
    if(fecha){
      return moment(fecha).format('DD/MM/YYYY HH:mm:ss');
    }else{
      return '';
    }  
  }

  humanizafrecuencia(f){
    var frecuencia;
    switch(f){
      case '1':
        frecuencia = '1º Diurno';
        break;
      case '2':
        frecuencia = '2º Diurno';
        break;
      case 'S':
        frecuencia = 'Entrega en sabado';
        break;
      default:
        frecuencia = undefined;
        break;
    }
    return frecuencia;
  }

  cancelar() {
    this.closeDialog();
  }

  closeDialog() {
    this.dialogoAlbaran.close();
  }

  aviso(texto, subtexto) {
    this._snackBar.open(texto,subtexto,{
      duration: 5000,
      horizontalPosition: "center",
      verticalPosition: "top",
    });
  }

  ubicar(){
    if(!this.ubicacionseleccionada){
      delete this.data.gestionado;
      this._incidenciasservice.borrarincidencia(this.data.NumAlbaran).subscribe(
        response=>{
            if(response.message) this._incidenciasservice.guardarincidencia(this.data).subscribe(response =>{});
          },
        error=>{
          console.log(<any>error);
        });
    }else{
      this.data.gestionado = this.ubicacionseleccionada;
    };
  }

  guardaincidencia(){
     this._incidenciasservice.guardarincidencia(this.data).subscribe(
        response =>{
            this.aviso('incidencia guardada', 'ok');
            this.dialogoAlbaran.close(this.data);
          },
         error =>{
          console.log(<any>error);
         } 
      );
  }

  muestramail(dato){
    var firma = this.identity.options.firmamail ? this.identity.options.firmamail: '';
    if(dato == 'mailalvuelo'){
      this.email = 'mailcliente';
      this.getplantillas('mailcliente');
    }else{
      this.email = dato;
      this.getplantillas(dato);
    }

    if(this.email == 'mailcliente'){
      this.asuntomail = 'MRW, Comunicacion Incidencia envio: ' + this.data.NumAlbaran;
    }else{
      this.asuntomail = 'Anomalia envio: ' + this.data.NumAlbaran + ' cliente: ' + this.data.CodigoAbonado + ' agencia 02601';
    }
    
    if(dato == 'mailagenciadestino'){
      this.emaildestino = this.data.FranquiciaDestino + '@grupomrw.com';
    }
    if(dato == 'mailagenciaorigen'){
      this.emaildestino = this.data.FranquiciaOrigen + '@grupomrw.com';
    }
    
    if(this.emailseleccionado){
      this.asuntomail = 'RV: ' + this.emailseleccionado.subject;
    }
  
    this.mensajenuevo = {
      message: {
        subject: this.asuntomail,
        body: {
          contentType: "HTML",
          content: this.emailseleccionado ? '<br><br>' + firma + '<br>' + 
          '<hr><div style="font-size:8pt"><br> De: ' + this.emailseleccionado.from.emailAddress.address + '<br> Enviado: ' + 
          this.emailseleccionado.sentDateTime + '<br> Para: ' + this.emailseleccionado.toRecipients[0].emailAddress.address +
          '<br> Asunto: ' + this.emailseleccionado.subject
          + '</div><br><div>' + this.emailseleccionado.body.content : '<br><br>' + firma
        },
        toRecipients: [
          { emailAddress:{ 
            name: this.emaildestino ? this.emaildestino : '',
            address: this.emaildestino ? this.emaildestino : ''
            }
          }
        ],
        sender:{
          emailAddress:{
                address:"02601@grupomrw.com",
                name:"Mensasur Getafe"
            }
        },
        from:{
          emailAddress:{
                address:"02601@grupomrw.com",
                name:"Mensasur Getafe"
            }
        }
      }
    }

    if(dato == 'mailcliente'){
      if(this.abonadomail.includes(';')){
        this.emaildestino = undefined;
        let direcciones = this.abonadomail.split(';');
        this.mensajenuevo.message.toRecipients = direcciones.map(direccion=>{
          let destino = {
            emailAddress:{
              name: direccion.trim(),
              address: direccion.trim()
            }
          }
          return destino;
        });
      }else{
        this.mensajenuevo.message.toRecipients[0].emailAddress.name = this.abonadomail.trim();
        this.mensajenuevo.message.toRecipients[0].emailAddress.address = this.abonadomail.trim();
      }
    }

    if(dato == 'mailalvuelo'){
      if(this.destinatarionuevo.length >=1){
        this.mensajenuevo.message.toRecipients = this.destinatarionuevo.map((destinatario)=>{
          let destino = {
            emailAddress:{
              name: destinatario,
              address: destinatario
            }
          }
          return destino;
        });
      } 
    }
  }

  cancelamail(){
    this.mensajenuevo = undefined;
    this.email = undefined;
    this.plantillaseleccionada = null;
    this.emailseleccionado = undefined;
    this.getplantillas('general');
    this.destinatarionuevo = new Array();
  }

  cancelacontestaexpediente(){
    this.notaexpediente = undefined;
    this.plantillaseleccionada = null;
    this.contestarexpediente = undefined;
  }


  fileChangeEvent(fileInput: any){
    if(!this.mensajenuevo.message.attachments){
      this.mensajenuevo.message['attachments'] = new Array();
    }  
      var archivos = <Array<File>>fileInput.target.files;
      for(let file of archivos){
          readFileAsync(file).then((data)=>{return data}).then((a:any)=>{
              let partes = a.split(";");
              let content = partes[1].substring(7);
              let tipo = partes[0].split(':');
              var archivo = {
              '@odata.type': '#microsoft.graph.fileAttachment',
              'contentType': tipo[1],
              'name': file.name,
              'contentBytes': content
              };
              this.mensajenuevo.message.attachments.push(archivo);
          }).catch(err=>{
            console.log(err);
          });  
       }
  
      function readFileAsync(file) {
        return new Promise((resolve, reject) => {
          let reader = new FileReader();
  
          reader.onload = () => {
            resolve(reader.result);
          };
  
          reader.onerror = reject;
  
          reader.readAsDataURL(file);
        })
      }
  }  

 eliminarAdjunto(indice){
    this.mensajenuevo.message.attachments.splice(indice, 1);
  }

  getplantillas(tipo){
    this._plantillasservice.getplantillas(this.identity._id, tipo).subscribe(
      response=>{
        this.plantillas = response.plantillas;
      },
      error=>{
        console.log(<any>error);
      }  
    );
  }

  newplantilla(){
    if(this.email){
      this.nuevaplantilla.tipo = this.email;
      }else{
        this.nuevaplantilla.tipo = 'general';
      }
    this.nuevaplantilla.usuario = this.identity._id;
    this.nuevaplantilla.texto = "";
    this.plantillaseleccionada = null;
  }

  saveplantilla(){
    this._plantillasservice.nuevaplantilla(this.nuevaplantilla).subscribe(
      response=>{
          if(response.message) this.aviso(response.message, 'ok');
          this.getplantillas(this.nuevaplantilla.tipo);
          this.nuevaplantilla = {};
          this.nuevaplantilla.texto = null;
          
        },
      error=>{
        console.log(<any>error);
      }  
    );
  }

  editaplantilla(){
    this.editplantilla = this.plantillaseleccionada;
  }

  updateplantilla(){
    this._plantillasservice.actualizarplantilla(this.plantillaseleccionada).subscribe(
      response=>{
          if(response.message) this.aviso(response.message, 'ok');
          this.editplantilla = undefined;
        },
      error=>{
        console.log(<any>error);
      }  
    );
  }

  cancelplantilla(){
    this.getplantillas(this.nuevaplantilla.tipo);
    this.nuevaplantilla = {};
    this.nuevaplantilla.texto = null;
  }

  canceleditplantilla(){
    this.editplantilla = undefined;
  }

  selecplantilla(plantilla){
    this.plantillaseleccionada = plantilla;
  }

  pasartextoplantilla(plantilla){
      this.mensajenuevo.message.body.content = '<br>' + plantilla + '<br>' + this.mensajenuevo.message.body.content;  
  }

  pasartextonota(plantilla){
    if(this.notaexpediente){
      this.notaexpediente = this.notaexpediente + '\n\r' + plantilla;  
      }else{
        this.notaexpediente = plantilla;
      };
  }

  eliminaPlantilla(plantilla){
    this._plantillasservice.eliminarplantilla(plantilla._id).subscribe(
      response=>{
          if(response.message) this.aviso(response.message, 'ok');
          this.plantillaseleccionada = null;
          this.getplantillas(plantilla.tipo);
        },
      error=>{
        console.log(<any>error);
      }  
    );
  }

  incluyeincidencia(){
    
    var incidencia = 'El envio: ' + this.data.NumAlbaran + ', con destino: ' + this.data.Destinatario + ',  no ha podido ser entregado por: \n' + this.data.Estado;
    this.pasartextoplantilla(incidencia);
  }

  incluyeincidenciaorigen(){
          
    var incidencia = 'La recogida: ' + this.data.NumAlbaran + ', con origen: ' + this.data.Remitente + ', no ha podido ser recogido por: \n' + this.data.Estado;
    this.pasartextoplantilla(incidencia);
    
  }

  incluyealbaran(){
     this.pasartextoplantilla(this.data.NumAlbaran);
  }

  incluyeref(){
     this.pasartextoplantilla(this.data.ReferenciaCliente);
  }

  enviarEmail(){
    var dir = "https://graph.microsoft.com/v1.0/me/sendMail";
    this.mensajenuevo.message['sentDateTime'] = moment().toISOString();   
    this._office365Service.enviarRespuesta(dir, this.mensajenuevo).subscribe(
      response=>{
        if(response.status != 202){
          this.aviso('ha ocurrido un error al enviar el mensaje', 'error');
        }else{
         this.aviso('Mensaje Enviado', 'Ok');
         this.guardaremail(this.mensajenuevo, this.data);
         this.email = undefined;
         if(this.destinatarionuevo.length >=1){
          this.destinatarionuevo.forEach((dest=>{
            this.guardacontacto(dest);
          }))
         }
         if(this.direcciones) this.guardacontacto(this.direcciones);
         this.mensajenuevo = undefined;
         this.destinatarionuevo = new Array();
        }
      },
      error=>{
        console.log(<any>error);
      }  
    );
    console.log(this.mensajenuevo);
  }

  guardacontacto(contacto){
    if(!this.contactos) this.contactos = new Array();
      if(!this.contactos.includes(contacto)){
        this.contactos.push(contacto);
        this._emailsService.savelista(this.contactos, this.identity._id).subscribe(
          response=>{
          },
          error=>{
            console.log(<any>error);
          }
        );
      }  
  }

  borraopcion(opcion){
    this.contactos.splice(this.contactos.indexOf(opcion), 1);
    this._emailsService.savelista(this.contactos, this.identity._id).subscribe(
      response=>{
        if(response){
          this.myControl.setValue('');
          this.aviso('opcion eliminada', 'ok');
        }  
      },
      error=>{
        console.log(<any>error);
      });
  }      

  intranet(albaran){
    window.open('http://intranet.mrw.es/espana/Aplicaciones/historia/esepes/portal_repnac.asp?Albaran=' + albaran);
  }

  expediente(){
    window.open(this.dirExpediente);
  }

  albarang3(albaran){
          this._incidenciasservice.albaranG3(albaran).subscribe(
            response=>{
                if(response.datos){
                  this.albG3 = response.datos;
                  if(this.albG3.Estado != this.data.Estado){
                    if(this.albG3.CodigoAbonado)this.albG3.CodigoAbonado = this.albG3.CodigoAbonado.substring(-1, 6);
                    if(this.data.albaranrd) this.albG3.albaranrd = this.data.albaranrd;
                    if(this.data.gestionado) this.albG3.gestionado = this.data.gestionado;
                    if(this.data.notas) this.albG3.notas = this.data.notas;
                    this._incidenciasservice.guardarincidencia(this.albG3).subscribe(
                      response =>{
                          this.data.Estado = this.albG3.Estado;
                          this.aviso('Estado actualizado, incidencia guardada', 'ok');
                       },
                       error =>{
                        console.log(<any>error);
                       } 
                    );
                  }
                }else{
                  this.aviso(response.message, 'error');
                } 

               },
            error=>{
              console.log(<any>error)
            }  
          );
  }

  notag3(){
    var h = new Date().getHours();
    var m = new Date().getMinutes();
    var hora = h + ':' + m;    

    var postit = {idenvio: this.data.Id, nota: this.notaexpediente, hora: hora, tipo: this.tiponota};

    this._incidenciasservice.notaG3(postit).subscribe(
            response=>{
                if(response.message) this.aviso(response.message, 'ok');
                this.notaexpediente = undefined;
                this.tiponota = undefined;
                if(this.contestarexpediente) this.contestarexpediente = undefined;
               },
            error=>{
              console.log(<any>error);
              this.aviso('error al enviar la nota', 'error');
            }  
    );
  }

  anteriorimg(){
    this.numimagen = this.numimagen -1;
    this.imagenactual = this.imagenes[this.numimagen];
    
  }

  siguienteimg(){
    this.numimagen = this.numimagen +1;
    this.imagenactual = this.imagenes[this.numimagen];
    
  }

  abreimagenes(incidencia){
      
    this.ventanaImagenes.open(ImagenesexpedienteComponent, {
      width: '85%',
      height: '85%',
      data: incidencia});
   }

   getemails(albaran){
      this._emailsService.getemails(albaran).subscribe(
        response=>{
          this.listaemails = response.emails;
          },
        error=>{
          console.log(<any>error);
        }  
       );
   }

   abrirmensaje(email){
    this.email = undefined;
    this.emailseleccionado = email;
    this.veremails = this.listaemails;
  }

  seleccionamensaje(email){
    this.email = undefined;
    this.emailseleccionado = email;
  }

   cancelavistaemails(){
    this.veremails = undefined;
    this.emailseleccionado = undefined;
   }

   guardaremail(email, envio){
    var albaranlinkar = envio;
    if(this.mensajenuevo.message.attachments && this.mensajenuevo.message.attachments.length >=1){
        email['adjuntos'] = this.mensajenuevo.message.attachments;
    }    
    this._emailsService.guardaremail(email.message, albaranlinkar).subscribe(
        response=>{
          if(response) this.getemails(this.data.NumAlbaran);
        },
        error=>{
          console.log(<any>error);
      });
  }

  getadjunto(adjunto){
    var descarga = 'data:' + adjunto.contentType + ';base64,' + adjunto.contentBytes
      saveAs(descarga, adjunto.name);
  }


  adjuntarimagenexpediente(indice){
      var tmp = this.imagenes[indice];
      let partes = tmp.split(";");
      let content = partes[1].substring(7);
      let tipo = partes[0].split(':');
      var archivo = {
      '@odata.type': '#microsoft.graph.fileAttachment',
      'contentType': tipo[1],
      'name': 'imagenExpediente'+indice+'.png',
      'contentBytes': content
      };
      if(!this.mensajenuevo.message.attachments) this.mensajenuevo.message.attachments = new Array();
      this.mensajenuevo.message.attachments.push(archivo);
  }

  contestaexpediente(){
    this.contestarexpediente = true;
  }

  noveremails(){
    this.veremails = undefined;
  }

  mostrarcuadroalbaran(){
    if(this.cuadroalbaran == false){
      this.cuadroalbaran = true;
    }else{
      this.cuadroalbaran = false;
    }
  }

 linkaremail(mail, envio){
    var albaran = envio.trim();
    var email = mail;
    email.usuario = this.identity._id;
    email.albaran = albaran;

    this._emailsService.updatemail(email).subscribe(
      response=>{
        this.aviso('albaran guardado', 'ok');
        this.getemails(this.data.NumAlbaran);
        this.cuadroalbaran = false;
        this.alb = undefined;
      },
      error=>{
        console.log(<any>error);
      });
  }

  bucaryabrir(albaran){
    this._incidenciasservice.getincidencia(albaran).subscribe(
        response =>{
          if(response.incidencia){
            this.data = response.incidencia;
            this.dirExpediente = 'https://www2-vpc.mrw.es/gestion3/Operativo/Popups/ExpedienteEnvio2?Arguments=' + this.data.Id + ';' + this.data.NumAlbaran + ';' + this.data.Estado + ';' + this.data.Fase + ';;;' + this.data.FechaEnvio + ';' + this.data.FranquiciaOrigen + ';' + this.data.FranquiciaDestino;
            this.albarang3(this.data.NumAlbaran);
            this.getemails(this.data.NumAlbaran);
            this.getimgexp();
          }else{
              this._incidenciasservice.albaranG3(albaran).subscribe(
                  response =>{
                    if(response.datos){
                      var inc = response.datos;
                        if(inc.CodigoAbonado)inc.CodigoAbonado = inc.CodigoAbonado.substring(-1, 6);
                        switch(inc.Motivo){
                            case 'OT0700':
                              inc.Motivo = 'Clasificado en plataforma';
                              break;
                            case 'OE0062': 
                              inc.Motivo = 'Depositado en Franquicia de destino';
                              break;
                            case 'OE0600':
                              inc.Motivo = 'Mensajero de entrega asignado';
                              break;  
                            case 'OR0600':
                              inc.Motivo = 'Mensajero de recogida asignado';
                              break; 
                            case 'OE0500':
                              inc.Motivo = 'Recibido en franquicia de destino';
                              break;
                            case 'OR0200':
                              inc.Motivo = 'Recogida finalizada';
                              break;
                            case 'OS0200':
                              inc.Motivo = 'Recogida pendiente';
                              break;
                            case 'OE0100':
                              inc.Motivo = 'Tránsito interno de entrega';
                              break;
                            default:
                              inc.Motivo = inc.Motivo;    
                        }
                        this.data = inc;
                        this.albarang3(this.data.NumAlbaran);
                        this.getemails(this.data.NumAlbaran);
                        this.getimgexp();
                     }  
                  },
                  error=>{
                    console.log(<any>error);
                  });
          }              
        },
        error=>{
          console.log(<any>error);
        });  
  } 

  getexpediente(){
    this._incidenciasservice.getexpediente(this.data).subscribe(
      response=>{
          if(response.datos){
            this.listaexpediente = response.datos;
          }else{
            this.listaexpediente = undefined;
          }  
        },
      error=>{
        console.log(<any>error);
      });
  }
  
  exportar(mensaje){
    this._emailsService.exportarmail(mensaje).subscribe(
      response=>{
        saveAs(response, 'mensaje.eml');
      },
      error=>{
        console.log(error);
      }
    )
	}

}


