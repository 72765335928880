import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as moment from 'moment';
import { FichajeService } from '../../servicios/fichaje.service';

@Component({
  selector: 'dialogfichamensajero',
  templateUrl: './dialogfichamensajero.component.html',
  styleUrls: ['./dialogfichamensajero.component.css'],
  providers:[FichajeService]
})
export class DialogfichamensajeroComponent implements OnInit {
  public hora = moment().format('HH:mm:ss');
  public horas = this.hora.split(':')[0];
  public minutos = this.hora.split(':')[1];
  public segundos = this.hora.split(':')[2];
  public usuario;
  constructor(
    public dialogRef: MatDialogRef<DialogfichamensajeroComponent>,
    private _fichajeService: FichajeService,
    @Inject(MAT_DIALOG_DATA) public data
  ){
  }

  cancelar(){
      this.dialogRef.close('cancelado fichaje');
  }

  fichar(){
    if(this.horas.toString().length <=1){
      this.horas = '0' + this.horas;
    }
    
    if(this.minutos.toString().length <=1){
      this.minutos = '0' + this.minutos;
    }
  
    if(this.segundos.toString().length <=1){
      this.segundos = '0' + this.segundos;
    }
    this.data[this.data.campo] = this.horas + ':' + this.minutos + ':' + this.segundos;
    let h = parseInt(this.horas);
    let m = parseInt(this.minutos);
    let s = parseInt(this.segundos);
    this.data[this.data.campo2] = moment(this.data.fecha, 'DD/MM/YYYY').set({hour:h,minute:m,second:s,millisecond:0}).toISOString();
 
    if(this.data.estado == 'sin datos'){
      this.data.estado = false;
      this.guardafichaje('entrada fichada a las: ' + this.horas + ':' + this.minutos + ':' + this.segundos);
    }else{
      this.updatefichaje(this.data, 'entrada fichada a las: ' + this.horas + ':' + this.minutos + ':' + this.segundos);
    }
  }

  ngOnInit(): void {
    console.log(this.data);
  }

  guardafichaje(mensaje){
    this._fichajeService.fichajemensajero(this.data).subscribe(
      response=>{
        if(response){
          this.dialogRef.close(mensaje);
        }
      }
    )     
  }

  updatefichaje(datos, mensaje){
    let id = datos._id;
    delete datos._id;
    datos.estado = false;
    this._fichajeService.updatefichaje(datos, id).subscribe(
      response=>{
        if(response){
          this.dialogRef.close(mensaje);
        }
      }
    )
  }

  mas(campo){
    let num = parseInt(this[campo])
    num = num + 1;
    if(campo != 'horas' && num >= 60){
      num = 0;
    }
    if(campo == 'horas' && num >= 24){
      num = 0;
    }
    this[campo] = num.toString();
  }

  menos(campo){
    let num = parseInt(this[campo])
    num = num - 1;
    if(campo != 'horas' && num < 0){
      num = 59;
    }
    if(campo == 'horas' && num < 0){
      num = 23;
    }
    this[campo] = num.toString();
  }

}

