import { Component, OnInit, Inject } from '@angular/core';
import { VehiculoService } from '../../servicios/vehiculos.service';
import { Gasto } from '../../models/gasto';
import { ClienteService } from '../../servicios/cliente.service';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition, MatSnackBarConfig } from '@angular/material/snack-bar';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateModule, MomentDateAdapter } from '@angular/material-moment-adapter';
import * as moment from 'moment';

export const MY_FORMATS = {
  parse: {
      dateInput: 'LL'
  },
  display: {
      dateInput: 'DD/MM/YYYY',
      monthYearLabel: 'YYYY',
      dateA11yLabel: 'LL',
      monthYearA11yLabel: 'YYYY'
  }
};

@Component({
  selector: 'app-gasto',
  templateUrl: './gasto.component.html',
  styleUrls: ['./gasto.component.css'],
  providers: [VehiculoService, ClienteService,{ provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
  { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }]
})
export class GastoComponent implements OnInit {
  public vehiculos;
  public tarjetas;
  public fechagasto;
  public listatiendas;
  public mensajeros;
  public numerotarjeta;
  constructor(
    public dialogoGasto: MatDialogRef<GastoComponent>,
    private _snackBar: MatSnackBar,
    private _vehiculoService: VehiculoService,
    private _clienteService: ClienteService,
    @Inject(MAT_DIALOG_DATA) public data: Gasto
  ) { 
    var query = {fechabaja:{$in:[null, '']}};
    this._vehiculoService.getvehiculos(query).subscribe(
      response=>{
        if(response.vehiculos) this.vehiculos = response.vehiculos;  
      },
      error=>{
        console.log(<any>error);
      }
    );
    this._vehiculoService.gettarjetas({}).subscribe(
      response=>{
        if(response.tarjetas) this.tarjetas = response.tarjetas;
      },
      error=>{
        console.log(<any>error);
      }
    );
    if(this.data.fecha) this.fechagasto = moment(this.data.fecha).format('DD/MM/YYYY');
    this.listatiendas = ['BP', 'REPSOL', 'REMOLCAUTO', 'VOLKSWAGEN', 'TALLERES BOXTER', 'NEUMATICOS MEDINA'];
    this._clienteService.getmensajeros().subscribe(
      response=>{
        this.mensajeros = response.mensajeros.sort(function(a, b){
          return a['numero'] > b['numero'];
        });
      },
      error=>{
        console.log(<any>error);
      }
    )    
  }

  ngOnInit(): void {
  }

  aviso(texto, subtexto) {
    this._snackBar.open(texto,subtexto,{
      duration: 5000,
      horizontalPosition: "center",
      verticalPosition: "top",
    });
  }

  closeDialog(){
    this.dialogoGasto.close(true);
  }

  seleccionanumerotarjeta(event){
    this.data.tarjeta = event.nombre + '-' + event.matricula;
    this.numerotarjeta = event.numero;
  }

  seleccionatarjeta(event){
    let matricula = event.split('-');
    let tj = this.tarjetas.filter(tarjeta=>tarjeta.matricula == matricula[1]).map(trj=>{
        return trj.numero;
    });
    this.numerotarjeta = tj[0];
  }

  guardargasto(){
    if(this.data.matricula && this.fechagasto){
      this._vehiculoService.guardargasto(this.data).subscribe(
        response=>{
            if(response.status == 200){
              this.aviso(response.message, 'Ok');
              this.closeDialog();
            }
          },
        error=>{
          this.aviso(error.error.message, 'error');
          console.log(<any>error);
        }  
      )
    }else{
      if(!this.data.matricula) this.aviso('falta introducir matricula', 'error');
      if(!this.fechagasto) this.aviso('falta fecha del gasto', 'error');
    }  
  }

  eliminargasto(){
    if(this.data._id){
      this._vehiculoService.borragasto(this.data._id).subscribe(
        response=>{
            if(response.gastoremoved){
              this.aviso('Gasto eliminado','Ok');
              this.closeDialog();
            }  
          },
        error=>{
          console.log(<any>error);
        }  
      );
    }else{
      this.closeDialog();
    }  
  }

  vergasto(){
    console.log(this.data);
  }

  asignarfecha(event){
    this.data.fecha =  moment(event.value).set({hour:4,minute:0,second:0,millisecond:0}).toDate();
  }

  parsenumero(){
    let num = this.data.importe.toString().replace(',','.');
    this.data.importe = parseFloat(num);
  }

 
}
