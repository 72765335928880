import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs/Observable";
import { GLOBAL } from "./global";
import { AppService } from "./app.service";

@Injectable()
export class DeudasService {
  public url: string;

  constructor(public _http: HttpClient, private _appservice: AppService) {
    this.url = GLOBAL.urldeudas;
  }

  guardardeuda(deuda): Observable<any> {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("authorization", this._appservice.getToken());
    let params = { deuda: deuda };
    let cuerpo = JSON.stringify(params);

    return this._http.post(this.url + "guardardeuda", cuerpo, {
      headers: headers,
    });
  }

  borrardeuda(deuda): Observable<any> {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("authorization", this._appservice.getToken());
    return this._http.delete(this.url + "borrardeuda/" + deuda, {
      headers: headers,
    });
  }

  getdeudas(query): Observable<any> {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("authorization", this._appservice.getToken());
    let params = { query: query };
    let cuerpo = JSON.stringify(params);
    return this._http.post(this.url + "getdeudas", cuerpo, {
      headers: headers,
    });
  }
}
