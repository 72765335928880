<div class="barraprincipalincidencias">
	<div class="cajaherramientasincidencias overfondo" matTooltip='Guardar' style="font-size:10pt;cursor:pointer;padding:5px;" (click)="guardar()">
		<mat-icon style="font-size:25pt">save</mat-icon><br>
		Guardar
	</div>
	<span class='spacerlargo'></span>
	<div class="albaran">{{data.albaran}}</div>
	<span class='spacerlargo'></span>
	<div class="cajaherramientasincidencias overfondo" style="cursor:pointer;padding: 5px;" (click)="cancelar()">
		<mat-icon style="font-size:25pt">close</mat-icon><br>
		Cancelar
	</div>
</div>
<div class="contenedor">
	<div class="lineacont">
		<div class="datocrudo"><b>Destinatario:</b> {{data.nombre}}</div>
		<div class="datocrudo"><b>Direccion:</b> {{data.direccion}}</div>
		<div class="datocrudo"><b>Poblacion:</b> {{data.poblacion}}</div>
		<div class="datocrudo"><b>Codigo Postal:</b> {{data.cp}}</div>
		<div class="datocrudo"><b>Telefono:</b> {{data.telefono}}</div>
	</div>
	<hr>
	<div class="camposedit">
		<mat-form-field style="margin-right:50px;" class="campo">
			<mat-label><b>Contacto</b></mat-label>
			<input matInput type="text" [(ngModel)]="data.contacto" style="padding:1px;color:black;">
			<button mat-button *ngIf="data.contacto" matSuffix mat-icon-button aria-label="Clear" (click)="data.contacto =''">
				<mat-icon>close</mat-icon>
			</button>
		</mat-form-field>
		<mat-form-field style="margin-right:50px;" class="campo">
			<mat-label><b>Referencia</b></mat-label>
			<input matInput type="text" [(ngModel)]="data.referencia" style="padding:1px;color:black;">
			<button mat-button *ngIf="data.referencia" matSuffix mat-icon-button aria-label="Clear" (click)="data.referencia =''">
				<mat-icon>close</mat-icon>
			</button>
		</mat-form-field>
		<mat-form-field style="margin-right:50px;" class="campo">
			<mat-label><b>Observaciones</b></mat-label>
			<input matInput type="text" [(ngModel)]="data.observaciones" style="padding:1px;color:black;">
			<button mat-button *ngIf="data.observaciones" matSuffix mat-icon-button aria-label="Clear" (click)="data.observaciones =''">
				<mat-icon>close</mat-icon>
			</button>
		</mat-form-field>
		<mat-form-field style="margin-right:50px;" class="campocorto">
			<mat-label><b>Bultos</b></mat-label>
			<input matInput type="text" [(ngModel)]="data.bultos" style="padding:1px;color:black;">
			<button mat-button *ngIf="data.bultos" matSuffix mat-icon-button aria-label="Clear" (click)="data.bultos =''">
				<mat-icon>close</mat-icon>
			</button>
		</mat-form-field>
	</div>
	<div style="margin-top: 60px;">
		<button mat-icon-button matTooltip="Cancelar Envio" class="overfondo" style="cursor:pointer;padding: 5px;" (click)="cancelarenvio()">
			<mat-icon style="font-size:25pt">delete_forever</mat-icon>
		</button>
	</div>
</div>
