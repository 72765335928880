<div class="barraseleccion">
    <div class="botonbarra" style="height:40px;">
        <button mat-icon-button matTooltip="Cargar Excel">
            <label for="file-upload" style="cursor:pointer;">
               <mat-icon>file_upload</mat-icon>
            </label>
        </button>
        <input id="file-upload" multiple="false" (change)="onFileChange($event)" type="file" style='display: none;'/>
    </div>
    <div class="botonbarra" style="height:40px;">
        <button mat-icon-button matTooltip="Comparar Servicios" (click)="category = 'servicios'">
               <mat-icon>departure_board</mat-icon>
        </button>
    </div>
    <div class="botonbarra" style="height:40px;">
        <button mat-icon-button matTooltip="Comparar Abonados" (click)="category = 'abonados'">
               <mat-icon>person_search</mat-icon>
        </button>
    </div>
    <div class="botonbarra" style="height:40px;">
        <button mat-icon-button matTooltip="Cargar Csv">
            <label for="file-upload" style="cursor:pointer;">
               <mat-icon>file_upload</mat-icon>
            </label>
        </button>
        <input id="file-upload" multiple="false" (change)="onCsvChange($event)" type="file" style='display: none;'/>
    </div>
    
    <!--<div class="botonbarraherramientas" style="margin-left:100px;">
		<mat-form-field style="text-align:center;font-size:10pt">
			<mat-label style="color:black;" (click)="picker.open()"><b>Rango Fechas</b></mat-label>
			<mat-date-range-input [rangePicker]="picker" (click)="picker.open()">
			  <input matStartDate placeholder="Fecha Inicio" [(ngModel)]="fechainicio">
			  <input matEndDate placeholder="Fecha fin" [(ngModel)]="fechafin" (dateChange)="onDaterangofechas()">
			</mat-date-range-input>
			<button mat-button *ngIf="fechainicio" matSuffix mat-icon-button aria-label="Clear" (click)="fechainicio=null;fechafin=null;">
			  <mat-icon>close</mat-icon>
			</button>
			<mat-date-range-picker #picker></mat-date-range-picker>
		</mat-form-field>
	</div>-->
</div>
<mat-progress-bar *ngIf="!load" mode="indeterminate"></mat-progress-bar>
<div *ngIf="category == 'abonados'" class="contenidodatos">
    categoria envios abonados
</div>    
<div *ngIf="category == 'servicios'" class="contenidodatos">    
    <div  class="contienecomparativas">
        <div class="comparativa">
            <div class="titulocomparativa">
                Urgente19Exp
            </div>
            <div class="cuerpocomparativa">
                <div class="ladocentral" (click)="serviciocentral('URGENTE 19 EXPEDICIÓN')">
                    <div *ngIf="serviciosCentral && serviciosCentral['URGENTE 19 EXPEDICIÓN']">
                        {{serviciosCentral['URGENTE 19 EXPEDICIÓN'].cantidad}}
                    </div>
                    <div class="origen">
                        Central
                    </div>
                </div>
                <div class="ladoagencia" (click)="servicioagencia('U19E')">
                    <div *ngIf="serviciosLocal && serviciosLocal['U19E']">
                        {{serviciosLocal['U19E'].cantidad}}
                    </div>
                    <div class="origen">
                        Agencia
                    </div>
                </div>
                <div class="ladoagencia" (click)="comparaservicio('URGENTE 19 EXPEDICIÓN', 'U19E')">
                    <div *ngIf="serviciosLocal && serviciosLocal['U19E'] && serviciosCentral && serviciosCentral['URGENTE 19 EXPEDICIÓN']">
                        {{serviciosLocal['U19E'].cantidad - serviciosCentral['URGENTE 19 EXPEDICIÓN'].cantidad}}
                    </div>
                    <div class="origen">
                        Diferencia
                    </div>
                </div>
            </div>
        </div>
        <div class="comparativa">
            <div class="titulocomparativa">
                Urgente19
            </div>
            <div class="cuerpocomparativa">
                <div class="ladocentral" (click)="serviciocentral('URGENTE 19')">
                    <div *ngIf="serviciosCentral && serviciosCentral['URGENTE 19']">
                        {{serviciosCentral['URGENTE 19'].cantidad}}
                    </div>
                    <div class="origen">
                        Central
                    </div>
                </div>
                <div class="ladoagencia" (click)="servicioagencia('U19')">
                    <div *ngIf="serviciosLocal && serviciosLocal['U19']">
                        {{serviciosLocal['U19'].cantidad}}
                    </div>
                    <div class="origen">
                        Agencia
                    </div>
                </div>
                <div class="ladoagencia" (click)="comparaservicio('URGENTE 19', 'U19')">
                    <div *ngIf="serviciosLocal && serviciosLocal['U19'] && serviciosCentral && serviciosCentral['URGENTE 19']">
                        {{serviciosLocal['U19'].cantidad - serviciosCentral['URGENTE 19'].cantidad}}
                    </div>
                    <div class="origen">
                        Diferencia
                    </div>
                </div>
            </div>
        </div>
        <div class="comparativa">
            <div class="titulocomparativa">
                E-COMMERCE
            </div>
            <div class="cuerpocomparativa">
                <div class="ladocentral" (click)="serviciocentral('E-COMMERCE')">
                    <div *ngIf="serviciosCentral && serviciosCentral['E-COMMERCE']">
                        {{serviciosCentral['E-COMMERCE'].cantidad}}
                    </div>
                    <div class="origen">
                        Central
                    </div>
                </div>
                <div class="ladoagencia" (click)="servicioagencia('ECOM')">
                    <div *ngIf="serviciosLocal && serviciosLocal['ECOM']">
                        {{serviciosLocal['ECOM'].cantidad}}
                    </div>
                    <div class="origen">
                        Agencia
                    </div>
                </div>
                <div class="ladoagencia" (click)="comparaservicio('E-COMMERCE', 'ECOM')">
                    <div *ngIf="serviciosLocal && serviciosLocal['ECOM'] && serviciosCentral && serviciosCentral['E-COMMERCE']">
                        {{serviciosLocal['ECOM'].cantidad - serviciosCentral['E-COMMERCE'].cantidad}}
                    </div>
                    <div class="origen">
                        Diferencia
                    </div>
                </div>
            </div>
        </div>
        <div class="comparativa">
            <div class="titulocomparativa">
                E-COMMERCE CANJE
            </div>
            <div class="cuerpocomparativa">
                <div class="ladocentral" (click)="serviciocentral('E-COMMERCE CANJE')">
                    <div *ngIf="serviciosCentral && serviciosCentral['E-COMMERCE CANJE']">
                        {{serviciosCentral['E-COMMERCE CANJE'].cantidad}}
                    </div>
                    <div class="origen">
                        Central
                    </div>
                </div>
                <div class="ladoagencia" (click)="servicioagencia('ECCJ')">
                    <div *ngIf="serviciosLocal && serviciosLocal['ECCJ']">
                        {{serviciosLocal['ECCJ'].cantidad}}
                    </div>
                    <div class="origen">
                        Agencia
                    </div>
                </div>
                <div class="ladoagencia" (click)="comparaservicio('E-COMMERCE CANJE', 'ECCJ')">
                    <div *ngIf="serviciosLocal && serviciosLocal['ECCJ'] && serviciosCentral && serviciosCentral['E-COMMERCE CANJE']">
                        {{serviciosLocal['ECCJ'].cantidad - serviciosCentral['E-COMMERCE CANJE'].cantidad}}
                    </div>
                    <div class="origen">
                        Diferencia
                    </div>
                </div>
            </div>
        </div>
        <div class="comparativa">
            <div class="titulocomparativa">
                Urgente14Exp
            </div>
            <div class="cuerpocomparativa">
                <div class="ladocentral" (click)="serviciocentral('URGENTE 14 EXPEDICIÓN')">
                    <div *ngIf="serviciosCentral && serviciosCentral['URGENTE 14 EXPEDICIÓN']">
                        {{serviciosCentral['URGENTE 14 EXPEDICIÓN'].cantidad}}
                    </div>
                    <div class="origen">
                        Central
                    </div>
                </div>
                <div class="ladoagencia" (click)="servicioagencia('U14E')">
                    <div *ngIf="serviciosLocal && serviciosLocal['U14E']">
                        {{serviciosLocal['U14E'].cantidad}}
                    </div>
                    <div class="origen">
                        Agencia
                    </div>
                </div>
                <div class="ladoagencia" (click)="comparaservicio('URGENTE 14 EXPEDICIÓN', 'U14E')">
                    <div *ngIf="serviciosLocal && serviciosLocal['U14E'] && serviciosCentral && serviciosCentral['URGENTE 14 EXPEDICIÓN']">
                        {{serviciosLocal['U14E'].cantidad - serviciosCentral['URGENTE 14 EXPEDICIÓN'].cantidad}}
                    </div>
                    <div class="origen">
                        Diferencia
                    </div>
                </div>
            </div>
        </div>
        <div class="comparativa">
            <div class="titulocomparativa">
                Urgente14
            </div>
            <div class="cuerpocomparativa">
                <div class="ladocentral" (click)="serviciocentral('URGENTE 14')">
                    <div *ngIf="serviciosCentral && serviciosCentral['URGENTE 14']">
                        {{serviciosCentral['URGENTE 14'].cantidad}}
                    </div>
                    <div class="origen">
                        Central
                    </div>
                </div>
                <div class="ladoagencia" (click)="servicioagencia('U14')">
                    <div *ngIf="serviciosLocal && serviciosLocal['U14']">
                        {{serviciosLocal['U14'].cantidad}}
                    </div>
                    <div class="origen">
                        Agencia
                    </div>
                </div>
                <div class="ladoagencia" (click)="comparaservicio('URGENTE 14', 'U14')">
                    <div *ngIf="serviciosLocal && serviciosLocal['U14'] && serviciosCentral && serviciosCentral['URGENTE 14']">
                        {{serviciosLocal['U14'].cantidad - serviciosCentral['URGENTE 14'].cantidad}}
                    </div>
                    <div class="origen">
                        Diferencia
                    </div>
                </div>
            </div>
        </div>
        <div class="comparativa">
            <div class="titulocomparativa">
                Urgente12Exp
            </div>
            <div class="cuerpocomparativa">
                <div class="ladocentral" (click)="serviciocentral('URGENTE 12 EXPEDICIÓN')">
                    <div *ngIf="serviciosCentral && serviciosCentral['URGENTE 12 EXPEDICIÓN']">
                        {{serviciosCentral['URGENTE 12 EXPEDICIÓN'].cantidad}}
                    </div>
                    <div class="origen">
                        Central
                    </div>
                </div>
                <div class="ladoagencia" (click)="servicioagencia('U12E')">
                    <div *ngIf="serviciosLocal && serviciosLocal['U12E']">
                        {{serviciosLocal['U12E'].cantidad}}
                    </div>
                    <div class="origen">
                        Agencia
                    </div>
                </div>
                <div class="ladoagencia" (click)="comparaservicio('URGENTE 12 EXPEDICIÓN', 'U12E')">
                    <div *ngIf="serviciosLocal && serviciosLocal['U12E'] && serviciosCentral && serviciosCentral['URGENTE 12 EXPEDICIÓN']">
                        {{serviciosLocal['U12E'].cantidad - serviciosCentral['URGENTE 12 EXPEDICIÓN'].cantidad}}
                    </div>
                    <div class="origen">
                        Diferencia
                    </div>
                </div>
            </div>
        </div>
        <div class="comparativa">
            <div class="titulocomparativa">
                Urgente12
            </div>
            <div class="cuerpocomparativa">
                <div class="ladocentral" (click)="serviciocentral('URGENTE 12')">
                    <div *ngIf="serviciosCentral && serviciosCentral['URGENTE 12']">
                        {{serviciosCentral['URGENTE 12'].cantidad}}
                    </div>
                    <div class="origen">
                        Central
                    </div>
                </div>
                <div class="ladoagencia" (click)="servicioagencia('U12')">
                    <div *ngIf="serviciosLocal && serviciosLocal['U12']">
                        {{serviciosLocal['U12'].cantidad}}
                    </div>
                    <div class="origen">
                        Agencia
                    </div>
                </div>
                <div class="ladoagencia" (click)="comparaservicio('URGENTE 12', 'U12')">
                    <div *ngIf="serviciosLocal && serviciosLocal['U12'] && serviciosCentral && serviciosCentral['URGENTE 12']">
                        {{serviciosLocal['U12'].cantidad - serviciosCentral['URGENTE 12'].cantidad}}
                    </div>
                    <div class="origen">
                        Diferencia
                    </div>
                </div>
            </div>
        </div>
        <div class="comparativa">
            <div class="titulocomparativa">
                Urgente10Exp
            </div>
            <div class="cuerpocomparativa">
                <div class="ladocentral" (click)="serviciocentral('URGENTE 10 EXPEDICIÓN')">
                    <div *ngIf="serviciosCentral && serviciosCentral['URGENTE 10 EXPEDICIÓN']">
                        {{serviciosCentral['URGENTE 10 EXPEDICIÓN'].cantidad}}
                    </div>
                    <div class="origen">
                        Central
                    </div>
                </div>
                <div class="ladoagencia" (click)="servicioagencia('U10E')">
                    <div *ngIf="serviciosLocal && serviciosLocal['U10E']">
                        {{serviciosLocal['U10E'].cantidad}}
                    </div>
                    <div class="origen">
                        Agencia
                    </div>
                </div>
                <div class="ladoagencia" (click)="comparaservicio('URGENTE 10 EXPEDICIÓN', 'U10E')">
                    <div *ngIf="serviciosLocal && serviciosLocal['U10E'] && serviciosCentral && serviciosCentral['URGENTE 10 EXPEDICIÓN']">
                        {{serviciosLocal['U10E'].cantidad - serviciosCentral['URGENTE 10 EXPEDICIÓN'].cantidad}}
                    </div>
                    <div class="origen">
                        Diferencia
                    </div>
                </div>
            </div>
        </div>
        <div class="comparativa">
            <div class="titulocomparativa">
                Urgente10
            </div>
            <div class="cuerpocomparativa">
                <div class="ladocentral" (click)="serviciocentral('URGENTE 10')">
                    <div *ngIf="serviciosCentral && serviciosCentral['URGENTE 10']">
                        {{serviciosCentral['URGENTE 10'].cantidad}}
                    </div>
                    <div class="origen">
                        Central
                    </div>
                </div>
                <div class="ladoagencia" (click)="servicioagencia('U10')">
                    <div *ngIf="serviciosLocal && serviciosLocal['U10']">
                        {{serviciosLocal['U10'].cantidad}}
                    </div>
                    <div class="origen">
                        Agencia
                    </div>
                </div>
                <div class="ladoagencia" (click)="comparaservicio('URGENTE 10', 'U10')">
                    <div *ngIf="serviciosLocal && serviciosLocal['U10'] && serviciosCentral && serviciosCentral['URGENTE 10']">
                        {{serviciosLocal['U10'].cantidad - serviciosCentral['URGENTE 10'].cantidad}}
                    </div>
                    <div class="origen">
                        Diferencia
                    </div>
                </div>
            </div>
        </div>
        <div class="comparativa">
            <div class="titulocomparativa">
                Urgente 8:30 Exp
            </div>
            <div class="cuerpocomparativa">
                <div class="ladocentral" (click)="serviciocentral('URGENTE 8:30 EXPEDICIÓN')">
                    <div *ngIf="serviciosCentral && serviciosCentral['URGENTE 8:30 EXPEDICIÓN']">
                        {{serviciosCentral['URGENTE 8:30 EXPEDICIÓN'].cantidad}}
                    </div>
                    <div class="origen">
                        Central
                    </div>
                </div>
                <div class="ladoagencia" (click)="servicioagencia('U830E')">
                    <div *ngIf="serviciosLocal && serviciosLocal['U830E']">
                        {{serviciosLocal['U830E'].cantidad}}
                    </div>
                    <div class="origen">
                        Agencia
                    </div>
                </div>
                <div class="ladoagencia" (click)="comparaservicio('URGENTE 8:30 EXPEDICIÓN', 'U830E')">
                    <div *ngIf="serviciosLocal && serviciosLocal['U830E'] && serviciosCentral && serviciosCentral['URGENTE 8:30 EXPEDICIÓN']">
                        {{serviciosLocal['U830E'].cantidad - serviciosCentral['URGENTE 8:30 EXPEDICIÓN'].cantidad}}
                    </div>
                    <div class="origen">
                        Diferencia
                    </div>
                </div>
            </div>
        </div>
        <div class="comparativa">
            <div class="titulocomparativa">
                Urgente 8:30
            </div>
            <div class="cuerpocomparativa">
                <div class="ladocentral" (click)="serviciocentral('URGENTE 8:30')">
                    <div *ngIf="serviciosCentral && serviciosCentral['URGENTE 8:30']">
                        {{serviciosCentral['URGENTE 8:30'].cantidad}}
                    </div>
                    <div class="origen">
                        Central
                    </div>
                </div>
                <div class="ladoagencia" (click)="servicioagencia('U830')">
                    <div *ngIf="serviciosLocal && serviciosLocal['U830']">
                        {{serviciosLocal['U830'].cantidad}}
                    </div>
                    <div class="origen">
                        Agencia
                    </div>
                </div>
                <div class="ladoagencia" (click)="comparaservicio('URGENTE 8:30', 'U830')">
                    <div *ngIf="serviciosLocal && serviciosLocal['U830'] && serviciosCentral && serviciosCentral['URGENTE 8:30']">
                        {{serviciosLocal['U830'].cantidad - serviciosCentral['URGENTE 8:30'].cantidad}}
                    </div>
                    <div class="origen">
                        Diferencia
                    </div>
                </div>
            </div>
        </div>
        <div class="comparativa">
            <div class="titulocomparativa">
                Urgente HOY Exp
            </div>
            <div class="cuerpocomparativa">
                <div class="ladocentral" (click)="serviciocentral('URGENTE HOY EXPEDICIÓN')">
                    <div *ngIf="serviciosCentral && serviciosCentral['URGENTE HOY EXPEDICIÓN']">
                        {{serviciosCentral['URGENTE HOY EXPEDICIÓN'].cantidad}}
                    </div>
                    <div class="origen">
                        Central
                    </div>
                </div>
                <div class="ladoagencia" (click)="servicioagencia('URHE')">
                    <div *ngIf="serviciosLocal && serviciosLocal['URHE']">
                        {{serviciosLocal['URHE'].cantidad}}
                    </div>
                    <div class="origen">
                        Agencia
                    </div>
                </div>
                <div class="ladoagencia" (click)="comparaservicio('URGENTE HOY EXPEDICIÓN', 'URHE')">
                    <div *ngIf="serviciosLocal && serviciosLocal['URHE'] && serviciosCentral && serviciosCentral['URGENTE HOY EXPEDICIÓN']">
                        {{serviciosLocal['URHE'].cantidad - serviciosCentral['URGENTE HOY EXPEDICIÓN'].cantidad}}
                    </div>
                    <div class="origen">
                        Diferencia
                    </div>
                </div>
            </div>
        </div>
        <div class="comparativa">
            <div class="titulocomparativa">
                Urgente HOY
            </div>
            <div class="cuerpocomparativa">
                <div class="ladocentral" (click)="serviciocentral('URGENTE HOY')">
                    <div *ngIf="serviciosCentral && serviciosCentral['URGENTE HOY']">
                        {{serviciosCentral['URGENTE HOY'].cantidad}}
                    </div>
                    <div class="origen">
                        Central
                    </div>
                </div>
                <div class="ladoagencia" (click)="servicioagencia('UHOY')">
                    <div *ngIf="serviciosLocal && serviciosLocal['UHOY']">
                        {{serviciosLocal['UHOY'].cantidad}}
                    </div>
                    <div class="origen">
                        Agencia
                    </div>
                </div>
                <div class="ladoagencia" (click)="comparaservicio('URGENTE HOY', 'UHOY')">
                    <div *ngIf="serviciosLocal && serviciosLocal['UHOY'] && serviciosCentral && serviciosCentral['URGENTE HOY']">
                        {{serviciosLocal['UHOY'].cantidad - serviciosCentral['URGENTE HOY'].cantidad}}
                    </div>
                    <div class="origen">
                        Diferencia
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="datosenvios">
        <div class="herramientasenvios">
            <button mat-icon-button matTooltip="diferencia totales" (click)="mostrartotales()">
                <mat-icon>clear_all</mat-icon>
            </button>
            <div *ngIf="mensajeenvios">{{mensajeenvios}}</div>
        </div>
        <div class="contienelistaenvios">
            <div clas="listaalbaranes" *ngIf="mensajeenvios && albaranesmostrarcentral">
                <div>
                    Central
                </div>
                <ul class="envios">
                    <li *ngFor="let alb of albaranesmostrarcentral" class="elementolista" (click)="bucaryabrir(alb)">
                        {{alb}}
                    </li>
                </ul>
            </div>
            <div clas="listaalbaranes" *ngIf="mensajeenvios && albaranesmostraragencia">
                <div>
                    Agencia
                </div>
                <ul class="envios">
                    <li *ngFor="let alb of albaranesmostraragencia" class="elementolista" (click)="bucaryabrir(alb)">
                        {{alb}}
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>
