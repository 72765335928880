import { AppService } from "./app.service";
import { GLOBAL } from "./global";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs/Observable";
import { environment } from "../../environments/environment";
import { Injectable, EventEmitter, Output } from "@angular/core";
import { Socket } from "ngx-socket-io";

@Injectable()
export class ChatService extends Socket {
  @Output() outEven: EventEmitter<any> = new EventEmitter();
  @Output() users: EventEmitter<any> = new EventEmitter();
  @Output() iact: EventEmitter<any> = new EventEmitter();
  @Output() recon: EventEmitter<any> = new EventEmitter();

  public url: string;

  constructor(public _appservice: AppService, public _http: HttpClient) {
    super({
      url: environment.SOCKET_ENDPOINT,
      options: {},
    });

    this.url = GLOBAL.urlchat;
    this.ioSocket.on("message", (res) => this.outEven.emit(res));
    this.ioSocket.on("usuarios", (res) => {
      var ident = this._appservice.getIdentity();
      let usuarios = res.filter((us) => {
        return us.usuario != ident["_id"];
      });
      this.users.emit(usuarios);
    });
    this.ioSocket.on("reconect", (res) => this.recon.emit(res));
    this.ioSocket.on("incactualizadas", (res) => this.iact.emit(res));
  }

  emitEvent = (event, datos): void => {
    this.ioSocket.emit(event, datos);
  };

  getmensajesnuevos(): Observable<any> {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("authorization", this._appservice.getToken());
    return this._http.get(this.url + "nuevos", { headers: headers });
  }

  getmensajeschat(usuario): Observable<any> {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("authorization", this._appservice.getToken());
    var destino = this.url + "mensajeschat/" + usuario.usuario;
    return this._http.get(destino, { headers: headers });
  }

  marcarchatleido(usuario): Observable<any> {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("authorization", this._appservice.getToken());
    var destino = this.url + "chatleido/" + usuario.usuario;
    return this._http.get(destino, { headers: headers });
  }

  marcarchattodosleido(): Observable<any> {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("authorization", this._appservice.getToken());
    var destino = this.url + "chattodosleido";
    return this._http.get(destino, { headers: headers });
  }

  getmensajestodos(): Observable<any> {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("authorization", this._appservice.getToken());
    var destino = this.url + "mensajestodos";
    return this._http.get(destino, { headers: headers });
  }
}
