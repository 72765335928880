import { Component, OnInit, Inject} from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ClienteService } from '../../servicios/cliente.service';
import * as moment from 'moment';

@Component({
  selector: 'albeditable',
  templateUrl: './albeditable.component.html',
  styleUrls: ['./albeditable.component.css'],
  providers: [ClienteService]
})
export class AlbEditableComponent implements OnInit {
    
  constructor(
    private _clienteService: ClienteService,
  	public dialogoAlbaran: MatDialogRef<AlbEditableComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  	) {

    }

  ngOnInit(): void {
  }

  
  cancelar() {
    this.dialogoAlbaran.close();
  }

  closeDialog(datos) {
    this.dialogoAlbaran.close(datos);
  }

 guardar(){
  const galleta = sessionStorage.getItem('galletag3');
  this._clienteService.editarmasivo(this.data.idEnvio, this.data, galleta).subscribe(
     res=>{
     }
   )
   this.closeDialog({msg: 'envio editado', estado: 'editado', data: this.data});
 }

 cancelarenvio(){
  const galleta = sessionStorage.getItem('galletag3');
  const idEnvio = this.data.idEnvio;
  const albaran = this.data.albaran;
  const fecha = moment().format('DD/MM/YYYY');
  const hora = moment().format('HH:mm');
  const datos = {
    idEnvio,
    albaran,
    fecha,
    hora
  }
  this._clienteService.eliminaenviogrupo(this.data).subscribe(
    res=>{
    }
  );
  this._clienteService.cancelamasivo(datos, galleta).subscribe(
     res=>{
       console.log(res);
     }
   )
  this.closeDialog({msg: 'envio cancelado', estado: 'cancelado', data: this.data});
 }
 

}


