<div class="barraseleccion">
  <!-- <mat-form-field style="margin-right:50px;width:250px;font-size:10pt;">
    <mat-label><b>Buscar Envio</b></mat-label>
    <input matInput type="text" [(ngModel)]="filtros['NumAlbaran']" style="padding:1px;color:black;"
          (keyup.enter)="buscarAlbaran(filtros['NumAlbaran'])" (ngModelChange)="filtros['NumAlbaran'] = $event.toUpperCase()">
    <button mat-button *ngIf="filtros['NumAlbaran']" matSuffix mat-icon-button aria-label="Clear" (click)="filtros['NumAlbaran']=''; getanomalias(fechainicio, fechafin)">
        <mat-icon>close</mat-icon>
    </button>
  </mat-form-field>
  <mat-form-field style="margin-left:50px;width:250px;font-size:10pt;">
    <mat-label><b>Buscar Referencia</b></mat-label>
    <input matInput type="text" [(ngModel)]="filtros['referencia']" style="padding:1px;color:black;"
          (keyup.enter)="buscarReferencia(filtros['referencia'])">
    <button mat-button *ngIf="filtros['referencia']" matSuffix mat-icon-button aria-label="Clear" (click)="filtros['referencia']=''; getanomalias(fechainicio, fechafin)">
        <mat-icon>close</mat-icon>
    </button>
  </mat-form-field> -->
 
  <!-- <mat-form-field style="margin-left:50px;margin-right:20px;width:250px;font-size:10pt;">
    <mat-label (click)="picker3.open()"><b>Rango fechas</b></mat-label>
    <mat-date-range-input [rangePicker]="picker3" (click)="picker3.open()">
      <input matStartDate placeholder="Fecha Inicio" [(ngModel)]="fechai">
      <input matEndDate placeholder="Fecha fin" [(ngModel)]="fechaf" (dateChange)="onDateFecha()">
    </mat-date-range-input>
    <button mat-button *ngIf="fechai" matSuffix mat-icon-button aria-label="Clear" (click)="fechai=''; fechaf=''; getanomalias(fechainicio, fechafin);">
      <mat-icon>close</mat-icon>
    </button>
    <mat-date-range-picker #picker3></mat-date-range-picker>
  </mat-form-field> -->
  
  <div class="botonbarra" style="display:inline-flex;height:50px;">
      <mat-button-toggle-group (change)="selectipo($event.value)">
          <mat-button-toggle value="todos">Todos</mat-button-toggle>
          <mat-button-toggle value="impresos">Impresos</mat-button-toggle>
          <mat-button-toggle value="noimpresos">No Impresos</mat-button-toggle>
      </mat-button-toggle-group>
  </div>
  <div *ngIf="selection.selected.length >=1 && galletag3" class="botonbarra" style="height:30px;">
    <mat-icon class="material-icons-outlined" matTooltip="imprimir" (click)="imprimir()">print</mat-icon>
  </div>
  <mat-form-field *ngIf="dataSource.data.length >= 2" style="margin-left:50px;width:250px;font-size:10pt;">
    <mat-label><b>Buscar...</b></mat-label>
    <input matInput (keyup)="applyFilter($event)" placeholder="buscar.." [(ngModel)]="filtro" style="padding:1px;color:black;">
    <button mat-button *ngIf="filtro" matSuffix mat-icon-button aria-label="Clear" (click)="dataSource.filter = ''; filtro=''">
        <mat-icon>close</mat-icon>
    </button>
  </mat-form-field>
</div>
<div class="contenidoincidencias">
	<mat-progress-bar style="width:100%" *ngIf="!load" mode="indeterminate"></mat-progress-bar>
    <table *ngIf="load" mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">
        <ng-container matColumnDef="select" class="cabeceras">
		    <th mat-header-cell cdkDrag *matHeaderCellDef class="cabeceras">
		      <mat-checkbox color="primary" (change)="$event ? masterToggle() : null"
		                    [checked]="selection.hasValue() && isAllSelected()"
		                    [indeterminate]="selection.hasValue() && !isAllSelected()"
		                    [aria-label]="checkboxLabel()">
		      </mat-checkbox>
		    </th>
		    <td mat-cell *matCellDef="let row">
		      <mat-checkbox color="primary" (click)="$event.stopPropagation()"
		                    (change)="$event ? selection.toggle(row) : null"
		                    [checked]="selection.isSelected(row)"
		                    [aria-label]="checkboxLabel(row)">
		      </mat-checkbox>
		    </td>
		  </ng-container>

        <ng-container matColumnDef="ALBARAN">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Albaran </th>
          <td mat-cell *matCellDef="let row"> {{row.albaran}} </td>
        </ng-container>
    
        <ng-container matColumnDef="CONTACTO">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Contacto </th>
          <td mat-cell *matCellDef="let row"> {{row.contacto}} </td>
        </ng-container>
     
        <ng-container matColumnDef="TELEFONO">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Telefono </th>
          <td mat-cell *matCellDef="let row"> {{row.telefono}} </td>
        </ng-container>

        <ng-container matColumnDef="CP">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Cp </th>
            <td mat-cell *matCellDef="let row"> {{row.cp}} </td>
        </ng-container>

        <ng-container matColumnDef="NOMBRE">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Nombre </th>
            <td mat-cell *matCellDef="let row"> {{row.nombre}} </td>
        </ng-container>

        <ng-container matColumnDef="DIRECCION">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Direccion</th>
            <td mat-cell *matCellDef="let row"> {{row.direccion}} </td>
        </ng-container>

        <ng-container matColumnDef="POBLACION">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Poblacion </th>
            <td mat-cell *matCellDef="let row"> {{row.poblacion}} </td>
        </ng-container>

        <ng-container matColumnDef="OBSERVACIONES">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Observaciones </th>
            <td mat-cell *matCellDef="let row"> {{row.observaciones}} </td>
        </ng-container>

        <ng-container matColumnDef="REFERENCIA">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Referencia </th>
            <td mat-cell *matCellDef="let row"> {{row.referencia}} </td>
        </ng-container>

        <ng-container matColumnDef="BULTOS">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Bultos </th>
            <td mat-cell *matCellDef="let row"> {{row.bultos}} </td>
        </ng-container>
    
        <tr mat-header-row *matHeaderRowDef="totalcolumns; sticky:true"></tr>
        <tr mat-row *matRowDef="let row; columns: totalcolumns;" (click)="seleccionar(row)" (dblclick)="abrealbaran(row)" class="celdas" [class.seleccionada]="seleccion && seleccion.albaran == row.albaran"></tr>
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="13">No hay datos que mostrar</td>
        </tr>
    </table>
</div>
<div *ngIf="load && dataSource.data.length >=1" style="margin-left:100px;margin-top:10px;margin-bottom:10px;padding:10px;"><b>{{'Total: ' + dataSource.data.length + ' Envios'}}</b></div>
