import { OnInit, Component} from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { ClienteService } from '../../servicios/cliente.service';
import { MatTableDataSource } from '@angular/material/table';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition, MatSnackBarConfig } from '@angular/material/snack-bar'; 
import { Cliente } from '../../models/cliente';
import * as moment from 'moment';
import { DomSanitizer } from '@angular/platform-browser';
import { AppService } from '../../servicios/app.service';


@Component({
  selector: 'misenvios',
  templateUrl: './misenvios.component.html',
  styleUrls: ['./misenvios.component.css'],
  providers: [ClienteService, AppService]
})
export class MisenviosComponent implements OnInit {
  public categoria;
  public identity;
  public apc;

  constructor(
    private _clienteService: ClienteService,
    private _sanitizer: DomSanitizer,
    private _appservice: AppService,
  ) {
    this.identity = this._appservice.getIdentity();
   }

  ngOnInit(): void {
    this.categoria = 'envios';
  }

  selectcategory(categoria){
    this.categoria = categoria;
  }

  abreapc(){
    window.open('https://clientes.mrw.es/APCAccount/Login.aspx?ReturnUrl=%2f');
  }
  
  getsolicitudapc(){
    let query = {numero:this.identity.numero}
    this._clienteService.getsolicitudes(query).subscribe(
      response=>{
        if(response && response.apcs){
          this.apc = response.apcs[0];
        }else{
          this.apc = undefined;
        }
      }
    )
  }

  solictaApc(){
    this._clienteService.solicitarApc('','').subscribe(
      response=>{
        if(response && response.message && response.message == 'solicitud apc guardada con exito'){
          alert('se ha solicitado el alta en la solicitud de envios correctamenste, en breve recibira un email con los datos de acceso.')
        }else if(response && response.message){  
          alert(response.message);
        }else{
          alert('error inesperado, contacte con nosotros en: administracion.02601@grupomrw.com');
        }
      },
      error=>{
        console.log(<any>error);
        //alert(error.err.message);
      }
    )
  }

}
