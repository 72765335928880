import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Incidencia } from '../../models/incidencia';
import { IncidenciasService } from '../../servicios/incidencias.service';
import { EmailsService } from '../../servicios/emails.service';
import { saveAs } from "file-saver";
import * as moment from 'moment';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition, MatSnackBarConfig } from '@angular/material/snack-bar';
import { FormControl } from '@angular/forms';
import { AppService } from '../../servicios/app.service';

@Component({
  selector: 'albcliente',
  templateUrl: './albcliente.component.html',
  styleUrls: ['./albcliente.component.css'],
  providers: [IncidenciasService, AppService, EmailsService]
})
export class AlbclienteComponent implements OnInit {
    public identity;
    public notas: string;
    public notaexpediente;
    public notaagencia;
    
  constructor(
    private _incidenciasservice: IncidenciasService,
    private _emailsService: EmailsService,
  	public dialogoAlbaran: MatDialogRef<AlbclienteComponent>,
    private _snackBar: MatSnackBar,
    private _appservice: AppService,
    @Inject(MAT_DIALOG_DATA) public data: Incidencia
  	) {
      this.identity = this._appservice.getIdentity();
    }


  ngOnInit(): void {
  }

  
  parsefecha(fecha){
    if(fecha){
      return moment(fecha).format('DD/MM/YYYY');
    }else{
      return '';
    }  
  }

  parsefechahms(fecha){
    if(fecha){
      return moment(fecha).format('DD/MM/YYYY HH:mm:ss');
    }else{
      return '';
    }  
  }

  humanizafrecuencia(f){
    var frecuencia;
    switch(f){
      case '1':
        frecuencia = '1º Diurno';
        break;
      case '2':
        frecuencia = '2º Diurno';
        break;
      case 'S':
        frecuencia = 'Entrega en sabado';
        break;
      default:
        frecuencia = undefined;
        break;
    }
    return frecuencia;
  }

  cancelar() {
    this.closeDialog();
  }

  closeDialog() {
    this.dialogoAlbaran.close();
  }

  aviso(texto, subtexto) {
    this._snackBar.open(texto,subtexto,{
      duration: 5000,
      horizontalPosition: "center",
      verticalPosition: "top",
    });
  }

  guardaincidencia(){
    if(!this.notas && !this.notaexpediente){
      this.aviso('debe rellenar al menos uno de los campos de direccion o notas para la agencia', 'ok');
    }else{
      this.crearmensajenuevo();
      this.aviso('incidencia guardada', 'ok');
      this.dialogoAlbaran.close(this.data);
    }  
  }

  notag3(){
    var h = new Date().getHours();
    var m = new Date().getMinutes();
    var hora = h + ':' + m;    
    var postit = {idenvio: this.data.Id, nota: this.notaexpediente, hora: hora, tipo: 'Pública Notificada'};

    this._incidenciasservice.notaG3(postit).subscribe(
            response=>{
                if(response.message) this.aviso(response.message, 'ok');
                this.notaexpediente = undefined;
               },
            error=>{
              console.log(<any>error)
            }  
    );
  }

  crearmensajenuevo(){
    this.notas = this.notas? this.notas : undefined;
    this.notaexpediente = this.notaexpediente? 'la direccion completa/correcta es: ' + this.notaexpediente : undefined;
    if(this.notaexpediente) this.notag3();
    if(this.notas){
      var mensajenuevo = {
        message: {
          subject: 'ANOMALIA: ' + this.data.NumAlbaran,
          body: {
            contentType: "HTML",
            content: '<div><p>Comunicacion del cliente: ' + this.data.CodigoAbonado + ' ' + this.data.NombreCliente + '</p><hr><br><pre>' + 
            this.notas + '</pre><br><hr><p>Comunicacion (nota publica notificada) ' + this.notaexpediente + '</p></div>'
          },
          toRecipients: [{
            emailAddress:{
                  address: '02601@grupomrw.com',
                  name: 'Mensasur Getafe'
              }
          }],
          sender:{
            emailAddress:{
                  address: this.identity.emailsIncidencias,
                  name: this.data.NombreCliente
              }
          },
          from:{
            emailAddress:{
                  address: this.identity.emailsIncidencias,
                  name: this.data.NombreCliente
              }
          },
          receivedDateTime: new Date()
        }
      }
      this.linkaremail(mensajenuevo.message);
    }  
  }

  linkaremail(mail){
    this._emailsService.guardaremail(mail, this.data).subscribe(
      response=>{
        this.aviso('', 'ok');
      },
      error=>{
        console.log(<any>error);
      });
  }

  exportar(mensaje){
    this._emailsService.exportarmail(mensaje).subscribe(
      response=>{
        saveAs(response, 'mensaje.eml');
      },
      error=>{
        console.log(error);
      }
    )
	}

}


