import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ClienteService } from '../../servicios/cliente.service';
import { saveAs } from "file-saver";

@Component({
  selector: 'descargaenlace',
  templateUrl: './descargaenlace.component.html',
  styleUrls: ['./descargaenlace.component.css'],
  providers: [ClienteService]
})
export class DescargaenlaceComponent implements OnInit {
  public solicitarpassword = false;
  public mensajeerror;
  public enlace;
  public password;
  public passincorrecta = false;
  public intentos = 0;
  constructor(
  	private _route: ActivatedRoute,
  	private _router: Router,
    private _clienteService: ClienteService
  ) { }

  ngOnInit(): void {
    sessionStorage.setItem('concertar', 'true');
    this._route.params.subscribe(
      params=>{
        if(params && params.id){
          this._clienteService.solicitadescarga(params.id).subscribe(
            response=>{
              if(response && response['enlace']){
                this.mensajeerror = undefined;
                this.enlace = response['enlace'];
                this.solicitarpassword = true;
              }else{
                this.mensajeerror = response['message'];
              }
            }
          )
        }else{
          this.mensajeerror = 'url incorrecta, no se puede acceder al archivo con esta direccion web'
        }
      }
    );      
  }

  comprobar(){
    this._clienteService.compruebapass(this.enlace._id,this.password).subscribe(
      response=>{
        console.log(response);
        if(response && response.message == 'ok'){
          this.passincorrecta = false;
          this.getarchivo();
        }else{
          this.passincorrecta = true;
          this.intentos = this.intentos +1;
          if(this.intentos > 5){
            this.mensajeerror = "Demasiados intentos erroneos, la descarga se bloqueara durante 15 minutos"
          }
        }
      }
    )
  }

  getarchivo(){
    console.log('obteniendo archivo..')
    this._clienteService.getarchivoenlace(this.enlace._id,this.password).subscribe(
      response=>{
        saveAs(response, this.enlace.nombre);
        this.mensajeerror = 'Archivo obtenido correctamente';
      }
    )
  }

}
