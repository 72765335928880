import { Component, OnInit } from '@angular/core';
import { AppService } from '../../servicios/app.service';
import { IncidenciasService } from '../../servicios/incidencias.service';
import { DomSanitizer } from '@angular/platform-browser';
import { FormControl } from '@angular/forms';
import { ConcertadaService } from '../../servicios/concertada.service';
import * as moment from 'moment';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';

@Component({
  selector: 'app-noclientes',
  templateUrl: './noclientes.component.html',
  styleUrls: ['./noclientes.component.css'],
  providers: [IncidenciasService, AppService, ConcertadaService]
})
export class NoclientesComponent implements OnInit {
  public value;
  public albaran;
  public identity;
  public urlseguimientomrw;
  public telefono;
  public tlfdest;
  public myControl = new FormControl();
  public fechaconcertacion;
  public filteredOptions;
  public fechasocupadas;
  public horarioconcertacion;
  public fechaconcertada;
  public mensajerespuesta;
  public datosconcertados;
  public estadoenvio;
  public advertencia;
  public habilitado;
  public observaciones;

  constructor(
    private _incidenciasservice: IncidenciasService,
    private _appservice: AppService, 
    private _sanitizer: DomSanitizer,
    private _concertadasservice: ConcertadaService,
    private dateAdapter: DateAdapter<Date>,
    private _route: ActivatedRoute,
  	private _router: Router
  ) {
    this.filteredOptions = ['9h A 14h', '16h A 20h', '9H A 20H'];
    this.dateAdapter.setLocale('es');
    this.dateAdapter.getFirstDayOfWeek = () => { return 1; };
    this.habilitado = false;
   }

  ngOnInit(): void {
    this.identity = this._appservice.getIdentity();
    if(!this.identity) this.acess();
    this.dateocupadas();
    this._route.params.subscribe(
      params=>{
        if(params && params.envio){
          sessionStorage.setItem('concertar', 'true');
          this.value = params.envio;
          this.buscarenvio(params.envio);
        }
    });  
  }

  buscarenvio(valor){
    this.limpiar();
      this._incidenciasservice.getseguimiento(valor.trim()).subscribe(
        response=>{
          if(response.incidencia){
            this.albaran = response.incidencia;
            var direccion = this.albaran.DireccionDestinatario.toLowerCase();
            this.tlfdest = this.albaran.TelefonoDestinatario.replace('+34','').trim();
            var uri = 'https://www.mrw.es/seguimiento_envios/MRW_resultados_consultas.asp?modo=nacional&envio=' + this.albaran.NumAlbaran;
            this.urlseguimientomrw = this._sanitizer.bypassSecurityTrustResourceUrl(uri);
            if(this.albaran.FranquiciaDestino != '02601'){
              this.estadoenvio = 'noconcertable1'; 
            }else if(direccion.includes('montcada') || direccion.includes('burjasot') || direccion.includes('rocafort') || direccion.includes('godella') || direccion.includes('pinazo') || direccion.includes('beniferri') || direccion.includes('droc') || direccion.includes('paterna') || direccion.includes('benimamet') || direccion.includes('paloma de la paz')){
              this.estadoenvio = 'noconcertable3';
            }else{
              this.estadoenvio = this.albaran.Estado == 'EntregaFinalizada'? 'entregado' : this.setestado(this.albaran.Motivo);
            }  
          }else{
            var uri = 'https://www.mrw.es/seguimiento_envios/MRW_resultados_consultas.asp?modo=nacional&envio=' + valor.trim();
            this.urlseguimientomrw = this._sanitizer.bypassSecurityTrustResourceUrl(uri);
            this.estadoenvio = 'noconcertable2';
          }  
        }
      );
  }

  limpiar(){
    this.datosconcertados = undefined;
    this.habilitado = false;
    this.horarioconcertacion = undefined;
    this.estadoenvio = undefined;
    this.cancelafecha();
    this.datosconcertados = undefined;
    this.telefono = undefined;
    this.dateocupadas();
  }

  cancelabusqueda(){
    this.value = '';
    this.albaran = undefined;
    this.urlseguimientomrw = '';
    this.limpiar();
  }

  acess(){
    this._appservice.getaccesonocliente().subscribe(
      response=>{
        sessionStorage.setItem('token', response.token);
      }
    )
  }

  dateocupadas(){
    var fecha = moment().set({hour:22,minute:0,second:0,millisecond:0}).add(1, 'days').toISOString();
    var query = {fecha:{$gte:fecha}};
    this._concertadasservice.getcierres(query).subscribe(
      response=>{
        if(response.cierres){
          this.fechasocupadas = response.cierres.map((cierre)=>{
            return cierre.fecha;
          });
        }
      }  
    );       
  }
  
  
  myFilter = (d: Date | null): boolean => {
    const day = (d || new Date()).getDay();
    var fecha = new Date();
    var hora = fecha.getHours();
    if(hora >= 18){
      var diasiguiente = new Date(fecha.setDate(fecha.getDate() + 1));
    }else{
      var diasiguiente = fecha;
    }
    var fechamoment = moment(d).set({hour:22,minute:0,second:0,millisecond:0}).toISOString();
    if(day !== 0 && day !== 6 && day !== 2 && d > fecha && !this.fechasocupadas.includes(fechamoment) && diasiguiente != d){
      return true
    }else{
      return false;
    }
  }

  asignarfechaconcertacion(){
    this.fechaconcertada = moment(this.fechaconcertacion).format("DD/MM/YYYY");
  }

  cancelafecha(){
    this.fechaconcertada = undefined;
    this.fechaconcertacion = null;
  }

  sethorario(event){
    this.horarioconcertacion = event;
  }

  setadvertencia(){
    var mensaje:string;
    switch(this.estadoenvio){
      case 'sinrecibir':
        mensaje = 'Este envio aun no ha llegado a la agencia de destino y por tanto no podemos garantizar la entrega en el dia concertado, pero tomaremos nota y haremos lo posible.';
        break;
      case 'cancelado': 
        mensaje = 'Este envio ya no lo tenemos nosotros y por tanto no podemos concertar una entrega. por favor, contacte con el emisor.';
        break;
      case 'recibido': 
        mensaje = 'ok';
        break;
      case 'enreparto': 
        mensaje = 'este envio lo lleva nuestro mensajero en reparto, se intentara entregar en el dia de hoy. puede concertar un segundo intento de entrega por si no conseguimos entregarlo hoy.';
        break;
      case 'noconcertable1':
          mensaje = 'esta entrega no pertenece a nuestra agencia (02601-Getafe), no podemos concertar una entrega.'
          break;  
      case 'noconcertable2':
        mensaje = 'este numero no aparece en nuestro sistema, o no pertenece a nuestra agencia (02601-Getafe), no podemos concertar una entrega. si puede ver el seguimiento, por favor intentelo de nuevo mas tarde.'
        break;
      case 'noconcertable3':
        mensaje = `este envio pertenece a una zona de riesgo para MRW, no podemos concertar una entrega. puede recoger su envio en nuestra oficina de c/Edison 4, 28906 Getafe, 
Si necesita otro tipo de solucion, contacte con el emisor del envio, que debio informarle de esta circunstancia al realizar el envio.`
        break;   
      case 'entregado':
        mensaje = 'ok'
        break;     
      default:
        mensaje = 'ok';
        break;     
    }
    return mensaje;
  }

  setestado(estado){
      var motivohumanizado:string;
      switch(estado){
        case 'OT0700': //Clasificado en plataforma
          motivohumanizado = 'sinrecibir';
          this.habilitado = true;
          break;
        case 'OE0062': //Depositado en Franquicia de destino
          motivohumanizado = 'recibido';
          this.habilitado = true;
          break;
        case 'OE0600': //Mensajero de entrega asignado
          if(this.albaran.FechaConcertacion){
            motivohumanizado = 'recibido';
            this.habilitado = true;
          }else{
            motivohumanizado = 'enreparto';
            this.habilitado = true;
          }
          break;  
        case 'OR0600': //Mensajero de recogida asignado
          motivohumanizado = 'sinrecibir';
          this.habilitado = true;
          break; 
        case 'OE0500': //Recibido en franquicia de destino
          motivohumanizado = 'recibido';
          this.habilitado = true;
          break;
        case 'OR0200': //Recogida finalizada
          motivohumanizado = 'sinrecibir';
          this.habilitado = true;
          break;
        case 'OS0200': //Recogida pendiente
          motivohumanizado = 'sinrecibir';
          this.habilitado = true;
          break;
        case 'OE0100': //Tránsito interno de entrega
          motivohumanizado = 'sinrecibir';
          this.habilitado = true;
          break;
        case 'OR0402': //Envio borrado
          motivohumanizado = 'cancelado';
          break;
        case 'OR0201': //Recogida finalizada en franquicia
          motivohumanizado = 'sinrecibir';
          this.habilitado = true;
          break;
        case 'OE0702': //Reexpedir Envio
          motivohumanizado = 'cancelado';
          break;
        case 'OE0401': //Devolver al remitente
          motivohumanizado = 'cancelado';
          break;
        case 'OE1103': //Cliente Ordena destruir el envio
          motivohumanizado = 'cancelado';
          break;          
        default:
          motivohumanizado = 'sinrecibir';    
      }
      return motivohumanizado;
  }
  
  guardaconcertada(){
    this.albaran['fecharegistro'] = moment().format('DD/MM/YYYY HH:mm:ss');
    this.albaran['usuario'] = 'cliente';
    this.albaran['fechaconcertada'] = this.fechaconcertada;
    this.albaran['impresa'] = 0;
    this.albaran['horarioconcertado'] = this.horarioconcertacion;
    this.albaran['obconcertada'] = this.observaciones;
    this._concertadasservice.guardarconcertada(this.albaran).subscribe(
      response =>{
        if(response && response.message){
          this.mensajerespuesta = response.message;
          this.concertar();
          this.datosconcertados = 'Entrega concertada para el dia: ' + this.fechaconcertada + ' en horario: ' + this.horarioconcertacion + ' en direccion: ' + this.albaran.DireccionDestinatario;
          this.horarioconcertacion = undefined;
          this.cancelafecha();
          this.telefono = undefined;
        }  
      },
      error=>{
        console.log(error);
      }
    );
  }

  concertar(){
    var notaexpediente = 'Concertada entrega: ' + this.fechaconcertada + ' de ' + this.horarioconcertacion + ' por Cliente';
    var canalconcer = 'Email';
    var email = this.albaran.EmailDestinatario? this.albaran.EmailDestinatario : '02601@grupomrw.com';
    var telefono = this.albaran.TelefonoDestinatario? this.albaran.TelefonoDestinatario : '';

    var concertada = {
      idenvio:this.albaran.Id,
      texto: notaexpediente,
      fecha: this.fechaconcertada,
      canalconcertacion: canalconcer,
      email: email,
      telefono: telefono
    }
    this._concertadasservice.mailconcertada(this.albaran).subscribe(response=>{});
    this._concertadasservice.concertarentrega(concertada).subscribe(
      response=>{
      console.log(response);
    });
  }
}
