<div style="display:inline-flex;width:100%;padding-top:10px;margin-top:10px;border-bottom: 0.5px solid black;">
	<div matTooltip="Nuevo Gasto" class="botonbarraherramientas fondooscuro" (click)="nuevogasto()" style="height:30px;margin-top:10px;margin-left:10px;">
		<span class="material-icons" style="vertical-align:middle;">add_shopping_cart</span>
	</div>
	<div class="botonbarraherramientas" style="margin-left:100px;">
		<mat-form-field style="text-align:center;font-size:10pt">
			<mat-label style="color:black;" (click)="picker.open()"><b>Rango Fechas</b></mat-label>
			<mat-date-range-input [rangePicker]="picker" (click)="picker.open()">
			  <input matStartDate placeholder="Fecha Inicio" [(ngModel)]="fechainicio">
			  <input matEndDate placeholder="Fecha fin" [(ngModel)]="fechafin" (dateChange)="onDaterangofechas()">
			</mat-date-range-input>
			<button mat-button *ngIf="fechainicio" matSuffix mat-icon-button aria-label="Clear" (click)="fechainicio=null;fechafin=null;gastosmesactual()">
			  <mat-icon>close</mat-icon>
			</button>
			<mat-date-range-picker #picker></mat-date-range-picker>
		  </mat-form-field>
	</div>
	<div class="botonbarraherramientas">
		<mat-button-toggle-group [(ngModel)]="tipogastos" (change)="toggletipogasto($event.value)">
			<mat-button-toggle value="todos">Todos</mat-button-toggle>
			<mat-button-toggle value="sintarjeta">Otros</mat-button-toggle>
			<mat-button-toggle value="tarjeta">Tarjeta</mat-button-toggle>
		</mat-button-toggle-group>
	</div>
	<div *ngIf="tipogastos == 'tarjeta'" class="botonbarraherramientas">
		<mat-form-field class="datovehiculo">
			<mat-label>Tarjeta</mat-label>
			<input matInput type="text" [matAutocomplete]="auto3" [(ngModel)]="tarjetaseleccionada" (ngModelChange)="seleccionatarjeta($event)">
			<button mat-button *ngIf="tarjetaseleccionada" matSuffix mat-icon-button aria-label="Clear" (click)="cancelaselecciontarjeta()">
				<mat-icon>close</mat-icon>
			</button>
			<mat-autocomplete #auto3="matAutocomplete">
				<mat-option *ngFor="let tarjeta of tarjetas" [value]="tarjeta.nombre + '-' + tarjeta.matricula">
					{{tarjeta.nombre}}-{{tarjeta.matricula}}
				</mat-option>
			</mat-autocomplete>
		</mat-form-field>
	</div>
	<div class="botonbarraherramientas">
		<mat-form-field class="datovehiculo">
			<mat-label>Mensajero</mat-label>
			<input matInput type="text" [matAutocomplete]="auto5" [(ngModel)]="gpm">
			<button mat-button *ngIf="gpm" matSuffix mat-icon-button aria-label="Clear" (click)="cancelagastospormensajero()">
				<mat-icon>close</mat-icon>
			</button>
			<mat-autocomplete #auto5="matAutocomplete" (optionSelected)="gastospormensajero($event.option.value)">
				<mat-option *ngFor="let mensajero of mensajeros" [value]="mensajero.numero + '-' + mensajero.nombre">
					{{mensajero.numero}}-{{mensajero.nombre}}
				</mat-option>
			</mat-autocomplete>
		</mat-form-field>
	</div>
	<div class="botonbarraherramientas">
		<mat-form-field class="datovehiculo">
			<mat-label>Vehiculo</mat-label>
			<input matInput type="text" [matAutocomplete]="auto6" [(ngModel)]="gpv">
			<button mat-button *ngIf="gpv" matSuffix mat-icon-button aria-label="Clear" (click)="cancelagastosporvehiculo()">
				<mat-icon>close</mat-icon>
			</button>
			<mat-autocomplete #auto6="matAutocomplete" (optionSelected)="gastosporvehiculo($event.option.value)">
				<mat-option *ngFor="let vehiculo of vehiculos" [value]="vehiculo.matricula">
					{{vehiculo.matricula}}
				</mat-option>
			</mat-autocomplete>
		</mat-form-field>
	</div>
</div>

<div style="width:100%;padding-top:10px;">
	<div class="contenidodatos">
		<table mat-table [dataSource]="gastos">
			<ng-container matColumnDef="fecha">
				<th mat-header-cell *matHeaderCellDef class="casilla">Fecha</th>
				<td mat-cell *matCellDef="let element" class="casilla">{{element.fecha | date:'dd/MM/yyyy'}} </td>
			</ng-container>
			<ng-container matColumnDef="matricula">
				<th mat-header-cell *matHeaderCellDef class="casilla">Matricula</th>
				<td mat-cell *matCellDef="let element" class="casilla">{{element.matricula}}</td>
			</ng-container>
			<ng-container matColumnDef="tarjeta">
				<th mat-header-cell *matHeaderCellDef class="casilla">Tarjeta</th>
				<td mat-cell *matCellDef="let element" class="casilla">{{element.tarjeta}}</td>
			</ng-container>
			<ng-container matColumnDef="mensajero">
				<th mat-header-cell *matHeaderCellDef class="casilla">Mensajero</th>
				<td mat-cell *matCellDef="let element" class="casilla">{{element.mensajero}}</td>
			</ng-container>
			<ng-container matColumnDef="importe">
				<th mat-header-cell *matHeaderCellDef class="casilla">Importe</th>
				<td mat-cell *matCellDef="let element" class="casilla">{{element.importe}} €</td>
			</ng-container>
			<ng-container matColumnDef="notas">
				<th mat-header-cell *matHeaderCellDef class="casilla">notas</th>
				<td mat-cell *matCellDef="let element" class="casilla">{{element.notas}}</td>
			</ng-container>
			<tr mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></tr>
			<tr mat-row *matRowDef="let row; columns: displayedColumns;" class="celdas" [class.seleccionada]="seleccion && seleccion._id == row._id" (click)="seleccionar(row)" (dblclick)="abregasto(row)"></tr>
		</table>
    </div>
	<div style="position:absolute;bottom:10px;display:inline-flex;margin-left:60px;margin-bottom:10px;height:50px">
		<div *ngIf="!tarjetaseleccionada" class="totales">importe total sin tarjeta: {{importesintarjeta.toFixed(2)}}€</div><div class="totales">importe total con tarjeta: {{importecontarjeta.toFixed(2)}}€</div><div class="totales">total gastos: {{importetotal.toFixed(2)}}€</div>
	</div>	
</div>    