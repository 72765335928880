//web
export var GLOBAL = {
  url: "https://www.mensasur.es/api/",
  urlincidencias: "https://www.mensasur.es/incidencias/",
  urlconcertadas: "https://www.mensasur.es/concertadas/",
  urlmodificados: "https://www.mensasur.es/modificados/",
  urlplantilla: "https://www.mensasur.es/plantillas/",
  urloffice: "https://www.mensasur.es/office/",
  urlemails: "https://www.mensasur.es/emails/",
  urldeudas: "https://www.mensasur.es/deudas/",
  urlvehiculo: "https://www.mensasur.es/vehiculos/",
  urldirecciones: "https://www.mensasur.es/direcciones/",
  urlcallg3: "https://www.mensasur.es/call/",
  urlseguimiento: "https://www.mensasur.es/seguimiento/",
  urlcomparativas: "https://www.mensasur.es/comparativas/",
  urlvacaciones: "https://www.mensasur.es/comparativas/",
  urlfichaje: "https://www.mensasur.es/fichaje/",
  urlchat: "https://www.mensasur.es/mensajes/",
};

//local

// export var GLOBAL = {
// 	url: 'http://localhost:3700/api/',
// 	urlincidencias: 'http://localhost:3700/incidencias/',
// 	urlconcertadas: 'http://localhost:3700/concertadas/',
// 	urlmodificados: 'http://localhost:3700/modificados/',
// 	urlplantilla: 'http://localhost:3700/plantillas/',
// 	urloffice: 'http://localhost:3700/office/',
// 	urlemails: 'http://localhost:3700/emails/',
// 	urldeudas: 'http://localhost:3700/deudas/',
// 	urlvehiculo: 'http://localhost:3700/vehiculos/',
// 	urldirecciones: 'http://localhost:3700/direcciones/',
// 	urlcallg3: 'http://localhost:3700/call/',
// 	urlseguimiento: 'http://localhost:3700/seguimiento/',
// 	urlcomparativas: 'http://localhost:3700/comparativas/',
// 	urlvacaciones: 'http://localhost:3700/vacaciones/',
// 	urlfichaje: 'http://localhost:3700/fichaje/',
// 	urlchat: 'http://localhost:3700/mensajes/'
// }
