<div class="barraseleccion">
  <mat-form-field style="margin-right:50px;width:250px;font-size:10pt;">
    <mat-label><b>Buscar Envio</b></mat-label>
    <input matInput type="text" [(ngModel)]="filtros['NumAlbaran']" style="padding:1px;color:black;"
          (keyup.enter)="buscarAlbaran(filtros['NumAlbaran'])" (ngModelChange)="filtros['NumAlbaran'] = $event.toUpperCase()">
    <button mat-button *ngIf="filtros['NumAlbaran']" matSuffix mat-icon-button aria-label="Clear" (click)="filtros['NumAlbaran']=''; getanomalias(fechainicio, fechafin)">
        <mat-icon>close</mat-icon>
    </button>
  </mat-form-field>
  <mat-form-field style="margin-left:50px;width:250px;font-size:10pt;">
    <mat-label><b>Buscar Referencia</b></mat-label>
    <input matInput type="text" [(ngModel)]="filtros['referencia']" style="padding:1px;color:black;"
          (keyup.enter)="buscarReferencia(filtros['referencia'])">
    <button mat-button *ngIf="filtros['referencia']" matSuffix mat-icon-button aria-label="Clear" (click)="filtros['referencia']=''; getanomalias(fechainicio, fechafin)">
        <mat-icon>close</mat-icon>
    </button>
  </mat-form-field>
  <mat-form-field style="margin-left:50px;width:250px;font-size:10pt;">
    <mat-label><b>Buscar Texto</b></mat-label>
    <input matInput (keyup)="applyFilter($event)" placeholder="buscar.." [(ngModel)]="filtro" style="padding:1px;color:black;">
    <button mat-button *ngIf="filtro" matSuffix mat-icon-button aria-label="Clear" (click)="dataSource.filter = ''; filtro=''">
        <mat-icon>close</mat-icon>
    </button>
  </mat-form-field>
  <mat-form-field style="margin-left:50px;margin-right:20px;width:250px;font-size:10pt;">
    <mat-label (click)="picker3.open()"><b>Rango fechas</b></mat-label>
    <mat-date-range-input [rangePicker]="picker3" (click)="picker3.open()">
      <input matStartDate placeholder="Fecha Inicio" [(ngModel)]="fechai">
      <input matEndDate placeholder="Fecha fin" [(ngModel)]="fechaf" (dateChange)="onDateFecha()">
    </mat-date-range-input>
    <button mat-button *ngIf="fechai" matSuffix mat-icon-button aria-label="Clear" (click)="fechai=''; fechaf=''; getanomalias(fechainicio, fechafin);">
      <mat-icon>close</mat-icon>
    </button>
    <mat-date-range-picker #picker3></mat-date-range-picker>
  </mat-form-field>
  <div class="botonbarra" style="height:30px;">
    <mat-icon class="material-icons-outlined" matTooltip="Exportar a excel" (click)="descargaExcel()">document_scanner</mat-icon>
  </div>
  <div *ngIf="seleccion" class="botonbarra" style="height:30px;">
    <mat-icon class="material-icons-outlined" matTooltip="Justificante Entrega" (click)="justificante()">receipt</mat-icon>
  </div>
  <div *ngIf="seleccion" class="botonbarra" style="height:30px;">
      {{seleccion.NumAlbaran}}
  </div>
  <div *ngIf="urlseguimientomrw && seguimiento" class="botonbarra" style="height:30px;">
    <mat-icon class="material-icons-outlined" matTooltip="Volver" (click)="seguimiento = false">keyboard_return</mat-icon>
  </div>
  <div *ngIf="seleccion" class="botonbarra" style="height:30px;">
      <mat-icon class="material-icons-outlined" matTooltip="Seguimiento" (click)="verseguimiento()">travel_explore</mat-icon>
  </div>
  <div *ngIf="seleccion && galleta" class="botonbarra" style="height:30px;">
    <mat-icon class="material-icons-outlined" matTooltip="Datos entrega" (click)="getexpediente()">content_paste_search</mat-icon>
  </div>
</div>
<div class="contenidoincidencias">
	<mat-progress-bar style="width:100%" *ngIf="!load" mode="indeterminate"></mat-progress-bar>
    <div *ngIf="urlseguimientomrw && seguimiento">
      <iframe [src]="urlseguimientomrw" width=100% height=500 frameborder=1 scrolling=auto></iframe>
    </div>
    <table *ngIf="load && !seguimiento" mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">
        <!--<ng-container matColumnDef="select" class="cabeceras">
		    <th mat-header-cell cdkDrag *matHeaderCellDef class="cabeceras">
		      <mat-checkbox color="primary" (change)="$event ? masterToggle() : null"
		                    [checked]="selection.hasValue() && isAllSelected()"
		                    [indeterminate]="selection.hasValue() && !isAllSelected()"
		                    [aria-label]="checkboxLabel()">
		      </mat-checkbox>
		    </th>
		    <td mat-cell *matCellDef="let row">
		      <mat-checkbox color="primary" (click)="$event.stopPropagation()"
		                    (change)="$event ? selection.toggle(row) : null"
		                    [checked]="selection.isSelected(row)"
		                    [aria-label]="checkboxLabel(row)">
		      </mat-checkbox>
		    </td>
		  </ng-container>-->
        <ng-container matColumnDef="FechaRecogida">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha </th>
          <td mat-cell *matCellDef="let row"> {{row.FechaRecogida | date:'dd/MM/yyyy'}} </td>
        </ng-container>

        <ng-container matColumnDef="NumAlbaran">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Albaran </th>
          <td mat-cell *matCellDef="let row"> {{row.NumAlbaran}} </td>
        </ng-container>

        <ng-container matColumnDef="Estado">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Estado </th>
          <td mat-cell *matCellDef="let row"> {{row.Estado}} </td>
        </ng-container>

        <ng-container matColumnDef="ReferenciaCliente">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Referencia </th>
          <td mat-cell *matCellDef="let row"> {{row.ReferenciaCliente}} </td>
        </ng-container>

        <ng-container matColumnDef="Destinatario">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Destinatario </th>
            <td mat-cell *matCellDef="let row"> {{row.Destinatario}} </td>
        </ng-container>

        <ng-container matColumnDef="DireccionDestinatario">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Direccion Destino </th>
          <td mat-cell *matCellDef="let row"> {{row.DireccionDestinatario}} </td>
        </ng-container>

        <ng-container matColumnDef="ObservacionesEntrega">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Observaciones </th>
          <td mat-cell *matCellDef="let row"> {{row.ObservacionesEntrega}} </td>
        </ng-container>

        <ng-container matColumnDef="TelefonoDestinatario">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Telefono </th>
          <td mat-cell *matCellDef="let row"> {{row.TelefonoDestinatario}} </td>
        </ng-container>

        <ng-container matColumnDef="TotalBultos">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Bultos </th>
            <td mat-cell *matCellDef="let row"> {{row.TotalBultos}} </td>
        </ng-container>
    
        <tr mat-header-row *matHeaderRowDef="totalcolumns; sticky:true"></tr>
        <tr mat-row *matRowDef="let row; columns: totalcolumns;" (click)="seleccionar(row)" class="celdas" [class.seleccionada]="seleccion && seleccion.NumAlbaran == row.NumAlbaran"></tr>
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="13">No hay datos que mostrar</td>
        </tr>
    </table>
</div>
<div *ngIf="load && dataSource.data.length >=1" style="margin-left:100px;margin-top:10px;margin-bottom:10px;padding:10px;"><b>{{'Total: ' + dataSource.data.length + ' Envios'}}</b></div>
<mat-progress-bar style="width:100%" *ngIf="!loaddatos" mode="indeterminate"></mat-progress-bar>
<div *ngIf="!loaddatos">Cargando datos de Entrega....</div>
<div *ngIf="datosentrega" style="display:inline-flex;margin-top:15px;">
  <ul style="list-style:none;">
    <li>
      ENVIO: {{seleccion.NumAlbaran}}
    </li>
    <li>
      FECHA/HORA ENTREGA: {{datosentrega.fechahora}}
    </li>
    <li>
      RECEPTOR: {{datosentrega.receptor}}
    </li>
    <li>
      DNI: {{datosentrega.dni}}
    </li>
  </ul>
  <div style="margin-left:30px;">
    <img [src]="foto"/>
  </div>
</div>