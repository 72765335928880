import { Injectable, EventEmitter } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs/Observable";
import { AppService } from "./app.service";
import { GLOBAL } from "./global";

@Injectable()
export class IncidenciasService {
  public url: string;
  public urloffice: string;
  public urlcliente: string;
  public albaran: string;
  public urlseguimiento: string;
  public direcciones;
  public fechadirecciones;

  constructor(public _http: HttpClient, public _appservice: AppService) {
    this.url = GLOBAL.urlincidencias;
    this.urloffice = GLOBAL.urloffice;
    this.urlseguimiento = GLOBAL.urlseguimiento;
  }

  getseguimiento(albaran): Observable<any> {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("authorization", this._appservice.getToken());
    return this._http.get(this.urlseguimiento + albaran, { headers: headers });
  }

  getseguimientocliente(albaran): Observable<any> {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("authorization", this._appservice.getToken());
    return this._http.get(this.urlseguimiento + "/cliente/" + albaran, {
      headers: headers,
    });
  }

  getincidencias(query, queryoptions): Observable<any> {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("authorization", this._appservice.getToken());
    var cuerpo = { query: query, options: queryoptions };
    var params = JSON.stringify(cuerpo);

    return this._http.post(this.url + "incidencias", params, {
      headers: headers,
    });
  }

  getincidencia(albaran): Observable<any> {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("authorization", this._appservice.getToken());
    return this._http.get(this.url + "incidencia/" + albaran, {
      headers: headers,
    });
  }

  guardarincidencia(incidencia): Observable<any> {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("authorization", this._appservice.getToken());
    var tmp = { incidencia: incidencia };
    var params = JSON.stringify(tmp);
    return this._http.post(this.url + "guardaincidencia", params, {
      headers: headers,
    });
  }

  borrarincidencia(albaran): Observable<any> {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("authorization", this._appservice.getToken());
    var tmp = { incidencia: albaran };
    var params = JSON.stringify(tmp);
    return this._http.post(this.url + "borrarincidencia", params, {
      headers: headers,
    });
  }

  enviaremail(datos): Observable<any> {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("authorization", this._appservice.getToken());
    var params = JSON.stringify(datos);
    return this._http.post(this.url + "enviarmail", params, {
      headers: headers,
    });
  }

  albaranG3(albaran): Observable<any> {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("authorization", this._appservice.getToken());
    let cookie = {
      galletag3: this._appservice.getgalletag3(),
      authorization: this._appservice.getgauthG3(),
    };
    let tmp = { albaran: albaran, cookie: cookie };
    var params = JSON.stringify(tmp);
    return this._http.post(this.url + "albg3", params, { headers: headers });
  }

  notaG3(nota): Observable<any> {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("authorization", this._appservice.getToken());
    let cookie = {
      galletag3: this._appservice.getgalletag3(),
      authorization: this._appservice.getgauthG3(),
    };
    let tmp = { nota: nota, cookie: cookie };
    var params = JSON.stringify(tmp);

    return this._http.post(this.url + "notag3", params, { headers: headers });
  }

  imgExp(idenvio, direxpediente): Observable<any> {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("authorization", this._appservice.getToken());
    let cookie = {
      galletag3: this._appservice.getgalletag3(),
      authorization: this._appservice.getgauthG3(),
    };
    let temp = {
      idenvio: idenvio,
      direxpediente: direxpediente,
      cookie: cookie,
    };
    let params = JSON.stringify(temp);

    return this._http.post(this.url + "imgexp", params, { headers: headers });
  }

  guardarconfig(dato): Observable<any> {
    let temp = { dato: dato };
    let params = JSON.stringify(temp);
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("authorization", this._appservice.getToken());
    return this._http.post(this.url + "guardarconfig", params, {
      headers: headers,
    });
  }

  verconfig(): Observable<any> {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("authorization", this._appservice.getToken());
    return this._http.get(this.url + "verconfig", { headers: headers });
  }

  getincidenciasnuevas(query): Observable<any> {
    let headers = new HttpHeaders().set("Content-Type", "application/json");
    var queryoptions = { page: 1, limit: 50 };
    var cuerpo = { query: query, options: queryoptions };
    var params = JSON.stringify(cuerpo);

    return this._http.post(this.url + "incidenciasnuevas", params, {
      headers: headers,
    });
  }

  getexpediente(incidencia): Observable<any> {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("authorization", this._appservice.getToken());
    let cookie = {
      galletag3: this._appservice.getgalletag3(),
      authorization: this._appservice.getgauthG3(),
    };
    var tmp = { incidencia: incidencia, cookie: cookie };
    var params = JSON.stringify(tmp);
    return this._http.post(this.url + "getexpediente", params, {
      headers: headers,
    });
  }

  getdetalleexpediente(incidencia, idexp, fecha): Observable<any> {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("authorization", this._appservice.getToken());
    let cookie = {
      galletag3: this._appservice.getgalletag3(),
      authorization: this._appservice.getgauthG3(),
    };
    var tmp = {
      incidencia: incidencia,
      idexp: idexp,
      fecha: fecha,
      galleta: cookie,
    };
    var params = JSON.stringify(tmp);
    return this._http.post(this.url + "detalleexpediente", params, {
      headers: headers,
    });
  }

  getfotoexpediente(incidencia, estado, fecha, hora): Observable<any> {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("authorization", this._appservice.getToken());
    let cookie = {
      galletag3: this._appservice.getgalletag3(),
      authorization: this._appservice.getgauthG3(),
    };
    var tmp = {
      numalbaran: incidencia,
      estado: estado,
      fecha: fecha,
      hora: hora,
      galleta: cookie,
    };
    var params = JSON.stringify(tmp);
    return this._http.post(this.url + "fotosexpediente", params, {
      headers: headers,
    });
  }

  getvaloracion(idalbaran): Observable<any> {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("authorization", this._appservice.getToken());
    let cookie = {
      galletag3: this._appservice.getgalletag3(),
      authorization: this._appservice.getgauthG3(),
    };
    var tmp = { idalbaran: idalbaran, cookie: cookie };
    var params = JSON.stringify(tmp);

    return this._http.post(this.url + "valoracion", params, {
      headers: headers,
    });
  }

  getcomision(idalbaran): Observable<any> {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("authorization", this._appservice.getToken());
    let cookie = {
      galletag3: this._appservice.getgalletag3(),
      authorization: this._appservice.getgauthG3(),
    };
    var tmp = { idalbaran: idalbaran, cookie: cookie };
    var params = JSON.stringify(tmp);
    return this._http.post(this.url + "comisionadelanto", params, {
      headers: headers,
    });
  }

  setultimoalbaran(albaran) {
    localStorage.setItem("ultimoalbaran", JSON.stringify(albaran));
  }

  getultimoalbaran() {
    let alb = JSON.parse(localStorage.getItem("ultimoalbaran"));
    if (alb) {
      this.albaran = alb;
    } else {
      this.albaran = null;
    }
    return this.albaran;
  }

  getanomaliascliente(query): Observable<any> {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("authorization", this._appservice.getToken());
    var tmp = { query: query };
    var params = JSON.stringify(tmp);
    return this._http.post(this.url + "anomaliascliente", params, {
      headers: headers,
    });
  }

  getrecibidos(): Observable<any> {
    let cookie = {
      galletag3: this._appservice.getgalletag3(),
      authorization: this._appservice.getgauthG3(),
    };
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("authorization", this._appservice.getToken());
    var cuerpo = { cookie: cookie };
    var params = JSON.stringify(cuerpo);
    return this._http.post(this.url + "recibidos", params, {
      headers: headers,
    });
  }

  creaexcel(datos): Observable<any> {
    let params = JSON.stringify({ datos: datos });
    let headers = new HttpHeaders()
      .append("Content-Type", "application/json")
      .set("authorization", this._appservice.getToken());
    return this._http.post(this.url + "datosexcel", params, {
      responseType: "blob",
      headers: headers,
    });
  }
}
