import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { routing, appRoutingProviders } from "./app.routing";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatNativeDateModule } from "@angular/material/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MatInputModule } from "@angular/material/input";
import { MatMenuModule } from "@angular/material/menu";
import {
  MatDialogModule,
  MAT_DIALOG_DEFAULT_OPTIONS,
} from "@angular/material/dialog";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatListModule } from "@angular/material/list";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatSelectModule } from "@angular/material/select";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatCardModule } from "@angular/material/card";
import { MatChipsModule } from "@angular/material/chips";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatTableModule } from "@angular/material/table";
import { MatTabsModule } from "@angular/material/tabs";
import { MatBadgeModule } from "@angular/material/badge";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatSortModule } from "@angular/material/sort";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { IvyCarouselModule } from "./components/ivyсarousel_pro/carousel.module";
import { AppService } from "./servicios/app.service";
import { MatTooltipModule } from "@angular/material/tooltip";
import { CKEditorModule } from "@ckeditor/ckeditor5-angular";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { SocketIoModule } from "ngx-socket-io";
import { ChatService } from "./servicios/chat.service";

import { AppComponent } from "./app.component";
import { LoginComponent } from "./components/login/login.component";
import { InicioComponent } from "./components/inicio/inicio.component";
import { FacturasComponent } from "./components/facturas/facturas.component";
import { CarpetasComponent } from "./components/carpetas/carpetas.component";
import { RegistroComponent } from "./components/registro/registro.component";
import { DatosclienteComponent } from "./components/datoscliente/datoscliente.component";
import { RestablecerpassComponent } from "./components/restablecerpass/restablecerpass.component";
import { ContactoComponent } from "./components/contacto/contacto.component";
import { PassolvidadaComponent } from "./components/passolvidada/passolvidada.component";
import { ClientesComponent } from "./components/clientes/clientes.component";
import { MisarchivosComponent } from "./components/misarchivos/misarchivos.component";
import { ArchivosclientesComponent } from "./components/archivosclientes/archivosclientes.component";
import { SubirfacturasComponent } from "./components/subirfacturas/subirfacturas.component";
import { ConfiguracionComponent } from "./components/configuracion/configuracion.component";
import { CobromensajeroComponent } from "./components/cobromensajero/cobromensajero.component";
import { MensajerosComponent } from "./components/mensajeros/mensajeros.component";
import { IncidenciasComponent } from "./components/incidencias/incidencias.component";
import { GestionusuariosComponent } from "./components/gestionusuarios/gestionusuarios.component";
import { AlbaranComponent } from "./components/albaran/albaran.component";
import { RegusuariosComponent } from "./components/regusuarios/regusuarios.component";
import { AdmintabComponent } from "./components/admintab/admintab.component";
import { ImagenesexpedienteComponent } from "./components/imagenesexpediente/imagenesexpediente.component";
import { MailcorporativoComponent } from "./components/mailcorporativo/mailcorporativo.component";
import { ConcertadasComponent } from "./components/concertadas/concertadas.component";
import { AlbconcertadaComponent } from "./components/albconcertada/albconcertada.component";
import { DialogoimpresasComponent } from "./components/dialogoimpresas/dialogoimpresas.component";
import { DireccionesmensajeroComponent } from "./components/direccionesmensajero/direccionesmensajero.component";
import { MensajeroComponent } from "./components/mensajero/mensajero.component";
import { DeudasComponent } from "./components/deudas/deudas.component";
import { NuevadeudaComponent } from "./components/nuevadeuda/nuevadeuda.component";
import { GestiondeudasComponent } from "./components/gestiondeudas/gestiondeudas.component";
import { VehiculosComponent } from "./components/vehiculos/vehiculos.component";
import { VehiculoComponent } from "./components/vehiculo/vehiculo.component";
import { CobrofacturasComponent } from "./components/cobrofacturas/cobrofacturas.component";
import { MisenviosComponent } from "./components/misenvios/misenvios.component";
import { MailmasivoclientesComponent } from "./components/mailmasivoclientes/mailmasivoclientes.component";
import { NuevoenvioComponent } from "./components/nuevoenvio/nuevoenvio.component";
import { EnviosclienteComponent } from "./components/envioscliente/envioscliente.component";
import { EnviosEditablesclienteComponent } from "./components/envioseditablescliente/envioseditablescliente.component";
import { NuevadireccionComponent } from "./components/nuevadireccion/nuevadireccion.component";
import { GastosComponent } from "./components/gastos/gastos.component";
import { GastoComponent } from "./components/gasto/gasto.component";
import { TarjetasComponent } from "./components/tarjetas/tarjetas.component";
import { TarjetaComponent } from "./components/tarjeta/tarjeta.component";
import { AnomaliasclientesComponent } from "./components/anomaliasclientes/anomaliasclientes.component";
import { NoclientesComponent } from "./components/noclientes/noclientes.component";
import { ComparafacturacentralComponent } from "./components/comparafacturacentral/comparafacturacentral.component";
import { GestionanomaliaComponent } from "./components/gestionanomalia/gestionanomalia.component";
import { AlbclienteComponent } from "./components/albcliente/albcliente.component";
import { GestionenviosComponent } from "./components/gestionenvios/gestionenvios.component";
import { ModificadosComponent } from "./components/modificados/modificados.component";
import { EnviosrecibidosComponent } from "./components/enviosrecibidos/enviosrecibidos.component";
import { DestinatariosmasivoComponent } from "./components/destinatariosmasivo/destinatariosmasivo.component";
import { DestinatariospeticionComponent } from "./components/destinatariospeticion/destinatariospeticion.component";
import { VentasComponent } from "./components/ventas/ventas.component";
import { VentascomercialComponent } from "./components/ventascomercial/ventascomercial.component";
import { EnviosexcelComponent } from "./components/enviosexcel/enviosexcel.component";
import { AdminenviosclientesComponent } from "./components/adminenviosclientes/adminenviosclientes.component";
import { DescargaenlaceComponent } from "./components/descargaenlace/descargaenlace.component";
import { DialogfichajeComponent } from "./components/dialogfichaje/dialogfichaje.component";
import { FichajesComponent } from "./components/fichajes/fichajes.component";
import { FichajemensajerosComponent } from "./components/fichajemensajeros/fichajemensajeros.component";
import { DialogfichamensajeroComponent } from "./components/dialogfichamensajero/dialogfichamensajero.component";
import { FichajeusuariosComponent } from "./components/fichajeusuarios/fichajeusuarios.component";
import { ChatComponent } from "./components/chat/chat.component";
import { MensajeriaComponent } from "./components/mensajeria/mensajeria.component";
import { SubirMasivoComponent } from "./components/subirmasivo/subirmasivo.component";
import { AlbEditableComponent } from "./components/albeditable/albeditable.component";

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    InicioComponent,
    FacturasComponent,
    CarpetasComponent,
    RegistroComponent,
    DatosclienteComponent,
    RestablecerpassComponent,
    ContactoComponent,
    PassolvidadaComponent,
    ClientesComponent,
    MisarchivosComponent,
    ArchivosclientesComponent,
    SubirfacturasComponent,
    ConfiguracionComponent,
    CobromensajeroComponent,
    MensajerosComponent,
    IncidenciasComponent,
    GestionusuariosComponent,
    AlbaranComponent,
    RegusuariosComponent,
    AdmintabComponent,
    ImagenesexpedienteComponent,
    MailcorporativoComponent,
    ConcertadasComponent,
    AlbconcertadaComponent,
    DialogoimpresasComponent,
    DireccionesmensajeroComponent,
    MensajeroComponent,
    DeudasComponent,
    NuevadeudaComponent,
    GestiondeudasComponent,
    VehiculosComponent,
    VehiculoComponent,
    CobrofacturasComponent,
    MisenviosComponent,
    MailmasivoclientesComponent,
    NuevoenvioComponent,
    EnviosclienteComponent,
    NuevadireccionComponent,
    GastosComponent,
    GastoComponent,
    TarjetasComponent,
    TarjetaComponent,
    AnomaliasclientesComponent,
    NoclientesComponent,
    ComparafacturacentralComponent,
    GestionanomaliaComponent,
    AlbclienteComponent,
    GestionenviosComponent,
    ModificadosComponent,
    EnviosrecibidosComponent,
    DestinatariosmasivoComponent,
    DestinatariospeticionComponent,
    VentasComponent,
    VentascomercialComponent,
    EnviosexcelComponent,
    AdminenviosclientesComponent,
    DescargaenlaceComponent,
    DialogfichajeComponent,
    FichajesComponent,
    FichajemensajerosComponent,
    DialogfichamensajeroComponent,
    FichajeusuariosComponent,
    ChatComponent,
    MensajeriaComponent,
    SubirMasivoComponent,
    EnviosEditablesclienteComponent,
    AlbEditableComponent,
  ],
  imports: [
    BrowserModule,
    routing,
    HttpClientModule,
    FormsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    BrowserAnimationsModule,
    MatInputModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatMenuModule,
    MatSelectModule,
    MatSidenavModule,
    MatToolbarModule,
    MatListModule,
    MatIconModule,
    MatExpansionModule,
    MatCheckboxModule,
    MatCardModule,
    MatChipsModule,
    MatSlideToggleModule,
    MatTabsModule,
    MatBadgeModule,
    MatAutocompleteModule,
    MatProgressBarModule,
    MatTableModule,
    MatSelectModule,
    DragDropModule,
    MatPaginatorModule,
    MatSortModule,
    ReactiveFormsModule,
    MatSnackBarModule,
    MatDialogModule,
    IvyCarouselModule,
    MatTooltipModule,
    CKEditorModule,
    SocketIoModule,
  ],
  providers: [
    appRoutingProviders,
    AppService,
    MatDatepickerModule,
    MatNativeDateModule,
    ChatService,
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: { hasBackdrop: false, disableClose: true },
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
