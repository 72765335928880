import { Component, OnInit } from '@angular/core';
import { ComparativaService } from '../../servicios/comparativas.service';
import { IncidenciasService } from '../../servicios/incidencias.service';
import { MatDialog } from '@angular/material/dialog';
import { AlbaranComponent } from '../albaran/albaran.component';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition, MatSnackBarConfig } from '@angular/material/snack-bar';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import * as moment from 'moment';
import * as XLSX from 'xlsx';


@Component({
  selector: 'app-comparafacturacentral',
  templateUrl: './comparafacturacentral.component.html',
  styleUrls: ['./comparafacturacentral.component.css'],
  providers: [ComparativaService, IncidenciasService]
})
export class ComparafacturacentralComponent implements OnInit {
  public excelCentral;
  public serviciosCentral = {};
  public serviciosLocal = {};
  public fechainicio;
  public fechafin;
  public load;
  public totalescentral;
  public totalesagencia;
  public noencentral;
  public noenagencia;
  public mensajeenvios;
  public fechaexcel;
  public albaranesmostraragencia;
  public albaranesmostrarcentral;
  public category;

  constructor(
    private dateAdapter: DateAdapter<Date>,
    private _incidenciasservice: IncidenciasService,
    public ventanaAlbaran: MatDialog,
    private _snackBar: MatSnackBar,
    private _comparativaservice: ComparativaService
  ) { 
    this.dateAdapter.setLocale('es');
    this.dateAdapter.getFirstDayOfWeek = () => { return 1; };
    this.load = true;
  }

  ngOnInit(): void {
  }

  aviso(texto, subtexto) {
    this._snackBar.open(texto,subtexto,{
      duration: 4000,
      horizontalPosition: "center",
      verticalPosition: "top",
    });
  }

  entregas(){
    this.load = false;
    this.serviciosLocal = {};
    this.totalesagencia = [];
    var fecha = this.fechaexcel.split('.');
    console.log(fecha);
    let mes = fecha[1];
    if(mes.length <= 1) mes = '0' + mes;
    let fech = '01/' + mes + '/' + fecha[2];
    var fechainicial = moment(fech, 'DD/MM/YYYY');
    console.log(fechainicial);
    var fi = moment(fechainicial).startOf('month').set({hour:4,minute:0,second:0,millisecond:0}).toISOString(true);
    var ff = moment(fechainicial).endOf('month').set({hour:4,minute:0,second:0,millisecond:0}).toISOString(true);
		var query = {$and:[{FechaEnvio:{$gte:fi}}, {FechaEnvio:{$lte:ff}}]};
    this._comparativaservice.getentregas(query).subscribe(
      async response=>{
        var envios = response.envios;
        var contador = 0;
        for(let i = 0;i < envios.length;i++){
          var servicio = envios[i].Servicio.trim();
          var albaran = envios[i].NumAlbaran;
          if(this.serviciosLocal[servicio] && this.serviciosLocal[servicio].nombre == servicio){
            this.serviciosLocal[servicio].envios.push(albaran);
            this.serviciosLocal[servicio].cantidad = this.serviciosLocal[servicio].envios.length;
          }else{
            var nuevo = {
              nombre: servicio,
              cantidad: 1,
              envios: [albaran]
            }
            this.serviciosLocal[servicio] = nuevo;
          }
          this.totalesagencia.push(albaran)
          if(contador >= envios.length-1){
            this.comparaAlbaranes();
            this.load = true;
          }else{
            contador++
          }
        }
      },
      error=>{
        console.log(error);
        this.load = true;
      }
    )
  }

  onDaterangofechas(){
    var fechainicial = moment(this.fechainicio).set({hour:4,minute:0,second:0,millisecond:0});
    if(!this.fechafin){
     var fechafinal = moment(this.fechafin).set({hour:21,minute:0,second:0,millisecond:0}).toISOString(true);
    }else{
      var fechafinal = moment(this.fechafin).set({hour:23,minute:0,second:0,millisecond:0}).toISOString(true);
    }
    let dia = moment(fechainicial).format('DD');
    let mes = moment(fechainicial).format('MM');
    let ano = moment(fechainicial).format('YYYY');
    this.fechaexcel = dia + '.' + mes + '.' + ano;
    this.entregas();
  }


  onFileChange(event: any) {
    /* wire up file reader */
    this.load = false;
    const target: DataTransfer = <DataTransfer>(event.target);
    if (target.files.length !== 1) {
      throw new Error('Cannot use multiple files');
    }
    const reader: FileReader = new FileReader();
    reader.readAsBinaryString(target.files[0]);
    reader.onload = (e: any) => {
      /* create workbook */
      const binarystr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(binarystr, { type: 'binary' });

      /* selected the first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data */
      var data = XLSX.utils.sheet_to_json(ws, {header: 1}); // to get 2d array pass 2nd parameter as object {header: 1}
      this.procesaexcel(data);// Data will be logged in array format containing objects
    };
  }

  procesaexcel(excel){
    this.serviciosCentral = {};
    this.totalescentral = [];
    this.excelCentral = excel;
    var contador = 0;
    for(let i = 0; i < excel.length -1; i++){
      var line = excel[i];
      if(line[5] && !line[0] && line.length >= 25){
        var linea = {
          nombre: line[5],
          cantidad: parseInt(line[11]),
          envios: this.partirservicio(i +1, parseInt(line[11]))
        }
        this.serviciosCentral[line[5]] = linea;
        this.totalescentral = this.totalescentral.concat(linea.envios);
        var indicesiguiente = i+2;
        if(this.excelCentral[indicesiguiente] && this.excelCentral[indicesiguiente][8] && !this.fechaexcel){
          this.fechaexcel= this.excelCentral[indicesiguiente][8];
        }
      }
      if(contador >= excel.length -1){
        this.totalescentral = this.totalescentral.map((alb)=>{
          return alb[11];
        }).sort(function(a, b){
          return a > b;
        });;
        this.entregas();
      }else{
        contador++
      }
    };
  }

  partirservicio(inicio, fin){
    var servicio = this.excelCentral.splice(inicio, fin);
    return servicio;
  }

  comparaAlbaranes(){
    this.noenagencia = this.totalescentral.filter((albaran)=>{
      if(this.totalesagencia.includes(albaran)){
        return false;
      }else{
        return true;
      }
    });
    this.noencentral = this.totalesagencia.filter((albaran)=>{
      if(this.totalescentral.includes(albaran)){
        return false;
      }else{
        return true;
      }
    });
    this.mostrartotales();
  }

  mostrartotales(){
    this.albaranesmostraragencia = this.totalesagencia;
    this.albaranesmostrarcentral = this.totalescentral;
    this.mensajeenvios = 'Diferencia envios total';
  }

  comparaservicio(serviciocentral, servicioagencia){
    var central = this.serviciosCentral[serviciocentral].envios.map((envio)=>{
      return envio[11];
    });
    this.albaranesmostraragencia= this.serviciosLocal[servicioagencia].envios.filter((albaran)=>{
      if(central.includes(albaran)){
        return false;
      }else{
        return true;
      }
    }).sort(function(a, b){
      return a > b;
    });
    this.albaranesmostrarcentral  = central.filter((albaran)=>{
      if(this.serviciosLocal[servicioagencia].envios.includes(albaran)){
        return false;
      }else{
        return true;
      }
    }).sort(function(a, b){
      return a > b;
    });
    this.mensajeenvios = 'Diferencia envios servicio: ' + serviciocentral;
  }

  serviciocentral(servicio){
    this.albaranesmostrarcentral = this.serviciosCentral[servicio].envios.map((envio)=>{
      return envio[11];
    }).sort(function(a, b){
      return a > b;
    });
    this.albaranesmostraragencia = undefined;
    this.mensajeenvios = 'Albaranes Central: ' + servicio;
  }

  servicioagencia(servicio){
    this.albaranesmostraragencia = this.serviciosLocal[servicio].envios.sort(function(a, b){
      return a > b;
    });
    this.albaranesmostrarcentral = undefined;
    this.mensajeenvios = 'Albaranes Agencia: ' + servicio;
  }

  bucaryabrir(alb){
    var albaran = alb.trim();
    this._incidenciasservice.getincidencia(albaran).subscribe(
        response =>{
          if(response.incidencia){
            this.abrealbaran(response.incidencia);
          }else{
            this.aviso('No se ha encontrado albaran', 'error');
          } 
        },
        error=>{
          console.log(<any>error);
        });  
  }
  
  abrealbaran(incidencia){
    var albaranabierto = this.ventanaAlbaran.open(AlbaranComponent, {
      width: '85%',
      height: '85%',
      data: incidencia
    });
  }

  onCsvChange(event: any) {
    /* wire up file reader */
    this.load = false;
    const target: DataTransfer = <DataTransfer>(event.target);
    if (target.files.length !== 1) {
      throw new Error('Cannot use multiple files');
    }
    const reader: FileReader = new FileReader();
    reader.readAsText(target.files[0]);
    reader.onload = (e: any) => {
      /* create workbook */
      const datos = e.target.result;
      this.load = true;
      console.log(datos);
    };
  }

  getfacturados(){
    
  }

}
