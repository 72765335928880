<div style="width:100%;display:inline-flex">
	<div class="spacercorto"></div>
	<button style="margin-right:20px;" *ngIf="identity && identity.role != 'cliente'" mat-raised-button (click)="vermensajerostoggle()">Ver Mensajeros</button>
	<div style="width:70%;display:inline-flex">
		<h1>{{title}}</h1>
	</div>	
</div>
<br><br>
<div style="width:100%;">
	<div style="display:inline-flex;width:100%">
		<div style="width:15%">
			<div *ngIf="identity" class="contenedorarchivosgenerales">
				<h3>Descargate nuestras tarifas</h3>
				<ul class="archivosgenerales">
					<li class="fondooscuro" style="cursor:pointer;font-size:12pt;" (click)="descargageneral('condiciones.pdf')">
						<span class="material-icons md-36" style="margin-right:7px;">picture_as_pdf</span>Condiciones del Servicio<br>+ Prestaciones Complementarias {{aactual}}
					</li>
					<li class="fondooscuro" style="cursor:pointer;font-size:12pt;" (click)="descargageneral('tarifas.pdf')">
						<span class="material-icons md-36" style="margin-right:7px;">picture_as_pdf</span>Tarifas Cliente Abonado {{aactual}}
					</li>
					<li class="fondooscuro" style="cursor:pointer;font-size:12pt;" (click)="descargageneral('tarifas_ecommerce.pdf')">
						<span class="material-icons md-36" style="margin-right:7px;">picture_as_pdf</span>Tarifas Ecommerce {{aactual}}
					</li>
					<li class="fondooscuro" style="cursor:pointer;font-size:12pt;" (click)="descargageneral('Condiciones_generales.pdf')">
						<span class="material-icons md-36" style="margin-right:7px;">picture_as_pdf</span>Condiciones Generales
					</li>
					<li class="fondooscuro" style="cursor:pointer;font-size:12pt;" (click)="descargageneral('APC_Manual_ Cliente.pdf')">
						<span class="material-icons md-36" style="margin-right:7px;">picture_as_pdf</span>Manual APC (Area Privada Cliente)
					</li>
				</ul>
			</div>
		</div>	
		<div *ngIf="slides.length == cantidadimagenes && !vermensajeros" class="carruselsinlogin" [class.carruselconlogin]="identity">
			<carousel
				#myCarousel="carousel"
			    [images]="slides"
			    [arrows]="false"
			    [objectFit]="'contain'"
			    [cellWidth]="'100%'"
			    [autoplay]="true"
			    [autoplayInterval]="5000"
				[pauseOnHover] = "false"
				[freeScroll] = "false"
			    [height]="500"
			    [dots]="true"
			    (events)="eventoscarrusel($event)"
			    >
			</carousel>
		</div>
		<div style="padding-left:30px;" *ngIf="vermensajeros && datosmensajeros">
			<table style="font-size:11pt;">
				<thead>
					<tr>
						<th *ngFor="let head of datosmensajeros.cabeceras" style="margin:2px;padding:2px;">{{head}}</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let row of datosmensajeros.filas" class="fondooscuro">
						<td *ngFor="let dato of row">
							{{dato}}
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>		
	<div class="negro letramin" style="width:100%;margin-top:20px;padding-left:15px;text-align:left">
		<div style="display:inline-flex;width:100%;padding-left:20%">
			<div><a [routerLink]="['../contacto/' + mailcomercial + '/' + comercial]" style="margin-right:20px;"><mat-icon style="margin-right:5px;font-size:18pt">contact_mail</mat-icon>para solicitar informacion comercial, contactenos en: <span class="resaltado">{{mailcomercial}}</span></a></div>
			<div><a [routerLink]="['../contacto/' + mailadmin + '/' + admin]"><mat-icon style="margin-right:5px;font-size:18pt">manage_accounts</mat-icon>contacte con Administracion/Facturacion en: <span class="resaltado">{{mailadmin}}</span></a></div>
		</div>
		<div style="display:inline-flex;width:100%;margin-top:15px;padding-left:20%">
			<a *ngIf="identity" [routerLink]="['../contacto/' + mailclientes + '/' + clientes]" style="margin-right:20px;"><mat-icon style="margin-right:5px;font-size:18pt">local_shipping</mat-icon>consultas sobre sus envios/incidencias en: <span class="resaltado">{{mailclientes}}</span></a>
			<a [routerLink]="['../contacto/' + mailadmin + '/' + admin]"><mat-icon style="margin-right:5px;font-size:18pt">room_preferences</mat-icon>cualquier sugerencia o problema con esta plataforma, por favor dirijase a: <span class="resaltado">{{mailadmin}}</span></a>
		</div>	
	</div>
</div>
