import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs/Observable";
import { GLOBAL } from "./global";

@Injectable()
export class UploadService {
  public url: string;

  constructor(public _http: HttpClient) {
    this.url = GLOBAL.url;
  }

  makeFilesRequest(numerocliente, files: Array<File>): Observable<any> {
    var formData = new FormData();
    for (var i = 0; i < files.length; i++) {
      formData.append("archivo" + i, files[i], files[i].name);
    }
    formData.append("cliente", numerocliente);

    var token = sessionStorage.getItem("token");
    var uri = this.url + "subirarchivos";
    let headers = new HttpHeaders().set("Authorization", token);
    return this._http.post(uri, formData, { headers: headers });
  }

  makeFacturasRequest(
    files: Array<File>,
    mes,
    ano,
    token: string
  ): Observable<any> {
    var formData = new FormData();
    for (var i = 0; i < files.length; i++) {
      formData.append("archivo" + i, files[i], files[i].name);
    }
    formData.append("mes", mes);
    formData.append("ano", ano);
    let headers = new HttpHeaders().set("Authorization", token);
    return this._http.post(this.url + "subirfacturas", formData, {
      headers: headers,
    });
  }

  makeMasivoRequest(files: Array<File>, cookies, cliente): Observable<any> {
    var formData = new FormData();
    for (var i = 0; i < files.length; i++) {
      formData.append("archivo" + i, files[i], files[i].name);
    }
    formData.append("cliente", cliente);
    formData.append("cookies", cookies);
    var token = sessionStorage.getItem("token");
    var uri = this.url + "subemasivo";
    let headers = new HttpHeaders().set("Authorization", token);

    return this._http.post(uri, formData, { headers: headers });
  }
}
