import { Component, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ClienteService } from '../../servicios/cliente.service';
import { UploadService } from '../../servicios/upload.service';
import { EnviosEditablesclienteComponent } from '../envioseditablescliente/envioseditablescliente.component';
import { AppService } from '../../servicios/app.service'
import * as moment from 'moment';

@Component({
  selector: 'app-subirmasivo',
  templateUrl: './subirmasivo.component.html',
  styleUrls: ['./subirmasivo.component.css'],
  providers: [ClienteService, UploadService, AppService],
  encapsulation: ViewEncapsulation.None
})
export class SubirMasivoComponent implements OnInit {
	  public title: string;
	  public filesToUload: Array<File>;
	  public status: string;
	  public editables: EnviosEditablesclienteComponent;
	  public procesando = '';
	  public envios;
	  public contenidoinput = '';
	  public grupos = [];

	  @ViewChild('EnviosEditablesclienteComponent') set content(content: EnviosEditablesclienteComponent){
		if(content){
		  this.editables = content;
		}
	  }

  constructor(
    private _clienteService: ClienteService,
    private _uploadService: UploadService,
	private _appservice: AppService
  ) { 
  	
  }

	ngOnInit(): void {
		setTimeout(()=>{
			this.getmasivo();
		},2000);
	}

	fileChangeEvent(fileInput: any){
	  this.filesToUload = <Array<File>>fileInput.target.files;
	}

 	subir(){
		this.editables.load = false;
		this.procesando = `Procesando Archivo....
		espere por favor`
		const galleta = sessionStorage.getItem('galletag3');
		if(!galleta){
			alert('hay un problema de conexion a central MRW, intentelo de nuevo mas tarde');
		}else{
			const user = this._appservice.getIdentity();
			this._uploadService.makeMasivoRequest(this.filesToUload, galleta, user['numero']).subscribe(
				response=>{
					console.log(response);
					this.getmasivo();
					this.procesando = '';
				}
			)	
		}
		this.contenidoinput = '';
	}

	getmasivo(){
		this.editables.load = false;
		this.editables.dataSource.data = [];
		this.editables.selection.clear();
		this._clienteService.getgrupoeditablecliente().subscribe(
			(response:any)=>{
				this.envios = [];
				this.grupos = response.grupos;
				response.grupos.forEach((grupo) => {
					this.envios = grupo.envios.map((env)=>{
						env['grupo'] = grupo._id;
						return env;
					}).concat(this.envios);	
				});
				console.log(this.envios);
				this.editables.dataSource.data = this.envios.filter((env)=>{
					if(env.cancelado){
						return false;
					}else{
						return true;
					}
				})
				this.editables.load = true;
			}
		)
	}

	tipoenvio(tipo){
		this.editables.selection.clear();
		if(tipo == 'todos'){
			this.getmasivo();
		}else if(tipo == 'impresos'){
			this.editables.dataSource.data = [];
			this.editables.dataSource.data = this.envios.filter((env)=>{
				if(!env.cancelado && env.impreso){
					return true;
				}else{
					return false;
				}
			});
		}else if(tipo == 'noimpresos'){
			this.editables.dataSource.data = [];
			this.editables.dataSource.data = this.envios.filter((env)=>{
				if(!env.cancelado && !env.impreso){
					return true;
				}else{
					return false;
				}
			});
		}		
	}

	actualizagrupo(envio){
		const grupo = this.grupos.find(grup=>grup._id == envio.grupo);
		grupo.envios[grupo.envios.indexOf(gr=>gr.albaran == envio.albaran)] = envio;
		this._clienteService.actualizagrupo(grupo, grupo._id).subscribe(
			res=>{
				console.log(res);
			}
		)
	}

	mensajeprocesando(mensaje){
		this.procesando = mensaje;
	}
}