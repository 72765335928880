<div class="titulobarraprincipalapp">
    <img src="../../../assets/Images/logo.png">
    <div class="trans">
        <div style="padding:0px;margin:0px">
            Mensasur Getafe
        </div>	
        <div style="font-size: 8pt;padding:0px;margin:0px">
            Franquicia MRW 02601
        </div>
    </div>
</div>
<div style="width:100%;padding:10px;">
    <div>Indiquenos el numero de envio sobre el que realizar el seguimiento o concertacion de entrega</div>
    <div style="display:inline-flex;width:100%;margin-bottom:15px;">
        <mat-form-field style="width:40%;">
            <mat-label>Buscar Envio</mat-label>
            <input matInput type="text" [(ngModel)]="value" (keyup.enter)="buscarenvio(value)">
            <button *ngIf="value" matSuffix mat-icon-button aria-label="Clear" (click)="cancelabusqueda()">
                <mat-icon>close</mat-icon>
            </button>
        </mat-form-field>
        <button mat-icon-button matTooltip="Buscar" class="botones" (click)="buscarenvio(value)" [disabled]="!value">
            <mat-icon class="material-icons">manage_search</mat-icon>
        </button>
        <div *ngIf="albaran && habilitado" style="width:40%;margin-left:15px;">
            <mat-form-field style="width:90%;">
                <mat-label>Telefono</mat-label>
                <input matInput type="text" [(ngModel)]="telefono">
                <button mat-button *ngIf="telefono" matSuffix mat-icon-button aria-label="Clear" (click)="telefono=''">
                    <mat-icon>close</mat-icon>
                </button>
            </mat-form-field>
            <p>por favor, escriba el numero de telefono que indicó en el pedido <br>
                y que se incluye en el albaran de envio,<br>como verificación para concertar la nueva entrega
            </p> 
        </div>
    </div>     
    <div *ngIf="estadoenvio == 'entregado'" style="margin:10px;padding-left:50px;background-color: rgb(186, 245, 186);">Envio ya entregado</div>
    <div *ngIf="setadvertencia() != 'ok'" style="margin:10px;padding-left:50px;background-color: rgb(245, 186, 186);">{{setadvertencia()}}</div>
    <div *ngIf="albaran && telefono == tlfdest && estadoenvio != 'entregado'" style="width:100%;margin-bottom:20px;">
        <mat-form-field style="width:25%;text-align:center;font-size: 2vw;">
            <mat-label style="color:black;font-size: 2vw;" (click)="picker9.open()">Fecha</mat-label>
              <input style="font-size: 2vw;" matInput [matDatepickerFilter]="myFilter" [matDatepicker]="picker9" (click)="picker9.open()" [(ngModel)]="fechaconcertacion" (dateChange)="asignarfechaconcertacion()" disabled>
              <button mat-button *ngIf="fechaconcertacion" matSuffix mat-icon-button aria-label="Clear" (click)='cancelafecha()'>
              <mat-icon>close</mat-icon>
            </button>
            <mat-datepicker-toggle matPrefix [for]="picker9" style="font-size: 2vw;"></mat-datepicker-toggle>
            <mat-datepicker #picker9 style="font-size: 2vw;" disabled="false"></mat-datepicker>
            <mat-hint>Seleccionar Fecha de Entrega</mat-hint>
        </mat-form-field>
        <button mat-raised-button matTooltip="seleccionar fecha" style="width:55px;height:55px;margin-left:3%;margin-right:5%;text-align:center;cursor:pointer;" (click)="picker9.open()">
            <mat-icon>event</mat-icon>
        </button>
        <mat-form-field style="width:30%;">
            <mat-label>Horario</mat-label>
            <mat-select (selectionChange)="sethorario($event.value)">
                <mat-option *ngFor="let option of filteredOptions" [value]="option">
                    {{option}}
                </mat-option>
            </mat-select>
            <mat-hint>Solo se pueden escoger los horarios especificados</mat-hint>
        </mat-form-field>
        <button mat-raised-button style="width:55px;height:55px;margin-left:50px;text-align:center;cursor:pointer;" matTooltip="concertar entrega" color="primary" (click)="guardaconcertada()" [disabled]="!fechaconcertada || !horarioconcertacion">
        <mat-icon>event_available</mat-icon>
        </button>
        <br>
        <mat-form-field style="width:95%;">
        <mat-label>Observaciones</mat-label>
        <textarea matInput placeholder="direccion completa, telefono diferente...." [(ngModel)]="observaciones"></textarea>
        <mat-hint>indicanos tu direccion completa, autoriza a dejarlo al conserje o a un vecino</mat-hint>
        </mat-form-field>
    </div>
    <div *ngIf="mensajerespuesta == 'Entrega concertada'" style="width:100%;margin:10px;padding-left:50px;background-color: rgb(186, 245, 186);">{{datosconcertados}}</div>
    <div *ngIf="urlseguimientomrw">
        <iframe [src]="urlseguimientomrw" width=100% height=500 frameborder=1 scrolling=auto></iframe>
    </div>
</div> 
