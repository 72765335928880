import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs/Observable";
import { GLOBAL } from "./global";
import { AppService } from "./app.service";

@Injectable()
export class ConcertadaService {
  public url: string;
  public urlcliente: string;

  constructor(public _http: HttpClient, public _appservice: AppService) {
    this.url = GLOBAL.urlconcertadas;
    this.urlcliente = GLOBAL.url;
  }

  getconcertadas(tmp): Observable<any> {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("authorization", this._appservice.getToken());
    var params = JSON.stringify(tmp);
    return this._http.post(this.url + "concertadas", params, {
      headers: headers,
    });
  }

  getconcertada(albaran): Observable<any> {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("authorization", this._appservice.getToken());
    return this._http.get(this.url + "concertada/" + albaran, {
      headers: headers,
    });
  }

  guardarconcertada(concertada): Observable<any> {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("authorization", this._appservice.getToken());
    var tmp = { concertada: concertada };
    var params = JSON.stringify(tmp);
    return this._http.post(this.url + "guardarconcertada", params, {
      headers: headers,
    });
  }

  albaranG3(albaran): Observable<any> {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("authorization", this._appservice.getToken());
    let cookie = {
      galletag3: this._appservice.getgalletag3(),
      authorization: this._appservice.getgauthG3(),
    };
    let tmp = { albaran: albaran, cookie: cookie };
    var params = JSON.stringify(tmp);
    return this._http.post(this.url + "albg3", params, { headers: headers });
  }

  notaG3(nota): Observable<any> {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("authorization", this._appservice.getToken());
    let cookie = {
      galletag3: this._appservice.getgalletag3(),
      authorization: this._appservice.getgauthG3(),
    };
    let tmp = { nota: nota, cookie: cookie };
    var params = JSON.stringify(tmp);
    return this._http.post(this.url + "notag3", params, { headers: headers });
  }

  imgExp(idenvio, direxpediente): Observable<any> {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("authorization", this._appservice.getToken());
    let cookie = {
      galletag3: this._appservice.getgalletag3(),
      authorization: this._appservice.getgauthG3(),
    };
    let temp = {
      idenvio: idenvio,
      direxpediente: direxpediente,
      cookie: cookie,
    };
    let params = JSON.stringify(temp);
    return this._http.post(this.url + "imgexp", params, { headers: headers });
  }

  borrarconcertada(albaran): Observable<any> {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("authorization", this._appservice.getToken());
    return this._http.delete(this.url + "concertada/" + albaran, {
      headers: headers,
    });
  }

  getdepositados(): Observable<any> {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("authorization", this._appservice.getToken());
    let cookie = {
      galletag3: this._appservice.getgalletag3(),
      authorization: this._appservice.getgauthG3(),
    };
    let temp = { cookie: cookie };
    let params = JSON.stringify(temp);
    return this._http.post(this.url + "depositados", params, {
      headers: headers,
    });
  }

  getdepositado(albaran): Observable<any> {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("authorization", this._appservice.getToken());
    let cookie = {
      galletag3: this._appservice.getgalletag3(),
      authorization: this._appservice.getgauthG3(),
    };
    let temp = { albaran: albaran, cookie: cookie };
    let params = JSON.stringify(temp);
    return this._http.post(this.url + "depositado", params, {
      headers: headers,
    });
  }

  concertarentrega(concertada): Observable<any> {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("authorization", this._appservice.getToken());
    let cookie = {
      galletag3: this._appservice.getgalletag3(),
      authorization: this._appservice.getgauthG3(),
    };
    let temp = {
      idenvio: concertada.idenvio,
      texto: concertada.texto,
      fecha: concertada.fecha,
      telefono: concertada.telefono,
      canalconcertacion: concertada.canalconcertacion,
      email: concertada.email,
      cookie: cookie,
    };
    let params = JSON.stringify(temp);
    return this._http.post(this.url + "concertar", params, {
      headers: headers,
    });
  }

  concertarAgencia(concertada): Observable<any> {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("authorization", this._appservice.getToken());
    let cookie = {
      galletag3: this._appservice.getgalletag3(),
      authorization: this._appservice.getgauthG3(),
    };
    let temp = {
      idenvio: concertada.idenvio,
      texto: concertada.texto,
      fecha: concertada.fecha,
      cookie: cookie,
    };
    let params = JSON.stringify(temp);
    return this._http.post(this.url + "concertaragencia", params, {
      headers: headers,
    });
  }

  ubicar(concertada): Observable<any> {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("authorization", this._appservice.getToken());
    let cookie = {
      galletag3: this._appservice.getgalletag3(),
      authorization: this._appservice.getgauthG3(),
    };
    let temp = {
      IdEnvio: concertada.idenvio,
      NumeroAlbaran: concertada.NumeroAlbaran,
      ubicacion: concertada.ubicacion,
      fecha: concertada.fecha,
      hora: concertada.hora,
      cookie: cookie,
    };
    let params = JSON.stringify(temp);
    return this._http.post(this.url + "ubicar", params, { headers: headers });
  }

  recibido(concertada): Observable<any> {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("authorization", this._appservice.getToken());
    let cookie = {
      galletag3: this._appservice.getgalletag3(),
      authorization: this._appservice.getgauthG3(),
    };
    let temp = {
      IdEnvio: concertada.idenvio,
      NumeroAlbaran: concertada.NumeroAlbaran,
      ubicacion: concertada.ubicacion,
      fecha: concertada.fecha,
      hora: concertada.hora,
      cookie: cookie,
    };
    let params = JSON.stringify(temp);
    return this._http.post(this.url + "recibido", params, { headers: headers });
  }

  getcierre(fecha): Observable<any> {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("authorization", this._appservice.getToken());
    let temp = { fecha: fecha };
    let params = JSON.stringify(temp);
    return this._http.post(this.url + "cierredia", params, {
      headers: headers,
    });
  }

  getcierres(query): Observable<any> {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("authorization", this._appservice.getToken());
    let temp = { query: query };
    let params = JSON.stringify(temp);
    return this._http.post(this.url + "cierres", params, { headers: headers });
  }

  setcierre(cierre): Observable<any> {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("authorization", this._appservice.getToken());
    let params = JSON.stringify(cierre);
    return this._http.post(this.url + "setcierredia", params, {
      headers: headers,
    });
  }

  borracierre(cierre): Observable<any> {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("authorization", this._appservice.getToken());
    let params = JSON.stringify(cierre);
    return this._http.post(this.url + "eliminacierre", params, {
      headers: headers,
    });
  }

  mailconcertada(envio): Observable<any> {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("authorization", this._appservice.getToken());
    let params = JSON.stringify({ envio: envio });
    return this._http.post(this.url + "mailconcertada", params, {
      headers: headers,
    });
  }
}
