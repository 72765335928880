import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ClienteService } from '../../servicios/cliente.service';
import * as moment from 'moment';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition, MatSnackBarConfig } from '@angular/material/snack-bar';
import { FormControl } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { AppService } from '../../servicios/app.service';
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort'; 

@Component({
  selector: 'app-destinatariospeticion',
  templateUrl: './destinatariospeticion.component.html',
  styleUrls: ['./destinatariospeticion.component.css'],
  providers: [AppService]
})
export class DestinatariospeticionComponent implements OnInit {
  public identity;
  public numerosplataforma;
  public destinatario;
  public dataSource = new MatTableDataSource<any>();
  public columns = ['Email', 'Eliminar'];
  @ViewChild(MatSort, {static: false}) set content(sort: MatSort){this.dataSource.sort = sort;}
  constructor(
  	public dialogoDestinatarios: MatDialogRef<DestinatariospeticionComponent>,
    private _snackBar: MatSnackBar,
    private _appservice: AppService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { 
    this.identity = this._appservice.getIdentity();
  }

  ngOnInit(): void {
    this.getdestinatariospendientes();
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.content;
  }

  aviso(texto, subtexto) {
    this._snackBar.open(texto,subtexto,{
      duration: 4000,
      horizontalPosition: "center",
      verticalPosition: "top",
    });
  }

  getdestinatariospendientes(){
    this.dataSource.data = this.data.destinatariospendientes;
  }
  
  getdestinatariostotales(){
    this.dataSource.data = this.data.destinatariostotales;
  }
  
  getdestinatarioserrores(){
    this.dataSource.data = this.data.errores;
  }  

  cerrar(){
    this.dialogoDestinatarios.close();
  }

  guardar(){
    let destinatarios = this.dataSource.data;
    this.dialogoDestinatarios.close(destinatarios);
  }

  eliminadestinatario(destinatario){
    let destinos = this.dataSource.data;
    destinos.splice(destinos.indexOf(destinatario), 1);
    this.dataSource.data = destinos;
  }

}

