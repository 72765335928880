<div class="barraseleccion">
    <div class="botonbarra" matTooltip="Enviados" (click)="selectcategory('envios')">
        <mat-icon class="material-icons-outlined">send</mat-icon>
    </div>
    <div class="botonbarra" matTooltip="Anomalias" (click)="selectcategory('anomalias')">
        <mat-icon class="material-icons-outlined">cancel_schedule_send</mat-icon>
    </div>
    <div class="botonbarra" matTooltip="Solicitar envio APC" (click)="abreapc()">
        <mat-icon class="material-icons-outlined">local_shipping</mat-icon>
    </div>
    <div class="botonbarra" matTooltip="Solicitar envios por email/excel" (click)="selectcategory('enviosexcel')">
        <mat-icon class="material-icons-outlined">description</mat-icon>
    </div>
    <div class="botonbarra" matTooltip="Subir Fichero Masivo" (click)="selectcategory('subirmasivo')">
        <mat-icon class="material-icons-outlined">drive_folder_upload</mat-icon>
    </div>
</div>
<div *ngIf="categoria == 'anomalias'">
    <anomaliasclientes></anomaliasclientes>
</div>
<div *ngIf="categoria == 'envios'">
    <envioscliente></envioscliente>
</div>
<div *ngIf="categoria == 'enviosexcel'">
    <enviosexcel></enviosexcel>
</div>
<div *ngIf="categoria == 'subirmasivo'">
    <app-subirmasivo></app-subirmasivo>
</div>
