import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ClienteService } from '../../servicios/cliente.service';
import { Cliente } from '../../models/cliente';
import { AppService } from '../../servicios/app.service' 

@Component({
  selector: 'registro',
  templateUrl: './registro.component.html',
  styleUrls: ['./registro.component.css'],
  providers: [ClienteService, AppService]
})
export class RegistroComponent implements OnInit {

	public title:String;
	public cliente;
	public status: String;
  public administrador: boolean;
  public listarol;
  public pass;
  public identity;

  constructor(
  	private _route: ActivatedRoute,
    private _clienteService: ClienteService,
    private _appservice: AppService

  ) {
      this.identity = this._appservice.getIdentity();
      if(this.identity && this.identity.role == 'Administrador'){
        this.title = 'registro nuevo usuario/mensajero'
      }else{  
  		  this.title = 'bienvenido a la pagina de registro de la palataforma Mensasur Getafe';
      }  
  		this.cliente = new Cliente("","","","","","","","","","","","","","","","","",[""],
        {
          page:1,
          limit:50,
          sort:{fechaenvio: 'asc'},
          carpetasmail:[""],
          carpetasinteraccion:[""],
          ordencamposincidencias:["select"],
          ordencamposconcertadas:["select"],
          ordencamposfacturas:["select"],
          ordencamposreembolsos:{
            mensajeros:["select"],
            totales:["select"],
            direcciones:["select"]
          },
          g3:{
            usuario:'0260100019',
            password:'mensasur2601'
          },
          firmamail: ''
        });
    }

  ngOnInit(): void {
      this.listarol = ['cliente', 'Administrador', 'operador-admin', 'operador-incidencias', 'operador', 'operador-plus', 'Mensajero'];
      this._route.params.subscribe(params=>{
        if(params.admin && params.pass){
          if(params.pass == 'Mensasur2601' && params.admin == 'Admin'){
                this.administrador = true;
          }
        }else{
          this.administrador = false;
        }
        if(params.abonado){
          var query = {Abonado:params.abonado}
          this._clienteService.getcecos(query).subscribe(
            response=>{
              if(response.cecos.length >=1){
                var ceco = response.cecos[0];
                this.cliente.role = 'cliente';
                this.cliente.nombre = ceco.Descripcion;
              }
            },
            error=>{
              console.log(error);
            }
          )
        }
      });

  }

  comprobar(){
    if(!this.administrador){
      if(this.cliente.numero[0] != "0" || this.cliente.numero[1] != "0"){
        this.status = 'errornumero';
      }else{
        this.status = "";
      };
    }  
  }

  onSubmit(form){
    if(this.status != 'errornumero'){
        if(this.pass != this.cliente.password){
          this.status = 'passerror';
        }else{

        if(!this.cliente.role){this.cliente.role = "cliente"}
        
      	this._clienteService.registro(this.cliente).subscribe(
          response =>{
            if(response.message && response.message == 'usuario guardado con exito'){
                  this.status = 'success';
                  form.reset();
            }else{
              if(response.message == 'el cliente ya existe'){
                this.status = 'existe'
              }else{
                this.status = 'error';
              }
            }
          },
          error =>{
            console.log(<any>error);
          }
        );

        }
      }
   }
}


