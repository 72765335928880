import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { DireccionesService } from '../../servicios/direcciones.service';
import { AppService } from '../../servicios/app.service';
import { CallG3Service } from '../../servicios/g3.service';  
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition, MatSnackBarConfig } from '@angular/material/snack-bar'; 
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';

@Component({
  selector: 'app-nuevoenvio',
  templateUrl: './nuevoenvio.component.html',
  styleUrls: ['./nuevoenvio.component.css'],
  providers: [AppService, DireccionesService, CallG3Service]
})
export class NuevoenvioComponent implements OnInit {
  public destino;
  public identity;
  public remite;
  public direcciones;
  public remiteControl = new FormControl();
  public filteredremite: Observable<any[]>;
  public destinoControl = new FormControl();
  public filtereddestino: Observable<any[]>;
  public cpsremite;
  public callejeroremite = false;
  public poblacionesremitesugeridas: Observable<any[]>;
  public poblacionesporcpremite;
  public callesremitesugeridas;


  constructor(
    private _snackBar: MatSnackBar,
    private _appservice: AppService,
    private _direccionesservice: DireccionesService,
    private _callg3service: CallG3Service
  ) {
    this.identity = this._appservice.getIdentity();

    this.destino = {
      nombre: '',
      direccion: {
        calle: '',
        tipovia: '',
        numero:'',
        resto:''
      },
      poblacion: '',
      cp: '',
      provincia: '',
      telefono: '',
      contacto: '',
      observaciones: ''
    }

    this.remite = {
      nombre: '',
      direccion: {
        calle: '',
        tipovia: '',
        numero: '',
        resto:''
      },
      poblacion: '',
      cp: '',
      provincia: '',
      telefono: '',
      contacto: '',
      observaciones: ''
    }
  }

  ngOnInit(): void {
    this.getdirecciones();
  }

  vertemita(){
    console.log(this.remite);
    console.log(this.destino);
  }

  aviso(texto, subtexto) {
    this._snackBar.open(texto,subtexto,{
      duration: 4000,
      horizontalPosition: "center",
      verticalPosition: "top",
    });
  }

  filtro(name){
    const filterValue = name;
    return this.direcciones.filter(option => option.nombre.indexOf(filterValue) === 0);
  }

  remiteselected(event: MatAutocompleteSelectedEvent): void {
    this.remiteControl.setValue(event.option.value.nombre);
    this.remite = {
      nombre: this.remiteControl.value,
      direccion: {
        calle: event.option.value.direccion.calle,
        tipovia: event.option.value.direccion.tipovia,
        numero: event.option.value.direccion.numero,
        resto: event.option.value.direccion.resto
      },
      poblacion: event.option.value.poblacion,
      cp: event.option.value.cp,
      provincia: event.option.value.provincia,
      telefono: event.option.value.telefono,
      contacto: event.option.value.contacto,
      observaciones: event.option.value.observaciones
    }
  }

  destinoselected(event: MatAutocompleteSelectedEvent): void {
    this.destinoControl.setValue(event.option.value.nombre);
    this.destino = {
      nombre: this.destinoControl.value,
      direccion: {
        calle: event.option.value.direccion.calle,
        tipovia: event.option.value.direccion.tipovia,
        numero: event.option.value.direccion.numero,
        resto: event.option.value.direccion.resto
      },
      poblacion: event.option.value.poblacion,
      cp: event.option.value.cp,
      provincia: event.option.value.provincia,
      telefono: event.option.value.telefono,
      contacto: event.option.value.contacto,
      observaciones: event.option.value.observaciones
    }
  }

  guardardireccion(dir){
    this._direccionesservice.savedir(dir).subscribe(
      response=>{
        this.aviso(response['message'], 'ok');
      },
      error=>{
        console.log(error);
      }
    )
  }

  getdirecciones(){
    var query = {};
    this._direccionesservice.getdirecciones(query).subscribe(
      response=>{
        this.direcciones = response['direcciones'];
        this.filteredremite = this.remiteControl.valueChanges
            .pipe(
              startWith(''),
              map(name => this.filtro(name))  
            );
        this.filtereddestino = this.destinoControl.valueChanges
        .pipe(
          startWith(''),
          map(name => this.filtro(name))  
        );    
      },
      error=>{
        console.log(error);
      }
    )
  }

  borradireccion(dirid){
    this._direccionesservice.borradireccion(dirid).subscribe(
      response=>{
        this.aviso(response['message'], 'ok');
      },
      error=>{
        console.log(error);
      }
    )
  }

  updatedireccion(direccion){
    this._direccionesservice.updatedireccion(direccion).subscribe(
      response=>{
        this.aviso(response['message'], 'ok');
      },
      error=>{
        console.log(error);
      }
    )
  }

  getpoblacionesremiteporcp(cp){
    this._callg3service.poblacionporcp(cp).subscribe(
      response=>{
        if(response.poblaciones && response.poblaciones.length == 1){
          this.remite.poblacion = response.poblaciones[0].NombrePoblacion;
          this.remite.provincia = response.poblaciones[0].NombreProvincia;
          this.poblacionesporcpremite = undefined;
          this._callg3service.tienecallejero(this.remite.poblacion).subscribe(
            response=>{
              this.callejeroremite = response.callejero;
            },
            error=>{
              console.log(error);
            }
          )  
        }else if(response.poblaciones){
          this.poblacionesporcpremite = response.poblaciones;
        }
      },
      error=>{
        console.log(error);
      }
    )
  }

  seleccionadapoblacionremite(event){
    this.poblacionesporcpremite = undefined;
    this.remite.provincia = event.value.NombreProvincia;
    this.remite.poblacion = event.value.NombrePoblacion;
    this._callg3service.tienecallejero(event.value.NombrePoblacion).subscribe(
      response=>{
        this.callejeroremite = response.callejero;
      },
      error=>{
        console.log(error);
      }
    )  
    console.log(this.callejeroremite);
  }

  remdirsugselected(event){
    this.remite.poblacion = event.option.value.NombrePoblacion;
    this.remite.provincia = event.option.value.NombreProvincia;
    this._callg3service.cppoblacion(event.option.value.NombrePoblacion, event.option.value.NombreProvincia).subscribe(
      response=>{
        this.cpsremite = response.cps;
        console.log(this.remite);  
        if(this.cpsremite.length == 1){
          this.remite.cp = this.cpsremite[0];
        }
        this._callg3service.tienecallejero(event.option.value.NombrePoblacion).subscribe(
          response=>{
            this.callejeroremite = response.callejero;
          },
          error=>{
            console.log(error);
          }
        )  
      },
      error=>{
        console.log(error);
      }
    )
    
  }

  sugerirpoblacionremite(event){
    this._callg3service.poblacionsugerida(event.originalTarget.value).subscribe(
      response=>{
         this.poblacionesremitesugeridas = response.poblaciones;
      },
      error=>{
        console.log(error);
      }
    );
  }

  getcallesremitesugeridas(calle){
    this._callg3service.callesugerida(this.remite.poblacion, calle).subscribe(
      response=>{
        this.callesremitesugeridas = response.calles;
      },
      error=>{
        console.log(error);
      }
    );
  }

  remcallesugselected(event){
    this.remite.direccion.calle = event.option.value.NombreCalle;
    this.remite.direccion.tipovia = event.option.value.CodigoVia;
  }

  limpiarpoblacionremite(){
    this.remite.poblacion = '';
    this.remite.cp = '';
    this.remite.provincia = '';
    this.poblacionesremitesugeridas = undefined;
    this.callejeroremite = false;
    this.cpsremite = undefined;
  }

}

