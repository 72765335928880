import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { ClienteService } from '../../servicios/cliente.service';

@Component({
  selector: 'app-archivosclientes',
  templateUrl: './archivosclientes.component.html',
  styleUrls: ['./archivosclientes.component.css'],
  providers: [ClienteService]
})
export class ArchivosclientesComponent implements OnInit {

	public title: String;
	public clientes;
	public role: String;
	public identity;


  constructor(
   	private _router: Router,
    private _clienteService: ClienteService
  ) {
  	this.identity = JSON.parse(sessionStorage.getItem('identity'));
    this.title = "Clientes que han subido nuevos archivos";
   }

  ngOnInit(): void {
  	this._clienteService.getclientesnuevoarchivo(this.identity.role, "cliente").subscribe(
  		response =>{
			if(response){
				this.clientes = response.clientes;
			}
		},
  		error =>{
			console.log(<any>error);
  		}	
  	);

  }

  archivoscliente(cliente){
    var clienteId = cliente._id;
    this._router.navigate(['archivos/' + clienteId]);
  }

}