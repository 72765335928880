import { OnInit, Component, ViewChild, AfterViewInit, HostListener } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { ClienteService } from '../../servicios/cliente.service';
import { IncidenciasService } from '../../servicios/incidencias.service';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import * as moment from 'moment';
import { AlbaranComponent } from '../albaran/albaran.component';
import { Incidencia } from '../../models/incidencia';
import { MatTableDataSource } from '@angular/material/table';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition, MatSnackBarConfig } from '@angular/material/snack-bar';
import { AppService } from '../../servicios/app.service';
import { FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { saveAs } from "file-saver";
import { ChatService } from 'src/app/servicios/chat.service';

@Component({
  selector: 'app-incidencias',
  templateUrl: './incidencias.component.html',
  styleUrls: ['./incidencias.component.css'],
  providers: [ClienteService, IncidenciasService, AppService, ChatService],
  
})

export class IncidenciasComponent implements OnInit, AfterViewInit{
  public incidencias;
  public seleccion;
  public seleccionexp;
  public queryactual;
  public category;
  public perfil;
  public listaperfiles;
  public avisoatrasadassingestionar: number;
  public fechahoy;
  public avisorecogidas;
  public gestionadosnoincluir;
  public gestionadosnoincluirreexp;
  public selecionra;
  public carpetasmail;
  public avisonuevas;
  public albarananterior;
  public avisodevreexp;
  public columns;
  public dataSource;
  public selection;
  public totalcolumns;
  public campos;
  public camposeleccionados;
  public filtros;
  public incidenciasSeleccionadas;
  public listasfiltros;
  public listaexpediente;
  public columnasexpediente;
  public queryoptions;
  public identity;
  public errorexpediente;
  public motivosincluir = new FormControl();
  public load;
  public comparafecha;
  public albaranabierto = null;
  public pendienteswilson = 0;
  public notificacioneswilson = 0;

  @HostListener('window:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if(event.key == 'ArrowDown' && this.seleccion && this.dataSource.paginator.pageSize > this.seleccion.position && this.albaranabierto == null){
        event.preventDefault();
        this.seleccionar(this.dataSource.data[this.seleccion.position+1]);
    }else if(event.key == 'ArrowUp' && this.seleccion && this.seleccion.position > 1 && this.albaranabierto == null){
        event.preventDefault();
        this.seleccionar(this.dataSource.data[this.seleccion.position-1]);
    }
  }
  @ViewChild(MatSort, {static: false}) set content(sort: MatSort){this.dataSource.sort = sort;}
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;

  constructor(
    private _clienteService: ClienteService,
    private _incidenciasservice: IncidenciasService,
    private dateAdapter: DateAdapter<Date>,
    public ventanaAlbaran: MatDialog,
    private _snackBar: MatSnackBar,
    private _chatService: ChatService,
    private _appservice: AppService
  	) {
      this.identity = this._appservice.getIdentity();
      this.totalcolumns = ['select','NumAlbaran','Tipo','Anomalia','gestionado','AnomaliaMotivo','AnomaliaObservaciones','CodigoAbonado','CodigoFranquicia','ContactoEntrega','ContactoRecogida','CpDestinatario','CpRemitente','Destinatario','DireccionDestinatario','DireccionRemitente','Estado','Fase','FechaEntrega','FechaEnvio','EntregarAPartirDe','FechaRecogida','FechaFinalizacionRecogida','FechaConcertacion','HoraMaximaEntrega','HoraMaximaRecogida','IdFiscal','AR','ImporteAR','Kilos','KmDestinatario','KmRemitente','Motivo','NombreCliente','NombreSolicitante','ObservacionesEntrega','ObservacionesRecogida','PoblacionDestinatario','PoblacionRemitente','ProvinciaDestinatario','ProvinciaRemitente','RangoHorarioEntregaManana','RangoHorarioEntregaTarde','RangoHorarioRecogidaManana','RangoHorarioRecogidaTarde','Remitente','Servicio','TelefonoDestinatario','TelefonoRemitente','TipoComision','TipoEntrega','TipoGeografico','TipoMercancia','TipoRecogida','TotalBultos','TipoCobro','FranquiciaDestino','FranquiciaOrigen','FranquiciaTercera','FranquiciaClienteFacturacion','CodigoCupon','NumeroAlbaranPrecursor','NumeroEnvioRetorno','TipoGestion','TieneRetorno','ConfirmacionInmediata','EntregaSabado','TipoRetorno','Usuario','UsuarioModificacion','ReferenciaCliente','EsInternacional','GeneradoPor','Aviso','TotalMedidas','Operacion','Frecuencia','Contador','NifRemitente','NifDestinatario','MensajeroEntrega','MensajeroRecogida','PuntoRecogida','PuntoEntrega','PuntoAnomalia','PuntoEstado','PuntoConcertacion','TipoPuntoConcertacion','albaranrd','notas'];
      var totalcolincidencias = this.identity.options.ordencamposincidencias.length-1;
      for(let i = 0;i< totalcolincidencias;i++){
        let col = this.identity.options.ordencamposincidencias[i];
        if(!this.totalcolumns.includes(col)) this.identity.options.ordencamposincidencias.splice(this.identity.options.ordencamposincidencias.indexOf(col), 1);
        if(i >= totalcolincidencias){
          sessionStorage.setItem('identity', JSON.stringify(this.identity));
          this._clienteService.saveCliente(this.identity);
        }
      }
      this.load = true;
      this.queryoptions = {
        paginacion : 'no'
      }
      this.listasfiltros = {
        listamotivo:[
          {Motivo:'OT0700', valor: 'Clasificado en plataforma'},
          {Motivo:'OE0062', valor: 'Depositado en Franquicia de destino'},
          {Motivo:'OE0600', valor: 'Mensajero de entrega asignado'},
          {Motivo:'OR0600', valor: 'Mensajero de recogida asignado'},
          {Motivo:'OE0500', valor: 'Recibido en franquicia de destino'},
          {Motivo:'OR0200', valor: 'Recogida finalizada'},
          {Motivo:'OS0200', valor: 'Recogida pendiente'},
          {Motivo:'OE0100', valor: 'Tránsito interno de entrega'},
          {Motivo:'OR0402', valor: 'Envio borrado'},
          {Motivo:'OR0201', valor: 'Recogida finalizada en franquicia'},
          {Motivo:'OE0702', valor: 'Reexpedir Envio'},
          {Motivo:'OE0401', valor: 'Devolver al remitente'},
          {Motivo:'OE1103', valor: 'Cliente Ordena destruir el envio'}
          ],
        listagestionados:['','Pendiente Cliente','Pendiente Agencia','Para Entregar','Recoge en agencia','Entregado','Cancelado','No Recibido','Devuelto/Reexpedido','Especial/vigilar'],
        listaestado:['EntregaPendiente','EnvioCancelado','RecogidaFinalizada','RecogidaPendiente','TransitoInternoEntrega','EntregaCancelada','EntregaFinalizada']
     }
     this.filtros = {
      filtroprincipal: ''
      };
     this.motivosincluir.setValue(this.listasfiltros.listamotivo); 
     this.columnasexpediente = ['Estado','FechaEntrada','HoraEntrada','Descripcion','Detalle','Usuario'];
     this.selection = new SelectionModel<Incidencia>(true, []);
   	 this.dateAdapter.setLocale('es');
     this.dateAdapter.getFirstDayOfWeek = () => { return 1; };
     this.avisoatrasadassingestionar = 0;
     this.avisonuevas = 0;
     this.avisorecogidas = 0;
     this.avisodevreexp = 0;
     this.dataSource = new MatTableDataSource<Incidencia>();
     this.fechahoy = moment().set({hour:0,minute:0,second:0,millisecond:0});
     this.comparafecha = moment(this.fechahoy).toISOString();
     this.gestionadosnoincluir = ['Entregado','Cancelado','Devuelto/Reexpedido','No Recibido'];
     this.gestionadosnoincluirreexp = ['Entregado','Cancelado','No Recibido'];
     this.getperfil(this.identity.perfil);
   }

  ngOnInit(): void {
    this.getperfiles();
    this._chatService.emitEvent('incidencias', this.identity);
    this._chatService.iact.subscribe(
      res=>{
        console.log(res);
      }
    )
    let alb = this._incidenciasservice.getultimoalbaran();
    if(alb) this.albarananterior = alb;
    setTimeout(()=>{
      this.avisoslong();
    }, 5000);
  }

  avisoslong(){
    this.getavisos();
    setTimeout(()=>{
      this.avisoslong();
    }, 300000);
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.content;
  }

  aviso(texto, subtexto) {
    this._snackBar.open(texto,subtexto,{
      duration: 4000,
      horizontalPosition: "center",
      verticalPosition: "top",
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  isAllSelected() {
    if(this.dataSource.filteredData){
      const numSelected = this.selection.selected.length;
      const numRows = this.dataSource.filteredData.length;
      return numSelected === numRows;
    }else{
      const numSelected = this.selection.selected.length;
      const numRows = this.dataSource.data.length;
      return numSelected === numRows;
    }  
  }

 // Selects all rows if they are not all selected; otherwise clear selection.
  masterToggle() {
    if(this.dataSource.filteredData){
        this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.filteredData.forEach(row => this.selection.select(row));
    }else{  
      this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.data.forEach(row => this.selection.select(row));
    }    
  }

  // The label for the checkbox on the passed row 
  checkboxLabel(row?: Incidencia): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.identity.options['ordencamposincidencias'], event.previousIndex, event.currentIndex);
    this.actualizacliente();
  }

  actualizacliente(){
    sessionStorage.setItem('identity', JSON.stringify(this.identity));
    this._clienteService.saveCliente(this.identity);
  }

  getperfiles(){
    this._clienteService.getperfiles().subscribe(
      response=>{
         this.listaperfiles = new Array();
            response.perfiles.forEach(perfil=>{
              this.listaperfiles.push(perfil.nombre);
            });
        },
      error=>{
        console.log(<any>error);
      }  

    );
  }

  getperfil(nombre){
    this._clienteService.getperfil(nombre).subscribe(
      response=>{
         this.perfil = response.perfil;
         this.getincidencias();
      },
      error=>{
        console.log(<any>error);
      }
    );
  }

  onselectperfil(item){
    this.identity.perfil = item;
    this.getperfil(item);
    this.actualizacliente();
  }

//revisar si localstorage o no
  onselectfiltroestado(value){
      this.identity.incidenciasdefinitivas = value;
      this.queryactual.Estado = {$in:this.identity.incidenciasdefinitivas};
      this.actualizacliente();
      this.incidenciasactuales();
  }

//revisar si localstorage o no
  onselectcolumnas(value){
    this.identity.options.ordencamposincidencias = value;
    this.actualizacliente();
  }
  //revisar uno generico y extras para cada caso especial
  onselectfiltro(filtro, valor){
     if(valor == ''){
      this.filtros[filtro] = undefined;
      if(filtro == 'gestionado') delete this.queryactual.gestionado;
      this.eliminafiltro(filtro);
    }else{
      if(filtro == 'gestionado') delete this.queryactual.Estado;
      if(filtro == 'Motivo'){
        this.queryactual['Motivo'] = {$in:[valor.Motivo, valor.valor]};
      }else{
        this.queryactual[filtro] = valor;
      }  
      this.incidenciasactuales();
    }  
  }
  onselectmotivo(valores){
    let lista = valores.map(valor=>{
      return valor.Motivo;
    }); 
    this.queryactual['Motivo'] = {$in:lista};
    this.incidenciasactuales();
 }


  eliminafiltro(filtro){
    delete this.queryactual[filtro];
    if(filtro == 'CodigoAbonado') this.queryactual.CodigoAbonado = {$nin:this.perfil.abonadosnoincluir}
    this.incidenciasactuales();
  }

//unificar filtros de fechas
  onDateFechaenvio(){
   var fechainicial = moment(this.filtros['FechaEnvioinicial']).set({hour:0,minute:0,second:0,millisecond:0});
   if(!this.filtros.FechaEnviofinal){
    var fechafinal = moment(this.filtros['FechaEnvioinicial']).set({hour:23,minute:0,second:0,millisecond:0});
   }else{
     var fechafinal = moment(this.filtros['FechaEnviofinal']).set({hour:23,minute:0,second:0,millisecond:0});
   }
   this.queryactual = {$and:[{FechaEnvio:{$gte:fechainicial}},{FechaEnvio:{$lte:fechafinal}}], CodigoAbonado:{$nin:this.perfil.abonadosnoincluir}, Estado:{$in:this.identity.incidenciasdefinitivas}, FranquiciaClienteFacturacion: '02601', FranquiciaDestino: {$ne: '02601'}};
   this.incidenciasactuales();
  }

  onDateFecharecogida(){
    var fechainicial = moment(this.filtros['FechaRecogidainicial']).set({hour:0,minute:0,second:0,millisecond:0});
   if(!this.filtros.FechaRecogidafinal){
    var fechafinal = moment(this.filtros['FechaRecogidainicial']).set({hour:23,minute:0,second:0,millisecond:0});
   }else{
    var fechafinal = moment(this.filtros['FechaRecogidafinal']).set({hour:23,minute:0,second:0,millisecond:0});
   }
   this.queryactual = {$and:[{FechaRecogida:{$gte:fechainicial}},{FechaRecogida:{$lte:fechafinal}}], CodigoAbonado:{$nin:this.perfil.abonadosnoincluir}, Estado:{$in:this.identity.incidenciasdefinitivas}, FranquiciaClienteFacturacion: '02601', FranquiciaDestino: {$ne: '02601'}};
   this.incidenciasactuales();
  }

  onDateFecharecogidafinalizada(){
    var fechainicial = moment(this.filtros['FechaRecogidafinalizadainicial']).set({hour:0,minute:0,second:0,millisecond:0});
   if(!this.filtros.FechaRecogidafinalizadafinal){
    var fechafinal = moment(this.filtros['FechaRecogidafinalizadainicial']).set({hour:23,minute:0,second:0,millisecond:0});
   }else{
    var fechafinal = moment(this.filtros['FechaRecogidafinalizadafinal']).set({hour:23,minute:0,second:0,millisecond:0});
   }
   this.queryactual = {$and:[{FechaFinalizacionRecogida:{$gte:fechainicial}},{FechaFinalizacionRecogida:{$lte:fechafinal}}], CodigoAbonado:{$nin:this.perfil.abonadosnoincluir}, Estado:{$in:this.identity.incidenciasdefinitivas}, FranquiciaClienteFacturacion: '02601', FranquiciaDestino: {$ne: '02601'}};
   this.incidenciasactuales();
  }

  onDateFechaentrega(){
    var fechainicial = moment(this.filtros['FechaEntregainicial']).set({hour:0,minute:0,second:0,millisecond:0});
   if(!this.filtros.FechaEntregafinal){
    var fechafinal = moment(this.filtros['FechaEntregainicial']).set({hour:23,minute:0,second:0,millisecond:0});
   }else{
    var fechafinal = moment(this.filtros['FechaEntregafinal']).set({hour:23,minute:0,second:0,millisecond:0});
   }
    this.queryactual = {$and:[{FechaEntrega:{$gte:fechainicial}},{FechaEntrega:{$lte:fechafinal}}], CodigoAbonado:{$nin:this.perfil.abonadosnoincluir}, FranquiciaClienteFacturacion: '02601', FranquiciaDestino: {$ne: '02601'}};
    this.incidenciasactuales();
  }

  onDateFechaConcertacion(){
     var fechainicial = moment(this.filtros['FechaConcertacioninicial']).set({hour:0,minute:0,second:0,millisecond:0});
   if(!this.filtros.FechaConcertacionfinal){
    var fechafinal = moment(this.filtros['FechaConcertacioninicial']).set({hour:23,minute:0,second:0,millisecond:0});
   }else{
    var fechafinal = moment(this.filtros['FechaConcertacionfinal']).set({hour:23,minute:0,second:0,millisecond:0});
   }
    this.queryactual = {$and:[{FechaConcertacion:{$gte:fechainicial}},{FechaConcertacion:{$lte:fechafinal}}], CodigoAbonado:{$nin:this.perfil.abonadosnoincluir}, FranquiciaClienteFacturacion: '02601', FranquiciaDestino: {$ne: '02601'}};
    this.incidenciasactuales();
  }

  onselectabonado(abonado){
    var ab = abonado.trim();
    for(var i = ab.length; i < 6;i++){
      ab = '0' + ab;
    }
    let fecha = moment().set({hour:0,minute:0,second:0,millisecond:0}).add(-45, 'days');
    var query = {
      Estado:{$ne:'EntregaFinalizada'},
      FechaRecogida: {$gt:fecha},
      CodigoAbonado:ab,
      FranquiciaClienteFacturacion: '02601'
    }
    this.queryactual = query;
    this.queryoptions['paginacion'] = 'no';
    this.incidenciasactuales();
  }

  eliminafiltroabonado(){
    this.getincidencias();
  }

  
  reiniciar(){
    this.queryoptions['paginacion'] = 'no';
    this.limpiarfiltros();
  }

  humanizemotivo(motivo){
    var motivohumanizado:string;
    switch(motivo){
      case 'OT0700':
        motivohumanizado = 'Clasificado en plataforma';
        break;
      case 'OE0062': 
        motivohumanizado = 'Depositado en Franquicia de destino';
        break;
      case 'OE0600':
        motivohumanizado = 'Mensajero de entrega asignado';
        break;  
      case 'OR0600':
        motivohumanizado = 'Mensajero de recogida asignado';
        break; 
      case 'OE0500':
        motivohumanizado = 'Recibido en franquicia de destino';
        break;
      case 'OR0200':
        motivohumanizado = 'Recogida finalizada';
        break;
      case 'OS0200':
        motivohumanizado = 'Recogida pendiente';
        break;
      case 'OE0100':
        motivohumanizado = 'Tránsito interno de entrega';
        break;
      case 'OR0402':
        motivohumanizado = 'Envio borrado';
        break;
      case 'OR0201':
        motivohumanizado = 'Recogida finalizada en franquicia';
        break;
      case 'OE0702':
        motivohumanizado = 'Reexpedir Envio';
        break;
      case 'OE0401':
        motivohumanizado = 'Devolver al remitente';
        break;
      case 'OE1103':
        motivohumanizado = 'Cliente Ordena destruir el envio';
        break;          
      default:
        motivohumanizado = motivo;    
    }
    return motivohumanizado;
  }

  buscarAlbaran(){
    this.load = false;
    var albaran = this.filtros['NumAlbaran'].trim();
    this._incidenciasservice.getincidencia(albaran).subscribe(
        response =>{
            if(response.incidencia){
              this.incidencias = new Array();
              this.incidencias.push(response.incidencia);
              this.load = true;
            }else{
              this._incidenciasservice.albaranG3(albaran).subscribe(
                response =>{
                  if(response.datos){
                    this.incidencias = new Array();
                    this.incidencias.push(response.datos);
                    this.dataSource.data = [];
                    this.dataSource.data = this.incidencias.map((inc, index)=>{
                      if(inc.CodigoAbonado)inc.CodigoAbonado = inc.CodigoAbonado.substring(-1, 6);
                      inc['position'] = index;
                      return inc;
                    });
                    this.load = true;
                  }else{
                      this.aviso('No se ha encontrado albaran en G3', 'error');
                      this.load = true;
                  }
                },
                error=>{
                  console.log(<any>error);
                  this.load = true;
                });
            }          
        },
        error=>{
          console.log(<any>error);
          this.load = true;
        });        
  }

  bucaryabrir(alb){
    var albaran = alb.trim();
    this._incidenciasservice.getincidencia(albaran).subscribe(
        response =>{
          if(response.incidencia){
            this.abrealbaran(response.incidencia);
          }else{
              this._incidenciasservice.albaranG3(albaran).subscribe(
                  response =>{
                    if(response.datos){
                      var inc = response.datos;
                        if(inc.CodigoAbonado)inc.CodigoAbonado = inc.CodigoAbonado.substring(-1, 6);
                        this.abrealbaran(inc);
                      }else{
                        this.aviso('No se ha encontrado albaran en G3', 'error');
                      } 
                  },
                  error=>{
                    console.log(<any>error);
                  });
          }              
        },
        error=>{
          console.log(<any>error);
        });  
  }        

  limpiarfiltros(){
    this.dataSource.filter = '';
    this.filtros = {filtroprincipal: ''};
    this.selection.clear();
    this.seleccion = undefined;
  }  

  incidenciasactuales(){
    this.load = false;
     this._incidenciasservice.getincidencias(this.queryactual, this.queryoptions).subscribe(
      response =>{
          if(response.incidencias && response.incidencias.length >= 1){
            var contador = 0;
            this.dataSource.data = response.incidencias.map((inc, index)=>{
              inc['position']=index;
              if(contador >= index){
                this.load = true;
              }else{
                contador++
              }
              return inc
            });
            this.dataSource.paginator.firstPage();
            //this.paginator = response.paginator;
          }else{
            this.dataSource.data = response.incidencias;
            //this.paginator = response.paginator;
            this.dataSource.paginator.firstPage();
            this.load = true;
          }
        },
      error =>{
        console.log(<any>error);
      } 
    );
  }
  
  getincidencias(){
    this.load = false;
    this.category = 'totales';
    this.limpiarfiltros();
    var query = {
       gestionado:{$nin:this.gestionadosnoincluir},
       Estado:{$in:this.identity.incidenciasdefinitivas},
       CodigoAbonado:{$nin:this.perfil.abonadosnoincluir},
       FranquiciaClienteFacturacion: '02601', 
       $or:[{FechaRecogida: {$lt: this.fechahoy}},{Frecuencia: {$ne: 'N'}}]
    };   
    this.queryactual = query;
    this.queryoptions['paginacion'] = 'no'; 
      this._incidenciasservice.getincidencias(query, this.queryoptions).subscribe(
        response =>{
        if(response.incidencias){
          var contador = 0;
​​          this.incidencias = response.incidencias.map((inc, index)=>{
            inc['position']=index;
            if(contador >= index){
              this.load = true;
            }else{
              contador++
            }
            return inc
          });
          //this.paginator = response.paginator;
          this.dataSource.data = this.incidencias;
          this.dataSource.paginator.firstPage();
          this.load = true;
        }
      },
      error =>{
        console.log(<any>error);
      } 
    );
  }

  albaranenquery(albaran){
      this.queryactual['NumAlbaran'] = albaran.trim().toUpperCase();
      this.queryoptions['paginacion'] = 'no';
      this._incidenciasservice.getincidencias(this.queryactual, this.queryoptions).subscribe(
        response =>{
        if(response.paginator.totalDocs >=1){
​​          this.incidencias = response.incidencias.map((inc, index)=>{inc['position']=index;return inc});
          //this.paginator = response.paginator;
          this.dataSource.data = this.incidencias;
          this.seleccionar(this.dataSource.data[0]);
        }else{
          this.aviso(`no se encuentra albaran: ` + albaran.trim().toUpperCase() + `en los filtros actuales`, 'error');
        }
      },
      error =>{
        console.log(<any>error);
      } 
    );
  }
 

  seleccionar(row){
    this.seleccion = row;
    this.selecionra = this.tipora(row.AR);
    this.getexpediente();
  }

  tipora(tipo){
    if(tipo == 1) return '(R)';
    if(tipo == 0) return '(A)';
  }
 

  clickkment(){
    this.limpiarfiltros();
    delete this.queryactual['KmDestinatario'];
    this.queryactual.KmDestinatario = {$gt: 15};
    this.incidenciasactuales();
  }

  clickkmrec(){
    this.limpiarfiltros();
    delete this.queryactual['KmRemitente'];
    this.queryactual.KmRemitente = {$gt: 15};
    this.incidenciasactuales();
  }

  singestionar(){
    this.reiniciar();
    this.queryactual = {};
    var query = {
      gestionado:{$exists:false},
      Estado:{$in:this.identity.incidenciasdefinitivas},
      CodigoAbonado:{$nin:this.perfil.abonadosnoincluir},
      FranquiciaClienteFacturacion: '02601',
      $or:[{FechaRecogida: {$lt: this.fechahoy}},{Frecuencia: {$ne: 'N'}}]
    };   
    this.queryactual = query;
    this.incidenciasactuales();
  }

  internacionales(){
    this.reiniciar();
    this.queryactual = {};
    var query = {FechaEnvio:{$lt:this.fechahoy},Estado:{$in:this.identity.incidenciasdefinitivas},EsInternacional:true, CodigoAbonado:{$nin:this.perfil.abonadosnoincluir}, FranquiciaClienteFacturacion: '02601'};
    this.queryactual = query;
    this.incidenciasactuales();
  }

  devueltosreexp(){
    this.reiniciar();
    this.queryactual = {};
    var query = {$or:[{gestionado:'Devuelto/Reexpedido'},{Estado:'EntregaCancelada'}],gestionado:{$nin:['Entregado','Cancelado']},CodigoAbonado:{$nin:this.perfil.abonadosnoincluir},FranquiciaClienteFacturacion: '02601'};
    this.queryactual = query;
    this.incidenciasactuales();
  }

  cancelados(){
    this.reiniciar();
    this.queryactual = {};
    var fechafinal = moment().add(-35, 'days').toISOString();
    var query = {$or:[{gestionado:'Cancelado'},{Estado:'EnvioCancelado'}],FechaRecogida:{$gte:fechafinal},CodigoAbonado:{$nin:this.perfil.abonadosnoincluir},FranquiciaClienteFacturacion: '02601'};
    this.queryactual = query;
    this.incidenciasactuales();
  }

  gestionado(dato){
      this.reiniciar();
      this.queryactual = {};
      var query = {
        Estado:{$in:this.identity.incidenciasdefinitivas},
        CodigoAbonado:{$nin:this.perfil.abonadosnoincluir},
        FranquiciaClienteFacturacion: '02601',
        gestionado: dato
      };
      this.queryactual = query;
      this.incidenciasactuales();
  }

  sinrecibir(){
    this.reiniciar();
    this.queryactual = {};
    var query = {$or:[{gestionado:'No Recibido'}, {Tipo:'INotificarNoRecibido'}],gestionado:{$nin:['Entregado','Cancelado']},Estado:{$in:this.identity.incidenciasdefinitivas}, CodigoAbonado:{$nin:this.perfil.abonadosnoincluir}, FranquiciaClienteFacturacion: '02601'};
    this.queryactual = query;
    this.incidenciasactuales();
  }

  recogidas(){
    this.reiniciar();
    var query = {
      Estado:{$nin:['EntregaFinalizada', 'EntregaPendiente']},
      gestionado:{$exists:false},
      CodigoAbonado:{$nin:this.perfil.abonadosnoincluir},
      FranquiciaOrigen:{$ne:'02601'},
      FranquiciaClienteFacturacion: '02601'
    };
    this.queryactual = query;
    this.incidenciasactuales();
  }

  ubicar(ubicacion){
    this.selection.selected.forEach(incidencia =>{
      incidencia.gestionado = ubicacion;
      this._incidenciasservice.guardarincidencia(incidencia).subscribe(
        response =>{
            if(response){
             this.selection.clear();
             this.incidenciasactuales();
            }
          },
         error =>{
          console.log(<any>error);
         } 
      );
    });
  }

  categoriaseleccionada(categoria){
    this.category = categoria;
    this.selection.clear();
    this.seleccion = undefined;
    setTimeout(()=>{
      this.getavisos();
    }, 5000);
  }

  abrealbaran(incidencia){
      incidencia['nueva'] = false;
      this._incidenciasservice.guardarincidencia(incidencia).subscribe(response =>{});
      if(this.category == 'inuevas'){
        this.incidenciasnuevas();
      }
      this.albaranabierto = true;
      var albaranopen = this.ventanaAlbaran.open(AlbaranComponent, {
        width: '85%',
        height: '85%',
        data: incidencia
      });
      albaranopen.afterClosed().subscribe(result =>{
        if(result){
          this.albarananterior = result;
          this._incidenciasservice.setultimoalbaran(result);
          this.seleccionar(result);
          var indexrow = this.dataSource.data.findIndex(fila=>{
            fila.NumAlbaran == result.NumAlbaran;
          });
          this.dataSource.data[indexrow] = result;
        }
        this.albaranabierto = null;  
      });
   }

  getavisos(){
    this.atrasadasSingestionar();
    this.avisorec();
    this.avisodevueltosreexp();
    this.getnuevas();
    this.getnotifwilson();
  }  

   //You can use limit=0 to get only metadata:
   atrasadasSingestionar(){
    var query = {$or:[{FechaRecogida: {$lt: this.fechahoy}}, {Frecuencia: {$ne: 'N'}}], Estado:{$in:this.identity.incidenciasdefinitivas}, gestionado: {$exists: false}, CodigoAbonado:{$nin:this.perfil.abonadosnoincluir}, FranquiciaClienteFacturacion: '02601'};
    this._incidenciasservice.getincidencias(query, this.queryoptions).subscribe(
      response =>{
          this.avisoatrasadassingestionar = response.incidencias.length;
        },
      error =>{
        console.log(<any>error);
      });    
  }

  getnuevas(){
    var querynuevas = {CodigoAbonado:{$nin:this.perfil.abonadosnoincluir}, Estado:{$ne:'EntregaFinalizada'}, FranquiciaClienteFacturacion: '02601', nueva:true};
    this._incidenciasservice.getincidencias(querynuevas, this.queryoptions).subscribe(
      response =>{
          this.avisonuevas = response.incidencias.length;
        },
      error =>{
        console.log(<any>error);
      } 
    );
  }  

  avisorec(){
    var query = {$or:[{FechaRecogida: {$lt: this.fechahoy}}, {Frecuencia: {$ne: 'N'}}], Estado:{$nin:['EntregaFinalizada', 'EntregaPendiente']}, gestionado: {$exists: false}, CodigoAbonado:{$nin:this.perfil.abonadosnoincluir}, FranquiciaOrigen:{$ne:'02601'}, FranquiciaClienteFacturacion: '02601'};
    this._incidenciasservice.getincidencias(query, this.queryoptions).subscribe(
      response =>{
            this.avisorecogidas = response.incidencias.length;
        },
      error =>{
        console.log(<any>error);
      });
      this.avisodevueltosreexp();    
  }

  avisodevueltosreexp(){
    var query = {$or:[{FechaRecogida: {$lt: this.fechahoy}}, {Frecuencia: {$ne: 'N'}}], Estado:'EntregaCancelada', CodigoAbonado:{$nin:this.perfil.abonadosnoincluir}, FranquiciaClienteFacturacion: '02601', gestionado: {$exists: false}};
    this._incidenciasservice.getincidencias(query, this.queryoptions).subscribe(
      response =>{
         this.avisodevreexp = response.incidencias.length;
        },
      error =>{
        console.log(<any>error);
      });    
  }

  incidenciasnuevas(){
   this.load = false;
   var query = {CodigoAbonado:{$nin:this.perfil.abonadosnoincluir}, Estado:{$ne:'EntregaFinalizada'}, FranquiciaClienteFacturacion: '02601', nueva:true};
   this.queryactual = query;
   this._incidenciasservice.getincidencias(query, this.queryoptions).subscribe(
      response =>{
          if(response.incidencias && response.incidencias.length >= 1){
            var contador = 0;
            this.dataSource.data = response.incidencias.map((inc, index)=>{
              inc['position']=index;
              if(contador >= index){
                this.load = true;
                this.avisonuevas = response.incidencias.length;
              }else{
                contador++
              }
              return inc
            });
            //this.paginator = response.paginator;
            this.dataSource.paginator.firstPage();
            this.load = true;
          }else{
            this.dataSource.data = [];
            this.dataSource.paginator.firstPage();
            this.load = true;
          }
        },
      error =>{
        console.log(<any>error);
      } 
    );
  }

  getexpediente(){
    this._incidenciasservice.getexpediente(this.seleccion).subscribe(
      response=>{
          if(response.datos){
            this.errorexpediente = undefined;
            this.listaexpediente = response.datos.reverse();
          }else if(response.message){
            this.errorexpediente = response.message;
            this.listaexpediente = undefined;
          }
        });
  }

  detalleexpediente(detalle){
    this.seleccionexp = detalle.Id;
   switch(detalle.Tipo){
    case 'Alta':
      var tipo = 'Alta';
      break;
    case 'Operacion':
      var tipo = 'Operacion';
      break;
    case 'Lectura':
      var tipo = 'Lectura';
      break;
    case 'Anomalía':
      var tipo = 'Anomalía';
      break;
    case 'Nota notificada':
      var tipo = 'Notificada';
      break;
    case 'Nota pública':
      var tipo = 'Nota';
      break;  
    default:
      var tipo = 'Alta';
      break;  
   }
   var url = 'https://www2-vpc.mrw.es/gestion3/Operativo/Envios/DetalleExpediente?Arguments=id:' + detalle.Id + '_numcolumna:0_fechaexp:' + detalle.FechaEntrada + '_esNivelBulto:false_numbultocurrent:' + detalle.Bulto + '_processOrder:' + detalle.ProcessOrder + '_tipo:' + tipo + '_numAlbaran:' + detalle.NumeroEnvio + '_idEnvio:' + this.seleccion.Id;
   window.open(url);
  }

  getnotifwilson(){
    this._clienteService.getnotificacioneswilson().subscribe(
      res=>{
        this.pendienteswilson = res.notif.pendientes;
        this.notificacioneswilson = res.notif.notificaciones;
      }, 
      error=>{
        console.log(error);
      }
    )
  }

  abrewilson(){
    window.open('https://wilsonapp.tech/frontend/dashboard/blank');
  }

  descargaExcel(){
    var fechas = ['FechaEntrega','FechaEnvio','FechaRecogida','FechaFinalizacionRecogida','FechaConcertacion']
    var datos = this.dataSource.data.map((dato)=>{
      var linea = {};
      this.identity.options.ordencamposincidencias.forEach((campo)=>{
        if(fechas.includes(campo)){
          linea[campo] = dato[campo]? moment(dato[campo]).format('DD/MM/YYYY') : '';
        }else if(campo == 'Motivo'){
          linea[campo] = this.humanizemotivo(dato[campo]);
        }else{  
          linea[campo] = dato[campo];
        }
      }); 
      return linea;
    });
    this._incidenciasservice.creaexcel(datos).subscribe(
      response =>{
          saveAs(response, 'datos.xlsx');
        },
      error => {
        console.log(<any>error);
      }  
     );


  }

}

                        
