import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs/Observable";
import { AppService } from "./app.service";
import { Cliente } from "../models/cliente";
import { GLOBAL } from "./global";
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
  MatSnackBarConfig,
} from "@angular/material/snack-bar";

@Injectable()
export class ClienteService {
  public url: string;
  public cliente: string;

  constructor(
    public _http: HttpClient,
    public _appservice: AppService,
    private _snackBar: MatSnackBar
  ) {
    this.url = GLOBAL.url;
  }

  aviso(texto, subtexto) {
    this._snackBar.open(texto, subtexto, {
      duration: 4000,
      horizontalPosition: "center",
      verticalPosition: "top",
    });
  }

  saveCliente(cliente) {
    this.updateCliente(cliente).subscribe(
      (response) => {
        if (response.message != "usuario actualizado")
          this.aviso(response.message, "error");
      },
      (error) => {
        console.log(error);
      }
    );
  }

  registro(cliente: Cliente): Observable<any> {
    let params = JSON.stringify(cliente);
    let headers = new HttpHeaders().set("Content-Type", "application/json");
    return this._http.post(this.url + "guardarcliente", params, {
      headers: headers,
    });
  }

  signUp(datos): Observable<any> {
    let params = JSON.stringify(datos);
    let headers = new HttpHeaders().set("Content-Type", "application/json");
    return this._http.post(this.url + "login", params, { headers: headers });
  }

  getcliente(id): Observable<any> {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("authorization", this._appservice.getToken());
    return this._http.get(this.url + "vercliente/" + id, { headers: headers });
  }

  getclientenum(numero, mail): Observable<any> {
    let headers = new HttpHeaders().set("Content-Type", "application/json");
    let params = { mail: mail };
    return this._http.post(this.url + "verclientenum/" + numero, params, {
      headers: headers,
    });
  }

  updateCliente(cliente): Observable<any> {
    let params = JSON.stringify(cliente);
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("authorization", this._appservice.getToken());
    return this._http.post(
      this.url + "actualizacliente/" + cliente._id,
      params,
      { headers: headers }
    );
  }

  getFacturas(clienteid, numero, carpeta?): Observable<any> {
    if (carpeta) {
      var ruta = { ruta: "facturasclientes/" + numero + "/" + carpeta };
    } else {
      var ruta = { ruta: "facturasclientes/" + numero };
    }
    let params = JSON.stringify(ruta);
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("authorization", this._appservice.getToken());
    return this._http.post(this.url + "archivos/" + clienteid, params, {
      headers: headers,
    });
  }

  getArchivos(clienteid, numero): Observable<any> {
    var ruta = { ruta: "archivosclientes/" + numero };
    let params = JSON.stringify(ruta);
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("authorization", this._appservice.getToken());
    return this._http.post(this.url + "archivos/" + clienteid, params, {
      headers: headers,
    });
  }

  descargaFactura(factura, clienteid, ruta): Observable<any> {
    let file = { archivo: factura, ruta: ruta };
    let params = JSON.stringify(file);
    let headers = new HttpHeaders()
      .append("Content-Type", "application/json")
      .set("authorization", this._appservice.getToken());
    return this._http.post(this.url + "descarga/" + clienteid, params, {
      responseType: "blob",
      headers: headers,
    });
  }

  updatePassword(pass, id): Observable<any> {
    let params = JSON.stringify({ pass: pass });
    let headers = new HttpHeaders().set("Content-Type", "application/json");
    return this._http.put(this.url + "cambia-password/" + id, params, {
      headers: headers,
    });
  }

  updatePasswordlogged(pass, id): Observable<any> {
    let params = JSON.stringify({ pass: pass });
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("authorization", this._appservice.getToken());
    return this._http.put(this.url + "cambia-password-logged/" + id, params, {
      headers: headers,
    });
  }

  enviamail(numero, mail): Observable<any> {
    let tmp = { mail: mail, numero: numero };
    let params = JSON.stringify(tmp);
    let headers = new HttpHeaders().set("Content-Type", "application/json");
    return this._http.post(this.url + "mail", params, { headers: headers });
  }

  getclientes(): Observable<any> {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("authorization", this._appservice.getToken());
    return this._http.get(this.url + "clientes", { headers: headers });
  }

  getusuarios(): Observable<any> {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("authorization", this._appservice.getToken());
    return this._http.get(this.url + "usuarios", { headers: headers });
  }

  getmensajeros(): Observable<any> {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("authorization", this._appservice.getToken());
    return this._http.get(this.url + "mensajeros", { headers: headers });
  }

  getclientesnuevoarchivo(role: String, tiporole: String): Observable<any> {
    let tmp = { role: role, tiporole: tiporole };
    let params = JSON.stringify(tmp);
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("authorization", this._appservice.getToken());
    return this._http.post(this.url + "nuevosarchivos", params, {
      headers: headers,
    });
  }

  borrararchivo(archivo, numero, ruta): Observable<any> {
    let datos = { archivo: archivo, numero: numero, ruta: ruta };
    let params = JSON.stringify(datos);
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("authorization", this._appservice.getToken());
    return this._http.post(this.url + "borrararchivos", params, {
      headers: headers,
    });
  }

  borracliente(id): Observable<any> {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("authorization", this._appservice.getToken());
    return this._http.post(this.url + "borracliente/" + id, "", {
      headers: headers,
    });
  }

  borrarfacturas(ano): Observable<any> {
    let datos = { ano: ano };
    let params = JSON.stringify(datos);
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("authorization", this._appservice.getToken());
    return this._http.post(this.url + "borrarfacturas", params, {
      headers: headers,
    });
  }

  borrarfacturasmes(ano, mes): Observable<any> {
    let datos = { ano: ano, mes: mes };
    let params = JSON.stringify(datos);
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("authorization", this._appservice.getToken());
    return this._http.post(this.url + "borrafacturasmes", params, {
      headers: headers,
    });
  }

  getperfil(nombre): Observable<any> {
    let tmp = { perfil: nombre };
    let params = JSON.stringify(tmp);
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("authorization", this._appservice.getToken());
    return this._http.post(this.url + "verperfil", params, {
      headers: headers,
    });
  }

  getperfiles(): Observable<any> {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("authorization", this._appservice.getToken());
    return this._http.get(this.url + "verperfiles", { headers: headers });
  }

  guardaperfil(perfil, abonados): Observable<any> {
    let tmp = { nombre: perfil, abonadosnoincluir: abonados };
    let params = JSON.stringify(tmp);
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("authorization", this._appservice.getToken());
    return this._http.post(this.url + "guardaperfil", params, {
      headers: headers,
    });
  }

  borrarperfil(nombre): Observable<any> {
    let tmp = { perfil: nombre };
    let params = JSON.stringify(tmp);
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("authorization", this._appservice.getToken());
    return this._http.post(this.url + "borraperfil", params, {
      headers: headers,
    });
  }

  getfacturasg3(query): Observable<any> {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("authorization", this._appservice.getToken());
    var tmp = { query: query };
    var params = JSON.stringify(tmp);
    return this._http.post(this.url + "getfacturasg3", params, {
      headers: headers,
    });
  }

  guardafacturag3(factura): Observable<any> {
    var tmp = { factura: factura };
    var params = JSON.stringify(tmp);
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("authorization", this._appservice.getToken());
    return this._http.post(this.url + "guardafacturag3", params, {
      headers: headers,
    });
  }

  actualizaabonado(abonado): Observable<any> {
    var tmp = { abonado: abonado };
    var params = JSON.stringify(tmp);
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("authorization", this._appservice.getToken());
    return this._http.post(this.url + "aabonado", params, { headers: headers });
  }

  avisomail(id, mail, mensaje, asunto): Observable<any> {
    let tmp = { mail: mail, mensaje: mensaje, asunto: asunto };
    let params = JSON.stringify(tmp);
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("authorization", this._appservice.getToken());
    return this._http.post(this.url + "avisomail/" + id, params, {
      headers: headers,
    });
  }

  getabonados(query): Observable<any> {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("authorization", this._appservice.getToken());
    var cuerpo = JSON.stringify({ query: query });
    return this._http.post(this.url + "getabonados", cuerpo, {
      headers: headers,
    });
  }

  getcecos(query): Observable<any> {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("authorization", this._appservice.getToken());
    var cuerpo = JSON.stringify({ query: query });
    return this._http.post(this.url + "getcecos", cuerpo, { headers: headers });
  }

  getemail(numero): Observable<any> {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("authorization", this._appservice.getToken());
    return this._http.get(this.url + "emailcliente/" + numero, {
      headers: headers,
    });
  }

  getcontactos2601(): Observable<any> {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("authorization", this._appservice.getToken());
    return this._http.get(this.url + "getcontactos2601", { headers: headers });
  }

  getsolicitudes(query): Observable<any> {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("authorization", this._appservice.getToken());
    var cuerpo = JSON.stringify({ query: query });
    return this._http.post(this.url + "getsolicitudesapc", cuerpo, {
      headers: headers,
    });
  }

  solicitarApc(pndcrea, pndenvia): Observable<any> {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("authorization", this._appservice.getToken());
    var cuerpo = JSON.stringify({
      pendientecrear: pndcrea,
      pendienteenviarcliente: pndenvia,
    });
    return this._http.post(this.url + "guardasolicitudapc", cuerpo, {
      headers: headers,
    });
  }

  solicitarenvios(lineas, abonado, dep?) {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("authorization", this._appservice.getToken());
    var cuerpo = JSON.stringify({
      lineas: lineas,
      abonado: abonado,
      departamento: dep,
    });
    return this._http.post(this.url + "solicitaenvios", cuerpo, {
      headers: headers,
    });
  }

  getgrupos() {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("authorization", this._appservice.getToken());
    return this._http.get(this.url + "getgruposenvios", { headers: headers });
  }

  getgrupospendientes() {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("authorization", this._appservice.getToken());
    return this._http.get(this.url + "getgrupospendientes", {
      headers: headers,
    });
  }

  getgruposadmin(query) {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("authorization", this._appservice.getToken());
    var cuerpo = JSON.stringify({ query: query });
    return this._http.post(this.url + "getgruposadmin", cuerpo, {
      headers: headers,
    });
  }

  getgrupoeditablecliente() {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("authorization", this._appservice.getToken());
    return this._http.get(this.url + "getmasivo", { headers: headers });
  }

  savegrupo(grupo) {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("authorization", this._appservice.getToken());
    var cuerpo = JSON.stringify(grupo);
    return this._http.post(this.url + "savegrupo", cuerpo, {
      headers: headers,
    });
  }

  borragrupo(grupoid) {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("authorization", this._appservice.getToken());
    var cuerpo = JSON.stringify({ id: grupoid });
    return this._http.post(this.url + "borragrupo", cuerpo, {
      headers: headers,
    });
  }

  actualizagrupo(grupo, grupoid) {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("authorization", this._appservice.getToken());
    var cuerpo = JSON.stringify({ grupo: grupo });
    return this._http.post(this.url + "actualizagrupo/" + grupoid, cuerpo, {
      headers: headers,
    });
  }

  solicitadescarga(id) {
    let headers = new HttpHeaders().set("Content-Type", "application/json");
    return this._http.get(this.url + "solicitadescarga/" + id, {
      headers: headers,
    });
  }

  getarchivoenlace(id, pass): Observable<any> {
    let file = { password: pass };
    let params = JSON.stringify(file);
    let headers = new HttpHeaders().append("Content-Type", "application/json");
    return this._http.post(this.url + "download/" + id, params, {
      responseType: "blob",
      headers: headers,
    });
  }

  creaenlace(enlace): Observable<any> {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("authorization", this._appservice.getToken());
    var cuerpo = JSON.stringify({ enlace: enlace });
    return this._http.post(this.url + "creaenlace/", cuerpo, {
      headers: headers,
    });
  }

  compruebapass(id, pass): Observable<any> {
    let file = { password: pass };
    let params = JSON.stringify(file);
    let headers = new HttpHeaders().append("Content-Type", "application/json");
    return this._http.post(this.url + "comprobarpass/" + id, params, {
      headers: headers,
    });
  }

  getnotificacioneswilson(): Observable<any> {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("authorization", this._appservice.getToken());
    return this._http.get(this.url + "getnotifwilson", { headers: headers });
  }

  getetiquetas(albaranes, cookies): Observable<any> {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("authorization", this._appservice.getToken());
    let params = JSON.stringify({ albaranes, cookies });
    return this._http.post(this.url + "imprimiretiquetas", params, {
      responseType: "blob",
      headers: headers,
    });
  }

  justificante(albaran, cookies): Observable<any> {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("authorization", this._appservice.getToken());
    let params = JSON.stringify({ albaran, cookies });
    return this._http.post(this.url + "justificante", params, {
      responseType: "blob",
      headers: headers,
    });
  }

  editarmasivo(albaranid, datos, cookies): Observable<any> {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("authorization", this._appservice.getToken());
    let params = JSON.stringify({ albaranid, cookies, datos });
    return this._http.post(this.url + "edicionmasivo", params, {
      headers: headers,
    });
  }

  cancelamasivo(datos, cookies): Observable<any> {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("authorization", this._appservice.getToken());
    let params = JSON.stringify({ cookies, datos });
    return this._http.post(this.url + "cancelarenvio", params, {
      headers: headers,
    });
  }

  marcamasivo(envios, modificacion): Observable<any> {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("authorization", this._appservice.getToken());
    let params = JSON.stringify({ envios, modificacion });
    return this._http.post(this.url + "marcapendientes", params, {
      headers: headers,
    });
  }

  eliminaenviogrupo(envio): Observable<any> {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("authorization", this._appservice.getToken());
    let params = JSON.stringify({ envio });
    return this._http.post(this.url + "cancelarenviogrupo", params, {
      headers: headers,
    });
  }
}
