<div style="width:100%;display:inline-flex">
    <div class="filtros">
        <span class="spacer"></span>
            <button *ngIf="busqueda" mat-fab matTooltip="Salir de Busqueda" color="primary" (click)="cancelabusqueda()">
                <mat-icon>arrow_back</mat-icon>
            </button>  
        <span class="spacer"></span>
        <mat-form-field>
            <mat-label>Usuarios</mat-label>
            <mat-select [(ngModel)]="usuariosseleccionados" multiple>
              <mat-option *ngFor="let usuario of datos" [value]="usuario._id">{{usuario.nombre}}</mat-option>
            </mat-select>
        </mat-form-field>
        <span class="spacer"></span>
        <mat-form-field style="text-align:center;width:250px">
			<mat-label style="color:black;" (click)="picker2.open()"><b>Rango Fechas</b></mat-label>
			<mat-date-range-input [rangePicker]="picker2" (click)="picker2.open()">
			  <input matStartDate placeholder="Fecha Inicio" [(ngModel)]="fechainicio">
			  <input matEndDate placeholder="Fecha fin" [(ngModel)]="fechafin">
			</mat-date-range-input>
			<mat-date-range-picker #picker2></mat-date-range-picker>
		</mat-form-field>
        <span class="spacer"></span>
        <div>
            <button mat-raised-button (click)="buscarfichajes()">
                Buscar Fichajes
            </button> 
        </div>
        <span *ngIf="busqueda" class="spacer"></span>
        
        <div *ngIf="busqueda">
            <button mat-icon-button matTooltip="Exportar a excel" (click)="exportaexcel()">
                <mat-icon>file_copy</mat-icon>
            </button>
        </div>        
        <span class="spacer"></span>
    </div>
    <div *ngIf="!busqueda" class="contenedor">
        <span class="spacer"></span>
            <button mat-fab color="primary" (click)="antdia()">
                <mat-icon>arrow_back</mat-icon>
            </button>    
        <span class="spacer"></span>
        <mat-form-field>
            <input matInput [matDatepicker]="picker" value="value | date: 'dd/mm/yyyy'" [formControl]="date" (dateChange)="selectfecha($event)">
            <mat-datepicker #picker></mat-datepicker>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        </mat-form-field>     
        <span class="spacer"></span>
        <button mat-fab color="primary" (click)="sigdia()">
            <mat-icon>arrow_forward</mat-icon>
        </button>
        <span class="spacer"></span>
    </div>
</div>    
<div *ngIf="!busqueda" class="usuarios">
    <table mat-table *ngIf="fichajes && usuarios && dataSource" [dataSource]="dataSource" class="tabla">
        <ng-container matColumnDef="Fichado">
            <th mat-header-cell *matHeaderCellDef="Fichado">
                Fichado
            </th>
            <td mat-cell *matCellDef="let row">
                <img *ngIf="fichajes[row._id].estado" src="../assets/Iconos/trafficlight-green_40427.png" width="15">
                <img *ngIf="!fichajes[row._id].estado" src="../assets/Iconos/trafficlight-red_40428.png" width="15">
            </td>
        </ng-container>
        <ng-container *ngFor="let column of columns" [matColumnDef]="column.columnDef">
            <th mat-header-cell *matHeaderCellDef>
              {{column.header}}
            </th>
            <td mat-cell *matCellDef="let element">
              {{element[column.columnDef]}}
            </td>
        </ng-container>
        <ng-container matColumnDef="eman">
            <th mat-header-cell *matHeaderCellDef="eman">
                Entrada Mañana
            </th>
            <td mat-cell *matCellDef="let row">
                {{fichajes[row._id].horaentrada}}
            </td>
        </ng-container>
        <ng-container matColumnDef="salman">
            <th mat-header-cell *matHeaderCellDef="salman">
                Salida Mañana
            </th>
            <td mat-cell *matCellDef="let row">
                {{fichajes[row._id].horasalida}}
            </td>
        </ng-container>
        <ng-container matColumnDef="entar">
            <th mat-header-cell *matHeaderCellDef="entar">
                Entrada Tarde
            </th>
            <td mat-cell *matCellDef="let row">
                {{fichajes[row._id].horaentrada2}}
            </td>
        </ng-container>
        <ng-container matColumnDef="saltar">
            <th mat-header-cell *matHeaderCellDef="saltar">
                Salida Tarde
            </th>
            <td mat-cell *matCellDef="let row">
                {{fichajes[row._id].horasalida2}}
            </td>
        </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="celdas"></tr>
    </table>
</div>
<div *ngIf="busqueda" class="usuarios">
    <table mat-table *ngIf="fichajes && dataSource2" [dataSource]="dataSource2" class="tabla" matSort>
        <ng-container matColumnDef="nombre">
            <th mat-header-cell *matHeaderCellDef="nombre" mat-sort-header>
                Nombre
            </th>
            <td mat-cell *matCellDef="let element">
                {{element.usuario.nombre}}
            </td>
        </ng-container>
        <ng-container matColumnDef="numero">
            <th mat-header-cell *matHeaderCellDef="numero" mat-sort-header>
                Numero
            </th>
            <td mat-cell *matCellDef="let element">
                {{element.usuario.numero}}
            </td>
        </ng-container>
        <ng-container matColumnDef="fecha">
            <th mat-header-cell *matHeaderCellDef="fecha" mat-sort-header>
                Fecha
            </th>
            <td mat-cell *matCellDef="let element">
                {{element.fecha}}
            </td>
        </ng-container>
        <ng-container matColumnDef="horaentrada">
            <th mat-header-cell *matHeaderCellDef="horaentrada" mat-sort-header>
                Hora Entrada Mañana
            </th>
            <td mat-cell *matCellDef="let element">
                {{element.horaentrada}}
            </td>
        </ng-container>
        <ng-container matColumnDef="horasalida">
            <th mat-header-cell *matHeaderCellDef="horasalida" mat-sort-header>
                Hora Salida Mañana
            </th>
            <td mat-cell *matCellDef="let element">
                {{element.horasalida}}
            </td>
        </ng-container>
        <ng-container matColumnDef="horaentrada2">
            <th mat-header-cell *matHeaderCellDef="horaentrada2" mat-sort-header>
                Hora Entrada Tarde
            </th>
            <td mat-cell *matCellDef="let element">
                {{element.horaentrada2}}
            </td>
        </ng-container>
        <ng-container matColumnDef="horasalida2">
            <th mat-header-cell *matHeaderCellDef="horasalida2" mat-sort-header>
                Hora Salida tarde
            </th>
            <td mat-cell *matCellDef="let element">
                {{element.horasalida2}}
            </td>
        </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns2; sticky:true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns2;" class="celdas"></tr>
    </table>
</div>