<div style="display:inline-flex">
    <div style="margin-left:30px;">
		<mat-form-field appearance="outline" style="text-align:center;font-size:12pt">
			<mat-label style="color:black;"><b>Total Bultos</b></mat-label>
			<input matInput type="text" [value]="totales.totalbultos" style="padding:3px;color:black;" disabled="true">
		</mat-form-field>	
	</div>
	<div style="margin-left:30px;">
		<mat-form-field appearance="outline" style="text-align:center;font-size:12pt">
			<mat-label style="color:black;"><b>Total Kilos</b></mat-label>
			<input matInput type="text" [value]="totales.totalkilos.toFixed(2)" style="padding:3px;color:black;" disabled="true">
		</mat-form-field>	
	</div>
    <div style="margin-left:30px;">
		<mat-form-field appearance="outline" style="text-align:center;font-size:12pt">
			<mat-label style="color:black;"><b>Total envios</b></mat-label>
			<input matInput type="text" [value]="totales.totalenvios" style="padding:3px;color:black;" disabled="true">
		</mat-form-field>	
	</div>
	<div style="margin-left:30px;text-align: center;cursor:pointer;" class="boton" (click)="toggletotales()">
		<mat-icon>list_alt</mat-icon><br>
		<span *ngIf="!tot">Total de Envios</span>
		<span *ngIf="tot">Envios Pendientes</span>
	</div>
	<div style="margin-left:30px;">
		<mat-form-field appearance="outline" style="text-align:center;font-size:12pt">
			<mat-label style="color:black;"><b>Filtro</b></mat-label>
			<input matInput type="text" (keyup)="applyFilter($event)" style="padding:3px;color:black;" placeholder="Nombre, direccion, bultos..." #input>
		</mat-form-field>
	</div>
</div>
<mat-progress-bar *ngIf="!load" mode="indeterminate"></mat-progress-bar>
<div class="contenido">
    <table mat-table [dataSource]="envios" matSort class="mat-elevation-z8 demo-table tablamodificados">
        <ng-container *ngFor="let column of columns" [matColumnDef]="column">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                {{column}}
            </th>
            <td mat-cell *matCellDef="let row">
            <div *ngIf="row[column]">{{row[column]}}</div>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="columns sticky:true"></tr>
        <tr mat-row *matRowDef="let row; columns: columns;" class="example-element-row"></tr>
		<tr class="mat-row" *matNoDataRow>
			<td class="mat-cell">No data matching the filter "{{input.value}}"</td>
		</tr>
    </table>
</div>    