import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Concertada } from '../../models/concertada';
import { ClienteService } from '../../servicios/cliente.service';
import { ConcertadaService } from '../../servicios/concertada.service';
import { IncidenciasService } from '../../servicios/incidencias.service';
import { PlantillaService } from '../../servicios/plantilla.service';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { ImagenesexpedienteComponent } from '../imagenesexpediente/imagenesexpediente.component';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import * as moment from 'moment';
import { AppService } from '../../servicios/app.service';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition, MatSnackBarConfig } from '@angular/material/snack-bar';
import html2canvas from 'html2canvas';
import {MatCalendarCellClassFunction} from '@angular/material/datepicker';

@Component({
  selector: 'app-albconcertada',
  templateUrl: './albconcertada.component.html',
  styleUrls: ['./albconcertada.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [ClienteService, ConcertadaService, PlantillaService, IncidenciasService, AppService]
})
export class AlbconcertadaComponent implements OnInit {
    public identity;
	  public mensaje;
    public plantillas;
    public plantillas2;
    public nuevaplantilla;
    public editplantilla;
    public plantillaseleccionada;
    public nuevaplantilla2;
    public editplantilla2;
    public plantillaseleccionada2;
    public dirExpediente;
    public notaexpediente;
    public tiponota;
    public listatiponota;
    public imagenes;
    public imagenactual;
    public numimagen: number;
    public cantidadimagenes: number;
    public contestarexpediente;
    public albG3;
    public fechaconcertacion;
    public horario;
    public myControl = new FormControl();
    public filteredOptions: Observable<any[]>;
    public filtrohorario;
    public originales;
    public controlUbicaciones = new FormControl();
    public filteredUbicaciones: Observable<any[]>;
    public listaubicaciones;
    public listaexpediente;
    public columnasexpediente;
    public seleccionexp;
    public tipoconcertacion: string;
    public fechalibre;
    public listacierres;
    public fechasocupadas;

  constructor(
    private _concertadasservice: ConcertadaService,
    private _incidenciasservice: IncidenciasService,
    private _plantillasservice: PlantillaService,
    private dateAdapter: DateAdapter<Date>,
  	public dialogoAlbaran: MatDialogRef<AlbconcertadaComponent>,
    public ventanaImagenes: MatDialog,
    private _snackBar: MatSnackBar,
    private _appservice: AppService,
    @Inject(MAT_DIALOG_DATA) public data: Concertada
  	) {
      this.identity = this._appservice.getIdentity();
      this.proximodialibre();
      this.columnasexpediente = ['Estado','FechaEntrada','HoraEntrada','Descripcion','Detalle','Usuario'];
      this.filtrohorario = ['9h A 14h', '16h A 20h', '9H A 20H'];
      this.listaubicaciones = ['ALMACEN', 'ANDAMIO', 'PALET', 'LIBRERIA', 'ESTANTERIA', 'CARPA', 'DIRECCIONES INCORRECTAS', 'MUEBLE GRIS'];
      this.numimagen = 0;
      this.dateAdapter.setLocale('es');
      this.dateAdapter.getFirstDayOfWeek = () => { return 1; };
      this.editplantilla = undefined;
      this.nuevaplantilla = {};
      this.nuevaplantilla.texto = null;
      this.editplantilla2 = undefined;
      this.nuevaplantilla2 = {};
      this.nuevaplantilla2.texto = null;
      this.setoriginales();
      this.getexpediente();
      this.listatiponota = ['Pública Notificada', 'Pública','Privada'];
      this.tiponota = 'Pública Notificada';
      this.getplantillas('general');
      this.getplantillas2('observaciones');
      this.albarang3(this.data.NumAlbaran);
    }

  ngOnInit(): void {

    this.dateocupadas();
      this._concertadasservice.getdepositado(this.data.NumAlbaran).subscribe(response=>{
        if(response.datos){
          this.data.ObservacionesEstado = response.datos.ObservacionesEstado;
        }else{
          if(!this.data.ObservacionesEstado) this.data.ObservacionesEstado = this.data.MensajeroEntrega;
        }  
      });
      this.filteredOptions = this.myControl.valueChanges
          .pipe(
            startWith(''),
            map(name => name ? this.filtro(name) : this.filtrohorario.slice())
          );

      this.filteredUbicaciones = this.controlUbicaciones.valueChanges
          .pipe(
            startWith(''),
            map(name => name ? this.filtroubicacion(name) : this.listaubicaciones.slice())
          );

      this.dirExpediente = 'https://www2-vpc.mrw.es/gestion3/Operativo/Popups/ExpedienteEnvio2?Arguments=' + this.data.Id + ';' + this.data.NumAlbaran + ';' + this.data.Estado + ';' + this.data.Fase + ';;;' + this.data.FechaEnvio + ';' + this.data.FranquiciaOrigen + ';' + this.data.FranquiciaDestino;
      
      this._incidenciasservice.imgExp(this.data.NumAlbaran, this.dirExpediente).subscribe(
        response =>{
            if(response.resp){
              this.imagenes = new Array();
              var tmp = response.resp;
              this.cantidadimagenes = tmp.length;
              tmp.forEach((imagen)=>{
                this.imagenes.push('data:image/jpg;base64,' + imagen.ImageBase64);
              });
              this.numimagen = 0;
              if(tmp.length >=1) this.imagenactual = 'data:image/jpg;base64,' + tmp[this.numimagen].ImageBase64;
              
            }
        },
         error =>{
          console.log(<any>error);
         } 
      );
      if(this.identity.numero == '0260100010') this.tipoconcertacion = 'mensajero';
    }

  cancelar() {
    this.closeDialog();
  }

  setoriginales(){
    this.originales = {};
    if(this.data.fechaconcertada){
      var tmp = this.data.fechaconcertada;
      this.originales['fecha'] = tmp; 
      } 
    if(this.data.horarioconcertado){
      var tmp = this.data.horarioconcertado;
      this.originales['horario'] = tmp; 
      } 
  }

  dateClass: MatCalendarCellClassFunction<Date> = (cellDate, view) => {
    if (!this.fechasocupadas) {
      console.error('fechasocupadas is undefined');
      return '';
    }
  
    const date = moment(cellDate).set({hour:22, minute:0, second:0, millisecond:0}).toISOString();
    return this.fechasocupadas.includes(date) ? 'fechaagotada' : '';
  };
  

  closeDialog() {
    this.dialogoAlbaran.close();
  }

  filtro(name: string){
    const filterValue = name.toLowerCase();
   return this.filtrohorario.filter(option => option.toLowerCase().indexOf(filterValue) === 0);
  }

  filtroubicacion(name: string){
    const filterValue = name.toLowerCase();
   return this.listaubicaciones.filter(option => option.toLowerCase().indexOf(filterValue) === 0);
  }

  dateocupadas(){
    var fecha = moment().set({hour:22,minute:0,second:0,millisecond:0}).add(1, 'days').toISOString();
    var query = {fecha:{$gte:fecha}};
    this._concertadasservice.getcierres(query).subscribe(
      response=>{
        if(response.cierres){
          this.fechasocupadas = response.cierres.map((cierre)=>{
            return cierre.fecha;
          });
        }
      }  
    );       
  }
 
  myFilter = (d: Date | null): boolean => {
    const day = (d || new Date()).getDay();
    var diasiguiente = new Date();
    if(this.identity.role == 'Administrador' || this.identity.role == 'operador-plus' || this.identity.role == 'operador-admin'){
      // Prevent Saturday and Sunday from being selected.
      return day !== 0 && day !== 6;
    }else{
      var fechamoment = moment(d).set({hour:22,minute:0,second:0,millisecond:0}).toISOString();
      if(day !== 0 && day !== 6 && day && d > diasiguiente && !this.fechasocupadas.includes(fechamoment) && diasiguiente != d){
        return true
      }else{
        return false;
      }
    }
  }


  displayFn(user): string {
    return user ? user : '';
  }

  aviso(texto, subtexto) {
    this._snackBar.open(texto,subtexto,{
      duration: 5000,
      horizontalPosition: "left",
      verticalPosition: "top",
    });
  }

  getplantillas(tipo){
    this._plantillasservice.getplantillas(this.identity._id, tipo).subscribe(
      response=>{
        this.plantillas = response.plantillas;
        },
      error=>{
        console.log(<any>error);
      }  
    );
  }

   getplantillas2(tipo){
    this._plantillasservice.getplantillas(this.identity._id, tipo).subscribe(
      response=>{
        this.plantillas2 = response.plantillas;
        },
      error=>{
        console.log(<any>error);
      }  
    );
  }

  asignarfechaconcertacion(){
      var fecha = moment(this.fechaconcertacion).format("DD/MM/YYYY");
      this.data.impresa = 0;
      var fechacierre = moment(this.fechaconcertacion).set({hour:22,minute:0,second:0,millisecond:0}).toISOString(true)
      this._concertadasservice.getcierre(fechacierre).subscribe(
        response=>{
            if(response.cierre){
              var cierre = response.cierre;
            }
            var query = {fechaconcertada: fecha};
            var params = {"query": query};
            this._concertadasservice.getconcertadas(params).subscribe(
              response =>{
                if(response.concertadas){
                  var dia = new Date(this.fechaconcertacion).getDay();
                  if(dia == 2){
                    var maximo = 20; 
                  }else{
                    var maximo = 40;
                  }
                  if(this.tipoconcertacion){
                    var conc = this.tipoconcertacion;
                  }else{
                    var conc = '';
                  }
                  if(cierre || response.concertadas.length >= maximo){
                    if(this.identity.role == 'Administrador' || this.identity.role == 'operador-plus' || this.identity.role == 'operador-admin'){
                      this.data.fechaconcertada = moment(this.fechaconcertacion).format("DD/MM/YYYY");
                      this.notaexpediente = 'Concertada entrega: ' + this.data.fechaconcertada + ' de ' + this.data.horarioconcertado + ' por ' + conc;
                    }else{
                      this.fechaconcertacion = undefined;
                      this.aviso(`Dia cerrado para segundas entregas, no se puede concertar para esta fecha, el proximo dia libre es: ` + this.fechalibre, 'error');
                    }  
                  }else{
                    this.data.fechaconcertada = moment(this.fechaconcertacion).format("DD/MM/YYYY");
                    this.notaexpediente = 'Concertada entrega: ' + this.data.fechaconcertada + ' de ' + this.data.horarioconcertado + ' por ' + conc;
                  }
                }
              },
              error=>{
                console.log(<any>error);  
            });    
      },
        error=>{
          console.log(<any>error);
        }  
      );
  }

  cancelafecha(){
    this.fechaconcertacion = undefined;
  }

  sethorario(){
    this.data['horarioconcertado'] = this.myControl.value;
    this.data.impresa = 0;
    if(this.tipoconcertacion){
      var conc = this.tipoconcertacion;
    }else{
      var conc = '';
    }
    this.notaexpediente = 'Concertada entrega: ' + this.data.fechaconcertada + ' de ' + this.data.horarioconcertado + ' por ' + conc;
  }

  setubicacion(ubicacion){
    var fecha = moment().format('DD/MM/YYYY').toString();
    var hora = moment().format('HH:mm').toString();
    var concertada = {
      IdEnvio:this.data.Id,
      NumeroAlbaran:this.data.NumAlbaran,
      ubicacion:ubicacion,
      fecha:fecha,
      hora:hora
    };

    if(this.data.Fase != 'Entrega'){
      this._concertadasservice.recibido(concertada).subscribe(
      response=>{
        if(response.datos[0]){
            this.aviso(response.datos[0], 'error');
          }else{
            this._concertadasservice.ubicar(concertada).subscribe(
              response=>{
                if(response.datos[0]){
                    this.aviso(response.datos[0], 'error');
                  }else{
                    this.aviso('Ubicacion guardada con Exito', 'ok');
                  }
              });  
          }
      });  

    }else{
      this._concertadasservice.ubicar(concertada).subscribe(
        response=>{
          if(response.datos[0]){
              this.aviso(response.datos[0], 'error');
            }else{
              this.aviso('Ubicacion guardada con Exito', 'ok');
            }
        });
    }      
  }

  guardaconcertada(){
     if(this.tipoconcertacion && this.data.fechaconcertada && this.data.horarioconcertado || this.data.impresamensajero == 'agencia'){
        this.data['fecharegistro'] = moment(new Date()).format('DD/MM/YYYY HH:mm:ss');
        this.data['usuario'] = this.identity.nombre;
        this._concertadasservice.guardarconcertada(this.data).subscribe(
          response =>{
              if(this.originales){
                if(this.data.fechaconcertada == this.originales.fecha){
                  this.concertar();
                  this.aviso('Concertacion guardada correctamente', 'ok');
                  this.dialogoAlbaran.close();
                }else{
                  this.data.impresa = 0;
                  this.concertar();
                } 
              }else{
                this.concertar();
              }
              var fecha = new Date(this.data.fechaconcertada);
              var query = {fechaconcertada: this.data.fechaconcertada};
              var params = {"query": query};
              this._concertadasservice.getconcertadas(params).subscribe(
                response =>{
                  if(response.concertadas && response.concertadas.length >= 1){
                  var dia = fecha.getDay();
                    if(dia == 2){
                      var maximo = 20;
                    }else{
                      var maximo = 40;
                    }
                    if(response.concertadas.length >= maximo){
                      var setfecha = moment(this.data.fechaconcertada, 'DD/MM/YYYY').set({hour:22,minute:0,second:0,millisecond:0}).toISOString(true);
                      this.setcierre(setfecha);
                    }  
                  }  
                },
                error=>{
                  console.log(<any>error);
              });        

            },
           error =>{
            console.log(<any>error);
           });
     }else{
      if(!this.tipoconcertacion) this.aviso('Falta seleccionar tipo de concertación', 'error');
      if(!this.data.fechaconcertada) this.aviso('Falta fecha de concertación', 'error');
      if(!this.data.horarioconcertado) this.aviso('Falta horario de concertación', 'error');
    }

  }

  guardaconcertadamensajero(){
    if(this.tipoconcertacion && this.data.fechaconcertada && this.data.horarioconcertado || this.data.impresamensajero == 'agencia'){
        this.data['fecharegistro'] = moment(new Date()).format('DD/MM/YYYY HH:mm:ss');
        this.data['usuario'] = this.identity.nombre;
        this._concertadasservice.guardarconcertada(this.data).subscribe(
          response =>{
            var fecha = new Date(this.data.fechaconcertada);
            var query = {fechaconcertada: this.data.fechaconcertada};
            var params = {"query": query};
            this._concertadasservice.getconcertadas(params).subscribe(
              response =>{
                if(response.concertadas && response.concertadas.length >= 1){
                  var dia = fecha.getDay();
                  if(dia == 2){
                    var maximo = 20;
                  }else{
                    var maximo = 40;
                  }
                  if(response.concertadas.length >= maximo){
                    var setfecha = moment(this.data.fechaconcertada, 'DD/MM/YYYY').set({hour:22,minute:0,second:0,millisecond:0}).toISOString(true);
                    this.setcierre(setfecha);
                  }  
                }
                this.dialogoAlbaran.close();  
              },
               error=>{
                 console.log(<any>error);
              });        

          },
            error =>{
            console.log(<any>error);
          });
    }else{
     if(!this.tipoconcertacion) this.aviso('falta seleccionar tipo de concertacion', 'error');
     if(!this.data.fechaconcertada) this.aviso('falta fecha de concertacion', 'error');
     if(!this.data.horarioconcertado) this.aviso('falta horario de concertacion', 'error');
   }

 }

  newplantilla(){
    this.nuevaplantilla.tipo = 'general';
    this.nuevaplantilla.usuario = this.identity._id;
    this.nuevaplantilla.texto = "";
    this.plantillaseleccionada = null;
  }

  saveplantilla(){
    this._plantillasservice.nuevaplantilla(this.nuevaplantilla).subscribe(
      response=>{
          if(response.message) this.aviso(response.message, 'ok');
          this.getplantillas(this.nuevaplantilla.tipo);
          this.nuevaplantilla = {};
          this.nuevaplantilla.texto = null;
          
        },
      error=>{
        console.log(<any>error);
      }  
    );
  }

  editaplantilla(){
    this.editplantilla = this.plantillaseleccionada;
  }

  updateplantilla(){
    this._plantillasservice.actualizarplantilla(this.plantillaseleccionada).subscribe(
      response=>{
          if(response.message) this.aviso(response.message, 'ok');
          this.editplantilla = undefined;
        },
      error=>{
        console.log(<any>error);
      }  
    );
  }

  cancelplantilla(){
    this.getplantillas(this.nuevaplantilla.tipo);
    this.nuevaplantilla = {};
    this.nuevaplantilla.texto = null;
  }

  canceleditplantilla(){
    this.editplantilla = undefined;
  }

  selecplantilla(plantilla){
    this.plantillaseleccionada = plantilla;
  }

  pasartextonota(plantilla){
    if(this.notaexpediente){
      this.notaexpediente = this.notaexpediente + '\n\r' + plantilla;  
      }else{
        this.notaexpediente = plantilla;
      };
  }

  eliminaPlantilla(plantilla){
    this._plantillasservice.eliminarplantilla(plantilla._id).subscribe(
      response=>{
          if(response.message) this.aviso(response.message, 'ok');
          this.plantillaseleccionada = null;
          this.getplantillas(plantilla.tipo);
        },
      error=>{
        console.log(<any>error);
      }  
    );
  }

 
  editaplantilla2(){
    this.editplantilla2 = this.plantillaseleccionada2;
  }

  updateplantilla2(){
    this._plantillasservice.actualizarplantilla(this.plantillaseleccionada2).subscribe(
      response=>{
          if(response.message) this.aviso(response.message, 'ok');
          this.editplantilla2 = undefined;
        },
      error=>{
        console.log(<any>error);
      }  
    );
  }

  cancelplantilla2(){
    this.getplantillas2(this.nuevaplantilla2.tipo);
    this.nuevaplantilla2 = {};
    this.nuevaplantilla2.texto = null;
  }

  canceleditplantilla2(){
    this.editplantilla2 = undefined;
  }

  selecplantilla2(plantilla){
    this.plantillaseleccionada2 = plantilla;
  }

  pasartextoobservaciones(plantilla){
    if(this.data.obconcertada){
      this.data.obconcertada = this.data.obconcertada + '\n\r' + plantilla;  
      }else{
        this.data.obconcertada = plantilla;
      };
  }

  eliminaPlantilla2(plantilla){
    this._plantillasservice.eliminarplantilla(plantilla._id).subscribe(
      response=>{
          if(response.message) this.aviso(response.message, 'ok');
          this.plantillaseleccionada2 = null;
          this.getplantillas2(plantilla.tipo);
        },
      error=>{
        console.log(<any>error);
      }  
    );
  }

  newplantilla2(){
    this.nuevaplantilla2.tipo = 'observaciones';
    this.nuevaplantilla2.usuario = this.identity._id;
    this.nuevaplantilla2.texto = "";
    this.plantillaseleccionada2 = null;
  }

  saveplantilla2(){
    this._plantillasservice.nuevaplantilla(this.nuevaplantilla2).subscribe(
      response=>{
          if(response.message) this.aviso(response.message, 'ok');
          this.getplantillas2(this.nuevaplantilla2.tipo);
          this.nuevaplantilla2 = {};
          this.nuevaplantilla2.texto = null;
          
        },
      error=>{
        console.log(<any>error);
      }  
    );
  }

  intranet(albaran){
    window.open('http://intranet.mrw.es/espana/Aplicaciones/historia/esepes/portal_repnac.asp?Albaran=' + albaran);
  }

  expediente(){
    window.open(this.dirExpediente);
  }

  albarang3(albaran){
         
          this._incidenciasservice.albaranG3(albaran).subscribe(
            response=>{
                if(response.datos){
                  this.albG3 = response.datos;
                 
                  if(this.albG3.Estado != this.data.Estado){
                    if(this.albG3.CodigoAbonado)this.albG3.CodigoAbonado = this.albG3.CodigoAbonado.substring(-1, 6);
                    this.data.Fase = this.albG3.Fase;
                    this._concertadasservice.guardarconcertada(this.albG3).subscribe(
                      response =>{
                          this.data.Estado = this.albG3.Estado;
                          this.aviso('estado actualizado, concertacion guardada', 'ok');
                       },
                       error =>{
                        console.log(<any>error);
                       } 
                    );
                  }
                }else{
                  this.aviso('no se ha podido buscar el albaran en G3', 'error');
                } 

               },
            error=>{
              console.log(<any>error)
            }  
          );
  }

  notag3(){
    var h = new Date().getHours();
    var m = new Date().getMinutes();
    var hora = h + ':' + m;    

    var postit = {idenvio: this.data.Id, nota: this.notaexpediente, hora: hora, tipo: this.tiponota};

    this._incidenciasservice.notaG3(postit).subscribe(
            response=>{
                if(response.message) this.aviso(response.message, 'ok');
                this.notaexpediente = undefined;
                this.tiponota = undefined;
                if(this.contestarexpediente) this.contestarexpediente = undefined;
               },
            error=>{
              console.log(<any>error)
            }  
    );
  }

  anteriorimg(){
    this.numimagen = this.numimagen -1;
    this.imagenactual = this.imagenes[this.numimagen];
    
  }

  siguienteimg(){
    this.numimagen = this.numimagen +1;
    this.imagenactual = this.imagenes[this.numimagen];
    
  }

  abreimagenes(concertada){
      
    this.ventanaImagenes.open(ImagenesexpedienteComponent, {
      width: '85%',
      height: '85%',
      data: concertada});
   }

   eliminaconcertacion(){
    this._concertadasservice.borrarconcertada(this.data.NumAlbaran).subscribe(
      response=>{
            if(response.message) this.aviso(response.message, 'ok');
            this.closeDialog();
        },
      error=>{
        this.aviso('ha ocurrido un error al eliminar la concertacion', 'error');
      }  
    );
   }

  imprimirElemento(){
    if(!this.tipoconcertacion || !this.data.fechaconcertada || !this.data.horarioconcertado){
      if(!this.tipoconcertacion) this.aviso('falta seleccionar tipo de concertacion', 'error');
      if(!this.data.fechaconcertada) this.aviso('falta fecha de concertacion', 'error');
      if(!this.data.horarioconcertado) this.aviso('falta horario de concertacion', 'error');
    }else{
      if(!this.data.obconcertada) this.data.obconcertada = ' ';
      if(!this.data.ObservacionesEstado) this.data.ObservacionesEstado = 'Mensajero: ' + this.data.MensajeroEntrega;
      var contenido = '<div style="text-align:center;align-content:center;"><h2>' + this.data.NumAlbaran + '</h2><h3>2º Intento de Entrega</h3><div style="font-size:50px;page-break-after:avoid">' + this.data.fechaconcertada + '</div><div style="font-size:40px">' + this.data.horarioconcertado + '</div><br><div>' + this.data.Destinatario + '</div><br><div>Direccion<br>' + this.data.DireccionDestinatario + '</div><br><div style="display:inline-flex"><div style="margin-right:20px">Ubicacion<br>' + this.data.ObservacionesEstado + '</div><div>Codigo Postal<br>' + this.data.CpDestinatario + '</div></div><div style="margin-top:20px">Bultos: ' + this.data.TotalBultos + '</div><br><div style="font-size:22px"><b>' + this.data.obconcertada + '</b></div><div style="margin-top:12px;">Etiqueta 1 de ' + this.data.TotalBultos + '</div></div>';
      if(this.data.TotalBultos >= 2){
        var etiquetaextra = '<div style="text-align:center;align-content:center;page-break-before:always"><h2>' + this.data.NumAlbaran + '</h2><h3>2º Intento de Entrega</h3><div style="font-size:50px;page-break-after:avoid">' + this.data.fechaconcertada + '</div><div style="font-size:40px">' + this.data.horarioconcertado + '</div><br><div>' + this.data.Destinatario + '</div><br><div>Direccion<br>' + this.data.DireccionDestinatario + '</div><br><div style="display:inline-flex"><div style="margin-right:20px">Ubicacion<br>' + this.data.ObservacionesEstado + '</div><div>Codigo Postal<br>' + this.data.CpDestinatario + '</div></div><div style="margin-top:20px">Bultos: ' + this.data.TotalBultos + '</div><br><div style="font-size:22px"><b>' + this.data.obconcertada + '</b></div>';
        for(var i = 2; i <= this.data.TotalBultos;i++){
          contenido = contenido + etiquetaextra + '<div style="margin-top:12px;">Etiqueta ' + i + ' de ' + this.data.TotalBultos + '</div></div>';
        }
      }
      var ventana = window.open('', 'PRINT', 'height=500,width=500');
      ventana.document.write('<html><head><style type="text/css" media="print">*{margin:0px;padding:0px;} body{font-size:15px;}</style></head><body>');
      ventana.document.write(contenido);
      ventana.document.write('</body></html>');
      ventana.document.close();
      ventana.focus();
      ventana.print();
      ventana.close();
      if(this.data.impresa){
        this.data.impresa++
        this.aviso('etiqueta impresa mas de una vez', 'ok');
      }else{
        this.data['impresa'] = 1;
      }
      this.data.usuarioimpresion = this.identity.nombre;
      this.data['fechaimpresion'] = moment(new Date()).format('DD/MM/YYYY HH:mm:ss');
      var ubicacion = 'Sacado según concertación';
      this.setubicacion(ubicacion);
      this.guardaconcertada();
    }  
  }

  imprimircalle(){
    var direccion = this.data.DireccionDestinatario.toUpperCase().replace('CALLE', '').replace('AVENIDA','');
    var contenido = '<div style="text-align:center;align-content:center;"><div style="font-size:60px;transform: rotate(90deg) translate(100px, 0px);"><b>' + direccion + '</b></div></div>';
    var ventana = window.open('', 'PRINT', 'height=500,width=500');
    ventana.document.write('<html><head><style type="text/css" media="print">*{margin:0px;padding:0px;} body{font-size:15px;}</style></head><body>');
    ventana.document.write(contenido);
    ventana.document.write('</body></html>');
    ventana.document.close();
    ventana.focus();
    ventana.print();
    ventana.close();         
  }

  imprimirmensajero(){
    if(!this.data.obconcertada) this.data.obconcertada = ' ';
    if(!this.data.ObservacionesEstado) this.data.ObservacionesEstado = ' ';
    if(!this.data.fechaconcertada) this.data.fechaconcertada = '';
    if(!this.data.horarioconcertado) this.data.horarioconcertado = '';
    var contenido = '<div style="text-align:center;align-content:center;"><h2>' + this.data.NumAlbaran + '</h2><h3>Concertada Entrega</h3><div style="font-size:50px;page-break-after:avoid">' + this.data.fechaconcertada + '</div><div style="font-size:40px">' + this.data.horarioconcertado + '</div><br><div>' + this.data.Destinatario + '</div><br><div>Direccion<br>' + this.data.DireccionDestinatario + '</div><br><div style="display:inline-flex"><div>Codigo Postal: ' + this.data.CpDestinatario + '</div><div style="margin-left:10px;">Bultos: ' + this.data.TotalBultos + '</div></div><br><div style="font-size:22px"><b>' + this.data.obconcertada + '</b></div></div>';
    var ventana = window.open('', 'PRINT', 'height=500,width=500');
    ventana.document.write('<html><head><style type="text/css" media="print">*{margin:0px;padding:0px;} body{font-size:15px;}</style></head><body>');
    ventana.document.write(contenido);
    ventana.document.write('</body></html>');
    ventana.document.close();
    ventana.focus();
    ventana.print();
    ventana.close();
    this.data['impresamensajero'] = 'agencia';
    this.guardaconcertadamensajero();
  }

   concertar(){
    if(!this.notaexpediente) this.notaexpediente = 'Concertada entrega: ' + this.data.fechaconcertada + ' de ' + this.data.horarioconcertado + ' por ' + this.tipoconcertacion;
    if(this.data.EmailDestinatario){
      var canalconcer = 'Email';
      var email = this.data.EmailDestinatario;
      var telefono = '';
    }else{
      var telefono = this.data.TelefonoDestinatario? this.data.TelefonoDestinatario : '';
      var canalconcer = telefono? 'SMS' : 'Email';
      var email = '';
    }
    var concertada = {
      idenvio:this.data.Id,
      texto: this.notaexpediente,
      fecha: this.data.fechaconcertada,
      canalconcertacion: canalconcer,
      email: email,
      telefono: telefono
    }
    this._concertadasservice.concertarentrega(concertada).subscribe(
      response=>{         
          if(response.datos[0]){
            this.aviso(response.datos[0], 'error');
          }else{
            this.aviso('concertacion guardada con Exito', 'ok');
            this.dialogoAlbaran.close();
          }
          
        },
      error=>{
        console.log(<any>error);
      });
  }

  concertarAgencia(){
    if(!this.data.obconcertada) this.data.obconcertada = ' ';
    this.data['impresamensajero'] == 'agencia';
    var fecha = moment().format('DD/MM/YYYY').toString();
    var concertada = {
      idenvio:this.data.Id,
      texto: this.data.obconcertada,
      fecha: fecha
    }
    this._concertadasservice.concertarAgencia(concertada).subscribe(
      response=>{         
          if(response.datos.errorTitle){
            this.aviso('error al enviar concertacion a G3', 'error');
          }else{
            this.aviso('concertacion en Agencia guardada con Exito', 'ok');
          }
        },
      error=>{
        console.log(<any>error);
      });
  }

  setcierre(fecha){
    var query = {fechaconcertada: fecha};
    var params = {"query": query};
    this._concertadasservice.getconcertadas(params).subscribe(
      response =>{
        if(response.concertadas && response.concertadas.length >= 1){
          var cantidad = response.concertadas.length;
          var cierre = {
            usuario: this.identity.nombre,
            fecha: fecha,
            cantidad: cantidad
          };

          this._concertadasservice.setcierre(cierre).subscribe(
            response=>{
                console.log(response);
              },
            error=>{
              console.log(<any>error);
            });
        }
      },
      error =>{
        console.log(<any>error);
      });
  }

   getexpediente(){
    this._incidenciasservice.getexpediente(this.data).subscribe(
      response=>{
          if(response.datos){
            this.listaexpediente = response.datos.reverse();
          }else{
            this.listaexpediente = undefined;
          }  
        },
      error=>{
        console.log(<any>error);
      });
  } 

  detalleexpediente(detalle){
    this.seleccionexp = detalle.Id;
   switch(detalle.Tipo){
    case 'Alta':
      var tipo = 'Alta';
      break;
    case 'Operacion':
      var tipo = 'Operacion';
      break;
    case 'Lectura':
      var tipo = 'Lectura';
      break;
    case 'Anomalía':
      var tipo = 'Anomalía';
      break;
    case 'Nota notificada':
      var tipo = 'Notificada';
      break;
    case 'Nota pública':
      var tipo = 'Nota';
      break;  
    default:
      var tipo = 'Alta';
      break;  
   }
   var url = 'https://www2-vpc.mrw.es/gestion3/Operativo/Envios/DetalleExpediente?Arguments=id:' + detalle.Id + '_numcolumna:0_fechaexp:' + detalle.FechaEntrada + '_esNivelBulto:false_numbultocurrent:' + detalle.Bulto + '_processOrder:' + detalle.ProcessOrder + '_tipo:' + tipo + '_numAlbaran:' + detalle.NumeroEnvio + '_idEnvio:' + this.data.Id;
   window.open(url);
  }

  botontipoconc(valor){
    this.tipoconcertacion = valor;
    this.notaexpediente = 'Concertada entrega: ' + this.data.fechaconcertada + ' de ' + this.data.horarioconcertado + ' por ' + this.tipoconcertacion;
  } 

  proximodialibre(){
    this.fechalibre = undefined;
    var fecha = moment().set({hour:22,minute:0,second:0,millisecond:0}).add(1, 'days').toISOString();
    var query = {fecha:{$gte:fecha}};
    this._concertadasservice.getcierres(query).subscribe(
      response=>{
        if(response.cierres){
          var cierres = response.cierres;
          var control = undefined;
          fecha = moment(fecha).format('DD/MM/YYYY');
          var i = 1;
          while(!this.fechalibre){
            control = cierres.findIndex(cierre=>{
              var tmp = moment(cierre.fecha).format('DD/MM/YYYY');
              if(tmp == fecha){
                return true;
              }else{
                return false;
              }  
            });
            if(control > -1){
              i++
              fecha = moment().set({hour:22,minute:0,second:0,millisecond:0}).add(i, 'days').format('DD/MM/YYYY'); 
              var controlfinde = moment(fecha, 'DD/MM/YYYY').day();
              if(controlfinde == 0){
                i++
                fecha = moment().set({hour:22,minute:0,second:0,millisecond:0}).add(i, 'days').format('DD/MM/YYYY');
              }
              if(controlfinde == 6){
                i = i + 2;
                fecha = moment().set({hour:22,minute:0,second:0,millisecond:0}).add(i, 'days').format('DD/MM/YYYY');
              }  
            }else{
              this.fechalibre = fecha;
            }
          }
        }
      },
    error=>{
      console.log(<any>error);
    });
  }     

}