<div class="barraprincipalapp" *ngIf="!concertar">
  <div
    class="titulobarraprincipalapp"
    style="display: inline-flex; cursor: pointer"
    (click)="enrutar('inicio')"
  >
    <img src="../assets/Images/logo.png" />
    <div class="trans">
      <div style="padding: 0px; margin: 0px">
        {{ title }}
      </div>
      <div style="font-size: 8pt; padding: 0px; margin: 0px">
        Franquicia MRW 02601
      </div>
    </div>
  </div>
  <span class="spacer"></span>
  <div style="margin-right: 25px; margin-top: 8px" *ngIf="!identity">
    <button mat-button (click)="enrutar('login')">
      <mat-icon style="margin-right: 5px">login</mat-icon>Login
    </button>
  </div>
  <div *ngIf="identity" class="infobarraprincipalapp">
    <button
      mat-button
      matTooltip="Chat"
      [matMenuTriggerFor]="menumsg"
      *ngIf="identity && identity.role != 'cliente'"
      style="margin-right: 20px; cursor: pointer"
    >
      <mat-icon
        [matBadge]="mensajes.length"
        [matBadgeOverlap]="true"
        matBadgeSize="medium"
        [matBadgeHidden]="mensajes.length == 0"
        >message</mat-icon
      >
    </button>
    <mat-menu #menumsg="matMenu" class="mensajes">
      <button
        mat-button
        mat-menu-item
        style="background-color: white; color: black; opacity: 1"
        (click)="abremensajeria()"
      >
        <mat-icon style="margin-right: 5px; color: black">forum</mat-icon>Chat
      </button>
      <button
        mat-button
        mat-menu-item
        style="background-color: white; color: black; opacity: 1"
        (click)="ocultanotificacionesmensajes()"
      >
        <mat-icon style="margin-right: 5px; color: black">hide_image</mat-icon
        >Ocultar Todas
      </button>
      <div
        *ngFor="let mensaje of mensajes"
        class="listamensajes"
        (click)="abrechat(mensaje)"
      >
        <mat-card class="tarjetamensaje">
          <mat-card-title style="font-size: 10px">
            mensaje de: {{ mensaje.alias }}
          </mat-card-title>
          <mat-card-content
            style="word-wrap: break-word"
            [innerHtml]="mensaje.mensaje"
          >
          </mat-card-content>
        </mat-card>
      </div>
    </mat-menu>
    <div
      #tooltip4="matTooltip"
      matTooltip="Fichaje correcto"
      *ngIf="
        identity && identity.role != 'cliente' && ficha && ficha.estado == true
      "
      style="margin-right: 20px; cursor: pointer"
      (click)="openDialog()"
    >
      <img src="../assets/Iconos/trafficlight-green_40427.png" width="20" />
    </div>
    <div
      #tooltip5="matTooltip"
      matTooltip="Sin Fichar"
      *ngIf="
        (identity && identity.role != 'cliente' && !ficha) ||
        (identity &&
          identity.role != 'cliente' &&
          ficha &&
          ficha.estado == false)
      "
      style="margin-right: 20px; cursor: pointer"
      (click)="openDialog()"
    >
      <img src="../assets/Iconos/trafficlight-red_40428.png" width="20" />
    </div>
    <span
      #tooltip="matTooltip"
      matTooltip="Conexion correcta a G3"
      *ngIf="galletaG3 && identity && identity.role != 'cliente'"
      class="material-icons"
      style="color: white; margin-right: 20px; cursor: pointer"
      >import_export</span
    >
    <span
      #tooltip1="matTooltip"
      (click)="refrescaG3()"
      matTooltip="No hay conexion a G3"
      *ngIf="!galletaG3 && identity && identity.role != 'cliente'"
      class="material-icons"
      style="color: white; margin-right: 20px; cursor: pointer"
      >desktop_access_disabled</span
    >
    <span
      #tooltip2="matTooltip"
      matTooltip="Conexion correcta a Office365"
      *ngIf="tokenoffice && identity && identity.role != 'cliente'"
      class="material-icons"
      style="margin-right: 20px; cursor: pointer"
      >mark_email_read</span
    >
    <span
      #tooltip3="matTooltip"
      matTooltip="No hay conexion a Office365"
      *ngIf="!tokenoffice && identity && identity.role != 'cliente'"
      class="material-icons"
      style="margin-right: 20px; cursor: pointer"
      >domain_disabled</span
    >
    <span
      #tooltip3="matTooltip"
      matTooltip="Acciones pendientes Wilson"
      *ngIf="pendienteswilson && pendienteswilson >= 1"
      class="material-icons"
      style="margin-right: 20px; cursor: pointer; color: red"
      [matBadge]="pendienteswilson"
      [matBadgeOverlap]="true"
      matBadgeSize="medium"
      [matBadgeHidden]="pendienteswilson == 0"
      matBadgeColor="warn"
      (click)="abrewilson()"
      >pending_actions</span
    >
    <span>{{ identity.numero }} {{ identity.nombre }} </span>
    <button [matMenuTriggerFor]="menu" class="botontransparente">
      <mat-icon>settings</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button
        *ngIf="identity.role != 'cliente'"
        mat-menu-item
        (click)="enrutar('configuracion')"
      >
        <mat-icon>tune</mat-icon> Configuracion
      </button>
      <button
        *ngIf="identity.role == 'Administrador'"
        mat-menu-item
        (click)="enrutar('gestionusuarios')"
      >
        <mat-icon>manage_accounts</mat-icon> Gestion Usuarios
      </button>
      <button mat-menu-item (click)="enrutar('cliente/' + identity._id)">
        <mat-icon>admin_panel_settings</mat-icon> Mis Datos
      </button>
      <button mat-menu-item (click)="logOut()">
        <mat-icon>logout</mat-icon> Salir
      </button>
    </mat-menu>
  </div>
</div>
<div
  class="barraprincipaltabsapp"
  *ngIf="identity && identity.role == 'cliente'"
>
  <div
    class="tabprincipal"
    [class.active]="activetab == 'carpetas'"
    (click)="tabclick('carpetas')"
  >
    <mat-icon>receipt_long</mat-icon> Mis facturas
  </div>
  <div
    class="tabprincipal"
    [class.active]="activetab == 'archivos'"
    (click)="tabclick('archivos')"
  >
    <mat-icon>folder_shared</mat-icon> Mis Archivos
  </div>
  <div
    class="tabprincipal"
    [class.active]="activetab == 'misenvios'"
    (click)="tabclick('misenvios')"
  >
    <mat-icon class="material-icons-outlined">outbox</mat-icon> Mis Envios
  </div>
</div>
<div
  class="barraprincipaltabsapp"
  *ngIf="identity && identity.role == 'Administrador' && !concertar"
>
  <div
    class="tabprincipal"
    [class.active]="activetab == 'admintab'"
    (click)="tabclick('admintab')"
    style="width: 150px"
  >
    <mat-icon>contact_mail</mat-icon> Administrar
  </div>
  <div
    class="tabprincipal"
    [class.active]="activetab == 'incidencias'"
    (click)="tabclick('incidencias')"
  >
    <mat-icon>warning</mat-icon> Incidencias
  </div>
  <div
    class="tabprincipal"
    [class.active]="activetab == 'mailcorporativo'"
    (click)="tabclick('mailcorporativo')"
    [matBadge]="totalemailsinleer"
    matBadgeOverlap
    matBadgeColor="warn"
    matBadgeSize="large"
    [matBadgeHidden]="totalemailsinleer == 0 || activetab == 'mailcorporativo'"
  >
    <mat-icon>markunread</mat-icon> Email 02601
  </div>
  <div
    class="tabprincipal"
    [class.active]="activetab == 'concertadas'"
    (click)="tabclick('concertadas')"
  >
    <mat-icon>real_estate_agent</mat-icon> Concertadas
  </div>
  <div
    class="tabprincipal"
    [class.active]="activetab == 'mensajeros'"
    (click)="tabclick('mensajeros')"
  >
    <mat-icon>portrait</mat-icon> Mensajeros
  </div>
  <div
    class="tabprincipal"
    [class.active]="activetab == 'vehiculos'"
    (click)="tabclick('vehiculos')"
  >
    <mat-icon>local_shipping</mat-icon> Vehiculos
  </div>
  <div
    class="tabprincipal"
    [class.active]="activetab == 'gestion-envios'"
    (click)="tabclick('gestion-envios')"
  >
    <mat-icon>emoji_transportation</mat-icon>Gestion Envios
  </div>
</div>

<div
  class="barraprincipaltabsapp"
  *ngIf="identity && identity.role == 'operador-incidencias' && !concertar"
>
  <div
    class="tabprincipal"
    [class.active]="activetab == 'incidencias'"
    (click)="tabclick('incidencias')"
  >
    <mat-icon>warning</mat-icon> Incidencias
  </div>
  <div
    class="tabprincipal"
    [class.active]="activetab == 'mailcorporativo'"
    (click)="tabclick('mailcorporativo')"
    [matBadge]="totalemailsinleer"
    matBadgeOverlap
    matBadgeColor="warn"
    matBadgeSize="large"
    [matBadgeHidden]="totalemailsinleer == 0 || activetab == 'mailcorporativo'"
  >
    <mat-icon>markunread</mat-icon> Email 02601
  </div>
  <div
    class="tabprincipal"
    [class.active]="activetab == 'concertadas'"
    (click)="tabclick('concertadas')"
  >
    <mat-icon>real_estate_agent</mat-icon> Concertadas
  </div>
</div>

<div
  class="barraprincipaltabsapp"
  *ngIf="identity && identity.role == 'operador-plus' && !concertar"
>
  <div
    class="tabprincipal"
    [class.active]="activetab == 'mailcorporativo'"
    (click)="tabclick('mailcorporativo')"
    [matBadge]="totalemailsinleer"
    matBadgeOverlap
    matBadgeColor="warn"
    matBadgeSize="large"
    [matBadgeHidden]="totalemailsinleer == 0 || activetab == 'mailcorporativo'"
  >
    <mat-icon>markunread</mat-icon> Email 02601
  </div>
  <div
    class="tabprincipal"
    [class.active]="activetab == 'concertadas'"
    (click)="tabclick('concertadas')"
  >
    <mat-icon>real_estate_agent</mat-icon> Concertadas
  </div>
  <div
    class="tabprincipal"
    [class.active]="activetab == 'gestion-envios'"
    (click)="tabclick('gestion-envios')"
  >
    <mat-icon>emoji_transportation</mat-icon>Gestion Envios
  </div>
  <div
    class="tabprincipal"
    [class.active]="activetab == 'incidencias'"
    (click)="tabclick('incidencias')"
  >
    <mat-icon>warning</mat-icon> Incidencias
  </div>
</div>

<div
  class="barraprincipaltabsapp"
  *ngIf="identity && identity.role == 'operador' && !concertar"
>
  <div
    class="tabprincipal"
    [class.active]="activetab == 'mailcorporativo'"
    (click)="tabclick('mailcorporativo')"
    [matBadge]="totalemailsinleer"
    matBadgeOverlap
    matBadgeColor="warn"
    matBadgeSize="large"
    [matBadgeHidden]="totalemailsinleer == 0 || activetab == 'mailcorporativo'"
  >
    <mat-icon>markunread</mat-icon> Email 02601
  </div>
  <div
    class="tabprincipal"
    [class.active]="activetab == 'concertadas'"
    (click)="tabclick('concertadas')"
  >
    <mat-icon>real_estate_agent</mat-icon> Concertadas
  </div>
</div>

<div
  class="barraprincipaltabsapp"
  *ngIf="identity && identity.role == 'comercial' && !concertar"
>
  <div
    class="tabprincipal"
    [class.active]="activetab == 'comercial'"
    (click)="comercialclick('comercial')"
  >
    <mat-icon>switch_account</mat-icon> Comercial
  </div>
</div>

<div
  class="barraprincipaltabsapp"
  *ngIf="identity && identity.role == 'operador-admin' && !concertar"
>
  <div
    class="tabprincipal"
    [class.active]="activetab == 'mailcorporativo'"
    (click)="tabclick('mailcorporativo')"
    [matBadge]="totalemailsinleer"
    matBadgeOverlap
    matBadgeColor="warn"
    matBadgeSize="large"
    [matBadgeHidden]="totalemailsinleer == 0 || activetab == 'mailcorporativo'"
  >
    <mat-icon>markunread</mat-icon> Email 02601
  </div>
  <div
    class="tabprincipal"
    [class.active]="activetab == 'concertadas'"
    (click)="tabclick('concertadas')"
  >
    <mat-icon>real_estate_agent</mat-icon> Concertadas
  </div>
  <div
    class="tabprincipal"
    [class.active]="activetab == 'mensajeros'"
    (click)="tabclick('mensajeros')"
  >
    <mat-icon>portrait</mat-icon> Mensajeros
  </div>
  <div
    class="tabprincipal"
    [class.active]="activetab == 'vehiculos'"
    (click)="tabclick('vehiculos')"
  >
    <mat-icon>local_shipping</mat-icon> Vehiculos
  </div>
  <div
    class="tabprincipal"
    [class.active]="activetab == 'gestion-envios'"
    (click)="tabclick('gestion-envios')"
  >
    <mat-icon>emoji_transportation</mat-icon>Gestion Envios
  </div>
</div>
<router-outlet></router-outlet>
<div
  *ngIf="identity && identity.role != 'cliente'"
  cdkDrag
  class="example-box"
  [ngClass]="{ escondido: chat == false }"
>
  <div class="example-cerrar">
    <div class="example-handle" cdkDragHandle matTooltip="mover">
      <svg width="24px" fill="currentColor" viewBox="0 0 24 24">
        <path
          d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z"
        ></path>
        <path d="M0 0h24v24H0z" fill="none"></path>
      </svg>
    </div>
    <button
      *ngIf="identity && identity.role == 'Administrador' && !config"
      mat-icon-button
      (click)="config = true"
      matTooltip="configuracion"
    >
      <mat-icon>tune</mat-icon>
    </button>
    <button
      *ngIf="identity && identity.role == 'Administrador' && config"
      mat-icon-button
      (click)="config = false"
      matTooltip="volver a chat"
    >
      <mat-icon>settings_backup_restore</mat-icon>
    </button>
    <button mat-icon-button (click)="cierrachat()" matTooltip="cerrar chat">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <mensajeria
    #MensajeriaComponent
    [abierto]="chat"
    [config]="config"
    [mensajesnuevos]="mensajes"
    (nuevosmensajes)="getnuevosmensajes($event)"
    (abrenotificacion)="abrechat($event)"
  >
  </mensajeria>
</div>
