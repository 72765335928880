export class Tarjeta{
    constructor(
        public _id: string,
        public caducidad: string,
        public nombre: string,
        public matricula: string,
        public numero: string,
        public mensajero: string,
        public notas: string
    ){}
}	