<div class="contenidoincidencias">
	<div class="barraherramientas">
		<div *ngIf="vista == 'vehiculos'" class="botonherramientas fondooscuro" matTooltip="Nuevo Vehiculo" (click)="abrenuevovehiculo()">
			<span class="material-icons">commute</span>
		</div>
		<div *ngIf="vista != 'vehiculos'" class="botonherramientas fondooscuro" matTooltip="Volver a Vehiculos" (click)="togglevista('vehiculos');getvehiculos()">
			<span class="material-icons">directions_car</span>
		</div>
		<div class="botonherramientas fondooscuro" matTooltip="Gastos" (click)="togglevista('gastos')">
			<span class="material-icons">paid</span>
		</div>
		<div class="botonherramientas fondooscuro" matTooltip="Tarjetas" (click)="togglevista('tarjetas')">
			<span class="material-icons">credit_card</span>
		</div>
		<div *ngIf="vista == 'vehiculos' || vista == 'baja'" class="botonherramientas fondooscuro" matTooltip="Vehiculos de baja" (click)="getvehiculosbaja();togglevista('baja')">
			<span class="material-icons">no_transfer</span>
		</div>
	</div>
	<div>
		<div *ngIf="vista == 'gastos'">
			<gastos></gastos>
		</div>
		<div *ngIf="vista == 'tarjetas'">
			<tarjetas></tarjetas>
		</div>
		<div *ngIf="vista == 'vehiculos' || vista == 'baja'" class="tablavehiculos">
			<table mat-table *ngIf="dataSource" [dataSource]="dataSource" matSort>
			<ng-container matColumnDef="matricula">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>
					<mat-form-field appearance="outline" style="text-align:center;max-width:110px;font-size:7pt">
					<mat-label style="color:black;"><b>Matricula</b></mat-label>
					<input matInput type="text" [(ngModel)]="filtros['matricula']" style="padding:1px;color:black;">
					<button mat-button *ngIf="filtros['matricula']" matSuffix mat-icon-button aria-label="Clear" (click)="filtros['matricula']=''">
						<mat-icon>close</mat-icon>
					</button>
					</mat-form-field>
				</th>
				<td mat-cell *matCellDef="let element"> {{element.matricula}} </td>
			</ng-container>
			<ng-container matColumnDef="marca">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>
					<mat-card style="width:100%;font-size:10pt;height:15pt;padding:2px;margin:0px;">
						Marca
					</mat-card>
				</th>
				<td mat-cell *matCellDef="let element"> {{element.marca}} </td>
			</ng-container>

			<ng-container matColumnDef="modelo">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>
					<mat-card style="width:100%;font-size:10pt;height:15pt;padding:2px;margin:0px;">
						Modelo
					</mat-card>
				</th>
				<td mat-cell *matCellDef="let element"> {{element.modelo}} </td>
			</ng-container>
			<ng-container matColumnDef="fechaalta">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>
					<mat-card style="width:100%;font-size:10pt;height:15pt;padding:2px;margin:0px;">
						Fecha de Alta
					</mat-card>
				</th>
				<td mat-cell *matCellDef="let element"> {{element.fechaalta}} </td>
			</ng-container>
			<ng-container matColumnDef="notas">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>
					<mat-card style="width:100%;font-size:10pt;height:15pt;padding:2px;margin:0px;">
						Notas
					</mat-card>
				</th>
				<td mat-cell *matCellDef="let element"> {{element.notas}} </td>
			</ng-container>
		
			<tr mat-header-row *matHeaderRowDef="totalcolumns; sticky:true"></tr>
			<tr mat-row (click)="seleccionar(row)" (dblclick)="abrevehiculo(row)" class="celdas" [class.seleccionada]="seleccion == row" *matRowDef="let row; columns: totalcolumns;">
			</tr>
			</table>
		</div>	
	</div>	
</div>
	<!--<div style="display:inline-flex;align-items:center;">
		<mat-paginator style="color:black;" showFirstLastButtons [length]="mensajeros.length" [pageSize]="cliente.options.limit" [pageSizeOptions]="[5, 10, 25, 50, 100]">
		</mat-paginator>
	</div>-->
