<div class="formulario">
		<h3 class="centrado">{{title}}</h3>

			<form #olvidaForm="ngForm">
					<p *ngIf="status == 'error' || status == 'normal'">
						<label>Numero de Abonado</label>
						<input type="text" name="Nombre" #nombre="ngModel" [(ngModel)]="numero" class="form-control" required />
					</p>
					<p *ngIf="status == 'error' || status == 'normal'">
						<label>E-mail</label>
						<input type="text" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" name="email" #email="ngModel" [(ngModel)]="mail" class="form-control" required />
					</p>
					<div class="alert alert-danger" *ngIf="status == 'error'">
						el cliente y/o el email no son validos
					</div>
					<div class="alert alert-danger" *ngIf="status == 'normal' && mensaje">
						{{mensaje}}
					</div>
					<div class="alert alert-success" *ngIf="status == 'success'">
						{{mensaje}}<br>
						se ha enviado un email a: {{mail}}, con un enlace para cambiar la contraseña
					</div>
					<input *ngIf="status == 'error' || status == 'normal'" type="submit" value="enviar solicitud" class="btn btn-success but" [disabled]="!olvidaForm.form.valid" (click)="onSubmit()"/>
		</form>
	</div>

