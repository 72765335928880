<div *ngIf="mensajero" style="display:inline-flex;margin-left:20px;">
	<div>
		<mat-icon class="material-icons-outlined fondooscuro" style="margin-right:4px;cursor:pointer;" (click)="enrutar('mensajeros')">arrow_back</mat-icon>
	</div>	
	<div style="width:100%;display:inline-flex;margin-left:30px;">
		<b><span style="width:100%;font-size:12pt;">{{mensajero.numero}} {{mensajero.nombre}}</span></b>
	</div>
	<div style="margin-right:30px;margin-left: 30px;">
		<mat-slide-toggle [(ngModel)]="estadocobrado" (change)="checkcobrado($event.checked)" color="warn">
			<span *ngIf="estadocobrado == true">Cobrado</span>
			<span *ngIf="estadocobrado == false">No Cobrado</span>
		</mat-slide-toggle>
	</div>	
	<div>
		<mat-form-field style="max-height:100px;width:500px;font-size:9pt;">
			<mat-label>Notas Mensajero</mat-label>
			<textarea matInput [(ngModel)]="mensajero['observaciones']" rows="2" placeholder="observaciones"></textarea>
		<button mat-button matSuffix mat-icon-button style="font-size: 20pt;" (click)="actualizacliente();aviso('nota guardada')">
		  <mat-icon>save</mat-icon>
		</button>
		</mat-form-field>
	</div>	
</div>
<div>
	<mat-tab-group>
	  <mat-tab>
	    <ng-template mat-tab-label>
			<mat-icon class="example-tab-icon" style="margin-right:4px;">
				payments
			</mat-icon> Cobros
	    </ng-template>
		<div>
			<cobromensajero></cobromensajero>
		</div>
	  </mat-tab>

	  <mat-tab>
	    <ng-template mat-tab-label>
	      <mat-icon class="example-tab-icon">list</mat-icon>
	      Direcciones
	    </ng-template>
		<direccionesmensajero></direccionesmensajero>
	  </mat-tab>

	  <mat-tab>
	    <ng-template mat-tab-label>
	      <mat-icon class="example-tab-icon">request_quote</mat-icon>
	      Deudas
	    </ng-template>
	    <deudas></deudas>
	  </mat-tab>
	  <mat-tab>
	    <ng-template mat-tab-label>
	      <mat-icon class="example-tab-icon">account_box</mat-icon>
	      Datos
	    </ng-template>
		<div *ngIf="mensajero">
	    	<datoscliente [cliente]="mensajero"></datoscliente>
		</div>	
	  </mat-tab>
	</mat-tab-group>
</div>
