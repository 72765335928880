import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ClienteService } from '../../servicios/cliente.service';


@Component({
  selector: 'app-contacto',
  templateUrl: './contacto.component.html',
  styleUrls: ['./contacto.component.css'],
  providers: [ClienteService]
})
export class ContactoComponent implements OnInit {
	  public title: String;
 	  public status: String;
	  public email;
	  public mensaje;
	  public asunto;
	  public departamento;
	  public emailrespuesta;

  constructor(
  	private _route: ActivatedRoute,
    private _clienteService: ClienteService
  	) { }

  ngOnInit(): void {
  	this._route.params.subscribe(params=>{
          if(params.email){
              this.email = params.email;
          }else{
              alert("ha ocurrido un error en la pagina");
          }

          if(params.departamento){
              this.departamento = params.departamento;
          }else{
              alert("ha ocurrido un error en la pagina");
          }

          this.title = "Va a contactar con el departamento de: " + this.departamento
    });      

  }


  onSubmit(form){
  	var message = "<br><br> solicitan respuesta al siguiente email: <br> <a href=" + this.emailrespuesta + ">" + this.emailrespuesta + "</a>";

  	this.mensaje = this.mensaje + message;

  	this.enviamailcontacto();

  	form.reset();

    this.status = 'success';

  }


  enviamailcontacto(){
  	var tmpId = "0";
  	
  	this._clienteService.avisomail(tmpId, this.email, this.mensaje, this.asunto).subscribe(
            response =>{
                console.log(response);
              },
            error =>{
              console.log(<any>error);
            }  
          );

  }

}


