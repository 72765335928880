import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Office365Service } from '../../servicios/office365.service';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { AppService } from '../../servicios/app.service';
import { saveAs } from "file-saver";
import { EmailsService } from '../../servicios/emails.service';
import { ClienteService } from '../../servicios/cliente.service';
import { AlbaranComponent } from '../albaran/albaran.component';
import { MatDialog } from '@angular/material/dialog';
import { IncidenciasService } from '../../servicios/incidencias.service';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition, MatSnackBarConfig } from '@angular/material/snack-bar'; 
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import * as Editor from '../../ck5editor/ckeditor.js';

@Component({
  selector: 'app-mailcorporativo',
  templateUrl: './mailcorporativo.component.html',
  styleUrls: ['./mailcorporativo.component.css'],
  providers: [Office365Service, EmailsService, IncidenciasService, AppService, ClienteService]
})

export class MailcorporativoComponent implements OnInit{
  
  public identity;
  public carpetasmail;
  public dirmail;
  public dirmailraiz;
  public dircarpetasmail;
  public mensajesactuales;
  public carpetaseleccionada;
  public mensajeseleccionado;
  public carpetasUsuario;
  public dirmensajes;
  public mensajesSeleccionados;
  public categoryselected;
  public contadorerrores;
  public escribirRespuesta;
  public textoRespuesta;
  public datosRespuesta;
  public mensajeRespuesta;
  public filesToUload;
  public listaAntiguos;
  public cuadroalbaran;
  public alb;
  public carpetasinteraccion;
  public usuarios;
  public myControl = new FormControl();
  public filteredOptions: Observable<any[]>;
  public masdestinatarios;
  public listacategorias;
  public contactos;
  public htmlanterior;
  public mensajehover;
  public textoabuscar;
  public siguientesmensajes;
  public mensajesbuscados;
  public load;
  public mensajenuevo;
  public destinatarionuevo = new Array();
  public separatorKeysCodes: number[] = [ENTER, COMMA];
  public direccionaleiminar;
  public Editor = Editor;
  public totaldestinatarios;
  public mensajeEliminado;
  @ViewChild('destinatariosinput') destinatariosinput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;
  @ViewChild('cuerpomensaje') cuerpomensaje: ElementRef;


  constructor(
    private _office365Service: Office365Service,
    private _emailsService: EmailsService,
    public ventanaAlbaran: MatDialog,
    private _incidenciasservice: IncidenciasService,
    private _snackBar: MatSnackBar,
    private _appservice: AppService,
    private _clienteservice: ClienteService
  ) {
    this.identity = this._appservice.getIdentity();
    this.getcontactos();
    this.getcategorias();
   }

  ngOnInit(): void {
    this.masdestinatarios = false;
    this.load = true;
    this.cuadroalbaran = false;
    this.escribirRespuesta = undefined;
    this.contadorerrores = 0;
   	this.carpetasmail = new Array();
    this.carpetasinteraccion = new Array();
    this.mensajesSeleccionados = new Array();
  	this.dirmailraiz = "https://graph.microsoft.com/v1.0/me/mailFolders";
    this.dircarpetasmail = "https://graph.microsoft.com/v1.0/me/mailFolders";
    this.dirmail = "https://graph.microsoft.com/v1.0/me/mailFolders/inBox";
    this.dirmensajes = "https://graph.microsoft.com/v1.0/me/messages";
    this.pedircarpetas();
    setTimeout(()=>{
      this.pedircarpetasinteraccion();
    }, 3000);

    setTimeout(()=>{
      this.actualizar();
    }, 300000);
  }

  filtro(name: string){
    const filterValue = name.toLowerCase();
    return this.usuarios.filter(option => option.toLowerCase().indexOf(filterValue) === 0);
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.destinatariosinput.nativeElement.value = '';
    this.myControl.setValue(null);
    this.destinatarionuevo.push(event.option.value);
    this.agregadestinatario(event.option.value);
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    if ((value || '').trim()) {
      this.destinatarionuevo.push(value.trim());
      this.agregadestinatario(value.trim());
    }
    if (input) {
      input.value = '';
    }
    this.myControl.setValue(null);
  }

  remove(destinatario: string): void {
    const index = this.destinatarionuevo.indexOf(destinatario);
    if (index >= 0) {
      this.destinatarionuevo.splice(index, 1);
      this.eliminadestinatario(destinatario);
    }
  }

  agregadestinatario(direccion){
    this.mensajenuevo.message.toRecipients.push(
      {
        emailAddress:{
          address:direccion
        }
      }
    );
    this.guardacontacto(direccion);
  }

  eliminadestinatario(direccion){
    this.mensajenuevo.message.toRecipients = this.mensajenuevo.message.toRecipients.filter(dest=>{
      if(dest.emailAddress.address != direccion) return true;
    });
  }

  aviso(texto, subtexto) {
    this._snackBar.open(texto,subtexto,{
      duration: 4000,
      horizontalPosition: "center",
      verticalPosition: "top",
    });
  }


  togglecarpetaexpandida(carpeta){
    if(carpeta['expandida'] && carpeta['expandida'] == true){
      carpeta['expandida'] = false;
    }else{
      carpeta['expandida'] = true;
    }
  }

  vermasdestinatarios(){
    if(this.masdestinatarios == false){
      this.masdestinatarios = true;
    }else{
      this.masdestinatarios = false;
    }
  }

  getcategorias(){
    this._office365Service.getcategorys().subscribe(response=>{
      this.listacategorias = response.value.map((categoria)=>{
        switch(categoria.color){
          case 'preset0':
            categoria['mostrarcolor'] = '#f70000';
            categoria['colorfondo'] = 'rgba(132, 0, 0, 0.31)';
            break;
          case 'preset3':
            categoria['mostrarcolor'] = '#f7c500';
            categoria['colorfondo'] = 'rgba(255, 216, 6, 0.31)'
            break;
          case 'preset7':
            categoria['mostrarcolor'] = '#0800f7';
            categoria['colorfondo'] = 'rgba(2, 42, 255, 0.36)';
            break;
          case 'preset4':
            categoria['mostrarcolor'] = '#169504';
            categoria['colorfondo'] = 'rgba(60, 255, 6, 0.31)'
            break;
          case 'preset1':
            categoria['mostrarcolor'] = '#ff7800';
            categoria['colorfondo'] = 'rgba(255, 123, 6, 0.31)'
            break;
          case 'preset8':
            categoria['mostrarcolor'] = '#8700f7';
            categoria['colorfondo'] = 'rgba(134, 37, 123, 0.42)'
            break;
        default:
          break;
        }
        return categoria;   
      });
    });
  }

  pedircarpetas(){
    var dir = this.dircarpetasmail + '?$top=100&$expand=childFolders';
    this._office365Service.solicitaremails(dir).subscribe(
      response=>{
        //console.log(response.value);
        this.carpetasmail = response.value.filter(carpeta=>this.identity.options.carpetasmail.includes(carpeta.id));
      },
      error=>{
        console.log(<any>error);
      });
  }

  pedircarpetasinteraccion(){
    var dir = this.dircarpetasmail + '?$top=100&$expand=childFolders';
    this._office365Service.solicitaremails(dir).subscribe(
      response=>{
        this.carpetasinteraccion = response.value.filter(carpeta=>this.identity.options.carpetasinteraccion.includes(carpeta.id));
      },
      error=>{
        console.log(<any>error);
      });
  }

  mensajes(carpetaid){
    this.dirmail = this.dirmailraiz + '/' + carpetaid + '/messages?$top=100';
    this._office365Service.solicitaremails(this.dirmail).subscribe(
    response =>{
      if(response.value.length >= 1){
        this.mensajesactuales = response.value;
        this.mensajeseleccionado = this.mensajesactuales[0];
      }else{
        this.mensajesactuales = undefined;
        this.mensajeseleccionado = undefined;
      }
      this.mensajesbuscados = undefined;
      this.mensajesSeleccionados = new Array();
      if(response['@odata.nextLink']){
        this.siguientesmensajes = response['@odata.nextLink'];
      }else{
        this.siguientesmensajes = undefined;
      }
      this.cancelarRespuesta();
      this.load = true;  
      },
    error =>{
      console.log(<any>error);
      this.load = true;
    });
  }

  seleccionarmensaje(mensaje){
    //console.log(mensaje);
    this.masdestinatarios = false;
    this.cancelarRespuesta();
  	this.mensajeseleccionado = mensaje;
    this.scrolLarriba();
    this.setTotaldestinatarios();
    this.veradjuntos(this.mensajeseleccionado);
  }

  setTotaldestinatarios(){
    this.totaldestinatarios = new Array();
    this.totaldestinatarios = this.mensajeseleccionado.toRecipients.concat(this.mensajeseleccionado.ccRecipients);
  }

  actualizaCarpeta(carpeta){
    this.carpetaseleccionada = carpeta;
    this.cancelarRespuesta();
    this.load = false;
    var dir = this.dirmailraiz + '/' + carpeta.id + '?$expand=childFolders';
    this._office365Service.solicitaremails(dir).subscribe(
      response=>{
          this.carpetasmail = this.carpetasmail.map((carpeta)=>{
            var folder = carpeta;
            if(carpeta.id == response.id){
              folder.unreadItemCount = response.unreadItemCount;
              folder.totalItemCount = response.totalItemCount;
            } 
            return folder;
          });    
          this.mensajes(carpeta.id);
         },
      error=>{
        console.log(<any>error);
        this.load = true;
      }  
    );
  }

  actualizacarpetas(){
    var dir = this.dircarpetasmail + '?$top=100&$expand=childFolders';
    this._office365Service.solicitaremails(dir).subscribe(
      response=>{
        this.carpetasmail = response.value.filter(carpeta=>this.identity.options.carpetasmail.includes(carpeta.id)).map((carpeta)=>{
          let folder = carpeta;
          if(folder.childFolderCount >=1){
            let expandida = this.carpetasmail.find(fldr=>fldr.id == folder.id);
            if(expandida && expandida['expandida']) folder['expandida'] = expandida['expandida'];
          }
          return folder;  
        });
        this._appservice.refreshTokenOffice();
      },
      error=>{
        console.log(<any>error);
      });
  }

  marcarcomoleido(mensaje){
    if(mensaje.isRead == false){
      mensaje.isRead = true;
      var dir = this.dirmensajes + '/' + mensaje.id;
      var objeto = {}
      objeto['id'] = mensaje.id;
      objeto['isRead'] = true;
      return this._office365Service.actualizarobjeto(dir, objeto).subscribe(
        response=>{
           return true;
           },
        error=>{
          console.log(<any>error);
          return false;
          
        });
    }  
  }

  leidotoggle(mensaje){
    var dir = this.dirmensajes + '/' + mensaje.id;
    var objeto = {}
    objeto['id'] = mensaje.id;
    if(mensaje.isRead == false){
      mensaje.isRead = true
      objeto['isRead'] = true;
    }else{
      mensaje.isRead = false
      objeto['isRead'] = false;
    }
    this._office365Service.actualizarobjeto(dir, objeto).subscribe(
      response=>{
        this.actualizacarpetas();
        },
      error=>{
        console.log(<any>error);
      }  
    );
  }

  abrirmensaje(link, mensaje){
    window.open(link);
    this.marcarcomoleido(mensaje);
  }

  veradjuntos(mensaje){
    var dir = this.dirmensajes + '/' + mensaje.id + '/attachments';
    this._office365Service.solicitaremails(dir).subscribe(
      response =>{
        this.mensajeseleccionado['adjuntos'] = response.value.filter(adj=>adj.isInline === false);
        var enlinea = response.value.filter(adj=>adj.isInline === true);
        enlinea.forEach((adjunto)=>{
         
        var cuerpo = this.mensajeseleccionado.body.content.replace('src="cid:' + adjunto.contentId + '"', 'src="data:' + adjunto.contentType + ';base64,' + adjunto.contentBytes + '"');
        this.mensajeseleccionado.body.content = cuerpo;
        });
       },
      error =>{
        console.log(<any>error);
      });
  }

  getadjuntos(mensaje, adjunto){
    var dir = this.dirmensajes + '/' + mensaje.id + '/attachments/' + adjunto.id + '/$value';
    this._office365Service.solicitaAdjunto(dir).subscribe(
      response =>{
          saveAs(response, adjunto.name);
       },
      error =>{
          console.log(<any>error);
      });
  }

  checkmensaje(check, mensaje){
    if(check == true){
      this.mensajesSeleccionados.push(mensaje);
    }else{
      this.mensajesSeleccionados.splice(this.mensajesSeleccionados.indexOf(mensaje.id), 1);
    };
  }

  crearmensajenuevo(){
    var firma = this.identity.options.firmamail ? this.identity.options.firmamail : '';
    this.mensajenuevo = {
      message: {
        subject: '',
        body: {
          contentType: "HTML",
          content: '<br><br>' + firma
        },
        toRecipients: [],
        sender:{
          emailAddress:{
                address:"02601@grupomrw.com",
                name:"Mensasur Getafe"
            }
        }
      }
    }
  }

  crearRespuesta(mensaje){
    this.crearmensajenuevo();
    var firma = this.identity.options.firmamail ? this.identity.options.firmamail : '';
    var de = mensaje.from? mensaje.from.emailAddress? mensaje.from.emailAddress.address : '' : '';
    var para = mensaje.toRecipients? mensaje.toRecipients[0]? '<br> Para: ' + mensaje.toRecipients[0].emailAddress.address : mensaje.ccRecipients[0]? '<br> Para: ' + mensaje.ccRecipients[0].emailAddress.address: mensaje.bccRecipients[0]? '<br> Para: ' + mensaje.bccRecipients[0].emailAddress.address: '' : '';
    this.mensajenuevo.message.subject = 'RE: ' + mensaje.subject;
    this.mensajenuevo.message.body.content = '<br><br>' + firma + '<br>' + '<hr><div style="font-size:8pt"><br> De: ' + de + '<br> Enviado: ' + 
                                              mensaje.sentDateTime + para + '<br> Asunto: ' + mensaje.subject + '</div><br><div>' + mensaje.body.content + '</div>';
    if(mensaje.replyTo && mensaje.replyTo.length >=1) this.mensajenuevo.message.toRecipients = mensaje.replyTo;
    if(!mensaje.replyTo || (mensaje.replyTo && mensaje.replyTo.length <=0)){
      this.mensajenuevo.message.toRecipients[0] = mensaje.sender.emailAddress.address? mensaje.sender : mensaje.from
    }
    this.destinatarionuevo = this.mensajenuevo.message.toRecipients.map((destinatario)=>{
      return destinatario.emailAddress.address;
    });
    if(mensaje.adjuntos){
      this.mensajenuevo.message['attachments'] = mensaje.adjuntos;
    }
    
  }

  crearRespuestaTodos(mensaje){
    this.crearmensajenuevo();
    var firma = this.identity.options.firmamail ? this.identity.options.firmamail : '';
    var de = mensaje.from? mensaje.from.emailAddress? mensaje.from.emailAddress.address : '' : '';
    var para = mensaje.toRecipients? mensaje.toRecipients[0]? '<br> Para: ' + mensaje.toRecipients[0].emailAddress.address : mensaje.ccRecipients[0]? '<br> Para: ' + mensaje.ccRecipients[0].emailAddress.address: mensaje.bccRecipients[0]? '<br> Para: ' + mensaje.bccRecipients[0].emailAddress.address: '' : '';
    this.mensajenuevo.message.subject = 'REE: ' + mensaje.subject;
    this.mensajenuevo.message.body.content = '<br><br>' + firma + '<br>' + '<hr><div style="font-size:8pt"><br> De: ' + de + '<br> Enviado: ' + 
                                              mensaje.sentDateTime + para + '<br> Asunto: ' + mensaje.subject + '</div><br><div>' + mensaje.body.content + '</div>';
    this.mensajenuevo.message.toRecipients = mensaje.toRecipients.concat(mensaje.ccRecipients.concat(mensaje.bccRecipients));
    mensaje.from? this.mensajenuevo.message.toRecipients.push(mensaje.from) : this.mensajenuevo.message.toRecipients.push(mensaje.sender)
    this.destinatarionuevo = this.mensajenuevo.message.toRecipients.map((destinatario)=>{
      return destinatario.emailAddress.address;
    });
    if(mensaje.adjuntos){
      this.mensajenuevo.message['attachments'] = mensaje.adjuntos;
    }
    
  }

  crearReenviar(mensaje){
    this.crearmensajenuevo();
    var firma = this.identity.options.firmamail ? this.identity.options.firmamail : '';
    var de = mensaje.from? mensaje.from.emailAddress? mensaje.from.emailAddress.address : '' : '';
    var para = mensaje.toRecipients? mensaje.toRecipients[0]? '<br> Para: ' + mensaje.toRecipients[0].emailAddress.address : mensaje.ccRecipients[0]? '<br> Para: ' + mensaje.ccRecipients[0].emailAddress.address: mensaje.bccRecipients[0]? '<br> Para: ' + mensaje.bccRecipients[0].emailAddress.address: '' : '';
    this.mensajenuevo.message.subject = 'RV: ' + mensaje.subject;
    this.mensajenuevo.message.body.content = '<br><br>' + firma + '<br>' + '<hr><div style="font-size:8pt"><br> De: ' + de + '<br> Enviado: ' + 
                                              mensaje.sentDateTime + para + '<br> Asunto: ' + mensaje.subject + '</div><br><div>' + mensaje.body.content + '</div>';
    if(mensaje.adjuntos){
      this.mensajenuevo.message['attachments'] = mensaje.adjuntos;
    }
  }


  enviarmensaje(){
    var dir = "https://graph.microsoft.com/v1.0/me/sendMail";
      this._office365Service.enviarRespuesta(dir, this.mensajenuevo).subscribe(
        response=>{
          if(response.status != 202){
            this.aviso('ha ocurrido un error al enviar el mensaje', 'error');
          }else{
            this.aviso('Mensaje Enviado', 'Ok');
            if(this.mensajenuevo.message.toRecipients[0].emailAddress.address == 'exportacion@seracargo.com'){
              this.copiarislas(this.mensajenuevo.message);
              this.mensajenuevo = undefined;
            }else{
              this.mensajenuevo = undefined;
            }  
          }
        },
        error=>{
          this.aviso('ha ocurrido un error al enviar el mensaje', 'error');
          console.log(<any>error);
        }  
      );
  }

  cancelarRespuesta(){
    this.mensajenuevo = undefined;
    this.destinatarionuevo = new Array();

  }

  borrarmensaje(mensaje){
      this.marcarcomoleido(mensaje);
      this.mensajeEliminado = mensaje;
      var dir = this.dirmensajes + '/' + mensaje.id + '/move';
      var cuerpo = {destinationId: 'deletedItems'};
      this._office365Service.movermensaje(dir, cuerpo).subscribe(
        response=>{
            var indice = this.mensajesactuales.findIndex(ident=>ident.id == mensaje.id);
            this.mensajesactuales.splice(indice, 1);
            this.aviso('mensaje eliminado', 'ok');
            if(indice == 0){
              this.mensajeseleccionado = this.mensajesactuales[indice];
            }else{
              this.mensajeseleccionado = this.mensajesactuales[indice -1];
            }  
            this.actualizacarpetas();
          },
        error=>{
          console.log(<any>error);
        }  
      );
  }

  recuperamensaje(){
     delete this.mensajeEliminado["@odata.context"];
     delete this.mensajeEliminado["@odata.etag"];
     delete this.mensajeEliminado["id"];
     delete this.mensajeEliminado["createdDateTime"];
     delete this.mensajeEliminado["lastModifiedDateTime"];
     delete this.mensajeEliminado["changeKey"];
     this.mensajeEliminado['attachments'] = this.mensajeEliminado.adjuntos;
     delete this.mensajeEliminado.adjuntos;
     this.mensajeEliminado.isDraft = false;
     this._office365Service.recuperamensaje(this.mensajeEliminado, this.carpetaseleccionada.id).subscribe(
        response=>{
          if(response){
            this.mensajeEliminado = undefined;
            this.seleccionarmensaje(response);
            this.actualizaCarpeta(this.carpetaseleccionada);
          }  
        },
        error=>{
          console.log(error);
        }
     );
  }

  borrarmensajes(msg, contador){
      this.marcarcomoleido(msg);
      var dir = this.dirmensajes + '/' + msg.id + '/move';
      var cuerpo = {destinationId: 'deletedItems'};
      this._office365Service.movermensaje(dir, cuerpo).subscribe(
        response=>{
        if(contador >= this.mensajesSeleccionados.length -1){
          this.aviso('mensajes eliminados', 'ok');
          this.mensajesSeleccionados = new Array();
          this.actualizaCarpeta(this.carpetaseleccionada);
          
        }else{
          contador++
          this.borrarmensajes(this.mensajesSeleccionados[contador], contador);
        }
      });
}

fileChangeEvent(fileInput: any){
  if(!this.mensajenuevo.message['attachments']){
    this.mensajenuevo.message['attachments'] = new Array();
  }  
    var archivos = <Array<File>>fileInput.target.files;
    for(let file of archivos){
        readFileAsync(file).then((data)=>{return data}).then((a:any)=>{
            let partes = a.split(";");
            let content = partes[1].substring(7);
            let tipo = partes[0].split(':');
            var archivo = {
            '@odata.type': '#microsoft.graph.fileAttachment',
            'contentType': tipo[1],
            'name': file.name,
            'contentBytes': content
            };
            this.mensajenuevo.message.attachments.push(archivo);
        }).catch(err=>{
          console.log(err);
        });  
     }

    function readFileAsync(file) {
      return new Promise((resolve, reject) => {
        let reader = new FileReader();
        reader.onload = () => {
          resolve(reader.result);
        };
        reader.onerror = reject;
        reader.readAsDataURL(file);
      })
    }
}  

eliminarAdjunto(indice){
  this.mensajenuevo.message.attachments.splice(indice, 1);
}

mostrarcuadroalbaran(){
  if(this.cuadroalbaran == false){
    this.cuadroalbaran = true;
  }else{
    this.cuadroalbaran = false;
  }
}

  guardaremail(mail, envio){
    var albaran = envio.trim();
    var email = mail;
      this._incidenciasservice.getincidencia(albaran).subscribe(
        response =>{
          if(response.incidencia){
            var albaranlinkar = response.incidencia;
            this._emailsService.getemails(this.alb).subscribe(
              response=>{
                  var emilios = response.emails;
                  var resultado = emilios.find(emilio => emilio.id == email.id);
                  if(!resultado){
                    this._emailsService.guardaremail(email, albaranlinkar).subscribe(
                      response=>{
                          this.aviso('albaran guardado', 'ok');
                          this.cuadroalbaran = false;
                          this.alb = undefined;
                        },
                      error=>{
                        if(error.error.message){
                           this.aviso('el email ya esta linkado a un albaran', 'ok');
                           this.cuadroalbaran = false;
                           this.alb = undefined;
                        }
                      });
                  }else{
                    this.aviso('el email ya esta linkado a esta incidencia', 'ok');
                    this.cuadroalbaran = false;
                    this.alb = undefined;
                  }
                },
              error=>{
                console.log(<any>error);
              });
          }else{
            this.aviso('el numero de albaran no corresponde con una incidencia', 'error');
            this.cuadroalbaran = false;
            this.alb = undefined;
          }
        },
        error=>{
          console.log(<any>error);
        });    
  }
  

  guardaremailyver(email, envio){
   var albaran = envio.trim();
      this._incidenciasservice.getincidencia(albaran).subscribe(
        response =>{
          if(response.incidencia){
            var albaranlinkar = response.incidencia;
            this._emailsService.getemails(this.alb).subscribe(
              response=>{
                  var emilios = response.emails;
                  var resultado = emilios.find(emilio => emilio.id == email.id);
                  if(!resultado){
                    this._emailsService.guardaremail(email, albaranlinkar).subscribe(
                      response=>{
                          this.abrealbaran(albaranlinkar);
                          this.cuadroalbaran = false;
                          this.alb = undefined;
                        },
                      error=>{
                        console.log(<any>error);
                      });
                  }else{
                    this.aviso('el email ya esta linkado a esta incidencia', 'ok');
                    this.cuadroalbaran = false;
                    this.alb = undefined;
                  }
                },
              error=>{
                console.log(<any>error);
              });
          }else{
            this.aviso('el numero de albaran no corresponde con una incidencia', 'error');
            this.cuadroalbaran = false;
            this.alb = undefined;
          }
        },
        error=>{
          console.log(<any>error);
        });    
  }

   abrealbaran(incidencia){
      
    this.ventanaAlbaran.open(AlbaranComponent, {
      width: '85%',
      height: '85%',
      data: incidencia});
   }

   movermensaje(mensaje, carpeta){
      var dir = this.dirmensajes + '/' + mensaje.id + '/move';
      var cuerpo = {destinationId: carpeta.id};
      this._office365Service.movermensaje(dir, cuerpo).subscribe(
        response=>{
            this.mensajeseleccionado = undefined;
            var indice = this.mensajesactuales.findIndex(ident=>ident.id == mensaje.id);
            this.mensajesactuales.splice(indice, 1);
            if(indice == 0){
              this.mensajeseleccionado = this.mensajesactuales[indice];
            }else{
              this.mensajeseleccionado = this.mensajesactuales[indice -1];
            }  
            this.actualizacarpetas();

          },
        error=>{
          console.log(<any>error);
        }  
      );
   }

    movermensajes(msg, carpetaid, contador){
      var dir = this.dirmensajes + '/' + msg.id + '/move';
      var cuerpo = {destinationId: carpetaid};
      this._office365Service.movermensaje(dir, cuerpo).subscribe(
        response=>{
        if(contador >= this.mensajesSeleccionados.length -1){
          this.aviso('mensajes movidos', 'ok');
          this.mensajesSeleccionados = new Array();
          this.actualizaCarpeta(this.carpetaseleccionada);
          this.actualizacarpetas();
        }else{
          contador++
          this.movermensajes(this.mensajesSeleccionados[contador], carpetaid, contador);
        }
      });
    }  
  
  getcontactos(){
    this._emailsService.getlista(this.identity._id).subscribe(
      response=>{
        if(response.lista){
          this.contactos = response.lista;
        }
        this._clienteservice.getcontactos2601().subscribe(
          response=>{
            if(response.contactos){
              var contacts = response.contactos.filter(usr=>{
                if(usr.slice(0,1) == '0'){
                  return true
                }else{
                  return false
                }  
              });
              if(this.contactos){
                this.usuarios = this.contactos.concat(contacts);
              }else{
                this.usuarios = contacts;
              }  
            }
            this.filteredOptions = this.myControl.valueChanges
            .pipe(
              startWith(''),
              map(name => name ? this.filtro(name) : this.usuarios.slice())
            );
          }
        );
      }
    );  
  }

  getcategoriamensaje(mensaje): string{
      if(mensaje.categories[0]){
        var indice = this.listacategorias.findIndex(cat=> cat.displayName == mensaje.categories[0]);
        return this.listacategorias[indice].colorfondo;
      }
  }

  setcategoria(categoria){
    if(this.mensajeseleccionado.categories[0]){
      this.mensajeseleccionado.categories.splice(0,1);
    }else{
      this.mensajeseleccionado.categories[0] = categoria.displayName;
    }
    var objeto = {}
    objeto['id'] = this.mensajeseleccionado.id;
    objeto['categories'] = this.mensajeseleccionado.categories;

    var urlmensaje = this.dirmensajes + '/' + this.mensajeseleccionado.id;
    this._office365Service.actualizarobjeto(urlmensaje, objeto).subscribe(
      response=>{
        console.log(response);
      },
      error=>{
        console.log(<any>error);
      }
    )      
  }

  setcategoriatodos(categoria, mensaje, contador){
      if(mensaje.categories[0]){
        mensaje.categories.splice(0,1);
      }else{
        mensaje.categories[0] = categoria.displayName;
      }
      var objeto = {}
      objeto['id'] = mensaje.id;
      objeto['categories'] = mensaje.categories;
  
      var urlmensaje = this.dirmensajes + '/' + mensaje.id;
      this._office365Service.actualizarobjeto(urlmensaje, objeto).subscribe(
        response=>{
        if(contador >= this.mensajesSeleccionados.length -1){
          this.aviso('categorias actualizadas', 'ok');
          this.mensajesSeleccionados = new Array();
          this.actualizacarpetas();

        }else{
          contador++
          this.setcategoriatodos(categoria, this.mensajesSeleccionados[contador], contador);
        }
      });
  }

  setbandera(){
    if(this.mensajeseleccionado.flag.flagStatus == 'flagged'){
      this.mensajeseleccionado.flag.flagStatus = "notFlagged";
    }else{
      this.mensajeseleccionado.flag.flagStatus = 'flagged';
    }
    var objeto = {}
    objeto['id'] = this.mensajeseleccionado.id;
    objeto['flag'] = {
      flagStatus: this.mensajeseleccionado.flag.flagStatus
    }

    var urlmensaje = this.dirmensajes + '/' + this.mensajeseleccionado.id;
    this._office365Service.actualizarobjeto(urlmensaje, objeto).subscribe(
      response=>{
        
      }
    )
  }

  setbanderatodos(msg, contador){
       if(msg.flag.flagStatus == 'flagged'){
        msg.flag.flagStatus = "notFlagged";
      }else{
        msg.flag.flagStatus = 'flagged';
      }
      var objeto = {}
      objeto['id'] = msg.id;
      objeto['flag'] = {
        flagStatus: msg.flag.flagStatus
      }
      var urlmensaje = this.dirmensajes + '/' + msg.id;
      this._office365Service.actualizarobjeto(urlmensaje, objeto).subscribe(
        response=>{
        if(contador >= this.mensajesSeleccionados.length -1){
          this.aviso('banderas colocadas', 'ok');
          this.mensajesSeleccionados = new Array();
          this.actualizacarpetas();
        }else{
          contador++
          this.setbanderatodos(this.mensajesSeleccionados[contador], contador);
        }
      });
  }

  guardacontacto(contacto){
    if(contacto){
      if(!this.contactos) this.contactos = new Array();
        if(!this.usuarios.includes(contacto)){
          this.contactos.push(contacto);
          this.usuarios.push(contacto);
          this._emailsService.savelista(this.contactos, this.identity._id).subscribe(
            response=>{
            },
            error=>{
              console.log(<any>error);
            });
        }
    }      
  }

  eliminamensajedefinitivo(mensaje){
    var dir = this.dirmensajes + '/' + mensaje.id;
      this._office365Service.eliminamensaje(dir).subscribe(
        response=>{
            this.aviso('mensaje cancelado', 'ok');
          },
        error=>{
          console.log(<any>error);
        }  
      );
  }

  borraopcion(opcion){
    this.myControl.setValue(null);
    this.destinatarionuevo = this.destinatarionuevo.filter((dest)=>{
      if(dest != opcion) return true;
    });
    if(this.contactos.includes(opcion)){
      this.contactos.splice(this.contactos.indexOf(opcion), 1);
      this.usuarios.splice(this.usuarios.indexOf(opcion), 1);
      console.log(this.contactos);
      this._emailsService.savelista(this.contactos, this.identity._id).subscribe(
              response=>{
                console.log(response);
                this.aviso('opcion eliminada', 'ok');
              },
              error=>{
                console.log(<any>error);
              });
    }else{
      this.aviso('no se puede eliminiar un contacto del GrupoMrw', 'error');
    }  
  }

  buscar(texto){
    if(texto){
      this.load = false;
      this.mensajesSeleccionados = new Array();
      this._office365Service.buscarenservidor(texto).subscribe(
        response=>{
          this.mensajesactuales = response.value;
          this.mensajesbuscados = this.mensajesactuales.length;
          this.siguientesmensajes = true;
          this.load = true;
          if(response['@odata.nextLink']){
            this.siguientesmensajes = response['@odata.nextLink'];
          }else{
            this.siguientesmensajes = undefined;
          }  
        },
        error=>{
          console.log(error);
        }
      )
    }else{
      this.aviso('no hay texto para buscar emails', 'error');
    }    
  }

  buscareneliminados(texto){
    if(texto){
      this.load = false;
      this.mensajesSeleccionados = new Array();
      this._office365Service.buscareneliminados(texto).subscribe(
        response=>{
          this.mensajesactuales = response.value;
          this.mensajesbuscados = this.mensajesactuales.length;
          this.siguientesmensajes = true;
          this.load = true;
          if(response['@odata.nextLink']){
            this.siguientesmensajes = response['@odata.nextLink'];
          }else{
            this.siguientesmensajes = undefined;
          }  
        },
        error=>{
          console.log(error);
        }
      )
    }else{
      this.aviso('no hay texto para buscar emails', 'error');
    }    
  }

  masmensajes(){
    this.load = false;
    this.mensajesSeleccionados = new Array();
    this._office365Service.solicitaremails(this.siguientesmensajes).subscribe(
      response=>{
        this.mensajesactuales = response.value;
        this.mensajesbuscados = this.mensajesactuales.length;
        this.load = true;
        if(response['@odata.nextLink']){
          this.siguientesmensajes = response['@odata.nextLink'];
        }else{
          this.siguientesmensajes = undefined;
        }  
      },
      error=>{
        this.aviso('ocurrio un error al recuperar los mensajes..', 'error');
      }
    )
  }

  actualizar(){
    console.log('actualizando...')
    this.actualizacarpetas();
    setTimeout(()=>{
      this.actualizar();
    },300000);
  }

  copiarmensaje(mensaje, carpeta){
    var dir = this.dirmensajes + '/' + mensaje.id + '/copy';
    var cuerpo = {destinationId: carpeta};
    this._office365Service.movermensaje(dir, cuerpo).subscribe(
      response=>{
        this.aviso('mensaje copiado', 'ok');
      },
      error=>{
        this.aviso('error al copiar el mensaje', 'error');
        console.log(error);
      }
    )    
  }

  copiarislas(mensaje){
    var carpetaislas = "AAMkAGRkMTdiYzA5LTNiNTktNGQ4OS05MDBlLWUzM2EwZTA5NDYxZgAuAAAAAAA5O8lJxd5bSYS3MerS5yfTAQD_hHShWQALRrQJ96baUnMcAANNwICNAAA=";
     delete mensaje["@odata.context"];
     delete mensaje["@odata.etag"];
     delete mensaje["id"];
     delete mensaje["createdDateTime"];
     delete mensaje["lastModifiedDateTime"];
     delete mensaje["changeKey"];
     mensaje.isDraft = false;
     this._office365Service.recuperamensaje(mensaje, carpetaislas).subscribe(
        response=>{
          if(response){
            this.aviso('copiado a carpeta islas...', 'Ok');
          }  
        },
        error=>{
          console.log(error);
        }
     );
  }

	exportar(mensaje){
    this._emailsService.exportarmail(mensaje).subscribe(
      response=>{
        console.log(response);
        saveAs(response, 'mensaje.eml');
      },
      error=>{
        console.log(error);
      }
    )
	}

  scrolLarriba(){
    this.cuerpomensaje.nativeElement.scrollTop = 0;
  }

}

