export class Cliente{
	constructor(
		public _id:string,
		public nif: string,
		public nombre:string,
		public numero:string,
		public password:string,
		public direccion: string,
		public poblacion: string,
		public cp: string,
		public telefono: string,
		public observaciones: string,
		public emailFacturas: string,
		public emailsIncidencias: string,
		public emailNotificaciones: string, 
		public contacto: string,
		public role: string,
		public nuevoarchivo: string,
		public perfil: string,
		public incidenciasdefinitivas: [string],
		public options: {
			page: number,
			limit: number,
			sort: {fechaenvio: string},
			carpetasmail: [string],
			carpetasinteraccion: [string],
			ordencamposincidencias: [string],
			ordencamposconcertadas: [string],
			ordencamposfacturas: [string],
			ordencamposreembolsos:{
				mensajeros:[string],
				totales: [string],
				direcciones: [string]
			},
			g3: {
				usuario: string | '0260100019',
				password: string | 'mensasur2601'
			},
			firmamail: string
		}		

	){}
}