import { OnInit, Component, ViewChild, AfterViewInit} from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { ClienteService } from '../../servicios/cliente.service';
import { IncidenciasService } from '../../servicios/incidencias.service';
import { AppService } from '../../servicios/app.service';
import { ConcertadaService } from '../../servicios/concertada.service';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import * as moment from 'moment';
import { Concertada } from '../../models/concertada';
import { AlbconcertadaComponent } from '../albconcertada/albconcertada.component';
import { DialogoimpresasComponent } from '../dialogoimpresas/dialogoimpresas.component';
import { MatTableDataSource } from '@angular/material/table';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { FormControl } from '@angular/forms';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition, MatSnackBarConfig } from '@angular/material/snack-bar'; 
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-concertadas',
  templateUrl: './concertadas.component.html',
  styleUrls: ['./concertadas.component.css'],
  providers: [ClienteService, IncidenciasService, ConcertadaService, AppService]
})
export class ConcertadasComponent implements OnInit, AfterViewInit{

  public identity;
  public etiquetasimprimir;
  public etiquetasimpresas;
  public concertadas = [];
  public depositados;
  public fechasiguiente;
  public seleccion;
  public queryactual;
  public category;
  public selecionra;
  public avisonuevas;
  public albarananterior;
  public columns;
  public dataSource;
  public selection;
  public totalcolumns;
  public campos;
  public camposeleccionados;
  public filtros;
  public listasfiltros;
  public listaexpediente;
  public seleccionexp;
  public columnasexpediente;
  public erroresubicacion;
  public controlUbicaciones = new FormControl();
  public filteredUbicaciones: Observable<any[]>;
  public listaubicaciones;
  public cerrardia;
  public fechalibre;
  public cuenta;
  public fechahoy;
  public concertadaspendienteshoy = 0;
  public fechasocupadas;
  public anterioressinimprimir = new Array();
  public load = true;
  @ViewChild(MatSort, {static: false}) set content(sort: MatSort){this.dataSource.sort = sort;}
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;

  constructor(
    private _clienteService: ClienteService,
    private _concertadasservice: ConcertadaService,
    private _incidenciasservice: IncidenciasService,
    private dateAdapter: DateAdapter<Date>,
    public ventanaAlbaran: MatDialog,
    private _snackBar: MatSnackBar,
    public dialogoimpresas: MatDialog,
    public _appservice: AppService
  	) { 
    this.identity = this._appservice.getIdentity(); 
  	this.totalcolumns = ['select','NumAlbaran','Tipo','Anomalia','AnomaliaMotivo','AnomaliaObservaciones','CodigoAbonado','CodigoFranquicia','ContactoEntrega','ContactoRecogida','CpDestinatario','CpRemitente','Destinatario','DireccionDestinatario','DireccionRemitente','Estado','Fase','FechaEntrega','FechaEnvio','EntregarAPartirDe','FechaRecogida','FechaFinalizacionRecogida','FechaConcertacion','HoraMaximaEntrega','HoraMaximaRecogida','IdFiscal','AR','ImporteAR','Kilos','KmDestinatario','KmRemitente','Motivo','NombreCliente','NombreSolicitante','ObservacionesEntrega','ObservacionesRecogida','PoblacionDestinatario','PoblacionRemitente','ProvinciaDestinatario','ProvinciaRemitente','RangoHorarioEntregaManana','RangoHorarioEntregaTarde','RangoHorarioRecogidaManana','RangoHorarioRecogidaTarde','Remitente','Servicio','TelefonoDestinatario','TelefonoRemitente','TipoComision','TipoEntrega','TipoGeografico','TipoMercancia','TipoRecogida','TotalBultos','TipoCobro','FranquiciaDestino','FranquiciaOrigen','FranquiciaTercera','FranquiciaClienteFacturacion','CodigoCupon','NumeroAlbaranPrecursor','NumeroEnvioRetorno','TipoGestion','TieneRetorno','ConfirmacionInmediata','EntregaSabado','TipoRetorno','Usuario','UsuarioModificacion','ReferenciaCliente','EsInternacional','GeneradoPor','Aviso','TotalMedidas','Operacion','Frecuencia','Contador','NifRemitente','NifDestinatario','MensajeroEntrega','MensajeroRecogida','PuntoRecogida','PuntoEntrega','PuntoAnomalia','PuntoEstado','PuntoConcertacion','TipoPuntoConcertacion','texto','usuario','fechaconcertada','fecharegistro','horarioconcertado','ObservacionesEstado', 'impresa','usuarioimpresion','fechaimpresion','obconcertada'];
    var totalcolconcertadas = this.identity.options.ordencamposconcertadas.length-1;
    for(let i = 0;i< totalcolconcertadas;i++){
      let col = this.identity.options.ordencamposconcertadas[i];
      if(!this.totalcolumns.includes(col)) this.identity.options.ordencamposconcertadas.splice(this.identity.options.ordencamposconcertadas.indexOf(col), 1);
      if(i >= totalcolconcertadas){
        sessionStorage.setItem('identity', JSON.stringify(this.identity));
        this._clienteService.saveCliente(this.identity);
      }
    }
    
    this.listasfiltros = {
      listamotivo:[
        {Motivo:'', valor:''},
        {Motivo:'OT0700', valor: 'Clasificado en plataforma'},
        {Motivo:'OE0062', valor: 'Depositado en Franquicia de destino'},
        {Motivo:'OE0600', valor: 'Mensajero de entrega asignado'},
        {Motivo:'OR0600', valor: 'Mensajero de recogida asignado'},
        {Motivo:'OE0500', valor: 'Recibido en franquicia de destino'},
        {Motivo:'OR0200', valor: 'Recogida finalizada'},
        {Motivo:'OS0200', valor: 'Recogida pendiente'},
        {Motivo:'OE0100', valor: 'Tránsito interno de entrega'}
        ],
      listagestionados:['','Pendiente Cliente','Pendiente Agencia','Para Entregar','Recoge en agencia','Entregado','Cancelado','Especial/vigilar'],
      listaestado:['','EntregaPendiente','EnvioCancelado','RecogidaFinalizada','RecogidaPendiente','TransitoInternoEntrega','EntregaCancelada','RecogidaFinalizada','EntregaFinalizada']
    }
    this.filtros = {
      filtroprincipal: ''
      };
    this.cuenta = 0; 
    this.listaubicaciones = ['ALMACEN', 'ANDAMIO', 'PALET', 'LIBRERIA', 'ESTANTERIA', 'DIRECCIONES INCORRECTAS', 'CARPA', 'MUEBLE GRIS'];
    this.selection = new SelectionModel<Concertada>(true, []);
   	this.dateAdapter.setLocale('es');
    this.dateAdapter.getFirstDayOfWeek = () => { return 1; };
    this.columnasexpediente = ['Estado','FechaEntrada','HoraEntrada','Descripcion','Detalle','Usuario'];
    this.avisonuevas = 0;
    this.dataSource = new MatTableDataSource<Concertada>();
    this.dateocupadas();
    this.getconcertadas();
  }

  ngOnInit(): void {
    setTimeout(()=>{
      this.actualizaubicacion();
      this.avisopendienteshoy();
    }, 5000);
    this.filteredUbicaciones = this.controlUbicaciones.valueChanges
          .pipe(
            startWith(''),
            map(name => name ? this.filtroubicacion(name) : this.listaubicaciones.slice())
          );
    this.proximodialibre();          
  }

  ngAfterViewInit(){
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.content;
  }

  filtroubicacion(name: string){
   const filterValue = name.toLowerCase();
   return this.listaubicaciones.filter(option => option.toLowerCase().indexOf(filterValue) === 0);
  }

  displayFn(user): string {
    return user ? user : '';
  }

  dateocupadas(){
    var fecha = moment().set({hour:22,minute:0,second:0,millisecond:0}).add(1, 'days').toISOString();
    var query = {fecha:{$gte:fecha}};
    this._concertadasservice.getcierres(query).subscribe(
      response=>{
        if(response.cierres){
          this.fechasocupadas = response.cierres.map((cierre)=>{
            return moment(cierre.fecha).toISOString();
          });
          this.sigfechaconcertacion(fecha);  
        }
      }  
    );       
  }

  myFilter = (d: Date | null): boolean => {
    const day = (d || new Date()).getDay();
    var fecha = new Date();
    var diasiguiente = fecha;
    var fechamoment = moment(d).set({hour:22,minute:0,second:0,millisecond:0}).toISOString();
    if(day !== 0 && day !== 6 && d > fecha && !this.fechasocupadas.includes(fechamoment) && diasiguiente != d){
      return true
    }else{
      return false;
    }
  }

  aviso(texto, subtexto) {
    this._snackBar.open(texto,subtexto,{
      duration: 4000,
      horizontalPosition: "center",
      verticalPosition: "top",
    });
  }

  sigfechaconcertacion(fecha){
    // 0 domingo -> primer dia
    var dia = moment(fecha).day();
    if(dia == 6){
      this.sigfechaconcertacion(moment(fecha).add(2,'days'));
      //this.fechasiguiente = moment(fecha).add(3,'days').format("DD/MM/YYYY");
    }else if(dia == 0){
      this.sigfechaconcertacion(moment(fecha).add(1,'days'));
      //this.fechasiguiente = moment(fecha).add(1,'days').format("DD/MM/YYYY");
    }else if(this.fechasocupadas.includes(moment(fecha).set({hour:22,minute:0,second:0,millisecond:0}).toISOString())){
      this.sigfechaconcertacion(moment(fecha).add(1,'days'));
    }else{
      this.fechasiguiente = moment(fecha).format("DD/MM/YYYY");
      this.fechahoy = moment().format('DD/MM/YYYY');
    }
  }

  actualizacliente(){
    sessionStorage.setItem('identity', JSON.stringify(this.identity));
    this._clienteService.saveCliente(this.identity);
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  isAllSelected() {
    if(this.dataSource.filteredData){
      const numSelected = this.selection.selected.length;
      const numRows = this.dataSource.filteredData.length;
      return numSelected === numRows;
    }else{
      const numSelected = this.selection.selected.length;
      const numRows = this.dataSource.data.length;
      return numSelected === numRows;
    }
  }

  /** Selects all rows if they are not all selected; otherwise clear selection.*/ 
  masterToggle() {
    if(this.dataSource.filteredData){
        this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.filteredData.forEach(row => this.selection.select(row));
    }else{  
      this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.data.forEach(row => this.selection.select(row));
    }    
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: Concertada): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.identity.options['ordencamposconcertadas'], event.previousIndex, event.currentIndex);
    this.actualizacliente();
  }

  onselectfiltro(filtro, valor){
     if(valor == ''){
      this.filtros[filtro] = undefined;
      this.eliminafiltro(filtro);
    }else{
      if(filtro == 'Motivo'){
        this.queryactual['Motivo'] = {$in:[valor.Motivo, valor.valor]};
      }else{
        this.queryactual[filtro] = valor;
      }  
      this.concertadasactuales();
    }  
  }

  eliminafiltro(filtro){
    delete this.queryactual[filtro];
    this.concertadasactuales();
  }

  onDateFechaenvio(){

   var fechainicial = moment(this.filtros['FechaEnvioinicial']).format('DD/MM/YYYY');
   if(this.filtros.FechaEnviofinal == null){
    var fechafinal = moment(this.filtros['FechaEnvioinicial']).format('DD/MM/YYYY');
   }else{
     var fechafinal = moment(this.filtros['FechaEnviofinal']).format('DD/MM/YYYY');
   }
   this.queryactual = {$and:[{FechaEnvio:{$gte:fechainicial}},{FechaEnvio:{$lte:fechafinal}}]};
   this.concertadasactuales();
  }

  onDateFecharecogida(){
    var fechainicial = moment(this.filtros['FechaRecogidainicial']).format('DD/MM/YYYY');
   if(this.filtros.FechaRecogidafinal == null){
    var fechafinal = moment(this.filtros['FechaRecogidainicial']).format('DD/MM/YYYY');
   }else{
    var fechafinal = moment(this.filtros['FechaRecogidafinal']).format('DD/MM/YYYY');
   }
   this.queryactual = {$and:[{FechaRecogida:{$gte:fechainicial}},{FechaRecogida:{$lte:fechafinal}}]};
   this.concertadasactuales();
  }

  onDateFecharecogidafinalizada(){
    var fechainicial = moment(this.filtros['FechaRecogidafinalizadainicial']).format('DD/MM/YYYY HH:mm:ss');
   if(this.filtros.FechaRecogidafinalizadafinal == null){
    var fechafinal = moment(this.filtros['FechaRecogidafinalizadainicial']).format('DD/MM/YYYY HH:mm:ss');
   }else{
    var fechafinal = moment(this.filtros['FechaRecogidafinalizadafinal']).format('DD/MM/YYYY HH:mm:ss');
   }
   this.queryactual = {$and:[{FechaFinalizacionRecogida:{$gte:fechainicial}},{FechaFinalizacionRecogida:{$lte:fechafinal}}]};
   this.concertadasactuales();
  }

  onDateFechaentrega(){
    var fechainicial = moment(this.filtros['FechaEntregainicial']).format('DD/MM/YYYY HH:mm:ss');
   if(this.filtros.FechaEntregafinal == null){
    var fechafinal = moment(this.filtros['FechaEntregainicial']).set({hour:23,minute:59,second:59}).format('DD/MM/YYYY HH:mm:ss');
   }else{
    var fechafinal = moment(this.filtros['FechaEntregafinal']).set({hour:23,minute:59,second:59}).format('DD/MM/YYYY HH:mm:ss');
   }
    this.queryactual = {$and:[{FechaEntrega:{$gte:fechainicial}},{FechaEntrega:{$lte:fechafinal}}]};
    this.concertadasactuales();
  }

  onDateFechaimpresion(){
    var fechainicial = moment(this.filtros['fechaimpresioninicial']).format('DD/MM/YYYY HH:mm:ss');
   if(this.filtros.FechaEntregafinal == null){
    var fechafinal = moment(this.filtros['fechaimpresioninicial']).set({hour:23,minute:59,second:59}).format('DD/MM/YYYY HH:mm:ss');
   }else{
    var fechafinal = moment(this.filtros['fechaimpresionfinal']).set({hour:23,minute:59,second:59}).format('DD/MM/YYYY HH:mm:ss');
   }
    this.queryactual = {$and:[{fechaimpresion:{$gte:fechainicial}},{fechaimpresion:{$lte:fechafinal}}]};
    this.concertadasactuales();
  }

  

  onDateFechaConcertacion(){
     var fechainicial = moment(this.filtros['FechaConcertacioninicial']).format('DD/MM/YYYY HH:mm:ss');
   if(this.filtros.FechaConcertacionfinal == null){
    var fechafinal = moment(this.filtros['FechaConcertacioninicial']).set({hour:23,minute:59,second:59}).format('DD/MM/YYYY HH:mm:ss');
   }else{
    var fechafinal = moment(this.filtros['FechaConcertacionfinal']).set({hour:23,minute:59,second:59}).format('DD/MM/YYYY HH:mm:ss');
   }
    this.queryactual = {$and:[{FechaConcertacion:{$gte:fechainicial}},{FechaConcertacion:{$lte:fechafinal}}]};
    this.concertadasactuales();
  }

  onDateFecharegistro(){
     var fechainicial = moment(this.filtros['Fecharegistroinicial']).format('DD/MM/YYYY HH:mm:ss');
   if(this.filtros.Fecharegistrofinal == null){
    var fechafinal = moment(this.filtros['Fecharegistroinicial']).set({hour:23,minute:59,second:59}).format('DD/MM/YYYY HH:mm:ss');
   }else{
    var fechafinal = moment(this.filtros['Fecharegistrofinal']).set({hour:23,minute:59,second:59}).format('DD/MM/YYYY HH:mm:ss');
   }
    this.queryactual = {$and:[{fecharegistro:{$gte:fechainicial}},{fecharegistro:{$lte:fechafinal}}]};
    this.concertadasactuales();
  }

  onDateFechaentregaconcertada(){
    var fecha = moment(this.filtros['FechaEntregaConcertada']).format('DD/MM/YYYY');
    this.queryactual = {fechaconcertada:fecha};
    this.concertadasactuales();
  }

  actualizaubicacion(){
    this.proximodialibre();
    this.avisopendienteshoy();
    this.mapearconcertadas();
    this.totsinimprimir();
    setTimeout(()=>{
      this.actualizaubicacion();
    }, 300000);
  }

  humanizemotivo(motivo){
    var motivohumanizado:string;
    switch(motivo){
      case 'OT0700':
        motivohumanizado = 'Clasificado en plataforma';
        break;
      case 'OE0062': 
        motivohumanizado = 'Depositado en Franquicia de destino';
        break;
      case 'OE0600':
        motivohumanizado = 'Mensajero de entrega asignado';
        break;  
      case 'OR0600':
        motivohumanizado = 'Mensajero de recogida asignado';
        break; 
      case 'OE0500':
        motivohumanizado = 'Recibido en franquicia de destino';
        break;
      case 'OR0200':
        motivohumanizado = 'Recogida finalizada';
        break;
      case 'OS0200':
        motivohumanizado = 'Recogida pendiente';
        break;
      case 'OE0100':
        motivohumanizado = 'Tránsito interno de entrega';
        break;
      case 'OR0402':
        motivohumanizado = 'Envio borrado';
        break;
      case 'OR0201':
        motivohumanizado = 'Recogida finalizada en franquicia';
        break;
      case 'OE0702':
        motivohumanizado = 'Reexpedir Envio';
        break;
      case 'OE0401':
        motivohumanizado = 'Devolver al remitente';
        break;
      case 'OE1103':
        motivohumanizado = 'Cliente Ordena destruir el envio';
        break;          
      default:
        motivohumanizado = motivo;    
    }
    return motivohumanizado;
  }

  mapearconcertadas(){
    this.dataSource.data = [];
    this.dataSource.data = this.concertadas.map((inc, index)=>{
      this._concertadasservice.getdepositado(inc.NumAlbaran).subscribe(response=>{
        if(response.datos) inc.ObservacionesEstado = response.datos.ObservacionesEstado
      });
      inc['position'] = index;
      return inc;
    });
    if(this.dataSource.paginator) this.dataSource.paginator.firstPage();
    this.cuenta = 0;
  }

mapearalbconcertadas(){
  this.dataSource.data = [];
  this.dataSource.data = this.concertadas.map((inc, index)=>{
    if(inc.CodigoAbonado)inc.CodigoAbonado = inc.CodigoAbonado.substring(-1, 6);
    this._concertadasservice.getdepositado(inc.NumAlbaran).subscribe(response=>{
      if(response.datos) inc.ObservacionesEstado = response.datos.ObservacionesEstado
    });
    inc['position'] = index;
    return inc;
  });
 if(this.dataSource.paginator) this.dataSource.paginator.firstPage();
 this.cuenta = 0;
}

buscarconcertada(){
  var albaran = this.filtros['NumAlbaran'].trim().toUpperCase();
  this.dataSource.data = [];
  this._concertadasservice.getconcertada(albaran).subscribe(
      response =>{
          if(response.concertada){
            this.concertadas = new Array();
            this.concertadas.push(response.concertada);
            this.mapearconcertadas();
            this.abrealbaran(response.concertada);
          }else{
            this.aviso('no hay ninguna entrega concertada con albaran: ' + albaran, 'error');
          }
      },
      error=>{
        console.log(<any>error);
      });      
  }

  buscarAlbaran(){
    var albaran = this.filtros['NumAlbarang3'].trim().toUpperCase();
    this.seleccion = undefined; 
    this.dataSource.data = [];
    this._concertadasservice.getconcertada(albaran).subscribe(
        response =>{
            if(response.concertada){
              this.concertadas = new Array();
              this.concertadas.push(response.concertada);
              this.mapearconcertadas();
              this.abrealbaran(response.concertada);
            }else{
              this._incidenciasservice.albaranG3(albaran).subscribe(
                  response =>{
                    if(response.datos){
                      this.concertadas = new Array();
                      this.concertadas.push(response.datos);
                      this.dataSource.data = [];
                      var inc = response.datos;
                        if(inc.CodigoAbonado)inc.CodigoAbonado = inc.CodigoAbonado.substring(-1, 6);
                        inc['position'] = 0;
                        this._concertadasservice.getdepositado(inc.NumAlbaran).subscribe(response=>{
                          if(response.datos) inc.ObservacionesEstado = response.datos.ObservacionesEstado;
                          this.dataSource.data.push(inc);
                          this.abrealbaran(inc);
                          if(this.dataSource.paginator) this.dataSource.paginator.firstPage();
                        });
                    }else{
                      if(this.cuenta == 0){
                        this._appservice.refreshg3();  
                        this.aviso('No se ha encontrado albaran en G3', 'error');
                      }

                      this.cuenta = this.cuenta + 1; 
                      
                    }
                  },
                  error=>{
                    console.log(<any>error);
                  });
            }
        },
        error=>{
          console.log(<any>error);
        });        
  }

  limpiarfiltros(){
    this.dataSource.filter = '';
    this.filtros = {filtroprincipal: ''};
  }  

  concertadasactuales(){
    this.load = false;
    var params = {'query': this.queryactual};
    this.selection.clear();
    this.dataSource.filteredData = '';
    this._concertadasservice.getconcertadas(params).subscribe(
      response =>{
        if(response.concertadas && response.concertadas.length >= 1){
          this.concertadas = response.concertadas;
          this.mapearconcertadas();
            if(this.filtros['FechaEntregaConcertada']){
              var fecha = moment(this.filtros['FechaEntregaConcertada'], "DD/MM/YYYY");
            }else{
              var dea = moment().add(1,'days').day();
              if(dea == 6){
                var fecha = moment().add(3,'days');
              }else if(dea == 0){
                var fecha = moment().add(2,'days');
              }else{
                var fecha = moment().add(1,'days');
              }  
            }
            var dia = fecha.format('d');
            if(dia == '2'){
              var maximo = 20; 
            }else{
              var maximo = 40;
            }
            if(this.concertadas.length >= maximo){
              var setfecha = moment(fecha).set({hour:22,minute:0,second:0,millisecond:0}).toISOString(true);
              this.setcierre(setfecha);  
            }  
        }else{
          this.concertadas = response.concertadas;
          this.dataSource.data = this.concertadas;
        }
        this.load = true;
        },
      error =>{
        console.log(<any>error);
      } 
    );
  }

  getconcertadas(){
    this.load = false;
    this.category = 'totales';
    this.limpiarfiltros();
    this.selection.clear();
    this.dataSource.filteredData = '';
    this.queryactual = {};
    var dia = moment().add(1,'days').day();
    if(dia == 6){
      var siguientedia = moment().add(3,'days').format('DD/MM/YYYY');
    }else if(dia == 0){
      var siguientedia = moment().add(2,'days').format('DD/MM/YYYY');
    }else{
      var siguientedia = moment().add(1,'days').format('DD/MM/YYYY');
    }  
    var query = {fechaconcertada: siguientedia};
    var params = {"query": query};
      this._concertadasservice.getconcertadas(params).subscribe(
        response =>{
          if(response.concertadas && response.concertadas.length >= 1){
            this.concertadas = response.concertadas;
            this.mapearconcertadas();
            if(this.filtros['FechaEntregaConcertada']){
              var fecha = moment(this.filtros['FechaEntregaConcertada'], "DD/MM/YYYY");
            }else{
              var fecha = moment(siguientedia, "DD/MM/YYYY");
            }
            var dia = fecha.format('d');
            if(dia == '2'){
              var maximo = 20; 
            }else{
              var maximo = 40;
            }
            if(this.concertadas.length >= maximo){
              var setfecha = moment(fecha).set({hour:22,minute:0,second:0,millisecond:0}).toISOString(true);
              this.setcierre(setfecha);  
            }  
          }else{
            this.concertadas = response.concertadas;
            this.dataSource.data = this.concertadas;
          }
          this.load = true;
        },
        error =>{
          console.log(<any>error);
          this.load = true;
        } 
      );
  }

  seleccionar(row){
    this.seleccion = row;
    this.selecionra = this.tipora(row.AR);
    this.getexpediente();
  }

  tipora(tipo){
    if(tipo == 1) return '(R)';
    if(tipo == 0) return '(A)';
  }

  categoriaseleccionada(categoria){
    this.category = categoria;
    this.selection.clear();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
    this.proximodialibre();
    this.seleccion = undefined;  
  }

  abrealbaran(concertada){
     this.ventanaAlbaran.open(AlbconcertadaComponent, {
      width: '98%',
      height: '95%',
      data: concertada});
     this.albarananterior = concertada;
     this.seleccion = concertada;
  }

  onselectcolumnas(value){
    this.identity.options.ordencamposconcertadas = value;
    this.actualizacliente();
  }

  getdepositados(){
    this.limpiarfiltros();
    this.load = false;
    this._concertadasservice.getdepositados().subscribe(
      response=>{
          if(response.datos){
            this.concertadas = response.datos;
            this.mapearalbconcertadas();
          }else{
            this.aviso('No se ha Podido acceder a G3, intentelo de nuevo mas tarde', 'error');
          }
          this.load = true;  
        },
      error=>{
        console.log(<any>error);
      }  
    )
  }

  imprimircalles(concertadas){
    this.mapearcalles(concertadas).then(async (concert)=>{
          var ventana = window.open('', 'PRINT', 'height=400,width=600');
          this.etiquetasimprimir = '<html><head><style type="text/css" media="print">*{margin:0px;padding:0px;} body{font-size:15px;}</style></head><body>' + this.etiquetasimprimir;
          ventana.document.write(this.etiquetasimprimir);
          ventana.document.write('</body></html>');
          ventana.document.close();
          ventana.focus();
          ventana.print();
          ventana.close();
    });     
  }

  imprimirmensajeros(concertadas){
    this.mapearetiquetasmensajero(concertadas).then(async (concert)=>{
          var ventana = window.open('', 'PRINT', 'height=400,width=600');
          this.etiquetasimprimir = '<html><head><style type="text/css" media="print">*{margin:0px;padding:0px;} body{font-size:15px;}</style></head><body>' + this.etiquetasimprimir;
          ventana.document.write(this.etiquetasimprimir);
          ventana.document.write('</body></html>');
          ventana.document.close();
          ventana.focus();
          ventana.print();
          ventana.close();
    });      
  }

  imprimiretiquetas(concertadas){
    var query = {$or:[{impresa:{$exists: false}}, {impresa: 0}], fechaconcertada:concertadas[0].fechaconcertada};
    var params = {'query': query};
    this._concertadasservice.getconcertadas(params).subscribe(
      response =>{
        if(response.concertadas && response.concertadas.length >= 1){
           if(concertadas.length >= response.concertadas.length){
             var setfecha = moment(concertadas[0].fechaconcertada, 'DD/MM/YYYY').set({hour:22,minute:0,second:0,millisecond:0}).toISOString(true);
             this.setcierre(setfecha); 
           }  
        }  
      },
      error =>{
        console.log(<any>error);
      } 
    );
    this.mapearetiquetas(concertadas).then(async (concert)=>{
        if(this.etiquetasimpresas.length >= 1){
          var impresasabierto = this.dialogoimpresas.open(DialogoimpresasComponent, {
            width: '30%',
            height: '30%'
            });
          impresasabierto.afterClosed().subscribe(result =>{ 
              if(result == true){
                  var ventana = window.open('', 'PRINT', 'height=400,width=600');
                  this.etiquetasimprimir = '<html><head><style type="text/css" media="print">*{margin:0px;padding:0px;} body{font-size:15px;}</style></head><body>' + this.etiquetasimprimir;
                  ventana.document.write(this.etiquetasimprimir);
                  ventana.document.write('</body></html>');
                  ventana.document.close();
                  ventana.focus();
                  ventana.print();
                  ventana.close();
                  var ubicacion = 'Sacado según concertación';
                  this.ubicar(concert, ubicacion);
                  concert.forEach((concertada)=>{
                    concertada.usuarioimpresion = this.identity.nombre;
                    concertada['fechaimpresion'] = moment(new Date()).format('DD/MM/YYYY HH:mm:ss');
                    if(concertada.ObservacionesEstado != 'Sacado según concertación') concertada['ubicacionant'] = concertada.ObservacionesEstado;
                    this.guardaconcertada(concertada);
                  });
              }else{
                this.selection.clear();
                this.dataSource.filteredData = '';
                this.dataSource.data = this.etiquetasimpresas.map((etiqueta)=>{
                    etiqueta.impresa--;
                    return etiqueta;
                });
              }
          });
        }else{
          var ventana = window.open('', 'PRINT', 'height=400,width=600');
          this.etiquetasimprimir = '<html><head><style type="text/css" media="print">*{margin:0px;padding:0px;} body{font-size:15px;}</style></head><body>' + this.etiquetasimprimir;
          ventana.document.write(this.etiquetasimprimir);
          ventana.document.write('</body></html>');
          ventana.document.close();
          ventana.focus();
          ventana.print();
          ventana.close();
          var ubicacion = 'Sacado según concertación';
          this.ubicar(concert, ubicacion);
          concert.forEach((concertada)=>{
            concertada.usuarioimpresion = this.identity.nombre;
            concertada['fechaimpresion'] = moment(new Date()).format('DD/MM/YYYY HH:mm:ss');
            if(concertada.ObservacionesEstado != 'Sacado según concertación') concertada['ubicacionant'] = concertada.ObservacionesEstado;
            this.guardaconcertada(concertada);
          });
        }
    });
  }

  async mapearetiquetas(concertadas){
    this.etiquetasimprimir = '';
    this.etiquetasimpresas = new Array();
    var etiquetas = await concertadas.map((concertada, index)=>{
        if(concertada.impresa){
          this.etiquetasimpresas.push(concertada);
          concertada.impresa++ 
        }else{
          concertada['impresa'] = 1;
        }
        if(concertada['ubicacionant'] && concertada.ObservacionesEstado == 'Sacado según concertación') concertada.ObservacionesEstado = concertada.ubicacionant;
        if(!concertada.obconcertada) concertada.obconcertada = ' '; 
        if(!concertada.ObservacionesEstado) concertada.ObservacionesEstado = 'Mensajero: ' + concertada.MensajeroEntrega;
        if(index == 0){
          this.etiquetasimprimir = '<div style="text-align:center;align-content:center;"><h2>' + concertada.NumAlbaran + '</h2><h3>2º Intento de Entrega</h3><div style="font-size:50px;">' + concertada.fechaconcertada + '</div><div style="font-size:40px">' + concertada.horarioconcertado + '</div><br><div>' + concertada.Destinatario + '</div><br><div>Direccion<br>' + concertada.DireccionDestinatario + '</div><br><div style="display:inline-flex"><div style="margin-right:20px">Ubicacion<br>' + concertada.ObservacionesEstado + '</div><div>Codigo Postal<br>' + concertada.CpDestinatario + '</div></div><div style="margin-top:20px">Bultos: ' + concertada.TotalBultos + '</div><br><div style="font-size:22px"><b>' + concertada.obconcertada + '</b></div><div style="margin-top:12px;">Etiqueta 1 de ' + concertada.TotalBultos + '</div></div>';
          if(concertada.TotalBultos >= 2){
            var etiquetaextra = '<div style="text-align:center;align-content:center;page-break-before:always"><h2>' + concertada.NumAlbaran + '</h2><h3>2º Intento de Entrega</h3><div style="font-size:50px;">' + concertada.fechaconcertada + '</div><div style="font-size:40px">' + concertada.horarioconcertado + '</div><br><div>' + concertada.Destinatario + '</div><br><div>Direccion<br>' + concertada.DireccionDestinatario + '</div><br><div style="display:inline-flex"><div style="margin-right:20px">Ubicacion<br>' + concertada.ObservacionesEstado + '</div><div>Codigo Postal<br>' + concertada.CpDestinatario + '</div></div><div style="margin-top:20px">Bultos: ' + concertada.TotalBultos + '</div><br><div style="font-size:22px"><b>' + concertada.obconcertada + '</b></div>';
            for(var i = 2; i <= concertada.TotalBultos;i++){
              this.etiquetasimprimir = this.etiquetasimprimir + etiquetaextra + '<div style="margin-top:12px;">Etiqueta ' + i + ' de ' + concertada.TotalBultos + '</div></div>';
            }
          }
        }else{  
          this.etiquetasimprimir = this.etiquetasimprimir + '<div style="text-align:center;align-content:center;page-break-before:always"><h2>' + concertada.NumAlbaran + '</h2><h3>2º Intento de Entrega</h3><div style="font-size:50px;">' + concertada.fechaconcertada + '</div><div style="font-size:40px">' + concertada.horarioconcertado + '</div><br><div>' + concertada.Destinatario + '</div><br><div>Direccion<br>' + concertada.DireccionDestinatario + '</div><br><div style="display:inline-flex"><div style="margin-right:20px">Ubicacion<br>' + concertada.ObservacionesEstado + '</div><div>Codigo Postal<br>' + concertada.CpDestinatario + '</div></div><div style="margin-top:20px">Bultos: ' + concertada.TotalBultos + '</div><br><div style="font-size:22px"><b>' + concertada.obconcertada + '</b></div><div style="margin-top:12px;">Etiqueta 1 de ' + concertada.TotalBultos + '</div></div>';
          if(concertada.TotalBultos >= 2){
            var etiquetaextra = '<div style="text-align:center;align-content:center;page-break-before:always"><h2>' + concertada.NumAlbaran + '</h2><h3>2º Intento de Entrega</h3><div style="font-size:50px;">' + concertada.fechaconcertada + '</div><div style="font-size:40px">' + concertada.horarioconcertado + '</div><br><div>' + concertada.Destinatario + '</div><br><div>Direccion<br>' + concertada.DireccionDestinatario + '</div><br><div style="display:inline-flex"><div style="margin-right:20px">Ubicacion<br>' + concertada.ObservacionesEstado + '</div><div>Codigo Postal<br>' + concertada.CpDestinatario + '</div></div><div style="margin-top:20px">Bultos: ' + concertada.TotalBultos + '</div><br><div style="font-size:22px"><b>' + concertada.obconcertada + '</b></div>';
            for(var i = 2; i <= concertada.TotalBultos;i++){
              this.etiquetasimprimir = this.etiquetasimprimir + etiquetaextra + '<div style="margin-top:12px;">Etiqueta ' + i + ' de ' + concertada.TotalBultos + '</div></div>';
            }
          }
        }
       return concertada;  
    });

    return etiquetas;
  }

  async mapearetiquetasmensajero(concertadas){
    this.etiquetasimprimir = '';
    this.etiquetasimpresas = new Array();
    var etiquetas = await concertadas.map((concertada, index)=>{
        if(!concertada.obconcertada) concertada.obconcertada = ' '; 
        if(!concertada.ObservacionesEstado) concertada.ObservacionesEstado = 'Mensajero: ' + concertada.MensajeroEntrega;
        if(index == 0){
          this.etiquetasimprimir = '<div style="text-align:center;align-content:center;"><h2>' + concertada.NumAlbaran + '</h2><h3>Concertada Entrega</h3><div style="font-size:50px;page-break-after:avoid">' + concertada.fechaconcertada + '</div><div style="font-size:40px">' + concertada.horarioconcertado + '</div><br><div>' + concertada.Destinatario + '</div><br><div>Direccion<br>' + concertada.DireccionDestinatario + '</div><br><div style="display:inline-flex"><div>Codigo Postal: ' + concertada.CpDestinatario + '</div><div style="margin-left:10px;">Bultos: ' + concertada.TotalBultos + '</div></div><br><div style="font-size:22px"><b>' + concertada.obconcertada + '</b></div></div>';
        }else{  
          this.etiquetasimprimir = this.etiquetasimprimir + '<div style="text-align:center;align-content:center;page-break-before:always"><h2>' + concertada.NumAlbaran + '</h2><h3>Concertada Entrega</h3><div style="font-size:50px;page-break-after:avoid">' + concertada.fechaconcertada + '</div><div style="font-size:40px">' + concertada.horarioconcertado + '</div><br><div>' + concertada.Destinatario + '</div><br><div>Direccion<br>' + concertada.DireccionDestinatario + '</div><br><div style="display:inline-flex"><div>Codigo Postal: ' + concertada.CpDestinatario + '</div><div style="margin-left:10px;">Bultos: ' + concertada.TotalBultos + '</div></div><br><div style="font-size:22px"><b>' + concertada.obconcertada + '</b></div></div>';
        }
        concertada['impresamensajero'] = 'agencia';
        this.guardaconcertada(concertada);
       return concertada;  
    });
    return etiquetas;
  }

  async mapearcalles(concertadas){
    this.etiquetasimprimir = '';
    this.etiquetasimpresas = new Array();
    var etiquetas = await concertadas.map((concertada, index)=>{
        if(index == 0){
          this.etiquetasimprimir = '<div style="text-align:center;align-content:center;"><div style="font-size:60px;transform: rotate(90deg) translate(100px, 0px);"><b>' + concertada.DireccionDestinatario + '</b></div></div>';
        }else{
          var espacio = 13 * index + 'cm';  
          this.etiquetasimprimir = this.etiquetasimprimir + '<div style="text-align:center;align-content:center;page-break-before:always"><div style="font-size:60px;transform: rotate(90deg) translate(' + espacio + ', 0px)"><b>' + concertada.DireccionDestinatario + '</b></div></div>';
        }
       return concertada;  
    });
    return etiquetas;
  }

  guardaconcertada(concertada){
    this._concertadasservice.guardarconcertada(concertada).subscribe(()=>{});
  }

  getpendientes(){
    var dia = moment().add(1,'days').day();
    if(dia == 6){
      var siguientedia = moment().add(3,'days').format('DD/MM/YYYY');
    }else if(dia == 0){
      var siguientedia = moment().add(2,'days').format('DD/MM/YYYY');
    }else{
      var siguientedia = moment().add(1,'days').format('DD/MM/YYYY');
    }  
    this.queryactual = {$or:[{impresa:{$exists: false}}, {impresa: 0}], fechaconcertada:siguientedia};
    this.concertadasactuales();
    this.avisopendienteshoy();
  }

  avisopendienteshoy(){
    var query = {$or:[{impresa:{$exists: false}}, {impresa: 0}], fechaconcertada:this.fechahoy};
    var params = {'query': query};
    this._concertadasservice.getconcertadas(params).subscribe(
      response =>{
        if(response.concertadas && response.concertadas.length >= 1){
          this.concertadaspendienteshoy = response.concertadas.length;
        }else{
          this.concertadaspendienteshoy = 0;
        }
      }
    );      
  }

  getpendienteshoy(){
    this.queryactual = {$or:[{impresa:{$exists: false}}, {impresa: 0}], fechaconcertada:this.fechahoy};
    this.concertadasactuales();
  }

  getpendientestot(){
    this.dataSource.data = this.anterioressinimprimir;
  }

  totsinimprimir(){
    var fecha = moment().set({hour:22,minute:0,second:0,millisecond:0}).toISOString();
    var query = {fecha:{$lte:fecha}};
    this._concertadasservice.getcierres(query).subscribe(
      response=>{
        if(response.cierres){
          var cierresanteriores = response.cierres.map((cierre)=>{
            return moment(cierre.fecha).format('DD/MM/YYYY');
          });
          var que = {$or:[{impresa:{$exists: false}}, {impresa: 0}]};
          var params = {'query': que};
          this._concertadasservice.getconcertadas(params).subscribe(
            response =>{
              if(response.concertadas && response.concertadas.length >= 1){
                this.anterioressinimprimir = response.concertadas.filter((concer)=>{
                  if(cierresanteriores.includes(concer.fechaconcertada)){
                    return true;
                  }else{
                    return false;
                  }
                })
              }
            }
          );      
        }
      }  
    );       
  }

  getimpresas(){
    var dia = moment().add(1,'days').day();
    if(dia == 6){
      var siguientedia = moment().add(3,'days').format('DD/MM/YYYY');
    }else if(dia == 0){
      var siguientedia = moment().add(2,'days').format('DD/MM/YYYY');
    }else{
      var siguientedia = moment().add(1,'days').format('DD/MM/YYYY');
    }  
    this.queryactual = {fechaconcertada:siguientedia, impresa:{$gte: 1}};
    this.concertadasactuales();
  }

  getimpresasmensajero(){
    this.queryactual = {impresamensajero:{$exists: true}, Estado:{$nin:['EnvioCancelado', 'EntregaFinalizada', 'EntregaCancelada']}};
    this.concertadasactuales();
  }

  getexpediente(){
    this._incidenciasservice.getexpediente(this.seleccion).subscribe(
      response=>{
          if(response.datos){
            this.listaexpediente = response.datos.reverse();
          }else{
            this.listaexpediente = undefined;
          }  
        },
      error=>{
        console.log(<any>error);
      });
  }

  detalleexpediente(detalle){
    this.seleccionexp = detalle.Id;
   switch(detalle.Tipo){
    case 'Alta':
      var tipo = 'Alta';
      break;
    case 'Operacion':
      var tipo = 'Operacion';
      break;
    case 'Lectura':
      var tipo = 'Lectura';
      break;
    case 'Anomalía':
      var tipo = 'Anomalía';
      break;
    case 'Nota notificada':
      var tipo = 'Notificada';
      break;
    case 'Nota pública':
      var tipo = 'Nota';
      break;  
    default:
      var tipo = 'Alta';
      break;  
   }
   var url = 'https://www2-vpc.mrw.es/gestion3/Operativo/Envios/DetalleExpediente?Arguments=id:' + detalle.Id + '_numcolumna:0_fechaexp:' + detalle.FechaEntrada + '_esNivelBulto:false_numbultocurrent:' + detalle.Bulto + '_processOrder:' + detalle.ProcessOrder + '_tipo:' + tipo + '_numAlbaran:' + detalle.NumeroEnvio + '_idEnvio:' + this.seleccion.Id;
   window.open(url);
  }

  ubicar(concertadas, ubicacion){
    this.mapearubicaciones(concertadas, ubicacion).then((concert)=>{
          if(this.erroresubicacion == 'true'){
            this.aviso('No se pudieron establecer algunas ubicaciones', 'error');
          }else{
            this.aviso('Ubicaciones establecidas correctamente', 'ok');
          }
        switch(this.category){
          case 'totales':
            this.getconcertadas();
            break;
          case 'depositados':
            this.getdepositados();
            break;
          case 'pendientes':
            this.getpendientes();
            break;
          case 'impresas':
            this.getimpresas();
            break;
          default:
            this.getconcertadas();
            break;        
        }  
    });     
  }

  async mapearubicaciones(concertadas, ubicacion){
    this.erroresubicacion = 'false';
    var etiquetas = await concertadas.map((concertada)=>{
        var fecha = moment().format('DD/MM/YYYY').toString();
        var hora = moment().format('HH:mm').toString();
        var concert = {
          IdEnvio:concertada.Id,
          NumeroAlbaran:concertada.NumAlbaran,
          ubicacion:ubicacion,
          fecha:fecha,
          hora:hora
        };
        this._concertadasservice.ubicar(concert).subscribe(
        response=>{
          if(response.datos.errorTitle){
            this.erroresubicacion = 'true';
            this.aviso(response.datos[0], 'error');
          }
        });  
       return concertada;  
    });
    return etiquetas;
  }

  setcierre(fecha){
    var query = {fechaconcertada: fecha};
    var params = {"query": query};
    this._concertadasservice.getconcertadas(params).subscribe(
      response =>{
        if(response.concertadas){
          var cantidad = response.concertadas.length;
          var cierre = {
            usuario: this.identity.nombre,
            fecha: fecha,
            cantidad: cantidad
          };

          this._concertadasservice.setcierre(cierre).subscribe(
            response=>{
                this.proximodialibre();
              },
            error=>{
              console.log(<any>error);
            });
        }
      },
      error =>{
        console.log(<any>error);
      });
  }

  cierradia(){
    var fecha = moment(this.cerrardia).set({hour:22,minute:0,second:0,millisecond:0}).toISOString(true);
    var cierre = {
      usuario: this.identity.nombre,
      fecha: fecha,
      cantidad: 0
    };
    this._concertadasservice.getcierre(fecha).subscribe(
      response=>{
        if(!response.cierre){
          this._concertadasservice.setcierre(cierre).subscribe(
            response=>{
              this.aviso('cerrado dia: ' + moment(fecha).format('DD/MM/YYYY'), 'ok');
              this.cerrardia = undefined;
              this.proximodialibre();
            },
            error=>{
              console.log(<any>error);
          });
        }else{
          this.aviso('dia ya cerrado', 'error');
          this.cerrardia = undefined;
        }
      },
      error=>{
        console.log(<any>error);
    });

  }

  abredia(){
    var fecha = moment(this.cerrardia).set({hour:22,minute:0,second:0,millisecond:0}).toISOString(true);
    var cierre = {
      usuario: this.identity.nombre,
      fecha: fecha,
      cantidad: 0
    };
    this._concertadasservice.borracierre(cierre).subscribe(
      response=>{
          if(response.cierre){
            this.aviso('dia: ' + moment(response.cierre.fecha).format('DD/MM/YYYY') + ' Abierto', 'ok');
            this.cerrardia = undefined;
            this.proximodialibre();
          }
          if(response.message){
            this.aviso(response.message, 'error');
            this.cerrardia = undefined;
            this.proximodialibre();
          }
        },
      error=>{
        console.log(<any>error);
      });
  }

  proximodialibre(){
    this.fechalibre = undefined;
    var fecha = moment().set({hour:21,minute:0,second:0,millisecond:0}).add(1, 'days').toISOString();
    var query = {fecha:{$gte:fecha}};
    this._concertadasservice.getcierres(query).subscribe(
      response=>{
        if(response.cierres){
          var cierres = response.cierres;
          var control = undefined;
          fecha = moment(fecha).format('DD/MM/YYYY');
          var i = 1;
          while(!this.fechalibre){
            control = cierres.findIndex(cierre=>{
              var tmp = moment(cierre.fecha).format('DD/MM/YYYY');
              if(tmp == fecha){
                return true;
              }else{
                return false;
              }  
            });

            if(control <= -1){
              this.fechalibre = fecha;
            }else{
              i++
              fecha = moment().set({hour:22,minute:0,second:0,millisecond:0}).add(i, 'days').format('DD/MM/YYYY'); 
              var controlfinde = moment(fecha, 'DD/MM/YYYY').day();
              if(controlfinde == 0){
                i++
                fecha = moment().set({hour:22,minute:0,second:0,millisecond:0}).add(i, 'days').format('DD/MM/YYYY');
              }
              if(controlfinde == 6){
                i = i + 2;
                fecha = moment().set({hour:22,minute:0,second:0,millisecond:0}).add(i, 'days').format('DD/MM/YYYY');
              }  
            }
          }
        }
      },
    error=>{
      console.log(<any>error);
    });
  }

}


