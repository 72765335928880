import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs/Observable";
import { GLOBAL } from "./global";
import { AppService } from "./app.service";

@Injectable()
export class EmailsService {
  public url: string;
  public identity: string;
  public token: string;
  public cliente: string;

  constructor(public _http: HttpClient, public _appservice: AppService) {
    this.url = GLOBAL.urlemails;
  }

  guardaremail(email, albaran): Observable<any> {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("authorization", this._appservice.getToken());
    let usuario = this._appservice.getIdentity();
    let params = {
      usuario: usuario["nombre"],
      albaran: albaran,
      email: email,
    };
    let cuerpo = JSON.stringify(params);
    return this._http.post(this.url + "savemail", cuerpo, { headers: headers });
  }

  getemails(albaran): Observable<any> {
    let headers = new HttpHeaders().set("Content-Type", "application/json");

    let params = { albaran: albaran };

    let cuerpo = JSON.stringify(params);

    return this._http.post(this.url + "getemails", cuerpo, {
      headers: headers,
    });
  }

  updatemail(email): Observable<any> {
    let headers = new HttpHeaders().set("Content-Type", "application/json");
    let params = { email: email };
    let cuerpo = JSON.stringify(params);
    return this._http.post(this.url + "updatemail", cuerpo, {
      headers: headers,
    });
  }

  savelista(lista, usuario): Observable<any> {
    let headers = new HttpHeaders().set("Content-Type", "application/json");
    let params = { usuario: usuario, emails: lista };
    let cuerpo = JSON.stringify(params);
    return this._http.post(this.url + "savelistacontactos", cuerpo, {
      headers: headers,
    });
  }

  getlista(usuario): Observable<any> {
    let headers = new HttpHeaders().set("Content-Type", "application/json");
    return this._http.get(this.url + "listacontactos/" + usuario, {
      headers: headers,
    });
  }

  imagenfirma(): Observable<any> {
    let headers = new HttpHeaders().append("Content-Type", "application/json");
    return this._http.get(this.url + "getimagenfirma", {
      responseType: "blob",
      headers: headers,
    });
  }

  exportarmail(mensaje): Observable<any> {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("authorization", this._appservice.getToken());
    let params = { mensaje: mensaje };
    let cuerpo = JSON.stringify(params);
    return this._http.post(this.url + "exportar", cuerpo, {
      responseType: "blob",
      headers: headers,
    });
  }

  mailmasivo(mensaje, destinatarios): Observable<any> {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("authorization", this._appservice.getToken());
    let params = { destinatarios: destinatarios, mensaje: mensaje };
    let cuerpo = JSON.stringify(params);
    return this._http.post(this.url + "mailmasivo", cuerpo, {
      headers: headers,
    });
  }

  continuamailmasivo(peticionid, destinatario): Observable<any> {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("authorization", this._appservice.getToken());
    let params = {
      token: this._appservice.getTokenOffice(),
      destinatario: destinatario,
    };
    let cuerpo = JSON.stringify(params);
    return this._http.post(
      this.url + "continuamailmasivo/" + peticionid,
      cuerpo,
      { headers: headers }
    );
  }

  eliminapeticion(peticionid): Observable<any> {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("authorization", this._appservice.getToken());
    return this._http.delete(this.url + "eliminapeticion/" + peticionid, {
      headers: headers,
    });
  }

  getpeticiones(): Observable<any> {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("authorization", this._appservice.getToken());
    return this._http.get(this.url + "getpeticiones", { headers: headers });
  }

  getpeticion(peticionid): Observable<any> {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("authorization", this._appservice.getToken());
    return this._http.get(this.url + "getpeticion/" + peticionid, {
      headers: headers,
    });
  }
}
