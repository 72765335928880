<div class="barraprincipalincidencias">
	<div class="cajaherramientasincidencias overfondo" style="cursor:pointer;padding: 5px;" (click)="guardaconcertada()">
		<mat-icon style="font-size:25pt">save</mat-icon><br>
		Guardar
	</div>
	<div class="cajaherramientasincidenciaslarga" style="padding:5px;">
		<div *ngIf="data.fechaconcertada" style="width:100%;text-align:center;"><strong>Fecha Concertada</strong></div>
		<div *ngIf="data.fechaconcertada" style="width:100%;text-align:center;"><strong>{{data.fechaconcertada}}</strong></div>
		<div *ngIf="data.horarioconcertado" style="width:100%;text-align:center;"><strong>Horario Concertado</strong></div>
		<div *ngIf="data.horarioconcertado" style="width:100%;text-align:center;"><strong>{{data.horarioconcertado}}</strong></div>
	</div>
	<div class="cajaherramientasincidenciaslarga2" style="padding: 5px;">
		<mat-form-field appearance="outline" style="text-align:center;width:100%;font-size:8pt">
		  <mat-label style="color:black;" (click)="picker9.open()"><b>Nueva Fecha Entrega concertada</b></mat-label>
			<input matInput [matDatepickerFilter]="myFilter" [matDatepicker]="picker9" (click)="picker9.open()" [(ngModel)]="fechaconcertacion" (dateChange)="asignarfechaconcertacion()">
		  <button mat-button *ngIf="fechaconcertacion" matSuffix mat-icon-button aria-label="Clear" (click)='cancelafecha()'>
		    <mat-icon>close</mat-icon>
		  </button>
		  <mat-datepicker [dateClass]="dateClass" #picker9></mat-datepicker>
		</mat-form-field>
		<mat-form-field style="width:100%">
			<mat-label>Horario Concertado</mat-label>
			<input type="text" matInput [formControl]="myControl" [matAutocomplete]="auto" (keyUp.enter)="sethorario()">
			<button mat-button matSuffix mat-icon-button aria-label="Clear" (click)="myControl.setValue('')">
				<mat-icon>close</mat-icon>
			</button>
			<mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="sethorario()">
				<mat-option *ngFor="let option of filteredOptions | async" [value]="option">
					{{option}}
				</mat-option>
			</mat-autocomplete>
		</mat-form-field>
	</div>
	<div class="cajaherramientasincidenciaslarga" style="padding:5px;width: 14%">
		<div *ngIf="data.MensajeroEntrega" style="width:100%;text-align:center;font-size:10pt;"><span style="margin-right:8px;">Mensajero Entrega:</span><strong style="font-size:10pt;">{{data.MensajeroEntrega}}</strong>
		</div>
		<div *ngIf="data.ObservacionesEstado" style="width:100%;text-align:center;font-size:10pt;"><span style="margin-right:8px;">Ubicacion:</span><strong style="font-size:10pt;">{{data.ObservacionesEstado}}</strong>
		</div>
	</div>
	<div class="cajaherramientasincidencias overfondo" (click)="imprimirElemento()" style="padding: 5px;cursor:pointer;">
		<mat-icon style="font-size:25pt">print</mat-icon><br>
		Imprimir<br>Etiqueta
	</div>
	<div class="cajaherramientasincidenciaslarga" style="width:14%;">
		<div class="herramientaincidenciaspequena overfondo" style="margin-top:0px;cursor:pointer;" (click)="imprimirmensajero()">
			<mat-icon style="font-size:12pt">map</mat-icon> Etiqueta Mensajero
		</div>
		<div class="herramientaincidenciaspequena overfondo" style="margin-top:8px;margin-bottom:8px;cursor:pointer;" (click)="imprimircalle()">
			<mat-icon style="font-size:12pt">add_road</mat-icon> Imprimir Calle
		</div>
		<div class="herramientaincidenciaspequena overfondo" style="margin-bottom:0px;cursor:pointer;" (click)="concertarAgencia()">
			<mat-icon style="font-size:12pt">maps_home_work</mat-icon> Concertar en Agencia
		</div>
	</div>
	<div class="cajaherramientasincidencias overfondo" style="padding:5px;cursor:pointer;font-size:9pt;width:6%;" (click)="eliminaconcertacion()">
		<mat-icon style="font-size:25pt">delete_forever</mat-icon><br>
		Eliminar<br>concertacion
	</div>	
	<div class="cajaherramientasincidenciaslarga" style="padding:5px; width:10%">
		<div class="herramientaincidenciaspequena2" (click)="intranet(data.NumAlbaran)">
			<mat-icon style="font-size:12pt">language</mat-icon> Intranet
		</div>
		<div *ngIf="dirExpediente" class="herramientaincidenciaspequena2" (click)="expediente()">
			<mat-icon style="font-size:12pt">folder_open</mat-icon> Expediente
		</div>
	</div>
	<div class="cajaherramientasincidenciaslarga2" style="padding: 5px;">
		<mat-form-field style="width:100%">
			<mat-label>Ubicar</mat-label>
			<input type="text" matInput [formControl]="controlUbicaciones" [matAutocomplete]="auto2" (keyUp.enter)="setubicacion(controlUbicaciones.value)">
			<button mat-button matSuffix mat-icon-button aria-label="Clear" (click)="controlUbicaciones.setValue('')">
				<mat-icon>close</mat-icon>
			</button>
			<mat-autocomplete #auto2="matAutocomplete" [displayWith]="displayFn" (optionSelected)="setubicacion(controlUbicaciones.value)">
				<mat-option *ngFor="let option of filteredUbicaciones | async" [value]="option">
					{{option}}
				</mat-option>
			</mat-autocomplete>
		</mat-form-field>
	</div>	
	<div class="cajaherramientasincidencias overfondo" (click)="cancelar()" style="cursor:pointer;padding:5px;">
		<mat-icon style="font-size:25pt">cancel</mat-icon><br>
		Cancelar
	</div>
</div>

<div class="contenedor">
	<div class="datosenvio">
		<div class="remitente">
			Remitente: <strong>{{data.Remitente}}</strong><br>
			Direccion: <strong>{{data.DireccionRemitente}}</strong><br>
			Codigo postal: <strong>{{data.CpRemitente}}</strong><br>
			Poblacion: <strong>{{data.PoblacionRemitente}}</strong><br>
			telefono: <strong>{{data.TelefonoRemitente}}</strong><br>
			Contacto: <strong>{{data.ContactoRecogida}}</strong>
		</div>
		<div class="remitente">
			Destinatario: <strong>{{data.Destinatario}}</strong><br>
			Direccion: <strong>{{data.DireccionDestinatario}}</strong><br>
			Codigo postal: <strong>{{data.CpDestinatario}}</strong><br>
			Poblacion: <strong>{{data.PoblacionDestinatario}}</strong><br>
			telefono: <strong>{{data.TelefonoDestinatario}}</strong><br>
		</div>
		<div class="remitente">
			Cliente: <strong>{{data.CodigoAbonado}}; {{data.NombreCliente}}</strong><br>
			Agencias: <strong>{{data.FranquiciaOrigen}}  </strong><mat-icon>arrow_right_alt</mat-icon><strong> {{data.FranquiciaDestino}}  </strong><mat-icon>euro_symbol</mat-icon><strong>  {{data.FranquiciaClienteFacturacion}} </strong><br>Bultos: <strong>{{data.TotalBultos}}</strong>
			Servicio: <strong>{{data.Servicio}}</strong><br><span *ngIf="data.RangoHorarioRecogidaManana || data.RangoHorarioRecogidaTarde"> Horario Recogida: <strong style="font-size: 10pt">{{data.RangoHorarioRecogidaManana}}<br> {{data.RangoHorarioRecogidaTarde}}</strong></span><br> Generado: <strong>{{data.GeneradoPor}}</strong><br>
			Referencia: <strong>{{data.ReferenciaCliente}}</strong> 

		</div>
	</div>
	<div style="width:50%">
		<div class="remitente" style="width:100%">
			<div style="text-align:center;align-content:center;">
				<strong style="margin-right:20px;text-align:center;align-content:center;font-size:12pt;">
					Reembolso: {{data.ImporteAR}} <mat-icon>euro_symbol</mat-icon>
				</strong>
				<strong style="text-align:center;align-content:center;font-size:14pt;">
					{{data.NumAlbaran}}
				</strong>
				<span *ngIf="data.impresa" style="text-align:center;align-content:center;margin-left:30px;">
					Impresa: {{data.impresa}} Veces
				</span>
			</div>
			<div style="display:inline-flex;">
				<mat-button-toggle-group (change)="botontipoconc($event.value)">
				    <mat-button-toggle value="telefono">Telefono</mat-button-toggle>
				    <mat-button-toggle value="web">Web</mat-button-toggle>
				    <mat-button-toggle value="Mensajero">Mensajero</mat-button-toggle>
				    <mat-button-toggle value="Agencia">Agencia</mat-button-toggle>
				</mat-button-toggle-group>
			</div><br>
			Estado: <strong>{{data.Estado}}</strong><br>
			Anomalia: <strong>{{data.Tipo}}</strong><br>
			Motivo: <strong>{{data.AnomaliaObservaciones}}</strong>
		</div>
		<div class="comentarios" style="display:inline-flex;width:100%">
			<div style="width:50%">
				<mat-form-field class="coments">
				  <mat-label>Observaciones</mat-label>
				  <textarea matInput [(ngModel)]="data.obconcertada" rows="5" placeholder="Observaciones"></textarea>
				</mat-form-field>
			</div>
			<div style="width:50%">
				<div class="btnplantillas">
						 <button mat-raised-button *ngIf="nuevaplantilla2.texto == null && !editplantilla2" (click)="newplantilla2()" matTooltip="Nueva Plantilla"><mat-icon>description</mat-icon></button>
						 <button mat-raised-button *ngIf="plantillaseleccionada2 && !editplantilla2" (click)="editaplantilla2()" matTooltip="Editar Plantilla"><mat-icon>drive_file_rename_outline</mat-icon></button>
						 <button mat-raised-button *ngIf="editplantilla2" (click)="updateplantilla2()" matTooltip="Actualizar Plantilla"><mat-icon>save</mat-icon></button>
						 <button mat-raised-button *ngIf="editplantilla2" (click)="canceleditplantilla2()" matTooltip="Cancelar Edicion Plantilla"><mat-icon>cancel_presentation</mat-icon></button>
						 <button mat-raised-button *ngIf="plantillaseleccionada2 && !editplantilla2" (click)="eliminaPlantilla2(plantillaseleccionada2)" matTooltip="Eliminar Plantilla"><mat-icon>cancel_presentation</mat-icon></button>
						 <button mat-raised-button *ngIf="nuevaplantilla2.texto != null" (click)="saveplantilla2()" matTooltip="Guardar Plantilla"><mat-icon>save</mat-icon></button>
						 <button mat-raised-button *ngIf="nuevaplantilla2.texto != null && !editplantilla2" (click)="cancelplantilla2()" matTooltip="Cancelar"><mat-icon>cancel_presentation</mat-icon></button>
					</div>
					<div class="nplantilla" *ngIf="nuevaplantilla2.texto != null">
						<mat-form-field class="message">
						  <mat-label>Nueva Plantilla</mat-label>
						  <textarea matInput [(ngModel)]="nuevaplantilla2.texto" rows="7" placeholder="Texto plantilla"></textarea>
						</mat-form-field>
					</div>
					<div class="nplantilla" *ngIf="editplantilla2 != null">
						<mat-form-field class="message">
						  <mat-label>Editar Plantilla</mat-label>
						  <textarea matInput [(ngModel)]="editplantilla2.texto" rows="7" placeholder="Texto plantilla"></textarea>
						</mat-form-field>
					</div>
					<ul style="max-height:170px; overflow-y: scroll;">
						<li style="list-style: none; padding: 2px; margin-bottom: 3px; border-bottom: 0.5px solid black" *ngFor="let plantilla2 of plantillas2" (dblclick)="pasartextoobservaciones(plantilla2.texto)" (click)="selecplantilla2(plantilla2)" [class.sinseleccionar]="plantillaseleccionada2 != plantilla2" [class.seleccionada]="plantillaseleccionada2 == plantilla2">
							{{plantilla2.texto}}
						</li>
					</ul>	
				</div>		
		</div>
		<div style="display:inline-flex;width:100%">
			<div style="width:50%">
				<select name="seltiponota" [(ngModel)]="tiponota">
						<option [value]="item" *ngFor="let item of listatiponota">{{item}}</option>
					</select>
					<button mat-raised-button style="margin-left: 10px;" *ngIf="tiponota && notaexpediente" (click)="notag3()" matTooltip="Enviar nota"><mat-icon>read_more</mat-icon>
					</button>
				<mat-form-field class="coments">
					<mat-label>Nota expediente</mat-label>
					<textarea matInput [(ngModel)]="notaexpediente" rows="5" placeholder="Nota Expediente"></textarea>
				</mat-form-field>
			</div>	
			<div style="width:50%">
				<div class="btnplantillas">
					 <button mat-raised-button *ngIf="nuevaplantilla.texto == null && !editplantilla" (click)="newplantilla()" matTooltip="Nueva Plantilla"><mat-icon>description</mat-icon></button>
					 <button mat-raised-button *ngIf="plantillaseleccionada && !editplantilla" (click)="editaplantilla()" matTooltip="Editar Plantilla"><mat-icon>drive_file_rename_outline</mat-icon></button>
					 <button mat-raised-button *ngIf="editplantilla" (click)="updateplantilla()" matTooltip="Actualizar Plantilla"><mat-icon>save</mat-icon></button>
					 <button mat-raised-button *ngIf="editplantilla" (click)="canceleditplantilla()" matTooltip="Cancelar Edicion Plantilla"><mat-icon>cancel_presentation</mat-icon></button>
					 <button mat-raised-button *ngIf="plantillaseleccionada && !editplantilla" (click)="eliminaPlantilla(plantillaseleccionada)" matTooltip="Eliminar Plantilla"><mat-icon>cancel_presentation</mat-icon></button>
					 <button mat-raised-button *ngIf="nuevaplantilla.texto != null" (click)="saveplantilla()" matTooltip="Guardar Plantilla"><mat-icon>save</mat-icon></button>
					 <button mat-raised-button *ngIf="nuevaplantilla.texto != null && !editplantilla" (click)="cancelplantilla()" matTooltip="Cancelar"><mat-icon>cancel_presentation</mat-icon></button>
				</div>
				<div class="nplantilla" *ngIf="nuevaplantilla.texto != null">
					<mat-form-field class="message">
					  <mat-label>Nueva Plantilla</mat-label>
					  <textarea matInput [(ngModel)]="nuevaplantilla.texto" rows="7" placeholder="Texto plantilla"></textarea>
					</mat-form-field>
				</div>
				<div class="nplantilla" *ngIf="editplantilla != null">
					<mat-form-field class="message">
					  <mat-label>Editar Plantilla</mat-label>
					  <textarea matInput [(ngModel)]="editplantilla.texto" rows="7" placeholder="Texto plantilla"></textarea>
					</mat-form-field>
				</div>
				<ul style="max-height:170px; overflow-y: scroll;">
					<li style="list-style: none; padding: 2px; margin-bottom: 3px; border-bottom: 0.5px solid black" *ngFor="let plantilla of plantillas" (dblclick)="pasartextonota(plantilla.texto)" (click)="selecplantilla(plantilla)" [class.sinseleccionar]="plantillaseleccionada != plantilla" [class.seleccionada]="plantillaseleccionada == plantilla">
						{{plantilla.texto}}
					</li>
				</ul>
			</div>	
		</div>			
	</div>
	<div class="datosincidencia" style="width:45%">
		<div style="width:60%">
			<div *ngIf="cantidadimagenes != 0" class="coments" style="position:relative;">
				<div *ngIf="cantidadimagenes >= 1 && numimagen >= 1" style="position:absolute; left:3px; top:145px;" (click)="anteriorimg()"><mat-icon class="fa-arrow-circle-left" style="font-size:25px">arrow_left</mat-icon>
			</div>
				<div *ngIf="cantidadimagenes >= 1 && numimagen < cantidadimagenes -1" style="position:absolute; right:3px; top:145px;" (click)="siguienteimg()"><mat-icon class="fa-arrow-circle-left" style="font-size:25px">arrow_right</mat-icon>
			</div>
				<img style="width:100%;height:300px;" (dblclick)="abreimagenes(data)" [src]="imagenactual"/>
			</div>
			<div *ngIf="cantidadimagenes == 0" style="width:100%;height:300px;position:relative;">
				<div style="position:absolute;top:145px;right:40%">No hay Imagenes...</div>
			</div>
		</div>	
		<div class="datosexpediente">
			<table mat-table [dataSource]="listaexpediente" style="width:100%">
				 <ng-container matColumnDef="Estado">
				    <th mat-header-cell *matHeaderCellDef  style="color:black;text-align:center;padding:1px;"> Estado </th>
				    <td mat-cell *matCellDef="let element"> {{element.Estado}} </td>
				  </ng-container>
				  <ng-container matColumnDef="FechaEntrada">
				    <th mat-header-cell *matHeaderCellDef style="color:black;text-align:center;padding:1px;"> FechaEntrada </th>
				    <td mat-cell *matCellDef="let element"> {{element.FechaEntrada}} </td>
				  </ng-container>
				  <ng-container matColumnDef="HoraEntrada">
				    <th mat-header-cell *matHeaderCellDef style="color:black;text-align:center;padding:1px;"> HoraEntrada </th>
				    <td mat-cell *matCellDef="let element"> {{element.HoraEntrada}} </td>
				  </ng-container>
				  <ng-container matColumnDef="Descripcion">
				    <th mat-header-cell *matHeaderCellDef style="color:black;text-align:center;padding:1px;"> Descripcion </th>
				    <td mat-cell *matCellDef="let element"> {{element.Descripcion}} </td>
				  </ng-container>
				  <ng-container matColumnDef="Detalle">
				    <th mat-header-cell *matHeaderCellDef style="color:black;text-align:center;padding:1px;"> Detalle </th>
				    <td mat-cell *matCellDef="let element"> {{element.Detalle}} </td>
				  </ng-container>
				  <ng-container matColumnDef="Usuario">
				    <th mat-header-cell *matHeaderCellDef style="color:black;text-align:center;padding:1px;"> Usuario </th>
				    <td mat-cell *matCellDef="let element"> {{element.Usuario}} </td>
				  </ng-container>	
				 <tr mat-header-row *matHeaderRowDef="columnasexpediente; sticky:true" style="padding:1px;"></tr>
  				 <tr mat-row *matRowDef="let fila; columns: columnasexpediente;" class="fondooscuro" [class.seleccionada]="seleccionexp == fila.Id" (dblclick)="detalleexpediente(fila)"></tr>
			</table>
		</div>	
	</div>
</div>
