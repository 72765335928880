import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ClienteService } from '../../servicios/cliente.service';
import { Office365Service } from '../../servicios/office365.service';
import { IncidenciasService } from '../../servicios/incidencias.service';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition, MatSnackBarConfig } from '@angular/material/snack-bar';
import { AppService } from '../../servicios/app.service';

@Component({
  selector: 'configuracion',
  templateUrl: './configuracion.component.html',
  styleUrls: ['./configuracion.component.css'],
  providers: [ClienteService, Office365Service, IncidenciasService, AppService]
})

export class ConfiguracionComponent implements OnInit {

	public title: string;
	public identity;
  public status;
  public perfil;
  public abincluir;
  public abini;
  public abfin;
  public newperfil;
  public incidenciadefagregar;
  public listaperfiles;
  public listaoa;

  constructor(
  	private _router: Router,
    private _clienteService: ClienteService,
    private _office365Service: Office365Service,
    private _snackBar: MatSnackBar,
    private _appservice: AppService
    
  ) {
    this.identity = this._appservice.getIdentity();
  }
 
  ngOnInit(): void {
   this.getperfiles();
  }

  enrutar(ruta){
    this._router.navigate([ruta]);
  }

  aviso(texto, subtexto) {
    this._snackBar.open(texto,subtexto,{
      duration: 5000,
      horizontalPosition: "center",
      verticalPosition: "top",
    });
  }
  
  agregaabonado(abd){
      var ab = abd.trim();
      if(this.perfil.abonadosnoincluir.includes(ab)){
        this.aviso('el abonado ya esta incluido', 'error');
      }else{
        this.perfil.abonadosnoincluir.push(ab);
        this._clienteService.guardaperfil(this.perfil.nombre, this.perfil.abonadosnoincluir).subscribe(
          response=>{
            if(response.message == 'perfil guardado correctamente'){
              this.aviso('el abonado: ' + ab + ' ya no es visible', 'ok');
            }else{
              this.aviso('error al guardar', 'error');
            }  
          }
        );
        this.abincluir = undefined;
      }    
  }

  agregaabloque(abi, abf){
    var abinicio = abi.trim();
    var abfin = abf.trim();
    var abant = this.perfil.abonadosnoincluir;

    async function agregabloque(){
      try{
          var abonadoinicio = parseInt(abinicio);
          var abonadofin = parseInt(abfin);
          var i = 0;
          var cantidad = abonadofin - abonadoinicio;
          
          if(cantidad > 1000){
            this.aviso('el/los abonados no pueden ser mas de 1000 a la vez', 'error');
            return {message:'error'};
          }else{  
            if(abonadoinicio > abonadofin){
              this.aviso('el abonado inicial es mayor que el final', 'error');
              return {message:'error'};
            }else{
              for(var i = abonadoinicio; i <= abonadofin; i++){
                var abonado = i.toString().length < 6? '00' + i : i.toString();
                if(abant.includes(abonado) == false){
                  abant.push(abonado);  
                }  
              } 
                return abant;
            }
          
          }  
      
      }catch(error){
        console.log(error);
      }
    }

    agregabloque().then((abant)=>{
      if(!abant.message){
        this._clienteService.guardaperfil(this.perfil.nombre, abant).subscribe(
              response =>{
                  this.aviso('abonados incluidos', 'ok');
                  this.perfil.abonadosnoincluir = abant;
                  this.abfin = null;
                  this.abini = null;
                },
              error =>{
                console.log(<any>error);
              }  
            );
      }        
    });

  }

  eliminaabloque(abi, abf){

    var abinicio = abi.trim();
    var abfin = abf.trim();
    var abant = this.perfil.abonadosnoincluir;

    async function eliminabloque(){
      try{
          var abonadoinicio = parseInt(abinicio);
          var abonadofin = parseInt(abfin);
          var i = 0;
          if(isNaN(abonadoinicio) || isNaN(abonadofin)){
            this.aviso('el/los abonados no son correctos', 'error');
            return {message:'error'};
          }else{  
            if(abonadoinicio > abonadofin){
              this.aviso('el abonado inicial es mayor que el final', 'error');
              return {message:'error'};
            }else{
              
              for(var i = abonadoinicio; i <= abonadofin; i++){
                var abonado = i.toString().length < 6? '00' + i : i.toString();
                if(abant.includes(abonado)){
                   var indice:number = abant.indexOf(abant.find(abnd => abnd == abonado));
                   abant.splice(indice, 1);
                }  
              }
              return abant;
            }
          }  
      
      }catch(error){
        console.log(error);
      }
    }  
    eliminabloque().then((abant)=>{
      if(!abant.message){
        this._clienteService.guardaperfil(this.perfil.nombre, abant).subscribe(
              response =>{
                  this.aviso('abonados eliminados', 'ok');
                  this.perfil.abonadosnoincluir = abant;
                  this.abfin = null;
                  this.abini = null;
                },
              error =>{
                console.log(<any>error);
              }  
            );
      }        
    });

  }


  eliminaabonado(abdo){
    var abonado = abdo.trim();
    if(this.perfil.abonadosnoincluir.includes(abonado)){
      var indice:number = this.perfil.abonadosnoincluir.indexOf(this.perfil.abonadosnoincluir.find(abnd => abnd == abonado));
      this.perfil.abonadosnoincluir.splice(indice, 1);
      this._clienteService.guardaperfil(this.perfil.nombre, this.perfil.abonadosnoincluir).subscribe(
        response =>{
          this.abincluir = null;
          this.abfin = null;
          this.abini = null;
          },
        error =>{
          console.log(<any>error);
        }  
      );
    }else{
      this.aviso('el abonado ya es visible', 'ok');
    }    
  }

   getperfiles(){
    this.listaperfiles = new Array()
    this._clienteService.getperfiles().subscribe(
      response=>{
         if(response.perfiles.length >=1){
          this.listaperfiles = response.perfiles.map(perfil=>{
            return perfil.nombre;
          });
          this.perfil = this.listaperfiles[0];
        }
      },
      error=>{
        console.log(<any>error);
      }  

    );
  }

  borraperfil(){
    this._clienteService.borrarperfil(this.perfil.nombre).subscribe(
      response=>{
          this.aviso(response.message, 'ok');
          this.getperfiles();
        },
      error=>{
        console.log(<any>error);
      }  
    )
  }


  nuevoperfil(nombre){
    if(!nombre){
      this.aviso('falta nombre del perfil', 'error');
    }else{
      let tmp = ['', null, undefined];
      this._clienteService.guardaperfil(nombre.trim(), tmp).subscribe(
        response =>{
          if(response.message){
            this.aviso(response.message, 'ok');
            this.newperfil = "";
            this.getperfiles();
          }  
        },
        error =>{
          console.log(<any>error);
        }  
      );
    }  
  }

  cargaperfil(nombre){
     this._clienteService.getperfil(nombre).subscribe(
      response =>{
          this.perfil = response.perfil;
      },
      error =>{
        console.log(<any>error);
      } 
    );    
  }

  grabaroc(){
    sessionStorage.setItem('identity', JSON.stringify(this.identity));
    this._clienteService.saveCliente(this.identity);
  }

  codigo(){
    this._office365Service.vercodigo().subscribe(
      response =>{
        var vent;
        var cerrar = function(){
          vent.close();
        };
        vent = window.open(response.url);
        setTimeout(cerrar, 10000);
       },   
      error =>{
        console.log(<any>error);
      });
  }

}

