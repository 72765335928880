import {Component, ViewChild, OnInit, AfterViewInit} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { AppService } from '../../servicios/app.service';
import { ClienteService } from '../../servicios/cliente.service';
import { IncidenciasService } from '../../servicios/incidencias.service';
import { Incidencia } from '../../models/incidencia';
import * as moment from 'moment';
import { DomSanitizer } from '@angular/platform-browser';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition, MatSnackBarConfig } from '@angular/material/snack-bar';
import { saveAs } from "file-saver";

@Component({
  selector: 'envioscliente',
  templateUrl: './envioscliente.component.html',
  styleUrls: ['./envioscliente.component.css'],
  providers: [ClienteService, IncidenciasService, AppService]
})
export class EnviosclienteComponent implements OnInit, AfterViewInit {
  public dataSource = new MatTableDataSource<Incidencia>()
  public selection = new SelectionModel<Incidencia>(true, []);
  public filtro;
  public identity;
  public load = true;
  public loaddatos = true;
  public category;
  public estadosnoincluir;
  public envios;
  public totalcolumns;
  public seleccion;
  public urlseguimientomrw;
  public seguimiento;
  public filtros;
  public fechainicio;
  public fechafin;
  public fechai;
  public fechaf;
  public datosentrega = undefined;
  public galleta = false;
  public foto = undefined;

  @ViewChild(MatSort, {static: false}) set content(sort: MatSort){this.dataSource.sort = sort;}
 
  constructor(
    private _clienteService: ClienteService,
    private _incidenciasservice: IncidenciasService,
    private _appservice: AppService,
    private _sanitizer: DomSanitizer,
    private _snackBar: MatSnackBar
  ){
    this.filtros = {
      filtroprincipal: ''
      };
    this.seguimiento = false;
    this.identity = this._appservice.getIdentity();
    this.estadosnoincluir = ['EnvioCancelado'];
    this.totalcolumns = ['FechaRecogida','NumAlbaran','ReferenciaCliente','TelefonoDestinatario', 'TotalBultos', 'Destinatario','DireccionDestinatario', 'ObservacionesEntrega','Estado'];
  }

  ngOnInit(): void {
    var fechai = moment().startOf('month').toISOString();
    var fechaf = moment().toISOString();
    this.getanomalias(fechai, fechaf);
    this.fechainicio = fechai;
    this.fechafin = fechaf;
    this.getgalletag3();
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.content;
  }

  aviso(texto, subtexto) {
    this._snackBar.open(texto,subtexto,{
      duration: 4000,
      horizontalPosition: "center",
      verticalPosition: "top",
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  isAllSelected() {
    if(this.dataSource.filteredData){
      const numSelected = this.selection.selected.length;
      const numRows = this.dataSource.filteredData.length;
      return numSelected === numRows;
    }else{
      const numSelected = this.selection.selected.length;
      const numRows = this.dataSource.data.length;
      return numSelected === numRows;
    }  
  }

 // Selects all rows if they are not all selected; otherwise clear selection.
  masterToggle() {
    if(this.dataSource.filteredData){
        this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.filteredData.forEach(row => this.selection.select(row));
    }else{  
      this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.data.forEach(row => this.selection.select(row));
    }    
  }

  // The label for the checkbox on the passed row 
  checkboxLabel(row?: Incidencia): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  seleccionar(row){
    this.seleccion = row;
    this.urlseguimientomrw = undefined;
    if(this.datosentrega && this.galleta){
      this.foto = undefined;
      this.datosentrega = undefined;
      this.getexpediente();
    }else{
      this.foto = undefined;
      this.datosentrega = undefined;
    }
  }

  getanomalias(fi, ff){
    this.load = false;
    this.seleccion = undefined;
    var query = {
      $and:[{FechaEnvio: {$gt: fi}},{FechaEnvio: {$lt: ff}}],
      Estado:{$nin:this.estadosnoincluir}, 
      FranquiciaClienteFacturacion: '02601' 
    };   
    this._incidenciasservice.getanomaliascliente(query).subscribe(
        response =>{
        if(response.incidencias){
          var contador = 0;
​​          this.dataSource.data = response.incidencias.map((inc, index)=>{
            inc['position']=index;
            if(contador >= index){
              this.load = true;
            }else{
              contador++
            }
            return inc
          });
          this.load = true;
        }else{
          console.log('error');
          this.load = true;
        }
      },
      error =>{
        console.log(<any>error);
        this.load = true;
      } 
    );
  }

  onDateFecha(){
    this.getanomalias(this.fechai, this.fechaf);
  }

  humanizemotivo(motivo){
    var motivohumanizado:string;
    switch(motivo){
      case 'OT0700':
        motivohumanizado = 'Clasificado en plataforma';
        break;
      case 'OE0062': 
        motivohumanizado = 'Depositado en Franquicia de destino';
        break;
      case 'OE0600':
        motivohumanizado = 'Mensajero de entrega asignado';
        break;  
      case 'OR0600':
        motivohumanizado = 'Mensajero de recogida asignado';
        break; 
      case 'OE0500':
        motivohumanizado = 'Recibido en franquicia de destino';
        break;
      case 'OR0200':
        motivohumanizado = 'Recogida finalizada';
        break;
      case 'OS0200':
        motivohumanizado = 'Recogida pendiente';
        break;
      case 'OE0100':
        motivohumanizado = 'Tránsito interno de entrega';
        break;
      case 'OR0402':
        motivohumanizado = 'Envio borrado';
        break;
      case 'OR0201':
        motivohumanizado = 'Recogida finalizada en franquicia';
        break;
      case 'OE0702':
        motivohumanizado = 'Reexpedir Envio';
        break;
      case 'OE0401':
        motivohumanizado = 'Devolver al remitente';
        break;
      case 'OE1103':
        motivohumanizado = 'Cliente Ordena destruir el envio';
        break;
      case 'OE0200':
        motivohumanizado = 'Envío entregado en domicilio';
        break;
      case 'OE0205':
        motivohumanizado = 'Envío entregado en franquicia';
        break;
      case 'OE0102':
        motivohumanizado = 'Cliente ordena destruir el envío'
        break;
        
      default:
        motivohumanizado = motivo;    
    }
    return motivohumanizado;
  }

  verseguimiento(){
    this.seguimiento = true;
    var uri = 'https://www.mrw.es/seguimiento_envios/MRW_resultados_consultas.asp?modo=nacional&envio=' + this.seleccion.NumAlbaran;
    this.urlseguimientomrw = this._sanitizer.bypassSecurityTrustResourceUrl(uri);
  }

  buscarAlbaran(alb){
    this.load = false;
    var albaran = alb.trim();
    this._incidenciasservice.getincidencia(albaran).subscribe(
        response =>{
            if(response.incidencia){
              var contador = 0;
              var incidencias = [response.incidencia];
    ​​          this.dataSource.data = incidencias.map((inc, index)=>{
                inc['position']=index;
                if(contador >= index){
                  this.load = true;
                }else{
                  contador++
                }
                return inc
              });
              this.load = true;
              this.seleccion = this.dataSource.data[0];
            }else{
              this._incidenciasservice.albaranG3(albaran).subscribe(
                response =>{
                  if(response.datos){
                    var contador = 0;
          ​​          this.dataSource.data = response.datos.map((inc, index)=>{
                      if(inc.CodigoAbonado)inc.CodigoAbonado = inc.CodigoAbonado.substring(-1, 6);
                      inc['position']=index;
                      if(contador >= index){
                        this.load = true;
                      }else{
                        contador++
                      }
                      return inc
                    });
                    this.load = true;
                    this.seleccion = this.dataSource.data[0];
                  }else{
                      this.aviso('No se ha encontrado el envio', 'error');
                      this.load = true;
                  }
                },
                error=>{
                  console.log(<any>error);
                  this.load = true;
                });
            }          
        },
        error=>{
          console.log(<any>error);
          this.load = true;
        });        
  }

  buscarReferencia(ref){
    this.load = false;
    var referencia = ref.trim();
    var query = {
      ReferenciaCliente: referencia,
      FranquiciaClienteFacturacion: '02601' 
    };   
    this._incidenciasservice.getanomaliascliente(query).subscribe(
        response =>{
        if(response.incidencias && response.incidencias.length >= 1){
          var contador = 0;
​​          this.dataSource.data = response.incidencias.map((inc, index)=>{
            inc['position']=index;
            if(contador >= index){
              this.load = true;
              this.seleccion = this.dataSource.data[0];
            }else{
              contador++
            }
            return inc
          });
          this.load = true;
        }else{
          this.aviso('no se encuentra la referencia: ' + ref, 'error');
          console.log('error');
          this.load = true;
        }
      },
      error =>{
        this.aviso('no se encuentra la referencia: ' + ref, 'error');
        console.log(<any>error);
        this.load = true;
      } 
    );
  }

  getgalletag3(){
    this._appservice.refreshg3cliente().subscribe(
      response=>{
          sessionStorage.setItem('galletag3', response['cookie']);
          this.galleta = true;
      },
      error=>{
          console.log(error);
      }
    )
  }

  getexpediente(){
    this.loaddatos = false;
    this._incidenciasservice.getexpediente(this.seleccion).subscribe(
      response=>{
        if(response.datos){
          if(this.seleccion.Estado == 'EntregaFinalizada'){
            var linea = response.datos.filter((ln)=>{
              if(ln.Estado == 'Entrega finalizada'){
                return true;
              }else{
                return false;
              }
            });
            let FechaEntrada = moment(linea[0].FechaEntrada, 'DD/MM/YYYY').toISOString();
            this._incidenciasservice.getdetalleexpediente(this.seleccion, linea[0].Id, FechaEntrada).subscribe(
              exp=>{
                var datos = exp.detalle.filter((lin)=>{
                  if(lin.Nombre == 'Datos finalizacion Ent / Rec'){
                    return true;
                  }else{
                    return false;
                  }
                });
                let fechaparts = exp.detalle[1].Detalle.Filas[0].Celdas[1].Valor.split(' ');
                this.datosentrega = {
                  fechahora: datos[0].Detalle.Filas[0].Celdas[1].Valor,
                  receptor: datos[0].Detalle.Filas[2].Celdas[1].Valor,
                  dni: datos[0].Detalle.Filas[4].Celdas[1].Valor
                };
                this._incidenciasservice.getfotoexpediente(this.seleccion.NumAlbaran, this.seleccion.Estado, fechaparts[0], fechaparts[1]).subscribe(
                  res=>{
                    if(res.fotos){
                      this.foto = 'data:image/jpg;base64,' + res.fotos[0].ImageBase64;
                    }
                    this.loaddatos = true;
                  },
                  error=>{
                    this.loaddatos = true;
                  }
                )
            });
          }else{
            this.loaddatos = true;
          }
        }else{
          this.loaddatos = true;
        }
      }
    )
  }

  descargaExcel(){
    var fechas = ['FechaEntrega','FechaEnvio','FechaRecogida','FechaFinalizacionRecogida','FechaConcertacion']
    var datos = this.dataSource.data.map((dato)=>{
      var linea = {};
      this.totalcolumns.forEach((campo)=>{
        if(fechas.includes(campo)){
          linea[campo] = dato[campo]? moment(dato[campo]).format('DD/MM/YYYY') : '';
        }else if(campo == 'Motivo'){
          linea[campo] = this.humanizemotivo(dato[campo]);
        }else{  
          linea[campo] = dato[campo];
        }
      }); 
      return linea;
    });
    this._incidenciasservice.creaexcel(datos).subscribe(
      response =>{
          saveAs(response, 'datos.xlsx');
        },
      error => {
        console.log(<any>error);
      }  
     );
  }

  justificante(){
    this._clienteService.justificante(this.seleccion.NumAlbaran, this._appservice.getgalletag3()).subscribe(
      res=>{
        saveAs(res, 'justificante.pdf');
      }
    )
  }

}