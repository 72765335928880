import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { ClienteService } from '../../servicios/cliente.service';
import { UploadService } from '../../servicios/upload.service';
import { GLOBAL } from '../../servicios/global';

@Component({
  selector: 'app-subirfacturas',
  templateUrl: './subirfacturas.component.html',
  styleUrls: ['./subirfacturas.component.css'],
  providers: [ClienteService, UploadService]
})
export class SubirfacturasComponent implements OnInit {
	  public title: string;
	  public filesToUload: Array<File>;
	  public status: string;
	  public ano;
	  public mes;
	  public url: string;
	  public token;
	  public fecha;
	  public meses;
	  public anoeliminar;
	  public meseliminar;
	  public subtitle;
	  public clientes;
	  public muestraprogreso;

  constructor(
  	private _route: ActivatedRoute,
  	private _router: Router,
    private _clienteService: ClienteService,
    private _uploadService: UploadService
  ) { 
  	this.meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
  	this.token = sessionStorage.getItem('token');
    this.url = GLOBAL.url;
    this.fecha = new Date();
    this.title = "Subir Facturas";
    this.subtitle = "Eliminar Facturas";
	this.muestraprogreso = "";
  }

	ngOnInit(): void {
		this.mes = this.fecha.getMonth() -1;
		this.mes = this.meses[this.mes];
		this.ano = this.fecha.getFullYear();
		this.anoeliminar = this.ano -2;
	}

	fileChangeEvent(fileInput: any){
	  this.filesToUload = <Array<File>>fileInput.target.files;
	}

 	subir(){
	  this.muestraprogreso = 'mostrar';   
	  this._uploadService.makeFacturasRequest(this.filesToUload, this.mes, this.ano, this.token).subscribe(
	    response=>{
	       var abonados = response.abonados;
	       this.status = 'success'
	       //this.avisofacturas(abonados);
	      },
	     error=>{
	      console.log(<any>error);
	     }
	  );    

	}

	borrafacturas(ano){
		this._clienteService.borrarfacturas(ano).subscribe(
			response =>{
					this.status = 'successborrado';
				},
			error =>{
				console.log(<any>error);
			}	
		);
	}

	borrafacturasmes(ano, mes){
		console.log(mes, ano);
		this._clienteService.borrarfacturasmes(ano, mes).subscribe(
			response =>{
				console.log(response);
				this.status = 'successborrado';
			},
			error =>{
				console.log(<any>error);
			}	
		);
	}

	/*avisofacturas(abonados){
		var total = abonados.length;
		this._clienteService.getclientes().subscribe(
			response =>{
			    
			        if(response){
			            this.clientes = response.clientes;
			            var mail = "";
						var nombre = "";
						var client;

			            for(var i = 0; i < total; i++){
							mail = "";
							indice = 0;
							nombre = "";
							client = abonados[i];
			   
				            if(client != "" || client != undefined){
				               var indice:number = this.clientes.indexOf(this.clientes.find(cliente => cliente.numero == client));
				               
				               if(isNaN(indice) || this.clientes[indice] == undefined){
				            
				           	   }else{
					           	   	mail = this.clientes[indice].emailFacturas;
						            nombre = this.clientes[indice].nombre;
						            if(mail != "" || mail != undefined){
					            		this.enviaaviso(mail, client, nombre);
					            	}
				           	   }
				            }

				            
				        }    	

			        }  
			},
			error =>{
			    console.log(<any>error);
			}
		); 
			
			    
	}*/

	enviaaviso(email, abonado, nombre){
	  var enlace = "https://www.mensasur.es";
	  var mensaje = '<div><p>esta recibiendo este email porque se ha subido la factura del mes de '+ this.mes + ' del año ' + this.ano + ' <br> de su abonado: ' + abonado +' - ' + nombre + ' <br> a la plataforma Mensasur Getafe</p> <p>siga el siguiente enlace para acceder: <a href='+ enlace+'>'+enlace+'</a></p></div>';
	  var tmp = "0";
	  var asunto = 'Factura MRW subida'; 
	  this._clienteService.avisomail(tmp, email, mensaje, asunto).subscribe(
	    response=>{
	        console.log(response);
	      },
	    error =>{
	      console.log(<any>error);
	    }  
	  );
	}
}