import { OnInit, Component, ViewChild, AfterViewInit} from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { ClienteService } from '../../servicios/cliente.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { DeudasService } from '../../servicios/deudas.service';
import { IncidenciasService } from '../../servicios/incidencias.service';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition, MatSnackBarConfig } from '@angular/material/snack-bar'; 
import { Cliente } from '../../models/cliente';
import * as moment from 'moment';
import { AppService } from '../../servicios/app.service';

@Component({
  selector: 'app-mensajeros',
  templateUrl: './mensajeros.component.html',
  styleUrls: ['./mensajeros.component.css'],
  providers: [ClienteService, IncidenciasService, DeudasService, AppService]
})
export class MensajerosComponent implements OnInit, AfterViewInit{

  public identity;
  public seleccion;
  public mensajeros;
  public category;
  public dataSource = new MatTableDataSource<Cliente>();
  public totalcolumns;
  public filtros;
  public cobros;
  public totales;
  public queryoptions;
  public listaoa;
  public datos;
  public totaldeudas;
  public columns = [
    {
      columnDef: 'nombre',
      header: 'nombre'
    },
    {
      columnDef: 'role',
      header: 'role'
    },
    {
      columnDef: 'numero',
      header: 'numero'
    },
    {
      columnDef: 'emailsIncidencias',
      header: 'emailsIncidencias'
    }
  ];
  
  public displayedColumns = this.columns.map(c => c.columnDef);
  @ViewChild(MatSort) sort: MatSort;
  
  constructor(
    private _router: Router,
    private _clienteService: ClienteService,
    private _snackBar: MatSnackBar,
    private _incidenciasService: IncidenciasService,
    private _deudasService: DeudasService,
    private _appservice: AppService
    ) {
     this.identity = this._appservice.getIdentity();
     this.totales = {};
     this.filtros = {};
     this.totaldeudas = {};
     this.listaoa = ['000000', '', null];
     this.totalcolumns = ['numero','nombre','direccion','poblacion','cp','telefono','emailsIncidencias','pendiente','observaciones', 'importe', 'Deudas'];
     this.dataSource.data = [];
     this._clienteService.getmensajeros().subscribe(
        response=>{
          this.mensajeros = response.mensajeros;
          if(!this.identity.options.muestramensajeros || this.identity.options.muestramensajeros.length == 0){
            this.identity.options['muestramensajeros'] = this.mensajeros.map((mens)=>{
              return mens.numero;
            }); 
          }
          this.mapearmensajeros();
          this.getcobros();
          this.getdeudas();
         },
      error =>{
        console.log(<any>error);
    });      
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
  }


  aviso(texto, subtexto) {
    this._snackBar.open(texto,subtexto,{
      duration: 4000,
      horizontalPosition: "center",
      verticalPosition: "top",
    });
  }

  actualizacliente(){
    sessionStorage.setItem('identity', JSON.stringify(this.identity));
    this._clienteService.updateCliente(this.identity).subscribe(
      res=>{
        console.log('cliente actualizado');
      }
    );
  }

  seleccionar(row){
    this.seleccion = row;
  }


  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  
  drop(event: CdkDragDrop<string[]>){
    moveItemInArray(this.identity.options['ordencamposreembolsos.mensajeros'], event.previousIndex, event.currentIndex);
    this.actualizacliente();
  }

  categoriaseleccionada(categoria){
    this.category = categoria;
  }

  onselectcolumnas(value){
    this.identity.options.ordencamposreembolsos.mensajeros = value;
    this.actualizacliente();
  }

  onselectcmuestramensajeros(value){
    this.identity.options['muestramensajeros'] = value;
    this.actualizacliente();
    this.mapearmensajeros();
  }


  enrutar(ruta){
    this._router.navigate([ruta]);
  }

  mapearmensajeros(){
    this.dataSource.data = [];
    var fechahoy = moment().format('DD/MM/YYYY');
    this.dataSource.data = this.mensajeros.filter((mens)=>{
      if(this.identity.options.muestramensajeros.includes(mens.numero)){
        return true;
      }else{
        return false;
      }
    }).map((mensajero)=>{
      if(mensajero.pendiente && mensajero.pendiente == fechahoy) mensajero.pendiente = 'cobrado';
      return mensajero;
    }).sort(function(a,b){
      return a.numero > b.numero? 1 : -1;
    });
  }

  mapearcobros(){
    this.cobros = this.datos.map((inc)=>{
        if(inc.AR == 1 && inc.FranquiciaDestino == '02601'){
          if(!this.totales[inc.MensajeroEntrega]) this.totales[inc.MensajeroEntrega] = 0;
          this.totales[inc.MensajeroEntrega] = this.totales[inc.MensajeroEntrega] + inc.ImporteAR;
        }
        return inc;
      });
  }

  getcobros(){
    this.queryoptions = {pagination:false};
    var fechafinal = moment(new Date()).set({hour:23,minute:0,second:0,millisecond:0});
    var params = {
    cobrado:{$ne:true},
    Estado:{$in:['EntregaFinalizada', 'RecogidaFinalizada']},
    FechaEntrega:{$lte:fechafinal},
    FranquiciaDestino:'02601',
    ImporteAR: {$gt: 1}
    };    
    this._incidenciasService.getincidencias(params, this.queryoptions).subscribe(
      response =>{
        if(response.incidencias && response.incidencias.length >= 1){
          this.datos = response.incidencias;
          this.mapearcobros();
        }else{
          this.cobros = [];
        }  
      },
      error =>{
        console.log(<any>error);
      });
  }
 
  getdeudas(){
    var query = {$or:[{cobrada:false},{cobrada:{$exists:false}}]};
    this._deudasService.getdeudas(query).subscribe(
      response=>{
          if(response.deudas){
            response.deudas.map((deuda)=>{
              if(!this.totaldeudas[deuda.mensajero]) this.totaldeudas[deuda.mensajero] = 0;
              this.totaldeudas[deuda.mensajero] = this.totaldeudas[deuda.mensajero] + deuda.importe;
            });
          }  
        },
      error=>{
        console.log(<any>error);
      }  
    );
  }

}