<div class="barraprincipalincidencias">
	<div class="cajaherramientasincidencias overfondo" matTooltip='Guardar y Enviar' style="font-size:10pt;cursor:pointer;padding:5px;" (click)="guardaincidencia()">
		<mat-icon style="font-size:25pt">save</mat-icon><br>
		Guardar
	</div>
	<span class='spacerlargo'></span>
	<div class="cajaherramientasincidencias overfondo" style="cursor:pointer;padding: 5px;" (click)="cancelar()">
		<mat-icon style="font-size:25pt">close</mat-icon><br>
		Cancelar
	</div>
</div>

<div class="contenedor">
	<div class="datosenvio">
		<div class="remitente">
			Fecha Recogida: <strong>{{parsefechahms(data.FechaFinalizacionRecogida)}}</strong><br>
			Remitente: <strong>{{data.Remitente}}</strong><br>
			Direccion: <strong>{{data.DireccionRemitente}}</strong><br>
			Codigo postal: <strong>{{data.CpRemitente}}</strong><br>
			Poblacion: <strong>{{data.PoblacionRemitente}}</strong><br>
			telefono: <strong>{{data.TelefonoRemitente}}</strong><br>
			Km Recogida: <span [class.kmup]="data.KmRemitente > 15"><strong>{{data.KmRemitente}}</strong></span><br>
			Observaciones Recogida: <strong>{{data.ObservacionesRecogida}}</strong><br>
			Contacto: <strong>{{data.ContactoRecogida}}</strong>
		</div>
		<div class="remitente">
			Destinatario: <strong>{{data.Destinatario}}</strong><br>
			Direccion: <strong>{{data.DireccionDestinatario}}</strong><br>
			Codigo postal: <strong>{{data.CpDestinatario}}</strong><br>
			Poblacion: <strong>{{data.PoblacionDestinatario}}</strong><br>
			telefono: <strong>{{data.TelefonoDestinatario}}</strong><br>
			Km Entrega: <span [class.kmup]="data.KmDestinatario > 15"><strong>{{data.KmDestinatario}}</strong></span><br>
			Observaciones Entrega: <strong>{{data.ObservacionesEntrega}}</strong>
		</div>
		<div class="remitente">
			Fecha Envio: <strong>{{parsefecha(data.FechaEnvio)}}</strong><br>
			Cliente: <strong>{{data.CodigoAbonado}}; {{data.NombreCliente}}</strong><br>
			Agencias: <strong>{{data.FranquiciaOrigen}}  </strong><mat-icon>arrow_right_alt</mat-icon><strong> {{data.FranquiciaDestino}}  </strong><mat-icon style="font-size:smaller">euro_symbol</mat-icon><strong>  {{data.FranquiciaClienteFacturacion}} </strong><br>
			Bultos: <strong>{{data.TotalBultos}}</strong> Servicio: <strong>{{data.Servicio}}  {{humanizafrecuencia(data.Frecuencia)}}</strong><br>
			<span *ngIf="data.RangoHorarioRecogidaManana || data.RangoHorarioRecogidaTarde"> Horario Recogida: <strong style="font-size: 10pt">{{data.RangoHorarioRecogidaManana}}<br>
			{{data.RangoHorarioRecogidaTarde}}</strong></span><br>
			Referencia: <strong>{{data.ReferenciaCliente}}</strong><br>
			Retorno: <span class="overfondo" *ngIf="data.NumeroEnvioRetorno"><b>{{data.NumeroEnvioRetorno}}</b></span><br>
			Precursor: <span class="overfondo" *ngIf="data.NumeroAlbaranPrecursor"><b>{{data.NumeroAlbaranPrecursor}}</b></span> 
		</div>
	</div>
	<div class="datosincidencia">
		<div class="remitente">
			Estado: <strong>{{data.Estado}}</strong><br>
			Anomalia: <strong>{{data.Tipo}}</strong><br>
			Motivo: <strong>{{data.AnomaliaObservaciones}}</strong>
		</div>
		<!--<div class="comentarios">
			<mat-form-field class="coments">
			  <mat-label>Direccion correcta</mat-label>
			  <textarea matInput [(ngModel)]="notaexpediente" rows="5" placeholder="direccion..."></textarea>
			</mat-form-field>
		</div>-->
		<div class="comentarios">
			<mat-form-field class="coments">
			  <mat-label>Nota Para Mrw Getafe</mat-label>
			  <textarea matInput [(ngModel)]="notas" rows="5" placeholder="nota..."></textarea>
			</mat-form-field>
		</div>
	</div>
</div>    
	
		
	
	