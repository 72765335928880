import { Component, OnInit, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { FichajeService } from '../../servicios/fichaje.service';
import { ClienteService } from '../../servicios/cliente.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import {MAT_MOMENT_DATE_FORMATS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {FormControl} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition, MatSnackBarConfig } from '@angular/material/snack-bar';
import { DialogfichamensajeroComponent } from '../dialogfichamensajero/dialogfichamensajero.component';


@Component({
  selector: 'fichajeusuarios',
  templateUrl: './fichajeusuarios.component.html',
  styleUrls: ['./fichajeusuarios.component.css'],
  providers:[FichajeService, ClienteService,
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS}
  ]
})
export class FichajeusuariosComponent implements OnInit {
  public fichajes;
  public date = new FormControl(moment());
  public datos;
  public ficha;
  public mensajeros;
  public dataSource = new MatTableDataSource<any>();
  public columns = [
    {
      columnDef: 'nombre',
      header: 'nombre'
    },
    {
      columnDef: 'numero',
      header: 'numero'
    }
  ];
  public displayedColumns = ['Fichado'].concat(this.columns.map(c => c.columnDef)).concat(['eman', 'salman', 'entar', 'saltar']);
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private _fichajeService: FichajeService,
    private _clienteService: ClienteService,
    private dateAdapter: DateAdapter<Date>,
    private _snackBar: MatSnackBar,
    public dialogficha: MatDialog
  ) {
    this.dateAdapter.setLocale('es');
    this.dateAdapter.getFirstDayOfWeek = () => { return 1; };
  }

  ngOnInit(): void {
    this.getusuarios();
  }

  aviso(texto, subtexto) {
    this._snackBar.open(texto,subtexto,{
      duration: 4000,
      horizontalPosition: "center",
      verticalPosition: "top",
    });
  }

  getfichajeshoy(){
    let fechahoy = moment().format('DD/MM/YYYY');
    this.date = new FormControl(moment());
    let query = {fecha:fechahoy};
    this._fichajeService.getfichajes(query).subscribe(
      response=>{
        if(response && response.fichajes){
          this.fichajes = {};
          response.fichajes.forEach(element => {
            this.fichajes[element.usuario] = element;
          });
          this.datos.forEach(element => {
             if(!this.fichajes[element._id]){
               this.fichajes[element._id] = {};
             } 
          });
          this.dataSource.data = this.datos;
        }else{
          this.fichajes = {};
        }
        
      }
    )
  }

  getusuarios(){
    this._clienteService.getusuarios().subscribe(
      response=>{
        this.mensajeros = response.usuarios;
        this.datos = this.mensajeros.sort((a,b)=>{
          return a.numero > b.numero;
        });
        this.getfichajeshoy();
      },
      error=>{
        console.log(<any>error);
    });
  }

  getfichajesdia(dia){
    let query = {fecha:dia};
    this.date = new FormControl(moment(dia, 'DD/MM/YYYY'));
    this._fichajeService.getfichajes(query).subscribe(
      response=>{
        if(response && response.fichajes){
          this.fichajes = {};
          response.fichajes.forEach(element => {
            this.fichajes[element.usuario] = element;
          });
          this.datos.forEach(element => {
             if(!this.fichajes[element._id]){
               this.fichajes[element._id] = {};
             } 
          });
          this.dataSource.data = this.datos;
        }else{
          this.fichajes = {};
        }
        
      }
    )
  }

  sigdia(){
    let diasig = moment(this.date.value).add(1, 'days').format('DD/MM/YYYY');
    this.getfichajesdia(diasig);
  }

  antdia(){
    let diaant = moment(this.date.value).add(-1, 'days').format('DD/MM/YYYY');
    this.getfichajesdia(diaant);
  }

  selectfecha(event){
    let dia = moment(event.value).format('DD/MM/YYYY');
    this.getfichajesdia(dia);
  }

  openDialog(mensajero, campo, campo2){
    if(campo != 'horaentrada' && !this.fichajes[mensajero].horaentrada){
      this.aviso('falta hora de entrada', 'error');
    }else{
      if(this.fichajes[mensajero]._id){
        this.ficha = this.fichajes[mensajero];
        this.ficha.campo = campo;
        this.ficha.campo2 = campo2;
      }else{
        this.ficha = {
          usuario: mensajero,
          fecha: moment(this.date.value).format('DD/MM/YYYY'),
          estado:'sin datos',
          campo: campo,
          campo2: campo2
        }
      }
      const dialogRef = this.dialogficha.open(DialogfichamensajeroComponent,{
        width: '20%',
        height: '35%',
        hasBackdrop: false,
        disableClose: true,
        data: this.ficha
      });

      dialogRef.afterClosed().subscribe(result => {
        this.aviso(result, 'ok');
        this.getfichajesdia(moment(this.date.value).format('DD/MM/YYYY'));
      }); 
    }  
  }

}

