import { Component, OnInit, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { FichajeService } from '../../servicios/fichaje.service';
import { ClienteService } from '../../servicios/cliente.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import {MAT_MOMENT_DATE_FORMATS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {FormControl} from '@angular/forms';
import { IncidenciasService } from '../../servicios/incidencias.service';
import { saveAs } from "file-saver";

@Component({
  selector: 'fichajes',
  templateUrl: './fichajes.component.html',
  styleUrls: ['./fichajes.component.css'],
  providers:[FichajeService, ClienteService, IncidenciasService,
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS}
  ]
})
export class FichajesComponent implements OnInit {
  public fichajes;
  public date = new FormControl(moment());
  public datos;
  public usuarios;
  public mensajeros;
  public usuariosseleccionados;
  public fechainicio = moment();
  public fechafin = moment();
  public dataSource = new MatTableDataSource<any>();
  public dataSource2 = new MatTableDataSource<any>();
  public busqueda = false;
  public columns = [
    {
      columnDef: 'nombre',
      header: 'nombre'
    },
    {
      columnDef: 'numero',
      header: 'numero'
    }
  ];
  public displayedColumns = ['Fichado'].concat(this.columns.map(c => c.columnDef)).concat(['eman', 'salman', 'entar', 'saltar']);
  public displayedColumns2 = ['nombre','numero','fecha', 'horaentrada','horasalida','horaentrada2','horasalida2'];
  @ViewChild(MatSort) sort: MatSort;
  //@ViewChild('#sort2') sort2: MatSort;

  constructor(
    private _fichajeService: FichajeService,
    private _clienteService: ClienteService,
    private _incidenciasservice: IncidenciasService,
    private dateAdapter: DateAdapter<Date>,
  ) {
    this.dateAdapter.setLocale('es');
    this.dateAdapter.getFirstDayOfWeek = () => { return 1; };
  }

  ngOnInit(): void {
    this.getusuarios();
  }

  ngAfterViewInit(): void {
    this.dataSource2.sort = this.sort;
    //this.dataSource2.sort = this.sort2;
  }

  getfichajeshoy(){
    let fechahoy = moment().format('DD/MM/YYYY');
    this.date = new FormControl(moment());
    let query = {fecha:fechahoy};
    this._fichajeService.getfichajes(query).subscribe(
      response=>{
        if(response && response.fichajes){
          this.fichajes = {};
          response.fichajes.forEach(element => {
            this.fichajes[element.usuario] = element;
          });
          this.datos.forEach(element => {
             if(!this.fichajes[element._id]){
               this.fichajes[element._id] = {};
             } 
          });
          this.dataSource.data = this.datos;
        }else{
          this.fichajes = {};
        }
        
      }
    )
  }

  getusuarios(){
    this._clienteService.getusuarios().subscribe(
  		response=>{
  			this.usuarios = response.usuarios;
        this._clienteService.getmensajeros().subscribe(
          response=>{
            this.mensajeros = response.mensajeros;
            this.datos = this.usuarios.concat(this.mensajeros).sort((a,b)=>{
              return a.numero > b.numero;
            });
            this.getfichajeshoy();
          },
          error=>{
            console.log(<any>error);
        });
  			},
  		error=>{
  			console.log(<any>error);
  	});
  }

  getfichajesdia(dia){
    let query = {fecha:dia};
    this.date = new FormControl(moment(dia, 'DD/MM/YYYY'));
    this._fichajeService.getfichajes(query).subscribe(
      response=>{
        if(response && response.fichajes){
          this.fichajes = {};
          response.fichajes.forEach(element => {
            this.fichajes[element.usuario] = element;
          });
          this.datos.forEach(element => {
             if(!this.fichajes[element._id]){
               this.fichajes[element._id] = {};
             } 
          });
          this.dataSource.data = this.datos;
        }else{
          this.fichajes = {};
        }
        
      }
    )
  }

  sigdia(){
    let diasig = moment(this.date.value).add(1, 'days').format('DD/MM/YYYY');
    this.getfichajesdia(diasig);
  }

  antdia(){
    let diaant = moment(this.date.value).add(-1, 'days').format('DD/MM/YYYY');
    this.getfichajesdia(diaant);
  }

  selectfecha(event){
    let dia = moment(event.value).format('DD/MM/YYYY');
    this.getfichajesdia(dia);
  }

  //filtros

  buscarfichajes(){
    let fi = moment(this.fechainicio).set({hour:3,minute:0,second:0,millisecond:0}).toISOString(true);
    let ff = moment(this.fechafin).set({hour:21,minute:0,second:0,millisecond:0}).toISOString(true);
    if(this.usuariosseleccionados && this.usuariosseleccionados.length >=1){
      var users = this.usuariosseleccionados;
    }else{
      var users = this.datos.map(us=>{return us._id});
    }
    let query = {
      $and:[{fechahoraentrada:{$gte:fi}}, {fechahoraentrada:{$lte:ff}}], usuario:{$in:users}
    }
    this._fichajeService.getfichajespop(query).subscribe(
      response=>{
        if(response && response.fichajes){
          this.busqueda = true;
          this.dataSource2.data = response.fichajes.sort(function(a,b){
            return a.usuario.nombre > b.usuario.nombre;
          });
        }
      }
    )        
  }

  cancelabusqueda(){
    this.busqueda = false;
    this.getfichajesdia(new Date());
  }

  exportaexcel(){
    var datos = this.dataSource2.data.map((dato)=>{
      var linea = {
        NOMBRE: dato.usuario.nombre,
        NUMERO: dato.usuario.numero,
        FECHA: dato.fecha,
        ENTRADA: dato.horaentrada,
        SALIDA: dato.horasalida,
        'ENTRADA TARDE' : dato.horaentrada2,
        'SALIDA TARDE' : dato.horasalida2
      };
      return linea;
    });
    this._incidenciasservice.creaexcel(datos).subscribe(
      response =>{
          saveAs(response, 'datos.xlsx');
        },
      error => {
        console.log(<any>error);
      }  
     );


  }
  

}
