import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs/Observable";
import { AppService } from "./app.service";
import { GLOBAL } from "./global";

@Injectable()
export class ModificadoService {
  public url: string;

  constructor(public _http: HttpClient, public _appservice: AppService) {
    this.url = GLOBAL.urlmodificados;
  }

  getmodificados(query): Observable<any> {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("authorization", this._appservice.getToken());
    var cuerpo = { query: query };
    var params = JSON.stringify(cuerpo);

    return this._http.post(this.url + "albaranes", params, {
      headers: headers,
    });
  }

  getmodificado(albaran): Observable<any> {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("authorization", this._appservice.getToken());
    return this._http.get(this.url + "albaran/" + albaran, {
      headers: headers,
    });
  }

  guardar(incidencia): Observable<any> {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("authorization", this._appservice.getToken());
    var tmp = { albaran: incidencia };
    var params = JSON.stringify(tmp);
    return this._http.post(this.url + "guardar", params, { headers: headers });
  }

  borrar(albaran): Observable<any> {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("authorization", this._appservice.getToken());
    var tmp = { albaran: albaran };
    var params = JSON.stringify(tmp);
    return this._http.post(this.url + "borrar", params, { headers: headers });
  }
}
