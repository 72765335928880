import { ModuleWithProviders } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { LoginComponent } from "./components/login/login.component";
import { InicioComponent } from "./components/inicio/inicio.component";
import { FacturasComponent } from "./components/facturas/facturas.component";
import { CarpetasComponent } from "./components/carpetas/carpetas.component";
import { RegistroComponent } from "./components/registro/registro.component";
import { DatosclienteComponent } from "./components/datoscliente/datoscliente.component";
import { RestablecerpassComponent } from "./components/restablecerpass/restablecerpass.component";
import { PassolvidadaComponent } from "./components/passolvidada/passolvidada.component";
import { ClientesComponent } from "./components/clientes/clientes.component";
import { MisarchivosComponent } from "./components/misarchivos/misarchivos.component";
import { ArchivosclientesComponent } from "./components/archivosclientes/archivosclientes.component";
import { ContactoComponent } from "./components/contacto/contacto.component";
import { SubirfacturasComponent } from "./components/subirfacturas/subirfacturas.component";
import { ConfiguracionComponent } from "./components/configuracion/configuracion.component";
import { MensajerosComponent } from "./components/mensajeros/mensajeros.component";
import { IncidenciasComponent } from "./components/incidencias/incidencias.component";
import { GestionusuariosComponent } from "./components/gestionusuarios/gestionusuarios.component";
import { AlbaranComponent } from "./components/albaran/albaran.component";
import { RegusuariosComponent } from "./components/regusuarios/regusuarios.component";
import { AdmintabComponent } from "./components/admintab/admintab.component";
import { ImagenesexpedienteComponent } from "./components/imagenesexpediente/imagenesexpediente.component";
import { MailcorporativoComponent } from "./components/mailcorporativo/mailcorporativo.component";
import { ConcertadasComponent } from "./components/concertadas/concertadas.component";
import { CobromensajeroComponent } from "./components/cobromensajero/cobromensajero.component";
import { DireccionesmensajeroComponent } from "./components/direccionesmensajero/direccionesmensajero.component";
import { MensajeroComponent } from "./components/mensajero/mensajero.component";
import { DeudasComponent } from "./components/deudas/deudas.component";
import { GestiondeudasComponent } from "./components/gestiondeudas/gestiondeudas.component";
import { VehiculosComponent } from "./components/vehiculos/vehiculos.component";
import { VehiculoComponent } from "./components/vehiculo/vehiculo.component";
import { NuevoenvioComponent } from "./components/nuevoenvio/nuevoenvio.component";
import { GastosComponent } from "./components/gastos/gastos.component";
import { MisenviosComponent } from "./components/misenvios/misenvios.component";
import { NoclientesComponent } from "./components/noclientes/noclientes.component";
import { GestionenviosComponent } from "./components/gestionenvios/gestionenvios.component";
import { MailmasivoclientesComponent } from "./components/mailmasivoclientes/mailmasivoclientes.component";
import { VentascomercialComponent } from "./components/ventascomercial/ventascomercial.component";
import { DescargaenlaceComponent } from "./components/descargaenlace/descargaenlace.component";

const appRoutes: Routes = [
  { path: "", component: InicioComponent },
  { path: "login", component: LoginComponent },
  { path: "login/:anterior", component: LoginComponent },
  { path: "inicio", component: InicioComponent },
  { path: "facturas/:carpeta", component: FacturasComponent },
  { path: "facturas/:carpeta/:id", component: FacturasComponent },
  { path: "carpetas", component: CarpetasComponent },
  { path: "carpetas/:id/:numero", component: CarpetasComponent },
  { path: "registro/:admin/:pass", component: RegistroComponent },
  { path: "registro", component: RegistroComponent },
  { path: "registro/:abonado", component: RegistroComponent },
  { path: "cliente/:id", component: DatosclienteComponent },
  { path: "cambiar-password/:id", component: RestablecerpassComponent },
  { path: "olvidada", component: PassolvidadaComponent },
  { path: "clientes", component: ClientesComponent },
  { path: "archivos", component: MisarchivosComponent },
  { path: "archivos/:id", component: MisarchivosComponent },
  { path: "archivosclientes", component: ArchivosclientesComponent },
  { path: "contacto/:email/:departamento", component: ContactoComponent },
  { path: "subirfacturas", component: SubirfacturasComponent },
  { path: "configuracion", component: ConfiguracionComponent },
  { path: "mensajero/:numero", component: MensajeroComponent },
  { path: "mensajeros", component: MensajerosComponent },
  { path: "incidencias", component: IncidenciasComponent },
  { path: "gestionusuarios", component: GestionusuariosComponent },
  { path: "albaran/:albaran", component: AlbaranComponent },
  { path: "nuevousuario", component: RegusuariosComponent },
  { path: "admintab", component: AdmintabComponent },
  { path: "admintab/:categoria", component: AdmintabComponent },
  { path: "imagenesexpediente", component: ImagenesexpedienteComponent },
  { path: "mailcorporativo", component: MailcorporativoComponent },
  { path: "concertadas", component: ConcertadasComponent },
  { path: "cobromensajero", component: CobromensajeroComponent },
  { path: "direccionesmensajero", component: DireccionesmensajeroComponent },
  { path: "deudasmensajero", component: DeudasComponent },
  { path: "deudasmensajero/:numero/:admin", component: DeudasComponent },
  { path: "gestiondeudas", component: GestiondeudasComponent },
  { path: "vehiculo/:id", component: VehiculoComponent },
  { path: "vehiculos", component: VehiculosComponent },
  { path: "nuevoenvio", component: NuevoenvioComponent },
  { path: "misenvios", component: MisenviosComponent },
  { path: "concertar/:envio", component: NoclientesComponent },
  { path: "gestion-envios", component: GestionenviosComponent },
  { path: "emailmasivo", component: MailmasivoclientesComponent },
  { path: "comercial", component: VentascomercialComponent },
  { path: "descarga/:id", component: DescargaenlaceComponent },

  { path: "**", component: InicioComponent },
];

export const appRoutingProviders: any[] = [];
export const routing: ModuleWithProviders<any> = RouterModule.forRoot(
  appRoutes,
  { relativeLinkResolution: "legacy" }
);
