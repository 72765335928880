<div class="barraprincipalincidencias">
	<div *ngIf="identity && identity.role == 'Administrador'" class="cajaherramientasincidencias">
		<div class="herramientaincidenciaspequena" (click)="categoriaseleccionada('Clientes')" [class.selectcategory]="category == 'Clientes'">
			<mat-icon class="material-icons-outlined peque">people</mat-icon> Clientes
		</div>
		<div class="herramientaincidenciaspequena" (click)="categoriaseleccionada('SubirFacturas')" [class.selectcategory]="category == 'SubirFacturas'">
			<mat-icon class="material-icons-outlined peque">drive_folder_upload</mat-icon> Subir Facturas
		</div>
		<div class="herramientaincidenciaspequena" (click)="categoriaseleccionada('ArchivosClientes')" [class.selectcategory]="category == 'ArchivosClientes'">
			<mat-icon class="material-icons-outlined peque">folder_special</mat-icon> Archivos nuevos
		</div>
		<div class="herramientaincidenciaspequena" (click)="categoriaseleccionada('Cobrofacturas')" [class.selectcategory]="category == 'Cobrofacturas'">
			<mat-icon class="material-icons-outlined peque">description</mat-icon> Cobro Facturas
		</div>
		Gestion Clientes
	</div>
	<div class="cajaherramientasincidencias">
		<div *ngIf="identity && identity.role == 'Administrador'" class="herramientaincidenciaspequena" (click)="categoriaseleccionada('Gestiondeudas')" [class.selectcategory]="category == 'Gestiondeudas'">
			<mat-icon class="material-icons-outlined peque">toll</mat-icon> Gestion Deudas
		</div>
		<div class="herramientaincidenciaspequena" (click)="categoriaseleccionada('listaentregas')" [class.selectcategory]="category == 'listaentregas'">
			<mat-icon class="material-icons-outlined peque">compare</mat-icon> Comparativas
		</div>
		<div class="herramientaincidenciaspequena" (click)="categoriaseleccionada('emailmasivo')" [class.selectcategory]="category == 'emailmasivo'">
			<mat-icon class="material-icons-outlined peque">forward_to_inbox</mat-icon> Email Masivo
		</div>
		<div class="herramientaincidenciaspequena" (click)="categoriaseleccionada('ventas')" [class.selectcategory]="category == 'ventas'">
			<mat-icon class="material-icons-outlined peque">query_stats</mat-icon> Ventas
		</div>
	</div>
	<div class="cajaherramientasincidencias">
		<mat-form-field appearance="outline" style="text-align:center;width:100%;">
			<mat-label style="color:black;"><b>Buscar Albaran</b></mat-label>
			<input matInput type="text" [(ngModel)]="filtros['NumAlbaran']" style="padding:1px;color:black;"
				(keyup.enter)="bucaryabrir(filtros['NumAlbaran'])" (ngModelChange)="filtros['NumAlbaran'] = $event.toUpperCase()">
			<button mat-button *ngIf="filtros['NumAlbaran']" matSuffix mat-icon-button aria-label="Clear" (click)="filtros['NumAlbaran']='';">
				<mat-icon>close</mat-icon>
			</button>
		</mat-form-field>
	</div>
	<div class="cajaherramientasincidencias">
		<div *ngIf="identity && identity.role == 'Administrador'" class="herramientaincidenciaspequena" (click)="categoriaseleccionada('ventascomercial')" [class.selectcategory]="category == 'ventascomercial'">
			<mat-icon class="material-icons-outlined peque">monetization_on</mat-icon> Ventas Candida
		</div>
		<div *ngIf="identity && identity.role == 'Administrador'" class="herramientaincidenciaspequena" (click)="categoriaseleccionada('adminenviosclientes')" [class.selectcategory]="category == 'adminenviosclientes'">
			<mat-icon class="material-icons-outlined peque">speaker_notes</mat-icon> Solicitudes Clientes
		</div>
		<div *ngIf="identity && identity.role == 'Administrador'" class="herramientaincidenciaspequena" (click)="categoriaseleccionada('fichajes')" [class.selectcategory]="category == 'fichajes'">
			<mat-icon class="material-icons-outlined peque">login</mat-icon> Fichajes
		</div>
	</div>	
</div>
<div class="contenidoadmin" *ngIf="category == 'Clientes'">
	<app-clientes></app-clientes>
</div>
<div class="contenidoadmin" *ngIf="category == 'SubirFacturas'">
	<app-subirfacturas></app-subirfacturas>
</div>
<div class="contenidoadmin" *ngIf="category == 'ArchivosClientes'">
	<app-archivosclientes></app-archivosclientes>
</div>
<div class="contenidoadmin" *ngIf="category == 'Gestiondeudas'">
	<app-gestiondeudas></app-gestiondeudas>
</div>
<div class="contenidoadmin" *ngIf="category == 'Cobrofacturas'">
	<app-cobrofacturas></app-cobrofacturas>
</div>
<div class="contenidoadmin" *ngIf="category == 'listaentregas'">
	<app-comparafacturacentral></app-comparafacturacentral>
</div>
<div class="contenidoadmin" *ngIf="category == 'emailmasivo'">
	<mailmasivoclientes></mailmasivoclientes>
</div>
<div class="contenidoadmin" *ngIf="category == 'ventas'">
	<ventas></ventas>
</div>
<div class="contenidoadmin" *ngIf="category == 'ventascomercial'">
	<ventascomercial></ventascomercial>
</div>
<div class="contenidoadmin" *ngIf="category == 'adminenviosclientes'">
	<adminenviosclientes></adminenviosclientes>
</div>
<div class="contenidoadmin" *ngIf="category == 'fichajes'">
	<fichajes></fichajes>
</div>