import { Component, OnInit, ViewChild } from '@angular/core';
import { ClienteService } from '../../servicios/cliente.service';
import { AppService } from '../../servicios/app.service';
import * as moment from 'moment';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'ventascomercial',
  templateUrl: './ventascomercial.component.html',
  styleUrls: ['./ventascomercial.component.css'],
  providers: [ClienteService, AppService]
})
export class VentascomercialComponent implements OnInit {
  public emailclienteseleccionado;
  public contactoclienteseleccionado;
  public totalclientes;
  public clientes;
  public clientesactivos;
  public clientessuspendidos;
  public identity;
  public facturastotales;
  public clienteseleccionado;
  public facturasclienteseleccionado;
  public facturasclienteano;
  public facturasclienteanoant;
  public totalfacturas;
  public cecoarr;
  public load = true;
  public anoactual = moment().format('YYYY');
  public anoanterior = (parseInt(this.anoactual)-1);
  public dataSource = new MatTableDataSource<any>();
  public columns = [
    {
      columnDef: 'Abonado',
      header: 'Abonado'
    },
    {
      columnDef: 'Descripcion',
      header: 'Nombre'
    },
    {
      columnDef: 'Totalano',
      header: 'Total'
    }
  ];
  public columnasfacturas = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre','Diciembre'];
  public displayedColumns = this.columns.map(c => c.columnDef);
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private _clienteService: ClienteService,
    private _appservice: AppService
  ) {
    this.identity = this._appservice.getIdentity();
  }

  ngOnInit(): void {
    this.getFacturas();
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
  }

  getFacturas(){
    this.load = false;
    var query = {};
  	this._clienteService.getfacturasg3(query).subscribe(
  		response=>{
        this.facturastotales = response.facturas.filter(factura=>{
          let fecha = moment().add(-7, 'months');
          let fechafactura = moment(factura.FechaParticion);
          if(fechafactura >= fecha){
            return true;
          }else{
            return false;
          }  
        });
        this.getcecos();
  		},
  		error=>{
  			console.log(<any>error);	
  		}	
  	)
  }

  importeanoactual(abonado){
    return parseFloat(this.facturastotales.filter((factura)=>{
      let fecha = moment(factura.FechaParticion).format('YYYY');
      if(factura.Abonado == abonado && fecha == this.anoactual){
        return true;
      }else{
        return false;
      }
    }).reduce(function(accumulator, item){
      const toNumber = parseFloat(item.ImporteFactura);
      return accumulator + toNumber;
    },0));
  }

  importetotal(){
    var cecoarr = this.clientes.map((cliente)=>{
      return cliente.Abonado;
    });
    return parseFloat(this.facturastotales.filter((factura)=>{
      if(cecoarr.includes(factura.Abonado)){
        return true;
      }else{
        return false;
      }
    }).reduce(function(accumulator, item){
      const toNumber = parseFloat(item.ImporteFactura);
      return accumulator + toNumber;
    },0));
  }

  facturascliente(abonado){
    return this.facturastotales.filter((factura)=>{
      if(factura.Abonado == abonado){
        return true;
      }else{
        return false;
      }
    });
  }

  getcecos(){
    this.totalfacturas = 0;
    let fecha = moment().add(-7,'months');
    let query = {
      "datosAbonadoEdit.FechaAltaDeFranquicia": {$gte:fecha}
    }
    this._clienteService.getcecos(query).subscribe(
      response=>{
        if(response.cecos){
          var hash = {};
          this.totalclientes = response.cecos;
          this.clientes = response.cecos.filter(ceco=>{
            let abinicio = ceco.Abonado.slice(0,1);
            let abon = parseInt(ceco.Abonado);
            if(abinicio == '1' || abinicio == '2'){
              return false;
            }else if(abon >= 7000){
              return true;
            }else{
              return false;
            }
          }).filter((ab)=>{
            if(hash[ab.Abonado]){
              return false;
            }else{
              hash[ab.Abonado] = true;
              return true;
            }
          }).map((abonado)=>{
            abonado['Totalano'] = (this.importeanoactual(abonado.Abonado)).toFixed(2);
            return abonado;
          }).sort(function(a,b){
            let origen = parseInt(a.Abonado);
            let destino = parseInt(b.Abonado);
            return origen > destino;
          });
          this.clientesactivos = this.clientes.filter(cli=>cli.CodigoEstado == 'A');
          this.clientessuspendidos = this.clientes.filter(cli=>cli.CodigoEstado != 'A');
          }
          this.dataSource.data = this.clientes;
          this.clienteseleccionado = this.clientes[0];
          this.load = true;
      }
    )
  }

  importemes(mes, a){
    let ano = a.toString();
    if(this.facturasclienteseleccionado[ano] && this.facturasclienteseleccionado[ano][mes]){
      return this.facturasclienteseleccionado[ano][mes]
    }else{
      return 0.00;
    }
  }

  importeano(a){
    let ano = a.toString();
    if(this.facturasclienteseleccionado[ano] && this.facturasclienteseleccionado[ano].total){
      return this.facturasclienteseleccionado[ano].total
    }else{
      return 0.00;
    }
  }

  crearfacturascliente(abonado){
    var factcliente = this.facturascliente(abonado);
    this.facturasclienteseleccionado = {};
    this.facturasclienteseleccionado['anos'] = new Array();
    factcliente.forEach((fact)=>{
      let fecha = moment(fact.FechaParticion);
      let ano = moment(fecha).format('YYYY');
      let mes = moment(fecha).format('MM');
      if(this.facturasclienteseleccionado[ano]){
        if(this.facturasclienteseleccionado[ano][mes]){
          this.facturasclienteseleccionado[ano][mes] = this.facturasclienteseleccionado[ano][mes] + fact.ImporteFactura;
          this.facturasclienteseleccionado[ano]['total'] = this.facturasclienteseleccionado[ano]['total'] + fact.ImporteFactura
        }else{
          this.facturasclienteseleccionado[ano][mes] = fact.ImporteFactura;
          this.facturasclienteseleccionado[ano]['total'] = fact.ImporteFactura + this.facturasclienteseleccionado[ano]['total']
        }
      }else{
        this.facturasclienteseleccionado.anos.push(ano);
        this.facturasclienteseleccionado.anos.sort((a,b)=>{
          return parseInt(a) < parseInt(b);
        });
        this.facturasclienteseleccionado[ano] = {};
        this.facturasclienteseleccionado[ano][mes] = fact.ImporteFactura;
        this.facturasclienteseleccionado[ano]['total'] = fact.ImporteFactura;
      }
    });
  }

  seleccionar(row){
    this.clienteseleccionado = this.clientes.find(cliente=>cliente.Abonado == row.Abonado);
    this.crearfacturascliente(this.clienteseleccionado.Abonado);
  }

  verano(ano){
    console.log(ano);
  }

  abreabonadog3(abonado){
    let dir = 'https://www2-vpc.mrw.es/gestion3/Comercial/Clientes/EdicionCliente?Arguments=' + abonado + '_clienteEdit'
    window.open(dir);
  }


}
