<div style="padding-left: 20px;">
	<mat-form-field appearance="standard">
		<mat-label style="width:200px;font-size:11pt;color:black;">Buscar</mat-label>
		<input matInput (keyup)="applyFilter($event)" placeholder="buscar.." [(ngModel)]="filtro">
		<button mat-button *ngIf="filtro" matSuffix mat-icon-button aria-label="Clear" (click)="dataSource.filter = ''; filtro=''">
			<mat-icon>close</mat-icon>
		</button>
	</mat-form-field>
</div>
<div style="width:100%;display:inline-flex">
	<div class="facturas">
		<table mat-table *ngIf="dataSource && identity" [dataSource]="dataSource" matSort>
			<ng-container *ngFor="let column of columns" [matColumnDef]="column.header">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>
					{{column.header}}
				</th>
				<td mat-cell *matCellDef="let element">
					{{element[column.nombre]}}
				</td>
			</ng-container>
			<ng-container matColumnDef="disclaimer">
				<td mat-footer-cell *matFooterCellDef colspan="2">
					{{dataSource.data.length}} Clientes
				</td>
			</ng-container>
			<tr mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></tr>
			<tr mat-row *matRowDef="let row; columns: displayedColumns;" class="celdas" (click)="selectcliente(row)" (dblclick)="abrecliente(row._id)"></tr>
			<tr mat-footer-row *matFooterRowDef="['disclaimer'];sticky:true"></tr>
		</table>
	</div>
	<div *ngIf="seleccion && abonado" class="clientdata">
		<datoscliente [cliente]="seleccion" [abonado]="abonado"></datoscliente>
	</div>
</div>




