
export class Deuda{
	constructor(
		public _id:string,
		public mensajero: string,
		public fechadeuda: string,
		public importe: number,
		public motivo: string,
		public cobrada: boolean,
		public fechacobro: string,
		public position: number 
	){}
}	