<div class="barraprincipalincidencias">
	<div *ngIf="selection.selected.length >= 1" class="cajaherramientasincidenciasdoble">
		<div class=hpequenadoble>
			<div class="herramientaincidenciaspequena2" style="width: 49%;" (click)="ubicar('Pendiente Cliente')">
			<mat-icon style="font-size:11pt">call_made</mat-icon> Pendiente Cliente
			</div>
			<div class="herramientaincidenciaspequena2" style="width: 49%;" (click)="ubicar('Entregado')">
			<mat-icon style="font-size:11pt">call_made</mat-icon> Entregado
			</div>
		</div>
		<div class=hpequenadoble>
			<div class="herramientaincidenciaspequena2" style="width: 49%;" (click)="ubicar('Pendiente Agencia')">
			<mat-icon style="font-size:11pt">call_made</mat-icon> Pendiente Agencia
			</div>
			<div class="herramientaincidenciaspequena2" style="width: 49%;" (click)="ubicar('Cancelado')">
			<mat-icon style="font-size:11pt">call_made</mat-icon> Cancelado
			</div>
		</div>
		<div class=hpequenadoble>
			<div class="herramientaincidenciaspequena2" style="width: 49%;" (click)="ubicar('Para Entregar')">
			<mat-icon style="font-size:11pt">call_made</mat-icon> Para Entregar
			</div>
			<div class="herramientaincidenciaspequena2" style="width: 49%;" (click)="ubicar('Especial/vigilar')">
			<mat-icon style="font-size:11pt">call_made</mat-icon> Especial/Vigilar
			</div>
		</div>
		<div class=hpequenadoble>
			<div class="herramientaincidenciaspequena2" style="width: 49%;" (click)="ubicar('Recoge en agencia')">
			<mat-icon style="font-size:11pt">call_made</mat-icon> Recoge Agencia
			</div>
			<div class="herramientaincidenciaspequena2" style="width: 49%;" (click)="ubicar('No Recibido')">
				<mat-icon style="font-size:11pt">call_made</mat-icon> No Recibido
			</div>
		</div>
		<div class=hpequenadoble>
			<div class="herramientaincidenciaspequena2" style="width: 49%;">
				Ubicar Incidencias
			</div>
			<div class="herramientaincidenciaspequena2" style="width: 49%;" (click)="ubicar('Devuelto/Reexpedido')">
			<mat-icon style="font-size:11pt">call_made</mat-icon> Devuelto/Reexpedido
			</div>
		</div>							
	</div>
	<div class="cajaherramientasincidencias">
		<div class="herramientaincidenciaspequena" (click)="categoriaseleccionada('totales'); getincidencias()" [class.selectcategory]="category == 'totales'">
			<mat-icon style="font-size:12pt">storage</mat-icon> Totales
		</div>
		<div class="herramientaincidenciaspequena" (click)="categoriaseleccionada('singestionar'); singestionar()" [class.selectcategory]="category == 'singestionar'">
			<mat-icon style="font-size:12pt">visibility_off</mat-icon> Sin Gestionar <div class="avisoatrasadas" *ngIf="avisoatrasadassingestionar >= 1"><strong>({{avisoatrasadassingestionar}})</strong></div>
		</div>
		<div class="herramientaincidenciaspequena" (click)="categoriaseleccionada('recogidas'); recogidas()" [class.selectcategory]="category == 'recogidas'">
			<mat-icon style="font-size:12pt">emoji_transportation</mat-icon> Recogidas <div class="avisoatrasadas" *ngIf="avisorecogidas >= 1"><strong>({{avisorecogidas}})</strong></div>
		</div>
		<div class="herramientaincidenciaspequena" (click)="categoriaseleccionada('Devuelto/Reexpedido'); devueltosreexp()" [class.selectcategory]="category == 'Devuelto/Reexpedido'">
			<mat-icon style="font-size:12pt">no_transfer</mat-icon> dev/reexp <div class="avisoatrasadas" *ngIf="avisodevreexp >= 1"><strong>({{avisodevreexp}})</strong></div>
		
		</div>
		Envios sin Entregar
	</div>
	<div class="cajaherramientasincidencias">
		<div class="herramientaincidenciaspequena" (click)="categoriaseleccionada('cliente'); gestionado('Pendiente Cliente')" [class.selectcategory]="category == 'cliente'">
			<mat-icon style="font-size:12pt">record_voice_over</mat-icon> Cliente
		</div>
		<div class="herramientaincidenciaspequena" (click)="categoriaseleccionada('agencia'); gestionado('Pendiente Agencia')" [class.selectcategory]="category == 'agencia'">
			<mat-icon style="font-size:12pt">apartment</mat-icon> Agencia
		</div>
		<div class="herramientaincidenciaspequena" (click)="categoriaseleccionada('paraentregar'); gestionado('Para Entregar')" [class.selectcategory]="category == 'paraentregar'">
			<mat-icon style="font-size:12pt">local_shipping</mat-icon> Para Entregar
		</div>
		<div class="herramientaincidenciaspequena" (click)="categoriaseleccionada('ragencia'); gestionado('Recoge en agencia')" [class.selectcategory]="category == 'ragencia'">
			<mat-icon style="font-size:12pt">garage</mat-icon> Recoge en Agencia
		</div>
		Gestionadas Pendientes
	</div>
	
	<div class="cajaherramientasincidencias">
		<div class="herramientaincidenciaspequena" (click)="categoriaseleccionada('inuevas'); incidenciasnuevas()" [class.selectcategory]="category == 'inuevas'">
		<mat-icon *ngIf="avisonuevas >= 1" style="font-size:12pt">notification_important</mat-icon><mat-icon *ngIf="avisonuevas == 0" style="font-size:12pt">notifications_none</mat-icon><span [matBadge]="avisonuevas" [matBadgeOverlap]="false" matBadgeSize="medium" [matBadgeHidden]="avisonuevas == 0"> incidencias Nuevas</span>
		</div>
		<div class="herramientaincidenciaspequena" (click)="categoriaseleccionada('Norecibidos'); sinrecibir()" [class.selectcategory]="category == 'Norecibidos'">
			<mat-icon style="font-size:12pt">no_transfer</mat-icon> No Recibidos
		</div>
		<div class="herramientaincidenciaspequena" (click)="categoriaseleccionada('Especial/vigilar'); gestionado('Especial/vigilar')" [class.selectcategory]="category == 'Especial/vigilar'">
			<mat-icon style="font-size:12pt">videocam</mat-icon> Especial/vigilar
		</div>
		<div class="herramientaincidenciaspequena" (click)="categoriaseleccionada('Cancelados'); cancelados()" [class.selectcategory]="category == 'Cancelados'">
			<mat-icon style="font-size:12pt">cancel</mat-icon> Cancelados
		</div>
	</div>
	<div class="cajaherramientasincidencias" style="padding:5px;position:relative;">
		<mat-form-field appearance="outline" style="text-align:center;width:100%;">
			<mat-label style="color:black;"><b>Buscar Albaran</b></mat-label>
			<input matInput type="text" [(ngModel)]="filtros['NumAlbaran']" style="padding:1px;color:black;"
				  (keyup.enter)="bucaryabrir(filtros['NumAlbaran'])" (ngModelChange)="filtros['NumAlbaran'] = $event.toUpperCase()">
			<button mat-button *ngIf="filtros['NumAlbaran']" matSuffix mat-icon-button aria-label="Clear" (click)="filtros['NumAlbaran']=''; getincidencias()">
				<mat-icon>close</mat-icon>
			</button>
		</mat-form-field>
		<div class="herramientaincidenciaspequena" *ngIf="albarananterior" style="text-align:center;" (dblclick)="abrealbaran(albarananterior)"><strong>{{albarananterior.NumAlbaran}}</strong>
		</div>
	</div>
	<div class="cajaherramientasincidencias" style="padding:5px;position:relative;">
		<mat-form-field *ngIf="identity" appearance="outline" style="width:100%;font-size:9pt;text-align:center;">
		  <mat-label style="font-size:11pt;color:black;">Seleccionar Perfil</mat-label>
		  <mat-select [(value)]="identity.perfil" (selectionChange)="onselectperfil($event.value)">
		    <mat-option [value]="item" *ngFor="let item of listaperfiles">{{item}}</mat-option>
		  </mat-select>
		</mat-form-field>
		<mat-form-field appearance="outline" style="width:100%;font-size:9pt;">
		  <mat-label style="font-size:11pt;color:black;">Buscar Texto</mat-label>
		  <input matInput (keyup)="applyFilter($event)" placeholder="Texto a buscar.." [(ngModel)]="filtros['filtroprincipal']">
		   <button mat-button *ngIf="filtros['filtroprincipal']" matSuffix mat-icon-button aria-label="Clear" (click)="dataSource.filter = ''; filtros['filtroprincipal']=''">
			<mat-icon>close</mat-icon>
		   </button>
		</mat-form-field>
	</div>
	<div class="cajaherramientasincidencias">
		<div class="herramientaincidenciaspequena" (click)="categoriaseleccionada('internacional'); internacionales()" [class.selectcategory]="category == 'internacional'">
			<mat-icon style="font-size:12pt">public</mat-icon> Internacionales
		</div>
		<div class="herramientaincidenciaspequena" (click)="abrewilson()">
			<mat-icon style="font-size:12pt" [matBadge]="pendienteswilson" [matBadgeOverlap]="true" matBadgeSize="medium" [matBadgeHidden]="pendienteswilson == 0" matBadgeColor="warn">mark_chat_unread</mat-icon><span [matBadge]="notificacioneswilson" [matBadgeOverlap]="false" matBadgeSize="medium" [matBadgeHidden]="notificacioneswilson == 0" matBadgeColor="primary">Wilson</span>
		</div>
		<div class="herramientaincidenciaspequena" (click)="descargaExcel()">
			<mat-icon style="font-size:12pt">document_scanner</mat-icon> Exportar a Excel
		</div>
	</div>	
</div>
<div class="contenidoincidencias">
	<mat-progress-bar *ngIf="!load" mode="indeterminate"></mat-progress-bar>
		<table mat-table *ngIf="dataSource && identity" [dataSource]="dataSource" matSort cdkDropList cdkDropListOrientation="horizontal" (cdkDropListDropped)="drop($event)">
		  <ng-container matColumnDef="select" class="cabeceras">
		    <th mat-header-cell cdkDrag *matHeaderCellDef class="cabeceras">
		      <mat-checkbox color="primary" (change)="$event ? masterToggle() : null"
		                    [checked]="selection.hasValue() && isAllSelected()"
		                    [indeterminate]="selection.hasValue() && !isAllSelected()"
		                    [aria-label]="checkboxLabel()">
		      </mat-checkbox>
		    </th>
		    <td mat-cell *matCellDef="let row">
		      <mat-checkbox color="primary" (click)="$event.stopPropagation()"
		                    (change)="$event ? selection.toggle(row) : null"
		                    [checked]="selection.isSelected(row)"
		                    [aria-label]="checkboxLabel(row)">
		      </mat-checkbox>
		    </td>
		  </ng-container>
		  <ng-container matColumnDef="NumAlbaran">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
			    <mat-form-field style="text-align:center;max-width:110px;font-size:7pt">
				  <mat-label style="color:black;"><b>Albaran</b></mat-label>
				  <input matInput type="text" [(ngModel)]="filtros['albaranenquery']" style="padding:1px;color:black;"
				  (keyup.enter)="albaranenquery(filtros['albaranenquery'])">
				  <button mat-button *ngIf="filtros['albaranenquery']" matSuffix mat-icon-button aria-label="Clear" (click)="filtros['albaranenquery']=''; getincidencias()">
				    <mat-icon>close</mat-icon>
				  </button>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.NumAlbaran}} </td>
		  </ng-container>

		  <ng-container matColumnDef="Tipo">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
				<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>Tipo</b></mat-label>
					<input matInput type="text" [(ngModel)]="filtros['Tipo']" style="padding:1px;color:black;"
					(keyup.enter)="onselectfiltro('Tipo', filtros['Tipo'])">
					<button mat-button *ngIf="filtros['Tipo']" matSuffix mat-icon-button aria-label="Clear" (click)="filtros['Tipo']='';eliminafiltro('Tipo');">
					  <mat-icon>close</mat-icon>
					</button>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.Tipo}} </td>
		  </ng-container>

		  <ng-container matColumnDef="Anomalia">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>Anomalia</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.Anomalia}} </td>
		  </ng-container>
		  <ng-container matColumnDef="AnomaliaMotivo">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>Anomalia Motivo</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element" style="max-width:200px;"> {{element.AnomaliaMotivo}} </td>
		  </ng-container>
		  <ng-container matColumnDef="AnomaliaObservaciones">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>Anomalia Observaciones</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element" style="max-width:200px;"> {{element.AnomaliaObservaciones}} </td>
		  </ng-container>
		  <ng-container matColumnDef="CodigoAbonado">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
				  <mat-label style="color:black;"><b>Abonado</b></mat-label>
				  <input matInput type="text" [(ngModel)]="filtros['CodigoAbonado']" style="padding:1px;color:black;"
				  (keyup.enter)="onselectabonado(filtros['CodigoAbonado'])">
				  <button mat-button *ngIf="filtros['CodigoAbonado']" matSuffix mat-icon-button aria-label="Clear" (click)="filtros['CodigoAbonado']=''; eliminafiltroabonado()">
				    <mat-icon>close</mat-icon>
				  </button>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.CodigoAbonado}} </td>
		  </ng-container>
		  <ng-container matColumnDef="CodigoFranquicia">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>Codigo Franquicia</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.CodigoFranquicia}} </td>
		  </ng-container>
		  <ng-container matColumnDef="ContactoEntrega">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>Contacto Entrega</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.ContactoEntrega}} </td>
		  </ng-container>
		  <ng-container matColumnDef="ContactoRecogida">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>Contacto Recogida</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.ContactoRecogida}} </td>
		  </ng-container>
		  <ng-container matColumnDef="CpDestinatario">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>Cp Destinatario</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.CpDestinatario}} </td>
		  </ng-container>
		  <ng-container matColumnDef="CpRemitente">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>Cp Remitente</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.CpRemitente}} </td>
		  </ng-container>
		  <ng-container matColumnDef="Destinatario">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>Destinatario</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.Destinatario}} </td>
		  </ng-container>
		  <ng-container matColumnDef="DireccionDestinatario">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>Direccion Destinatario</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.DireccionDestinatario}} </td>
		  </ng-container>
		  <ng-container matColumnDef="DireccionRemitente">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>Direccion Remitente</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.DireccionRemitente}} </td>
		  </ng-container>
		  <ng-container matColumnDef="Estado">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field>
		    		<mat-label style="font-size:11pt;color:black;">Estado</mat-label>
					<mat-select [(ngModel)]="this.identity.incidenciasdefinitivas" (selectionChange)="onselectfiltroestado($event.value)" multiple>
						<mat-option *ngFor="let campo of listasfiltros.listaestado" [value]="campo">{{campo}}</mat-option>
					</mat-select>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.Estado}} </td>
		  </ng-container>
		  <ng-container matColumnDef="Fase">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>Fase</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.Fase}} </td>
		  </ng-container>
		  <ng-container matColumnDef="FechaEntrega">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:120px;font-size:8pt">
				  <mat-label style="color:black;" (click)="picker3.open()"><b>Fecha Entrega</b></mat-label>
				  <mat-date-range-input [rangePicker]="picker3" (click)="picker3.open()">
					<input matStartDate placeholder="Fecha Inicio" [(ngModel)]="filtros['FechaEntregainicial']">
					<input matEndDate placeholder="Fecha fin" [(ngModel)]="filtros['FechaEntregafinal']" (dateChange)="onDateFechaentrega()">
				  </mat-date-range-input>
				  <button mat-button *ngIf="filtros['FechaEntregainicial']" matSuffix mat-icon-button aria-label="Clear" (click)="filtros['FechaEntregainicial']=''; filtros['FechaEntregafinal']=''; getincidencias();">
				    <mat-icon>close</mat-icon>
				  </button>
				  <mat-date-range-picker #picker3></mat-date-range-picker>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.FechaEntrega | date:'dd/MM/yyyy HH:mm:ss'}} </td>
		  </ng-container>
		  <ng-container matColumnDef="FechaEnvio">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:110px;font-size:8pt">
				  <mat-label style="color:black;" (click)="picker.open()"><b>Fecha</b></mat-label>
				  <mat-date-range-input [rangePicker]="picker" (click)="picker.open()">
					<input matStartDate placeholder="Fecha Inicio" [(ngModel)]="filtros['FechaEnvioinicial']">
					<input matEndDate placeholder="Fecha fin" [(ngModel)]="filtros['FechaEnviofinal']" (dateChange)="onDateFechaenvio()">
				  </mat-date-range-input>
				  <button mat-button *ngIf="filtros['FechaEnvioinicial']" matSuffix mat-icon-button aria-label="Clear" (click)="filtros['FechaEnvioinicial']=''; filtros['FechaEnviofinal']=''; getincidencias();">
				    <mat-icon>close</mat-icon>
				  </button>
				  <mat-date-range-picker #picker></mat-date-range-picker>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.FechaEnvio | date:'dd/MM/yyyy'}} </td>
		  </ng-container>
		  <ng-container matColumnDef="EntregarAPartirDe">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>EntregarAPartirDe</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.EntregarAPartirDe}} </td>
		  </ng-container>
		  <ng-container matColumnDef="FechaRecogida">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:120px;font-size:8pt">
				  <mat-label style="color:black;" (click)="picker2.open()"><b>Fecha Recogida</b></mat-label>
				  <mat-date-range-input [rangePicker]="picker2" (click)="picker2.open()">
					<input matStartDate placeholder="Fecha Inicio" [(ngModel)]="filtros['FechaRecogidainicial']">
					<input matEndDate placeholder="Fecha fin" [(ngModel)]="filtros['FechaRecogidafinal']" (dateChange)="onDateFecharecogida()">
				  </mat-date-range-input>
				  <button mat-button *ngIf="filtros['FechaRecogidainicial']" matSuffix mat-icon-button aria-label="Clear" (click)="filtros['FechaRecogidainicial']=''; filtros['FechaRecogidafinal']=''; getincidencias();">
				    <mat-icon>close</mat-icon>
				  </button>
				  <mat-date-range-picker #picker2></mat-date-range-picker>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.FechaRecogida| date:'dd/MM/yyyy'}}</td>
		  </ng-container>
		  
		  <ng-container matColumnDef="FechaFinalizacionRecogida">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:120px;font-size:8pt">
				  <mat-label style="color:black;" (click)="picker4.open()"><b>Recogida Finalizada</b></mat-label>
				  <mat-date-range-input [rangePicker]="picker4" (click)="picker4.open()">
					<input matStartDate placeholder="Fecha Inicio" [(ngModel)]="filtros['FechaRecogidafinalizadainicial']">
					<input matEndDate placeholder="Fecha fin" [(ngModel)]="filtros['FechaRecogidafinalizadafinal']" (dateChange)="onDateFecharecogidafinalizada()">
				  </mat-date-range-input>
				  <button mat-button *ngIf="filtros['FechaRecogidafinalizadainicial']" matSuffix mat-icon-button aria-label="Clear" (click)="filtros['FechaRecogidafinalizadainicial']=''; filtros['FechaRecogidafinalizadafinal']=''; getincidencias();">
				    <mat-icon>close</mat-icon>
				  </button>
				  <mat-date-range-picker #picker4></mat-date-range-picker>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.FechaFinalizacionRecogida| date:'dd/MM/yyyy HH:mm:ss'}} </td>
		  </ng-container>
		  <ng-container matColumnDef="FechaConcertacion">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:120px;font-size:8pt">
				  <mat-label style="color:black;" (click)="picker5.open()"><b>Concertada</b></mat-label>
				  <mat-date-range-input [rangePicker]="picker5" (click)="picker5.open()">
					<input matStartDate placeholder="Fecha Inicio" [(ngModel)]="filtros['FechaConcertacioninicial']">
					<input matEndDate placeholder="Fecha fin" [(ngModel)]="filtros['FechaConcertacionfinal']" (dateChange)="onDateFechaConcertacion()">
				  </mat-date-range-input>
				  <button mat-button *ngIf="filtros['FechaConcertacioninicial']" matSuffix mat-icon-button aria-label="Clear" (click)="filtros['FechaConcertacioninicial']=''; filtros['FechaConcertacionfinal']=''; getincidencias();">
				    <mat-icon>close</mat-icon>
				  </button>
				  <mat-date-range-picker #picker5></mat-date-range-picker>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.FechaConcertacion | date:'dd/MM/yyyy'}} </td>
		  </ng-container>
		  <ng-container matColumnDef="HoraMaximaEntrega">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>Hora Maxima Entrega</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.HoraMaximaEntrega}} </td>
		  </ng-container>
		  <ng-container matColumnDef="HoraMaximaRecogida">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header> 
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>Hora Maxima Recogida</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.HoraMaximaRecogida}} </td>
		  </ng-container>
		  <ng-container matColumnDef="IdFiscal">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>IdFiscal</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.IdFiscal}} </td>
		  </ng-container>
		  <ng-container matColumnDef="AR">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>AR</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.AR}} </td>
		  </ng-container>
		  <ng-container matColumnDef="ImporteAR">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>ImporteAR</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.ImporteAR}} </td>
		  </ng-container>
		  <ng-container matColumnDef="Kilos">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>Kilos</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.Kilos}} </td>
		  </ng-container>
		  <ng-container matColumnDef="KmDestinatario">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>KmDestinatario</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.KmDestinatario}} </td>
		  </ng-container>
		  <ng-container matColumnDef="KmRemitente">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>KmRemitente</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.KmRemitente}} </td>
		  </ng-container>
		  <ng-container matColumnDef="Motivo">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;width:100%;font-size:8pt">
					<mat-label style="font-size:11pt;color:black;">Motivo</mat-label>
					<mat-select [formControl]="motivosincluir" multiple (selectionChange)="onselectmotivo($event.value)">
						<mat-option *ngFor="let motivo of listasfiltros.listamotivo" [value]="motivo">{{motivo.valor}}</mat-option>
					</mat-select>	
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element">{{humanizemotivo(element.Motivo)}}</td>
		  </ng-container>
		  <ng-container matColumnDef="NombreCliente">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>Nombre Cliente</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.NombreCliente}} </td>
		  </ng-container>
		  <ng-container matColumnDef="NombreSolicitante">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>Nombre Solicitante</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.NombreSolicitante}} </td>
		  </ng-container>
		  <ng-container matColumnDef="ObservacionesEntrega">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>Observaciones Entrega</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element" style="max-width:200px;"> {{element.ObservacionesEntrega}} </td>
		  </ng-container>
		  <ng-container matColumnDef="ObservacionesRecogida">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>Observaciones Recogida</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.ObservacionesRecogida}} </td>
		  </ng-container>
		  <ng-container matColumnDef="PoblacionDestinatario">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>Poblacion Destinatario</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.PoblacionDestinatario}} </td>
		  </ng-container>
		  <ng-container matColumnDef="PoblacionRemitente">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>Poblacion Remitente</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.PoblacionRemitente}} </td>
		  </ng-container>
		  <ng-container matColumnDef="ProvinciaDestinatario">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>Provincia Destinatario</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.ProvinciaDestinatario}} </td>
		  </ng-container>
		  <ng-container matColumnDef="ProvinciaRemitente">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>Provincia Remitente</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.ProvinciaRemitente}} </td>
		  </ng-container>
		  <ng-container matColumnDef="RangoHorarioEntregaManana">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>Rango Horario Entrega Manana</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.RangoHorarioEntregaManana}} </td>
		  </ng-container>
		  <ng-container matColumnDef="RangoHorarioEntregaTarde">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>Rango Horario Entrega Tarde</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.RangoHorarioEntregaTarde}} </td>
		  </ng-container>
		  <ng-container matColumnDef="RangoHorarioRecogidaManana">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>Rango Horario Recogida Manana</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.RangoHorarioRecogidaManana}} </td>
		  </ng-container>
		  <ng-container matColumnDef="RangoHorarioRecogidaTarde">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>Rango Horario Recogida Tarde</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.RangoHorarioRecogidaTarde}} </td>
		  </ng-container>
		  <ng-container matColumnDef="Remitente">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>Remitente</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.Remitente}} </td>
		  </ng-container>
		  <ng-container matColumnDef="Servicio">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>Servicio</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.Servicio}} </td>
		  </ng-container>
		  <ng-container matColumnDef="TelefonoDestinatario">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>Telefono Destinatario</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.TelefonoDestinatario}} </td>
		  </ng-container>
		  <ng-container matColumnDef="TelefonoRemitente">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>Telefono Remitente</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.TelefonoRemitente}} </td>
		  </ng-container>
		  <ng-container matColumnDef="TipoComision">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>Tipo Comision</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.TipoComision}} </td>
		  </ng-container>
		  <ng-container matColumnDef="TipoEntrega">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>Tipo Entrega</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.TipoEntrega}} </td>
		  </ng-container>
		  <ng-container matColumnDef="TipoGeografico">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>Tipo Geografico</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.TipoGeografico}} </td>
		  </ng-container>
		  <ng-container matColumnDef="TipoMercancia">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>Tipo Mercancia</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.TipoMercancia}} </td>
		  </ng-container>
		  <ng-container matColumnDef="TipoRecogida">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>Tipo Recogida</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.TipoRecogida}} </td>
		  </ng-container>
		  <ng-container matColumnDef="TotalBultos">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>Total Bultos</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.TotalBultos}} </td>
		  </ng-container>
		  <ng-container matColumnDef="TipoCobro">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>Tipo Cobro</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.TipoCobro}} </td>
		  </ng-container>
		  <ng-container matColumnDef="FranquiciaDestino">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>Franquicia Destino</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.FranquiciaDestino}} </td>
		  </ng-container>
		  <ng-container matColumnDef="FranquiciaOrigen">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>Franquicia Origen</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.FranquiciaOrigen}} </td>
		  </ng-container>
		  <ng-container matColumnDef="FranquiciaTercera">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>Franquicia Tercera</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.FranquiciaTercera}} </td>
		  </ng-container>
		  <ng-container matColumnDef="FranquiciaClienteFacturacion">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>Franquicia Cliente Facturacion</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.FranquiciaClienteFacturacion}} </td>
		  </ng-container>
		  <ng-container matColumnDef="CodigoCupon">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>Codigo Cupon</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.CodigoCupon}} </td>
		  </ng-container>
		  <ng-container matColumnDef="NumeroAlbaranPrecursor">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>Numero Albaran Precursor</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.NumeroAlbaranPrecursor}} </td>
		  </ng-container>
		  <ng-container matColumnDef="NumeroEnvioRetorno">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>Numero Envio Retorno</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.NumeroEnvioRetorno}} </td>
		  </ng-container>
		  <ng-container matColumnDef="TipoGestion">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>Tipo Gestion</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.TipoGestion}} </td>
		  </ng-container>
		  <ng-container matColumnDef="TieneRetorno">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>Tiene Retorno</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.TieneRetorno}} </td>
		  </ng-container>
		  <ng-container matColumnDef="ConfirmacionInmediata">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>Confirmacion Inmediata</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.ConfirmacionInmediata}} </td>
		  </ng-container>
		  <ng-container matColumnDef="EntregaSabado">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>Entrega Sabado</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.EntregaSabado}} </td>
		  </ng-container>
		  <ng-container matColumnDef="TipoRetorno">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>Tipo Retorno</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.TipoRetorno}} </td>
		  </ng-container>
		  <ng-container matColumnDef="Usuario">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>Usuario</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.Usuario}} </td>
		  </ng-container>
		  <ng-container matColumnDef="UsuarioModificacion">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>Usuario Modificacion</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.UsuarioModificacion}} </td>
		  </ng-container>
		  <ng-container matColumnDef="ReferenciaCliente">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>Referencia Cliente</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.ReferenciaCliente}} </td>
		  </ng-container>
		  <ng-container matColumnDef="EsInternacional">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>EsInternacional</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.EsInternacional}} </td>
		  </ng-container>
		  <ng-container matColumnDef="GeneradoPor">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>Generado Por</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.GeneradoPor}} </td>
		  </ng-container>
		  <ng-container matColumnDef="Aviso">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>Aviso</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.Aviso}} </td>
		  </ng-container>
		  <ng-container matColumnDef="TotalMedidas">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>Total Medidas</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.TotalMedidas}} </td>
		  </ng-container>
		  <ng-container matColumnDef="Operacion">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>Operacion</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.Operacion}} </td>
		  </ng-container>
		  <ng-container matColumnDef="Frecuencia">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>Frecuencia</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.Frecuencia}} </td>
		  </ng-container>
		  <ng-container matColumnDef="Contador">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>Contador</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.Contador}} </td>
		  </ng-container>
		  <ng-container matColumnDef="NifRemitente">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>NifRemitente</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.NifRemitente}} </td>
		  </ng-container>
		  <ng-container matColumnDef="NifDestinatario">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>NifDestinatario</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.NifDestinatario}} </td>
		  </ng-container>
		  <ng-container matColumnDef="MensajeroEntrega">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>MensajeroEntrega</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.MensajeroEntrega}} </td>
		  </ng-container>
		  <ng-container matColumnDef="MensajeroRecogida">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>MensajeroRecogida</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.MensajeroRecogida}} </td>
		  </ng-container>
		  <ng-container matColumnDef="PuntoRecogida">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>PuntoRecogida</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.PuntoRecogida}} </td>
		  </ng-container>
		  <ng-container matColumnDef="PuntoEntrega">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>PuntoEntrega</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.PuntoEntrega}} </td>
		  </ng-container>
		  <ng-container matColumnDef="PuntoAnomalia">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>PuntoAnomalia</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.PuntoAnomalia}} </td>
		  </ng-container>
		  <ng-container matColumnDef="PuntoEstado">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>PuntoEstado</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.PuntoEstado}} </td>
		  </ng-container>
		  <ng-container matColumnDef="PuntoConcertacion">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>PuntoConcertacion</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.PuntoConcertacion}} </td>
		  </ng-container>
		  <ng-container matColumnDef="TipoPuntoConcertacion">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>Tipo Punto Concertacion</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.TipoPuntoConcertacion}} </td>
		  </ng-container>
		  <ng-container matColumnDef="albaranrd">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
				<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>Albaran reex/dev/ret</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.albaranrd}} </td>
		  </ng-container>
		  <ng-container matColumnDef="gestionado">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;width:100%;font-size:8pt">
				  <mat-label style="font-size:11pt;color:black;">Gestionado</mat-label>
				  <mat-select [(value)]="filtros['gestionado']" (selectionChange)="onselectfiltro('gestionado', $event.value)">
				    <mat-option [value]="gest" *ngFor="let gest of listasfiltros.listagestionados">{{gest}}</mat-option>
				  </mat-select>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.gestionado}} </td>
		  </ng-container>
		  <ng-container matColumnDef="notas">
		    <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
		    	<mat-form-field style="text-align:center;max-width:80px;font-size:7pt">
					<mat-label style="color:black;"><b>notas</b></mat-label>
					<input matInput type="text" style="padding:1px;color:black;" disabled>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"style="max-width:200px;"> {{element.notas}} </td>
		  </ng-container>
	
		  <tr mat-header-row *matHeaderRowDef="identity.options.ordencamposincidencias; sticky:true"></tr>
		  <tr mat-row (dblclick)="abrealbaran(row)" (click)="seleccionar(row)" class="celdas" [class.seleccionada]="seleccion && seleccion.NumAlbaran == row.NumAlbaran" *matRowDef="let row; columns: identity.options.ordencamposincidencias;">
		  </tr>
		</table>
	</div>
	<div style="display:inline-flex;width:100%;max-height:60px;align-items:center;">
		<div style="font-size:10pt;margin-left:15px;color:black;">
			Seleccionadas: {{selection.selected.length}}
		</div>
		<div>
			<mat-form-field *ngIf="identity" style="width:35px;margin-left:20px;margin-right:50px;">
				<mat-select [(ngModel)]="identity.options.ordencamposincidencias" (selectionChange)="onselectcolumnas($event.value)" multiple>
				<mat-select-trigger class="fondooscuro">
					<mat-icon>view_list</mat-icon>
				</mat-select-trigger>
				<mat-option *ngFor="let campo of totalcolumns" [value]="campo">{{campo}}</mat-option>
				</mat-select>
			</mat-form-field>
		</div>
		<mat-paginator [pageSize]="50" [pageSizeOptions]="[10, 25, 50, 100]"></mat-paginator>

			<!--<div *ngIf="paginator.page == 1"class="paginatorelement paginatorboton flechaclara">
				<mat-icon>arrow_left</mat-icon>
			</div>
			<div *ngIf="paginator.page != 1"class="paginatorelement paginatorboton fondooscuro" (click)="paginatorfuncion('page', 1)">
				<mat-icon>arrow_left</mat-icon>
			</div>
			<div *ngIf="!paginator.hasPrevPage" class="paginatorelement paginatorboton flechaclara">
				<mat-icon>chevron_left</mat-icon>
			</div>
			<div *ngIf="paginator.hasPrevPage" class="paginatorelement paginatorboton fondooscuro" (click)="paginatorfuncion('page', paginator.page -1)">
				<mat-icon>chevron_left</mat-icon>
			</div>
			<div class="paginatorelement">
				{{paginator.mostrandodesde}} - {{paginator.mostrandohasta}} de {{paginator.totalDocs}}
			</div>
			<div *ngIf="!paginator.hasNextPage" class="paginatorelement paginatorboton flechaclara">
				<mat-icon>navigate_next</mat-icon>
			</div>
			<div *ngIf="paginator.hasNextPage" class="paginatorelement paginatorboton fondooscuro" (click)="paginatorfuncion('page', paginator.page +1)">
				<mat-icon>navigate_next</mat-icon>
			</div>
			<div *ngIf="paginator.page == paginator.totalPages" class="paginatorelement paginatorboton flechaclara">
				<mat-icon>arrow_right</mat-icon>
			</div>
			<div *ngIf="paginator.page != paginator.totalPages" class="paginatorelement paginatorboton fondooscuro" (click)="paginatorfuncion('page', paginator.totalPages)">
				<mat-icon>arrow_right</mat-icon>
			</div>

			<div style="margin-right: 5px;"> 
				<span>pagina {{paginator.page}}</span>
			</div>
			<div class="paginatorelement">
				de {{paginator.totalPages}}
			</div>
			<div>
				<select name="selectelementosporpagina" (change)="paginatorfuncion('limit', $event.target.value)">
				  <option value="10">10</option>
				  <option value="25">25</option>
				  <option value="50" selected>50</option>
				  <option value="100">100</option>
				</select>
			</div>-->	
	</div>
<div class="pieincidencias" *ngIf='incidencias'>
	<div class="datosincidencia">
		<div class="datodentroincidencia" *ngIf="seleccion">
			<ul style="list-style: none">
				<li (dblclick)="clickkmrec()">KM.Recogida: <span [class.kmup]="seleccion.KmRemitente > 15">{{seleccion.KmRemitente}}</span></li>
				<li (dblclick)="clickkment()">KM.Entrega: <span [class.kmup]="seleccion.KmDestinatario > 15">{{seleccion.KmDestinatario}}</span></li>
				<li>Referencia: {{seleccion.ReferenciaCliente}}</li>
				<li>Bultos: {{seleccion.TotalBultos}}</li>				
				<li>Generado: {{seleccion.GeneradoPor}} {{seleccion.FechaEnvio| date:'dd/MM/yyyy'}}</li>
				<li>Usuario: {{seleccion.Usuario}}</li>
				<li *ngIf="seleccion.UsuarioModificacion">Modificado: {{seleccion.UsuarioModificacion}}</li>
			</ul>	
		</div>
	</div>
	<div class="datosincidencia">
		<div class="datodentroincidencia" *ngIf="seleccion">
			<ul style="list-style: none">
				<li *ngIf="seleccion.NumeroEnvioRetorno" (dblclick)="bucaryabrir(seleccion.NumeroEnvioRetorno)">Retorno: <span class="fondooscuro"><b>{{seleccion.NumeroEnvioRetorno}}</b></span></li>
				<li *ngIf="seleccion.NumeroAlbaranPrecursor" (dblclick)="bucaryabrir(seleccion.NumeroAlbaranPrecursor)">Precursor: <span class="fondooscuro"><b>{{seleccion.NumeroAlbaranPrecursor}}</b></span></li>
				<li>Servicio: {{seleccion.Servicio}}</li>
				<li *ngIf="seleccion.ImporteAR">Adelanto/Reembolso: {{selecionra}} {{seleccion.ImporteAR}}€</li>
				<li>Kilos: {{seleccion.Kilos}}</li>
				<li>Bultos: {{seleccion.TotalBultos}}</li>
				<li>Medidas: {{seleccion.TotalMedidas}}</li>
				<li *ngIf="seleccion.FechaConcertacion">Concertada: {{seleccion.FechaConcertacion| date:'dd/MM/yyyy'}}</li>
			</ul>	
		</div>
	</div>
	<div class="datosexpediente">
		<div style="text-align:center;" *ngIf="errorexpediente">{{errorexpediente}}</div>
		<div class="datodentroincidencia" *ngIf="seleccion && listaexpediente">
			<table mat-table [dataSource]="listaexpediente">
				 <ng-container matColumnDef="Estado">
				    <th mat-header-cell *matHeaderCellDef  style="color:black;text-align:center;padding:1px;"> Estado </th>
				    <td mat-cell *matCellDef="let element"> {{element.Estado}} </td>
				  </ng-container>
				  <ng-container matColumnDef="FechaEntrada">
				    <th mat-header-cell *matHeaderCellDef style="color:black;text-align:center;padding:1px;"> FechaEntrada </th>
				    <td mat-cell *matCellDef="let element"> {{element.FechaEntrada}} </td>
				  </ng-container>
				  <ng-container matColumnDef="HoraEntrada">
				    <th mat-header-cell *matHeaderCellDef style="color:black;text-align:center;padding:1px;"> HoraEntrada </th>
				    <td mat-cell *matCellDef="let element"> {{element.HoraEntrada}} </td>
				  </ng-container>
				  <ng-container matColumnDef="Descripcion">
				    <th mat-header-cell *matHeaderCellDef style="color:black;text-align:center;padding:1px;"> Descripcion </th>
				    <td mat-cell *matCellDef="let element"> {{element.Descripcion}} </td>
				  </ng-container>
				  <ng-container matColumnDef="Detalle">
				    <th mat-header-cell *matHeaderCellDef style="color:black;text-align:center;padding:1px;"> Detalle </th>
				    <td mat-cell *matCellDef="let element"> {{element.Detalle}} </td>
				  </ng-container>
				  <ng-container matColumnDef="Usuario">
				    <th mat-header-cell *matHeaderCellDef style="color:black;text-align:center;padding:1px;"> Usuario </th>
				    <td mat-cell *matCellDef="let element"> {{element.Usuario}} </td>
				  </ng-container>	
				 <tr mat-header-row *matHeaderRowDef="columnasexpediente; sticky:true" style="padding:1px;"></tr>
  				 <tr mat-row *matRowDef="let fila; columns: columnasexpediente;" class="fondooscuro" [class.seleccionada]="seleccionexp == fila.Id" (dblclick)="detalleexpediente(fila)"></tr>
			</table>
		</div>
	</div>
	<div class="datosincidencia">
		<p style="text-align: center;">Origen</p>
		<div class="datodentroincidencia" *ngIf="seleccion">
			<ul style="list-style: none">
				<li>Agencia: {{seleccion.FranquiciaOrigen}}</li>
				<li>Nombre: {{seleccion.Remitente}}</li>
				<li>Direccion: {{seleccion.DireccionRemitente}}</li>
				<li>Poblacion: {{seleccion.PoblacionRemitente}}</li>
				<li>CP: {{seleccion.CpRemitente}}</li>
				<li>Telefono: {{seleccion.TelefonoRemitente}}</li>
			</ul>	
		</div>
	</div>
	<div class="datosincidencia">
		<p style="text-align: center;">Destino</p>
		<div class="datodentroincidencia" *ngIf="seleccion">
			<ul style="list-style: none">
				<li>Agencia: {{seleccion.FranquiciaDestino}}</li>
				<li>Nombre: {{seleccion.Destinatario}}</li>
				<li>Direccion: {{seleccion.DireccionDestinatario}}</li>
				<li>Poblacion: {{seleccion.PoblacionDestinatario}}</li>
				<li>CP: {{seleccion.CpDestinatario}}</li>
				<li>Telefono: {{seleccion.TelefonoDestinatario}}</li>
			</ul>	
		</div>
	</div>
</div>