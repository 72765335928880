import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import * as moment from 'moment';
import { Deuda } from '../../models/deuda';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition, MatSnackBarConfig } from '@angular/material/snack-bar'; 
import { ClienteService } from '../../servicios/cliente.service';
import { DeudasService } from '../../servicios/deudas.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'nuevadeuda',
  templateUrl: './nuevadeuda.component.html',
  styleUrls: ['./nuevadeuda.component.css'],
  providers: [DeudasService, ClienteService]
})
export class NuevadeudaComponent implements OnInit {
	public fechadeuda;
  public date = new FormControl(new Date());
  constructor(
  	private dateAdapter: DateAdapter<Date>,
  	public ventanaNuevadeuda: MatDialogRef<NuevadeudaComponent>,
	  private _snackBar: MatSnackBar,
	  private _deudasService: DeudasService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _clienteService: ClienteService,
    @Inject(MAT_DIALOG_DATA) public data: Deuda
  ) {
  	 this.dateAdapter.setLocale('es');
     this.dateAdapter.getFirstDayOfWeek = () => { return 1; };
  }

  ngOnInit(): void {
  }

  closeDialog() {
    this.ventanaNuevadeuda.close(true);
  }

   aviso(texto, subtexto) {
    this._snackBar.open(texto,subtexto,{
      duration: 5000,
      horizontalPosition: "center",
      verticalPosition: "top",
    });
  }

  setfechadeuda(){
    this.data.fechadeuda = moment(this.date.value).format('DD/MM/YYYY').toString();
  }

  guardar(){
    if(!this.data.fechadeuda) this.setfechadeuda();
  	this._deudasService.guardardeuda(this.data).subscribe(
  		response=>{
  			this.aviso('deuda guardada correctamente', 'ok');
        this.closeDialog();
  			},
  		error=>{
  			console.log(<any>error);
  		});
  }

}
