import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { ClienteService } from '../../servicios/cliente.service';
import { AppService } from '../../servicios/app.service';
import { Linea } from '../../models/lineaenvio';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';

@Component({
  selector: 'adminenviosclientes',
  templateUrl: './adminenviosclientes.component.html',
  styleUrls: ['./adminenviosclientes.component.css'],
  providers: [ClienteService]
})
export class AdminenviosclientesComponent implements OnInit {
  public identity;
  public lineas = new MatTableDataSource<Linea>();
  public linea = new Linea("","","",""," ","","","","","1","N","");
  public columns = ['NOMBRE', 'DIRECCION', 'POBLACION', 'CP', 'TELEFONO', 'CONTACTO', 'REFERENCIA', 'OBSERVACIONES'];
  public grupos = new MatTableDataSource<any>();
  public columnsgrupos = ['FECHA', 'CLIENTE','ENVIOS'];
  public gruposeleccionado;
  public errores = false;
  public filtro;
  public fechai;
  public fechaf;

  constructor(
    private _clienteService: ClienteService,
    private _appservice: AppService,
    private dateAdapter: DateAdapter<Date>
  ) { 
    this.identity = this._appservice.getIdentity();
    this.dateAdapter.setLocale('es');
    this.dateAdapter.getFirstDayOfWeek = () => { return 1; };
  }


  ngOnInit(): void {
    this.getgrupostotales();
  }

  getgrupos(query){
    this._clienteService.getgruposadmin(query).subscribe(
      response=>{
        if(response && response['grupos'] && response['grupos'].length >=1){
          this.grupos.data = response['grupos'].sort(function(a,b){
            return a.creado < b.creado? 1 : -1;
          });
        }
      }
    )
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.grupos.filter = filterValue.trim().toLowerCase();
  }

  lineasgrupo(grupo){
    this.lineas.data = grupo.envios;
    this.gruposeleccionado = grupo;
  }
  
  erroresgrupo(){
    this.errores = true;
    this.lineas.data = this.gruposeleccionado.errores;
  }

  volveralineasgrupo(){
    this.errores = false;
    this.lineas.data = this.gruposeleccionado.envios;
  }

  onDate(){
    let fini = moment(this.fechai).toISOString();
    let ffin = moment(this.fechaf).set({hour:21,minute:20,second:0,millisecond:0}).toISOString();
    let query = {$and:[{creado:{$gte:fini}},{creado:{$lte:ffin}}]};
    this.getgrupos(query);
  }

  getgrupostotales(){
    this.fechai = null;
    this.fechaf = null;
    let fecha = moment().add(-6,'months');
    let query = {creado:{$gte:fecha}}
    this.getgrupos(query);
  }
}
