<table mat-table [dataSource]="incidencias" multiTemplateDataRows class="mat-elevation-z8 demo-table tablamodificados">
    <ng-container *ngFor="let column of columns" [matColumnDef]="column.nombre">
      <th mat-header-cell *matHeaderCellDef>
        {{column.mostrar}}
      </th>
      <td mat-cell *matCellDef="let row">
        <div *ngIf="row[column.nombre]">{{row[column.nombre].original}}</div>
      </td>
    </ng-container>
    <ng-container matColumnDef="expandedDetail">
      <td mat-cell *matCellDef="let element" [attr.colspan]="columns.length">
        <div class="example-element-detail"
             [@detailExpand]="element.Id == expandedElement ? 'expanded' : 'collapsed'">
            <div *ngIf="element.Remitente && element.Remitente.cambio" class="linea">
              <div class="dato">
                 {{element.Remitente.original}}
              </div>
              <div>
                <mat-icon>published_with_changes</mat-icon>
              </div>
              <div class="example-element-symbol dato">
                {{element.Remitente.cambio}}
              </div>
            </div>
            <div *ngIf="element.DireccionRemitente && element.DireccionRemitente.cambio" class="linea">
              <div class="dato">
                {{element.DireccionRemitente.original}}
              </div>
              <div>
                <mat-icon>published_with_changes</mat-icon>
              </div>
              <div class="example-element-symbol dato">
                {{element.DireccionRemitente.cambio}}
              </div>
            </div>
            <div *ngIf="element.CpRemitente && element.CpRemitente.cambio" class="linea">
              <div class="dato"> {{element.CpRemitente.original}} </div>
              <div>
                <mat-icon>published_with_changes</mat-icon>
              </div>
              <div class="example-element-symbol dato">
                {{element.CpRemitente.cambio}}
              </div>
            </div>
            <div *ngIf="element.PoblacionRemitente && element.PoblacionRemitente.cambio" class="linea">
              <div class="dato"> {{element.PoblacionRemitente.original}} </div>
              <div>
                <mat-icon>published_with_changes</mat-icon>
              </div>
              <div class="example-element-symbol dato">
                {{element.PoblacionRemitente.cambio}}
              </div>
            </div>
            <div *ngIf="element.ProvinciaRemitente && element.ProvinciaRemitente.cambio" class="linea">
              <div class="dato"> {{element.ProvinciaRemitente.original}} </div>
              <div>
                <mat-icon>published_with_changes</mat-icon>
              </div>
              <div class="example-element-symbol dato">
                {{element.ProvinciaRemitente.cambio}}
              </div>
            </div>
            <div *ngIf="element.TelefonoRemitente && element.TelefonoRemitente.cambio" class="linea">
              <div class="dato"> {{element.TelefonoRemitente.original}} </div>
              <div>
                <mat-icon>published_with_changes</mat-icon>
              </div>
              <div class="example-element-symbol dato">
                {{element.TelefonoRemitente.cambio}}
              </div>
            </div>
            <div *ngIf="element.ContactoRecogida && element.ContactoRecogida.cambio" class="linea">
              <div class="dato"> {{element.ContactoRecogida.original}} </div>
              <div>
                <mat-icon>published_with_changes</mat-icon>
              </div>
              <div class="example-element-symbol dato">
                {{element.ContactoRecogida.cambio}}
              </div>
            </div>
            <div *ngIf="element.ObservacionesRecogida && element.ObservacionesRecogida.cambio" class="linea">
              <div class="dato"> {{element.ObservacionesRecogida.original}} </div>
              <div>
                <mat-icon>published_with_changes</mat-icon>
              </div>
              <div class="example-element-symbol dato">
                {{element.ObservacionesRecogida.cambio}}
              </div>
            </div>
            <div *ngIf="element.KmRemitente && element.KmRemitente.cambio" class="linea">
              <div class="dato"> {{element.KmRemitente.original}} </div>
              <div>
                <mat-icon>published_with_changes</mat-icon>
              </div>
              <div class="example-element-symbol dato">
                {{element.KmRemitente.cambio}}
              </div>
            </div>
            <div *ngIf="element.Destinatario && element.Destinatario.cambio" class="linea">
              <div class="dato"> {{element.Destinatario.original}} </div>
              <div>
                <mat-icon>published_with_changes</mat-icon>
              </div>
              <div class="example-element-symbol dato">
                {{element.Destinatario.cambio}}
              </div>
            </div>
            <div *ngIf="element.DireccionDestinatario && element.DireccionDestinatario.cambio" class="linea">
              <div class="dato"> {{element.DireccionDestinatario.original}} </div>
              <div>
                <mat-icon>published_with_changes</mat-icon>
              </div>
              <div class="example-element-symbol dato">
                {{element.DireccionDestinatario.cambio}}
              </div>
            </div>
            <div *ngIf="element.CpDestinatario && element.CpDestinatario.cambio" class="linea">
              <div class="dato"> {{element.CpDestinatario.original}} </div>
              <div>
                <mat-icon>published_with_changes</mat-icon>
              </div>
              <div class="example-element-symbol dato">
                {{element.CpDestinatario.cambio}}
              </div>
            </div>
            <div *ngIf="element.PoblacionDestinatario && element.PoblacionDestinatario.cambio" class="linea">
              <div class="dato"> {{element.PoblacionDestinatario.original}} </div>
              <div>
                <mat-icon>published_with_changes</mat-icon>
              </div>
              <div class="example-element-symbol dato">
                {{element.PoblacionDestinatario.cambio}}
              </div>
            </div>
            <div *ngIf="element.ProvinciaDestinatario && element.ProvinciaDestinatario.cambio" class="linea">
              <div class="dato"> {{element.ProvinciaDestinatario.original}} </div>
              <div>
                <mat-icon>published_with_changes</mat-icon>
              </div>
              <div class="example-element-symbol dato">
                {{element.ProvinciaDestinatario.cambio}}
              </div>
            </div>
            <div *ngIf="element.TelefonoDestinatario && element.TelefonoDestinatario.cambio" class="linea">
              <div class="dato"> {{element.TelefonoDestinatario.original}} </div>
              <div>
                <mat-icon>published_with_changes</mat-icon>
              </div>
              <div class="example-element-symbol dato">
                {{element.TelefonoDestinatario.cambio}}
              </div>
            </div>
            <div *ngIf="element.ContactoEntrega && element.ContactoEntrega.cambio" class="linea">
              <div class="dato"> {{element.ContactoEntrega.original}} </div>
              <div>
                <mat-icon>published_with_changes</mat-icon>
              </div>
              <div class="example-element-symbol dato">
                {{element.ContactoEntrega.cambio}}
              </div>
            </div>
            <div *ngIf="element.ObservacionesEntrega && element.ObservacionesEntrega.cambio" class="linea">
              <div class="dato"> {{element.ObservacionesEntrega.original}} </div>
              <div>
                <mat-icon>published_with_changes</mat-icon>
              </div>
              <div class="example-element-symbol dato">
                {{element.ObservacionesEntrega.cambio}}
              </div>
            </div>
            <div *ngIf="element.KmDestinatario && element.KmDestinatario.cambio" class="linea">
              <div class="dato"> {{element.KmDestinatario.original}} </div>
              <div>
                <mat-icon>published_with_changes</mat-icon>
              </div>
              <div class="example-element-symbol dato">
                {{element.KmDestinatario.cambio}}
              </div>
            </div>
        
            <div *ngIf="element.ReferenciaCliente && element.ReferenciaCliente.cambio" class="linea">
              <div class="dato"> {{element.ReferenciaCliente.original}} </div>
              <div>
                <mat-icon>published_with_changes</mat-icon>
              </div>
              <div class="example-element-symbol dato">
                {{element.ReferenciaCliente.cambio}}
              </div>
            </div>
            <div *ngIf="element.Servicio && element.Servicio.cambio" class="linea">
              <div class="dato"> {{element.Servicio.original}} </div>
              <div>
                <mat-icon>published_with_changes</mat-icon>
              </div>
              <div class="example-element-symbol dato">
                {{element.Servicio.cambio}}
              </div>
            </div>
            <div *ngIf="element.RangoHorarioRecogidaManana && element.RangoHorarioRecogidaManana.cambio" class="linea">
              <div class="dato"> {{element.RangoHorarioRecogidaManana.original}} </div>
              <div>
                <mat-icon>published_with_changes</mat-icon>
              </div>
              <div class="example-element-symbol dato">
                {{element.RangoHorarioRecogidaManana.cambio}}
              </div>
            </div>
            <div *ngIf="element.RangoHorarioRecogidaTarde && element.RangoHorarioRecogidaTarde.cambio" class="linea">
              <div class="dato"> {{element.RangoHorarioRecogidaTarde.original}} </div>
              <div>
                <mat-icon>published_with_changes</mat-icon>
              </div>
              <div class="example-element-symbol dato">
                {{element.RangoHorarioRecogidaTarde.cambio}}
              </div>
            </div>
        </div>
      </td>
    </ng-container>
  
    <tr mat-header-row *matHeaderRowDef="displayedColumns sticky:true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="example-element-row"
    [class.example-expanded-row]="expandedElement === row.Id"
    (click)="borramodificado(row.NumAlbaran);expandedElement = expandedElement == row.Id ? null : row.Id">
    </tr>
    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
  </table>
  