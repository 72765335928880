<div *ngIf="!config" style="width:100%;display:inline-flex;margin-top:20px;">
  <div style="width:20%;padding:10px;">
    <mat-selection-list [multiple]="false">
      <mat-list-option (click)="seleccionausuariotodos()" [ngClass]="{'seleccionado': !destino}">
        <div style="display:inline-flex;width:100%;margin-top:10px;" [matBadge]="mensajesnuevostodos()" [matBadgeOverlap]="true" matBadgeSize="small" [matBadgeHidden]="mensajesnuevostodos() == 0" matBadgeColor="primary">
          <div>Chat General</div>
        </div>
      </mat-list-option>
      <mat-list-option *ngFor="let user of usuarios" [value]="user" (click)="seleccionausuario(user)" [ngClass]="{'seleccionado': destino && destino.usuario && destino.usuario == user.usuario}">
        <div style="display:inline-flex;width:100%;margin-top:10px;" [matBadge]="mensajesnuevosusuario(user.usuario)" [matBadgeOverlap]="true" matBadgeSize="small" [matBadgeHidden]="mensajesnuevosusuario(user.usuario) == 0" matBadgeColor="primary">
          <div [ngClass]="user.conectado? 'conectado' : 'desconectado'"></div>
          <div>{{user.alias}}</div>
        </div>
      </mat-list-option>
    </mat-selection-list>
  </div>
  <div style="width:75%;padding:10px;">
    <div style="display:inline-flex;">
      <div *ngIf="destino" class="elementobarra">
        {{destino.alias}}
      </div>
      <div *ngIf="!destino" class="elementobarra">
        Chat General
      </div>
    </div>
    <div #msglist style="width:100%;height:400px;overflow-y:scroll;border: 1px solid black">
      <mat-card *ngFor="let mensaje of mensajeschat; let indice = index" [ngClass]="{'derecha': mensaje.usuario == identity._id, 'izquierda': mensaje.usuario != identity._id, 'ultimo': indice == mensajeschat.length-1}">
        <mat-card-content>
          <div>
            {{aliasusuario(mensaje.usuario)}}:
          </div>
          <div style="width:100%;display:inline-flex;">
            <div class="txtmensaje" [innerHtml]="mensaje.mensaje">
            </div>
            <div *ngIf="mensaje.leido && mensaje.usuario == identity._id" class="checkmensajeleido">
              <mat-icon>done_all</mat-icon>
            </div>
          </div>
        </mat-card-content>
        <mat-card-footer class="hora">
          {{mensaje.fechahora | date:'dd/MM/yyyy HH:mm'}}
        </mat-card-footer>
      </mat-card>
    </div>
    <div style="display:inline-flex;width:100%;padding:10px;">
      <div style="width:90%">
        <div class="contieneeditor">
					<ckeditor #editor [editor]="Editor" [config]="{ 
						toolbar: {
							items: [
								'heading',
								'|',
								'undo',
								'redo',
								'|',
								'bold',
								'italic',
								'fontColor',
								'fontSize',
								'fontFamily',
								'|',
								'alignment',
								'bulletedList',
								'numberedList',
								'highlight',
								'|',
								'imageUpload',
								'insertTable',
								'|',
								'horizontalLine',
							]
						},
						language: 'es',
						image: {
							styles: [
								'full', 'side', 'alignLeft', 'alignCenter', 'alignRight'
							],
							toolbar: [
								'imageStyle:full',
								'imageStyle:side',
								'|',
								'imageStyle:alignLeft',
								'imageStyle:alignCenter',
								'imageStyle:alignRight',
								'|',
								'ImageResize',
								'imageTextAlternative',
							]
						},
						table: {
							contentToolbar: [
								'tableColumn',
								'tableRow',
								'mergeTableCells',
								'tableCellProperties',
								'tableProperties'
							]
						}
					 }" [(ngModel)]="textomensaje" (ready)="iniciareditor($event)">
					</ckeditor>
				</div>        
      </div>
      <div style="padding-left: 20px;">
        <button mat-raised-button (click)="enviamensaje()">Enviar</button>
        <mat-checkbox style="padding-top: 10px;" [(ngModel)]="urgente">Urgente</mat-checkbox>
      </div>
    </div>
  </div>
</div>
<div *ngIf="config" style="width:100%;display:inline-flex;margin-top:20px;">
  <div style="width:50%;padding:10px;">
    <mat-selection-list [multiple]="false">
      <mat-list-option *ngFor="let user of usuarios" [value]="user">
        <div style="display:inline-flex;width:100%">
          <div>{{user.alias}}</div>
          <div>
            <button mat-button (click)="eliminausuario(user)">Eliminar</button>
          </div>
        </div>
      </mat-list-option>
    </mat-selection-list>
  </div>
</div>
