import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs/Observable";
import { GLOBAL } from "./global";
import { AppService } from "./app.service";

@Injectable()
export class Office365Service {
  public urloffice: string;
  public dirmailraiz: string;
  public dircarpetas: string;

  constructor(public _http: HttpClient, public _appservice: AppService) {
    this.urloffice = GLOBAL.urloffice;
    this.dirmailraiz = "https://graph.microsoft.com/v1.0/me/mailFolders";
    this.dircarpetas = this.dirmailraiz + "?$top=100";
  }

  vercodigo(): Observable<any> {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("authorization", this._appservice.getToken());
    return this._http.get(this.urloffice + "obtenercodigo", {
      headers: headers,
    });
  }

  solicitarcarpetas(): Observable<any> {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("Authorization", this._appservice.getTokenOffice());
    return this._http.get(this.dircarpetas, { headers: headers });
  }

  solicitarcarpeta(carpeta): Observable<any> {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("Authorization", this._appservice.getTokenOffice());
    return this._http.get(this.dircarpetas, { headers: headers });
  }

  solicitaremails(url): Observable<any> {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("Authorization", this._appservice.getTokenOffice());
    return this._http.get(url, { headers: headers });
  }

  recuperamensaje(mensaje, carpeta): Observable<any> {
    let dir = this.dirmailraiz + "/" + carpeta + "/messages";
    let params = JSON.stringify(mensaje);
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("Authorization", this._appservice.getTokenOffice());
    return this._http.post(dir, params, { headers: headers });
  }

  actualizarobjeto(url, objeto): Observable<any> {
    var params = JSON.stringify(objeto);
    var bodylength = JSON.stringify(objeto).length.toString();

    return this._http.patch(url, params, {
      headers: {
        Authorization: this._appservice.getTokenOffice(),
        "Content-Type": "application/json",
        "Content-Length": bodylength,
      },
    });
  }

  solicitaAdjunto(url): Observable<any> {
    return this._http.get(url, {
      headers: { Authorization: this._appservice.getTokenOffice() },
      responseType: "blob",
    });
  }

  responder(url): Observable<any> {
    return this._http.post(url, "", {
      headers: { Authorization: this._appservice.getTokenOffice() },
    });
  }

  enviarRespuesta(url, cuerpo): Observable<any> {
    var bodylength = JSON.stringify(cuerpo).length.toString();
    return this._http.post(url, cuerpo, {
      headers: {
        Authorization: this._appservice.getTokenOffice(),
        "Content-Type": "application/json",
        "Content-Length": bodylength,
      },
      observe: "response",
    });
  }

  eliminamensaje(url): Observable<any> {
    return this._http.delete(url, {
      headers: { Authorization: this._appservice.getTokenOffice() },
      observe: "response",
    });
  }

  getmensaje(url): Observable<any> {
    return this._http.get(url, {
      headers: { Authorization: this._appservice.getTokenOffice() },
      observe: "response",
    });
  }

  movermensaje(url, cuerpo): Observable<any> {
    return this._http.post(url, cuerpo, {
      headers: {
        Authorization: this._appservice.getTokenOffice(),
        "Content-Type": "application/json",
      },
      observe: "response",
    });
  }

  getcategorys(): Observable<any> {
    return this._http.get(
      "https://graph.microsoft.com/v1.0/me/outlook/masterCategories",
      { headers: { Authorization: this._appservice.getTokenOffice() } }
    );
  }

  buscarenservidor(texto): Observable<any> {
    var dir =
      'https://graph.microsoft.com/v1.0/me/messages?$search="' +
      texto +
      '"&$top=100';
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("Authorization", this._appservice.getTokenOffice());
    return this._http.get(dir, { headers: headers });
  }
  buscareneliminados(texto): Observable<any> {
    var dir =
      'https://graph.microsoft.com/v1.0/me/mailFolders/deleteditems/messages?$search="' +
      texto +
      '"&$top=100';
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("Authorization", this._appservice.getTokenOffice());
    return this._http.get(dir, { headers: headers });
  }
}
