<h2 class="centrado">{{title}}</h2>
<div *ngIf="identity.role == 'Administrador' && cliente" class="divenlace">
	<a class="enlace" [routerLink]="['../../cliente/' + cliente._id]"><mat-icon style="font-size:25pt;">arrow_back</mat-icon><br>Volver</a>	
</div>
<div *ngIf="archivos && archivos.length >=1" class="lista">
	<table style="width:100%;" mat-table [dataSource]="archivos">
		<ng-container matColumnDef="archivo">
			<th mat-header-cell *matHeaderCellDef>Archivo</th>
			<td mat-cell *matCellDef="let element">
				<mat-icon style="font-size:28px;margin-right:5px;cursor:pointer;" (click)='descargaArchivo(element)'>cloud_download</mat-icon> {{element}}
			</td>
		</ng-container>
		<ng-container matColumnDef="Eliminar">
			<th mat-header-cell *matHeaderCellDef="Eliminar">
				Eliminar
			</th>
			<td mat-cell *matCellDef="let row">
				<a href="#victorModal" data-toggle="modal" (click)="setarchivoaborrar(row)">
					<button mat-icon-button aria-label="Clear">
						<mat-icon>delete</mat-icon>
					</button>
				</a>	
			</td>
		</ng-container>
		<ng-container matColumnDef="CreaEnlace">
			<th mat-header-cell *matHeaderCellDef="CreaEnlace">
				Crear Enlace
			</th>
			<td mat-cell *matCellDef="let row">
				<button mat-icon-button aria-label="Clear" (click)="creaenlace(row)">
					<mat-icon>add_link</mat-icon>
				</button>
			</td>
		</ng-container>
		<ng-container matColumnDef="Enlace">
			<th mat-header-cell *matHeaderCellDef="Enlace">
				Enlace
			</th>
			<td mat-cell *matCellDef="let row">
				{{enlaces[row].url}}
			</td>
		</ng-container>
		<ng-container matColumnDef="Password">
			<th mat-header-cell *matHeaderCellDef="Password">
				Password
			</th>
			<td mat-cell *matCellDef="let row">
				{{enlaces[row].password}}
			</td>
		</ng-container>
		<tr mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></tr>
		<tr mat-row *matRowDef="let row; columns: displayedColumns;" class="celdas" (dblclick)='descargaArchivo(row)'></tr>
	</table>
	<!--<ul>
		<li *ngFor="let archivo of archivos">
			<div class="listafacturas">
				<div class="lilistafacturas" (click)='descargaArchivo(archivo)'>
					<mat-icon>cloud_download</mat-icon>{{archivo}} 
				</div>
				<div class="borralista"><span (click)="setarchivoaborrar(archivo)"><a href="#victorModal" data-toggle="modal"><mat-icon>delete</mat-icon></a></span></div>
				<div>
					<button mat-raised-button matTooltip="Crear enlace" (click)="creaenlace(archivo)">
						<mat-icon>add_link</mat-icon>
					</button>
				</div>
				<div *ngIf="enlaces && enlaces[archivo]">
					enlace: {{enlaces[archivo].url}}  password:{{enlaces[archivo].password}}
				</div>
			</div> 
		</li>
	</ul>-->
	
</div>
<div class="contienesubir" *ngIf="status != 'subido'">	
	<p>
		<input type="file" multiple="true" (change)="fileChangeEvent($event)"/>
	</p>

	<p>
		<input class="btn btn-success" value=" subir archivos " type="button" (click)="subir()"/>
	</p>

</div>
<div class="clearfix"></div>
<div class="alert alert-success centrado" *ngIf="status == 'subido'">
	el archivo se ha subido correctamente!!
</div>

<div id="victorModal" class="modal fade centramodal">
    <div class="modal-dialog centrado">
        <div class="modal-content centrado">
            <div class="modal-header">
                <h4 class="modal-title">¿Estás seguro?</h4>
            </div>
            <div class="modal-body">
                <p>¿Seguro que quieres borrar este elemento?</p>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-default" data-dismiss="modal">Cerrar</button>
                <button type="button" class="btn btn-danger" (click)="borraarchivo(archivoaborrar)" data-dismiss="modal">Eliminar</button>
            </div>
        </div>
    </div>
</div>	

