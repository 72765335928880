<h2 class="centrado">{{title}}</h2>
<div *ngIf="status != 'success'" class="centrado">
		
	<form #contactoForm="ngForm" (ngSubmit)="onSubmit(contactoForm)">
		<div class="formulario">
			
			<div class="datosprincipales">
				<p>
					<label>Asunto</label>
					<input type="text" name="Asunto" #Asunto="ngModel" [(ngModel)]="asunto" class="form-control" required />

				</p>
				<p>
					<label>Mensaje</label>
					<textarea name="Mensaje" #Mensaje="ngModel" [(ngModel)]="mensaje" class="form-control textomensaje" required></textarea> 
				</p>
				<p>
					<label>Email donde enviar nuestra respuesta</label>
					<input type="email" name="mailrespuesta" #mailrespuesta="ngModel" [(ngModel)]="emailrespuesta" class="form-control" required pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"/>
				</p>
							
			</div>	
		<div class="clearfix"></div>	
		<div class="centrado">
		<input type="submit" value="Enviar" class="btn btn-success" [disabled]="!contactoForm.form.valid" />
		</div>
	</div>
	</form>
	
</div>	
<div class="alert alert-success centrado" *ngIf="status == 'success'">
	Datos enviados correctamente, recibira respuesta lo antes posible.
</div>		