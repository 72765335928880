import { Component, OnInit } from '@angular/core';
import { AppService } from '../../servicios/app.service';
import { IncidenciasService } from '../../servicios/incidencias.service';
import { AlbaranComponent } from '../albaran/albaran.component';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-admintab',
  templateUrl: './admintab.component.html',
  styleUrls: ['./admintab.component.css'],
  providers: [AppService, IncidenciasService]
})
export class AdmintabComponent implements OnInit {
  public identity;
  public activetab;
  public category;
  public filtros;
  

  constructor(
    private _appservice: AppService,
    private _route: ActivatedRoute,
    private _incidenciasservice: IncidenciasService,
    public ventanaAlbaran: MatDialog    
  ) {
    this.filtros = {
      filtroprincipal: ''
    };

  }

  ngOnInit(): void {
    this.identity = this._appservice.getIdentity();
    this._route.params.subscribe(params=>{
      if(params && params.categoria){
        this.category = params.categoria;
      }
    });  
  }

  categoriaseleccionada(categoria){
    this.category = categoria;
  }

  bucaryabrir(alb){
    var albaran = alb.trim();
    this._incidenciasservice.getincidencia(albaran).subscribe(
        response =>{
          if(response.incidencia){
            this.abrealbaran(response.incidencia);
          }else{
            alert('no se encuentra albaran con numero: alb.NumAlbaran');
          }              
        },
        error=>{
          console.log(<any>error);
        });  
  }
  
  abrealbaran(incidencia){
    this.ventanaAlbaran.open(AlbaranComponent, {
      width: '85%',
      height: '85%',
      data: incidencia
    });

  }

}
