import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ClienteService } from '../../servicios/cliente.service';
import * as moment from 'moment';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition, MatSnackBarConfig } from '@angular/material/snack-bar';
import { FormControl } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { AppService } from '../../servicios/app.service';
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { Observable } from 'rxjs';

@Component({
  selector: 'destinatariosmasivo',
  templateUrl: './destinatariosmasivo.component.html',
  styleUrls: ['./destinatariosmasivo.component.css'],
  providers: [ClienteService, AppService]
})
export class DestinatariosmasivoComponent implements OnInit {
  public identity;
  public numerosplataforma;
  public filtros = {
    clientesg3: false,
    solog3: false,
    personalizar: false
  }
  public destinatario;
  public tarifaselected;
  public myControl = new FormControl();
  public filteredOptions: Observable<any[]>;
  public dataSource = new MatTableDataSource<any>();
  public columns = ['Numero', 'Nombre', 'Email', 'Eliminar'];
  @ViewChild(MatSort, {static: false}) set content(sort: MatSort){this.dataSource.sort = sort;}
  constructor(
    private _clienteService: ClienteService,
  	public dialogoDestinatarios: MatDialogRef<DestinatariosmasivoComponent>,
    private _snackBar: MatSnackBar,
    private _appservice: AppService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { 
    this.identity = this._appservice.getIdentity();
  }

  ngOnInit(): void {
    this.getplataforma();
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.content;
  }

  aviso(texto, subtexto) {
    this._snackBar.open(texto,subtexto,{
      duration: 4000,
      horizontalPosition: "center",
      verticalPosition: "top",
    });
  }

  getplataforma(){
    this.dataSource.data = this.data.plataforma.sort(function(a,b){
      let origen = parseInt(a.Numero);
      let destino = parseInt(b.Numero);
      return origen > destino;
    });
  }

  checksolog3(event){
    this.filtros.clientesg3 = false;
    if(!event){
        this.dataSource.data = this.data.plataforma.sort(function(a,b){
          let origen = parseInt(a.Numero);
          let destino = parseInt(b.Numero);
          return origen > destino;
        });
    }else{
      this.filteredOptions = this.myControl.valueChanges
            .pipe(
              startWith(''),
              map(name => name ? this.filtro(name) : this.data.tarifas.slice())
            );
      this.dataSource.data = this.data.g3.sort(function(a,b){
        let origen = parseInt(a.Numero);
        let destino = parseInt(b.Numero);
        return origen > destino;
      });
    }
  }

  filtro(name: string){
    const filterValue = name.toLowerCase();
    return this.data.tarifas.filter(option => option.toLowerCase().indexOf(filterValue) === 0);
  }

  checkincluirg3(event){
    this.filtros.solog3 = false;
    if(!event){
      this.dataSource.data = this.data.plataforma.sort(function(a,b){
        let origen = parseInt(a.Numero);
        let destino = parseInt(b.Numero);
        return origen > destino;
      });
    }else{
      var plat = this.dataSource.data.map((num)=>{
        return num.Numero;
      });
      
      let destinos = this.data.g3.filter((ab)=>{
        if(plat.includes(ab.Numero)){
          return false;
        }else{
          return true;
        }
      }).concat(this.dataSource.data).sort(function(a,b){
        let origen = parseInt(a.Numero);
        let destino = parseInt(b.Numero);
        return origen > destino;
      });
      this.dataSource.data = destinos;
    }
  }

  limpiarlista(){
    this.dataSource.data = new Array();
    this.filtros = {
        clientesg3: false,
        solog3: false,
        personalizar: false
      }
  }

  cerrar(){
    this.dialogoDestinatarios.close();
  }

  guardar(){
    let destinatarios = this.dataSource.data.map((email)=>{
      if(email.Email.includes(';')){
        //incluir emails con ;
      }
      return email.Email;
    });
    this.dialogoDestinatarios.close(destinatarios);
  }

  checkpersonalizar(event){
    if(event){
      this.filtros.personalizar = true;
    }else{
      this.filtros.personalizar = false;
    }  
  }

  filtratarifa(tarifa){
    let cecos = this.data.g3.filter((ceco)=>{
      return ceco.Tarifa == tarifa;
    });
    this.dataSource.data = cecos;
  }

  todastarifas(){
    this.myControl.setValue(null);
    this.dataSource.data = this.data.g3.sort(function(a,b){
      let origen = parseInt(a.Numero);
      let destino = parseInt(b.Numero);
      return origen > destino;
    });
  }

  agregadestinatario(){
    let destinos = this.dataSource.data;
    destinos.push({
      Nombre:this.destinatario,
      Numero:this.dataSource.data.length,
      Email:this.destinatario
    });
    this.dataSource.data = destinos;
    this.destinatario = undefined;
  }

  eliminadestinatario(destinatario){
    let destinos = this.dataSource.data;
    destinos.splice(destinos.findIndex(a=>a.Email == destinatario), 1);
    this.dataSource.data = destinos;
  }

}
