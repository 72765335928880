import {
  Component,
  OnInit,
  DoCheck,
  ViewChild,
  ViewEncapsulation,
  EventEmitter,
  Output,
} from "@angular/core";
import { Router } from "@angular/router";
import { AppService } from "./servicios/app.service";
import { DialogfichajeComponent } from "./components/dialogfichaje/dialogfichaje.component";
import { MensajeriaComponent } from "./components/mensajeria/mensajeria.component";
import { FichajeService } from "./servicios/fichaje.service";
import { ClienteService } from "./servicios/cliente.service";
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
  MatSnackBarConfig,
} from "@angular/material/snack-bar";
import { MatDialog } from "@angular/material/dialog";
import { MatMenuTrigger } from "@angular/material/menu";
import { DragDropModule } from "@angular/cdk/drag-drop";
import * as moment from "moment";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
  providers: [AppService, FichajeService, ClienteService],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit, DoCheck {
  public title = "Mensasur Getafe";
  public identity;
  public activetab;
  public galletaG3;
  public totalemailsinleer;
  public registrado;
  public contadoractivo;
  public tokenoffice;
  public concertar;
  public dirmailraiz = "https://graph.microsoft.com/v1.0/me/mailFolders";
  public dircarpetas = this.dirmailraiz + "?$top=100";
  public ficha;
  public chat = false;
  public config = false;
  public dragPosition = { x: 0, y: 0 };
  public mensajes = [];
  public chatcomponent: MensajeriaComponent;
  public pendienteswilson = 0;

  @ViewChild("menuTrigger") menuTrigger: MatMenuTrigger;
  @ViewChild("MensajeriaComponent") set content(content: MensajeriaComponent) {
    if (content) {
      this.chatcomponent = content;
    }
  }

  constructor(
    private _router: Router,
    private _snackBar: MatSnackBar,
    private _appservice: AppService,
    private _fichajeService: FichajeService,
    private _clienteservice: ClienteService,
    public dialogficha: MatDialog,
    public dialog: MatDialog
  ) {
    this.totalemailsinleer = 0;
    this.registrado = false;
    this.contadoractivo = false;
  }

  ngOnInit() {
    setTimeout(() => {
      this.comprobartokens();
    }, 120000);
  }

  ngDoCheck() {
    this.identity = this._appservice.getIdentity();
    if (this.identity) {
      this.registrado = true;
    } else {
      this.registrado = false;
    }
    if (this.registrado && this.identity["role"] != "cliente") {
      this.ficha = this._fichajeService.getficha();
      var tokenoffice = this._appservice.getTokenOffice();
      if (tokenoffice) {
        this.tokenoffice = true;
      } else {
        this.tokenoffice = false;
      }
      let galleta = this._appservice.getgalletag3();
      if (galleta) {
        this.galletaG3 = true;
      } else {
        this.galletaG3 = false;
      }
    }

    let concerta = sessionStorage.getItem("concertar");
    if (concerta && concerta == "true") {
      this.concertar = true;
    } else {
      this.concertar = false;
    }
  }

  aviso(texto, subtexto) {
    this._snackBar.open(texto, subtexto, {
      duration: 4000,
      horizontalPosition: "center",
      verticalPosition: "top",
    });
  }

  refrescaG3() {
    console.log("activado renovacion g3");
    this._appservice.refreshg3();
  }

  abremensajeria() {
    this.chat = true;
  }

  abrechat(mensaje) {
    this.chat = true;
    this.chatcomponent.abrirnotificacion(mensaje);
  }

  cierrachat() {
    this.chat = false;
  }

  getnuevosmensajes(mensajes) {
    this.mensajes = mensajes;
  }

  ocultanotificacionesmensajes() {
    this.mensajes = [];
  }

  openDialog() {
    if (this.ficha && this.ficha != null) {
      var ficha = this.ficha;
    } else {
      this.ficha = {
        estado: "sin datos",
      };
      var ficha = this.ficha;
    }
    const dialogRef = this.dialogficha.open(DialogfichajeComponent, {
      width: "20%",
      height: "25%",
      hasBackdrop: false,
      disableClose: true,
      data: ficha,
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.aviso(result, "ok");
    });
  }

  logOut() {
    this.identity = undefined;
    this.galletaG3 = undefined;
    sessionStorage.clear();
    this._router.navigate(["login"]);
  }

  tabclick(tab) {
    this.activetab = tab;
    this.enrutar(tab);
    if (
      this.registrado == true &&
      this.identity &&
      this.identity.role != "cliente" &&
      this.contadoractivo == false
    ) {
      this.actualizar();
    }
    if (
      this.registrado == true &&
      tab != "mailcorporativo" &&
      this.identity &&
      this.identity.role != "cliente" &&
      this.contadoractivo == true
    ) {
      this.mailsinleer();
    }
  }

  comercialclick(tab) {
    this.activetab = tab;
    this.enrutar(tab);
  }

  enrutar(ruta) {
    this._router.navigate([ruta]);
  }

  mailsinleer() {
    var identity = this._appservice.getIdentity();
    var carpetas = identity["options"]["carpetasmail"];
    this.totalemailsinleer = 0;
    this._appservice.solicitamails(this.dircarpetas).subscribe(
      (response) => {
        var folders = response.value.filter((fldr) =>
          carpetas.includes(fldr.id)
        );
        folders.forEach((folder) => {
          this.totalemailsinleer =
            this.totalemailsinleer + folder.unreadItemCount;
          if (folder.childFolderCount >= 1) {
            var dir =
              this.dirmailraiz + "/" + folder.id + "/childFolders?$top=100";
            this._appservice.solicitamails(dir).subscribe(
              (response) => {
                var subfolders = response.value;
                subfolders.forEach((fld) => {
                  this.totalemailsinleer =
                    this.totalemailsinleer + fld.unreadItemCount;
                });
              },
              (error) => {
                console.log(<any>error);
              }
            );
          }
        });
      },
      (error) => {
        console.log(<any>error);
      }
    );
  }

  getnotifwilson() {
    this._clienteservice.getnotificacioneswilson().subscribe(
      (res) => {
        this.pendienteswilson = res.notif.pendientes;
      },
      (error) => {
        console.log(error);
      }
    );
  }

  abrewilson() {
    window.open("https://wilsonapp.tech/frontend/dashboard/gestion");
  }

  actualizar() {
    setTimeout(() => {
      if (this.identity) {
        this.contadoractivo = true;
        if (!this.galletaG3) this._appservice.getgalletag3();
        var to = this._appservice.getTokenOffice();
        if (this.identity.role != "cliente" && to) this.mailsinleer();
        if (this.identity.role == "Administrador") this.getnotifwilson();
      }
    }, 4000);
    setTimeout(() => {
      this.actualizar();
    }, 120000);
  }

  comprobartokens() {
    if (this.identity && this.identity["role"] != "cliente") {
      this._appservice.comprobartokens();
      this._fichajeService.getfichajeusr().subscribe(
        (response) => {
          if (response && response.ficha) {
            this._fichajeService.setficha(response.ficha);
          }
        },
        (error) => {
          console.log("el usuario aun no ha fichado hoy");
        }
      );
    } else if (this.identity) {
      this._appservice.pruebag3().subscribe((response) => {
        if (response.message) this._appservice.refreshg3();
      });
    }
    setTimeout(() => {
      this.comprobartokens();
    }, 200000);
  }
}
