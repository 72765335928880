<div class="contenedorprincipal">
    <div class="datosenvio">
        <div class="remdest">
            <div class="datos1">
                <mat-form-field class="remitente">
                    <mat-label>Nombre</mat-label>
                    <input
                        matInput
                        placeholder="Nombre"
                        [formControl]="remiteControl"
                        [matAutocomplete]="autorem">
                    <mat-autocomplete #autorem="matAutocomplete" (optionSelected)="remiteselected($event)">
                        <mat-option *ngFor="let option of filteredremite | async" [value]="option">
                            <div style="width:100%;padding:1px;font-size:10pt;display:inline-flex">
                                {{option.nombre}}
                            </div>
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
                <mat-form-field class="telefono">
                    <mat-label>Telefono</mat-label>
                    <input
                        matInput
                        placeholder="Telefono"
                        [(ngModel)]="remite.telefono">
                </mat-form-field>
                <span class="spacer"></span>
                <mat-form-field class="contacto">
                    <mat-label>Contacto</mat-label>
                    <input
                        matInput
                        placeholder="Persona contacto"
                        [(ngModel)]="remite.contacto">
                </mat-form-field>
            </div>
            <div class="datos2">
                <div class="subdatos1">
                    <mat-form-field class="cp">
                        <mat-label>Codigo Postal</mat-label>
                        <input
                            matInput
                            placeholder="Cp"
                            [(ngModel)]="remite.cp"
                            [disabled]="remite.poblacion? true : false"
                            (keyup.enter)="getpoblacionesremiteporcp(remite.cp)">
                    </mat-form-field>
                    <mat-form-field *ngIf="poblacionesporcpremite" class="poblacion">
                        <mat-label>Poblacion</mat-label>
                        <mat-select [(value)]="remite.poblacion" (selectionChange)="seleccionadapoblacionremite($event)">
                            <mat-option [value]="option" *ngFor="let option of poblacionesporcpremite">{{option.NombrePoblacion}}</mat-option>
                        </mat-select>
                    </mat-form-field>    
                    <mat-form-field *ngIf="!poblacionesporcpremite" class="poblacion">
                        <mat-label>Poblacion</mat-label>
                        <input
                            matInput
                            placeholder="Poblacion"
                            [disabled]="remite.provincia? true : false"
                            [(ngModel)]="remite.poblacion"
                            [matAutocomplete]="autoremdirsug"
                            (keyup)="sugerirpoblacionremite($event)">
                        <mat-autocomplete #autoremdirsug="matAutocomplete" (optionSelected)="remdirsugselected($event)">
                            <mat-option *ngFor="let option of poblacionesremitesugeridas" [value]="option">
                                    {{option.NombrePoblacion}}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                    <mat-form-field class="provincia">
                        <mat-label>Provincia</mat-label>
                        <input
                            matInput
                            [disabled] = true
                            [(ngModel)]="remite.provincia">
                    </mat-form-field>
                    <div class="material-icons limpiar" matTooltip="limpiar datos poblacion" (click)="limpiarpoblacionremite()">cleaning_services</div>
                </div>
                <div class="subdatos1">
                    <mat-form-field *ngIf="!callejeroremite" class="calle">
                        <mat-label>Calle</mat-label>
                        <input
                            matInput
                            placeholder="Calle"
                            [(ngModel)]="remite.direccion.calle">
                    </mat-form-field>
                    <mat-form-field *ngIf="callejeroremite" class="calle">
                        <mat-label>Calle</mat-label>
                        <input
                            matInput
                            placeholder="Calle"
                            [(ngModel)]="remite.direccion.calle"
                            [matAutocomplete]="autoremcallesug"
                            (keyup)="getcallesremitesugeridas(remite.direccion.calle)">
                            <mat-autocomplete #autoremcallesug="matAutocomplete" (optionSelected)="remcallesugselected($event)">
                                <mat-option *ngFor="let option of callesremitesugeridas" [value]="option">
                                        {{option.NombreCalle}}
                                </mat-option>
                            </mat-autocomplete>
                    </mat-form-field>
                    <mat-form-field class="via">
                        <mat-label>Tipo via</mat-label>
                        <input
                            matInput
                            placeholder="tipo via"
                            [(ngModel)]="remite.direccion.tipovia">
                    </mat-form-field>
                    <mat-form-field class="numero">
                        <mat-label>Numero</mat-label>
                        <input
                            matInput
                            placeholder="Numero"
                            [(ngModel)]="remite.direccion.numero">
                    </mat-form-field>
                </div>
                <div class="subdatos1">
                    <mat-form-field class="resto">
                        <mat-label>Resto</mat-label>
                        <input
                            matInput
                            placeholder="Resto de direccion"
                            [(ngModel)]="remite.direccion.resto">
                    </mat-form-field>
                    <button (click)="vertemita()">ver temita</button>
                </div>
            </div>
        </div>
        <div class="remdest">
            <div class="datos1">
                <mat-form-field class="remitente">
                    <mat-label>Nombre</mat-label>
                    <input
                        matInput
                        placeholder="Nombre"
                        [formControl]="destinoControl"
                        [matAutocomplete]="autodest">
                    <mat-autocomplete #autodest="matAutocomplete" (optionSelected)="destinoselected($event)">
                        <mat-option *ngFor="let option of filtereddestino | async" [value]="option">
                                {{option.nombre}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>    
        </div>
    </div>
</div>
