import { AfterViewInit, Component, Input, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import {MatSort} from '@angular/material/sort'; 

@Component({
  selector: 'enviosrecibidos',
  templateUrl: './enviosrecibidos.component.html',
  styleUrls: ['./enviosrecibidos.component.css']
})
export class EnviosrecibidosComponent implements OnInit {
  public columns = ['FechaRecogida', 'NumAlbaran', 'Destinatario', 'DireccionDestinatario', 'Kilos', 'TotalBultos', 'TotalMedidas', 'Servicio', 'Estado'];
  public tot = false;
  @Input() envios;
  @Input() totales;
  @Input() load = true;
  @Output() totalesenvios = new EventEmitter<boolean>();
  @ViewChild(MatSort) sort: MatSort;
  constructor(
  ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.envios.sort = this.sort;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.envios.filter = filterValue.trim().toLowerCase();
  }

  toggletotales(){
    this.tot = !this.tot;
    this.totalesenvios.emit(this.tot);
  }
  
}
