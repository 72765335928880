<div style="width:100%">
    <div style="display:inline;width:100%;padding:10px;">
        <mat-form-field style="margin-left:50px;width:300px;">
            <mat-label><b>Buscar</b></mat-label>
            <input matInput (keyup)="applyFilter($event)" placeholder="buscar.." [(ngModel)]="filtro" style="padding:1px;color:black;">
            <button mat-button *ngIf="filtro" matSuffix mat-icon-button aria-label="Clear" (click)="grupos.filter = ''; filtro=''">
                <mat-icon>close</mat-icon>
            </button>
        </mat-form-field>
        <mat-form-field style="width:300px;text-align:center;margin-left:50px;">
            <mat-label (click)="picker3.open()"><b>Rango Fechas</b></mat-label>
            <mat-date-range-input [rangePicker]="picker3" (click)="picker3.open()">
              <input matStartDate placeholder="Fecha Inicio" [(ngModel)]="fechai">
              <input matEndDate placeholder="Fecha fin" [(ngModel)]="fechaf" (dateChange)="onDate()">
            </mat-date-range-input>
            <button mat-button *ngIf="fechai" matSuffix mat-icon-button aria-label="Clear" (click)="getgrupostotales()">
              <mat-icon>close</mat-icon>
            </button>
            <mat-date-range-picker #picker3></mat-date-range-picker>
          </mat-form-field>
    </div>    
    <div class="listagrupos">
        <table mat-table [dataSource]="grupos" class="tabla">
            <ng-container matColumnDef="FECHA">
                <th mat-header-cell *matHeaderCellDef>
                  Fecha
                </th>
                <td mat-cell *matCellDef="let element">
                  {{element.creado | date:'dd/MM/yyyy HH:mm:ss'}}
                </td>
            </ng-container>
            <ng-container matColumnDef="CLIENTE">
                <th mat-header-cell *matHeaderCellDef>
                  Cliente
                </th>
                <td mat-cell *matCellDef="let element">
                  {{element.cliente}}
                </td>
            </ng-container>
            <ng-container matColumnDef="ENVIOS">
                <th mat-header-cell *matHeaderCellDef="ENVIOS">
                    Envios
                </th>
                <td mat-cell *matCellDef="let element">
                    {{element.envios.length}}
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="columnsgrupos; sticky:true"></tr>
            <tr mat-row *matRowDef="let row; columns: columnsgrupos;" class="celdas" [class.seleccionada]="gruposeleccionado == row" (click)="lineasgrupo(row)"></tr>
        </table>
    </div>
    <div class="barraseleccion">
        <button *ngIf="gruposeleccionado && gruposeleccionado.errores && gruposeleccionado.errores.length >= 1 && !errores" mat-icon-button class="botonbarra" (click)="erroresgrupo()">
            <mat-icon class="material-icons-outlined" matTooltip="Errores solicitud de direcciones">report_problem</mat-icon>
        </button>
        <button *ngIf="gruposeleccionado && gruposeleccionado.errores && gruposeleccionado.errores.length >= 1 && errores" mat-icon-button class="botonbarra" (click)="volveralineasgrupo()">
            <mat-icon class="material-icons-outlined" matTooltip="Errores solicitud de direcciones">report_problem</mat-icon>
        </button>
    </div>
    <div class="listalineas">
        <table mat-table *ngIf="lineas" [dataSource]="lineas" class="tabla">
            <ng-container *ngFor="let column of columns; let index" [matColumnDef]="column">
                <th mat-header-cell *matHeaderCellDef>
                  {{column}}
                </th>
                <td mat-cell *matCellDef="let element">
                  {{element[column.toLowerCase()]}}
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="columns; sticky:true"></tr>
            <tr mat-row *matRowDef="let row; columns: columns;" class="celdas"></tr>
        </table>
    </div>
</div>