<div class="contenidoincidencias">
		<table mat-table *ngIf="dataSource && identity" [dataSource]="dataSource" matSort>
		  <ng-container matColumnDef="nombre">
		    <th mat-header-cell *matHeaderCellDef mat-sort-header>
			    <mat-form-field appearance="outline" style="text-align:center;max-width:110px;font-size:7pt">
				  <mat-label style="color:black;"><b>Nombre</b></mat-label>
				  <input matInput type="text" [(ngModel)]="filtros['nombre']" style="padding:1px;color:black;">
				  <button mat-button *ngIf="filtros['nombre']" matSuffix mat-icon-button aria-label="Clear" (click)="filtros['nombre']=''">
				    <mat-icon>close</mat-icon>
				  </button>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.nombre}} </td>
		  </ng-container>
		  <ng-container matColumnDef="numero">
		    <th mat-header-cell *matHeaderCellDef mat-sort-header>
		    	<mat-form-field appearance="outline" style="text-align:center;max-width:80px;font-size:7pt">
				  <mat-label style="color:black;"><b>Numero</b></mat-label>
				  <input matInput type="text" [(ngModel)]="filtros['numero']" style="padding:1px;color:black;">
				  <button mat-button *ngIf="filtros['numero']" matSuffix mat-icon-button aria-label="Clear" (click)="filtros['numero']=''">
				    <mat-icon>close</mat-icon>
				  </button>
				</mat-form-field>
		    </th>
		    <td mat-cell *matCellDef="let element"> {{element.numero}} </td>
		  </ng-container>
		  <ng-container matColumnDef="importe">
		    <th mat-header-cell *matHeaderCellDef mat-sort-header>
		    	<mat-card style="width:100%;font-size:10pt;height:15pt;padding:2px;margin:0px;">
					   Importe
				</mat-card>
		    </th>
		    <td mat-cell *matCellDef="let element"><b>{{element.totalimporte.toFixed(2)}} <mat-icon style="margin-left:4px;font-size:smaller">euro_symbol</mat-icon></b></td>
		  </ng-container>
		  <tr mat-header-row *matHeaderRowDef="totalcolumns; sticky:true"></tr>
		  <tr mat-row class="celdas" (dblclick)="enrutar('deudasmensajero/'+ row._id + '/si')" (click)="seleccionar(row)" *matRowDef="let row; columns: totalcolumns;"></tr>
		</table>
	</div>
	<div style="display:inline-flex;align-items:center;">
		<mat-paginator *ngIf='mensajeros' style="color:black;" showFirstLastButtons [length]="mensajeros.length" [pageSize]="identity.options.limit" [pageSizeOptions]="[5, 10, 25, 50, 100]">
		</mat-paginator>
	</div>
	<div style="width:100%">
		<div *ngIf="totalimporte" class="cajaherramientasincidencias" style="width:10%;padding-left:10px;text-align:center;align-content:center;font-size:20px;">
			<span style="padding:4px;margin-bottom:2px;">Total Deudas</span><br><br>
			<span style="padding:4px;border:0.2px solid black;font-size:30px;"><b>{{totalimporte.toFixed(2)}}</b> <mat-icon style="margin-left:4px;font-size:smaller">euro_symbol</mat-icon></span>
		</div>
	</div>