<div class="contenedoremail">
	<div class="contienelistacarpetas">
		<div style="width:100%;display:inline-flex;border-bottom: 1px solid rgba(0, 0, 0, 0.116);">
			<div style="width:60%;">
				<button mat-raised-button class="fondoscuro" matTooltip="Mensaje Nuevo" (click)="crearmensajenuevo()"><mat-icon class="material-icons-outlined">forward_to_inbox</mat-icon></button>
			</div>
			<span class="spacercorto"></span>
			<div *ngIf="mensajeEliminado" style="width:15%;cursor:pointer;margin-right:10px;" matTooltip="deshacer eliminar" class="fondoscuro" (click)="recuperamensaje()">
				<mat-icon class="material-icons">restore_from_trash</mat-icon>
			</div>
		</div>
		<mat-list style="overflow-y:scroll;">
			<ng-container *ngFor="let carpeta of carpetasmail">
				<mat-list-item class="carpetaprincipal" (click)="actualizaCarpeta(carpeta)" [ngClass]="{'selectmensaje': carpetaseleccionada && carpetaseleccionada.id == carpeta.id}">
					<div *ngIf="carpeta.childFolderCount >=1" (click)="togglecarpetaexpandida(carpeta)">
						<span *ngIf="!carpeta.expandida" class="material-icons">expand_more</span>
						<span *ngIf="carpeta.expandida"class="material-icons">expand_less</span>
					</div>
					<div class="displaycarpeta">
						{{carpeta.displayName}}
					</div>
					<span class="spacer"></span>
					<div class="datoscarpeta" *ngIf="carpeta.unreadItemCount >=1">
						<b>{{carpeta.unreadItemCount}}</b> <!-- / <span style="font-size:7pt;color:rgba(149, 153, 153, 0.945)">{{carpeta.totalItemCount}}</span>-->
					</div>
				</mat-list-item>
				<mat-list *ngIf="carpeta.expandida" style="margin-left:10px;">
					<div *ngFor="let subcarpeta of carpeta.childFolders">
						<mat-list-item class="carpetaprincipal" (click)="actualizaCarpeta(subcarpeta)" [ngClass]="{'selectmensaje': carpetaseleccionada && carpetaseleccionada.id == subcarpeta.id}">
							<div class="displaycarpeta">
								{{subcarpeta.displayName}}
							</div>
							<span class="spacer"></span>
							<div class="datoscarpeta" *ngIf="subcarpeta.unreadItemCount >=1">
								<b>{{subcarpeta.unreadItemCount}}</b>
							</div>
						</mat-list-item>
					</div>
				</mat-list>
			</ng-container>
		</mat-list>
 	</div>
 	<div class="contienemensajes">
		<div style="width:40%">
			<div class="barraopciones">
				<div style="display:inline-flex">
					<mat-form-field style="font-size:9pt;margin-left:15px;">
						<mat-label>Buscar</mat-label>
						<input matInput [(ngModel)]="textoabuscar" placeholder="Buscar..." (keyup.enter)="buscar(textoabuscar)">
						<button mat-button matSuffix mat-icon-button aria-label="Clear" (click)="textoabuscar = undefined; mensajesbuscados = undefined">
							<mat-icon>close</mat-icon>
						</button>
					</mat-form-field>
					<span style="font-size:2em;margin-right:20px;" matTooltip="Buscar" class="material-icons btnbuscar" (click)="buscar(textoabuscar)">manage_search</span>
					<span style="font-size:2em;margin-right:20px;" matTooltip="Buscar en eliminados" class="material-icons btnbuscar" (click)="buscareneliminados(textoabuscar)">youtube_searched_for</span>
					<span *ngIf="mensajesbuscados" style="font-size:9pt;margin-right:10px;">Encontrados {{mensajesbuscados}} mensajes</span>
					<button *ngIf="siguientesmensajes" mat-icon-button matTooltip="Mas mensajes" class="adjuntosover" (click)="masmensajes()" >
						<span class="material-icons">more</span>
					</button>
				</div>
				<span class="spacercorto"></span>
				<div *ngIf="mensajesSeleccionados && mensajesSeleccionados.length >=1" style="display:inline-flex">
					<button mat-icon-button matTooltip="Mover seleccionados a otra carpeta" [matMenuTriggerFor]="movertodos" class="adjuntosover" >
						<mat-icon style="font-size:13pt;">arrow_forward</mat-icon>
					</button>
					<button mat-icon-button matTooltip="Categorias/Colores seleccionados" [matMenuTriggerFor]="categorytodos" class="adjuntosover" >
						<mat-icon style="font-size:13pt;">palette</mat-icon>
					</button>
					<button mat-icon-button matTooltip="Eliminar seleccionados" class="adjuntosover" (click)="borrarmensajes(mensajesSeleccionados[0], 0)" >
						<mat-icon style="font-size:13pt;">clear</mat-icon>
					</button>
				</div>
			</div>
			<mat-menu #movertodos="matMenu">
				<mat-list>
					<ng-container *ngFor="let ci of carpetasinteraccion">
						<mat-list-item class="listainteraccion" (click)="movermensajes(mensajesSeleccionados[0], ci.id, 0)">{{ci.displayName}}</mat-list-item>
						<mat-list style="margin-left:20px;">
							<div *ngFor="let subci of ci.childFolders">
								<mat-list-item class="listainteraccion" (click)="movermensajes(mensajesSeleccionados[0], subci.id, 0)">{{subci.displayName}}</mat-list-item>
							</div>
						</mat-list>
					</ng-container>
				</mat-list>
			</mat-menu>
			<mat-menu #categorytodos="matMenu">
			  <div mat-menu-item style="padding:5px;text-align:center;align-content:center;" *ngFor="let category of listacategorias" (click)="setcategoriatodos(category, mensajesSeleccionados[0], 0)">
					  <div style="margin-left:40px;width:20px;height:20px;" [ngStyle]="{'background-color':category.mostrarcolor}">
					  </div>
			  </div>
			  <div mat-menu-item (click)="setbanderatodos(mensajesSeleccionados[0], 0)" style="text-align:center;align-content:center;">
				<mat-icon style="font-size:13pt;color:red;">outlined_flag</mat-icon>
			  </div>
			</mat-menu>
			<div class="overlay" *ngIf="!load">
				<div class="spinner ">
				   <img src="https://media.giphy.com/media/3oEjI6SIIHBdRxXI40/giphy.gif"/>
			   </div>
		   </div>
			<div class="contienelistamensajes" *ngIf="mensajesactuales && load">
				<ul *ngFor="let mensaje of mensajesactuales" class="listamensajes">
					<li style="width:100%;cursor:pointer;" (click)="seleccionarmensaje(mensaje)" (dblclick)="abrirmensaje(mensaje.webLink, mensaje)" (mouseover)="mensajehover = mensaje.id" (mouseleave)="mensajehover = undefined" [ngStyle]="{'background-color':getcategoriamensaje(mensaje)}" [ngClass]="{'selectmensaje': mensajeseleccionado && mensajeseleccionado.id == mensaje.id, 'fondoscuro': mensaje.id != mensajeseleccionado.id}">
						<div class="contenedormensaje">
							<div style="width:10px;margin-right:3px;margin-bottom:0px;" [class.relleno]="mensaje.isRead == false" (click)="leidotoggle(mensaje)">
							</div>
							<div style="margin-right:3px;">
								<mat-checkbox color="primary" (change)="checkmensaje($event.checked, mensaje)">
								</mat-checkbox>
								<mat-icon style="font-size:13pt;color:red;" *ngIf="mensaje.flag.flagStatus == 'flagged'">outlined_flag</mat-icon>
							</div>
							<div class="asuntoyremitente">
								<div style="width:100%;display:inline-flex">
									<div *ngIf="mensaje.hasAttachments == true">
										<button mat-icon-button matTooltip="Archivos adjuntos" [matMenuTriggerFor]="adj" class="adjuntosover">
											<mat-icon>attachment</mat-icon>
										</button>
									</div>
									<div style="font-size:8pt;color:rgb(75, 73, 73)" [class.noleido]="mensaje.isRead == false">
										<span [class.noleido]="mensaje.isRead == false">{{mensaje.from.emailAddress.name || mensaje.from.emailAddress.address}}</span><br>
										<span *ngIf="mensaje.from.emailAddress.name && mensaje.from.emailAddress.name != mensaje.from.emailAddress.address || !mensaje.from.emailAddress.name" [class.noleido]="mensaje.isRead == false">{{mensaje.from.emailAddress.address}}</span>
									</div>
									<span class="spacercorto"></span>
									<div style="max-width:55%;" [ngClass]="{'menuvisible': mensajehover == mensaje.id, 'menuoculto': mensajehover != mensaje.id}" (click)="seleccionarmensaje(mensaje)">
											<button mat-icon-button matTooltip="Categorias/Colores" [matMenuTriggerFor]="category" class="adjuntosover" >
												<mat-icon style="font-size:13pt;">palette</mat-icon>
											</button>	
											<button mat-icon-button matTooltip="Mover a otra carpeta" [matMenuTriggerFor]="mover" class="adjuntosover" >
												<mat-icon style="font-size:13pt;">arrow_forward</mat-icon>
											</button>
											<button mat-icon-button matTooltip="Enlazar con un Albaran" class="adjuntosover" (click)="mostrarcuadroalbaran()" >
												<mat-icon style="font-size:13pt;">add_link</mat-icon>
											</button> 
											<button mat-icon-button matTooltip="Responder/Reenviar" [matMenuTriggerFor]="reenvio" class="adjuntosover" >
												<mat-icon style="font-size:13pt;">reply_all</mat-icon>
											</button>
											<button mat-icon-button matTooltip="Eliminar" class="adjuntosover" (click)="borrarmensaje(mensaje)" >
												<mat-icon style="font-size:13pt;">clear</mat-icon>
											</button>
									</div>
									<mat-menu #reenvio="matMenu">
										<button mat-menu-item style="padding:5px;" (click)="crearRespuesta(mensajeseleccionado)"><mat-icon style="font-size:13pt;">reply</mat-icon> Responder
										</button>
										<button mat-menu-item style="padding:5px;" (click)="crearRespuestaTodos(mensajeseleccionado)"><mat-icon style="font-size:13pt;">reply_all</mat-icon> Responder a Todos
										</button>
										<button mat-menu-item	style="padding:5px;" (click)="crearReenviar(mensajeseleccionado)"><mat-icon style="font-size:13pt;">exit_to_app</mat-icon> ReEnviar
										</button>	
									</mat-menu>	
									<mat-menu #adj="matMenu">
										<button mat-menu-item style="padding:5px;" *ngFor="let adjunto of mensajeseleccionado.adjuntos" (click)="getadjuntos(mensajeseleccionado, adjunto)">
											<mat-icon style="font-size:13pt;">attach_file</mat-icon>{{adjunto.name}}
										</button>
									</mat-menu>
									<mat-menu #mover="matMenu">
										<mat-list>
											<ng-container *ngFor="let ci of carpetasinteraccion">
												<mat-list-item class="listainteraccion" (click)="movermensaje(mensaje, ci)">{{ci.displayName}}</mat-list-item>
												<mat-list style="margin-left:20px;">
													<div *ngFor="let subci of ci.childFolders">
														<mat-list-item class="listainteraccion" (click)="movermensaje(mensaje, subci)">{{subci.displayName}}</mat-list-item>
													</div>
												</mat-list>
											</ng-container>
										</mat-list>
									</mat-menu>
									<mat-menu #category="matMenu">
									<div mat-menu-item style="padding:5px;text-align:center;align-content:center;" *ngFor="let category of listacategorias" (click)="setcategoria(category)">
											<div style="margin-left:40px;width:20px;height:20px;" [ngStyle]="{'background-color':category.mostrarcolor}">
											</div>
									</div>
									<div mat-menu-item (click)="setbandera()" style="text-align:center;align-content:center;">
										<mat-icon style="font-size:13pt;color:red;">outlined_flag</mat-icon>
									</div>
									</mat-menu>
								</div>	
								<div style="width:100%;display:inline-flex">
									<div style="font-size:10pt;" [class.noleido]="mensaje.isRead == false">
										{{mensaje.subject}}
									</div>
									<span class="spacercorto"></span>
									<div style="font-size:8pt;">
										{{mensaje.receivedDateTime | date:'MMM d, y, HH:mm'}}
									</div>
								</div>
								<div style="width:100%">
									<div *ngIf="mensaje.bodyPreview" style="font-size:9pt;">
										{{mensaje.bodyPreview}}
									</div>
								</div>
							</div>
						</div>
					</li>
				</ul>
			</div>
		</div>
		<div class="contenidomensaje" *ngIf="mensajenuevo || (mensajeseleccionado && mensajesactuales && mensajesactuales.length >= 1)">
  <!--ver email email-->  
			<div class="vistamensaje" *ngIf="!mensajenuevo && mensajeseleccionado && mensajesactuales && mensajesactuales.length >= 1">
				<div class="cabeceramensaje">
					<div class="vistaasunto">{{mensajeseleccionado.subject}}</div>
					<div class="remitentedestinatario">
						<div class="remitente">
							<span>{{mensajeseleccionado.sender.emailAddress.name}}</span><br>
							<span>({{mensajeseleccionado.sender.emailAddress.address}})</span>	
						</div>
						<span class="spacercorto"></span>
						<div class="remitente">
							<span style="margin-right:5px;">Para: </span><span *ngIf="(mensajeseleccionado.toRecipients[0] || mensajeseleccionado.ccRecipients[0]) && !masdestinatarios" style="margin-left:10px;">{{mensajeseleccionado.toRecipients[0]? mensajeseleccionado.toRecipients[0].emailAddress.name : totaldestinatarios[0].emailAddress.name? totaldestinatarios[0].emailAddress.name : totaldestinatarios[0].emailAddress.address}}</span><mat-icon *ngIf="masdestinatarios" (click)="vermasdestinatarios()" style="margin-left:7px;font-size:14pt;cursor:pointer;">get_app</mat-icon>
							<div *ngIf="totaldestinatarios && totaldestinatarios.length >=2 && !masdestinatarios" style="display:inline-flex;margin-left:10px;padding:1px;border:0.1px solid black;cursor:pointer;" (click)="vermasdestinatarios()">
								<span>Y {{totaldestinatarios.length - 1}} destinatarios mas</span><mat-icon style="font-size:14pt;">publish</mat-icon>
							</div>
								<ul *ngIf="masdestinatarios" style="list-style: none;">
									<li *ngFor="let destinatario of totaldestinatarios">{{destinatario.emailAddress.name? destinatario.emailAddress.name : destinatario.emailAddress.address}}</li>
								</ul>
						</div>
					</div>
					<div class="datosmensaje">
						<span style="margin-right:10px;font-size: 9pt;">Recibido</span><span style="font-size: 9pt;">{{mensajeseleccionado.receivedDateTime | date:'MMM d, y, HH:mm'}}</span>
						<span class="spacercorto"></span>
						<div *ngIf="cuadroalbaran">
							<div style="display:inline-flex;">
								<button mat-raised-button *ngIf="alb" (click)="guardaremail(mensajeseleccionado, alb)">Linkar</button>
								<button mat-raised-button *ngIf="alb" (click)="guardaremailyver(mensajeseleccionado, alb)">Linkar y ver</button>
							</div>
							<mat-form-field style="font-size:9pt;margin-left:10px;margin-right:10px;">
								<mat-label>Albaran a Linkar</mat-label>
								<input matInput [(ngModel)]="alb" placeholder="Albaran...">
								<button mat-button *ngIf="alb" matSuffix mat-icon-button aria-label="Clear" (click)="alb=''">
									<mat-icon>close</mat-icon>
								</button>
							</mat-form-field>
						</div>		
						<div style="display:inline-flex;">
							<button mat-icon-button class="adjuntosover" matTooltip="Descargar Email" (click)="exportar(mensajeseleccionado)">
								<mat-icon style="font-size:13pt;">file_download</mat-icon>
							</button>
							<button mat-icon-button matTooltip="Categorias/Colores" [matMenuTriggerFor]="category3" class="adjuntosover" >
								<mat-icon style="font-size:13pt;">palette</mat-icon>
							</button>	
							<button mat-icon-button matTooltip="Mover a otra carpeta" [matMenuTriggerFor]="mover3" class="adjuntosover" >
								<mat-icon style="font-size:13pt;">arrow_forward</mat-icon>
							</button>
							<button mat-icon-button matTooltip="Enlazar con un Albaran" class="adjuntosover" (click)="mostrarcuadroalbaran()" >
								<mat-icon style="font-size:13pt;">add_link</mat-icon>
							</button> 
							<button mat-icon-button matTooltip="Responder/Reenviar" [matMenuTriggerFor]="reenvio3" class="adjuntosover" >
								<mat-icon style="font-size:13pt;">reply_all</mat-icon>
							</button>
							<button mat-icon-button matTooltip="Eliminar" class="adjuntosover" (click)="borrarmensaje(mensajeseleccionado)" >
								<mat-icon style="font-size:13pt;">clear</mat-icon>
							</button>
							<mat-menu #reenvio3="matMenu">
								<button mat-menu-item style="padding:5px;" (click)="crearRespuesta(mensajeseleccionado)"><mat-icon style="font-size:13pt;">reply</mat-icon> Responder
								</button>
								<button mat-menu-item style="padding:5px;" (click)="crearRespuestaTodos(mensajeseleccionado)"><mat-icon style="font-size:13pt;">reply_all</mat-icon> Responder a Todos
								</button>
								<button mat-menu-item style="padding:5px;" (click)="crearReenviar(mensajeseleccionado)"><mat-icon style="font-size:13pt;">exit_to_app</mat-icon> ReEnviar
								</button>	
							</mat-menu>	
							<mat-menu #mover3="matMenu">
								<mat-list>
									<ng-container *ngFor="let ci of carpetasinteraccion">
										<mat-list-item class="listainteraccion" (click)="movermensaje(mensajeseleccionado, ci)">{{ci.displayName}}</mat-list-item>
										<mat-list style="margin-left:20px;">
											<div *ngFor="let subci of ci.childFolders">
												<mat-list-item class="listainteraccion" (click)="movermensaje(mensajeseleccionado, subci)">{{subci.displayName}}</mat-list-item>
											</div>
										</mat-list>
									</ng-container>
								</mat-list>
							</mat-menu>
							<mat-menu #category3="matMenu">
							<div mat-menu-item style="padding:5px;text-align:center;align-content:center;" *ngFor="let category of listacategorias" (click)="setcategoria(category)">
									<div style="margin-left:40px;width:20px;height:20px;" [ngStyle]="{'background-color':category.mostrarcolor}">
									</div>
							</div>
							<div mat-menu-item (click)="setbandera()" style="text-align:center;align-content:center;">
								<mat-icon style="font-size:13pt;color:red;">outlined_flag</mat-icon>
							</div>
							</mat-menu>
						</div>
					</div>
				</div>
				<div *ngIf="mensajeseleccionado.hasAttachments == true" style="width:100%;display:inline-flex;padding:5px;border-top: 1.5px solid rgba(0, 0, 0, 0.288);">
					<div style="margin-right:10px;">Adjuntos</div>
					<mat-chip-list style="width:100%;" *ngIf="mensajeseleccionado.adjuntos"> 
						<mat-chip *ngFor="let anexo of mensajeseleccionado.adjuntos" (click)="getadjuntos(mensajeseleccionado, anexo)">{{anexo.name}}</mat-chip>
					</mat-chip-list>
				</div>
				<hr>	
				<div #cuerpomensaje [innerHtml]="mensajeseleccionado.body.content" style="overflow-y: scroll;max-height: 600px;padding:10px;">
				</div>
			</div>	
  <!--escribir respuesta email-->  
			<div class="msgnew" *ngIf="mensajenuevo">
				<div class="botonesnuevo">
					<button mat-raised-button style="margin-right:20px;cursor:pointer;" matTooltip="Enviar mensaje" (click)="enviarmensaje()" [disabled]="mensajenuevo.message.toRecipients.length <=0">
						Enviar
					</button>
					<div style="margin-left: 10px;">
						<button mat-raised-button>
							<label for="file-upload" style="cursor:pointer;">
								<mat-icon>folder_open</mat-icon>Adjuntar..
							</label>
						</button>
						<input id="file-upload" multiple="true" (change)="fileChangeEvent($event)" type="file" style='display: none;'/>
					</div>
					<span class="spacer"></span>
					<button mar-raised-button class="material-icons fondoscuro" style="width:35px;font-size:14pt;" matTooltip="Cerrar/Cancelar" (click)="cancelarRespuesta()">
						cancel
					</button>
				</div>
				<div class="cabeceranuevo">
					<mat-form-field style="width:100%;text-align:center;font-size:12pt;font-weight:700;margin-bottom:0">
						<mat-label>Asunto</mat-label>
						  <input matInput [(ngModel)]="mensajenuevo.message.subject" placeholder="Asunto">
					</mat-form-field>
					<mat-form-field class="agregardestinatarios">
						<mat-label>Destinatarios</mat-label>
						<mat-chip-list #chipList aria-label="destinatarios selection">
							<mat-chip
								*ngFor="let destinatario of destinatarionuevo"
								[removable] = "true"
								(removed)="remove(destinatario)">
								{{destinatario}}
								<mat-icon matChipRemove>cancel</mat-icon>
							</mat-chip>
							<input
							placeholder="Nuevo Destinatario..."
							#destinatariosinput
							[formControl]="myControl"
							[matAutocomplete]="auto"
							[matChipInputFor]="chipList"
							[matChipInputSeparatorKeyCodes]="separatorKeysCodes"
							(matChipInputTokenEnd)="add($event)">
						</mat-chip-list>
						<mat-autocomplete #auto="matAutocomplete" autoActiveFirstOption (optionSelected)="selected($event)">
							<mat-option *ngFor="let option of filteredOptions | async" [value]="option">
								<div style="width:100%;padding:1px;font-size:10pt;display:inline-flex">
									<span>{{option}}</span>
									<span class="spacer"></span>
									<button mat-button mat-icon-button aria-label="Clear" #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="eliminardireccion" (mouseover)="direccionaleiminar = option; menuTrigger.openMenu()">
										<mat-icon>close</mat-icon>
									</button>
								</div>
							</mat-option>
						</mat-autocomplete>
					</mat-form-field>
					<mat-menu #eliminardireccion="matMenu">
						<button mat-menu-item style="padding:5px;" (click)="borraopcion(direccionaleiminar)">
								Eliminar: {{direccionaleiminar}}
						</button>
					</mat-menu>
				</div>
				<div *ngIf="mensajenuevo.message.attachments && mensajenuevo.message.attachments.length >=1" style="width:100%;display:inline-flex;padding-bottom:5px;border-bottom: 1px solid rgba(0, 0, 0, 0.158);">
					<div style="margin-right:20px;">
						Adjuntos
					</div>
					<mat-chip-list #chipadjuntos style="width:100%;"> 
						<mat-chip *ngFor="let archivo of mensajenuevo.message.attachments; let indice = index" [removable]='true' (removed)="eliminarAdjunto(indice)">{{archivo.name}}<mat-icon matChipRemove>cancel</mat-icon></mat-chip>
					</mat-chip-list>
				</div>
				<div class="contieneeditor">
					<ckeditor [editor]="Editor" [config]="{ 
						toolbar: {
							items: [
								'heading',
								'|',
								'undo',
								'redo',
								'|',
								'bold',
								'italic',
								'fontColor',
								'fontSize',
								'fontFamily',
								'|',
								'alignment',
								'bulletedList',
								'numberedList',
								'highlight',
								'|',
								'imageUpload',
								'insertTable',
								'|',
								'horizontalLine',
							]
						},
						language: 'es',
						image: {
							styles: [
								'full', 'side', 'alignLeft', 'alignCenter', 'alignRight'
							],
							toolbar: [
								'imageStyle:full',
								'imageStyle:side',
								'|',
								'imageStyle:alignLeft',
								'imageStyle:alignCenter',
								'imageStyle:alignRight',
								'|',
								'ImageResize',
								'imageTextAlternative',
							]
						},
						table: {
							contentToolbar: [
								'tableColumn',
								'tableRow',
								'mergeTableCells',
								'tableCellProperties',
								'tableProperties'
							]
						}
					 }" [(ngModel)]="mensajenuevo.message.body.content">
					</ckeditor>
				</div>
			</div>	
		<!-- fin respuesta email -->
	</div>
	
</div>


