import {Component, ViewChild, OnInit, AfterViewInit, Input, OnChanges, EventEmitter, Output } from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { AppService } from '../../servicios/app.service';
import { ClienteService } from '../../servicios/cliente.service';
import { Incidencia } from '../../models/incidencia';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition, MatSnackBarConfig } from '@angular/material/snack-bar';
import { saveAs } from "file-saver";
import { MatDialog } from '@angular/material/dialog';
import { AlbEditableComponent } from '../albeditable/albeditable.component';
import { I } from '@angular/cdk/keycodes';

@Component({
  selector: 'envioseditablescliente',
  templateUrl: './envioseditablescliente.component.html',
  styleUrls: ['./envioseditablescliente.component.css'],
  providers: [ClienteService, AppService]
})
export class EnviosEditablesclienteComponent implements OnInit, AfterViewInit {
  public dataSource = new MatTableDataSource<any>()
  public selection = new SelectionModel<any>(true, []);
  public filtro;
  public identity;
  public load = true;
  public loaddatos = true;
  public category;
  public totalcolumns;
  public seleccion;
  public filtros;
  public galletag3 = false;
  @Output() procesando = new EventEmitter();
  @Output() tipo = new EventEmitter();
  @Output() actualizargrupo = new EventEmitter();
  @ViewChild(MatSort, {static: false}) set content(sort: MatSort){this.dataSource.sort = sort;}
 
  constructor(
    private _clienteService: ClienteService,
    private _appservice: AppService,
    private _snackBar: MatSnackBar,
    public ventanaAlbaran: MatDialog
  ){
    this.filtros = {
      filtroprincipal: ''
      };
    this.identity = this._appservice.getIdentity();
    this.totalcolumns = ['select','ALBARAN','NOMBRE', 'DIRECCION', 'POBLACION', 'CP', 'TELEFONO', 'CONTACTO', 'REFERENCIA', 'OBSERVACIONES', 'BULTOS'] //, 'Eliminar'];
  }

  ngOnInit(): void {
    this.getgalleta();
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.content;
  }

  getgalleta(){
    const cookie = this._appservice.getgalletag3();
    if(!cookie){
      this._appservice.refreshg3cliente().subscribe(
        res=>{
          sessionStorage.setItem('galletag3', res['cookie']);
          this.galletag3 = true;
        }
      )
    }else{
      this.galletag3 = true;
    }
  }

  aviso(texto, subtexto) {
    this._snackBar.open(texto,subtexto,{
      duration: 4000,
      horizontalPosition: "center",
      verticalPosition: "top",
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  isAllSelected() {
    if(this.dataSource.filteredData){
      const numSelected = this.selection.selected.length;
      const numRows = this.dataSource.filteredData.length;
      return numSelected === numRows;
    }else{
      const numSelected = this.selection.selected.length;
      const numRows = this.dataSource.data.length;
      return numSelected === numRows;
    }  
  }

 // Selects all rows if they are not all selected; otherwise clear selection.
  masterToggle() {
    if(this.dataSource.filteredData){
        this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.filteredData.forEach(row => this.selection.select(row));
    }else{  
      this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.data.forEach(row => this.selection.select(row));
    }    
  }

  // The label for the checkbox on the passed row 
  checkboxLabel(row?: Incidencia): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  seleccionar(row){
    this.seleccion = row;
  }
 

  imprimir(){
    this.load = false;
    this.procesando.emit(`Generando Etiquetas...
    Espere por favor`);
    const cuerpo = this.selection.selected.map((sel)=>{
      return sel.idEnvio;
    });
    const galleta = sessionStorage.getItem('galletag3');
    const envios = this.selection.selected.map((env)=>{
      return env.albaran;
    });
    this._clienteService.marcamasivo(envios, 'impreso').subscribe(
      res=>{
        console.log(res);
      }
    );
		this._clienteService.getetiquetas(cuerpo, galleta).subscribe(
      res=>{
        this.load = true;
        this.procesando.emit('');
        saveAs(res, 'etiquetas.pdf');
      },
      error=>{
        this.procesando.emit('');
        this.load = true;
      }
    )
  }

  abrealbaran(incidencia){
    const bultos = parseInt(incidencia.bultos);
    var albaranopen = this.ventanaAlbaran.open(AlbEditableComponent, {
      width: '85%',
      height: '85%',
      data: incidencia
    });
    albaranopen.afterClosed().subscribe(result =>{
      if(result){
        console.log('resultado');
        this.aviso(result.msg, 'ok');
        if(parseInt(result.data.bultos) != bultos){
          const envios = [result.data];
          this._clienteService.marcamasivo(envios, 'bultos').subscribe(
            res=>{
              this.aviso(res.msg, 'ok');
            });
        }
        if(result.estado == 'cancelado'){
          console.log('resultado cancelado');
          let dataenvios = [...this.dataSource.data]
          this.dataSource.data = [];
          dataenvios = dataenvios.filter(env=>env.albaran != result.data.albaran);         
          this.dataSource.data = dataenvios;
        }else{
          console.log('resultado otros...');
          const indice = this.dataSource.data.indexOf(env=>env.albaran == result.data.albaran);
          this.dataSource.data[indice] = result.data;
        }
        this.actualizargrupo.emit(result.data);
      }
            
    });
 }
 

 selectipo(tipo){
   this.tipo.emit(tipo);
 }

}