import { OnInit, Component, ViewChild, AfterViewInit} from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { ClienteService } from '../../servicios/cliente.service';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import * as moment from 'moment';
import { Factura } from '../../models/factura';
import { MatTableDataSource } from '@angular/material/table';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition, MatSnackBarConfig } from '@angular/material/snack-bar'; 
import { AppService } from '../../servicios/app.service';

@Component({
  selector: 'app-cobrofacturas',
  templateUrl: './cobrofacturas.component.html',
  styleUrls: ['./cobrofacturas.component.css'],
  providers: [ClienteService, AppService]
})
export class CobrofacturasComponent implements OnInit, AfterViewInit{
	  public identity;
    public clientes;
	  public facturas;
	  public seleccion;
	  public queryactual;
	  public category;
	  public fechahoy;
	  public columns;
	  public dataSource;
	  public selection;
	  public totalcolumns;
	  public campos;
	  public camposeleccionados;
	  public filtros;
	  public facturasSeleccionadas;
	  public gG3;
	  public abonados;
    public abonadoseleccionado;
	  public formasdepago;
    public totalpendiente;
    public importeseleccion;
    public clienteseleccionado;
    public facturastotales;
    public facturasabonado;
    public pendientecliente;
    public emailclienteseleccionado;
    public contactoclienteseleccionado;
    public facturasseleccionadasclienteseleccionado;
    public importefscs;
    public verclientes;
    public clientesactivos;
    public botoncorreo;
    public desmarcarfacturas;
	  private paginator: MatPaginator;
    private sort: MatSort;
    @ViewChild(MatSort, {static: false}) set content(sort: MatSort){this.sort = sort;}
    @ViewChild(MatPaginator, {static: false}) set matPaginator(mp: MatPaginator) {this.paginator = mp;}
    
  constructor(
    private _clienteService: ClienteService,
    private dateAdapter: DateAdapter<Date>,
    private _snackBar: MatSnackBar,
    private _appservice: AppService
  ) {
    this.identity = this._appservice.getIdentity();  
    this.verclientes = false;
    this.desmarcarfacturas = false;
  	this.totalcolumns = ['select','NumeroFactura','Abonado','Nif','RazonSocial','Departamento','GrupoImpositivoCliente','ImporteBaseImponible','ImporteExento','ImporteDescuentoCliente','ImporteRecargoCliente','ImporteImpuestos','ImporteFactura','Anulada','ImporteVentas','Abono','IdTipoFactura','NumeroFacturaRectificada','Franquicia','ImporteDescuento','MotivoRectificativa','EsPortugal','EsProforma','Impreso','Cobrada','Devuelta','FechaFactura','FechaVencimiento'];
    var totalcolfacturas = this.identity.options.ordencamposfacturas.length-1;
    for(let i = 0;i< totalcolfacturas;i++){
      let col = this.identity.options.ordencamposfacturas[i];
      if(!this.totalcolumns.includes(col)) this.identity.options.ordencamposfacturas.splice(this.identity.options.ordencamposfacturas.indexOf(col), 1);
      if(i >= totalcolfacturas){
        sessionStorage.setItem('identity', JSON.stringify(this.identity));
        this._clienteService.saveCliente(this.identity);
      }  
    }
    this.filtros = {
      filtroprincipal: ''
      };
     this.formasdepago = ['EFEC','PAG','CHTA','TRANS','cont'];  
     this.selection = new SelectionModel<Factura>(true, []);
   	 this.dateAdapter.setLocale('es');
     this.dateAdapter.getFirstDayOfWeek = () => { return 1; };
     this.dataSource = new MatTableDataSource<Factura>();
     this.fechahoy = moment().set({hour:0,minute:0,second:0,millisecond:0});
     var querycecos = {'Facturacion.FormaPago':{$in:this.formasdepago}};
     this._clienteService.getcecos(querycecos).subscribe(
     	response=>{
     		if(response.cecos){
          this.clientes = response.cecos;
     			this.abonados = this.clientes.map((abonado)=>{
             return abonado.Abonado;
          });
          this.getFacturas();
          var hash = {};
          this.clientesactivos = this.clientes.filter(cliente=>{
            let abinicio = cliente.Abonado.slice(0,1);
            if(abinicio == '1' || abinicio == '2'){
              return false;
            }else if(cliente.CodigoEstado == 'A'){
              return true;
            }else{ 
              return false;
            }
          }).filter((ab)=>{
            if(hash[ab.Abonado]){
              return false;
            }else{
              hash[ab.Abonado] = true;
              return true;
            }
          }).sort(function(a, b){
            return a['Abonado'] > b['Abonado'];
          });
     		}
     	}
     );
  }

  ngOnInit(): void {
    this.category = 'facturaspendientes';
  }

  ngAfterViewInit(){
    this.dataSource.paginator = this.paginator
    this.dataSource.sort = this.sort;
  }

  aviso(texto, subtexto) {
    this._snackBar.open(texto,subtexto,{
      duration: 4000,
      horizontalPosition: "center",
      verticalPosition: "top",
    });
  }

  applyFilter(event: Event){
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  isAllSelected() {
    if(this.dataSource.filteredData){
      const numSelected = this.selection.selected.length;
      const numRows = this.dataSource.filteredData.length;
      return numSelected === numRows;
    }else{
      const numSelected = this.selection.selected.length;
      const numRows = this.dataSource.data.length;
      return numSelected === numRows;
    }  
  }

  vistatoggle(){
    if(!this.verclientes){
      this.verclientes = true;
    }else{
      this.verclientes = false;
    }
    this.facturasseleccionadasclienteseleccionado = new Array();
    this.selection.clear();
  }
  
  masterToggle() {
    if(this.dataSource.filteredData){
        this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.filteredData.forEach(row => this.selection.select(row));
    }else{  
      this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.data.forEach(row => this.selection.select(row));
    }
    this.mapearseleccion();    
  }


  checkboxLabel(row?: Factura): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    this.mapearseleccion();
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.identity.options['ordencamposfacturas'], event.previousIndex, event.currentIndex);
    this.actualizacliente();
  }

  actualizacliente(){
    sessionStorage.setItem('identity', JSON.stringify(this.identity));
    this._clienteService.saveCliente(this.identity);
  }

  actualizaabonado(){
    this._clienteService.actualizaabonado(this.clienteseleccionado).subscribe(
      response=>{
          if(response.abonadoupdated){
            this.aviso('abonado actualizado', 'ok');
          }
        },
      error=>{
        console.log(<any>error);
      }  
    )
  }

  onselectcolumnas(value){
    this.identity.options.ordencamposfacturas = value;
    this.actualizacliente();
  }

  categoriaseleccionada(categoria){
    this.category = categoria;
    this.selection.clear();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }  
  }

   seleccionar(row){
    this.seleccion = row;
    this.clienteseleccionado = this.clientes.find(cliente=>cliente.Abonado == this.seleccion.Abonado);
    this.clienteseleccionado['emailfacturacion'] = this.clienteseleccionado.emailfacturacion? this.clienteseleccionado.emailfacturacion : this.clienteseleccionado.Email;
    this.clienteseleccionado['contactofacturacion'] = this.clienteseleccionado.contactofacturacion? this.clienteseleccionado.contactofacturacion : this.clienteseleccionado.datosAbonado.Contacto;
    this.emailclienteseleccionado = this.clienteseleccionado['emailfacturacion'];
    this.contactoclienteseleccionado = this.clienteseleccionado['contactofacturacion'];
    this.facturascliente(this.clienteseleccionado.Abonado);
  }

  facturascstoggle(){
    if(this.facturasseleccionadasclienteseleccionado.length == this.facturasabonado.length){
      this.facturasseleccionadasclienteseleccionado = new Array();
    }else{
      this.facturasseleccionadasclienteseleccionado = this.facturasabonado.map(fact=>{return fact});
    }
    this.mapearseleccioncs();
   }

  seleccionafacturaclienteseleccionado(event, factura){
    if(event.checked){
      this.facturasseleccionadasclienteseleccionado.push(factura);
    }else{
      this.facturasseleccionadasclienteseleccionado.splice(this.facturasseleccionadasclienteseleccionado.indexOf(factura), 1);
    }
    this.mapearseleccioncs();
  }

  eliminafiltro(filtro){
    this.getFacturas();
  }

  onselectfiltro(filtro, valor){
     this.queryactual[filtro] = valor;
  }

  buscarFactura(){
    this.selection.clear();
    var query = {NumeroFactura:this.filtros['NumFactura'].trim()};
    this._clienteService.getfacturasg3(query).subscribe(
      response=>{
        console.log(response);
        if(response.facturas && response.facturas.length >=1){
          this.facturas = response.facturas;
          this.mapearfacturas();
        }else{
          this.aviso('no se han encontrado facturas con esa numeracion','error');
        }
        
        },
      error=>{
        console.log(<any>error);  
      } 
    )
  }

  buscarabonado(abonado){
    this.selection.clear();
    var ab = abonado.trim();
    for(var i = ab.length; i < 6;i++){
      ab = '0' + ab;
    }  
    var query = {Abonado:ab, $or:[{Cobrada:{$exists:false}},{Cobrada:false}]};
    this._clienteService.getfacturasg3(query).subscribe(
      response=>{
          if(response.facturas && response.facturas.length >=1){
            this.facturas = response.facturas;
            this.mapearfacturas();
          }else{
            this.aviso('no se han encontrado facturas con ese numero de abonado','error');
          }
        },
      error=>{
        console.log(<any>error);
      }  
    );
    let abselect = this.clientes.filter(abd=>{
      if(abd.Abonado == ab) return true;
    });
    this.seleccion = abselect[0];
    this.clienteseleccionado = abselect[0];
    this.clienteseleccionado['emailfacturacion'] = this.clienteseleccionado.emailfacturacion? this.clienteseleccionado.emailfacturacion : this.clienteseleccionado.Email;
    this.clienteseleccionado['contactofacturacion'] = this.clienteseleccionado.contactofacturacion? this.clienteseleccionado.contactofacturacion : this.clienteseleccionado.datosAbonado.Contacto;
    this.emailclienteseleccionado = this.clienteseleccionado['emailfacturacion'];
    this.contactoclienteseleccionado = this.clienteseleccionado['contactofacturacion'];
    this.facturascliente(this.clienteseleccionado.Abonado);
  }

  getFacturas(){
    this.desmarcarfacturas = false;
    var query = {Abonado:{$in:this.abonados},$or:[{Cobrada:{$exists:false}},{Cobrada:false}]}
    this.queryactual = query;
  	this._clienteService.getfacturasg3(query).subscribe(
  		response=>{
        this.facturastotales = response.facturas;
  			this.facturas = this.facturastotales.filter(factura=>{
          let abinicio = factura.Abonado.slice(0,1);
          if(abinicio == '1' || abinicio == '2'){
            return false;
          }else{
            return true;
          }  
        });
  			this.mapearfacturas();
        if(this.clienteseleccionado) this.facturascliente(this.clienteseleccionado.Abonado);
  			},
  		error=>{
  			console.log(<any>error);	
  		}	
  	)
  }

  mapearfacturas(){
    this.totalpendiente = 0;
  	this.dataSource.data = [];
  	this.dataSource.data = this.facturas.map((factura)=>{
      if(factura.Cobrada && factura.Cobrada == true){
        factura.Cobrada = 'Cobrada';
      }else{
        this.totalpendiente = this.totalpendiente + factura.ImporteFactura;
      } 
      return factura;
    });
  }

  facturascliente(cliente){
    this.pendientecliente = 0;
    this.desmarcarfacturas = false;
    this.facturasseleccionadasclienteseleccionado = new Array();
    this.facturasabonado = this.facturastotales.filter(factura=>{
      if(factura.Abonado == cliente && !factura.Cobrada){
        this.pendientecliente = this.pendientecliente + factura.ImporteFactura;
        return true;
      }else{
        return false;
      } 
    }).reverse();
    var contador = 0;
    var totales = this.facturasabonado.length - 1;
    var lineastabla = "";
    this.facturasabonado.forEach((factura)=>{
      let linea = moment(factura.FechaParticion).format('DD/MM/YYYY') + "  " + factura.NumeroFactura + "   " + factura.ImporteFactura + " € \n";
      lineastabla = lineastabla + linea;
      if(contador == totales){
        var tablafacturas = encodeURIComponent(lineastabla);
        this.botoncorreo = "mailto:" + this.clienteseleccionado['emailfacturacion'] + "?subject=Facturas%20Pendientes%20Mensasur Getafe(MRW)&body=" + tablafacturas;
      }
      contador++  
    });
  }  

  mapearseleccioncs(){
    this.importefscs = 0;
    if(this.facturasseleccionadasclienteseleccionado.length >= 1){
      this.facturasseleccionadasclienteseleccionado.map((factura)=>{
      this.importefscs = this.importefscs + factura.ImporteFactura;
        return factura;
      });
    }   
  }

  mapearseleccion(){
    this.importeseleccion = 0;
    if(this.selection.selected.length >= 1){
      this.selection.selected.map((factura)=>{
      this.importeseleccion = this.importeseleccion + factura.ImporteFactura;
        return factura;
      });
    }   
  }

  cobrar(){
    var cantidad = 0;
    this.selection.selected.forEach(factura =>{
      factura.Cobrada = true;
      this._clienteService.guardafacturag3(factura).subscribe(
        response =>{
            if(response){
              cantidad++
              if(cantidad == this.selection.selected.length){
                this.selection.clear();
                this.aviso('facturas cobradas','ok');
                this.getFacturas();
              }  
            }
          },
         error =>{
          console.log(<any>error);
         } 
      );
    });
  }

  cobrarfcs(){
    var cantidad = 0;
    this.facturasseleccionadasclienteseleccionado.forEach(factura =>{
      factura.Cobrada = true;
      this._clienteService.guardafacturag3(factura).subscribe(
        response =>{
            if(response){
              cantidad++
              if(cantidad == this.facturasseleccionadasclienteseleccionado.length){
                this.facturasseleccionadasclienteseleccionado = new Array();
                this.aviso('facturas cobradas','ok');
                this.getFacturas();
              }  
            }
          },
         error =>{
          console.log(<any>error);
         } 
      );
    });
  }

  facturascobradascliente(){
    this.desmarcarfacturas = true;
    this.pendientecliente = 0;
    this.facturasseleccionadasclienteseleccionado = new Array();
    var query = {Abonado:this.clienteseleccionado.Abonado, Cobrada:true};
  	this._clienteService.getfacturasg3(query).subscribe(
  		response=>{
        this.facturasabonado = response.facturas.reverse();
      }
    );      
  }  

  desmarcarfcs(){
    var cantidad = 0;
    this.facturasseleccionadasclienteseleccionado.forEach(factura =>{
      factura.Cobrada = false;
      this._clienteService.guardafacturag3(factura).subscribe(
        response =>{
            if(response){
              cantidad++
              if(cantidad == this.facturasseleccionadasclienteseleccionado.length){
                this.facturasseleccionadasclienteseleccionado = new Array();
                this.aviso('facturas desmarcadas','ok');
                this.getFacturas();
              }  
            }
          },
         error =>{
          console.log(<any>error);
         } 
      );
    });
  }


  siguientecliente(){
    if(this.clientesactivos.indexOf(this.clienteseleccionado) >= (this.clientesactivos.length - 1)){
      this.clienteseleccionado = this.clientesactivos[0];
    }else{
      this.clienteseleccionado = this.clientesactivos[this.clientesactivos.indexOf(this.clienteseleccionado)+1];
    }
    this.seleccionar(this.clienteseleccionado);  
  }
  
  clienteanterior(){
    if(this.clientesactivos.indexOf(this.clienteseleccionado) <= 0){
      this.clienteseleccionado = this.clientesactivos[this.clientesactivos.length -1];
    }else{
      this.clienteseleccionado = this.clientesactivos[this.clientesactivos.indexOf(this.clienteseleccionado)-1];
    }
    this.seleccionar(this.clienteseleccionado);  
  }  


  parsefecha(fecha){
    if(!fecha){
      return ''
    }else{
      return moment(fecha).format('DD/MM/YYYY');
    }
  }  

}
