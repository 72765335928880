import { Component, OnInit } from '@angular/core';
import { VehiculoService } from '../../servicios/vehiculos.service';
import { Tarjeta } from '../../models/tarjeta';
import { TarjetaComponent } from '../tarjeta/tarjeta.component';
import { ClienteService } from '../../servicios/cliente.service';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition, MatSnackBarConfig } from '@angular/material/snack-bar';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';

@Component({
  selector: 'tarjetas',
  templateUrl: './tarjetas.component.html',
  styleUrls: ['./tarjetas.component.css'],
  providers: [VehiculoService, ClienteService]
})
export class TarjetasComponent implements OnInit {
  public tarjetas;
  public mensajeros;
  public gastos;
  public displayedColumns;
  constructor(
    private _snackBar: MatSnackBar,
    private _vehiculoService: VehiculoService,
    public ventanaTarjeta: MatDialog,
    private _clienteService: ClienteService
  ) { 
    this.displayedColumns = ['Tarjeta', 'Mensajero', 'Total'];
  }

  ngOnInit(): void {
    this.gastos = {};
    this.gastosmesactual();
    this._clienteService.getmensajeros().subscribe(
      response=>{
        this.mensajeros = response.mensajeros.sort(function(a, b){
          return a['numero'] > b['numero'];
        });
      },
      error=>{
        console.log(<any>error);
      }
    )    
  }

  aviso(texto, subtexto) {
    this._snackBar.open(texto,subtexto,{
      duration: 5000,
      horizontalPosition: "center",
      verticalPosition: "top",
    });
  }

  nuevatarjeta(){
    this.abretarjeta(new Tarjeta('','','','','','',''));
  }

  abretarjeta(tarjeta){
    var tarjetaabierta = this.ventanaTarjeta.open(TarjetaComponent, {
      width: '85%',
      height: '85%',
      data: tarjeta
    });
    
    tarjetaabierta.afterClosed().subscribe(result =>{
      if(result == true) this.gettarjetas();
    });
  }

  gettarjetas(){
    this._vehiculoService.gettarjetas({}).subscribe(
      response=>{
        this.tarjetas = response.tarjetas;
       },
      error=>{
        console.log(<any>error);
      }
    )
  }

  guardartarjeta(tarjeta){
    this._vehiculoService.guardartarjeta(tarjeta).subscribe(
        response=>{
            if(response.status == 200){
              this.aviso(response.message, 'Ok');
              this.gettarjetas();
            }
          },
        error=>{
          this.aviso(error.error.message, 'error');
          console.log(<any>error);
        }  
      )
  }

  gastosmesactual(){
    let fechaactual = moment().set({hour:21,minute:0,second:0,millisecond:0}).toISOString(true);
    let dia = moment(fechaactual).add(-1, 'days').date();
    let fechaanterior = moment(fechaactual).add(-dia, 'days').set({hour:4,minute:0,second:0,millisecond:0}).toISOString(true);
    let query = {$and:[{fecha:{$gte:fechaanterior}},{fecha:{$lte:fechaactual}}]};
    this._vehiculoService.getgastos(query).subscribe(
      response=>{
        if(response.gastos){
          response.gastos.forEach((gasto)=>{
            if(gasto.tarjeta){
              if(this.gastos[gasto.tarjeta]){
                this.gastos[gasto.tarjeta] =  this.gastos[gasto.tarjeta] + gasto.importe; 
              }else{
                this.gastos[gasto.tarjeta] = gasto.importe;
              }
            }
          });
          this.gettarjetas();
        }  
      },
      error=>{
        console.log(<any>error);
      }
    );
  }
  

}
