<div>
	<h1 class="h1-strong">{{title}}</h1>
	
	<form #registroForm="ngForm" (ngSubmit)="onSubmit(registroForm)">
		<div class="formulario">
			<div class="loguear">
				<p>
					<label>Numero de Usuario</label>
					<input type="text" name="numero" #numero="ngModel" [(ngModel)]="cliente.numero" class="form-control" required />
				</p>
				<p>
					<label>Contraseña</label>
					<input type="password" name="password" #password="ngModel" [(ngModel)]="cliente.password" class="form-control" required />
				</p>
				<p>
					<label>Repetir Contraseña</label>
					<input type="password" name="password2" #password2="ngModel" [(ngModel)]="pass" class="form-control" required />
				</p>
				<p>
					<label>Rol</label>
					<select name="role" [(ngModel)]="cliente.role" class="form-control" required>
						<option [value]="item" *ngFor="let item of listarol">{{item}}</option>
					</select>
				</p>
				<p>
					<label>Nif</label>
					<input type="text" name="nif" #poblacion="ngModel" [(ngModel)]="cliente.nif" class="form-control"/>
				</p>

					<div class="alert alert-danger aviso" *ngIf="status == 'error'">
						faltan datos a rellenar o los datos no son correctos
					</div>

					<div class="alert alert-danger aviso" *ngIf="status == 'existe'">
						el usuario ya existe
					</div>
					<div class="alert alert-danger aviso" *ngIf="status == 'passerror'">
						las contraseñas no coinciden
					</div>
					<div class="alert alert-success aviso" *ngIf="status == 'success'">
						te has registrado correctamente, inicia sesion <a [routerLink]="['../login']">Aqui</a>
					</div>
					<div class="alert alert-success aviso" *ngIf="status == 'admin'">
						te has registrado correctamente, inicia sesion <a [routerLink]="['../../../login']">Aqui</a>
					</div>			
			</div>
			<div class="datos">
				<p>
					<label>Nombre del Usuario</label>
					<input type="text" name="Nombre" #nombre="ngModel" [(ngModel)]="cliente.nombre" class="form-control" required />
				</p>
				<p>
					<label>Direccion</label>
					<input type="text" name="direccion" #direccion="ngModel" [(ngModel)]="cliente.direccion" class="form-control" required />
				</p>
				<p>
					<label>Codigo Postal</label>
					<input type="text" name="cp" #cp="ngModel" [(ngModel)]="cliente.cp" class="form-control" required />
				</p>
				<p>
					<label>Poblacion</label>
					<input type="text" name="poblacion" #poblacion="ngModel" [(ngModel)]="cliente.poblacion" class="form-control" required />
				</p>
				<p>
					<label>Telefono</label>
					<input type="text" name="telefono" #telefono="ngModel" [(ngModel)]="cliente.telefono" class="form-control" required />
				</p>
				<p>
					<label>Contacto</label>
					<input type="text" name="contacto" #contacto="ngModel" [(ngModel)]="cliente.contacto" class="form-control" required />
				</p>
				<p>
					<label>Email</label>
					<input type="text" name="emailsIncidencias" #emailsIncidencias="ngModel" [(ngModel)]="cliente.emailsIncidencias" class="form-control" required pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"/>
				</p>
			</div>	
			<div class="clearfix"></div>

		<div class="centrado">
		<input type="submit" value="Registrar Usuario" class="btn btn-success" [disabled]="!registroForm.form.valid" />
		</div>
	</div>
	</form>
	
</div>	
