
<h4 class="centrado">{{title}}</h4>
<div class="cajafecha">
	<div class="ano">
		<label>Año de facturas a subir</label>
		<input type="text" name="anofact" #anofact="ngModel" [(ngModel)]="ano" class="form-control" required />
	</div>
	<div class="mes">
		<label>Mes de facturas a subir</label>
		<input type="text" name="mesfact" #mesfact="ngModel" [(ngModel)]="mes" class="form-control" required />
	</div>
</div>



<div class="contienesubir" *ngIf="status != 'subido'">	
	<p>
		<input type="file" multiple="true" (change)="fileChangeEvent($event)"/>
	</p>

	<p>
		<input class="btn btn-success" value=" Subir Facturas " type="button" (click)="subir()"/>
	</p>

</div>

<div class="alert alert-success aviso" *ngIf="status == 'success'">
	Facturas subidas correctamente
</div>
<div class="alert alert-success aviso" *ngIf="status == 'successborrado'">
	Facturas borradas correctamente
</div>

<div class="clearfix"></div>

<div class="cajaeliminar">
	<h4 class="centrado">{{subtitle}}</h4>
	<div class="ano">
		<label>Año de facturas a eliminar</label>
		<input type="text" name="anofacteliminar" #anofacteliminar="ngModel" [(ngModel)]="anoeliminar" class="form-control" required />
	</div>
	<div class="ano">
		<label>Mes de facturas a eliminar</label>
		<input type="text" name="mesfacteliminar" #mesfacteliminar="ngModel" [(ngModel)]="meseliminar" class="form-control"/>
	</div>
	<div *ngIf="anoeliminar && !meseliminar" class="borracliente">
		<input class="btn btn-danger" type ="button" value="borrar facturas" href="#victorModal" data-toggle="modal"/>
	</div>
	<div *ngIf="anoeliminar && meseliminar" class="borracliente">
		<input class="btn btn-danger" type ="button" value="borrar facturas mes" href="#victorModal2" data-toggle="modal"/>
	</div>
</div>




<div id="victorModal" class="modal fade centramodal">
    <div class="modal-dialog centrado">
        <div class="modal-content centrado">
            <div class="modal-header">
                <h4 class="modal-title">¿Estás seguro?</h4>
            </div>
            <div class="modal-body">
                <p>¿Seguro que quieres borrar estas facturas?</p>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-default" data-dismiss="modal">Cerrar</button>
                <button type="button" class="btn btn-danger" (click)="borrafacturas(anoeliminar)" data-dismiss="modal">Eliminar</button>
            </div>
        </div>
    </div>
</div>

<div id="victorModal2" class="modal fade centramodal">
    <div class="modal-dialog centrado">
        <div class="modal-content centrado">
            <div class="modal-header">
                <h4 class="modal-title">¿Estás seguro?</h4>
            </div>
            <div class="modal-body">
                <p>¿Seguro que quieres borrar estas facturas?</p>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-default" data-dismiss="modal">Cerrar</button>
                <button type="button" class="btn btn-danger" (click)="borrafacturasmes(anoeliminar, meseliminar)" data-dismiss="modal">Eliminar</button>
            </div>
        </div>
    </div>
</div>		
