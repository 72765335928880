<h2 class="centrado">{{title}}</h2>

<div class="lista">
	<ul>
		<li *ngFor="let cliente of clientes" class="listafacturas">
			<a [routerLink]="['../archivos/' + cliente._id]" class="enlacearchivo">
				<div>
					<mat-icon>assignment_ind</mat-icon><br>
					{{cliente.numero}}  -  {{cliente.nombre}}
				</div>
			</a>	
		</li>
	</ul>
	
</div>
