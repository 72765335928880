<div style="width:100%;height:100%">
	<div style="display:inline-flex;width:100%;border-bottom: 0.5px solid black;">
		<div (click)="guardargasto()"  matTooltip="Guardar" class="botonbarraherramientas fondooscuro">
			<span class="material-icons">save</span>
		</div>
        <!--<div (click)="vergasto()"  matTooltip="ver" class="botonbarraherramientas fondooscuro">
			<span class="material-icons">save</span>
		</div>
		<label matTooltip="Agregar Archivo" for="file-upload" class="botonbarraherramientas fondooscuro">
			<span class="material-icons">attach_file</span>
		</label>
		<input id="file-upload" multiple="true" (change)="fileChangeEvent($event)" type="file" style='display: none;'/>-->
		<div (click)="eliminargasto()"  matTooltip="Eliminar Gasto" class="botonbarraherramientas fondooscuro" style="margin-left:30px;">
			<span class="material-icons">highlight_off</span>
		</div>
		<span class="spacer"></span>
		<div (click)="closeDialog()"  matTooltip="Salir" class="botonbarraherramientas fondooscuro">
			<span class="material-icons">backspace</span>
		</div>
	</div>
	<div class="contenidodatos">
        <mat-form-field class="datovehiculo">
            <mat-label style="color:black;" (click)="picker2.open()"><b>Fecha</b></mat-label>
              <input matInput [matDatepicker]="picker2" (click)="picker2.open()" [(ngModel)]="fechagasto" (dateChange)="asignarfecha($event)">
                <button mat-button *ngIf="fechagasto" matSuffix mat-icon-button aria-label="Clear" (click)="fechagasto=null">
                    <mat-icon>close</mat-icon>
                </button>
            <mat-datepicker #picker2></mat-datepicker>
          </mat-form-field>
        <mat-form-field class="datovehiculo">
            <mat-label>Matricula</mat-label>
            <input matInput type="text" [(ngModel)]="data.matricula" (ngModelChange)="data.matricula = $event" [matAutocomplete]="auto">
            <button mat-button *ngIf="data.matricula" matSuffix mat-icon-button aria-label="Clear" (click)="data.matricula=''">
                <mat-icon>close</mat-icon>
            </button>
            <mat-autocomplete #auto="matAutocomplete">
                <mat-option *ngFor="let vehiculo of vehiculos" [value]="vehiculo.matricula">
                    {{vehiculo.matricula}}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>
        <mat-form-field class="datovehiculo">
            <mat-label>Tienda</mat-label>
            <input matInput type="text" [(ngModel)]="data.tienda" (ngModelChange)="data.tienda = $event" [matAutocomplete]="auto2">
            <button mat-button *ngIf="data.tienda" matSuffix mat-icon-button aria-label="Clear" (click)="data.tienda=''">
            <mat-icon>close</mat-icon>
            </button>
            <mat-autocomplete #auto2="matAutocomplete">
                <mat-option *ngFor="let tienda of listatiendas" [value]="tienda">
                    {{tienda}}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>
        <mat-form-field class="datovehiculo">
            <mat-label>Tarjeta</mat-label>
            <input matInput type="text" [matAutocomplete]="auto3" [(ngModel)]="data.tarjeta" (ngModelChange)="seleccionatarjeta($event)">
            <button mat-button *ngIf="data.tarjeta" matSuffix mat-icon-button aria-label="Clear" (click)="data.tarjeta=''">
                <mat-icon>close</mat-icon>
            </button>
            <mat-autocomplete #auto3="matAutocomplete">
                <mat-option *ngFor="let tarjeta of tarjetas" [value]="tarjeta.nombre + '-' + tarjeta.matricula">
                    {{tarjeta.nombre}}-{{tarjeta.matricula}}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>
        <mat-form-field class="datovehiculo">
            <mat-label>Mensajero</mat-label>
            <input matInput type="text" [matAutocomplete]="auto4" [(ngModel)]="data.mensajero">
            <button mat-button *ngIf="data.mensajero" matSuffix mat-icon-button aria-label="Clear" (click)="data.mensajero=''">
                <mat-icon>close</mat-icon>
            </button>
            <mat-autocomplete #auto4="matAutocomplete">
                <mat-option *ngFor="let mensajero of mensajeros" [value]="mensajero.numero + '-' + mensajero.nombre">
                    {{mensajero.numero}}-{{mensajero.nombre}}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>
        <mat-form-field class="datovehiculo">
            <mat-label>Importe</mat-label>
            <input matInput type="text" [(ngModel)]="data.importe" (blur)="parsenumero()">
            <button mat-button *ngIf="data.importe" matSuffix mat-icon-button aria-label="Clear" (click)="data.importe=0">
                <mat-icon>close</mat-icon>
            </button>
        </mat-form-field>
        <mat-form-field class="datovehiculo">
            <mat-label>Numero Tarjeta</mat-label>
            <input matInput type="text" [matAutocomplete]="auto5" [(ngModel)]="numerotarjeta" (ngModelChange)="seleccionanumerotarjeta($event)">
            <button mat-button *ngIf="numerotarjeta" matSuffix mat-icon-button aria-label="Clear" (click)="numerotarjeta=null">
                <mat-icon>close</mat-icon>
            </button>
            <mat-autocomplete #auto5="matAutocomplete">
                <mat-option *ngFor="let tarjeta of tarjetas" [value]="tarjeta">
                    {{tarjeta.numero}}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>
        <mat-form-field class="datovehiculo" style="width:90%">
            <mat-label>Notas</mat-label>
            <textarea  matInput rows="5" [(ngModel)]="data.notas"></textarea>
        </mat-form-field>
    </div>  
</div>        